export const fullMenu = [
  {
    label: 'My profile',
    href: '#'
  },
  {
    label: 'Update profile'
  },
  {
    label: 'Log out'
  }
]

export const shortMenu = [
  {
    label: 'Create profile'
  },
  {
    label: 'Uploaded content',
    href: '#'
  },
  {
    label: 'Log out'
  }
]

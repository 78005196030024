<script lang="ts" setup>
import { computed, ref, watchEffect } from 'vue'
import { baseUrl } from '@/utils/route'
import { useNearWalletStore } from '@/stores/near-wallet'
import { useUcanStore } from '@/stores/ucan'
import { storeToRefs } from 'pinia'
import { Vue3Lottie } from 'vue3-lottie'
import { v4 as uuidv4 } from 'uuid'
// import { pinDataToIPFS } from '@/utils/ipfs'
import { addSmartBriefContentFeedback } from '@/queries/api'
import circles from '@/lottie/searching.json'
import LoginRequired from '@/components/LoginRequired.vue'
import ToggleContent from '@/components/ToggleContent.vue'
import { mobileDetect } from '@/utils/browser'

const ucanStore = useUcanStore()

const emit = defineEmits(['submitted'])

const props = withDefaults(
  defineProps<{
    brief?: SmartBrief
    show?: boolean
    brief_id: string
    thread_id?: string
    dm_candidate_id?: string
    reference_id?: string
    reference_type?: string
  }>(),
  {
    show: true,
    brief_id: '',
    thread_id: '',
    dm_candidate_id: '',
    reference_id: '',
    reference_type: ''
  }
)

const nearWalletStore = useNearWalletStore()
const { accountId } = storeToRefs(nearWalletStore)

const busy = ref(false)
const busyFile = ref(false)

const question = ref('')

const id = ref(uuidv4())

const selectedFile = ref<File | null>(null)
const fileInput = ref<HTMLInputElement | null>(null)
const filePreview = ref<string | null>(null)
const UploadRefLogo = ref()
const fileUploadIcon = ref(false)
const uploadedData = ref()
const isMobile = ref(mobileDetect())

const keyUp = (e: KeyboardEvent) => {
  question.value = (e.target as HTMLInputElement).value
  if (e.key == 'Enter') {
    submitQ()
  }
  if (e.key == 'Escape') {
    doFadeOut()
  }
}

const briefUrl = computed(() => baseUrl() + '/briefs/' + props.brief?.id)

const submitQ = async () => {
  busy.value = true

  const q = question.value.trim()

  if (!q.length) {
    console.warn('submitQ: no question submitted')
    busy.value = false
    return
  }

  /* const created_on = new Date().getTime()
  const data = {
    brief_id: props.brief_id,
    id: id.value,
    parent_id: props.thread_id,
    account_id: accountId.value,
    account_name: user.value?.name,
    comment: q,
    created_on: created_on,
    dm_candidate_id: props.dm_candidate_id || null
  } */
  const dm_candidate_id = props.dm_candidate_id || null
  const data: {
    id?: string
    referenceId: string
    referenceType: string
    parentId: string | null
    owner: string
    feedbackText: string
    feedbackFile: string
    createdAt?: string
    updatedAt?: string
  } = {
    referenceId: props.reference_id,
    referenceType: props.reference_type,
    parentId: props.thread_id || null,
    owner: accountId.value,
    feedbackText: q,
    feedbackFile: uploadedData.value
  }

  try {
    // const ipfsResponse = await pinDataToIPFS(JSON.stringify(data))
    // const cidString = ipfsResponse?.cid ? ipfsResponse.cid.toString() : ''

    // calling api to add smartbrief content feedback to db
    const addSmartBriefContentFeedbackResponse = await addSmartBriefContentFeedback(data)

    // update the data with the feedback id
    data.id = addSmartBriefContentFeedbackResponse?.id
    data.createdAt = addSmartBriefContentFeedbackResponse?.createdAt
    data.updatedAt = addSmartBriefContentFeedbackResponse?.updatedAt

    // console.log('addSmartBriefContentFeedbackResponse', addSmartBriefContentFeedbackResponse)

    question.value = ''
    const cl = 'qa-input-' + id.value
    const el = document.getElementsByClassName(cl)
    id.value = uuidv4()
    if (el?.[0] && el[0] instanceof HTMLInputElement) {
      el[0].value = ''
    }

    // Send a message to the other party when this is a DM using a "Delegated UCAN Token"
    // get own profile
    const fetchedProfile = (await ucanStore.fetchProfileMemoized(accountId.value))?.data
    const fetchedProfileName =
      fetchedProfile.name || fetchedProfile.firstName || fetchedProfile.firstname

    // send to the candidate or the brief owner
    const messageUrl = props.reference_type === 'content' ? 'submissions' : 'messages'
    const isQA = window.location.pathname.match(/^\/briefs\/[a-z0-9_-]+\/qa/) ? true : false

    const haveBrief = props.brief?.id

    if (props.reference_type === 'team') {
      // [TODO]: Send a message to the team members
    } else if (haveBrief && props.brief.owner === accountId.value) {
      if (dm_candidate_id) {
        // [TEMP]: Should be... ucanStore.notifyUsingDelegatedUCAN
        await ucanStore.notifyUsingBriefCandidateProfileUCAN(
          props.brief.owner,
          dm_candidate_id,
          props.brief,
          {
            workflowId: 'smart-brief-candidate-dm',
            payload: {
              comment: data.feedbackText,
              commentId: uuidv4(),
              briefName: props.brief.title,
              briefLink: briefUrl.value + (isQA ? '/qa' : `/${messageUrl}`),
              senderName: fetchedProfileName || 'Brand',
              senderId: fetchedProfileName?.owner || 'candidate'
            }
          }
        )
      }
    } else if (haveBrief) {
      // [TEMP]: Should be... ucanStore.notifyUsingDelegatedUCAN
      await ucanStore.notifyUsingBriefCandidateProfileUCAN(
        accountId.value,
        props.brief.owner,
        props.brief,
        {
          workflowId: isQA ? 'to-brand-creator-asked-question' : 'smart-brief-candidate-dm',
          payload: {
            comment: data.feedbackText,
            commentId: uuidv4(),
            briefName: props.brief.title,
            briefLink: briefUrl.value + (isQA ? '/qa' : `/${messageUrl}`),
            senderName: fetchedProfileName || 'Candidate',
            senderId: fetchedProfileName?.owner || 'candidate'
          }
        }
      )
    }

    emit('submitted', data)
  } catch (error) {
    console.error('submitQ:', error)
  } finally {
    busy.value = false
  }
}

const fadeIn = ref(props.show)
const spacer = ref(props.show)

const focusOnElementByClassname = (className: string) => {
  const el = document.getElementsByClassName(className)
  if (el?.[0] && el[0] instanceof HTMLInputElement) {
    el[0].focus()
  }
}

const doFadeIn = () => {
  setTimeout(() => {
    fadeIn.value = true
  }, 50)
  focusOnElementByClassname('qa-input-' + id.value)
}
const doFadeOut = () => {
  setTimeout(() => {
    if (!spacer.value) {
      fadeIn.value = false
    }
    setTimeout(() => {
      focusOnElementByClassname('qa-input-' + id.value)
    }, 500)
  }, 50)
}

watchEffect(() => {
  if (props.show) {
    doFadeIn()
  } else {
    doFadeOut()
  }
})

const placeholder = computed(() => {
  if (props.reference_type === 'profile') {
    return 'Send a message'
  } else if (props.reference_type === 'content') {
    return 'Send feedback or comments'
  } else {
    return 'Send a message'
  }
})

const triggerFileUpload = () => {
  isMobile.value = mobileDetect()
  busyFile.value = true
  fileUploadIcon.value = true
  setTimeout(() => {
    busyFile.value = false
    /* window.scrollBy({
        top: isMobile ? 200 : 400,
        behavior: "smooth",
      }); */
  }, 2000)
  /* if (fileInput.value) {
    fileInput.value.click();
  } */
}

const handleFileUpload = (event: any) => {
  fileUploadIcon.value = true
  const file = event.target.files[0]
  if (file) {
    selectedFile.value = file
    if (file.type.startsWith('image/') || file.type.startsWith('video/')) {
      const reader = new FileReader()
      reader.onload = (e) => {
        filePreview.value = e.target?.result as string
      }
      reader.readAsDataURL(file)
    } else {
      filePreview.value = null
    }

    console.log('Selected file:', file.name)
  }
}

const isImageFile = computed(() => {
  return selectedFile.value ? selectedFile.value.type.startsWith('image/') : false
})
const isVideoFile = computed(() => {
  return selectedFile.value ? selectedFile.value.type.startsWith('video/') : false
})
const fileIconClass = computed(() => {
  if (!selectedFile.value) return ''
  const fileType = selectedFile.value.type
  if (fileType.includes('pdf')) {
    return 'fa fa-file-pdf'
  } else if (
    fileType.includes('word') ||
    fileType.includes('officedocument.wordprocessingml.document')
  ) {
    return 'fa fa-file-word'
  } else if (
    fileType.includes('excel') ||
    fileType.includes('officedocument.spreadsheetml.sheet')
  ) {
    return 'fa fa-file-excel'
  } else if (
    fileType.includes('powerpoint') ||
    fileType.includes('officedocument.presentationml.presentation')
  ) {
    return 'fa fa-file-powerpoint'
  } else if (fileType.includes('zip') || fileType.includes('compressed')) {
    return 'fa fa-file-archive'
  } else {
    return 'fa fa-file'
  }
})
const saveUploadedFile = async (fieldName: string, data: any) => {
  // console.log('saveFile in BriefDetailsQAInput : fieldName', fieldName, data)
  uploadedData.value = data
}
</script>

<style>
.file-upload-button {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.file-upload-button:focus {
  outline: none;
}

.hidden {
  display: none;
}

.file-info {
  font-size: 0.875rem;
  color: #6c757d;
}

.file-preview {
  margin-top: 0.5rem;
  padding-bottom: 1rem;
}

.preview-image {
  max-width: 200px;
  max-height: 150px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.preview-video {
  max-width: 200px;
  max-height: 150px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.file-icon-preview {
  display: flex;
  align-items: center;
}

.file-icon {
  font-size: 2rem;
  margin-right: 0.5rem;
  color: #6c757d;
}

.non-image-preview {
  font-size: 0.875rem;
  color: #6c757d;
}
</style>

<template>
  <div v-if="fileUploadIcon" class="py-2">
    <ToggleContent
      message=""
      uploadField="UploadRefLogo"
      :show="fileUploadIcon"
      :uploadImagesOnly="false"
      :multiSelect="true"
      :uploadAccountId="accountId"
      :uploadValue="UploadRefLogo"
      @onUploaded="saveUploadedFile"
    />
  </div>
  <div
    class="w-full mx-auto transition-all duration-1000 opacity-0"
    :class="{
      'opacity-100 h-16': fadeIn,
      'h-0 overflow-hidden': !fadeIn,
      'mt-3': !spacer && fadeIn
    }"
  >
    <LoginRequired message="Please login to ask a question">
      <div
        class="w-full border border-[#E9E9EB] hover:border-[#C9C9CA] p-2 rounded-md flex flex-row"
      >
        <input
          type="text"
          class="border-0 flex-grow mx-1 focus:ring-0"
          :placeholder="placeholder"
          :v-model="question"
          @keyup="keyUp"
          :class="'qa-input-' + id"
        />
        <button class="file-upload-button mx-1" @click="triggerFileUpload">
          <Vue3Lottie v-if="busyFile" :animationData="circles" :height="25" :width="25" />
          <i v-else class="fa fa-paperclip" style="color: #e2007b"></i>
        </button>

        <!-- Hidden file input -->
        <input type="file" ref="fileInput" class="hidden" @change="handleFileUpload" />
        <button class="border-0" @click="submitQ()">
          <Vue3Lottie v-if="busy" :animationData="circles" :height="40" :width="40" />
          <i v-else class="fa-light px-3 fa-paper-plane-top" style="color: #e2007b"></i>
        </button>
      </div>
      <!-- Display selected file name and preview -->
      <div v-if="selectedFile" class="file-info mx-1 mt-2">
        <div v-if="filePreview" class="file-preview">
          <p>Selected File: {{ selectedFile.name }}</p>
          <!-- Image preview -->
          <img
            v-if="isImageFile"
            :src="filePreview"
            alt="Selected File Preview"
            class="preview-image"
          />
          <!-- Video preview -->
          <video v-if="isVideoFile" :src="filePreview" class="preview-video" controls></video>
        </div>
        <!-- Document or Other File Type Icon -->
        <div v-else class="file-icon-preview">
          <i :class="fileIconClass" class="file-icon"></i>
          <p>{{ selectedFile.name }}</p>
        </div>
      </div>
    </LoginRequired>
  </div>
</template>

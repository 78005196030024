<script lang="ts" setup>
import { ref, watchEffect } from 'vue'

const props = withDefaults(
  defineProps<{
    show: boolean
  }>(),
  {
    show: false
  }
)

const fadeIn = ref(false)
watchEffect(() => {
  if (props.show) {
    setTimeout(() => {
      fadeIn.value = true
    }, 50)
  } else {
    fadeIn.value = false
  }
})
</script>

<template>
  <div
    v-show="show"
    class="transition-opacity duration-1000"
    :class="{
      'opacity-0': !fadeIn,
      'opacity-100': fadeIn
    }"
  >
    <slot />
  </div>
</template>

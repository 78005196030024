<script lang="ts" setup>
import { ref } from 'vue'
import PopupModal from '@media/PopupModal.vue'

export interface Props {
  title?: string
  message?: string
  okButton?: string
  cancelButton?: string
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  message: '',
  okButton: '',
  cancelButton: 'Cancel'
})

const popup = ref<InstanceType<typeof PopupModal> | undefined>(undefined)

var title = ref(props.title)
var message = ref(props.message)
var okButton = ref(props.okButton)
var cancelButton = ref(props.cancelButton)

var resolvePromise = ref<Function>()
var rejectPromise = ref<Function>()

var show = function (opts = {} as Props) {
  title.value = opts.title || props.title
  message.value = opts.message || props.message
  okButton.value = opts.okButton || props.okButton
  if (opts.cancelButton) {
    cancelButton.value = opts.cancelButton || props.cancelButton
  }

  popup.value?.open()

  return new Promise((resolve, reject) => {
    resolvePromise.value = resolve
    rejectPromise.value = reject
  })
}

var _confirm = function () {
  popup.value?.close()
  if (typeof resolvePromise.value === 'function') {
    resolvePromise.value(true)
  }
}

var _cancel = function () {
  popup.value?.close()
  if (typeof resolvePromise.value === 'function') {
    resolvePromise.value(false)
  }
}

defineExpose({
  show
})
</script>

<template>
  <PopupModal ref="popup">
    <div class="p-3 mx-3">
      <h2 class="font-bold text-[#FF2028] text-center sm:text-start ps-2">ATTENTION!</h2>
      <div class="px-2 my-3">
        <div v-html="message"></div>
      </div>
      <h1 class="font-bold px-2 text-center sm:text-start">{{ title }}</h1>
      <div class="flex flex-row space-x-4 justify-center sm:justify-start px-2 pt-6">
        <button
          class="w-[120px] items-center justify-center bg-[#E2007B] text-[#ffffff] rounded-full cursor-pointer px-4 py-2"
          @click="_confirm"
        >
          {{ okButton }}
        </button>
        <button
          class="w-[120px] items-center justify-center bg-[#ffffff] text-[#E2007B] border border-[#E2007B] cursor-pointer rounded-full px-4 py-2"
          @click="_cancel"
        >
          {{ cancelButton }}
        </button>
      </div>
    </div>
  </PopupModal>
</template>

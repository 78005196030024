<script lang="ts" setup>
import { fetchProfile } from '@/queries/api'
import { useUcanStore } from '@/stores/ucan'
import { reactive, ref, watchEffect, computed } from 'vue'

import MediaCarousel from '@media/MediaCarousel.vue'
import TabNavigation from '@/components/TabNavigation.vue'

const ucanStore = useUcanStore()

const PAGE_SIZE = 10

const props = withDefaults(
  defineProps<{
    accountId: string
    brief: SmartBrief
  }>(),
  {
    accountId: ''
  }
)

const params = reactive({
  accountId: '',
  first: PAGE_SIZE,
  after: ''
})

const contents = ref<ProfileContent[]>([])
const profileDetails = ref()

watchEffect(async () => {
  const profileData = await fetchProfile(props.accountId)
  params.accountId = props.accountId

  if (profileData) {
    // pass the bio / headline down
    profileDetails.value = profileData

    contents.value = []
    for (const cc of profileData?.contents || []) {
      contents.value.push(cc)
    }
  }
})

const tabs = [
  { id: 'tab1', label: 'Portfolio', selected: true }
  // { id: 'tab2', label: 'Stock', selected: false }
]

const selectedTab = ref('tab1') // Set default selected tab

const updateSelectedTab = (tabId: string) => {
  selectedTab.value = tabId
}

const userCandidateProfile = computed(() => {
  const profile = props?.brief?.candidateProfiles?.find((item) => item.userId === props?.accountId)
  return profile
})
</script>
<style lang="scss" scoped>
.candidate-message {
  padding-top: 10px;
}
</style>

<template>
  <div v-if="profileDetails">
    <!-- Tabs Navigation -->
    <TabNavigation v-if="contents.length" :links="tabs" @selected="updateSelectedTab" />

    <!-- Tab Contents -->
    <div v-if="contents.length && selectedTab === 'tab1'">
      <MediaCarousel :showCount="true" :items="contents" />
    </div>

    <!-- Bio -->
    <div v-if="profileDetails && profileDetails.aboutYou">
      <div class="my-2"><b>Bio</b></div>
      <div class="my-2">{{ profileDetails.aboutYou }}</div>
    </div>

    <!-- Message -->
    <div v-if="userCandidateProfile && userCandidateProfile?.message" class="candidate-message">
      <div class="my-2"><b>Why I'm right for this brief</b></div>
      <div class="my-2">{{ userCandidateProfile?.message }}</div>
    </div>
  </div>
</template>

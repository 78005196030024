import { computed, ref } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import { defineStore } from 'pinia'
import {
  getTeam,
  getTeamBriefs,
  getTeamMembers,
  getTeamInvites,
  getTeamMemberPermissions,
  getTeamRoles,
  getAvailablePermissions,
  getMyPermissionsForBriefId,
  type FetchSmartBriefsParams
} from '@/queries/api'
import useLazyPiniaStore from '@/utils/lazyPiniaStore'

type TeamStoreTeamKeyType = 'nickname' | 'uuid'

const countRecordsByProperty = <T, K extends keyof T>(
  items: T[],
  propertyName: K
): Record<T[K] & (string | number | symbol), number> => {
  return (
    items?.reduce(
      (acc, item) => {
        const key = item[propertyName]
        if (typeof key === 'string' || typeof key === 'number' || typeof key === 'symbol') {
          acc[key] = (acc[key] || 0) + 1
        }
        return acc
      },
      {} as Record<T[K] & (string | number | symbol), number>
    ) || {}
  )
}

export const useTeamStore = defineStore('team', () => {
  const defaultDebounceTime = 10

  const team = ref<Team>()
  const briefs = ref<SmartBrief[]>([])
  const members = ref<TeamMemberWithRoleAndProfile[]>([])
  const invites = ref<TeamInvite[]>([])
  const roles = ref<TeamRoleWithPermissions[]>([])
  const availablePermissions = ref<TeamPermission[]>([])
  const loggedInMemberPermissions = ref<string[]>([])

  const loggedInMemberPermissionsMap = computed((): Record<string, boolean> => {
    if (!loggedInMemberPermissions.value || !Array.isArray(loggedInMemberPermissions.value)) {
      return {}
    }

    return loggedInMemberPermissions.value.reduce(
      (acc, permission) => {
        acc[permission] = true
        return acc
      },
      {} as Record<string, boolean>
    )
  })

  function resetState() {
    team.value = undefined
    members.value = []
    invites.value = []
    roles.value = []
    loggedInMemberPermissions.value = []
    availablePermissions.value = []
  }

  const fetchTeam = useDebounceFn(async (teamKey: TeamStoreTeamKeyType, teamId: string) => {
    team.value = await getTeam(teamKey, teamId)
  }, defaultDebounceTime)

  const fetchBriefs = useDebounceFn(
    async (
      teamKey: TeamStoreTeamKeyType,
      teamId: string,
      params: FetchSmartBriefsParams = {
        option: 'live'
      }
    ) => {
      briefs.value = await getTeamBriefs(teamKey, teamId, params)
    },
    defaultDebounceTime
  )

  const fetchTeamMembers = useDebounceFn(async (teamKey: TeamStoreTeamKeyType, teamId: string) => {
    members.value = await getTeamMembers(teamKey, teamId)
  }, defaultDebounceTime)

  const fetchTeamInvites = useDebounceFn(async (teamKey: TeamStoreTeamKeyType, teamId: string) => {
    invites.value = await getTeamInvites(teamKey, teamId)
  }, defaultDebounceTime)

  const fetchTeamRoles = useDebounceFn(async (teamKey: TeamStoreTeamKeyType, teamId: string) => {
    roles.value = await getTeamRoles(teamKey, teamId, {
      includePermissions: true
    })
  }, defaultDebounceTime)

  const fetchTeamMemberPermissions = useDebounceFn(
    async (teamKey: TeamStoreTeamKeyType, teamId: string, memberId: string) => {
      loggedInMemberPermissions.value = []
      loggedInMemberPermissions.value = await getTeamMemberPermissions(teamKey, teamId, memberId)
    },
    defaultDebounceTime
  )

  const fetchBriefPermissions = useDebounceFn(async (briefId: string) => {
    loggedInMemberPermissions.value = await getMyPermissionsForBriefId(briefId)
  }, defaultDebounceTime)

  const fetchAvailablePermissions = useDebounceFn(
    async (teamKey: TeamStoreTeamKeyType, teamId: string) => {
      availablePermissions.value = await getAvailablePermissions(teamKey, teamId)
    },
    defaultDebounceTime
  )

  const can = (permission: string) => {
    return loggedInMemberPermissionsMap.value?.[permission]
  }

  const cannot = (permission: string) => {
    return !can(permission)
  }

  const membersByRoleUuid = computed(() => countRecordsByProperty(members.value, 'roleUuid'))

  const invitesByRoleUuid = computed(() => countRecordsByProperty(invites.value, 'roleUuid'))

  return {
    team,
    briefs,
    members,
    invites,
    roles,
    availablePermissions,
    loggedInMemberPermissions,
    loggedInMemberPermissionsMap,
    resetState,
    fetchTeam,
    fetchBriefs,
    fetchTeamMembers,
    fetchTeamInvites,
    fetchTeamRoles,
    fetchTeamMemberPermissions,
    fetchBriefPermissions,
    fetchAvailablePermissions,
    can,
    cannot,
    membersByRoleUuid,
    invitesByRoleUuid
  }
})

export type TeamStoreType = ReturnType<typeof useTeamStore>

export const lazyTeamStore = useLazyPiniaStore<TeamStoreType>(useTeamStore) as TeamStoreType

<script lang="ts" setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

export type Step = {
  number: number
  label: string
  href?: string
  onClickStep?: () => void
}

const props = withDefaults(
  defineProps<{
    selectedStep?: number
    steps: Step[]
    allowAnyStep?: boolean
  }>(),
  {
    selectedStep: 1,
    steps: () => []
  }
)

const $router = useRouter()

const goToStep = (step: Step) => {
  if (props.allowAnyStep || props.selectedStep > step.number) {
    if (step.href) {
      $router.push(step.href)
    }

    if (typeof step.onClickStep === 'function') {
      step.onClickStep()
    }
  }
}

const completedPercentage = computed(() => {
  // localise
  const selectedStep = props.selectedStep
  const maxSteps = props.steps.length

  // calculate percentage
  let pct = ((selectedStep - 1) / (maxSteps - 1)) * 100

  // max can be 100
  pct = pct >= 100 ? 100 : pct

  // when its step 1 then its 0%
  pct = selectedStep == 1 ? 0 : pct

  // send back what we have
  return pct
})
</script>

<template>
  <div class="flex flex-col w-[80%] min-h-16">
    <ul class="flex flex-wrap justify-between z-10">
      <li v-for="(step, index) in props.steps" :key="index" class="" @click="goToStep(step)">
        <div class="my-1 flex flex-grow items-center text-center flex-col">
          <div class="px-2 bg-white rounded-full">
            <div
              class="text-xs font-bold rounded-full border-2 px-2 py-1"
              :class="{
                'cursor-pointer': allowAnyStep || step.number < selectedStep,
                'border-[#FF008B] bg-[#FF008B] text-white': step.number == selectedStep,
                'border-[#FF008B] bg-white text-[#FF008B]': step.number < selectedStep,
                'border-gray-300 bg-gray-300 text-black': step.number > selectedStep
              }"
            >
              <span>{{ step.number }}</span>
            </div>
          </div>

          <div class="my-3 text-xs font-semibold">
            <span>{{ step.label }}</span>
          </div>
        </div>
      </li>
    </ul>
    <div class="bg-gray-300 h-[2px] -mt-[3.75rem] mx-auto w-11/12">
      <div class="bg-[#FF008B] h-[2px]" :style="{ width: completedPercentage + '%' }">&nbsp;</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { route } from '@/utils/route'
import { useHead } from '@unhead/vue'
import TabNavigation from '@/components/TabNavigation.vue'
import PageHeading from '@/components/PageHeading.vue'
import PopupImage from '@media/PopupImage.vue'

const props = defineProps({
  title: {
    type: String,
    default: 'CMG'
  },
  selectedLinkId: String
})

const { t: $t } = useI18n({})

const page = ref(window.location.pathname.substring(1))

const links = [
  {
    id: 'licensing',
    text: $t('marketplace.licensing.title'),
    href: route('marketplace.licensing', { query: { media_preference: 'premium' } }),
    icon: 'fa-send-backward'
  },
  // {
  //     id: 'briefs',
  //     text: $t('marketplace.briefs.title'),
  //     href: route('marketplace.briefs'),
  //     icon: 'fa-memo',
  // },
  {
    id: 'licensed',
    text: $t('marketplace.licensed.title'),
    href: route({
      name: 'marketplace.licensed'
    }),
    icon: 'fa-folder-open'
  },
  {
    id: 'rewards',
    text: $t('marketplace.rewards.title'),
    href: route('marketplace.rewards'),
    icon: 'fa-sparkles'
  }
].map((link) => ({
  ...link,
  selected: link.id === props.selectedLinkId
}))

const lockScroll = ref<number>(0)
clearInterval(window.myWatch)
window.myWatch = setInterval(() => {
  lockScroll.value = window.lockScroll || 0
}, 1000)

useHead({
  title: props.title
})
</script>

<template>
  <!-- <MagicLinkAppLock> -->
  <PopupImage />
  <div>
    <div
      :class="{
        'min-h-screen': !lockScroll,
        'overflow-hidden max-h-screen': lockScroll
      }"
    >
      <header class="bg-cm-blue-500 shadow">
        <PageHeading :title="$t('marketplace.title')" />
      </header>
      <main>
        <div class="max-w-screen-2xl mx-auto flex">
          <!-- <div class='flex-none'>
                        <Sidebar :links="links" />
                    </div> -->
          <div class="flex-grow content m-6">
            <div class="mb-6">
              <TabNavigation
                :links="
                  links.map((link) => ({
                    ...link,
                    label: link.text
                  }))
                "
              />
            </div>

            <slot v-if="$slots.header" name="header" />

            <div class="my-3">
              <p v-if="page == 'marketplace/licensing'">
                {{ $t('marketplace.licensing.description') }}
              </p>

              <p v-if="page == 'marketplace/briefs'">
                {{ $t('marketplace.briefs.description') }}
              </p>

              <p v-if="page == 'marketplace/licensed'">
                {{ $t('marketplace.licensed.description') }}
              </p>

              <p v-if="page == 'marketplace/rewards'">
                {{ $t('marketplace.rewards.description') }}
              </p>
            </div>

            <slot />
          </div>
        </div>
      </main>
    </div>
  </div>
  <!-- </MagicLinkAppLock> -->
</template>

const key: string = 'cmg-local-data'

export const clearLocalData = (): boolean => {
  try {
    localStorage.removeItem(key)
    console.debug('clearLocalData : OK', key)
    return true
  } catch (error) {
    console.error('clearLocalData :', error)
    return false
  }
}

export const getLocalData = (): WizardData | false => {
  try {
    const serializedValue = localStorage.getItem(key)
    if (serializedValue) {
      const value = JSON.parse(serializedValue)
      if (value) {
        console.debug('getLocalData :', key, value)
        return value
      }
    }
  } catch (error) {
    console.error('getLocalData :', error)
  }
  return false
}

export const saveLocalData = (value: WizardData): boolean => {
  try {
    const serializedValue = JSON.stringify(value)
    localStorage.setItem(key, serializedValue)
    // console.debug('saveLocalData :', value)
    return true
  } catch (error) {
    console.error('saveLocalData :', error)
    return false
  }
}

<script lang="ts" setup>
// @ts-nocheck
import { ref, watchEffect } from 'vue'
import { BlobWriter, ZipWriter, BlobReader } from '@zip.js/zip.js'

const props = defineProps({
  dataType: {
    type: String,
    default: 'objects'
  },
  height: {
    type: String,
    default: '260px'
  },
  items: {
    type: Array,
    default: () => []
  },
  imageCss: {
    type: Array,
    default: () => []
  },
  showDescription: {
    type: Boolean,
    default: false
  },
  showOwner: {
    type: Boolean,
    default: false
  },
  isAccepted: {
    type: Boolean,
    default: false
  },
  downloadable: {
    type: Boolean,
    default: false
  },
  noContentMsg: {
    type: String,
    default: ''
  },
  brief: Object as PropType<SmartBrief>
})

const list = ref([])

watchEffect(() => {
  list.value = props.items
})

// just a list of urls
if (props.dataType == 'url_list') {
  list.value = []
  props.items.forEach((i) => {
    list.value.push({ file_url: i })
  })
}

// unknown format
if (list.value.length) {
  if (typeof list.value[0].file_url === 'undefined') {
    console.warn('Unexpected format', list.value)
  }
  if (typeof list.value[0].createdAt !== 'undefined') {
    list.value.sort((a, b) => b?.createdAt - a?.createdAt)
  }
}

const downloadAllFiles = async () => {
  try {
    const zipWriter = new ZipWriter(new BlobWriter('application/zip'))

    for (const entry of list.value) {
      const response = await fetch(entry.file_url, { mode: 'no-cors' })
      if (!response.ok) {
        console.error(`Failed to fetch ${entry.file_url}: ${response.statusText}`)
        continue
      }
      const blob = await response.blob()
      await zipWriter.add(entry.name, new BlobReader(blob))
    }

    const zipBlob = await zipWriter.close()
    const url = URL.createObjectURL(zipBlob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'all_files.zip'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  } catch (err) {
    console.log('Error:', err)
  }
}
</script>

<style lang="scss" scoped>
.file-icon {
  font-size: 1.5rem;
}
</style>

<template>
  <div v-if="list.length" id="file-list" class="w-full relative pt-2">
    <p class="text-m font-bold mb-4">Files</p>
    <ul class="list-none p-0">
      <li v-for="(entry, index) in list" :key="index" class="flex items-center mb-2 md:mb-4">
        <span class="file-icon mr-2 text-[#FF008B]">
          <i :class="entry.fileicon"></i>
        </span>
        <a :href="entry.file_url" class="text-gray-500 hover:underline text-sm" target="_blank">{{
          entry.name
        }}</a>
      </li>
    </ul>
    <!-- <div class="w-36 action-button hollow mt-6" @click="downloadAllFiles">
      Download all
    </div> -->
  </div>
  <div v-else-if="noContentMsg">
    {{ noContentMsg }}
  </div>
</template>

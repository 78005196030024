<script lang="ts" setup>
// @ts-nocheck
import { ref } from 'vue'
import { Styles } from '@/styles'
import { useToast } from 'vue-toastification'
import MarketplaceLayout from '@/Layouts/MarketplaceLayout.vue'
import BriefsNew from '@/components/Pages/Marketplace/BriefsNew.vue'
import BriefsLive from '@/components/Pages/Marketplace/BriefsLive.vue'
import StageCount from '@/components/StageCount.vue'
import PageTitleHeading from '@/components/PageTitleHeading.vue'
import TabNavigation from '@/components/TabNavigation.vue'
import LoginRequired from '@/components/LoginRequired.vue'

const props = defineProps({
  initTab: {
    type: String,
    default: 'briefsNew'
  }
})

const toast = useToast()

let activeTab = ref(props.initTab)
const selectTab = (item) => {
  activeTab.value = item
}

let tabMenuItems = [
  { id: 'briefsNew', label: 'New campaign brief' },
  { id: 'briefsLive', label: 'Live briefs' }
].map((item) => ({
  ...item,
  selected: activeTab.value === item.id,
  href: ''
}))

// colours for submit button
const isValid = ref('')
const isValidText = ref('')

const validate = (status) => {
  // change submit button colour
  if (status.value.valid) {
    isValid.value = '#08A7DE'
    isValidText.value = '#FFF'
  } else {
    isValid.value = ''
    isValidText.value = ''
  }
}

const briefsNewRef = ref()
const briefsLiveRef = ref()

const prevStage = () => {
  stage.value--
}

let stage = ref(1)

const nextStage = async () => {
  // validate and show any erros
  const valid = briefsNewRef.value.validate(false, true)
  if (valid) {
    // upload screen
    if (stage.value == 2) {
      await briefsNewRef.value.saveFiles()
    }

    // final screen
    if (stage.value == 3) {
      briefsNewRef.value.saveData()
      toast.success('Brief Saved, It might take a few minutes for your brief to show up!', {
        timeout: 5000
      })
    }

    isValid.value = ''
    isValidText.value = ''
    stage.value++
  }
}
</script>

<template>
  <MarketplaceLayout :title="$t('marketplace.briefs.title')" selectedLinkId="briefs">
    <template #header>
      <div class="flex flex-wrap items-start justify-between">
        <PageTitleHeading>
          {{ $t('marketplace.briefs.title') }}
        </PageTitleHeading>
        <div class="hidden sm:block mt-3 text-2xl">
          <StageCount v-if="activeTab == 'briefsNew'" :stage="stage" />
        </div>
      </div>
      <div class="mt-2">
        <TabNavigation :links="tabMenuItems" @selected="selectTab" />
      </div>
    </template>

    <!-- destroy component at end -->
    <LoginRequired :message="$t('marketplace.briefs.restricted.login')">
      <div v-if="stage < 4">
        <div v-show="activeTab == 'briefsNew'">
          <BriefsNew ref="briefsNewRef" @validate="validate" :toast="toast" :stage="stage" />
          <div class="w-full flex flex-wrap my-3 items-center justify-end">
            <div
              v-if="stage > 1"
              @click="prevStage"
              class="click-previous-stage mr-3 font-semi-bold hover:font-bold cursor-pointer text-sm w-20 text-center text-blue-600"
            >
              Previous
            </div>
            <input
              v-if="stage < 3"
              value="NEXT"
              @click="nextStage"
              class="click-next-stage"
              type="submit"
              :class="Styles.submit"
              :style="{
                background: isValid,
                color: isValidText
              }"
            />
            <input
              v-if="stage == 3"
              value="SUBMIT"
              @click="nextStage"
              class="click-next-stage"
              type="button"
              :class="Styles.submit"
              :style="{
                background: isValid,
                color: isValidText
              }"
            />
          </div>
        </div>
        <div v-show="activeTab == 'briefsLive'">
          <BriefsLive ref="briefsLiveRef" />
        </div>
      </div>
      <div v-else-if="activeTab == 'briefsNew'">
        <h1 class="my-3">Saved!</h1>
        <input value="Add new Brief" @click="stage = 1" type="submit" :class="Styles.submit" />
      </div>

      <!--
            <input value='saveData' @click='saveData' type='button' :class="Styles.submit">
            -->
    </LoginRequired>
  </MarketplaceLayout>
</template>

function convertToUnixTimestamp(dateStr: string) {
  if (dateStr) {
    return new Date(dateStr).getTime() / 1000
  }
  return null
}

export function formatSmartBriefData(d: any) {
  // map / process fields that need formatting
  d.interestDeadline = convertToUnixTimestamp(d.interestDeadline)
  d.submissionDeadline = convertToUnixTimestamp(d.submissionDeadline)
  return d
}

export const detectValueType = (value: string): 'UUID' | 'IPFS-CID' | 'Slug' | 'Invalid' => {
  // Check if the value is empty or too long
  if (!value || value.length > 100 || value.length < 5) return 'Invalid'

  // Regex for UUID (covers both v4 and v5)
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  if (uuidRegex.test(value)) return 'UUID'

  // Regex for IPFS CID (covers both v0 and v1)
  const ipfsCidRegex = /^(Qm[1-9A-HJ-NP-Za-km-z]{44}|b[A-Za-z2-7]{58,})$/
  if (ipfsCidRegex.test(value)) return 'IPFS-CID'

  // Regex for valid URL slug
  const slugRegex = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/
  if (slugRegex.test(value)) return 'Slug'

  // If none of the above conditions are met
  return 'Invalid'
}

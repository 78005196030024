<script lang="ts" setup>
import Markdown from '@/components/Markdown.vue'

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  body: {
    type: String,
    default: ''
  },
  class: {
    type: String,
    default: ''
  }
})
</script>

<template>
  <div :class="props.class">
    <h3 class="roboto text-xl font-semibold tracking-wide mb-3" style="text-align: left">
      {{ props.title }}
    </h3>
    <div class="roboto font-medium body-content-columns-div">
      <Markdown :content="props.body" />
    </div>
  </div>
</template>

<style>
.body-content-columns-div {
  columns: 2;
}

@media (max-width: 768px) {
  .body-content-columns-div {
    columns: 1;
  }
}
</style>

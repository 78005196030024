import { defineStore } from 'pinia'

export const useCotoStore = defineStore('coto', {
  state: () => {
    return {
      fx_rate: 1
    }
  },
  actions: {
    //
  }
})

import axios from 'axios'

const web3Token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDQyNjU3YjM5NUE3Q0VhMzlFNjJBRjFFNzI3ZUJDZkY1ZjA1OUU0MzAiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2ODE3MzY0MjYzMTksIm5hbWUiOiJjb250ZW50ZWRtZWRpYS1zdGFnaW5nIn0.3qiwHeYBlQ5xMcMP5rVYQtyNhFFFGBC5s-I0fJHyi14'

export const pinToWebStorage = async (cid: string, name: string) => {
  const data = {
    cid: cid,
    name: name
  }

  try {
    const response = await axios.post('https://api.web3.storage/pins', data, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${web3Token}`,
        'Content-Type': 'application/json'
      }
    })
    console.log(response.data)
  } catch (error) {
    console.error(error)
  }
}
// const response = await uploadToEstuary_v2(
//   UploadRef.value.fileList[0].file,
//   UploadRef.value.fileList[0].file.type,
//   UploadRef.value.fileList[0].file.name
// );
// console.log({response});
export const uploadToEstuary_v2 = async (data: Blob) => {
  const form = new FormData()
  form.append('data', data)

  const response = await axios.post('https://edge.estuary.tech/api/v1/content/add', form, {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ESTa100450c-a004-4386-82fb-ea49058bbd16ARY'
    }
  })
  // response.data.contents[0].cid
  return response.data.contents[0].cid
}

export const uploadToEstuary = async (data: Blob, type: string, filename: string) => {
  const form = new FormData()
  form.append('data', data, filename)

  const response = await axios.post('https://api.estuary.tech/content/add', form, {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer EST66e0c2ab-988f-4391-b6b8-3f06eff339e3ARY'
    }
  })
  return response.data
}

export const pinToEstuary = async (cid: string, name: string) => {
  const response = await axios.post(
    'https://api.estuary.tech/pinning/pins',
    {
      cid: cid,
      meta: {
        additionalProp1: {}
      },
      name: name,
      origins: ['contented']
    },
    {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer EST66e0c2ab-988f-4391-b6b8-3f06eff339e3ARY'
      }
    }
  )
  console.log(response.data)
  return response.data
}

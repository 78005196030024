<script lang="ts" setup>
// @ts-nocheck

import { ref } from 'vue'
import * as zip from '@zip.js/zip.js'
import { decryptSmartBriefImage } from '@/utils/postContent'
import SpinnerOverlay from '@/components/SpinnerOverlay.vue'
import { useUcanStore } from '@/stores/ucan'

const ucanStore = useUcanStore()

const props = defineProps({
  items: {
    type: Object,
    default: () => ({})
  },
  accountId: {
    type: String,
    default: null
  },
  isAccepted: {
    type: Boolean,
    default: false
  },
  brief: Object,
  track: {
    type: String,
    default: ''
  }
})

const processing = ref(false)

function formatDate() {
  const now = new Date()

  const day = String(now.getDate()).padStart(2, '0')
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const year = now.getFullYear()

  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  const seconds = String(now.getSeconds()).padStart(2, '0')

  return `${day}${month}${year}-${hours}${minutes}${seconds}`
}

const trackEvent = (track?: string, accountId?: string, briefId?: string) => {
  try {
    // track the event if we need to
    if (track && track !== '' && typeof window?.gtag === 'function') {
      // build the tracking event object
      const trackingEvent = {} as {
        brief_id?: string
        profile_id?: string
      }
      if (accountId) {
        trackingEvent.profile_id = accountId
      }
      if (briefId) {
        trackingEvent.brief_id = briefId
      }

      window.gtag('event', track, {
        event_category: 'brief',
        ...trackingEvent
      })

      window?.posthog?.capture(track, {
        category: 'brief',
        ...trackingEvent
      })
    }
  } catch (e) {
    console.log('trackEvent error', e)
  }
}

const zipFiles = async (exportname, items, accountId) => {
  const zipWriter = new zip.ZipWriter(new zip.BlobWriter('application/zip'))

  let fileBlobs = {}
  const { s64, msg } = ucanStore.createApiChallenge()
  let counter = 1
  for (const item of items) {
    try {
      // use content key for content owner and brief owner key for brief owner
      let key
      if (accountId === item.owner) {
        key = item.key
      } else if (accountId !== item.owner && props.isAccepted) {
        key = item.keyBriefOwner
      } else {
        key = ''
      }
      fileBlobs[item.id] = await decryptSmartBriefImage(
        accountId === props.brief?.owner || props.isAccepted ? '' : item.id,
        item.briefId,
        accountId === item.owner || props.isAccepted ? item.file_url : item.watermark_file_url,
        key,
        item.iv,
        item.media_type,
        accountId,
        ucanStore.getApiBaseUrl(),
        s64,
        msg,
        /* eslint-disable @typescript-eslint/no-unused-vars */
        (message) => {
          console.log('decryptSmartBriefImage ', message)
        }
      )

      if (!fileBlobs[item.id]) continue

      const fileName = [
        item.name || 'file',
        counter,
        '-',
        item.createdAt || formatDate(),
        '.',
        item.media_type.replace(/(image|video)\//g, '')
      ].join('')

      await zipWriter.add(
        exportname + '/' + (item.creatorName || item.brandName) + '/' + fileName,
        new zip.BlobReader(fileBlobs[item.id])
      )
      counter++
    } catch (error) {
      console.error(`Error processing item with id ${item.id}:`, error)
    } finally {
      if (zipWriter.locked) {
        await zipWriter.close()
      }
    }
  }

  const blob = await zipWriter.close()
  return blob
}

const downloadAll = async () => {
  try {
    processing.value = true

    // track the event if we need to
    trackEvent(props?.track, props?.accountId, props?.brief?.id)

    // create the name of the export
    const exportName = 'contented-' + new Date().toISOString().replace(/:/g, '-')

    // get the zipped images blob
    const zipBlob = await zipFiles(exportName, props?.items, props?.accountId)

    // create download link
    const url = URL.createObjectURL(zipBlob)
    const a = document.createElement('a')
    a.href = url
    a.download = exportName + '.zip'

    // trigger download
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)

    processing.value = false
  } catch (err) {
    processing.value = false
  }
}
</script>

<template>
  <SpinnerOverlay v-if="processing" :opacity="25" />
  <div class="w-28" @click="downloadAll">
    <div class="font-semibold">Download all</div>
    <div class="my-3 text-3xl text-[#FF008B] cursor-pointer hover:opacity-80 text-center">
      <i class="fal fa-download"></i>
    </div>
  </div>
</template>

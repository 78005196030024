<script lang="ts" setup>
import { onBeforeMount } from 'vue'
import { useHead } from '@unhead/vue'
import { useNearWalletStore } from '@/stores/near-wallet'
import TailwindBreakpointSwatch from '@/components/TailwindBreakpointSwatch.vue'
import FontsSelfHosted from '@/components/FontsSelfHosted.vue'

const store = useNearWalletStore()

onBeforeMount(() => {
  store.web3authInit()

  // get the hostname
  const hostname = window?.location?.hostname

  // set the default title and meta
  if (hostname === 'app.contentedworld.com') {
    useHead({
      title: 'Contented World'
    })
  } else {
    useHead({
      title: 'Contented World',
      meta: [
        {
          name: 'robots',
          content: 'noindex'
        }
      ]
    })
  }
})
</script>

<template>
  <TailwindBreakpointSwatch />
  <FontsSelfHosted href="/fontawesome/css/fontawesome.min.css" />
  <FontsSelfHosted href="/fontawesome/css/all.min.css" />

  <div class="mx-auto">
    <RouterView />
  </div>
</template>

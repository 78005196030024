<script lang="ts" setup>
// @ts-nocheck
import { ref, reactive, watch, onMounted } from 'vue'
import { FormKitMessages } from '@formkit/vue'
import SpinnerOverlay from '@/components/SpinnerOverlay.vue'
import { useNearWalletStore } from '@/stores/near-wallet'
import { storeToRefs } from 'pinia'
import CurrencyValue from '@/components/CurrencyValue.vue'
import { useUcanStore } from '@/stores/ucan'
import { getNode } from '@formkit/core'
import { saveSmartBriefPaymentPreference } from '@/queries/api'
import { CloudflareWorkersApi } from '@/queries/cloudflare-workers'

const emit = defineEmits(['complete', 'scrollToError'])

// form related
const loading = ref(false)
const form = ref()
// const confirmDialog = ref<InstanceType<typeof AlertDialog> | undefined>()
const props = withDefaults(
  defineProps<{
    brief: Partial<SmartBrief>
  }>(),
  {
    //
  }
)

const nearWalletStore = useNearWalletStore()
const { accountId, userEmail } = storeToRefs(nearWalletStore)

const ucanStore = useUcanStore()

const platformFeeSpan = ref(null)
const paymentToCreatorSpan = ref(null)
const totalChargesSpan = ref(null)
const couponDiscountSpan = ref(null)
const paymentPerCreatorSpan = ref(null)
const creatorCommissionSpan = ref(null)

const details = reactive({
  budget: props.brief.paymentPreference?.budget
    ? props.brief.paymentPreference?.budget
    : props.brief.budget || 0,
  fee_payer: 'add15', // props.brief.paymentPreference?.payment_options || '',
  number_of_creators: props.brief.paymentPreference?.number_of_creators || 1,
  promo_code: '',
  brief_contact_email: userEmail,
  colleague_contact_email: '',
  colleague_contact_name: props.brief.paymentPreference?.colleague_name || '',
  vat_number: props.brief.paymentPreference?.vat_number || '',
  vat_postcode: props.brief.paymentPreference?.vat_postcode || '',
  coupon_discount: props.brief.paymentPreference?.coupon_discount || 0,
  coupon_type: '',
  currency: props.brief.paymentPreference?.currency || 'USD'
})

onMounted(async () => {
  if (props.brief.paymentPreference) {
    // const { s64, msg } = ucanStore.createApiChallenge()
    let coupon_code: string | undefined = ''
    if (
      props.brief.paymentPreference.promo_code &&
      props.brief.paymentPreference.promo_code !== ''
    ) {
      /* coupon_code = await decryptSmartBriefString(
        props.brief.id,
        props.brief.paymentPreference.promo_code,
        props.brief.paymentPreference.key,
        props.brief.paymentPreference.iv,
        ucanStore.getApiBaseUrl(),
        s64,
        msg,
        (message) => {
          console.log(message)
        }
      ) */
      coupon_code = props.brief.paymentPreference.promo_code
    }
    let email_contact: string | undefined = ''
    if (
      props.brief.paymentPreference.email_contact &&
      props.brief.paymentPreference.email_contact !== ''
    ) {
      /* email_contact = await decryptSmartBriefString(
        props.brief.id,
        props.brief.paymentPreference.email_contact,
        props.brief.paymentPreference.key,
        props.brief.paymentPreference.iv,
        ucanStore.getApiBaseUrl(),
        s64,
        msg,
        (message) => {
          console.log(message)
        }
      ) */
      email_contact = props.brief.paymentPreference.email_contact
    }
    let colleague_email: string | undefined = ''
    if (
      props.brief.paymentPreference.colleague_email &&
      props.brief.paymentPreference.colleague_email !== ''
    ) {
      /* colleague_email = await decryptSmartBriefString(
        props.brief.id,
        props.brief.paymentPreference.colleague_email,
        props.brief.paymentPreference.key,
        props.brief.paymentPreference.iv,
        ucanStore.getApiBaseUrl(),
        s64,
        msg,
        (message) => {
          console.log(message)
        }
      ) */
      colleague_email = props.brief.paymentPreference.colleague_email
    }
    const promo_code_node = getNode('promo_code')
    const brief_contact_email_node = getNode('brief_contact_email')
    const colleague_contact_email_node = getNode('colleague_contact_email')
    promo_code_node?.input(coupon_code)
    brief_contact_email_node?.input(email_contact)
    colleague_contact_email_node?.input(colleague_email)
    details.promo_code = coupon_code
    details.brief_contact_email = email_contact
    details.colleague_contact_email = colleague_email
    if (coupon_code && coupon_code !== '') {
      await applyPromoCode(details.promo_code)
    }
  }
})

const onSubmit = async () => {
  if (!details.brief_contact_email || details.brief_contact_email.trim() === '') {
    console.warn('brief_contact_email is required')
    return false
  }

  if (!details.budget > 0 || details.budget > 20000) {
    console.warn('Budget is required and must be between 1 and 20000')
    return false
  }

  loading.value = true

  try {
    /* 
    // Comment string encryption
    const stringsToEncrypt = [
      details.brief_contact_email,
      details.colleague_contact_email,
      details.promo_code
    ]
    const { encryptedStrings, key, iv } = await encryptSmartBriefStrings(
      stringsToEncrypt,
      (message) => {
        console.log('Callback message:', message)
      }
    )
    */

    let paymentOptions
    let paymentDescription
    if (details.fee_payer) {
      paymentOptions = details.fee_payer
      paymentDescription = 'Add 15% to my existing budget'
    } else {
      paymentOptions = ''
      paymentDescription = 'Take 15% from the budget'
    }

    const dataToSubmit = {
      // key: key,
      // iv: iv,
      email_contact: details.brief_contact_email,
      colleague_email: details.colleague_contact_email,
      colleague_name: details.colleague_contact_name,
      promo_code: details.promo_code,
      platform_fee: platformFeeSpan.value.toString(),
      payment_to_creator: paymentToCreatorSpan.value.toString(),
      total_charges: totalChargesSpan.value.toString(),
      budget: details.budget,
      payment_options: paymentOptions,
      number_of_creators: details.number_of_creators.toString(),
      currency: details.currency,
      vat_number: details.vat_number,
      vat_postcode: details.vat_postcode
    }

    // const ipfsResponse = await pinDataToIPFS(JSON.stringify(dataToSubmit))
    // const cidString = ipfsResponse?.cid ? ipfsResponse.cid.toString() : ''
    // dataToSubmit['cid'] = cidString

    // calling api to update payment preference to db
    await saveSmartBriefPaymentPreference(ucanStore, props.brief.id, dataToSubmit)

    // send email
    const dataToEmail = {
      email_contact: [details.brief_contact_email],
      colleague_email: details.colleague_contact_email,
      colleague_name: details.colleague_contact_name,
      promo_code: details.promo_code,
      platform_fee: platformFeeSpan.value.toString(),
      payment_to_creator: paymentToCreatorSpan.value.toString(),
      total_charges: totalChargesSpan.value.toString(),
      budget: details.budget,
      payment_options: paymentDescription,
      number_of_creators: details.number_of_creators.toString(),
      currency: details.currency,
      vat_number: details.vat_number,
      vat_postcode: details.vat_postcode,
      brief_id: props.brief.id,
      sender_id: accountId.value,
      brief_title: props.brief.title,
      brief_contentTypes: props.brief.contentTypes
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const res = await CloudflareWorkersApi().post('/payment/preferences', dataToEmail)

    // tell the wizard that we're done
    emit('complete')
  } catch (error) {
    console.error('An error occurred:', error)
  } finally {
    loading.value = false
  }
}

const bugdetDisabled = ref(false)

const promoApplied = ref(false)
const applyPromoCode = async (promoCode) => {
  if (promoCode.trim() !== '') {
    // details.coupon_discount = await nearWalletStore.applyCoupon(promoCode)
    const couponDiscount = await nearWalletStore.applyCoupon(promoCode)
    let discount_percentage = 0
    let discount_type = ''
    if (couponDiscount !== null) {
      discount_percentage = couponDiscount[0];
      discount_type = couponDiscount[1];
    }
    details.coupon_discount = discount_percentage
    details.coupon_type = discount_type
    promoApplied.value = true
  }
}
// Watch for changes in the promo code input to reset the state if the input is cleared
watch(
  () => details.promo_code,
  (newVal) => {
    if (newVal === '') {
      promoApplied.value = false
      couponDiscountSpan.value = null
    }
  }
)

/* legacy
const addToBudget = (initialBudget, percent, couponDiscount) => {
  const effectivePlatformFeePercentage = Math.max(percent - couponDiscount, 0)
  const multiplier = 100 / (100 - effectivePlatformFeePercentage)
  const newBudget = initialBudget * multiplier
  return newBudget
}
const splitFee = (initialBudget, couponDiscount) => {
  const platformFeeBrand = initialBudget * 0.1
  const platformFeeCreator = initialBudget * 0.1

  const discountedBrandPlatformFee = Math.max(platformFeeBrand - couponDiscount, 0)
  const totalCharges = initialBudget + discountedBrandPlatformFee
  const paymentToCreator = initialBudget - platformFeeCreator

  return {
    totalCharges,
    paymentToCreator,
    platformFeeBrand: discountedBrandPlatformFee,
    platformFeeCreator
  }
}
*/

const calculateFees = () => {
  let platformFeePercentage = 15
  let creatorCommissionPercentage = 10
  let platformFee = 0
  let paymentToCreator = 0
  let totalCharges = 0
  let paymentPerCreator = 0

  // let splitFeeResult
  const initialBudget = parseFloat(details.budget)
  const numberOfCreators = parseInt(details.number_of_creators)

  // this is always 'add15'
  switch (details.fee_payer) {
    // this is not optional
    case 'add15':
      if (details.coupon_type === 'platform-fee' || details.coupon_type === 'platform-fee|creator-commission' || details.coupon_type === '') {
        totalCharges = (initialBudget + (initialBudget * (platformFeePercentage - details.coupon_discount)) / 100) * numberOfCreators
      } else {
        totalCharges = (initialBudget + (initialBudget * platformFeePercentage) / 100) * numberOfCreators;
      }
      platformFee = totalCharges - initialBudget * numberOfCreators
      if (details.coupon_type === 'creator-commission' || details.coupon_type === 'platform-fee|creator-commission') {
        creatorCommissionPercentage = Math.max(0, 10 - details.coupon_discount)
        paymentToCreator = initialBudget * numberOfCreators * ((100 - creatorCommissionPercentage) / 100)
        paymentPerCreator = initialBudget * ((100 - creatorCommissionPercentage) / 100)
      } else {
        paymentToCreator = initialBudget * numberOfCreators * ((100 - creatorCommissionPercentage) / 100)
        paymentPerCreator = initialBudget * ((100 - creatorCommissionPercentage) / 100)
      }
      /* totalCharges =
        (initialBudget + (initialBudget * (15 - details.coupon_discount)) / 100) * numberOfCreators
      platformFee = totalCharges - initialBudget * numberOfCreators
      paymentToCreator = initialBudget * numberOfCreators * 0.9
      paymentPerCreator = initialBudget * 0.9 */
      break
    default:
      console.error('Unknown fee_payer:', details.fee_payer)
      break
    /* legacy options
    case 'split10':
      splitFeeResult = splitFee(initialBudget, details.coupon_discount)
      totalCharges = splitFeeResult.totalCharges * numberOfCreators
      platformFee = splitFeeResult.platformFeeBrand * numberOfCreators
      paymentToCreator = splitFeeResult.paymentToCreator
      break
    case 'deduct20':
      totalPlatformFee = initialBudget * 0.2
      platformFee = 0
      paymentToCreator = initialBudget - totalPlatformFee
      totalCharges = initialBudget * numberOfCreators
      break
    */
  }

  couponDiscountSpan.value = details.coupon_discount
  platformFeeSpan.value = `${platformFee.toFixed(2)}`
  paymentToCreatorSpan.value = `${paymentToCreator.toFixed(2)}`
  totalChargesSpan.value = `${totalCharges.toFixed(2)}`
  paymentPerCreatorSpan.value = `${paymentPerCreator.toFixed(2)}`
  creatorCommissionSpan.value = `${creatorCommissionPercentage}`
}

const setCreators = (value: number = 0) => {
  const v = parseInt(details.number_of_creators)
  if (isNaN(v)) {
    details.number_of_creators = 1
    return
  }
  details.number_of_creators = v + value
  if (details.number_of_creators < 1) {
    details.number_of_creators = 1
  }
  if (details.number_of_creators < 1) {
    details.number_of_creators = 1
  }
  if (details.number_of_creators > 10) {
    details.number_of_creators = 10
  }
}

watch(() => details.fee_payer, calculateFees)
watch(() => details.budget, calculateFees)
watch(() => details.number_of_creators, calculateFees)
watch(() => details.coupon_discount, calculateFees)
watch(() => details.coupon_type, calculateFees)
calculateFees()

watch(
  () => details.brief_contact_email,
  (newVal) => {
    console.log('brief_contact_email changed to: ', newVal)
  }
)
</script>

<style>
table.fees {
  margin-left: -10px;
  border-spacing: 10px;
  border-collapse: separate;
}
table.fees th {
  border: 1px solid #f2f3f4;
  border-radius: 5px;
  background: #f7f8fc;
}
table.fees th,
td {
  padding: 10px;
  height: 54px;
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>

<template>
  <SpinnerOverlay v-if="loading" />
  <div class="w-full">
    <FormKit
      type="form"
      ref="form"
      :actions="false"
      id="brief-project-payment-form"
      method="post"
      @submit="onSubmit"
    >
      <div class="my-6 text-sm text-red-600 hidden" v-if="form?.node">
        <FormKitMessages :node="form.node" />
      </div>

      <div class="mb-6">
        <div class="mb-3 md:my-3">
          <h2 class="font-bold text-lg">Payment Setup</h2>
        </div>

        <div class="my-3">
          <p class="text-sm">
            Before you can publish your brief, please save your payment preferences.
          </p>
        </div>

        <FormKit
          type="text"
          name="budget"
          label="Budget (USD):"
          validation="required|min:1|max:20000"
          help="This is the amount you want to pay each approved creator, between $1 and $20,000."
          help-class="help"
          inner-class="w-full "
          label-class="bold-label"
          v-model="details.budget"
          :disabled="bugdetDisabled"
        />

        <!-- this is not optional any more
        <FormKit
          type="checkbox"
          name="fee_payer"
          :options="paymentOptionDescriptions"
          legend-class="bold-label"
          v-model="details.fee_payer"
          :value="true"
          validation="required"
          :disabled="true"
        />
        -->

        <!-- number of creators -->
        <div class="my-6 font-bold text-center">How many creators do you want to hire?</div>
        <div class="flex fa-2x justify-center">
          <div class="mt-3 text-gray-300 cursor-pointer" @click="setCreators(-1)">
            <i class="fa-solid fa-circle-minus"></i>
          </div>
          <div class="mx-6 justify-center">
            <FormKit
              @blur="setCreators()"
              type="text"
              name="number_of_creators"
              id="number_of_creators"
              wrapper-class="number-of-creators"
              label-class="bold-label"
              v-model="details.number_of_creators"
            />
          </div>
          <div class="mt-3 text-gray-300 cursor-pointer" @click="setCreators(1)">
            <i class="fa-solid fa-circle-plus"></i>
          </div>
        </div>

        <div class="my-6 font-bold text-center">
          After deduction of {{ creatorCommissionSpan }}% commission fee for Contented the creator will receive:
          <div class="text-xl mt-6">
            <CurrencyValue :value="parseFloat(paymentPerCreatorSpan)" />
          </div>
        </div>

        <div class="flex">
          <FormKit
            type="text"
            name="promo_code"
            id="promo_code"
            label="Apply promo code (if you have one):"
            label-class="bold-label"
            v-model="details.promo_code"
          />
          <div class="mt-[37px] ml-3 select-none">
            <div class="action-button hollow" @click.prevent="applyPromoCode(details.promo_code)">
              Apply
            </div>
          </div>
        </div>

        <FormKit type="hidden" name="fee_payer" value="add15" v-model="details.fee_payer" />

        <div>
          <p class="help" v-if="couponDiscountSpan > 0">The promo code was applied.</p>
          <p class="help" v-if="promoApplied && !couponDiscountSpan">
            The code applied is no longer valid.
          </p>
          <p class="help" v-if="!promoApplied && !couponDiscountSpan">Please enter a promo code.</p>

          <table class="fees mt-3">
            <tr>
              <th>
                <CurrencyValue :value="parseFloat(platformFeeSpan)" />
              </th>
              <td>
                <span class="font-bold text-sm">Platform fee</span>
                <p class="help">The 15% fee you pay to Contented</p>
              </td>
            </tr>
            <tr>
              <th>
                <CurrencyValue :value="parseFloat(totalChargesSpan)" />
              </th>
              <td>
                <span class="font-bold">Total charges</span>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="mb-6">
        <FormKit
          type="text"
          name="brief_contact_email"
          id="brief_contact_email"
          label="Brief contact email address:"
          validation="required|email"
          label-class="bold-label"
          v-model="details.brief_contact_email"
        />

        <div class="my-3 mb-1">
          <p class="text-sm">
            Are the contact details above correct for invoicing? If you'd like to add a preferred
            colleague please add below:
          </p>
        </div>

        <FormKit
          type="text"
          label="Name:"
          label-class="w-1/4 bold-label"
          inner-class="w-3/4"
          name="colleague_contact_name"
          id="colleague_contact_name"
          v-model="details.colleague_contact_name"
        />

        <FormKit
          type="text"
          label="Email:"
          validation="email"
          label-class="w-1/4 bold-label"
          inner-class="w-3/4"
          name="colleague_contact_email"
          id="colleague_contact_email"
          v-model="details.colleague_contact_email"
        />

        <FormKit
          type="select"
          label="Currency:"
          :options="['USD', 'GBP', 'EUR']"
          validation="required"
          label-class="bold-label"
          help-class="help"
          inner-class="w-full max-w-60"
          input-class="form-select"
          name="currency"
          v-model="details.currency"
        />

        <p class="help">
          Contented operate in $USD as standard. Please tell us if you need invoicing in an
          alternative currency.
        </p>

        <div class="flex items-center justify-between" v-if="details.currency === 'GBP'">
          <FormKit
            type="text"
            label="VAT Number:"
            validation="required"
            outer-class="max-w-60 mr-1"
            label-class="bold-label"
            name="vat_number"
            v-model="details.vat_number"
          />
          <FormKit
            type="text"
            label="Postcode:"
            validation="required"
            outer-class="max-w-60 ml-1"
            label-class="bold-label"
            name="vat_postcode"
            v-model="details.vat_postcode"
          />
        </div>
      </div>

      <div class="flex my-12 justify-center">
        <input
          type="submit"
          class="action-button solid"
          value="Next"
          @click="emit('scrollToError')"
        />
      </div>
    </FormKit>
  </div>
</template>

<style lang="scss">
#brief-project-payment-form {
  .bold-label {
    @apply font-bold mb-1 text-sm;
  }
  .help {
    @apply text-gray-500 text-xs mt-1;
  }
  .formkit-outer {
    @apply w-full my-3 py-1;
    .formkit-label {
      @apply text-sm select-none;
      white-space: nowrap;
    }
    .formkit-inner {
      input[type='text'].formkit-input,
      input[type='number'].formkit-input,
      select.formkit-input {
        @apply rounded-md text-sm border-gray-300 w-full my-1 placeholder-gray-300;
      }
      input[type='radio'].formkit-input {
        @apply rounded-full text-sm border-gray-300 w-5 h-5 my-1 placeholder-gray-300 mr-2;
      }
    }
    .formkit-messages {
      .formkit-message {
        @apply mt-1 text-xs text-red-600;
      }
    }
  }
  .number-of-creators {
    .formkit-prefix-icon {
      @apply mr-3;
    }
    .formkit-suffix-icon {
      @apply ml-3;
    }
    .formkit-inner {
      @apply flex p-1 items-center w-14;
      input[type='text'].formkit-input {
        @apply w-14 p-0 m-0;
        @apply text-center font-bold text-xl;
      }
    }
  }
}
</style>

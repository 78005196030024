import '@near-wallet-selector/modal-ui/styles.css'
import { getED25519Key } from '@toruslabs/openlogin-ed25519'
import { defineStore } from 'pinia'
import { Base64 } from 'js-base64'
import {
  connect,
  Contract,
  keyStores,
  KeyPair,
  Account,
  transactions,
  utils,
  type Near
} from 'near-api-js'
import { Web3Auth } from '@web3auth/modal'
import { OpenloginAdapter, type OpenloginUserInfo } from '@web3auth/openlogin-adapter'
import useLazyPiniaStore from '@/utils/lazyPiniaStore'
import { lazyUcanStore as ucanStore } from '@/stores/ucan'
import getConfig from '@/config/config'
import { hashValue } from '@/utils/hasher'
import type { IProvider } from '@web3auth/base'
import type { AccountBalance } from 'near-api-js/lib/account'
import axios from 'axios'
import { CommonPrivateKeyProvider } from '@web3auth/base-provider'
import { WEB3AUTH_NETWORK } from '@web3auth/base'
import { useMemoize } from '@vueuse/core'
// import { useRouter } from 'vue-router'

// const $router = useRouter()

const isTestMode = getConfig().TEST_MODE

const networkId = getConfig().nearConfig?.networkId

export const API_RESULT_NO_NEAR = -1
export const API_RESULT_SUCCESS = 1
export const API_RESULT_FAIL = 0

let web3auth: Web3Auth
let web3authProvider: IProvider | null
let web3authAccount: Account
let web3authNearAccountId: string | null
let web3authKeyPair: KeyPair
let web3authPublicAddress: string
let web3authUserEmail: string | undefined

let usdcAmountCall: any
let smartBriefContract: any

function isIOS() {
  try {
    return /iphone|ipad|ipod/i.test(window.navigator.userAgent)
  } catch (err) {
    return false
  }
}

function isSafari() {
  try {
    return /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent)
  } catch (err) {
    return false
  }
}

function isAndroid() {
  try {
    return /android/i.test(window.navigator.userAgent)
  } catch (err) {
    return false
  }
}

// get token for making API calls

const setApiToken = async (account: Account) => {
  if (isTestMode) {
    return getConfig().TEST_API_TOKEN
  }

  const accountId = account.accountId
  const networkId = account.connection.networkId

  // console.debug('setApiToken: accountId',accountId);
  // console.debug('setApiToken: networkId',networkId);

  const arr = new Array(accountId.length)
  for (let i = 0; i < accountId.length; i++) {
    arr[i] = accountId.charCodeAt(i)
  }

  // console.log(account.connection.signer);

  try {
    const msgBuf = new Uint8Array(arr)
    const signedMsg = await account.connection.signer.signMessage(msgBuf, accountId, networkId)
    const pubKey = Buffer.from(signedMsg.publicKey.data).toString('hex')
    const signature = Buffer.from(signedMsg.signature).toString('hex')
    const payload = [accountId, pubKey, signature]
    const token = Base64.encode(payload.join('&'))
    // console.debug('setApiToken : token',token);
    return token
  } catch (error) {
    console.error('setApiToken : ', error)
  }
}

// Initialize contract & set global variables

const initContract = async (groConnection: Near) => {
  const web3Contracts = getConfig().contracts as Record<string, string>

  const groAccount = await new Account(groConnection.connection, web3Contracts.groContract)

  /* await new Contract(groAccount, web3Contracts.auditContract, {
    viewMethods: [],
    changeMethods: ['add_audit_entry', 'add_audit_event'],
    useLocalViewExecution: false
  })

  await new Contract(groAccount, web3Contracts.bidContract, {
    viewMethods: [],
    changeMethods: ['gen_tx'],
    useLocalViewExecution: false
  }) */

  usdcAmountCall = await new Contract(groAccount, web3Contracts.usdcContract, {
    viewMethods: ['ft_balance_of', 'check_registration'],
    changeMethods: ['register_account', 'ft_transfer', 'ft_transfer_call'],
    useLocalViewExecution: false
  })

  /* await new Contract(groAccount, web3Contracts.mainContract, {
    viewMethods: [],
    changeMethods: [
      'add_to_subgraph',
      'delete_from_subgraph',
      'update_content_media',
      'update_content_media_bulk',
      'add_to_subgraph_bulk',
      'add_smart_brief',
      'update_smart_brief',
      'delete_smart_brief',
      'add_brief_comment',
      'add_brief_comment_v2',
      'accept_nda',
      'accept_nda_v2',
      'submit_nda',
      'submit_nda_v2',
      'submit_smart_content',
      'submit_smart_content_v2',
      'publish_brief',
      'un_publish_brief',
      'accept_profile_brief',
      'accept_profile_brief_v2',
      'candidate_profile_status',
      'update_profile_data',
      'save_payment_preferences'
    ],
    useLocalViewExecution: false
  }) */

  smartBriefContract = await new Contract(groAccount, web3Contracts.briefContract, {
    viewMethods: ['verify_coupon'],
    changeMethods: ['nft_mint'],
    useLocalViewExecution: false
  })
}

export const useNearWalletStore = defineStore('near-wallet', {
  state: () => {
    return {
      inited: false,
      walletReady: false,
      loggedIn: false,
      accountId: '',
      userEmail: '',
      user: null,
      userProfile: undefined,
      apiToken: '',
      balance: {
        yoctoNEAR: 0,
        NEAR: 0,
        USD: 0,
        COTO: 0,
        USDT: 0,
        USDC: 0
      }
    } as {
      inited: boolean
      walletReady: boolean
      loggedIn: boolean
      accountId: string
      userEmail: string
      user: Partial<OpenloginUserInfo> | null
      userProfile: ProfileData | undefined
      apiToken: string
      balance: {
        yoctoNEAR: number
        NEAR: number
        USD: number
        COTO: number
        USDT: number
        USDC: number
      }
    }
  },
  actions: {
    getApiToken() {
      return this.apiToken
    },
    doLogin() {
      if (isTestMode) {
        this.loggedIn = true
        return
      }

      this.web3authLogin()
      return
    },
    checkNearAmount(minAmount?: number | undefined) {
      const nearAmount = this.balance.NEAR
      console.log('checkNearAmount', this.balance)
      const minNearAmount = minAmount ?? 0.1
      if (nearAmount < minNearAmount) {
        const msg = `checkNearAmount: Not enough NEAR (${nearAmount}) to make a transaction`
        console.error(msg)
        window.error_message = msg
        return false
      } else {
        console.info(`checkNearAmount: Near balance (${nearAmount})`)
      }
      return true
    },
    async doLogout() {
      if (isTestMode) {
        this.loggedIn = false
        return
      }

      this.web3authLogout()

      window?.posthog?.reset()

      return
    },
    async ftLicenceTransferCall(
      content_owner: any,
      content_id: any,
      value: string | number,
      timestamp: any,
      hasBrief: { content_usages: any }
    ) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }

      if (isTestMode) {
        console.warn('ftLicenceTransferCall : No wallet selected, no transaction done')
        return API_RESULT_FAIL
      }

      // test
      value = 0.5

      const cotoValue = ~~(parseFloat(value.toString()) * 1000 + 0.5)
      let msg
      if (hasBrief) {
        const msgPrefix = `buy#${content_owner}:${content_id}:0:0:432000#bpch:`
        let channels = ''
        for (const channel of hasBrief.content_usages) {
          channels += `+${channel.type}`
        }
        // for brief per ch: #buy#creator.testnet:85d491b3-18f8-40f6-be33-b83dd749a8a4:123367777:0:432000#bpch:tiktok
        msg = `${msgPrefix}${channels}` // (this is for non brief)
      } else {
        // for non brief: buy#creator.testnet:85d491b3-18f8-40f6-be33-b83dd749a8a4:123367777:0:432000#nb:60.5#
        msg = `buy#${content_owner}:${content_id}:0:0:432000#nb` // (this is for non brief)
      }

      // console.info('window.web3authAccountId',web3authAccountId);
      // window.web3authAccountId = web3authAccountId;

      // console.info('window.web3authNearAccountId set',web3authNearAccountId);
      // window.web3authNearAccountId = web3authNearAccountId;

      const args = {
        receiver_id: getConfig().contracts.bidContract,
        msg,
        amount: cotoValue.toString()
      }

      // @ts-ignore
      const data = {
        receiverId: getConfig().contracts.bidContract,
        actions: [
          transactions.functionCall(
            'gen_tx',
            Buffer.from(JSON.stringify(args)),
            // @ts-ignore
            300000000000000,
            utils.format.parseNearAmount('0.14')
          )
        ]
      }

      console.log('licencing...')
      const licence = await web3authAccount.signAndSendTransaction(data)
      console.log('licenced', licence)
      return API_RESULT_SUCCESS
    },
    async postContent(cid: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        contents: [cid],
        marketing_briefs: []
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('add_to_subgraph', args, 300000000000000, 0)]
      }
      const result = await web3authAccount.signAndSendTransaction(data)
      console.log('postContent', result)
      return API_RESULT_SUCCESS
    },
    async deleteContent(id: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        content_id: id
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('delete_from_subgraph', args, 300000000000000, 0)]
      }
      const result = await web3authAccount.signAndSendTransaction(data)
      console.log('deleteContent', result)
      return API_RESULT_SUCCESS
    },
    async postContentBulk(cid: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        cid
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('add_to_subgraph_bulk', args, 300000000000000, 0)]
      }
      const result = await web3authAccount.signAndSendTransaction(data)
      console.log('postContentBulk', result)
      return API_RESULT_SUCCESS
    },
    async updateContentMedia(input: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        content_id: input.content_id,
        media_original: input.media_original,
        iv: input.iv,
        key: input.key
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('update_content_media', args, 300000000000000, 0)]
      }
      const result = await web3authAccount.signAndSendTransaction(data)
      console.log('result', result)
      return API_RESULT_SUCCESS
    },
    async updateContentMediaBulk(cid: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        cid
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('update_content_media_bulk', args, 300000000000000, 0)]
      }
      const result = await web3authAccount.signAndSendTransaction(data)
      console.log('result', result)
      return API_RESULT_SUCCESS
    },
    async addBriefComment(cid: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        cid
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('add_brief_comment_v2', args, 300000000000000, 0)]
      }
      const result = await web3authAccount.signAndSendTransaction(data)
      console.log('result', result)
      return API_RESULT_SUCCESS
    },
    async updateProfile(cid: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        cid
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('update_profile_data', args, 300000000000000, 0)]
      }
      const result = await web3authAccount.signAndSendTransaction(data)
      console.log('result', result)
      return API_RESULT_SUCCESS
    },
    async addSmartBriefs(cid: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        cid
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('add_smart_brief', args, 300000000000000, 0)]
      }
      const result = await web3authAccount.signAndSendTransaction(data)
      console.log('addSmartBriefs', result)
      return API_RESULT_SUCCESS
    },
    async deleteSmartBriefs(brief_id: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        brief_id
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('delete_smart_brief', args, 300000000000000, 0)]
      }
      const result = await web3authAccount.signAndSendTransaction(data)
      console.log('deleteSmartBriefs', result)
      return API_RESULT_SUCCESS
    },
    async updateSmartBriefs(cid: any, brief_id: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        cid,
        brief_id
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('update_smart_brief', args, 300000000000000, 0)]
      }
      const result = await web3authAccount.signAndSendTransaction(data)
      console.log('result', result)
      return API_RESULT_SUCCESS
    },
    async submitBriefNDAV2(cid: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        cid
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('submit_nda_v2', args, 300000000000000, 0)]
      }
      const result = await web3authAccount.signAndSendTransaction(data)
      console.log('submitBriefNDAV2', result)
      return API_RESULT_SUCCESS
    },
    async acceptNDAV2(cid: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        cid
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('accept_nda_v2', args, 300000000000000, 0)]
      }
      const result = await web3authAccount.signAndSendTransaction(data)
      console.log('acceptNDAV2', result)
      return API_RESULT_SUCCESS
    },
    async submitContentV2(cid: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        cid
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('submit_smart_content_v2', args, 300000000000000, 0)]
      }
      const result = await web3authAccount.signAndSendTransaction(data)
      console.log('submitContentV2', result)
      return API_RESULT_SUCCESS
    },
    async publishBrief(brief_id: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        brief_id
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('publish_brief', args, 300000000000000, 0)]
      }
      const result = await web3authAccount.signAndSendTransaction(data)
      console.log('publishBrief', result)
      return API_RESULT_SUCCESS
    },
    async candidateProfileStatus(cid: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        cid
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('candidate_profile_status', args, 300000000000000, 0)]
      }
      const result = await web3authAccount.signAndSendTransaction(data)
      console.log('candidateProfileStatus', result)
      return API_RESULT_SUCCESS
    },
    async unPublishBrief(brief_id: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        brief_id
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('un_publish_brief', args, 300000000000000, 0)]
      }
      const result = await web3authAccount.signAndSendTransaction(data)
      console.log('unPublishBrief', result)
      return API_RESULT_SUCCESS
    },
    async acceptProfileBriefV2(cid: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        cid
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('accept_profile_brief_v2', args, 300000000000000, 0)]
      }
      const result = await web3authAccount.signAndSendTransaction(data)
      console.log('acceptProfileBriefV2', result)
      return API_RESULT_SUCCESS
    },
    async archiveBrief(cid: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        cid
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('archive_smart_brief', args, 300000000000000, 0)]
      }
      const result = await web3authAccount.signAndSendTransaction(data)
      console.log('archiveBrief', result)
      return API_RESULT_SUCCESS
    },
    async checkNearAccount() {
      // function to check if an account exists
      const viewAccount = useMemoize(async (obj: { accountId: string }) => {
        try {
          // call the RPC
          const resp = await fetch(getConfig().nearConfig.nodeUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              jsonrpc: '2.0',
              id: 'dontcare',
              method: 'query',
              params: {
                request_type: 'view_account',
                finality: 'final',
                account_id: obj.accountId
              }
            })
          })

          return (await resp.json()) as {
            jsonrpc: string
            result?: {
              amount: string
              locked: string
              code_hash: string
              storage_usage: number
              storage_paid_at: number
              block_height: number
              block_hash: string
            }
            error?: {
              name: string
              cause: {
                info: Record<string, unknown>
                name: string
              }
              code: number
              data: string
              message: string
            }
            id: string
          }
        } catch (err) {
          throw err
        }
      })

      // exit early if we don't have an account id
      if (!web3authNearAccountId) {
        return {
          data: {
            success: false
          }
        }
      }

      // get the nearblocks url
      const nearBlocksUrl =
        getConfig().nearConfig.networkId === 'testnet'
          ? 'https://testnet.nearblocks.io/en'
          : 'https://nearblocks.io/en'

      // check if the account exists
      const accountExists = await viewAccount({ accountId: web3authNearAccountId })
      if (accountExists.result) {
        return {
          data: {
            success: true,
            nearblocks: `${nearBlocksUrl}/address/${web3authNearAccountId}`
          }
        }
      }

      // check near account exists
      const url =
        document.location.protocol +
        '//' +
        getConfig().nearAccountApiHost +
        '/near/account/' +
        web3authNearAccountId +
        '/' +
        web3authPublicAddress

      return await axios({
        method: 'get',
        url: url
      })
    },
    async web3authInit() {
      // Initialize within your constructor
      const privateKeyProvider = new CommonPrivateKeyProvider({
        config: {
          chainConfig: {
            chainId: '0x4e454153',
            chainNamespace: 'other', // for all non EVM and SOLANA chains, use "other"
            rpcTarget: getConfig().nearConfig.nodeUrl,
            displayName: 'Near',
            blockExplorerUrl: getConfig().nearConfig.explorerUrl,
            ticker: 'NEAR',
            tickerName: 'NEAR'
          }
        }
      })

      const web3AuthNetworkType =
        WEB3AUTH_NETWORK[(networkId || '').toUpperCase() as keyof typeof WEB3AUTH_NETWORK]

      // Initialize within your constructor
      web3auth = new Web3Auth({
        clientId: getConfig().nearConfig.web3AuthClientId, // get it from Web3Auth Dashboard
        web3AuthNetwork: web3AuthNetworkType, // "testnet" or "mainnet, "cyan", "aqua"
        privateKeyProvider: privateKeyProvider
      })

      if (isIOS() || isSafari() || isAndroid()) {
        const web3RedirectUrl = localStorage.getItem('web3RedirectUrl') || window.location.href
        const openloginAdapter = new OpenloginAdapter({
          adapterSettings: {
            uxMode: 'redirect', // "redirect" | "popup"
            replaceUrlOnRedirect: false,
            redirectUrl: web3RedirectUrl
          }
        })
        web3auth.configureAdapter(openloginAdapter)
      }

      await web3auth.initModal({
        modalConfig: {
          openlogin: {
            label: 'openlogin',
            loginMethods: {
              discord: {
                name: 'discord',
                showOnModal: false
              },
              github: {
                name: 'github',
                showOnModal: false
              },
              kakao: {
                name: 'kakao',
                showOnModal: false
              },
              line: {
                name: 'line',
                showOnModal: false
              },
              linkedin: {
                name: 'linkedin',
                showOnModal: false
              },
              reddit: {
                name: 'reddit',
                showOnModal: false
              },
              sms_passwordless: {
                name: 'sms_passwordless',
                showOnModal: false
              },
              twitch: {
                name: 'twitch',
                showOnModal: false
              },
              wechat: {
                name: 'wechat',
                showOnModal: false
              },
              weibo: {
                name: 'weibo',
                showOnModal: false
              }
            }
          }
        }
      })

      this.$patch({
        inited: true
      })

      // check if we are already logged in
      this.web3authUser()
    },
    async web3authLogin(
      opts: {
        errorCallback?: (e: any) => void
      } = {}
    ) {
      // console.info('web3authLogin...');

      if (this.loggedIn) {
        console.error('web3authLogin : Already logged in')
        return
      }

      await this.web3authUser()

      // show dialog, or re-connect to existing session
      if (!this.loggedIn) {
        try {
          // Update redirect URL and Init again
          if (isIOS() || isSafari() || isAndroid()) {
            const currentUrl = window.location.href
            localStorage.setItem('web3RedirectUrl', currentUrl)
            await this.web3authInit()
          }
          web3authProvider = await web3auth.connect()
          await this.web3authUser()
        } catch (e) {
          console.error('web3authLogin : Error connecting', (e as any).message)
          if (opts && typeof opts === 'object' && typeof opts?.errorCallback === 'function') {
            opts.errorCallback(e)
          }
        }
      }
    },
    async web3authUser() {
      // console.info('web3authUser...');

      if (!web3auth) {
        console.info('web3authUser : w3a not inited yet')
        return
      }

      try {
        const user = await web3auth.getUserInfo()
        this.$patch({
          loggedIn: true,
          user: user
        })
      } catch (error: any) {
        // wallet is not connected
        console.log('web3authUser FAIL :', error.code, error.message)
        if (error.code == 5113) {
          this.loggedIn = false
          this.$patch({
            loggedIn: false,
            user: null
          })
        } else {
          console.error('web3authUser : ', error)
        }
      }

      if (this.loggedIn) {
        // show dialog, or re-connect to existing session
        web3authProvider = await web3auth.connect()
        // await this.web3authTest();
        await this.web3authKeyPair()
        await this.web3authBalance()
      }

      // let components know the loggedIn value is now accurate
      this.$patch({
        walletReady: true
      })
    },
    async web3authKeyPair() {
      // get the provider, this could be either in openlogin or as set web3authprovider
      const keyProvider =
        (web3auth?.walletAdapters?.openlogin as any)?.privateKeyProvider?.provider ||
        web3authProvider

      // web3authProvider is web3auth.provider from above
      const privateKey = await keyProvider?.request({ method: 'private_key' })

      // Convert the secp256k1 key to ed25519 key
      const privateKeyEd25519 = getED25519Key(privateKey).sk.toString('hex')

      // Convert the private key to Buffer
      const privateKeyEd25519Buffer = Buffer.from(privateKeyEd25519, 'hex')

      // Convert the private key to base58
      const bs58encode = utils.serialize.base_encode(privateKeyEd25519Buffer)

      // Convert the base58 private key to KeyPair
      web3authKeyPair = KeyPair.fromString(bs58encode)

      // publicAddress
      web3authPublicAddress = web3authKeyPair?.getPublicKey().toString()

      // set the networkId and the privateKey
      ucanStore.setNetworkId(networkId)
      ucanStore.setSecp256k1PrivateKey(privateKey)
    },
    async web3authBalance() {
      // make near account ID
      let suffix = getConfig().nearConfig.networkId
      /* if (suffix == 'mainnet') {
        suffix = 'near'
      } */
      if (suffix == 'mainnet') {
        suffix = '-ct.near'
      } else {
        suffix = '.testnet'
      }

      const user = await web3auth.getUserInfo()
      web3authUserEmail = user.email

      if (user.email) {
        // web3authNearAccountId = (await hashValue(user.email)) + '-cmg.' + suffix
        web3authNearAccountId = (await hashValue(user.email, 'sha3_224')) + suffix
        console.info('web3authNearAccountId', web3authNearAccountId, 'email', web3authUserEmail)
      } else {
        web3authNearAccountId = ''
      }

      // set the namedWalletId
      ucanStore.setNearNamedWalletId(web3authNearAccountId)

      const myKeyStore = new keyStores.InMemoryKeyStore()

      // add the required keys for the gro / main contract and the logged in user
      /* const keyPair = KeyPair.fromString(getConfig().keys.groContract)
      await myKeyStore.setKey(
        getConfig().nearConfig.networkId,
        getConfig().contracts.groContract,
        keyPair
      ) */

      await myKeyStore.setKey(
        getConfig().nearConfig.networkId,
        web3authNearAccountId,
        web3authKeyPair
      )

      // gro connection
      const groConnection = await connect({
        ...getConfig().nearConfig
      })
      // groConnection.connection.signer.keyStore = myKeyStore

      // web3 connection
      const web3authNearConnection = await connect({
        ...getConfig().nearConfig,
        keyStore: myKeyStore
      })
      // web3authNearConnection.connection.signer.keyStore = myKeyStore

      web3authAccount = await web3authNearConnection.account(web3authNearAccountId)

      // this exposes private keys!!
      /*
			window.web3authKeyPair = web3authKeyPair;
			console.warn('window.web3authKeyPair set!');

			window.web3authAccount = web3authAccount;
			console.warn('window.web3authAccount set!');

			window.groConnection = groConnection;
			console.warn('window.groConnection set!');

			window.web3authNearConnection = web3authNearConnection;
			console.warn('window.web3authNearConnection set!');
			*/

      // set api token
      const token = await setApiToken(web3authAccount)

      // patch in what we have
      this.$patch({
        accountId: web3authNearAccountId,
        userEmail: web3authUserEmail,
        apiToken: token,
        loggedIn: true,
        balance: {
          yoctoNEAR: 0,
          NEAR: 0,
          USD: 0,
          COTO: 0,
          USDT: 0,
          USDC: 0
        }
      })

      // init contracts
      await initContract(groConnection)

      let USDC = await usdcAmountCall.ft_balance_of({ account_id: web3authNearAccountId })
      USDC = parseFloat(USDC) / 1000000

      try {
        const exists = await this.checkNearAccount()
        if (exists.data.success == true) {
          console.info('checkNearAccount : OK :', exists.data.nearblocks)
        } else {
          console.error('checkNearAccount : FAIL :', exists.data)
        }
      } catch (error) {
        console.error('web3authBalance : ', error)
      }

      let accountBalance: AccountBalance

      try {
        accountBalance = await web3authAccount.getAccountBalance()
        const availableBalance = utils.format.formatNearAmount(accountBalance.available)

        if (!availableBalance) {
          this.$patch({
            accountId: web3authNearAccountId,
            userEmail: web3authUserEmail,
            apiToken: token,
            balance: {
              yoctoNEAR: 0.0,
              NEAR: 0.0,
              USDC: USDC
            }
          })
          const balance = {
            yoctoNEAR: 0.0,
            NEAR: 0.0,
            USDC: USDC
          }
          return balance
        } else {
          const balance = {
            yoctoNEAR: parseFloat(accountBalance.total) || 0.0,
            NEAR: parseFloat(availableBalance) || 0.0,
            USDC: USDC
          }
          this.$patch({
            accountId: web3authNearAccountId,
            userEmail: web3authUserEmail,
            apiToken: token,
            balance: balance
          })
          console.log('balance', balance)
          return balance
        }
      } catch (error) {
        console.info('web3authBalance : no balance found on USDC contract', error)
      }
    },
    async web3authLogout() {
      if (!this.loggedIn) {
        console.error('web3authLogin : Not logged in')
        return
      }
      this.loggedIn = false
      await web3auth.logout()
      this.$patch({
        accountId: '',
        userEmail: '',
        apiToken: '',
        loggedIn: false
      })

      // reset the data
      ucanStore.resetData()

      // redirect to root path (homepage)
      window.location.href = 'https://contentedworld.com/'
    },
    async smartBriefNftMint(
      brief_id: any,
      brief_owner: any,
      brief_title: any,
      budget: number,
      account_id: any,
      aggregatedDataString: string,
      cid: any,
      coupon_code: any
    ) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const usdcContract = getConfig().contracts.usdcContract
      const contentContract = getConfig().contracts.mainContract

      const contents = JSON.parse(aggregatedDataString)
      const msgParts = contents.map((content: { contentId: any; contentFileUrl: any }) => {
        return `content_id:${content.contentId}#content_file_url:${getConfig().ipfsHostURL}${content.contentFileUrl}`
      })
      const content_str = msgParts.join(';')

      const args = {
        receiver_id: getConfig().contracts.briefContract,
        msg: `content_contract:${contentContract}#cid:${cid}#usdc_contract:${usdcContract}#brief_id:${brief_id}#brief_owner:${brief_owner}#brief_title:${brief_title.replace(/#/g, '')}#account_id:${account_id}#coupon_code:${coupon_code}#content_str:${content_str}`,
        amount: (budget * 1000000).toString()
      }
      console.log(args)
      const data = {
        receiverId: usdcContract,
        actions: [
          // @ts-ignore
          transactions.functionCall('ft_transfer_call', args, 300000000000000, 1) // Attach 1 yoctoNEAR
        ]
      }

      await web3authAccount.signAndSendTransaction(data)
      // console.log('licence', licence);
      return API_RESULT_SUCCESS
    },
    async requestSmartBriefPayment(cid: any, amount: string | number) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const usdcContract = getConfig().contracts.usdcContract
      const contentContract = getConfig().contracts.mainContract

      const args = {
        receiver_id: getConfig().contracts.briefContract,
        msg: `content_contract:${contentContract}#cid:${cid}#usdc_contract:${usdcContract}#brief_id:#brief_owner:#brief_title:#account_id:#content_str:`,
        amount: ((amount as number) * 1000000).toString()
      }
      console.log(args)
      const data = {
        receiverId: usdcContract,
        actions: [
          // @ts-ignore
          transactions.functionCall('ft_transfer_call', args, 300000000000000, 1) // Attach 1 yoctoNEAR
        ]
      }

      await web3authAccount.signAndSendTransaction(data)
      return API_RESULT_SUCCESS
    },
    async submitPaymentDetailsForBrief(cid: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const args = {
        cid
      }
      const data = {
        receiverId: getConfig().contracts.mainContract,
        // @ts-ignore
        actions: [transactions.functionCall('save_payment_preferences', args, 300000000000000, 0)]
      }
      await web3authAccount.signAndSendTransaction(data)
      return API_RESULT_SUCCESS
    },
    async applyCoupon(promo_code: any) {
      if (!this.checkNearAmount()) {
        return API_RESULT_NO_NEAR
      }
      const coupon_discount = await smartBriefContract.verify_coupon(
        { code: promo_code },
        {
          gas: '300000000000000', // Gas limit
          attachedDeposit: '8640000000000000000000' // Attached deposit if required
        }
      )
      console.log(`coupon_discount: ${coupon_discount}`)
      return coupon_discount
    }
  }
})

export type NearWalletStoreType = ReturnType<typeof useNearWalletStore>

export const lazyNearWalletStore = useLazyPiniaStore<NearWalletStoreType>(
  useNearWalletStore
) as NearWalletStoreType

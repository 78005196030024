<script lang="ts" setup>
import { ref, watchEffect } from 'vue'
import { storeToRefs } from 'pinia'
import { useNearWalletStore } from '@/stores/near-wallet'

const props = withDefaults(
  defineProps<{
    title?: string
    description?: string
    forceShow?: boolean
    wrapperMarginTop?: string
  }>(),
  {
    title: '',
    description: '',
    forceShow: false,
    wrapperMarginTop: 'mt-14'
  }
)

const nearWalletStore = useNearWalletStore()

const { accountId, inited } = storeToRefs(nearWalletStore)

const loggedIn = ref(false)

const auth = async () => {
  await nearWalletStore.web3authLogin()
}

watchEffect(() => {
  if (props.forceShow) {
    loggedIn.value = true
  } else {
    loggedIn.value = inited.value && accountId.value ? true : false
  }
})
</script>

<template>
  <div v-if="!loggedIn" class="py-3 px-2" :class="[wrapperMarginTop]">
    <h2 class="p-0 m-0 text-left" v-if="props.title">{{ props.title }}</h2>
    <div class="my-6" v-if="props.description">
      <p class="text-left text-md font-medium text-gray-600">
        {{ props.description }}
      </p>
    </div>
    <div class="">
      <button
        type="button"
        class="py-1.5 px-6 min-w-28 border-2 border-[#FF008B] text-[#FF008B] rounded-full hover:bg-[#FF008B] hover:text-white"
        @click.prevent="auth"
      >
        <span>Login</span>
      </button>
      <span class="mx-1">&nbsp;</span>
      <button
        type="button"
        class="py-1.5 px-6 min-w-28 border-2 border-[#FF008B] text-white bg-[#FF008B] rounded-full hover:opacity-85"
        @click.prevent="auth"
      >
        <span>Register</span>
      </button>
    </div>
  </div>

  <div v-if="loggedIn">
    <slot />
  </div>
</template>

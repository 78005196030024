import { createRouter, createWebHistory } from 'vue-router'

import userRoutes from '@/router/user'
import briefsRoutes from '@/router/briefs'
import profileRoutes from '@/router/profiles'
import marketplaceRoutes from '@/router/marketplace'
// import studioRoutes from '@/router/studio'
// import scoutRoutes from '@/router/scout'
import creativesRoutes from '@/router/creatives'
import teamsRoutes from '@/router/teams'
import experimentalRoutes from '@/router/experimental'
import toolsRoutes from '@/router/tools'

import BriefsCreatePage from '@/components/Pages/Marketplace/BriefsCreatePage.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...userRoutes,
    ...briefsRoutes,
    ...profileRoutes,
    ...marketplaceRoutes,
    // ...studioRoutes,
    // ...scoutRoutes,
    ...creativesRoutes,
    ...teamsRoutes,
    ...experimentalRoutes,
    ...toolsRoutes,
    {
      path: '/',
      name: 'home',
      component: BriefsCreatePage
    }
  ]
})

export default router

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { detectValueType } from '@/utils/common'
import { checkBriefSlug } from '@/queries/api'
import { type FormKitNode } from '@formkit/core'

const props = withDefaults(
  defineProps<{
    modelValue: string | undefined
    briefId?: string
  }>(),
  {
    modelValue: ''
  }
)

const emit = defineEmits(['update:modelValue'])

const slug = ref<string | undefined>(props?.modelValue ? props.modelValue : '')

const validateSlug = async (node: any) => {
  // check the value type
  const inputValue = node.value
  const valueType = detectValueType(inputValue)
  if (valueType === 'Invalid') {
    return false
  } else if (valueType === 'Slug') {
    const isValid = await checkBriefSlug(inputValue, props?.briefId)
    return isValid
  } else {
    return true
  }
}

const baseUrl = ref<string>(window.location.protocol + '//' + window.location.host)

const helpText = computed(() => {
  return slug.value ? `${baseUrl.value}/briefs/${slug.value.toLowerCase()}` : ''
})

const onInput = (value: string | undefined, node: FormKitNode) => {
  const valueNormalised = value?.toLowerCase()

  emit('update:modelValue', valueNormalised)
}
</script>

<template>
  <div>
    <FormKit
      type="text"
      name="brief_slug"
      id="brief_slug"
      input-class="lowercase"
      outer-class="min-w-full"
      label="The brief friendly URL *"
      :help="helpText"
      v-model.trim="slug"
      @input="onInput"
      :validation-rules="{ validateSlug }"
      validation="required|validateSlug"
      :validation-messages="{
        validateSlug:
          'Please enter a friendly URL pathname that is between 5 and 100 lowercase alphabetical or numeric characters without spaces.'
      }"
    />
  </div>
</template>

import md5 from 'md5'
import sha3 from 'jssha/sha3'

export const hashValue = async (input: string, algorithm = 'md5') => {
  // [WARN]: md5 is a bad hashing function
  // [WARN]: we have md5 and non-md5... should I add handling for each version
  if (algorithm === 'md5') {
    return md5(input)
  } else if (algorithm === 'sha3_256') {
    const shaObj = new sha3('SHA3-256', 'TEXT', { encoding: 'UTF8' })
    shaObj.update(input)
    return shaObj.getHash('HEX')
  } else if (algorithm === 'sha3_224') {
    const shaObj = new sha3('SHA3-224', 'TEXT', { encoding: 'UTF8' })
    shaObj.update(input)
    return shaObj.getHash('HEX')
  }
  return ''
}

<script lang="ts" setup>
import { computed, ref, watchEffect } from 'vue'
import { type RouteLocationRaw } from 'vue-router'
import { useNearWalletStore } from '@/stores/near-wallet'
import BriefsLayout from '@/Layouts/BriefsLayout.vue'
import TeamMembers from '@/components/Pages/Teams/Partial/Members.vue'
import TeamInvites from '@/components/Pages/Teams/Partial/Invites.vue'
import TeamRoles from '@/components/Pages/Teams/Partial/Roles.vue'
import TeamInvite from '@/components/Pages/Teams/Partial/Invite.vue'
import TeamChat from '@/components/Pages/Teams/Partial/Chat.vue'
import TeamUpdateForm from '@/components/Pages/Teams/Form.vue'
import Nav from '@/components/Pages/Teams/Partial/Nav.vue'
import ListBriefs from '@/components/Pages/Teams/Partial/ListBriefs.vue'
import Auth from '@/components/Pages/Creatives/Wizard/Shared/Auth.vue'
import { Vue3Lottie } from 'vue3-lottie'
import { useTeamStore } from '@/stores/team'
import { storeToRefs } from 'pinia'

import LottieCircles from '@/lottie/searching.json'
import { AxiosError } from 'axios'

export type ViewType = 'list' | 'manage' | 'invite' | 'update' | 'chat' | (string & {})

const props = withDefaults(
  defineProps<{
    nickname?: string
    view?: ViewType
  }>(),
  {
    nickname: undefined,
    view: 'list'
  }
)

const teamStore = useTeamStore()

const nearWalletStore = useNearWalletStore()

const errorMessage = ref<string>()
const loading = ref<boolean>(false)

const { team, loggedInMemberPermissions } = storeToRefs(teamStore)

const activeView = computed((): ViewType => {
  return props.view || 'list'
})

const loadTeam = async () => {
  loading.value = true
  try {
    if (props.nickname) {
      await teamStore.fetchTeam('nickname', props.nickname)
    }
  } catch (err) {
    errorMessage.value =
      err instanceof AxiosError
        ? err.response?.data.message
        : 'Something went wrong, please try again.'
  }
  loading.value = false
}

const loadTeamMemberPermissions = async () => {
  if (props.nickname && nearWalletStore.accountId) {
    await teamStore.fetchTeamMemberPermissions(
      'nickname',
      props.nickname,
      nearWalletStore.accountId
    )
  }
}

watchEffect(async () => {
  teamStore.resetState()
  loadTeam()
  loadTeamMemberPermissions()
})

const toViewRoute = (nickname: string, view: ViewType): RouteLocationRaw => ({
  name: 'teams.show',
  params: {
    nickname,
    view
  }
})

const isPreviewOrNext = ref<boolean>(
  document?.location?.host?.match(/^(preview|next)\.contented/) ? true : false
)
</script>

<template>
  <BriefsLayout title="Teams" selectedLinkId="">
    <div v-if="loading" class="flex justify-center items-center py-6">
      <Vue3Lottie :animationData="LottieCircles" :height="75" :width="75" />
    </div>
    <div v-else-if="errorMessage" class="flex justify-center items-center py-6">
      <div class="border rounded-md border-red-500 py-3 px-3 bg-red-50">
        <p class="text-red-500 font-bold tracking-wider">
          {{ errorMessage }}
        </p>
      </div>
    </div>
    <div v-else-if="team">
      <div v-if="activeView !== 'update'">
        <div
          class="-mb-4 uppercase tracking-widest font-bold text-xs text-gray-500 hover:text-cw-primary"
        >
          <router-link
            :to="{
              name: 'teams.index'
            }"
          >
            <small>Teams</small>
          </router-link>
        </div>
        <div class="flex flex-col items-start">
          <h1 class="font-bold tracking-wider text-3xl">{{ team.name }}</h1>
          <Nav
            :aria-label="'Team Navigation'"
            :links="[
              {
                name: 'List',
                icon: 'fa-light fa-list',
                to: toViewRoute(team.nickname, 'list'),
                show: Boolean(loggedInMemberPermissions.length > 0),
                isSelected: activeView === 'list'
              },
              {
                name: 'Chat',
                icon: 'fa-light fa-message',
                to: toViewRoute(team.nickname, 'chat'),
                show: Boolean(loggedInMemberPermissions.length > 0 && isPreviewOrNext),
                isSelected: activeView === 'chat'
              },
              {
                name: 'Manage',
                icon: 'fa-light fa-cog',
                to: toViewRoute(team.nickname, 'manage'),
                show: Boolean(loggedInMemberPermissions.length > 0),
                isSelected: activeView === 'manage'
              },
              {
                name: 'Create a Brief',
                icon: 'fa-light fa-plus',
                to: {
                  name: 'marketplace.create-brief',
                  query: {
                    team: team.nickname
                  }
                } as RouteLocationRaw,
                show: Boolean(teamStore.can('team.briefs.create') && team?.nickname),
                isSelected: activeView === 'create-brief'
              }
            ]"
          />
        </div>
      </div>
      <div>
        <div v-if="activeView === 'list'">
          <ListBriefs type="uuid" :typeValue="team.uuid" />
        </div>
        <div v-if="activeView === 'invite'">
          <TeamInvite type="uuid" :typeValue="team.uuid" :team="team" />
        </div>
        <div v-if="activeView === 'manage'" class="-ml-6 -mr-6 md:ml-0 md:mr-0">
          <Auth
            title="Manage Team"
            description="Before you manage your team please login or register."
            wrapper-margin-top="mt-0"
          >
            <div class="bg-white p-6 pt-0 mb-3 md:px-0 rounded-md">
              <div class="flex items-center justify-between -mb-6">
                <h2 class="text-xl tracking-wide font-medium">Update</h2>
              </div>
              <div class="max-w-xl -mb-10">
                <TeamUpdateForm
                  :team="team"
                  intent="update"
                  variant="embedded"
                  :showTitle="false"
                />
              </div>
            </div>
            <div class="bg-white p-6 pt-0 mb-3 md:px-0 rounded-md">
              <TeamMembers type="uuid" :typeValue="team.uuid" />
            </div>
            <div class="bg-white p-6 pt-0 mb-3 md:px-0 rounded-md">
              <TeamInvites type="uuid" :typeValue="team.uuid" />
            </div>
            <div class="bg-white p-6 pt-0 mb-3 md:px-0 rounded-md">
              <TeamRoles type="uuid" :typeValue="team.uuid" />
            </div>
          </Auth>
        </div>
        <div v-if="activeView === 'chat'">
          <Auth
            title="Manage Team"
            description="Before you manage your team please login or register."
            wrapper-margin-top="mt-0"
          >
            <TeamChat :team="team" />
          </Auth>
        </div>
      </div>
    </div>
  </BriefsLayout>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { Styles } from '@/styles'

// import SelectBox from '@/components/SelectBox.vue'

const emit = defineEmits(['init', 'update'])

// this is a bodge to stop the compiler not defining emit
emit('init', true)

const props = withDefaults(
  defineProps<{
    pagingTotal: number
    pagingOffset: number
    pagingPerPage: number
  }>(),
  {
    pagingTotal: 0,
    pagingOffset: 0,
    pagingPerPage: 100
  }
)

const prevPage = () => {
  emit('update', {
    pagingOffset: props.pagingOffset - props.pagingPerPage
  })
}
const nextPage = () => {
  if (props.pagingTotal * props.pagingPerPage < props.pagingOffset + props.pagingPerPage) {
    return
  }
  emit('update', {
    pagingOffset: props.pagingOffset + props.pagingPerPage
  })
}
const updatePerPage = (v: Event) => {
  const elSelect = v.target as HTMLSelectElement
  emit('update', {
    pagingPerPage: parseInt(elSelect.value)
  })
}

const page = ref(props.pagingOffset / props.pagingPerPage)
const clickClass = 'cursor-pointer text-cm-blue-light-500 hover:text-cm-blue-500'
</script>

<template>
  <div class="w-full flex justify-left">
    <div class="flex gap-x-4 items-center justify-center">
      <div v-if="pagingOffset" @click="prevPage" class="click-paging-previous" :class="clickClass">
        <a href="#"><i class="fa-duotone fa-backward"></i></a>
      </div>
      <div v-if="pagingOffset == 0 && pagingTotal == 0">0 results</div>
      <div v-if="pagingOffset == 0 && pagingTotal == 1">1 result</div>
      <div v-else-if="pagingTotal">
        Results {{ page * pagingPerPage + 1 }} - {{ page * pagingPerPage + pagingPerPage }}
      </div>
      <div
        v-if="pagingTotal * pagingPerPage >= pagingOffset + pagingPerPage"
        @click="nextPage"
        class="click-paging-next"
        :class="clickClass"
      >
        <a href="#"><i class="fa-duotone fa-forward"></i></a>
      </div>
      <select name="per_page" @change="updatePerPage" :value="pagingPerPage" :class="Styles.select">
        <option value="10">10 per page</option>
        <option value="20">20 per page</option>
        <option value="50">50 per page</option>
        <option value="100">100 per page</option>
      </select>
    </div>
  </div>
</template>

import '@/assets/app.scss'
import 'vue-toastification/dist/index.css'

import * as Sentry from '@sentry/vue'

import { createApp, provide, h } from 'vue'

// mobile touch events
import Vue3TouchEvents from 'vue3-touch-events'

// formkit pro
import { plugin } from '@formkit/vue'
import config from './formkit.config'

import { createHead } from '@unhead/vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { createPinia, setActivePinia } from 'pinia'
import { POSITION } from 'vue-toastification'

import getConfig from '@/config/config'
import i18n from '@/i18n'
import MasonryWall from '@yeger/vue-masonry-wall'
import Toast from 'vue-toastification'
import VueIpfsHttp from '@/utils/vue-ipfs-http'
import clickOutsides from '@/directives/clickOutsides'

import App from './App.vue'
import router from './router'

const cache = new InMemoryCache()

const httpLink = createHttpLink({
  uri: getConfig().graphUrl as string
})

export const apolloClient = new ApolloClient({
  cache,
  link: httpLink
})

const pinia = createPinia()
setActivePinia(pinia)

const head = createHead()

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App)
})

// Masking
const nonPIIdoms = [
  'https://next.contentedworld.pages.dev',
  'https://future.contentedworld.pages.dev',
  'https://uat.contentedworld.pages.dev'
]

const traceDoms = [
  'https://app.contentedworld.com',
  'https://next.contentedworld.pages.dev',
  'https://future.contentedworld.pages.dev',
  'https://uat.contentedworld.pages.dev'
]

let mask = true
if (nonPIIdoms.includes(window.location.origin)) {
  mask = false
}

const replayConfig = {
  maskAllText: mask,
  blockAllMedia: mask
}

console.log('Sentry replayConfig', replayConfig)

const isSentryEnabled = String(import.meta.env.VITE_SENTRY_DISABLED) === 'true' ? false : true
// console.log({ isSentryEnabled })

Sentry.init({
  app,
  dsn: 'https://bca0810bc0c2368c75a95a16bb0b3612@sentry.miappi.com/2',
  enabled: isSentryEnabled,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(replayConfig),
    Sentry.browserProfilingIntegration()
  ],

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: traceDoms,

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

/*
setTimeout(() => {
  throw new Error('Sentry Test Error')
})
*/

app.directive('click-outside', clickOutsides)
app.use(Vue3TouchEvents as any)
app.use(pinia)
app.use(VueIpfsHttp)
app.use(i18n)
app.use(router)
app.use(head)
app.use(MasonryWall)
app.use(plugin, config)
app.use(Toast, {
  position: POSITION.TOP_CENTER
})

app.mount('#app')

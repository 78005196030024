<script lang="ts" setup>
import { ref, watchEffect } from 'vue'

const props = withDefaults(
  defineProps<{
    title?: string
    text: string
    href?: string
    buttonTitle?: string
  }>(),
  {
    title: '',
    text: 'Please set a description',
    href: '',
    buttonTitle: 'Share Link'
  }
)

const title = ref(props.title)
if (!title.value) {
  title.value = document.title
}
const href = ref(props.href)
if (!href.value) {
  href.value = window.location.href
}

const sharingImpossible = ref(false)
const linkCopied = ref(false)
const share = () => {
  if (navigator.share) {
    navigator
      .share({
        title: title.value,
        text: props.text,
        url: href.value
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing:', error))
  } else if (navigator.clipboard) {
    navigator.clipboard.writeText(href.value)
    linkCopied.value = true
    console.log('Web Share API not supported : URL copied to clipboard')
  } else {
    sharingImpossible.value = true
    console.log('Web Share API / Copy to clipboard not supported')
  }
}

const fadeIn = ref(false)
watchEffect(() => {
  if (linkCopied.value) {
    setTimeout(() => {
      fadeIn.value = true
    }, 100)
    setTimeout(() => {
      fadeIn.value = false
      setTimeout(() => {
        linkCopied.value = false
      }, 1000)
    }, 2000)
  }
})
</script>

<template>
  <div v-if="sharingImpossible">Copy this page address and share</div>
  <div v-else class="flex-col h-12">
    <div v-if="!linkCopied" class="action-button" @click="share">{{ buttonTitle }}</div>
    <div
      v-else
      class="transition duration-1000"
      :class="{ 'opacity-0': !fadeIn, 'opacity-100': fadeIn }"
    >
      <div class="text-center pt-2.5 font-bold">Link Copied!</div>
    </div>
  </div>
</template>

import axios, { type CreateAxiosDefaults } from 'axios'
import { lazyUcanStore } from '@/stores/ucan'
import { createApiRequestSignature, toBase64Signature } from '@/utils/api-request-signing'

export const CloudflareWorkersApi = (config: CreateAxiosDefaults<any> | undefined = {}) => {
  const ax = axios.create({
    baseURL: lazyUcanStore.getApiBaseUrl(),
    ...config
  })

  ax.interceptors.request.use((request) => {
    if (!request.headers?.['Content-Type']) {
      request.headers['Content-Type'] = 'application/json'
    }

    if (!request.headers?.['X-Api-Signature'] && lazyUcanStore?.ed25519KeyPair) {
      const { s64, msg } = lazyUcanStore.createApiChallenge()
      request.headers['X-Api-Signature'] = s64
      request.headers['X-Api-Signature-Message'] = msg
    }

    if (request.headers?.common?.['X-Requested-With']) {
      delete request.headers.common['X-Requested-With']
    }

    return request
  })

  // Re-Try logic for when we have a failed challenge due to being out the time window
  // the theory is the clocks will be not synchronised. So the client gets another chance
  // in this case using the signature that is supplied from the server
  ax.interceptors.response.use(
    async (response) => {
      return response
    },
    async (error) => {
      const originalRequest = error.config
      const errorResponse = error.response || {}
      const errorResponseData = errorResponse.data || {}

      // Check if it's the specific error and if the request has not been retried yet
      if (
        !originalRequest?.retryAttempted &&
        errorResponse.status === 401 &&
        errorResponseData.error === 'Unauthorized' &&
        errorResponseData.message === 'Challenge failed. Try again with the supplied challenge.' &&
        errorResponseData.details?.challenge
      ) {
        originalRequest.retryAttempted = true

        originalRequest.headers['X-Api-Signature-Message'] = errorResponseData.details?.challenge
        originalRequest.headers['X-Api-Signature'] = toBase64Signature(
          createApiRequestSignature(
            lazyUcanStore.ed25519KeyPair!,
            new TextEncoder().encode(errorResponseData.details?.challenge)
          )
        )

        // Determine the method and resend the request accordingly
        switch (originalRequest.method) {
          case 'get':
            return CloudflareWorkersApi(originalRequest).get(originalRequest.url, originalRequest)
          case 'post':
            return CloudflareWorkersApi(originalRequest).post(
              originalRequest.url,
              originalRequest.data,
              originalRequest
            )
          case 'put':
            return CloudflareWorkersApi(originalRequest).put(
              originalRequest.url,
              originalRequest.data,
              originalRequest
            )
          case 'delete':
            return CloudflareWorkersApi(originalRequest).delete(
              originalRequest.url,
              originalRequest
            )
          case 'options':
            return CloudflareWorkersApi(originalRequest).options(
              originalRequest.url,
              originalRequest
            )
          default:
            return CloudflareWorkersApi(originalRequest)
        }
      }

      // Handle other errors or return the failed response
      return Promise.reject(error)
    }
  )

  return ax
}

<script lang="ts" setup>
import { useNearWalletStore } from '@/stores/near-wallet'
import { storeToRefs } from 'pinia'

import getConfig from '@/config/config'
import SecondaryButton from '@/components/Jetstream/SecondaryButton.vue'

// Leave 'message' as empty, when you want to
// show the 'message' outside of this component
const props = defineProps({
  message: {
    type: String,
    default: 'Please login to access this section'
  },
  color: {
    type: String,
    default: 'red'
  }
})

const nearWalletStore = useNearWalletStore()

const { loggedIn } = storeToRefs(nearWalletStore)

if (getConfig().TEST_ACCOUNT_ID) {
  loggedIn.value = true
}
</script>

<template>
  <div>
    <div v-if="loggedIn">
      <slot />
    </div>
    <div
      v-if="!loggedIn && message"
      @click.prevent="nearWalletStore.doLogin()"
      class="cursor-pointer"
    >
      <div
        role="alert"
        class="border px-4 py-3 rounded relative"
        :class="[`bg-${color}-100`, `border-${color}-400`, `text-${color}-700`]"
      >
        <span class="block sm:inline font-semibold">{{ message }}</span>
      </div>
    </div>
  </div>
</template>

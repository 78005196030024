<script lang="ts" setup>
import { reactive, ref, watchEffect, onMounted, onUnmounted } from 'vue'
import { RouterLink } from 'vue-router'
import { useQuery } from '@vue/apollo-composable'
import { getMyContent } from '@/queries/content'
import LetterboxImage from '@/components/Pages/Imaging/LetterboxImage.vue'

const PAGE_SIZE = 10

export interface Props {
  username: string
}

const props = withDefaults(defineProps<Props>(), {
  username: ''
})

const params = reactive({
  accountId: '',
  first: PAGE_SIZE,
  after: ''
})

const { result, fetchMore } = useQuery(getMyContent(), params)
const contents = ref<
  {
    id: string
    owner: string
    name: string
    description: string
    media_link: string
    media_link_original: string
    media_original: string
    media_thumbnail: string
    cid_media_link: string
    cid_media_link_original: string
    cid_media_original: string
    cid_media_thumbnail: string
    media_type: string
    restricted: boolean
    created_on: number
    orientation: string
    quality: string
    tags: string[]
    type: string
    search_meta: string
    bid_count: number
    highest_bid: number
    pinned_media: string[]
    media_original_blob: string
    iv: string
  }[]
>([])

watchEffect(() => {
  console.log(props.username)
  params.accountId = props.username

  if (result.value) {
    console.log(result.value)
    contents.value = result.value.contents
  }
})

const loadMore = () => {
  if (contents.value.length === 0) {
    return
  }
  fetchMore({
    variables: {
      first: PAGE_SIZE,
      after: contents.value[contents.value.length - 1].id
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      contents.value = [...contents.value, ...fetchMoreResult.contents]
    }
  })
}

let observer: IntersectionObserver

onMounted(() => {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1
  }

  observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        loadMore()
      }
    })
  }, options)

  const sentinel = document.getElementById('sentinel')
  if (sentinel) {
    observer.observe(sentinel)
  }
})

onUnmounted(() => {
  if (observer) {
    observer.disconnect()
  }
})
</script>

<template>
  <div class="flex flex-wrap -mx-1 lg:-mx-4">
    <!-- Column -->
    <div
      v-for="content in contents"
      class="search-result border border-gray-250 mr-2 mb-2 mt-2"
      :key="content.id"
    >
      <div class="overflow-clip bg-white">
        <RouterLink
          :to="{
            name: 'marketplace.licensing.detail',
            params: {
              uuid: content.id
            }
          }"
          @click.stop
        >
          <LetterboxImage
            imageRes="small"
            :autoFit="true"
            :rounded="false"
            :rounded_top="false"
            :shadow="false"
            :media="content"
          />
          <h1 class="text-md text-gray-600 w-60 p-2 bg-white">
            {{ content.name }}
          </h1>
        </RouterLink>
      </div>
    </div>
  </div>

  <!-- Sentinel -->
  <div id="sentinel"></div>
</template>

/* styles for use across components form elements to simply and normalise the markup */

const focus = 'focus:ring-0 focus:outline-0 focus:border-gray-300'

export const Styles = {
  input:
    'p-2  flex-grow w-full sm:w-auto md:mr-2 shadow-md rounded-md border border-gray-300 ' + focus,
  input_search:
    'p-2  flex-grow w-full sm:w-auto md:mr-2 rounded-r-md border border-l-0 border-gray-300 ' +
    focus,
  select: 'pr-6 xl:flex-grow w-full sm:w-auto md:mr-2 rounded-md border border-gray-300 ' + focus,
  textarea: 'p-2  flex-grow w-full sm:w-auto md:mr-2 border border-gray-300 ' + focus,
  checkbox: 'p-2  pl-0 shadow-md rounded-sm border border-gray-300 cursor-pointer ' + focus,
  radio: 'p-2  pl-0 shadow-md rounded-sm border border-gray-300 cursor-pointer ' + focus,
  submit:
    'py-2 px-3 shadow-md rounded-md border border-gray-300 cursor-pointer text-xs tracking-widest bg-white hover:bg-gray-200 ' +
    focus
}

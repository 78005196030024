import { usePricingConfigurationStore } from '@/stores/pricing-configurations'

export const calcLicencingPrice = (content: { brief: { content_usages: any }; type: string; bids: string | any[]; licences: string | any[] }) => {
  const pricingConfiguration = usePricingConfigurationStore()
  if (!pricingConfiguration.isLoaded) {
    console.error('Pricing constants not loaded yet!')
  }
  let price = 0
  if (content.brief) {
    for (const usage of content.brief.content_usages) {
      const channelPrice = pricingConfiguration.getChannelPrice(usage.type)
      price += parseFloat(channelPrice.toString())
    }
  } else {
    if (content.type === 'standard') {
      if (content.bids && content.bids.length > 0) {
        price = calcWithBids(
          content.bids,
          pricingConfiguration.minStandardPrice,
          pricingConfiguration.bidMultiplier
        )
      } else {
        if (content.licences && content.licences.length > 0) {
          price = pricingConfiguration.minPremiumPrice
        } else {
          price = pricingConfiguration.minStandardPrice
        }
      }
    } else {
      if (content.bids && content.bids.length > 0) {
        price = calcWithBids(
          content.bids,
          pricingConfiguration.minPremiumPrice,
          pricingConfiguration.bidMultiplier
        )
      } else {
        price = pricingConfiguration.minPremiumPrice
      }
    }
  }
  return parseFloat(price.toString())
}

const calcWithBids = (bids: string | any[], minimumPrice: number, bidMultiplier: number) => {
  return Math.max(minimumPrice, bidMultiplier * calcTotalBiddingPrice(bids))
}

const calcTotalBiddingPrice = (bids: string | any[]) => {
  let total = 0
  for (const bid of bids) {
    total += parseFloat(bid.value)
  }
  return total
}

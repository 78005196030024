import { KeyPairEd25519 } from 'near-api-js/lib/utils'
import bs58 from 'bs58'
import nacl from 'tweetnacl'
import naclUtil from 'tweetnacl-util'

export const createApiRequestSignatureString = (
  networkId: string,
  accountId: string,
  ed25119PublicKeyBase58: string,
  timestamp: number,
  attempt: number = 0
) => {
  // return: {networkId}|{accountId}|{ed25119PublicKeyBase58}|{timestamp}|{attempt}
  return [networkId, accountId, ed25119PublicKeyBase58, timestamp, attempt || 0]
    .map((item) => `{${item}}`)
    .join('|')
}

export const extractValuesFromMessageToSign = (messageToSignString: string) => {
  const regex = /\{([^}]+)\}/g
  const matches = [...messageToSignString.matchAll(regex)].map((match) => match[1])
  const [networkId, accountId, ed25119PublicKeyBase58, timestamp, attempt] = matches

  return {
    networkId: networkId,
    accountId: accountId,
    ed25119PublicKeyBase58: ed25119PublicKeyBase58,
    timestamp: Number(timestamp),
    attempt: Number(attempt)
  }
}

export const createApiRequestSignature = (
  keyPair: KeyPairEd25519,
  signatureStringUint8Array: Uint8Array
) => {
  return keyPair.sign(signatureStringUint8Array)
}

export const verifySignature = (
  message: string,
  ed25119PublicKeyBase58: string,
  base64Signature: string
) => {
  const publicKeyUint8Array = bs58.decode(ed25119PublicKeyBase58.split(':')[1])

  const messageUint8Array = new TextEncoder().encode(message)

  const signature = naclUtil.decodeBase64(base64Signature)

  return nacl.sign.detached.verify(messageUint8Array, signature, publicKeyUint8Array)
}

export const toBase64Signature = (signatureUint8Array: { signature: Uint8Array }) => {
  return naclUtil.encodeBase64(signatureUint8Array.signature)
}

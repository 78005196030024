const nearTestnetConfig = {
  web3AuthClientId:
    'BKlDR2v7uH6dDFVzRUUmM8E1y3SDdakwXWBqwkvpZQXlSGzOAcS9859Deqh0NspsD9VXnfO5rpY6kbn1be4jQ5E',
  networkId: 'testnet',
  nodeUrl: 'https://rpc.testnet.near.org',
  // nodeUrl: 'https://rpc.testnet.pagoda.co',
  walletUrl: 'https://wallet.testnet.near.org',
  helperUrl: 'https://helper.testnet.near.org',
  explorerUrl: 'https://explorer.testnet.near.org'
}

// this is as recommended on Near docs, but it doesn't work
const newNearTestnetConfig = {
  web3AuthClientId:
    'BKlDR2v7uH6dDFVzRUUmM8E1y3SDdakwXWBqwkvpZQXlSGzOAcS9859Deqh0NspsD9VXnfO5rpY6kbn1be4jQ5E',
  networkId: 'testnet',
  nodeUrl: 'https://testnet.aurora.dev',
  walletUrl: 'https://wallet.testnet.aurora.dev',
  helperUrl: 'https://helper.testnet.aurora.dev',
  explorerUrl: 'https://explorer.testnet.aurora.dev'
}

const nearMainnetConfig = {
  web3AuthClientId:
    'BPDyfWePnsEhAqnlbKOFsp7EW6RBagsNEhVB6YocFoZeRc2Xr24YPoHpbRu83wmtLYvnsIfZWiuhthh8iB9vVXI',
  networkId: 'mainnet',
  nodeUrl: 'https://rpc.near.org',
  // nodeUrl: 'https://rpc.mainnet.pagoda.co',
  walletUrl: 'https://wallet.near.org',
  helperUrl: 'https://helper.mainnet.near.org',
  explorerUrl: 'https://explorer.near.org'
}

const testConfig = {
  graphUrl: 'https://api.thegraph.com/subgraphs/name/cmg-platform/cmg-test',
  graphName: 'cmg-platform/cmg-test',
  apiUrl: 'https://api-test.contentedmedia.com',
  nearAccountApiHost: 'near-preview.contentedworld.workers.dev',
  profileUrl: '-test.contentedmedia.com/content-api/v1/profile',
  ipfsHostURL: 'https://aquamarine-sophisticated-bear-327.mypinata.cloud/ipfs/',
  contracts: {
    mainContract: 'content.cmg-test.testnet',
    bidContract: 'testnft1.gro.testnet',
    cotoContract: 'testcoto1.gro.testnet',
    usdtContract: 'ft.examples.testnet',
    usdcContract: '3e2210e1184b45b64c8a434c0a7e7b23cc04ea7eb7a6c3c32520d03d4afcb8af', // testnet
    briefContract: 'nft-brief.gro.testnet',
    groContract: 'gro.testnet'
  },
  contentExpiryDays: 5,
  briefExpiryDays: 7,
  contentEncryption: true,
  nearConfig: nearTestnetConfig,
  keys: {
    groContract:
      ''
  },

  // vars for cypress testing

  TEST_ACCOUNT_ID: 'sukerman.testnet',
  TEST_API_TOKEN:
    'c3VrZXJtYW4udGVzdG5ldCY0MTBjNGRkMjU0YTRhZGI1ZjAyOWQ5OTQ4MmYzMGE2NTM2ZDQwMDZkMDJlYjBjMzhlOTA5YmY3MDljNWQyZGQwJmI4YTljMTBlNmQ0M2ExNzM2Y2E4MTc3ZjJkNmU2Y2U0NzdmZWQ5YTlkMzMwZThjZDgzZTg4ZTBiYzcyZmZkNGU0YzljNDc4YjE0MmI3ZmQwMDg0NzkyZjkzN2EyOWMwZDVjNmQzN2M3YzZkOGQ2MGE1N2JhZjQzNzI2YWU4ZjA2',
  TEST_OK_SIZES: '0,37361,38522,16800,365569,374262,12515862,33993351'
}

const previewConfig = {
  graphUrl: 'https://api.thegraph.com/subgraphs/name/cmg-platform/cmg---staging',
  apiUrl: 'https://api-staging.contentedmedia.com',
  nearAccountApiHost: 'near-preview.contentedworld.workers.dev',
  profileUrl: '-staging.contentedmedia.com/content-api/v1/profile',
  ipfsHostURL: 'https://aquamarine-sophisticated-bear-327.mypinata.cloud/ipfs/',
  graphName: 'cmg-platform/cmg---staging',
  contracts: {
    mainContract: 'content2.cmg-staging.testnet',
    bidContract: 'nft102.gro.testnet',
    cotoContract: 'coto3.gro.testnet',
    usdtContract: 'ft.examples.testnet',
    usdcContract: '3e2210e1184b45b64c8a434c0a7e7b23cc04ea7eb7a6c3c32520d03d4afcb8af', // testnet
    briefContract: 'nft-brief2.gro.testnet',
    groContract: 'gro.testnet'
  },
  contentExpiryDays: 5,
  briefExpiryDays: 7,
  contentEncryption: true,
  nearConfig: nearTestnetConfig,
  keys: {
    groContract:
      ''
  }
}

const uatConfig = {
  graphUrl: 'https://api.thegraph.com/subgraphs/name/cmg-migrate/uat',
  apiUrl: 'https://api-uat.contentedmedia.com',
  nearAccountApiHost: 'near-preview.contentedworld.workers.dev',
  profileUrl: '-uat.contentedmedia.com/content-api/v1/profile',
  ipfsHostURL: 'https://aquamarine-sophisticated-bear-327.mypinata.cloud/ipfs/',
  graphName: 'cmg-migrate/uat',
  contracts: {
    mainContract: 'uat.sukerman.near',
    bidContract: 'nft-uat.sukerman.near',
    cotoContract: 'coto-uat.sukerman.near',
    usdtContract: 'usdt.tether-token.near',
    usdcContract: '17208628f84f5d6ad33f0da3bbbeb27ffcb398eac501a31bd6ad2011e36133a1',
    briefContract: 'nft-brief2.sukerman.near',
    groContract: 'sukerman.near'
  },
  contentExpiryDays: 5,
  briefExpiryDays: 7,
  contentEncryption: true,
  nearConfig: nearMainnetConfig,
  keys: {
    groContract:
      ''
  }
}

const appConfig = {
  graphUrl: 'https://api.thegraph.com/subgraphs/name/cmg-migrate/contented',
  apiUrl: 'https://api-app.contentedmedia.com',
  nearAccountApiHost: 'near.contentedworld.workers.dev',
  profileUrl: '-app.contentedmedia.com/content-api/v1/profile',
  ipfsHostURL: 'https://aquamarine-sophisticated-bear-327.mypinata.cloud/ipfs/',
  graphName: 'cmg-migrate/contented',
  contracts: {
    mainContract: 'cnt.contented.near',
    bidContract: 'nft.contented.near',
    cotoContract: 'coto.contented.near',
    usdtContract: 'usdt.tether-token.near',
    usdcContract: '17208628f84f5d6ad33f0da3bbbeb27ffcb398eac501a31bd6ad2011e36133a1',
    briefContract: 'nft-brief2.contented.near',
    groContract: 'contented.near'
  },
  contentExpiryDays: 5,
  briefExpiryDays: 7,
  contentEncryption: true,
  nearConfig: nearMainnetConfig,
  keys: {
    groContract:
      ''
  }
}

const logged: Record<string, boolean> = {}
function log(msg: string) {
  if (typeof logged[msg] === 'undefined') {
    logged[msg] = true
    console.debug('getConfig(): ' + msg)
  }
}

export default function getConfig(): Config {
  const hostname = window.location.host
  // you cannot use config here for built versions, just local - its bundled during the build and won't be picked up by node
  const isTest = process.env.MIX_TEST_MODE == 'true'
  let config: Config
  //
  // need to support
  //
  // test|preview|uat|app.contentedmedia.com / contentedworld.com
  // remote-test|remote-preview|remote-uat|remote-app.contentedmedia.com / contentedworld.com
  //
  if (hostname.includes('test.contented') || isTest) {
    log('using TEST config')
    config = testConfig
  } else if (hostname.includes('preview.contented') || hostname.includes('next.contentedworld')) {
    log('using PREVIEW config')
    config = previewConfig
  } else if (hostname.includes('uat.contented')) {
    log('using UAT config')
    config = uatConfig
  } else if (hostname.includes('app.contented')) {
    log('using APP config')
    config = appConfig
  } else {
    log('using PREVIEW config - no supported domain detected!')
    config = previewConfig
  }

  config.TEST_MODE = isTest
  return config
}

<script lang="ts" setup>
import { ref } from 'vue'

const props = withDefaults(
  defineProps<{
    isVisible?: boolean
  }>(),
  {
    isVisible: false
  }
)

const isVisibleValue = ref<boolean>()

const open = () => {
  isVisibleValue.value = true
}

const close = () => {
  isVisibleValue.value = false
}

defineExpose({ open, close })
</script>

<template>
  <transition name="fade">
    <div
      class="bg-black/50 fixed top-0 bottom-0 left-0 right-0 p-2 flex items-center z-10"
      v-if="isVisibleValue"
    >
      <div class="rounded ml-auto mr-auto p-4 bg-white max-w-lg shadow-lg">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

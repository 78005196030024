<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { fetchProfile } from '@/queries/api'
import { useNearWalletStore } from '@/stores/near-wallet'

import DocumentList from '@media/DocumentList.vue'
import MediaCarousel from '@media/MediaCarousel.vue'

const props = withDefaults(
  defineProps<{
    showLatest?: number
  }>(),
  {
    showLatest: undefined
  }
)

const profile = ref<ProfileData>()
const nearWalletStore = useNearWalletStore()

const items = computed(() => {
  if (typeof props.showLatest === 'undefined') {
    return profile.value?.contents?.slice().reverse()
  } else {
    return props.showLatest === 0 ? [] : profile.value?.contents?.slice(-props.showLatest)
  }
})

onMounted(async () => {
  profile.value = await fetchProfile(nearWalletStore.accountId)
})
</script>

<template>
  <div v-if="items" :key="items.length">
    <MediaCarousel
      :showCount="true"
      :items="items"
      dataType="objects"
      noContentMsg="No examples yet."
    />
    <div class="mt-6">
      <DocumentList :showCount="true" :items="items" />
    </div>
  </div>
</template>

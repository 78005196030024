<script lang="ts" setup>
import { onMounted, onUnmounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useNearWalletStore } from '@/stores/near-wallet'
import { storeToRefs } from 'pinia'

const $router = useRouter()
const nearWalletStore = useNearWalletStore()

const { accountId } = storeToRefs(nearWalletStore)

watch(accountId, (nv, ov) => {
  if (nv && nv !== ov) {
    if (accountId.value) {
      $router.replace({
        name: 'user.profile',
        params: {
          accountId: accountId.value
        }
      })
    }
  }
})

let timer: NodeJS.Timeout

onMounted(() => {
  timer = setTimeout(() => {
    $router.push('/')
  }, 2500)
})

onUnmounted(() => {
  clearTimeout(timer)
})
</script>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { RouterLink } from 'vue-router'
import { formatRelative } from 'date-fns'

import BriefDetailsQAInput from '@briefs/BriefDetailsQAInput.vue'
import BriefDetailsQAComment from '@briefs/BriefDetailsQAComment.vue'
import MediaCarousel from '@media/MediaCarousel.vue'
import ProfileAvatar from '@/components/ProfileAvatar.vue'

const emit = defineEmits(['submitted'])

const props = withDefaults(
  defineProps<{
    brief?: SmartBrief
    threads: Record<string, any>
    thread_id?: string
    depth?: number
    reference_type?: string
  }>(),
  {
    thread_id: '',
    depth: 0,
    reference_type: ''
  }
)

const show = ref<Record<string, boolean>>({})
const hideThread = ref<Record<string, boolean>>({})
const isExpanded = ref<Record<number, boolean>>({})

const toggleShow = (id: string) => {
  show.value[id] = !show.value[id]
}
const toggleThread = (id: string) => {
  hideThread.value[id] = !hideThread.value[id]
}

const toggleReadMore = (index: number) => {
  isExpanded.value[index] = !isExpanded.value[index]
}

const getDisplayedComment = (comment: string, index: number): string => {
  if (isExpanded.value[index] || comment.length <= 200) {
    return comment
  }
  return comment.substring(0, 200) + '...'
}

const submitted = (data: unknown) => {
  emit('submitted', data)
}

const style = computed(() => {
  return {
    marginLeft: props?.depth ? '5%' : 0
  }
})
</script>

<template>
  <div :style="style" class="my-1" v-for="(comment, index) in threads[thread_id]" :key="comment.id">
    <div
      class="flex rounded-md my-3 py-3 px-5"
      :class="{
        'bg-[#F2F3F4]': !comment?.isCommentOwner,
        'bg-[#E5E7E9]': comment?.isCommentOwner
      }"
    >
      <div class="mt-1 mr-3" v-if="comment?.profile_image && comment?.owner">
        <ProfileAvatar
          :profile-id="comment.owner"
          :data="comment.profile_image"
          class="h-8 w-8"
          fallback-border-color="border-slate-300"
          fallback-text-color="text-slate-400"
        />
      </div>
      <div class="flex-grow">
        <div class="font-semibold tracking-wide">
          <RouterLink to="#" title="View profile">
            <b class="text-lg text-left">{{ comment.account_name }}</b>
          </RouterLink>
        </div>
        <div class="pt-1 tracking-wide">
          <div>
            {{ getDisplayedComment(comment.comment, index) }}
          </div>
          <span
            v-if="comment.comment.length > 200"
            class="tab-link cursor-pointer font-medium hover:text-[#FF008B] selected"
          >
            <a href="#" @click.prevent="toggleReadMore(index)">
              {{ isExpanded[index] ? 'Read Less' : 'Read More' }}
            </a>
          </span>
        </div>
        <!-- Image Display -->
        <div class="mt-3 text-sm">
          <MediaCarousel
            v-if="comment.comment_file.length > 0"
            :items="comment.comment_file"
            :showCount="true"
            showCountTitle="Supporting files"
            dataType="objects"
            noContentMsg=""
          />
        </div>
      </div>
    </div>

    <div class="pb-1 mx-1 flex justify-between text-[#3F3F46]">
      <div class="cursor-pointer flex-none mx-4" @click="toggleShow(comment.id)">
        <i class="fal fa-reply hover:text-[#E2007B]" style="color: #e2007b"></i>
      </div>
      <div
        class="cursor-pointer flex-none mx-4 hover:text-[#E2007B]"
        @click="toggleThread(comment.id)"
        v-if="threads[comment.id]"
      >
        {{ threads[comment.id].length }}
        <span v-if="threads[comment.id].length != 1">replies</span><span v-else>reply</span>
        <span class="ml-2">
          <i v-if="hideThread[comment.id]" class="fa-light fa-triangle fa-rotate-90"></i>
          <i v-else class="fa-light fa-triangle fa-rotate-180"></i>
        </span>
      </div>
      <div class="flex-grow text-right mx-4 text-xs">
        Posted: {{ formatRelative(new Date(parseInt(comment.created_on)), new Date()) }}
      </div>
    </div>

    <BriefDetailsQAInput
      :brief="props.brief"
      :brief_id="comment.brief_id"
      :thread_id="comment.id"
      :dm_candidate_id="comment.dm_candidate_id || null"
      :show="show[comment.id] == true"
      :reference_id="comment.reference_id"
      :reference_type="props.reference_type"
      @submitted="submitted"
    />

    <div
      class="transition-all duration-1000"
      :class="{
        'h-0 overflow-hidden': hideThread[comment.id]
      }"
    >
      <BriefDetailsQAComment
        v-if="props.threads[comment.id]"
        :brief="props.brief"
        :threads="props.threads"
        :thread_id="comment.id"
        :depth="depth + 1"
        :reference_type="props.reference_type"
        @submitted="submitted"
      />
    </div>
  </div>
</template>

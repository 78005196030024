<script lang="ts" setup>
import { useTeamStore } from '@/stores/team'
import AllBriefs from '@/components/Pages/Briefs/AllBriefs.vue'

const props = withDefaults(
  defineProps<{
    type: 'uuid' | 'nickname'
    typeValue: string
  }>(),
  {
    type: 'uuid',
    typeValue: ''
  }
)

const teamStore = useTeamStore()
</script>

<template>
  <div class="mt-3 md:-mt-14 z-10">
    <AllBriefs :team="teamStore?.team" title="" :show-join-tile="false" />
  </div>
</template>

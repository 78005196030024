<script lang="ts" setup>
// @ts-nocheck
import { ref, reactive, watchEffect } from 'vue'
import { route } from '@/utils/route'
import { convertCoto, usePricingConfigurationStore } from '@/stores/pricing-configurations'
import { getContent } from '@/queries/content'
import { useToast } from 'vue-toastification'
import { calcLicencingPrice } from '@/utils/licencingPriceCalc'
import { saveResult } from '@/api/requests'
import { storeToRefs } from 'pinia'
import { useNearWalletStore } from '@/stores/near-wallet'
import { useQuery } from '@vue/apollo-composable'

import ConfirmDialog from '@/components/InputCurrencyDialog.vue'
import MarketplaceLayout from '@/Layouts/MarketplaceLayout.vue'
import PageTitleHeading from '@/components/PageTitleHeading.vue'
import LetterboxImage from '@/components/Pages/Imaging/LetterboxImage.vue'
import PageSectionTitle from '@/components/PageSectionTitle.vue'
import CotoValue from '@/components/CotoValue.vue'
import BackLink from '@/components/BackLink.vue'
import MarketplaceMoreLikeThisRow from '@/components/MarketplaceMoreLikeThisRow.vue'
import Loading from '@/components/Loading.vue'
import Alert from '@/components/Alert.vue'
import LoginRequiredButton from '@/components/LoginRequiredButton.vue'
import CurrencyValue from '@/components/CurrencyValue.vue'
import getConfig from '@/config/config'
import { decryptBlob, importKeyBlob, base64ToArrayBuffer } from '@/utils/encryptChunks'
import init, { decrypt_data } from '@/wasm/pkg/cmg_wasm_rust.js'
import { getToken } from '@/api/requests'

const props = defineProps({
  uuid: {
    type: String,
    default: ''
  },
  pagingOffset: {
    type: Number,
    default: 0
  },
  media_preference: {
    type: String,
    default: ''
  },
  pagingPerPage: {
    type: String,
    default: '100'
  },
  search: {
    type: String,
    default: ''
  }
})

const toast = useToast()

const nearWalletStore = useNearWalletStore()
const pricingStore = usePricingConfigurationStore()

const { selectedCurrency } = storeToRefs(pricingStore)
const { accountId, balance } = storeToRefs(nearWalletStore)

const params = reactive({
  accountId: ''
})

const { result, loading, error } = useQuery(getContent(props.uuid))
const content = ref(null)

const confirmDialog = ref<InstanceType<typeof ConfirmDialog>>()

const showBody = ref(true)
const licencedToMe = ref(false)

// posts
const data = ref({
  //
})

watchEffect(() => {
  params.accountId = accountId.value
  if (result.value) {
    setTimeout(() => {
      content.value = {
        ...result.value.content,
        licencingPrice: calcLicencingPrice(result.value.content)
      }
      const myLicence = content.value.licences.filter((e) => e.owner === params.accountId)
      licencedToMe.value = myLicence.length > 0

      // save result
      saveResult(params, {
        licencedToMe: licencedToMe.value,
        contents: content.value
      })
    }, 500)
  }
})

// [TEMP]: get the posts so we can show more like this
// and show more by this creator
const dataMoreCreator = ref([])
const dataMoreLikeThis = ref([])
const formatCurrency = (num, minimumFractionDigits = 2, locale = 'en') => {
  if (isNaN(num)) return num
  return num.toLocaleString(locale, {
    style: 'currency',
    currency: selectedCurrency.value,
    minimumFractionDigits: minimumFractionDigits
  })
}

const licenseContent = async () => {
  if (licencedToMe.value) {
    if (content.value.media_original.length > 0) {
      // var iv = base64ToArrayBuffer(content.value.iv);
      // console.log({iv});

      const toastId = toast('Downloading content...', { timeout: false })

      let originalUrl = content.value.media_original
      const data = await fetch(originalUrl)

      if (data.status !== 200) {
        toast.dismiss(toastId)
        toast.error('Error while downloading encrypted file!')
        return
      }

      await init()

      toast.update(toastId, { content: 'Getting key...' })

      try {
        // decrypt key
        const decrypted = await decrypt_data(
          new Uint8Array(base64ToArrayBuffer(content.value.key)),
          content.value.id,
          getToken(),
          getConfig().graphUrl
        )
        //const decrypted = await decrypt_data(new Uint8Array(base64ToArrayBuffer(content.value.key)));

        const decoder = new TextDecoder()
        const keyBase64 = decoder.decode(decrypted)

        const importedKey = await importKeyBlob(keyBase64)
        const rr = await decryptBlob(importedKey, await data.blob(), content.value.iv)
        var blob = new Blob([rr], {
          type: content.value.media_type === 'video' ? 'video/mp4' : 'image/png'
        })
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = content.value.id
        link.click()
        toast.dismiss(toastId)
      } catch (error) {
        toast.dismiss(toastId)
        toast.error(error.toString())
        console.log(error)
      }
    } else {
      // window.open breaks the test
      if (!getConfig().TEST_ACCOUNT_ID) {
        if (content.value.media_link_original) {
          window.open(content.value.media_link_original, '_blank')
        } else {
          window.open(content.value.media_link, '_blank')
        }
      }
    }
  } else {
    const amount = convertCoto(parseFloat(content.value.licencingPrice), selectedCurrency.value)
    if (balance.value.USDC > amount || getConfig().TEST_ACCOUNT_ID) {
      const ok = await confirmDialog.value?.show({
        title: 'License Content',
        message: `You will be charged ${formatCurrency(amount)} ($${content.value.licencingPrice.toLocaleString(
          'en',
          {
            minimumFractionDigits: 2
          }
        )} USDC)`,
        okButton: 'Pay Now'
      })
      if (ok) {
        nearWalletStore.ftLicenceTransferCall(
          result.value.content.owner,
          result.value.content.id,
          content.value.licencingPrice,
          content.value.created_on,
          result.value.content.brief
        )
      }
    } else {
      toast.error('You dont have enough money in your wallet!')
    }
  }
}
</script>

<template>
  <MarketplaceLayout title="Content Licensing" selectedLinkId="licensing">
    <template #header>
      <div class="flex flex-wrap items-end justify-between">
        <div class="my-1">
          <PageTitleHeading> Content Licensing </PageTitleHeading>
        </div>
      </div>
      <div class="mt-3">
        <BackLink
          :href="
            route('marketplace.licensing', {
              query: {
                pagingOffset: props.pagingOffset,
                media_preference: props.media_preference,
                pagingPerPage: props.pagingPerPage,
                search: props.search
              }
            })
          "
        />
      </div>
    </template>
    <Loading v-if="loading" />
    <Alert v-else-if="error" type="error" :text="error" />
    <div v-else-if="content" class="flex flex-wrap">
      <div class="-mt-3 mb-3 w-full flex-wrap flex justify-center">
        <div class="my-3 md:-ml-6 w-full sm:w-1/2 md:w-3/5">
          <div class="md:mx-6">
            <LetterboxImage height="400px" :media="content" />
          </div>
        </div>
        <div class="flex-grow sm:mt-0 w-full sm:w-1/2 md:w-2/5">
          <div class="my-3 bg-white py-4 px-6 rounded-md shadow-sm text-cm-blue-500">
            <div class="w-full my-3">
              <div>
                <div class="w-full">
                  <h3 class="font-bold text-lg tracking-wide items-center flex">Title</h3>
                </div>
                <div class="w-full my-1">
                  <p>{{ content.name }}</p>
                </div>
              </div>
            </div>
            <div class="w-full my-3">
              <div>
                <div class="w-full">
                  <h3 class="font-bold text-lg tracking-wide items-center flex">Description</h3>
                </div>
                <div class="w-full my-1">
                  <p>{{ content.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 w-full">
        <div class="w-full my-1 text-cm-blue-500">
          <div class="border border-gray-250 shadow-sm rounded-md bg-white">
            <div class="py-4 px-6 flex items-center justify-between">
              <div>
                <h3 class="font-bold tracking-wider text-lg">{{ content.name }}</h3>
              </div>
              <div>
                <a href="#" @click.prevent="showBody = !showBody" class="hover:text-blue-500">
                  <i
                    :class="{
                      'fa-solid': true,
                      'fa-chevron-up': showBody,
                      'fa-chevron-down': !showBody
                    }"
                  ></i>
                </a>
              </div>
            </div>
            <div class="py-4 px-6 border-t border-gray-250" v-show="showBody">
              <div class="w-full">
                <h4 class="font-bold tracking-wide">Price</h4>
                <p class="text-xl">
                  <strong><CurrencyValue :value="content.licencingPrice" /></strong>
                  <span>&nbsp;</span>
                  <small class="text-sm text-gray-500"
                    ><CotoValue :value="content.licencingPrice"
                  /></small>
                </p>
              </div>
              <div class="flex flex-wrap">
                <div class="mr-6 my-3">
                  <h4 class="font-bold tracking-wide">Aspect Ratio</h4>
                  <p>16:9</p>
                </div>
                <div class="mr-6 my-3">
                  <h4 class="font-bold tracking-wide">Format</h4>
                  <p>JPEG</p>
                </div>
                <div class="mr-6 my-3">
                  <h4 class="font-bold tracking-wide">Content Rating</h4>
                  <p>{{ content.type }}</p>
                </div>
                <div class="mr-6 my-3">
                  <h4 class="font-bold tracking-wide">Creator</h4>
                  <p>{{ data.owner }}</p>
                </div>
              </div>
              <div class="w-full my-3 mb-4 md:text-right">
                <LoginRequiredButton
                  title="License Content"
                  :message="`Licensing this content will be charged as $${content.licencingPrice.toFixed(2)}`"
                  ok-button-text="Login First"
                  button-text="Login"
                >
                  <a
                    href="#"
                    class="bg-cm-blue-light-400 hover:bg-cm-blue-light-500 py-3 px-4 rounded-md text-white"
                    @click.prevent="licenseContent"
                  >
                    <button class="licence-button" type="button">
                      {{ licencedToMe ? 'Download' : 'License' }}
                    </button>
                  </a>
                </LoginRequiredButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 w-full">
        <div class="my-3" v-if="dataMoreCreator && dataMoreCreator.length">
          <div class="w-full">
            <PageSectionTitle class="font-bold text-cm-blue-500 tracking-wider text-lg">
              More from this creator
            </PageSectionTitle>
            <div class="my-1">
              <MarketplaceMoreLikeThisRow :items="dataMoreCreator" />
            </div>
          </div>
        </div>
        <div class="my-6" v-if="dataMoreLikeThis && dataMoreLikeThis.length">
          <div class="w-full">
            <PageSectionTitle class="font-bold text-cm-blue-500 tracking-wider text-lg">
              More like this
            </PageSectionTitle>
            <div class="my-1">
              <MarketplaceMoreLikeThisRow :items="dataMoreLikeThis" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
  </MarketplaceLayout>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue'
import MediaItem from '@media/MediaItem.vue'
import UploadUppy from '@upload/UploadUppy.vue'

const emit = defineEmits(['onSave', 'onCancel', 'onUploaded', 'onMetaDataSaved'])

const props = withDefaults(
  defineProps<{
    accountId: string
    editIDList: string[]
    profileData: any
    showMeta: boolean
  }>(),
  {
    accountId: '',
    editIDList: () => [],
    profileData: {},
    showMeta: false
  }
)

const isInProfile = (id: string) => {
  return props.profileData.contents?.find((item: any) => item.id === id)
}
const fileDetails = (id: string) => {
  const json = isInProfile(id)
  return JSON.stringify([json])
}
const onUploaded = (data: any) => {
  // console.log('UploadList: onUploaded', data)
  emit('onUploaded', data)
}

const onSave = (data: any) => {
  // console.log('UploadList: onSave')
  emit('onSave')
}
const onCancel = () => {
  // console.log('UploadList: onCancel')
  emit('onCancel')
}

// eventually we want to be at zero that shows all meta data editors are closed
const metaDataSaved = ref(0)

// depends if we start with the editors open or not
if (!props.showMeta) {
  metaDataSaved.value = props.editIDList.length
}

// keep count of how many files have been saved
const onMetaDataSaved = (saved: boolean, data: any) => {
  // console.log('UploadList: onMetaDataSaved', saved, data)
  if (saved) {
    metaDataSaved.value++
  } else {
    metaDataSaved.value--
  }
}

const getUploadId = (index: number) => {
  return 'portfolioItem-' + index
}

const debug = ref(false)
</script>

<template>
  <pre v-if="debug" class="text-xs">
    editIDList {{ editIDList }}
    profileData {{ profileData }}
  </pre>
  <h1>Edit Selected Files</h1>
  <div
    class="grid gap-8"
    :class="{
      'grid-cols-1': editIDList.length == 1,
      'sm:grid-cols-2': editIDList.length > 1,
      'xl:grid-cols-3': editIDList.length > 2
    }"
  >
    <div v-for="(item, index) in editIDList" :key="item" class="p-8 rounded-md border shadow-md">
      <div v-if="isInProfile(item)">
        <div v-if="editIDList.length > 1" class="flex mb-3 w-full items-center justify-center">
          <div class="flex flex-col items-center justify-center">
            <div class="flex items-center">
              <MediaItem :item="isInProfile(item)" :hideIcons="true" :imageCss="['h-12']" />
            </div>
            <div class="mx-3 my-1 text-center text-sm font-semibold whitespace-nowrap">
              {{ index + 1 }} of {{ editIDList.length }}
            </div>
          </div>
        </div>
        <UploadUppy
          :uploadId="getUploadId(index)"
          :uploadValue="fileDetails(item)"
          :uploadAccountId="accountId"
          :multiSelect="false"
          :showIcons="true"
          :showMeta="showMeta"
          @onMetaDataSaved="onMetaDataSaved"
          @onUploaded="onUploaded"
          @onCancel="onCancel"
        />
      </div>
    </div>
  </div>
  <div class="mt-6 select-none flex items-center justify-center space-x-4">
    <input
      v-if="metaDataSaved == editIDList.length"
      @click="onSave"
      type="submit"
      class="action-button action-save-meta solid"
      value="Save"
    />
    <div v-else class="text-xs">Please save changes to all files before saving</div>
    <input
      @click="onCancel"
      type="submit"
      class="action-button action-save-meta hollow"
      value="Cancel"
    />
  </div>
</template>

<template>
  <div>
    <h2 class="my-6">Follow Contented on social:</h2>

    <!-- socials -->
    <div class="flex justify-center">
      <div class="flex w-[70%] fa-2x justify-between social-anim">
        <a target="_blank" href="https://www.facebook.com/profile.php?id=61557766690238"
          ><i class="fa-brands fa-facebook"></i
        ></a>
        <a
          target="_blank"
          href="https://www.instagram.com/contented.creators?igsh=MTd0ZmhkMTQ1OG1yaQ=="
          ><i class="fa-brands fa-instagram"></i
        ></a>
        <a target="_blank" href="https://twitter.com/Contented_World"
          ><i class="fa-brands fa-x-twitter"></i
        ></a>
        <a target="_blank" href="https://www.tiktok.com/@contented.creators"
          ><i class="fa-brands fa-tiktok"></i
        ></a>
        <a target="_blank" href="https://m.youtube.com/channel/UCHntojoQojKLc0gAckgsZHQ"
          ><i class="fa-brands fa-youtube"></i
        ></a>
        <a target="_blank" href="https://www.linkedin.com/company/contentedglobal"
          ><i class="fa-brands fa-linkedin"></i
        ></a>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.social-anim a:hover i {
  transform: scale(1.2);
  transition: transform 0.2s;
}
</style>

import { watermarkImage, createThumbnail } from '@/utils/image'
// import watermark from '@/images/watermark.png'
import watermark from '@/images/watermark-cmg-center-trans.png'
import {
  encryptBlob,
  generateKey,
  exportKeyToBase64,
  arrayBufferToBase64,
  decryptBlob,
  importKeyBlob,
  base64ToArrayBuffer,
  encryptString,
  decryptString,
  getPrivateKey,
  getPublicKey,
  encryptKey,
  decryptKey,
  encryptedDataToJson,
  jsonToEncryptedData
} from './encryptChunks'
import { v4 as uuidv4 } from 'uuid'
import init, { encrypt_data, decrypt_smart_brief_data } from '@/wasm/pkg/cmg_wasm_rust.js'
import getConfig from '@/config/config'
import { getToken } from '@/api/requests'
import { pinMediaToIPFS } from '@/utils/ipfs'
import type { FFmpeg } from '@ffmpeg/ffmpeg'
import { useUcanStore } from '@/stores/ucan'
import { fetchProfile } from '@/queries/api'

export async function submitImage(
  imageData: Blob | MediaSource,
  accountId: string,
  title: string,
  description: string,
  format: any,
  mediaType: string | undefined,
  briefId: null,
  updateStatus: { (message: any): void; (message: any): void; (arg0: string): void },
  tags: any[]
) {
  const contentId = uuidv4()

  updateStatus('Creating key...')

  // @ts-ignore
  await init()
  // Generate a new key
  const key = await generateKey()
  // convert key to base64
  const keyBase64 = await exportKeyToBase64(key)
  //console.log('keyBase64', keyBase64);
  // encrypt data
  const encryptedBase64KeyArrayBuffer = await encrypt_data(keyBase64)
  //console.log('encryptedBase64KeyArrayBuffer', encryptedBase64KeyArrayBuffer);
  const encryptedBase64Key = arrayBufferToBase64(encryptedBase64KeyArrayBuffer[0])

  const iv = window.crypto.getRandomValues(new Uint8Array(16))

  //const keyRet = await importKeyBlob(key);
  const { data } = await encryptBlob(key, imageData, iv)

  updateStatus('Creating thumbnail...')
  const thumbnail = (await createThumbnail(imageData)) as Blob

  updateStatus('Creating watermark...')
  const { logofied, width, height, aspectRatio, quality } = (await watermarkImage(
    imageData,
    watermark
  )) as WatermarkImageResult

  const pinned: never[] = []

  const filename = uuidv4()

  updateStatus('Pinning original...')
  const originalCid = await pinMediaToIPFS(data, filename, '', mediaType)
  updateStatus('Pinning waternarm...')
  const watermarkedCid = await pinMediaToIPFS(logofied, `${filename}-watermarked`, '', mediaType)
  updateStatus('Pinning thumbnail...')
  const thumnailCid = await pinMediaToIPFS(thumbnail, `${filename}-thumbnail`, '', mediaType)

  const metadata = {
    id: contentId,
    owner: accountId,
    name: title,
    description: description,
    media_link: `${watermarkedCid.pinataCid}`,
    media_thumbnail: `${thumnailCid.pinataCid}`,
    type: 'standard',
    format: format,
    source: 'upload',
    quality,
    aspect_ratio: aspectRatio,
    orientation: width > height ? 'landscape' : 'portrait',
    media_type: mediaType ? (mediaType?.match('video') ? 'video' : 'image') : 'image',
    tags: tags.map((tag) => (tag.text ? tag.text : tag)),
    restricted: false,
    created_on: new Date().getTime(),
    media: '',
    media_original: `${originalCid.pinataCid}`,
    media_original_blob: 'CiphertextBlob',
    pinned_media: pinned,
    iv: arrayBufferToBase64(iv),
    key: encryptedBase64Key,
    brief_id: ''
  }

  if (briefId) {
    metadata['brief_id'] = briefId
  }
  return metadata
}

export async function submitVideo(
  ffmpeg: FFmpeg,
  videoFile: Uint8Array,
  watermarkFile: Uint8Array,
  imageData: BlobPart,
  accountId: string,
  title: string,
  description: string,
  format: any,
  briefId: null,
  updateStatus: { (message: any): void; (message: any): void; (arg0: string): void },
  name: any,
  type: string | undefined,
  tags: any[]
) {
  updateStatus('Importing video...')
  ;(ffmpeg as any).FS('writeFile', 'original.mp4', videoFile)
  updateStatus('Importing logo...')
  ;(ffmpeg as any).FS('writeFile', 'logo.png', watermarkFile)

  updateStatus('Extracting thumbnail...')
  //await ffmpeg.run("-i", "original.mp4", "-filter:v", "scale=720:-1", "lowres.mp4");
  await (ffmpeg as any).run(
    '-i',
    'original.mp4',
    '-ss',
    '00:00:01.000',
    '-vframes',
    '1',
    'thumbnail.png'
  )

  updateStatus('Applying watermark...')
  await (ffmpeg as any).run(
    '-threads',
    '1',
    '-i',
    'original.mp4',
    '-i',
    'logo.png',
    '-filter_complex',
    'overlay=0:0,scale=720:-2',
    '-pix_fmt',
    'yuv420p',
    '-b:v',
    '2M',
    '-preset',
    'veryslow',
    'logofied.mp4'
  )

  updateStatus('Extracting data...')
  const logofiedData = (ffmpeg as any).FS('readFile', 'logofied.mp4')
  const thumbnailData = (ffmpeg as any).FS('readFile', 'thumbnail.png')

  console.log('logofiedData', logofiedData)

  updateStatus('Creating key...')
  const contentId = uuidv4()

  // @ts-ignore
  await init()
  // Generate a new key
  const key = await generateKey()
  // convert key to base64
  const keyBase64 = await exportKeyToBase64(key)
  //console.log('keyBase64', keyBase64);
  // encrypt data
  const encryptedBase64KeyArrayBuffer = await encrypt_data(keyBase64)
  //console.log('encryptedBase64KeyArrayBuffer', encryptedBase64KeyArrayBuffer);
  const encryptedBase64Key = arrayBufferToBase64(encryptedBase64KeyArrayBuffer[0])

  updateStatus('Encrypting original...')
  const iv = window.crypto.getRandomValues(new Uint8Array(16))
  const { data } = await encryptBlob(key, new Blob([imageData]), iv)

  const filename = uuidv4()

  updateStatus('Uploading watermarked version')
  const watermarkedCid = await pinMediaToIPFS(logofiedData, `${filename}-watermarked`, '', type)

  updateStatus('Uploading encrypted')
  const encryptedCid = await pinMediaToIPFS(data, `${filename}`, '', type)

  updateStatus('Uploading thumbnail')
  const thumbnailCid = await pinMediaToIPFS(thumbnailData, `${filename}-thumbnail`, '', type)

  const metadata = {
    id: contentId,
    owner: accountId,
    name: title,
    description: description,
    media_link: `${watermarkedCid.pinataCid}`,
    media_thumbnail: `${thumbnailCid.pinataCid}`,
    type: 'standard',
    format: format,
    source: 'upload',
    quality: '1',
    aspect_ratio: '1x1',
    orientation: 'portrait',
    media_type: 'video',
    tags: tags.map((tag) => tag.text),
    restricted: false,
    created_on: new Date().getTime(),
    media: '',
    media_original: `${encryptedCid.pinataCid}`,
    media_original_blob: 'CiphertextBlob',
    pinned_media: [],
    iv: arrayBufferToBase64(iv),
    key: encryptedBase64Key,
    brief_id: ''
  }

  if (briefId) {
    metadata['brief_id'] = briefId
  }
  return metadata
}

async function readFileBlob(file: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const arrayBuffer = reader.result as unknown as Blob
      const blob = new Blob([arrayBuffer], { type: file.type })
      resolve(blob)
    }
    reader.onerror = (error) => {
      reject(error)
    }
    reader.readAsArrayBuffer(file)
  })
}

export async function processSmartBriefImage(
  imageFile: Blob,
  imageName: string | undefined,
  imageExtension: string | undefined,
  briefOwnerId: string,
  updateStatus: { (message: any): void; (arg0: string): void }
): Promise<
  Partial<{
    file_url: string
    watermark_file_url: string
    iv: string
    key: string
    media_type: string
  }>
> {
  let imageData = (await readFileBlob(imageFile)) as Blob
  const imageType = imageFile.type

  updateStatus('Creating watermark...')
  const { logofied } = (await watermarkImage(imageData, watermark)) as WatermarkImageResult
  const watermarkData = logofied
  // console.log(logofied);

  // let encryptedBase64Key
  let iv
  let encryptedKeyJson
  let encryptedKeyJsonBriefOwner
  if (getConfig().contentEncryption) {
    updateStatus('Creating key...')
    // @ts-ignore
    await init()
    // Generate a new key
    const key = await generateKey()
    // convert key to base64
    const keyBase64 = await exportKeyToBase64(key)

    // encrypt content key using logged-in user public key
    const ucanStore = useUcanStore()
    updateStatus('Encrypting key...')
    const privateKey = await getPrivateKey(ucanStore.data?.secp256k1PrivateKey)
    const publicKey = getPublicKey(privateKey)
    const encryptedKey = await encryptKey(keyBase64, publicKey)
    encryptedKeyJson = encryptedDataToJson(encryptedKey)

    // encrypt content key using brief owner public key
    updateStatus('Encrypting brief owner key...')
    const briefOwner = await fetchProfile(briefOwnerId)
    if (briefOwner && briefOwner.publicKey) {
      const encryptedKeyBriefOwner = await encryptKey(
        keyBase64,
        Buffer.from(briefOwner.publicKey, 'hex')
      )
      encryptedKeyJsonBriefOwner = encryptedDataToJson(encryptedKeyBriefOwner)
    }

    // encrypt data
    updateStatus('Encrypting data...')
    // comment WASM encryption
    // const encryptedBase64KeyArrayBuffer = await encrypt_data(keyBase64)
    // encryptedBase64Key = arrayBufferToBase64(encryptedBase64KeyArrayBuffer[0])
    // encryptedBase64Key = keyBase64
    iv = window.crypto.getRandomValues(new Uint8Array(16))
    const { data } = await encryptBlob(key, imageData, iv)
    // console.log(data);
    imageData = data

    // Pin Encrypted  watermark image - no need
    // const encryptedWatermarkData = await encryptBlob(key, logofied, iv)
    // watermarkData = encryptedWatermarkData.data
  }

  updateStatus('Pinning original...')
  // Pin Encrypted image
  const originalCid = await pinMediaToIPFS(imageData, imageName, imageExtension, imageType)

  updateStatus('Pinning watermarked...')
  const watermarkedCid = await pinMediaToIPFS(
    watermarkData,
    `${imageName}-watermarked`,
    imageExtension,
    imageType
  )

  let metadata = {}
  if (originalCid.pinataCid && watermarkedCid.pinataCid) {
    metadata = {
      file_url: `${originalCid.pinataCid}`,
      watermark_file_url: `${watermarkedCid.pinataCid}`,
      iv: iv ? arrayBufferToBase64(iv) : iv,
      key: encryptedKeyJson,
      key_brief_owner: encryptedKeyJsonBriefOwner,
      media_type: imageType
    }
  }
  return metadata
}

export async function processSmartBriefVideo(
  imageFile: Blob,
  imageName: string | undefined,
  imageExtension: string | undefined,
  briefOwnerId: string,
  updateStatus: { (message: any): void; (arg0: string): void }
): Promise<
  Partial<{
    file_url: string
    watermark_file_url: string
    iv: string
    key: string
    media_type: string
  }>
> {
  let imageData = (await readFileBlob(imageFile)) as Blob
  const imageType = imageFile.type

  let iv
  let encryptedKeyJson
  let encryptedKeyJsonBriefOwner
  if (getConfig().contentEncryption) {
    updateStatus('Creating key...')
    // @ts-ignore
    await init()
    // Generate a new key
    const key = await generateKey()
    // convert key to base64
    const keyBase64 = await exportKeyToBase64(key)

    // encrypt content key using logged-in user public key
    const ucanStore = useUcanStore()
    updateStatus('Encrypting key...')
    const privateKey = await getPrivateKey(ucanStore.data?.secp256k1PrivateKey)
    const publicKey = getPublicKey(privateKey)
    const encryptedKey = await encryptKey(keyBase64, publicKey)
    encryptedKeyJson = encryptedDataToJson(encryptedKey)

    // encrypt content key using brief owner public key
    updateStatus('Encrypting brief owner key...')
    const briefOwner = await fetchProfile(briefOwnerId)
    if (briefOwner && briefOwner.publicKey) {
      const encryptedKeyBriefOwner = await encryptKey(
        keyBase64,
        Buffer.from(briefOwner.publicKey, 'hex')
      )
      encryptedKeyJsonBriefOwner = encryptedDataToJson(encryptedKeyBriefOwner)
    }

    // encrypt data
    updateStatus('Encrypting data...')
    // comment WASM encryption
    // const encryptedBase64KeyArrayBuffer = await encrypt_data(keyBase64)
    // encryptedBase64Key = arrayBufferToBase64(encryptedBase64KeyArrayBuffer[0])
    iv = window.crypto.getRandomValues(new Uint8Array(16))
    const { data } = await encryptBlob(key, imageData, iv)
    imageData = data
  }

  updateStatus('Pinning original...')
  // Pin Encrypted image
  const originalCid = await pinMediaToIPFS(imageData, imageName, imageExtension, imageType)

  /* updateStatus('Pinning watermarked...')
  // Pin Encrypted  watermark image
  //const encryptedWatermarkData = await encryptBlob(key, logofied, iv);
  const watermarkedCid = await pinMediaToIPFS(
    imageData,
    `${imageName}-watermarked`,
    imageExtension,
    imageType
  ) */
  // For video watermark is same as originsl version
  const watermarkedCid = originalCid

  let metadata = {}
  if (originalCid.pinataCid && watermarkedCid.pinataCid) {
    metadata = {
      file_url: `${originalCid.pinataCid}`,
      watermark_file_url: `${watermarkedCid.pinataCid}`,
      iv: iv ? arrayBufferToBase64(iv) : iv,
      key: encryptedKeyJson,
      key_brief_owner: encryptedKeyJsonBriefOwner,
      media_type: imageType
    }
  }
  return metadata
}

/* async function convertBlobString(blobData: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = (error) => {
      reject(error)
    }
    reader.readAsText(blobData)
  })
} */

async function streamAndCreateBlob(response: Response, mediaType: string): Promise<Blob> {
  // Ensure that the body is readable as a stream
  const reader: ReadableStreamDefaultReader<Uint8Array> = response.body!.getReader()

  // Array to accumulate all the chunks
  const chunks: Uint8Array[] = []

  // Read the stream in chunks
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { done, value } = await reader.read()
    if (done) break
    // Push the chunk into the array and track total size
    if (value) {
      chunks.push(value)
    }
  }

  // Combine all chunks into a Blob
  const blobParts: BlobPart[] = chunks
  const combinedBlob: Blob = new Blob(blobParts, { type: mediaType })

  return combinedBlob
}

export async function decryptSmartBriefImage(
  id: any,
  brief_id: any,
  mediaUrl: any,
  key: string,
  iv: any,
  media_type: any,
  accountId: string,
  apiUrl: string,
  apiSignature: string,
  apiSignatureMessage: string,
  updateStatus: { (message: any): void; (message: any): void; (arg0: string): void }
) {
  updateStatus('Fetch media data...')
  const data = await fetch(`${getConfig().ipfsHostURL}${mediaUrl}`)

  /*
  // comment WASM decryption
  let ok = true,
    decrypted
  if (key && iv) {
    // decrypt key
    updateStatus('Decrypting key...')
    // @ts-ignore
    await init()
    try {
      decrypted = await decrypt_smart_brief_data(
        new Uint8Array(base64ToArrayBuffer(key)),
        brief_id,
        id,
        getToken(),
        apiUrl,
        apiSignature,
        apiSignatureMessage
      )
    } catch (e) {
      console.warn('Could not decrypt key')
      ok = false
    }
  }
  if (!ok) {
    return
  } */

  if (key && iv) {
    try {
      // decrypt content key using logged-in user private key
      updateStatus('Decrypting key...')
      const ucanStore = useUcanStore()
      const retrievedEncryptedKey = jsonToEncryptedData(key)
      const privateKey = await getPrivateKey(ucanStore.data?.secp256k1PrivateKey)
      const decryptedKey = await decryptKey(retrievedEncryptedKey, privateKey)

      // read file using chunk (this is helpful in case of large files)
      const fileData = await streamAndCreateBlob(data, media_type)

      // decrypt content using decrypted content key
      updateStatus('Decrypting data...')
      // const decoder = new TextDecoder()
      // const keyBase64 = decoder.decode(decrypted)
      const keyBase64 = decryptedKey
      const importedKey = await importKeyBlob(keyBase64)
      // const rr = await decryptBlob(importedKey, await data.blob(), iv)
      const rr = await decryptBlob(importedKey, fileData, iv)
      const blob = new Blob([rr], { type: media_type })
      return blob
    } catch (e) {
      console.warn('Could not decrypt data', e)
    }
  } else {
    return data.blob()
  }
}

export async function encryptSmartBriefStrings(
  strArray: any[],
  updateStatus: { (message: any): void; (arg0: string): void }
) {
  // console.log(strData);
  updateStatus('Creating key...')

  // @ts-ignore
  await init()
  const key = await generateKey()
  const keyBase64 = await exportKeyToBase64(key)
  const encryptedBase64KeyArrayBuffer = await encrypt_data(keyBase64)
  const encryptedBase64Key = arrayBufferToBase64(encryptedBase64KeyArrayBuffer[0])
  const iv = window.crypto.getRandomValues(new Uint8Array(16))

  const encryptedStrings = await Promise.all(
    strArray.map(async (string) => {
      if (!string) {
        return ''
      }
      try {
        const encrypted = await encryptString(key, string, iv)
        return encrypted
      } catch (error) {
        console.error('Encryption error:', error)
        return ''
      }
    })
  )

  return {
    encryptedStrings,
    iv: arrayBufferToBase64(iv),
    key: encryptedBase64Key
  }
}

export async function decryptSmartBriefString(
  brief_id: string,
  strData: string,
  key: string,
  iv: string,
  apiUrl: string,
  apiSignature: string,
  apiSignatureMessage: string,
  updateStatus: {
    (message: any): void
    (message: any): void
    (message: any): void
    (message: any): void
    (arg0: string): void
  }
): Promise<string | undefined> {
  updateStatus('Decrypting key...')
  // @ts-ignore
  await init()

  // decrypt key
  let ok = true,
    decrypted
  try {
    decrypted = await decrypt_smart_brief_data(
      new Uint8Array(base64ToArrayBuffer(key)),
      brief_id,
      '',
      getToken(),
      apiUrl,
      apiSignature,
      apiSignatureMessage
    )
  } catch (e) {
    console.warn('Could not decrypt key')
    ok = false
  }

  if (!ok) {
    return
  }

  updateStatus('Decrypting data...')
  try {
    const decoder = new TextDecoder()
    const keyBase64 = decoder.decode(decrypted)
    const importedKey = await importKeyBlob(keyBase64)
    const data = await decryptString(importedKey, strData, iv)
    return data
  } catch (e) {
    console.warn('Could not decrypt data')
  }
}

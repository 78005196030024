<script lang="ts" setup>
const emit = defineEmits(['init', 'delete'])

// this is a bodge to stop the compiler not defining emit
emit('init', true)

withDefaults(
  defineProps<{
    media: Record<string, unknown>
  }>(),
  {
    //
  }
)
</script>

<template>
  <div class="md:absolute w-full h-full top-0 left-0">
    <div class="flex items-center justify-center relative w-full h-full">
      <div
        v-if="media.uploading || media.uploaded || media.error"
        class="rounded-full text-white p-2"
        :style="{
          'background-color': 'rgba(0,0,0,0.50)'
        }"
      >
        <div class="text-[#00A3DB]">
          <i v-if="media.uploading" class="fa-3x fa-spin fa-duotone fa-spinner-third"></i>
          <i v-if="media.uploaded" class="fa-3x fa-sharp fa-solid fa-shield-check"></i>
          <i v-if="media.error" class="fa-3x fa-regular fa-triangle-exclamation"></i>
        </div>
      </div>
      <div
        v-else-if="media.isVideo"
        class="md:absolute left-0 top-0 w-full h-full flex flex-col items-center justify-center"
      >
        <i class="fa-solid text-gray-400 opacity-50 fa-6x fa-video"></i>
        <p class="text-sm text-white">{{ media.name }}</p>
        <p class="text-xs text-white">{{ media.size }}</p>
      </div>
      <div v-if="media.uploading" class="md:absolute text-white text-xs">
        {{ media.progressPctRef }}
      </div>
      <div
        v-if="media.delete"
        class="absolute top-2 right-2 py-1 px-2 rounded-md hover:cursor-pointer"
        @click="emit('delete', true)"
        :style="{
          'background-color': 'rgba(0,0,0,0.25)'
        }"
      >
        <i class="fa-solid !text-white fa-xmark"></i>
      </div>
    </div>
  </div>
</template>

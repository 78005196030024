<script lang="ts" setup>
import { ref } from 'vue'
import { RouterLink } from 'vue-router'

export type TabMenuItemLink = {
  id: string
  label: string
  selected?: boolean
  href?: string
}

type ComponentProps = {
  links: Array<TabMenuItemLink>
}

const props = withDefaults(defineProps<ComponentProps>(), {
  links: () => [
    // Default values for links can be provided here if needed
  ]
})

const selected = ref((props.links || []).find((link) => link.selected)?.id)

const emit = defineEmits(['selected'])

const selectItem = (item: TabMenuItemLink) => {
  selected.value = item.id
  emit('selected', selected.value)
}

const sanitize = (label: string) => {
  return (label || '').replace(' ', '-').toLowerCase()
}

const linkClass =
  'tab-link cursor-pointer font-medium py-1.5 px-3 text-gray-900 capitalize hover:text-[#FF008B]'
</script>

<style>
.tab-link.selected {
  color: #ff008b;
}
</style>

<template>
  <ul class="flex flex-wrap -ml-4">
    <li class="mx-1 my-1" v-for="(item, indx) in links" :key="item.id || indx">
      <div
        v-if="!item.href"
        @click="selectItem(item)"
        :class="[
          'click-tab-' + sanitize(item.label),
          linkClass,
          {
            selected: item.id == selected
          }
        ]"
      >
        {{ item.label }}
      </div>
      <RouterLink
        v-else
        :to="item.href"
        :class="[
          'click-tab-' + sanitize(item.label),
          linkClass,
          {
            selected: item.id == selected
          }
        ]"
      >
        {{ item.label }}
      </RouterLink>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted } from 'vue'

const newBackground = '#ffffff'
const initialBackground = document.body.style.background

onMounted(() => {
  document.body.style.background = newBackground
})

onUnmounted(() => {
  document.body.style.background = initialBackground
})
</script>

<template>
  <div class="w-full max-w-3xl mx-auto">
    <slot />
  </div>
</template>

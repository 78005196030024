<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { useImageCDN } from '@/utils/image'
import { mobileDetect } from '@/utils/browser'
import { isImage, isFile, isPDF, isVideo } from '@/utils/mediaFileTypes'
import { getVideoThumb, getVideoStream } from '@/utils/mediaDecrypt'
import { onKeyStroke } from '@vueuse/core'

const emit = defineEmits(['popupState'])

let saveScroll = 0

const isMobile = ref(mobileDetect())
const updatePageWidth = () => {
  isMobile.value = mobileDetect()
}
onMounted(() => {
  updatePageWidth()
  window.addEventListener('resize', updatePageWidth)
})
onUnmounted(() => {
  window.removeEventListener('resize', updatePageWidth)
})

const show = ref(false),
  fade = ref(false),
  hideLabels = ref(false),
  rotate = ref(false),
  carouselIndex = ref(0),
  imageSrc = ref(),
  videoSrc = ref(),
  videoThumb = ref(),
  videoStream = ref(),
  bgSrc = ref(),
  bgColor = ref(),
  scrollTop = ref(),
  screenWidth = ref(),
  screenHeight = ref(),
  imageWidth = ref(),
  imageHeight = ref(),
  videoWidth = ref(),
  videoHeight = ref(),
  isLandscape = ref(false),
  imageLoaded = ref(false)

onKeyStroke(['ArrowLeft'], (e) => {
  e.preventDefault()
  prev()
})
onKeyStroke(['ArrowRight'], (e) => {
  e.preventDefault()
  next()
})
onKeyStroke(['Escape', 'Esc'], (e) => {
  e.preventDefault()
  hidePopup()
})

const refreshDimensions = () => {
  // full screen on mobile
  let pct = 70
  if (isMobile.value) {
    pct = 100
  }

  screenWidth.value = window.innerWidth // 430;
  screenHeight.value = window.innerHeight // 932;
  imageWidth.value = (screenWidth.value * pct) / 100 + 'px'
  imageHeight.value = (screenHeight.value * pct) / 100 + 'px'
  videoWidth.value = (screenWidth.value * pct) / 100 + 'px'
  videoHeight.value = (screenHeight.value * pct) / 100 + 'px'

  // if width < height on mobile we've been rotated
  if (screenWidth.value > screenHeight.value) {
    isLandscape.value = true
  } else {
    isLandscape.value = false
  }
}

const showPopup = () => {
  refreshDimensions()
  show.value = true
  hideLabels.value = false
  if (window.document.scrollingElement?.scrollTop) {
    saveScroll = window.document.scrollingElement.scrollTop
  }
  // this is needed on mobile, not in chrome emulated mobile
  if (isMobile.value) {
    window.document.scrollingElement?.scrollTo(0, 0)
  } else {
    scrollTop.value = saveScroll
  }
  let col = 'rgb(230,230,230,0.85)'
  if (isMobile.value) {
    col = '#000'
  }
  setTimeout(() => {
    bgColor.value = col
  }, 25)
  let el = document.getElementsByTagName('html')
  el[0].className += ' popupActive'
  setMeta()
  emit('popupState', true)
}

let noHide: boolean = false
const hidePopup = () => {
  // prevent propogation from open / close tags
  if (noHide) {
    noHide = false
    return
  }
  show.value = false
  fade.value = false
  rotate.value = false
  imageSrc.value = undefined
  videoSrc.value = undefined
  videoThumb.value = undefined
  videoStream.value = undefined
  imageLoaded.value = false
  showTags.value = false
  title.value = ''
  tags.value = ''
  description.value = ''
  bgSrc.value = undefined
  bgColor.value = 'rgb(0,0,0,0)'
  emit('popupState', false)
  let el = document.getElementsByTagName('html')
  el[0].className = el[0].className.replace(' popupActive', '')
  // scroll seems to need an offset on mobile
  if (isMobile.value) {
    let mobileFudge = 0
    if (!isLandscape.value) {
      mobileFudge = 120
    }
    setTimeout(() => {
      window.document.scrollingElement?.scrollTo(0, saveScroll + mobileFudge)
    }, 10)
  }
}

const popupLoaded = () => {
  setTimeout(() => {
    fade.value = true
  }, 25)
}

const title = ref()
const tags = ref()
const description = ref()
const carousel = ref([] as any)

const showTags = ref(false)

const showControls = ref(false)

const getTags = (tags: string) => {
  if (Array.isArray(tags)) {
    return tags.map((tag) => tag.trim())
  }
  if (typeof tags === 'string') {
    return tags.split(',').map((tag) => tag.trim())
  }
  return []
}

const setMeta = () => {
  if (
    window.popupCarousel &&
    window.carousels[window.popupCarousel] &&
    window.carousels[window.popupCarousel].length
  ) {
    // just the images and videos
    const items = window.carousels[window.popupCarousel].filter(
      (item: any) => isImage(item) || isVideo(item)
    )
    // normalise urls
    carousel.value = items.map((item: any) => ({
      ...item,
      url: item.file_url || item.image
    }))
    // strip out files
    carousel.value = carousel.value.filter((item: any) => !isFile(item.url))
    // carousel items
    setTimeout(() => {
      showControls.value = true
      for (let i = 0; i < carousel.value.length; i++) {
        let url = carousel.value[i].url
        const normalisedStream = ref(videoStream.value?.replace('/embed/', '/play/'))
        if (url == imageSrc.value || url == videoSrc.value || normalisedStream.value?.match(url)) {
          carouselIndex.value = i
          break
        }
      }
      title.value = carousel.value[carouselIndex.value]?.name
      description.value =
        carousel.value[carouselIndex.value]?.short_description ||
        carousel.value[carouselIndex.value]?.description
      tags.value = carousel.value[carouselIndex.value]?.tags
    }, 100)
  } else {
    title.value = window.popupTitle
    description.value = window.popupDescription
    tags.value = window.popupTags
  }
}

const setCurrent = (index: number) => {
  if (typeof carousel.value[index].url === 'undefined') {
    console.error(
      'setCurrent : carousel.value[' + index + '] has no file_url or image!',
      carousel.value[index]
    )
    return
  }
  const url = carousel.value[index].url
  imageSrc.value = null
  videoSrc.value = null
  videoStream.value = null
  done.value = false
  // we don't handle files
  // TODO - handle legacy video, though it shoudn't exist on live
  if (isVideo(carousel.value[index])) {
    videoThumb.value = getVideoThumb(carousel.value[index].url, '')
    if (carousel.value[index].hls) {
      videoStream.value = getVideoStream(carousel.value[index].hls, '')
    } else {
      videoStream.value = url
    }
    videoStream.value = videoStream.value?.replace('/play/', '/embed/')
    done.value = true
  } else if (isImage(url)) {
    imageSrc.value = url
  }
  setMeta()
}

const prev = () => {
  if (carouselIndex.value > 0) {
    carouselIndex.value--
    setCurrent(carouselIndex.value)
  }
}
const next = () => {
  if (carouselIndex.value < carousel.value.length - 1) {
    carouselIndex.value++
    setCurrent(carouselIndex.value)
  }
}

const done = ref(false)
const loaded = () => {
  imageLoaded.value = true
  done.value = true
}
const errored = () => {
  done.value = true
}

setTimeout(() => {
  done.value = true
}, 5000)

clearInterval(window.myPopupWatch)
window.myPopupWatch = setInterval(() => {
  if (window.popupImage) {
    imageSrc.value = window.popupImage
    window.popupImage = undefined
    showPopup()
  }

  if (window.popupVideo) {
    videoSrc.value = window.popupVideo
    window.popupVideo = undefined
    showPopup()
    popupLoaded()
  }

  if (window.popupVideoStream) {
    videoStream.value = window.popupVideoStream
    window.popupVideoStream = undefined
    showPopup()
    popupLoaded()
    imageLoaded.value = true
  }

  if (window.popupVideoId) {
    const cdnUrl = 'https://vz-4e8d49c5-eb4.b-cdn.net/',
      embedUrl = 'https://iframe.mediadelivery.net/embed/'
    imageSrc.value = cdnUrl + window.popupVideoId + '/thumbnail.jpg'
    videoStream.value =
      embedUrl +
      window.popupLibraryId +
      '/' +
      window.popupVideoId +
      '?autoplay=true&loop=false&muted=false&preload=true&responsive=true'
    window.popupVideoId = undefined
    window.popupLibraryId = undefined
    showPopup()
  }
}, 250)

const fitCover = ref(false)
const toggleCover = () => {
  fitCover.value = !fitCover.value
}

const controlClass =
  'text-white text-xl w-6 h-6 flex items-center justify-center cursor-pointer rounded-md bg-[rgba(100,100,100,0.5)]'

const debug = ref(false)
</script>

<template>
  <div
    v-if="show"
    class="popup-container transition-all duration-250 absolute top-0 left-0 w-full h-full bg-contain bg-no-repeat bg-center"
    :style="{
      'z-index': 500,
      'background-color': bgColor,
      'background-image': bgSrc,
      top: scrollTop + 'px'
    }"
  >
    <!-- debug -->
    <div
      v-if="debug"
      class="absolute w-[96%] overflow-scroll text-black z-20 right-[2%] top-5 bg-[rgba(255,255,255,0.5)] p-2 rounded-md"
    >
      <pre class="text-xs">dim {{ screenWidth }}x{{ screenHeight }}</pre>
      <pre class="text-xs">
mob {{ isMobile }} index {{ carouselIndex }} length {{ carousel.length }}</pre
      >

      <pre class="text-xs">controls {{ showControls }}</pre>
      <pre class="text-xs">done {{ done }} </pre>
      <pre
        class="text-xs"
      >imageSrc {{ imageSrc }} <img :src='useImageCDN(imageSrc, "small")' class='w-8'></pre>
      <pre class="text-xs">imageLoaded {{ imageLoaded }}</pre>
      <pre class="text-xs">videoSrc {{ videoSrc }}</pre>
      <pre class="text-xs">videoThumb {{ videoThumb }}</pre>
      <pre class="text-xs">videoStream {{ videoStream }}</pre>
      <pre class="text-xs">videoStream carousel {{ carousel[carouselIndex] }}</pre>
    </div>

    <!-- loading spinner-->
    <div v-if="!done" class="w-full h-full flex items-center justify-center">
      <div class="w-[50%] h-[50%] bg-[rgba(0,0,0,0.25)] rounded-md relative">
        <div class="absolute inset-0 flex items-center justify-center">
          <i class="fa-solid fa-spinner fa-spin text-gray-200 text-4xl"></i>
        </div>
      </div>
    </div>

    <!-- prev / next -->
    <div v-if="showControls">
      <div
        class="absolute left-4 z-[100]"
        :class="[
          controlClass,
          {
            'bottom-4': isMobile,
            'top-[45%]': !isMobile,
            disabled: carouselIndex == 0
          }
        ]"
        @click.prevent="prev"
      >
        <i class="fa-solid fa-chevron-left"></i>
      </div>
      <div
        class="absolute right-4 z-[100]"
        :class="[
          controlClass,
          {
            'bottom-4': isMobile,
            'top-[45%]': !isMobile,
            disabled: carouselIndex == carousel.length - 1
          }
        ]"
        @click.prevent="next"
      >
        <i class="fa-solid fa-chevron-right"></i>
      </div>
    </div>

    <div
      v-if="!bgSrc"
      class="w-full h-full"
      :class="{
        'flex items-center justify-center': !isMobile
      }"
    >
      <div
        class="text-black relative transition-all duration-500"
        :class="{
          'bg-black p-0 w-full h-full flex flex-col': isMobile,
          'bg-white p-6 rounded-md': !isMobile,
          'opacity-0': !isMobile,
          'opacity-100': !isMobile && imageLoaded
        }"
      >
        <!-- hide popup -->
        <div class="absolute right-4 top-4" style="z-index: 500">
          <div :class="controlClass" @click="hidePopup">
            <i class="fa-regular fa-xmark"></i>
          </div>
        </div>

        <!-- mobile -->
        <div v-if="isMobile" class="flex-grow flex items-center justify-center">
          <!-- images -->
          <div v-if="imageSrc" class="w-full h-full">
            <img
              v-if="!done"
              @load="loaded"
              @error="errored"
              :src="useImageCDN(imageSrc, 'small')"
              class="w-2 h-2 opacity-2"
            />
            <div
              v-else
              v-touch:swipe.left="next"
              v-touch:swipe.right="prev"
              @click="toggleCover"
              class="w-full h-full bg-no-repeat bg-center relative"
              :class="{
                'bg-cover': fitCover,
                'bg-contain': !fitCover
              }"
              :style="{
                'background-image': 'url(' + useImageCDN(imageSrc, 'large') + ')'
              }"
            ></div>
          </div>

          <!-- bunny video -->
          <div v-if="videoStream" class="relative w-full h-[70%]">
            <iframe
              :src="videoStream"
              style="
                position: absolute;
                z-index: 30;
                border: 0;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              autoplay="true"
              playsinline="false"
              allowfullscreen="true"
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
            ></iframe>
          </div>

          <!-- legacy video -->
          <div v-if="videoSrc">
            <video autoplay controls :style="{ height: videoHeight }">
              <source :src="videoSrc" type="video/mp4" />
            </video>
          </div>

          <!-- title, tags at the top -->
          <div
            v-touch:swipe.left="next"
            v-touch:swipe.right="prev"
            v-if="!hideLabels"
            class="p-2 bg-[rgba(0,0,0,0.5)] text-white absolute z-22 w-full top-0 flex justify-center"
            :class="{
              'p-3': !isLandscape
            }"
          >
            <div class="flex flex-col">
              <div>
                <div v-if="title" class="text-lg">
                  {{ title }}
                  <i
                    v-if="tags.length"
                    @click.prevent="
                      () => {
                        noHide = true
                        showTags = !showTags
                      }
                    "
                    class="cursor-pointer ml-3 fa-solid fa-ellipsis-vertical"
                  ></i>
                </div>
                <div class="ml-3 text-sm" v-if="showTags">{{ tags }}</div>
              </div>
              <!-- count for carousel -->
              <div
                v-if="carousel.length > 1"
                class="w-full flex justify-center text-xs mt-1 text-white"
              >
                < {{ carouselIndex + 1 }} / {{ carousel.length }} >
              </div>
            </div>
          </div>

          <!-- desc at the bottom -->
          <div
            v-if="description && !hideLabels"
            v-touch:swipe.left="next"
            v-touch:swipe.right="prev"
            class="p-2 bg-[rgba(0,0,0,0.5)] text-white min-h-20 width-[80%] text-sm absolute w-full bottom-0 flex items-center justify-center"
            :class="{
              'p-3': !isLandscape
            }"
          >
            <div
              :class="{
                'max-w-[75%]': !isLandscape,
                'flex flex-row': isLandscape
              }"
            >
              <div
                :class="{
                  'pb-1': !isLandscape
                }"
              >
                <b>Description:</b>&nbsp;
              </div>
              <div>{{ description }}</div>
            </div>
          </div>
        </div>

        <!-- desktop -->
        <div v-if="!isMobile">
          <!-- title / tags -->
          <div class="flex items-center mb-3">
            <div v-if="title">
              <b>{{ title }}</b>
              <i
                v-if="tags.length"
                @click="showTags = !showTags"
                class="cursor-pointer ml-3 fa-solid fa-ellipsis-vertical"
              ></i>
            </div>
            <div
              v-if="tags"
              class="flex transition-opacity"
              :class="{
                'opacity-0': !showTags,
                'opacity-100': showTags
              }"
            >
              <div v-for="tag in getTags(tags)" class="bg-gray-200 ml-3 text-xs p-1 rounded-md">
                {{ tag }}
              </div>
            </div>
          </div>

          <!-- images -->
          <div v-if="imageSrc">
            <img
              @load="loaded"
              @error="errored"
              :src="useImageCDN(imageSrc, 'large')"
              :style="{
                'max-width': imageWidth,
                'max-height': imageHeight
              }"
            />
          </div>

          <!-- bunny video -->
          <div
            v-if="videoStream"
            class="relative bg-black"
            :style="{
              width: imageWidth,
              height: imageHeight
            }"
          >
            <iframe
              :src="videoStream"
              style="position: absolute; border: 0; top: 0; left: 0; width: 100%; height: 100%"
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
              allowfullscreen="true"
            ></iframe>
          </div>

          <!-- legacy video -->
          <div v-if="videoSrc">
            <video autoplay controls :style="{ height: videoHeight }">
              <source :src="videoSrc" type="video/mp4" />
            </video>
          </div>

          <!-- count for carousel -->
          <div
            v-if="carousel.length > 1"
            class="absolute left-0 w-full flex justify-center text-xs font-semibold mt-1 text-gray-500"
          >
            < {{ carouselIndex + 1 }} / {{ carousel.length }} >
          </div>

          <!-- description -->
          <div v-if="description && !isMobile" class="flex-none justify-left">
            <div
              class="mt-3"
              :class="{
                'mt-6': !isMobile
              }"
            >
              <b>Description</b>
            </div>
            <div class="mt-3">{{ description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
html.popupActive,
html.popupActive body.mobile {
  background: #000;
  overflow: hidden;
}

.disabled {
  @apply cursor-auto bg-transparent;
}
.disabled i {
  @apply hidden;
}
</style>

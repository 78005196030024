<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { convertCoto, usePricingConfigurationStore } from '../stores/pricing-configurations'

const props = withDefaults(
  defineProps<{
    value: number
    currency?: string
    decimals?: number
    locale?: string
    append_ISO_3166_1_Alpha_3?: boolean
  }>(),
  {
    currency: 'USD',
    decimals: 2,
    locale: 'en',
    append_ISO_3166_1_Alpha_3: false
  }
)

const pricingStore = usePricingConfigurationStore()
const { selectedCurrency } = storeToRefs(pricingStore)

const formatCurrency = (num: number, minimumFractionDigits = 2, locale = 'en') => {
  let resp = `${num}`
  if (!isNaN(num)) {
    resp = num.toLocaleString(locale, {
      style: 'currency',
      currency: props.currency === null ? selectedCurrency.value : props.currency,
      minimumFractionDigits: minimumFractionDigits
    })
  }

  if (props.append_ISO_3166_1_Alpha_3) {
    resp += ` ${props.currency}`
  }

  return resp
}
</script>

<template>
  {{
    formatCurrency(
      convertCoto(value, currency === null ? selectedCurrency : currency),
      decimals,
      locale
    )
  }}
</template>

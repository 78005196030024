import { useMemoize } from '@vueuse/core'
import { v4 as uuidv4 } from 'uuid'
import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'
import { KeyPair, utils } from 'near-api-js'
import { type KeyPairEd25519 } from 'near-api-js/lib/utils'
import { addYears, getUnixTime } from 'date-fns'
import {
  buildPayload as UcansBuildPayload,
  EdKeypair as UcansEdKeypair,
  encode as UcansEncode,
  parse as UcansParse,
  signWithKeypair as UcansSignWithKeypair
} from '@ucans/ucans'
import { concat as uint8arraysToConcat, toString as uint8arraysToString } from 'uint8arrays'
import {
  createApiRequestSignatureString,
  createApiRequestSignature,
  toBase64Signature
} from '@/utils/api-request-signing'
import useLazyPiniaStore from '@/utils/lazyPiniaStore'
import { getED25519Key } from '@toruslabs/openlogin-ed25519'
import { CloudflareWorkersApi } from '@/queries/cloudflare-workers'

type JsonBodyType = Record<string, unknown>

type DataOriginal = {
  networkId: string | null
  nearNamedWalletId: string | null
  secp256k1PrivateKey: string | Buffer | null
}

export const useUcanStore = defineStore('ucanStore', () => {
  const dataOriginal: DataOriginal = {
    networkId: null,
    nearNamedWalletId: null,
    secp256k1PrivateKey: null
  }

  const data = reactive<DataOriginal>({
    ...dataOriginal
  })

  const setNetworkId = (networkId: string | null) => (data.networkId = networkId)

  const setNearNamedWalletId = (nearNamedWalletId: string | null) =>
    (data.nearNamedWalletId = nearNamedWalletId)

  const setSecp256k1PrivateKey = (secp256k1PrivateKey: string | null) =>
    (data.secp256k1PrivateKey = secp256k1PrivateKey)

  const resetData = () => {
    data.networkId = null
    data.nearNamedWalletId = null
    data.secp256k1PrivateKey = null
  }

  const ucanNotificationsAudience = computed(() => {
    if (data.networkId === 'testnet') {
      return {
        didKey: 'did:key:z6MkqJURnkun1UzA5ZZXZA5D5fd5QMuybyg9aPe5WNPX6bbP',
        nearNamedWalletId: 'notification-cmg.testnet'
      }
    }

    return {
      didKey: 'did:key:z6Mkffyz5wgiCZzmd2Ho3sG3iXiUoMXW9Qw9ve8QK2jbrFPy',
      nearNamedWalletId: 'notification-cmg.near'
    }
  })

  const ed25519KeyPair = computed((): KeyPairEd25519 | null => {
    // ensure we have the data
    if (!data?.secp256k1PrivateKey) return null

    // Convert the secp256k1 key to ed25519 key
    const privateKeyEd25519 = getED25519Key(data.secp256k1PrivateKey).sk.toString('hex')
    const privateKeyEd25519Buffer = Buffer.from(privateKeyEd25519, 'hex')

    // Convert the private key to base58
    const privateKeyEd25519Base58 = utils.serialize.base_encode(privateKeyEd25519Buffer)

    // Convert the base58 private key to KeyPair
    return KeyPair.fromString(privateKeyEd25519Base58) as KeyPairEd25519
  })

  const ed25519PublicKeyString = computed(() => {
    // key starts with "ed25519:"
    return ed25519KeyPair.value?.getPublicKey()?.toString()
  })

  const nearImplicitWalletId = computed(() => {
    const key = ed25519PublicKeyString.value?.replace(/^ed25519:/, '')
    // @ts-ignore
    return key ? utils.serialize.base_decode(key).toString('hex') : null
  })

  const edDSAKeyPair = async () => {
    const privateKeyEd25519 = getED25519Key(data.secp256k1PrivateKey!).sk.toString('base64')
    return await UcansEdKeypair.fromSecretKey(privateKeyEd25519)
  }

  const edDSAKeyPairDid = async () => {
    return (await edDSAKeyPair()).did()
  }

  // convert "ed25519:..." to "did:key:..."
  const edDSAPublicKeyDid = async (publicKeyString: string) => {
    const ed25519Prefix = 'ed25519:'
    const BASE58_DID_PREFIX = 'did:key:z'
    const EDWARDS_DID_PREFIX = new Uint8Array([0xed, 0x01])

    const publicKeyU8Array = utils.serialize.base_decode(publicKeyString.replace(ed25519Prefix, ''))

    const bytes = uint8arraysToConcat([EDWARDS_DID_PREFIX, publicKeyU8Array])

    const base58Key = uint8arraysToString(bytes, 'base58btc')

    return BASE58_DID_PREFIX + base58Key
  }

  const createApiChallenge = () => {
    const message = createApiRequestSignatureString(
      data.networkId!,
      (data.nearNamedWalletId || nearImplicitWalletId?.value)!,
      ed25519PublicKeyString?.value!,
      Math.floor(new Date().getTime() / 1000)
    )

    const encodedMessage = new TextEncoder().encode(message)

    const signature = createApiRequestSignature(ed25519KeyPair.value!, encodedMessage)
    const signatureBase64 = toBase64Signature(signature)

    return {
      msg: message,
      s64: signatureBase64
    }
  }

  const apiBaseUrl = computed((): string => {
    // get the base url from the env
    let baseUrl = import.meta.env.VITE_CLOUDFLARE_WORKERS_API_ENDPOINT

    // if we don't have it, create one based on the hostname
    if (!baseUrl) {
      const hostname = window.location.hostname
      if (hostname.startsWith('test.')) {
        baseUrl = 'https://cmg-test.contentedworld.workers.dev'
      } else if (
        hostname.startsWith('preview.') ||
        hostname.startsWith('next.') ||
        hostname.startsWith('future.')
      ) {
        baseUrl = 'https://cmg-preview.contentedworld.workers.dev'
      } else if (hostname.startsWith('uat.')) {
        baseUrl = 'https://cmg-uat.contentedworld.workers.dev'
      } else {
        baseUrl = 'https://cmg.contentedworld.workers.dev'
      }
    }

    // send back what we have
    return baseUrl
  })

  const getApiBaseUrl = () => {
    return apiBaseUrl.value
  }

  const issueNotificationsUcan = async ({
    audienceDid = '',
    audienceWalletId = '',
    issuerEmailrsa = ''
  } = {}) => {
    // get the keypair for the issuer
    const issuerKeypair = await edDSAKeyPair()

    // we either use the Audience Did or treat this as self-signed
    audienceDid = audienceDid || issuerKeypair.did()

    // create a payload for the ucan
    const payload = await UcansBuildPayload({
      audience: audienceDid,
      issuer: issuerKeypair.did(),
      capabilities: [
        {
          with: { scheme: 'mailto', hierPart: issuerEmailrsa },
          can: { namespace: 'CONTENTEDWORLD', segments: ['NOTIFY'] }
        }
      ],
      facts: [
        {
          issuerAccountName: data.nearNamedWalletId,
          audienceAccountName: audienceWalletId || data.nearNamedWalletId
        }
      ],
      expiration: getUnixTime(addYears(new Date(), 3))
    })

    // sign the ucan with the issuer keypair
    const ucan = await UcansSignWithKeypair(payload, issuerKeypair)

    // base64 jwt-formatted auth token
    return UcansEncode(ucan)
  }

  /**
   * Asynchronously extracts a UCAN token for a given brief owner change operation between two user profiles.
   * This function looks up the profiles of the "from" and "to" users based on their IDs, determines who the candidate
   * for the brief owner change is, and then retrieves the UCAN token that authorizes the change. The function ensures
   * that both users have decentralized identifiers (DIDs) and that the candidate's profile contains the necessary UCAN
   * tokens for authorization.
   *
   * @async
   * @param {string} fromId - The identifier of the sender.
   * @param {string} toId - The identifier of the receiver.
   * @param {Object} brief - The brief containing candidate profile data.
   * @returns {Promise<string|undefined>} A promise that resolves to the UCAN token if found, or undefined if not.
   */
  const extractCandidateToBriefOwnerUCAN = async (
    fromId: string,
    toId: string,
    brief: SmartBrief
  ) => {
    try {
      // fetch the profile data
      const toProfile = (await fetchProfileMemoized(toId))?.data
      const fromProfile = (await fetchProfileMemoized(fromId))?.data

      // ensure we have the didKeys
      if (toProfile?.didKey && fromProfile?.didKey) {
        // determine who the candidate is so we can pull up their "candidateProfile" which has the ucan tokens
        const candidateId = brief.owner === fromId ? toId : fromId

        // filter down to the candidate profile
        const candidateProfile = brief?.candidateProfiles?.find(
          (cb) => cb?.account_id === candidateId
        )

        // fetch the ucan token
        const ucanTokenEntry = candidateProfile?.ucanTokens?.find(
          ({ aud, iss }: { aud: string; iss: string }) =>
            aud === fromProfile.didKey && iss === toProfile.didKey
        )

        // use the token if we have it
        return ucanTokenEntry?.token
      }
    } catch (err) {
      //
    }
  }

  /**
   * Asynchronously generates a self-signed UCAN (User-Controlled Access Network) token for a given user ID.
   * This function fetches the profile associated with the specified user ID, extracts the email RSA key from the profile,
   * and then issues notifications using the UCAN protocol if an email RSA key is present.
   *
   * @async
   * @param {string} toId Unique identifier used to generate the UCAN token. This ID is used to fetch the user's profile.
   * @returns {Promise<string|undefined>} Promise resolving UCAN token or undefined if unable to
   */
  const getSelfSignedUCAN = async (toId: string) => {
    try {
      const fetchedProfile = (await fetchProfileMemoized(toId))?.data

      const emailrsa = fetchedProfile?.emailrsa

      let ucanToken
      if (emailrsa) {
        ucanToken = await issueNotificationsUcan({
          issuerEmailrsa: emailrsa
        })
      }

      return ucanToken
    } catch (err) {
      //
    }
  }

  const parseNotificationsUcanToken = (ucanToken: string) => {
    return UcansParse(ucanToken)
  }

  const checkUcanTokenIssuerAccountName = (
    ucanTokenString: string,
    issuerAccountName: string
  ): boolean => {
    try {
      return UcansParse(ucanTokenString).payload?.fct?.some(
        (fct: any) => fct?.issuerAccountName === issuerAccountName
      )
        ? true
        : false
    } catch (error) {
      console.error('checkUcanTokenIssuerAccountName : ', error)
      return false
    }
  }

  const fetchDelegatedToken = async (ucanToken: string) => {
    return CloudflareWorkersApi().post(
      '/n/delegate',
      {},
      {
        headers: {
          Authorization: 'Bearer ' + ucanToken
        }
      }
    )
  }

  const fetchProfile = async (profileId: string) => {
    return CloudflareWorkersApi().get('/profiles/' + profileId)
  }

  const fetchProfileMemoized = useMemoize(async (profileId: string) => {
    return fetchProfile(profileId)
  })

  const notify = async (jsonBody: JsonBodyType, ucanToken: string) => {
    return CloudflareWorkersApi().post('/n/ping', jsonBody, {
      headers: {
        Authorization: 'Bearer ' + ucanToken
      }
    })
  }

  /**
   * Sends a notification using a delegated UCAN token.
   *
   * This function first retrieves the user profile associated with the given `toId`.
   * It then filters the UCAN tokens found in the profile for the specific audience
   * defined by `ucanNotificationsAudience`. The last token for this audience is used
   * to fetch a delegated token. If the delegated token is successfully retrieved,
   * a notification is sent with the provided `jsonBody`.
   *
   * @async
   * @function notifyUsingDelegatedUCAN
   * @param {string} toId - The identifier of the user to whom the notification will be sent.
   * @param {Object} jsonBody - The JSON payload to be sent in the notification.
   * @returns {Promise<void>} - A promise that resolves when the notification has been sent or rejects if an error occurs.
   * @throws {Error} - Throws an error if any step in the notification process fails.
   */
  const notifyUsingDelegatedUCAN = async (toId: string, jsonBody: JsonBodyType) => {
    try {
      const fetchedProfile = (await fetchProfileMemoized(toId))?.data

      const ucanToken = fetchedProfile?.ucanTokens?.find(
        (ucanToken: UcanToken) =>
          ucanToken.aud === ucanNotificationsAudience.value.didKey &&
          checkUcanTokenIssuerAccountName(ucanToken.token, toId)
      )

      if (ucanToken?.token) {
        const delegatedToken = (await fetchDelegatedToken(ucanToken.token))?.data
        if (delegatedToken?.token) {
          await notify(jsonBody, delegatedToken.token)
        }
      }
    } catch (err) {
      //
    }
  }

  /**
   * Sends a notification using a self-signed UCAN (User Controlled Access Network) token.
   *
   * This function first retrieves the user profile associated with the given `toId`.
   * It then creates the UCAN token with the `emailrsa` using `issueNotificationsUcan`.
   * On successfully issuing the token, a notification is sent with the provided `jsonBody`.
   *
   * @async
   * @function notifyUsingSelfSignedUCAN
   * @param {string} toId - The ID of the recipient.
   * @param {Object} jsonBody - The JSON body of the notification.
   * @returns {Promise<void>} - A promise that resolves when the notification has been sent or rejects if an error occurs.
   * @throws {Error} - Throws an error if any step in the notification process fails.
   */
  const notifyUsingSelfSignedUCAN = async (toId: string, jsonBody: JsonBodyType) => {
    try {
      const ucanToken = await getSelfSignedUCAN(toId)

      if (ucanToken) {
        notify(jsonBody, ucanToken)
      }
    } catch (err) {
      //
    }
  }

  /**
   * Sends a notification using a brief candidate profile and a UCAN token.
   *
   * This function first retrieves the user profiles associated with the given `fromId`,
   * and the `toId`. Then it looks up the UCAN Token from briefs. If it finds one, it then
   * sends a notification with the provided `jsonBody`.
   *
   * @async
   * @function notifyUsingBriefCandidateProfileUCAN
   * @param {string} fromId - The identifier of the sender.
   * @param {string} toId - The identifier of the receiver.
   * @param {Object} brief - The brief containing candidate profile data.
   * @param {Object} jsonBody - The JSON body to be used in the notification.
   */
  const notifyUsingBriefCandidateProfileUCAN = async (
    fromId: string,
    toId: string,
    brief: SmartBrief,
    jsonBody: JsonBodyType
  ) => {
    try {
      /*
      // get the token from the brief response
      const ucanTokenEntry = await extractCandidateToBriefOwnerUCAN(fromId, toId, brief)

      // use the token if we have it
      if (ucanTokenEntry) {
        await notify(jsonBody, ucanTokenEntry)
      }
      */

      await CloudflareWorkersApi().post(`/n/brief/pong/${brief.id}`, jsonBody, {
        params: {
          to: toId,
          from: fromId
        }
      })
    } catch (err) {
      //
    }
  }

  /**
   * Uploads a video to Bunny using TUS protocol. This function handles either the signing or the starting of the
   * video upload process, depending on the action specified. It uses a memoization technique to cache and reuse
   * results for the same arguments to improve performance.
   *
   * @param {'briefs' | 'profiles'} type - Type of video to determine the upload directory, this can be 'briefs' or 'profiles'.
   * @param {string} typeId - Unique identifier for the type instance, used to construct the upload URL.
   * @param {'sign' | 'start'} action - Action to perform, 'start' initiates the upload and 'sign' gets a signature for the upload.
   * @param {File} file - The video file to be uploaded.
   * @param {string} ucanToken - The authorization token used to authenticate the request.
   *
   * @returns {Promise<Object>} A promise that resolves to the response from the API call.
   */
  const uploadVideoToBunnyUsingTus = useMemoize(async (type, typeId, action, file, ucanToken) => {
    return await CloudflareWorkersApi().post(
      `/tus/bunny/video/${type}/${action}?id=${typeId}`,
      {
        title: uuidv4()
      },
      {
        headers: {
          Authorization: 'Bearer ' + ucanToken
        }
      }
    )
  })

  return {
    data,
    setNetworkId,
    setNearNamedWalletId,
    setSecp256k1PrivateKey,
    ed25519KeyPair,
    ed25519PublicKeyString,
    nearImplicitWalletId,
    edDSAKeyPair,
    edDSAKeyPairDid,
    edDSAPublicKeyDid,
    createApiChallenge,
    resetData,
    issueNotificationsUcan,
    extractCandidateToBriefOwnerUCAN,
    getSelfSignedUCAN,
    parseNotificationsUcanToken,
    checkUcanTokenIssuerAccountName,
    fetchDelegatedToken,
    fetchProfile,
    fetchProfileMemoized,
    notify,
    notifyUsingDelegatedUCAN,
    notifyUsingSelfSignedUCAN,
    notifyUsingBriefCandidateProfileUCAN,
    ucanNotificationsAudience,
    uploadVideoToBunnyUsingTus,
    getApiBaseUrl
  }
})

export type UcanStoreType = ReturnType<typeof useUcanStore>

export const lazyUcanStore = useLazyPiniaStore<UcanStoreType>(useUcanStore) as UcanStoreType

<script lang="ts" setup>
import { computed } from 'vue'

export type Props = {
  profile?: ProfileData
  wrapperClass?: string
}

const props = withDefaults(defineProps<Props>(), {
  wrapperClass: ''
})

const profileSocials = computed(() => {
  const socials: Array<{
    name: string
    url: string
    icons: string[]
  }> = []

  const networks: Record<string, boolean> = {
    tiktok: true,
    instagram: true,
    youtube: true,
    twitter: true,
    website: true,
    facebook: true,
    behance: true,
    linkedin: true,
    dribbble: true,
    artstation: true
  }

  for (const network in networks) {
    const networkKey = network as keyof SocialMedia
    if (networkKey && props.profile?.socials && props.profile?.socials[networkKey]) {
      socials.push({
        name: networkKey,
        url: `${props.profile.socials[networkKey]}`,
        icons: ['fa-brands', 'fa-' + (networkKey || '').toLowerCase()]
      })
    }
  }

  // sort social media icons in certain order
  const order: Record<string, number> = {
    tiktok: 1,
    instagram: 2,
    youtube: 3,
    twitter: 4,
    website: 5,
    facebook: 6,
    behance: 7,
    linkedin: 8,
    dribbble: 9,
    artstation: 10
  }
  socials.sort((a, b) => order[a.name] - order[b.name])

  return socials
})
</script>

<template>
  <div v-if="profileSocials.length">
    <slot name="title"></slot>
    <div :class="props.wrapperClass">
      <a v-for="social in profileSocials" :key="social.name" :href="social.url" target="_blank" class="my-2 mx-1">
        <i :class="social.icons"></i>
      </a>
    </div>
  </div>
</template>

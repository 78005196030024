<script lang="ts" setup>
import { onMounted, onUnmounted, watch, ref } from 'vue'
import { mobileDetect } from '@/utils/browser'
import { extractFileUrl } from '@/utils/mediaDecrypt'
import { useImageCDN } from '@/utils/image'

const props = withDefaults(
  defineProps<{
    brief: SmartBrief
    briefImage: string
    isAccepted: boolean
    isBrandView: boolean
  }>(),
  {
    isAccepted: false,
    isBrandView: false
  }
)

const isMobile = ref(mobileDetect())
const updatePageWidth = () => {
  isMobile.value = mobileDetect()
}
onMounted(() => {
  updatePageWidth()
  window.addEventListener('resize', updatePageWidth)
})
onUnmounted(() => {
  window.removeEventListener('resize', updatePageWidth)
})

const getBrandName = () => {
  if (props.isAccepted) {
    return props.brief.brandName
  }
  return props.brief.ndaBrandName
}

const getTitle = () => {
  if (props.isAccepted) {
    return props.brief.title
  }
  return props.brief.ndaTitle
}

const showDescription = ref(false)
const fadeIn = ref(false)
watch(showDescription, (nv) => {
  if (nv) {
    setTimeout(() => {
      fadeIn.value = true
    }, 10)
  } else {
    fadeIn.value = false
  }
})

const formatTypes = (types: any) => {
  return types?.replaceAll(',', ', ')
}

const calculatePotentialEarnings = () => {
  if (
    props.brief?.paymentPreference?.payment_to_creator &&
    props.brief?.paymentPreference?.number_of_creators
  ) {
    return (
      Number(props.brief.paymentPreference.payment_to_creator) /
      Number(props.brief.paymentPreference.number_of_creators)
    )
  }
  return Number(props.brief.budget) * 0.9
}
</script>

<template>
  <div v-if="brief">
    <!-- Desktop View -->
    <div
      v-if="!isMobile"
      class="bg-white rounded-md text-xl flex flex-wrap items-start md:self-center md:justify-between"
    >
      <div class="flex w-full text-sm md:w-full items-center">
        <div class="flex-grow flex items-center">
          <div class="mx-6">
            <img
              v-if="props.isAccepted && brief.logo"
              :src="useImageCDN(extractFileUrl(brief.logo))"
              alt="Logo"
              class="max-h-12 md:max-h-48"
            />
            <img
              v-if="props.isAccepted && brief.brandLogo"
              :src="useImageCDN(extractFileUrl(brief.brandLogo))"
              alt="Logo"
              class="max-h-12 md:max-h-48"
            />
            <img
              v-if="!props.isAccepted && brief.ndaCompanyLogo"
              :src="useImageCDN(extractFileUrl(brief.ndaCompanyLogo))"
              alt="Logo"
              class="max-h-12 md:max-h-48"
            />
          </div>

          <!-- Name, format etc -->
          <div>
            <div class="my-1">
              <strong class="font-semibold tracking-wide">Name:</strong>
              <span class="ml-1">{{ getTitle() }}</span>
            </div>
            <div class="my-1">
              <strong class="font-semibold tracking-wide">Format:</strong>
              <span class="ml-1">{{ formatTypes(props.brief.contentTypes) }}</span>
            </div>
            <div class="my-1">
              <strong class="font-semibold tracking-wide">Potential Earnings:</strong>
              <span class="ml-1">${{ calculatePotentialEarnings() }}</span>
            </div>
            <div class="mt-5">
              <strong class="font-semibold tracking-wide">Published by:</strong>
              <span class="ml-1">{{ getBrandName() }}</span>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/3">
          <img
            v-if="briefImage"
            class="mx-auto h-12 md:h-60 md:ml-2 rounded-b-md md:rounded-b-none md:rounded-r-md md:float-right"
            :src="useImageCDN(extractFileUrl(briefImage), 'medium')"
            alt="Image"
          />
        </div>
      </div>
    </div>

    <!-- Mobile View -->
    <div v-else class="flex flex-col bg-white rounded-md mb-6 items-center justify-center">
      <div class="w-full mb-3">
        <img
          v-if="briefImage"
          class="w-full h-auto md:w-full md:h-auto lg:w-auto lg:h-60 lg:float-right object-cover rounded-tl-md rounded-tr-md md:rounded-none md:rounded-tl-md md:rounded-tr-md"
          :src="useImageCDN(extractFileUrl(briefImage), 'medium')"
          alt="Image"
        />
      </div>
      <div class="w-full flex items-center justify-between md:mb-3 md:p-3">
        <div class="font-semibold flex-grow pr-3">
          {{ getTitle() }}
        </div>
        <div class="flex flex-none space-x-4">
          <img
            v-if="props.isAccepted && brief.logo"
            :src="useImageCDN(extractFileUrl(brief.logo), 'thumb')"
            alt="Logo"
            class="h-12 md:h-48"
          />
          <img
            v-if="props.isAccepted && brief.brandLogo"
            :src="useImageCDN(extractFileUrl(brief.brandLogo), 'thumb')"
            alt="Logo"
            class="h-12 md:h-48"
          />
          <img
            v-if="!props.isAccepted && brief.ndaCompanyLogo"
            :src="useImageCDN(extractFileUrl(brief.ndaCompanyLogo), 'thumb')"
            alt="Logo"
            class="h-12 md:h-48"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

withDefaults(
  defineProps<{
    linkTo: any
  }>(),
  {
    linkTo: () => ({})
  }
)

const inviteLinkRef = ref<{
  $el: HTMLAnchorElement
} | null>(null)
const copied = ref<boolean>(false)

const copyLink = () => {
  // get the href link
  const link = inviteLinkRef.value?.$el.href

  // copy
  const tempInput = document.createElement('input')
  tempInput.value = link!
  document.body.appendChild(tempInput)
  tempInput.select()
  document.execCommand('copy')
  document.body.removeChild(tempInput)

  // mark as copied and then hide again after a few seconds
  copied.value = true
  setTimeout(() => {
    copied.value = false
  }, 2000)
}
</script>

<template>
  <div class="relative inline-block">
    <router-link
      :to="linkTo"
      target="_blank"
      rel="noreferrer noopener"
      @click.prevent="copyLink"
      ref="inviteLinkRef"
      class="text-blue-500 hover:underline"
    >
      <i class="fal fa-clipboard"></i>
      <span class="sr-only">Copy Link</span>
    </router-link>
    <transition name="fade">
      <div
        v-if="copied"
        class="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white px-2 py-1 rounded text-sm mb-2"
      >
        Copied!
      </div>
    </transition>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<script lang="ts" setup>
import { ref } from 'vue'
import { useNearWalletStore } from '@/stores/near-wallet'
import ConfirmDialog from '@/components/InputCurrencyDialog.vue'

const props = withDefaults(
  defineProps<{
    buttonText: string
    okButtonText: string
    message: string
    title: string
  }>(),
  {
    buttonText: 'Login',
    okButtonText: 'Login First',
    message: 'You need to login to do this action.',
    title: 'Login Required'
  }
)

const nearWalletStore = useNearWalletStore()

const confirmDialog = ref<InstanceType<typeof ConfirmDialog>>()

const btnCallback = async () => {
  const ok = await confirmDialog.value?.show({
    title: props.title,
    message: props.message,
    okButton: props.okButtonText
  })

  if (ok) {
    nearWalletStore.doLogin()
  }
}
</script>

<template>
  <div>
    <div v-if="nearWalletStore.loggedIn">
      <slot />
    </div>
    <div v-if="!nearWalletStore.loggedIn">
      <a
        href="#"
        class="bg-cm-blue-light-400 hover:bg-cm-blue-light-500 py-3 px-4 rounded-md text-white"
        @click.prevent="btnCallback"
      >
        <button type="button">{{ buttonText }}</button>
      </a>
      <div class="text-left">
        <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
// @ts-nocheck
import { ref } from 'vue'
import SearchInputBox from '@/components/SearchInputBox.vue'
import SelectBox from '@/components/SelectBox.vue'

const emit = defineEmits(['init', 'update'])

// this is a bodge to stop the compiler not defining emit
emit('init', true)

const props = withDefaults(
  defineProps<{
    filters: Array<{
      key: string
      label: string
      default: string
      options: Array<{
        id: string
        label: string
      }>
    }>
    search: string
  }>(),
  {
    search: ''
  }
)

const search = ref(props.search)
const filterVals = []

const validOption = (val, ops) => {
  for (var i = 0; i < ops.length; i++) {
    if (val == ops[i].id) {
      return true
    }
  }
}

// update any newly appeared / disappeared fields with defaults
const updateDefaults = (update = false) => {
  for (var f in props.filters) {
    var filter = props.filters[f]
    if (
      !update ||
      !filterVals[filter.key] ||
      !filter.default ||
      !validOption(filterVals[filter.key], filter.options)
    ) {
      filterVals[filter.key] = filter.default
    }
  }
  // console.debug('SearchFilters',filterVals);
}

const normaliseFilters = () => {
  let copy = {}
  for (let k in filterVals) {
    let v = filterVals[k]
    // qgl wants nothing for things that describe 'all' / 'any'
    if (v == 'any' || v == 'all') {
      v = ''
    }
    copy[k] = v
  }
  return copy
}

const update = (e) => {
  // search field
  if (typeof e.key === 'undefined') {
    return update({
      key: e.target.name,
      val: e.target.value
    })
  }

  // set new value
  filterVals[e.key] = e.val

  // tell the view
  emit('update', normaliseFilters())

  // update any newly appeared / disappeared fields with defaults
  updateDefaults(true)
}

filterVals.search = ''
updateDefaults()

const debug = false
</script>

<template>
  <pre
    v-if="debug"
    class="absolute mt-24 text-xs max-h-screen overflow-auto"
    style="background-color: rgb(255, 255, 255, 0.75)"
  >
		{{ filters }}
	</pre
  >
  <div class="flex flex-wrap flex-row gap-2 my-3">
    <SearchInputBox @input="update" v-model="search" />
    <SelectBox
      @update="update"
      :modelValue="filter.default"
      :options="filter"
      v-for="filter in filters"
      :key="filter.key"
    />
  </div>
</template>

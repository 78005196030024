<script lang="ts" setup>
import { RouterLink } from 'vue-router'
import Wrapper from '@/components/Pages/Creatives/Wizard/Shared/Wrapper.vue'

const props = withDefaults(
  defineProps<{
    onDone?: () => void | undefined
    showStep?: string
  }>(),
  {
    onDone: (): void => {},
    showStep: undefined
  }
)

const nextPage = () => {
  props?.onDone()
}
</script>

<template>
  <Wrapper>
    <div v-if="!showStep" class="mt-9 border-2 border-[#5386EA] rounded-lg py-3 px-6">
      <h2 class="p-0 m-0 mt-3 text-left">Full brief</h2>
      <div class="my-6">
        <p class="text-left text-md font-medium text-gray-600">
          Please read the brief carefully. If you would like to respond to the brief click 'Start'.
          If you have any questions that are not answered by the brief, please feel free to reach
          out to the brand by posting a question on the Q&A page.
        </p>
      </div>
      <div class="mb-3">
        <RouterLink
          :to="{
            name: 'briefs.list'
          }"
        >
          <button
            type="button"
            class="py-1.5 px-6 min-w-28 border-2 border-[#FF008B] text-[#FF008B] rounded-full hover:bg-[#FF008B] hover:text-white"
          >
            <span>Decline</span>
          </button>
        </RouterLink>
        &nbsp;
        <button
          type="button"
          class="py-1.5 px-6 min-w-28 border-2 border-[#FF008B] text-white bg-[#FF008B] rounded-full hover:opacity-85"
          @click="nextPage()"
        >
          <span>Start</span>
        </button>
      </div>
    </div>
    <div v-else class="mt-14 py-3 px-6">
      <div>
        <p class="text-center text-md font-bold text-black">Ready to get started?</p>
      </div>
      <div class="mt-8 mb-8 flex justify-center items-center space-x-4">
        <RouterLink
          :to="{
            name: 'briefs.list'
          }"
        >
          <button
            type="button"
            class="py-1.5 px-6 min-w-28 border-2 border-[#FF008B] text-[#FF008B] rounded-full hover:bg-[#FF008B] hover:text-white"
          >
            <span>Decline</span>
          </button>
        </RouterLink>
        &nbsp;
        <button
          type="button"
          class="py-1.5 px-6 min-w-28 border-2 border-[#FF008B] text-white bg-[#FF008B] rounded-full hover:opacity-85"
          @click="nextPage()"
        >
          <span>Start</span>
        </button>
      </div>
    </div>
  </Wrapper>
</template>

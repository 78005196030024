<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { onMounted } from 'vue'
import MarketplaceLayout from '@/Layouts/MarketplaceLayout.vue'

const { t: $t } = useI18n({})

onMounted(() => {
  setTimeout(() => {
    if (window.openBot) {
      window.openBot('smartBrief2')
    }
  }, 1000)
})
</script>
<template>
  <MarketplaceLayout :title="$t('marketplace.briefs.title')" selectedLinkId="briefs">
    <div>Loading landbot....</div>
  </MarketplaceLayout>
</template>

import { createMachine, assign } from 'xstate'

interface BriefMachineContext {
  data: {
    brief: Partial<SmartBrief>
  }
}

type BriefMachineEvent =
  | { type: 'START'; brief: Partial<SmartBrief> }
  | { type: 'SET_NDA_STATUS'; nda_status: boolean }
  | { type: 'SET_ACCEPTED_STATUS'; accepted_status: boolean }
  | { type: 'CANDIDATE_ACCEPT' }
  | { type: 'CANDIDATE_REJECT' }
  | { type: 'CANDIDATE_SHORTLIST' }
  | { type: 'CANDIDATE_CONTENT_UPLOAD' }
  | { type: 'CANDIDATE_CONTENT_UPLOAD_ACCEPT' }
  | { type: 'CANDIDATE_CONTENT_UPLOAD_REJECT' }

const briefMachineNDAStatus = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QCMBOBLMAzAwgewDsAXMYgOQBEBBAZSIEMiBXWAOlgdSIGIaAVKgCU+AbQAMAXUSgADnljoi6QtJAAPRAEYAzGNZjNAVgBs2zZoDs27QCYxY4wBoQAT0TaLAFlafjADgtjY08xQwsvT20AXyjnNExcQhJyajpGFnYwIkpaBmZYXgBRPgB9HJL+Kj4AVRpxKSQQOQUlFUaNBEjtVm1DM21jMT9tT09w5zcED29fAKCQsIjo2JB47HxiUmzUvIzYLKoAY0OwGRIINPyi0qocHEKABT5CigqBGrrJVWbFZQJVDp+Gw2ViaSKBYyGYGaGzDCbuLw+fwQhbhUbLOIYdZJLY5S4ZQ70AgQdAQRhgQRgABumAA7twcFRKABJajPEq3e5PerfeS-NqgDo6MTdXr2Cx+SKePzGCXwqb+fR9MGSgKeGwATg1MUxCQ2yW2uXSbEJxNJ5MpNLA9MZLLZhRKgkKAClCjhRF9Gj9Wv92lpdKLDOLJSMZXLXIhfN0LA4bBYNZDzOEHDrVljEpsUkb8qxTSSySRqjIADZ4egQBlMiisqoOnAAeTIzybJWqDwAMvWqBQeV6+T6Af6LJpWLCdL5pTp5vLLMZWF5-DKjFremFU2sMwa8bsTUT8+Si6Xy5a6ZW7bWSg2m4UW23O92OXdHh6GrJ+39BwhhYHg1Kw355T8EcxA1DxDE8LVjE0PxJU0dd031XEdmNXM93NQsSzLCAT2tM9q3tS9G2bUo7y7V4nVdd1ezfFoPz9L8Ax6IMxAlP9ZQAiMEDjEEQnsSxbAGIErBiFYCDwCA4FUDdEKzfF4D7WiBXURAAFonE4tT4L1HFZJ3dhOCIXlFN9QVIxseVtA1CxWHAyUJRsWwoNGYwtOxTNDTkzIPJ3Iz+RM5SuIlHxpg1QwdFCixwIsqybOldUNVhYJtD8PpXM3JDsz2A5jlOc45N8gd6NMPQ-FjXwNUiIDous2yxiBRywV8NKZO8lC83QilqTpAq6NMr8QOsvjNFjLUJS1arYrs+qbCczxmp01qc3agswEPLCeqUoUgj8edQJSsZQmlYJ5U1ayrITUq7ClfwMTTbT3O3Nq0JWtbjy660Nv8oUHBHTwjGCbboNCjUZyMfRZlAmNDqhOaVmkhbHpzLB0AIdBYAACxwZ6LTAAArMBDnOT7P00bbduS8CY1s47OOlQwbMskCDBMGw+kMeaHuQpGUbRzHsZIGgmGQABbNGFEII4TjOSBifo0nFXjCmDupzwZzEEEEshSEgxhYJIpEqIgA */
    id: 'briefContentNDAStatus',
    initial: 'start',
    context: {
      data: {
        brief: {}
      }
    },
    types: {} as {
      context: BriefMachineContext
      events: BriefMachineEvent
      ations: {
        type: 'updateBrief'
      }
    },
    states: {
      start: {
        on: {
          START: {
            actions: ['updateBrief'],
            target: 'setNDAStatus'
          }
        }
      },
      setNDAStatus: {
        on: {
          SET_NDA_STATUS: {
            actions: ['updateBrief'],
            target: 'setAcceptedStatus'
          }
        }
      },
      setAcceptedStatus: {
        on: {
          SET_ACCEPTED_STATUS: {
            actions: ['updateBrief'],
            target: 'candidateReview'
          }
        }
      },
      candidateReview: {
        on: {
          CANDIDATE_ACCEPT: {
            actions: ['updateBrief'],
            target: 'candidateUpload'
          },
          CANDIDATE_REJECT: {
            actions: ['updateBrief'],
            target: 'finishCandidateRejected'
          },
          CANDIDATE_SHORTLIST: {
            actions: ['updateBrief'],
            target: 'finishCandidateShortlisted'
          }
        }
      },
      candidateUpload: {
        on: {
          CANDIDATE_CONTENT_UPLOAD: {
            actions: ['updateBrief'],
            target: 'candidateUploadReview'
          }
        }
      },
      candidateUploadReview: {
        on: {
          CANDIDATE_CONTENT_UPLOAD_ACCEPT: {
            actions: ['updateBrief'],
            target: 'finishCandidateSubmissionAccepted'
          },
          CANDIDATE_CONTENT_UPLOAD_REJECT: {
            actions: ['updateBrief'],
            target: 'candidateUpload'
          }
        }
      },
      finishCandidateRejected: {
        type: 'final'
      },
      finishCandidateShortlisted: {
        type: 'final'
      },
      finishCandidateSubmissionAccepted: {
        type: 'final'
      }
    }
  },
  {
    actions: {
      updateBrief: assign({
        data: ({ context, event }) => {
          // depending on whether this is start handle this differently
          let briefChanges
          if (event && event?.type === 'START') {
            briefChanges = event.brief || {}
          } else {
            // remove the 'type' key
            briefChanges = Object.keys(event || {})
              .filter((key) => key !== 'type')
              .reduce((acc, key) => {
                return {
                  ...acc,
                  [key]: event[key as keyof typeof event]
                }
              }, {})
          }

          // new brief
          const newBrief = {
            ...context.data.brief,
            ...briefChanges
          }

          // update the brief
          return {
            ...context.data,
            brief: newBrief
          }
        }
      })
    }
  }
)

const briefMachine = createMachine({
  id: 'newBrief',
  initial: 'setTitle',
  context: {
    data: {
      brief: {}
    }
  },
  types: {} as {
    context: {
      data: {
        brief: Partial<SmartBrief>
      }
    }
  },
  states: {
    setTitle: {
      on: {
        SET_TITLE: {
          actions: assign({
            data: ({ context, event }) => ({
              brief: { ...context.data.brief, title: event.title }
            })
          }),
          target: 'setOwner'
        }
      }
    },
    setOwner: {
      on: {
        SET_OWNER: {
          actions: assign({
            data: ({ context, event }) => ({
              brief: { ...context.data.brief, owner: event.owner }
            })
          }),
          target: 'setType'
        }
      }
    },
    setType: {
      on: {
        SET_TYPE: {
          actions: assign({
            data: ({ context, event }) => ({
              brief: { ...context.data.brief, type: event.briefType }
            })
          }),
          target: 'setAudiences'
        }
      }
    },
    setAudiences: {
      on: {
        SET_AUDIENCES: {
          actions: assign({
            data: ({ context, event }) => ({
              brief: { ...context.data.brief, audience: event.audiences }
            })
          }),
          target: 'setObjective'
        }
      }
    },
    setObjective: {
      on: {
        SET_OBJECTIVE: {
          actions: assign({
            data: ({ context, event }) => ({
              brief: { ...context.data.brief, campaign_objective: event.objective }
            })
          }),
          target: 'setImage'
        }
      }
    },
    setImage: {
      on: {
        SET_IMAGE: {
          actions: assign({
            data: ({ context, event }) => ({
              brief: { ...context.data.brief, image: event.imageUrl, logo: event.logoUrl }
            })
          }),
          target: 'setMinimumPrice'
        }
      }
    },
    setMinimumPrice: {
      on: {
        SET_MINIMUM_PRICE: {
          actions: assign({
            data: ({ context, event }) => ({
              brief: { ...context.data.brief, image: event.minimumPrice }
            })
          }),
          target: 'setMetaData'
        }
      }
    },
    setMetaData: {
      on: {
        SET_META_DATA: {
          actions: assign({
            data: ({ context, event }) => ({
              brief: { ...context.data.brief, meta_data: event.metaData }
            })
          }),
          target: 'completed'
        }
      }
    },
    completed: {
      type: 'final'
    }
  }
})

export { briefMachine, briefMachineNDAStatus, type BriefMachineEvent }

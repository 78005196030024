<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import BriefsLayout from '@/Layouts/BriefsLayout.vue'
import TabNavigation, { type TabMenuItemLink } from '@/components/TabNavigation.vue'
import AllBriefs from '@/components/Pages/Briefs/AllBriefs.vue'
import MyBriefs from '@/components/Pages/Briefs/MyBriefs.vue'

const $route = useRoute()
const $router = useRouter()

withDefaults(
  defineProps<{
    //
  }>(),
  {
    //
  }
)

const { t: $t } = useI18n({})

const activeTab = ref<string>(String($route.query.tab || ''))

const tabMenuItemLink = (item: TabMenuItemLink) => {
  let queryParams = {}
  if (!item.href && item.id !== '' && item.id !== 'all') {
    queryParams = {
      tab: item.id
    }
  }

  const url = !item.href
    ? $router.resolve({
        name: 'briefs.list',
        query: queryParams
      }).href
    : item.href

  return {
    ...item,
    selected: activeTab.value === item.id || (activeTab.value === '' && item.id === 'all'),
    href: url
  }
}

const tabMenuItems = ref<Array<TabMenuItemLink>>(
  [
    { id: 'all', label: 'All Briefs' },
    { id: 'my', label: 'My Briefs' },
    { id: 'create-brief', href: '/marketplace/create-brief', label: 'Create a Brief' }
  ].map(tabMenuItemLink)
)

watch(
  () => $route.query.tab,
  (nv, ov) => {
    if (nv !== ov) {
      activeTab.value = String(nv || '')
      tabMenuItems.value = tabMenuItems.value.map(tabMenuItemLink)
    }
  }
)
</script>

<template>
  <BriefsLayout :title="$t('studio.briefs.title')" selectedLinkId="briefs">
    <div class="mt-2" :key="activeTab">
      <TabNavigation :links="tabMenuItems" />
    </div>
    <AllBriefs v-if="activeTab === ''" />
    <MyBriefs v-if="activeTab === 'my'" />
  </BriefsLayout>
</template>

import { ref } from 'vue'
import { debounceLog } from '@/utils/logging'
import { TUS_ENDPOINT_TEMP, TUS_ENDPOINT_VIDEO, TUS_ENDPOINT_GENERAL } from '@/utils/mediaConstants'
import { isVideo } from '@/utils/mediaFileTypes'
import { selfSignedUcanToken, fileUploadJwt } from '@/utils/ucan'
import { useUcanStore } from '@/stores/ucan'

import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import Unsplash from '@uppy/unsplash'
import Facebook from '@uppy/facebook'
import Instagram from '@uppy/instagram'
import ImageEditor from '@uppy/image-editor'
import Tus from '@uppy/tus'

import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'
import '@uppy/image-editor/dist/style.css'

let ucanStore: any = null

const imageBucket = ref('')
const imageBucketHostCDN = ref('')
const BunnyVideoId = ref('')
const BunnyLibraryId = ref('')

const uppyInstances: any = []
const uppyInstancesState: any = []

let fileAdded = false

export const clearUppyInstance = (target: string) => {
  // console.debug('clearUppyInstance', target)
  const el = document.getElementById(target)
  if (el) {
    el.innerHTML = ''
  }
  uppyInstances[target] = null
  uppyInstancesState[target] = []
}

export const getUppyState = (target: string, key: any) => {
  if (key) {
    if (typeof uppyInstancesState[target][key] !== 'undefined') {
      return uppyInstancesState[target][key]
    } else {
      console.error('setUppyState :', target, 'No such key', key)
    }
  }
  return uppyInstancesState[target]
}

export const setUppyState = (target: string, key: string, val: any) => {
  try {
    if (typeof uppyInstancesState[target] !== 'undefined') {
      uppyInstancesState[target][key] = val
      // console.info('setUppyState :', target, ':', key, '=', val)
    } else {
      console.error('setUppyState :', target, 'No such key', key)
    }
  } catch (e) {
    console.error('setUppyState :', target, 'No such key', key, e)
  }
}

const removeDarkTheme = () => {
  // disable uppy dark theme
  setTimeout(() => {
    const els = document.querySelectorAll('div[data-uppy-theme]')
    if (els.length) {
      els.forEach((element) => {
        element.removeAttribute('data-uppy-theme')
      })
    }
  }, 100)
}

export const getUppyInstance = (target: string) => {
  return {
    uppyInstance: uppyInstances[target],
    uppyInstanceState: uppyInstancesState[target],
    imageBucket: imageBucket,
    imageBucketHostCDN: imageBucketHostCDN,
    BunnyVideoId: BunnyVideoId,
    BunnyLibraryId: BunnyLibraryId
  }
}

let lastAccountId: string = ''
export const initUppy = (
  accountId: string,
  target: string,
  options: any,
  callbackComplete: Function
): any => {
  if (!accountId || !target) {
    console.error('initUppy : missing accountId [', accountId, '] or target [', target, ']')
    return {}
  }

  // if the account changes, clear the old instance
  if (lastAccountId !== accountId) {
    debounceLog('initUppy : accountId change detected ' + accountId)
    clearUppyInstance(target)
    lastAccountId = accountId
  }

  // exists already
  if (uppyInstances[target]) {
    // check dashboard exists, vue may have trashed it
    const dashEl = document.getElementById(target)
    if (!dashEl?.innerHTML) {
      // UploadUppy : dashboard has been destroyed by vue that has redrawn the component!!
      clearUppyInstance(target)
      return initUppy(accountId, target, options, callbackComplete)
    } else {
      return uppyInstances[target]
    }
  }

  // missing dom element
  const el = document.getElementById(target)
  if (!el) {
    return
  }

  uppyInstances[target] = new Uppy()

  let maxFiles = 1
  if (options.multiSelect) {
    maxFiles = 50
  }

  // isTemp means you are not logged in so the limit is 5Mb, and no video, because that requires login and bunny
  let isTemp = false
  if (accountId && accountId?.match('temporary-') !== null) {
    isTemp = true
  }

  // documents only
  const documentFormats = [
    '.doc',
    '.docx',
    '.epub',
    '.key',
    '.pages',
    '.numbers',
    '.pdf',
    '.ppt',
    '.pptx',
    '.rtf',
    '.txt',
    '.xls',
    '.xlsx',
    'text/plain'
  ]

  // logged in / not logged in limits are different
  let maxFileSize = 5 * 1000000

  // wierdly, 'image/*' does not work for heic
  let imageFormats = ['image/heic', 'image/*']
  let videoFormats = ['video/*', 'application/x-mpegURL']

  // cannot do heic without being logged in because temporary tus server
  // stores without extensions and cloudflare / bunny doesn't know what to do
  if (isTemp) {
    imageFormats = ['image/*']
  }

  let allowedFileTypes
  let allowedFileTypesText = 'You can only upload'
  if (options.uploadDocumentsOnly) {
    allowedFileTypes = documentFormats
    allowedFileTypesText += ' documents'
  } else if (options.uploadImagesOnly) {
    allowedFileTypes = imageFormats
    allowedFileTypesText += ' images'
  } else if (options.uploadMediaOnly) {
    if (isTemp) {
      allowedFileTypes = imageFormats
      allowedFileTypesText += ' images'
    } else {
      allowedFileTypes = [...imageFormats, ...videoFormats]
      allowedFileTypesText += ' images and videos'
    }
  } else {
    if (isTemp) {
      allowedFileTypes = [...documentFormats, ...imageFormats]
      allowedFileTypesText += ' documents and images'
    } else {
      allowedFileTypes = [...documentFormats, ...imageFormats, ...videoFormats]
      allowedFileTypesText += ' documents, images and videos'
    }
  }

  let limit = 'up to 5Mb'
  if (isTemp) {
    // docs only & images only are always 5Mg, logged in or not
    if (!options.uploadDocumentsOnly && !options.uploadImagesOnly) {
      limit += '  until you are logged in'
    }
  }

  // video is 500Mb if you are logged in
  if (!isTemp && !options.uploadDocumentsOnly && !options.uploadImagesOnly) {
    limit = 'up to 500Mb'
    maxFileSize = 500 * 1000000
  }

  allowedFileTypesText += ' ' + limit

  debounceLog('allowedFileTypes : ' + target + ' ' + allowedFileTypes + ' ' + allowedFileTypesText)

  uppyInstances[target].setOptions({
    autoProceed: false,
    locale: {
      strings: {
        youCanOnlyUploadFileTypes: allowedFileTypesText
      }
    },
    restrictions: {
      allowedFileTypes: allowedFileTypes,
      maxNumberOfFiles: maxFiles,
      maxFileSize: maxFileSize
    }
  })

  // only for allowed fields
  const unsplashFields = ['brief_metaData', 'brief_image']
  let height = '170px'
  if (unsplashFields.includes(target)) {
    height = '550px'
  }

  uppyInstances[target].use(Dashboard, {
    inline: true,
    width: '100%',
    height: height,
    target: '#' + target,
    hideUploadButton: true,
    proudlyDisplayPoweredByUppy: false,
    doneButtonHandler: null,
    theme: 'light'
  })

  ucanStore = useUcanStore()

  uppyInstances[target].use(Tus, {
    endpoint: '',
    removeFingerprintOnSuccess: true,
    retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
    async onBeforeRequest(req: any, file: any) {
      // no signatures if you are not logged in
      if (isTemp) {
        console.info('onBeforeRequest : temporary account detected, skipping signature checks')
      } else {
        // bunny stream
        if (file.tus.endpoint === TUS_ENDPOINT_VIDEO) {
          // fetch the headers we need to make this request
          const signature = await ucanStore.uploadVideoToBunnyUsingTus(
            'profiles',
            accountId,
            'sign',
            file,
            await selfSignedUcanToken(accountId)
          )

          // keep a copy of the VideoId and LibraryId
          BunnyVideoId.value = signature.data.VideoId
          BunnyLibraryId.value = signature.data.LibraryId

          // set the headers
          req.setHeader('AuthorizationSignature', signature.data.AuthorizationSignature) // SHA256 signature (library_id + api_key + expiration_time + video_id)
          req.setHeader('AuthorizationExpire', signature.data.AuthorizationExpire) // Expiration time as in the signature,
          req.setHeader('VideoId', signature.data.VideoId) // The guid of a previously created video object through the Create Video API call
          req.setHeader('LibraryId', signature.data.LibraryId)
        }

        // other files
        // console.log('file.tus.endpoint', file.tus.endpoint)

        if (file.tus.endpoint === TUS_ENDPOINT_GENERAL) {
          // fetch the jwt token to make this request
          const signature = await fileUploadJwt(
            accountId,
            file,
            await selfSignedUcanToken(accountId)
          )

          // keep a copy of the bucket name
          imageBucket.value = signature.data.bucket
          imageBucketHostCDN.value = signature.data.bucket_cdn

          // set the headers
          req.setHeader('Authorization', 'Bearer ' + signature.data.jwt)

          req.setHeader('X-Filename', file.name)
          req.setHeader('X-Bucket', signature.data.bucket)
          req.setHeader('X-Bucket-Hash-Secret', signature.data.bucket_hash)
          req.setHeader('X-Bucket-Hash-Timestamp', signature.data.bucket_hash_ts)
        }
      }
    }
  })

  // only for allowed fields
  if (unsplashFields.includes(target)) {
    uppyInstances[target].use(Unsplash, {
      companionUrl: 'https://companion.contentedworld.com'
    })
  }

  /*
  uppyInstances[target].use(Instagram, {
    companionUrl: 'https://companion.contentedworld.com/instagram'
  })
  uppyInstances[target].use(Facebook, {
    companionUrl: 'https://companion.contentedworld.com/facebook'
  })
  */

  uppyInstances[target].use(ImageEditor)

  let debounceUploadTimeout: NodeJS.Timeout
  const uploadFiles = () => {
    clearTimeout(debounceUploadTimeout)
    debounceUploadTimeout = setTimeout(() => {
      // console.log('uploadFiles', target)
      uppyInstances[target].upload()
    }, 1000)
  }

  uppyInstances[target].on('file-added', async (file: any) => {
    // what is it
    const isVideoFile = isVideo(file.type)

    // do this on the first file add
    if (!isTemp && isVideoFile && !fileAdded) {
      fileAdded = true
      ucanStore.uploadVideoToBunnyUsingTus(
        'profiles',
        accountId,
        'start',
        file,
        await selfSignedUcanToken(accountId)
      )
    }

    if (file.source == 'Unsplash') {
      isTemp = true
    }

    // set the tus endpoint on file type
    let tusEndpoint = TUS_ENDPOINT_TEMP // not logged in server
    if (!isTemp) {
      tusEndpoint = isVideoFile ? TUS_ENDPOINT_VIDEO : TUS_ENDPOINT_GENERAL
    }

    // console.log('tusEndpoint', file, tusEndpoint)

    uppyInstances[target].setFileState(file.id, {
      tus: {
        endpoint: tusEndpoint
      }
    })

    // Start the upload
    uploadFiles()
  })

  /*
  uppyInstances[target].on('progress', (progress: any) => {
    console.debug('Uppy : on(progress)', progress)
  })
  */

  uppyInstances[target].on('complete', async (result: any) => {
    console.debug('successful files:', result.successful)
    if (result.failed.length) {
      console.debug('failed files:', result.failed)
      console.log('retrying... in 5 seconds')
      setTimeout(() => {
        console.log('retrying failed files...')
        uppyInstances[target].retryAll()
      }, 5000)
    } else {
      callbackComplete(result)
    }
  })

  // console.debug('initUppy : created', accountId, target)
}

export default [
  {
    path: '/experimental',
    name: 'experimental',
    component: () => import('@/components/Pages/Experimental/Experimental.vue')
  },
  {
    path: '/experimental/rendering',
    children: [
      {
        path: 'pdf',
        component: () => import('@/components/Pages/Experimental/Rendering/PDF.vue'),
        name: 'experimental.rendering.pdf',
        props: true
      }
    ]
  },
  {
    path: '/experimental/content-authenticity-initiative',
    children: [
      {
        path: 'active-manifest',
        component: () =>
          import(
            '@/components/Pages/Experimental/ContentAuthenticityInitiative/ActiveManifest.vue'
          ),
        name: 'experimental.content-authenticity-initiative.active-manifest',
        props: true
      }
    ]
  }
]

import { useMemoize } from '@vueuse/core'

export type CheckWebpFeatureFeatureType = 'lossy' | 'lossless' | 'alpha' | 'animation'

export type CheckWebpFeaturePromiseReturn = {
  feature: CheckWebpFeatureFeatureType
  result: boolean
}

export const isWebpSupported = useMemoize(
  (feature: CheckWebpFeatureFeatureType): Promise<CheckWebpFeaturePromiseReturn> => {
    return new Promise((resolve, reject) => {
      const kTestImages = {
        lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
        lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
        alpha:
          'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
        animation:
          'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA'
      }
      const img = new Image()
      img.onload = function () {
        const result = img.width > 0 && img.height > 0
        resolve({
          feature: feature,
          result: result
        })
      }
      img.onerror = function () {
        reject({
          feature: feature,
          result: false
        })
      }
      img.src = 'data:image/webp;base64,' + kTestImages[feature]
    })
  }
)

// @ts-nocheck
// import { ref } from 'vue'

// validation tick marks for CheckOrCross component
// 0 - not submitted yet, 1 - ok, 2 - invalid
let status = []
export const validateFields = (fields) => {
  status = fields
}

export const validateStatus = (event, showToasts = false, toast, validateClass = 'validate') => {
  let isValid = false
  isValid = validateForm(event, showToasts, toast, validateClass)
  return {
    vals: status,
    valid: isValid
  }
}

// validate mandatory fields
const validateForm = (event, showToasts, toast, validateClass) => {
  let success = true
  const toastQ = []

  // its a form field, check its value
  const elements = document.getElementsByClassName(validateClass)

  for (let i = 0; i < elements.length; i++) {
    const el = elements[i]
    const key = el.name
    let val = el.value

    /* input element without a name= , probably v-model */
    if (!key) {
      continue
    }

    if (typeof val.trim == 'function') {
      val = val.trim()
    }

    if (!val || val.length == 0) {
      status[key] = 0
      success = false
      if (showToasts) {
        status[key] = false
      }
    } else {
      status[key] = 1
    }
  }

  if (typeof status.mandatoryVars !== 'undefined') {
    const key = 'mandatoryVars'

    // cycle through objects and check all values
    for (const obj in status[key]) {
      const vals = status[key][obj]['vals']

      if (typeof vals == 'undefined' || !vals.length) {
        success = false
        continue
      }

      // arrays
      for (let i = 0; i < vals.length; i++) {
        status[key][obj]['vals'][i]['valid'] = 1

        const keyvals = status[key][obj]['vals'][i]

        // cycle through key value pairs
        for (const kv in keyvals) {
          if (kv != 'valid' && !keyvals[kv]) {
            status[key][obj]['vals'][i]['valid'] = 0
            if (showToasts) {
              status[key][obj]['vals'][i]['valid'] = false
            }
            success = false
          }
        }
      }
    }
  }

  if (typeof status.uploadRef !== 'undefined' && !status.uploadRef.fileList.length) {
    success = false
    if (showToasts) {
      toastQ.push('Please choose a file to upload')
    }
  }

  if (!success) {
    toastQ.push('Please fill out the mandatory fields')
  }

  // just show first failure
  if (showToasts && toastQ.length) {
    toast(toastQ[0])
  }

  return success
}

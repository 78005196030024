<script lang="ts" setup>
import { nextTick, onMounted, onUnmounted, ref } from 'vue'
import CreativeWelcomePageContentedSvg from '@/components/Pages/Creatives/CreativeWelcomePageContentedSvg.vue'
import CreativeWelcomePageContentedUserIconSvg from '@/components/Pages/Creatives/CreativeWelcomePageContentedUserIconSvg.vue'
import PageHeading from '@/components/PageHeading.vue'

const pageHeight = ref(0)

const updatePageHeight = () => {
  pageHeight.value = 0
  nextTick(() => {
    pageHeight.value =
      Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight
      ) + 25
  })
}

onMounted(() => {
  window.addEventListener('resize', updatePageHeight)

  updatePageHeight()
})

onUnmounted(() => {
  window.removeEventListener('resize', updatePageHeight)
})
</script>

<template>
  <div
    :key="pageHeight"
    :style="{
      height: pageHeight + 'px'
    }"
  >
    <div class="relative h-full w-full">
      <div
        class="p-4 absolute top-0 bottom-0 right-0 left-0 bg-cover bg-no-repeat bg-center z-0"
        :style="{
          background: `url(https://contented-optimized.b-cdn.net/contented-uploads.b-cdn.net/creative-start-page/1b3a1d6ce6de1af5cb3879150cbc195b.jpeg?auto_optimize=medium)`,
          'background-blend-mode': 'soft-light, normal'
        }"
      ></div>
      <div
        class="p-4 absolute top-0 bottom-0 right-0 left-0 opacity-95 z-10"
        :style="{
          background: `linear-gradient(90deg, #2F2FA5 57%, #FF008B 115%)`
        }"
      ></div>
      <div class="absolute top-0 bottom-0 right-0 left-0 z-20">
        <PageHeading background="transparent" title="Contented">
          <template #loginButton>
            <div class="h-8 w-8 mt-1 mr-1">
              <CreativeWelcomePageContentedUserIconSvg />
            </div>
          </template>
        </PageHeading>
        <main class="text-white max-w-5xl mx-auto pt-6 p-4">
          <div class="flex justify-between items-center flex-wrap flex-col md:flex-row">
            <div class="flex flex-col justify-start">
              <h1 class="text-4xl font-bold mb-2 text-left">Welcome to</h1>
              <h2 class="text-5xl font-bold mb-6 text-left">
                <CreativeWelcomePageContentedSvg />
              </h2>
              <p class="text-2xl mb-8 font-bold text-left mt-3">
                Let's start by creating <br />your profile!
              </p>
            </div>

            <div class="ellipse-wrapper relative">
              <div class="ellipse-left">&nbsp;</div>
              <div class="ellipse-center">&nbsp;</div>
              <div class="ellipse-right">&nbsp;</div>
            </div>
          </div>

          <h3 class="text-2xl font-semibold mb-4">How does Contented work?</h3>

          <div class="grid grid-cols-1 space-y-4 md:grid-cols-3 md:space-y-0 gap-4 mb-8 mt-9">
            <div
              class="bg-white rounded-md p-4 relative"
              v-for="item in [
                {
                  number: 1,
                  paragraphs: ['Brands share content briefs on Contented']
                },
                {
                  number: 2,
                  paragraphs: ['When you both match, start to collaborate on content']
                },
                {
                  number: 3,
                  paragraphs: ['Get paid as soon as they accept the content']
                }
              ]"
              :key="item.number"
            >
              <div class="w-full flex items-center justify-center mb-3">
                <div
                  class="-mt-10 w-10 h-10 bg-[#FF008B] rounded-full text-white font-bold flex items-center justify-center"
                >
                  {{ item.number }}
                </div>
              </div>
              <div class="text-center text-[#FF008B] font-medium tracking-wide">
                <p v-for="p in item.paragraphs" :key="p">
                  {{ p }}
                </p>
              </div>
            </div>
          </div>

          <div class="max-w-2xl mx-auto border-[0.5px] border-[#D8D5D5] rounded-md p-3 px-6 my-9">
            <p class="text-center my-3">
              The first step is creating your profile and uploading a portfolio so that brands can
              see who you are and your work to consider collaborating with you.
            </p>

            <p class="text-center my-3">This is your space to reflect your creativity.</p>
          </div>

          <div class="flex justify-center mb-6">
            <router-link :to="{ name: 'creatives.wizard' }">
              <button class="bg-[#FF008B] text-white font-bold py-3 px-8 rounded-full">
                Create your Profile
              </button>
            </router-link>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.ellipse-wrapper {
  width: 293px;
  height: 240.63px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 10px;

  .ellipse-left {
    box-sizing: border-box;

    position: absolute;
    width: 118px;
    height: 117.33px;
    top: 20px;

    background: url('https://contented-optimized.b-cdn.net/contented-uploads.b-cdn.net/creative-start-page/b389bd06a2c681565fcb738496fae558.jpeg?auto_optimize=medium'),
      #d9d9d9;
    border: 2px solid #ffffff;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.5);

    margin-left: -50px;
    @apply rounded-full z-10 bg-cover bg-no-repeat bg-center;
  }
  .ellipse-center {
    box-sizing: border-box;

    position: absolute;
    width: 161.08px;
    height: 161.08px;
    top: 60px;

    background: url('https://contented-optimized.b-cdn.net/contented-uploads.b-cdn.net/creative-start-page/15e3fd314dc2ad15b5e65f2fc6a69a4d.jpeg?auto_optimize=medium'),
      #d9d9d9;
    border: 2px solid #ffffff;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.5);

    @apply rounded-full z-20 bg-cover bg-no-repeat bg-center;
  }
  .ellipse-right {
    box-sizing: border-box;

    position: absolute;
    width: 138.55px;
    height: 138.55px;
    top: -10px;

    background: url('https://contented-optimized.b-cdn.net/contented-uploads.b-cdn.net/creative-start-page/6fcddca6d51adbff43d71a3cc872e087.jpeg?auto_optimize=medium'),
      #d9d9d9;
    border: 2px solid #ffffff;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.5);

    margin-left: 100px;
    @apply rounded-full z-10 bg-cover bg-no-repeat bg-center;
  }
}

@media (max-width: 768px) {
  .ellipse-wrapper {
    left: 50px;
  }
}
</style>

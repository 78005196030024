<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { onMounted, onUnmounted, computed, ref, reactive, watch, watchEffect, useSlots } from 'vue'
import { useRouter } from 'vue-router'
import { mobileDetect } from '@/utils/browser'
import { useTeamStore } from '@/stores/team'
import { currencyToCOTO, usePricingConfigurationStore } from '@/stores/pricing-configurations'
import { fetchProfile } from '@/queries/api'
import { useNearWalletStore } from '@/stores/near-wallet'
import { storeToRefs } from 'pinia'
import { useActorRef } from '@xstate/vue'
import { briefMachine } from '@/utils/xstate'
import { extractFileUrl, upgradeUrlMeta } from '@/utils/mediaDecrypt'
import { isWebview } from '@/utils/detect'
import { useImageCDN } from '@/utils/image'

// import { pinDataToIPFS } from '@/utils/ipfs'
import { RouterLink } from 'vue-router'

import NearWallet from '@/components/NearWallet.vue'
import ProfileAvatar from '@/components/ProfileAvatar.vue'
import SecondaryButton from '@/components/Jetstream/SecondaryButton.vue'

const { t: $t } = useI18n({})

const $slots = useSlots()

withDefaults(
  defineProps<{
    background?: string
    title: string
  }>(),
  {
    background: 'linear-gradient(90deg, #2f2fa5 57%, #fa00ff 115%)',
    title: ''
  }
)

const $router = useRouter()

const teamStore = useTeamStore()

const isWebView = ref(isWebview())

const isMobile = ref(mobileDetect())
const updatePageWidth = () => {
  isMobile.value = mobileDetect()
}
onMounted(() => {
  updatePageWidth()
  window.addEventListener('resize', updatePageWidth)
})
onUnmounted(() => {
  window.removeEventListener('resize', updatePageWidth)
})

const buildBurgerMenuItems = () => {
  return {
    isBurgerMenuOpen: false,
    burgerMenuItems: [
      {
        label: 'Brands',
        id: 'https://contentedworld.com/#brands',
        show: true,
        open: !isMobile.value,
        submenu: [
          { label: 'For brands', id: 'https://contentedworld.com/#brands' },
          {
            label: 'View live briefs',
            id: $router.resolve({
              name: 'briefs.list'
            }).href
          },
          {
            label: 'Create a Brief',
            id: $router.resolve({
              name: 'marketplace.create-brief',
              query: teamStore?.team?.nickname ? { team: teamStore.team.nickname } : {}
            }).href
          }
        ]
      },
      {
        label: 'Creators',
        id: 'https://contentedworld.com/#creators',
        show: true,
        open: !isMobile.value,
        submenu: [
          { label: 'For creators', id: 'https://contentedworld.com/#creators' },
          {
            label: 'Join Contented',
            id: '/creatives/start'
          },
          {
            label: 'Creators Wanted',
            id: '/briefs/invite/brief/creative'
          },
          {
            label: 'View live briefs',
            id: '/briefs'
          }
        ]
      },
      {
        label: 'Technology',
        id: 'https://contentedworld.com/#technology',
        show: true,
        open: false
      },
      { label: 'About', id: 'https://contentedworld.com/#about', show: true, open: false },
      { label: 'Contact', id: 'https://contentedworld.com/#about', show: true, open: false }
    ]
  }
}

const burgerMenuParams = ref(buildBurgerMenuItems())

const toggleBurgerMenu = () => {
  burgerMenuParams.value.isBurgerMenuOpen = !burgerMenuParams.value.isBurgerMenuOpen
}
const toggleBurgerSubmenu = (index: number, event: { stopPropagation: () => void }) => {
  event.stopPropagation()
  burgerMenuParams.value.burgerMenuItems[index].open =
    !burgerMenuParams.value.burgerMenuItems[index].open
}

watch(
  () => teamStore?.team,
  () => {
    burgerMenuParams.value = buildBurgerMenuItems()
  }
)

const isExternalLink = (url: string) => {
  return /^(http|https):\/\//.test(url)
}

const params = reactive({
  accountId: ''
})

const briefMachineActor = useActorRef(briefMachine)
briefMachineActor.start()

const isStarted = ref(false)

const pricingStore = usePricingConfigurationStore()

const nearWalletStore = useNearWalletStore()
const { accountId, user, balance, loggedIn } = storeToRefs(nearWalletStore)

// fetch the uploaded artwork to the profile
const loadingProfile = ref(false)

const avatar = ref<string>()

const getProfile = async (nv: string) => {
  loadingProfile.value = true
  const resp = await fetchProfile(nv)
  if (resp.profilePic) {
    const { decode } = upgradeUrlMeta(resp.profilePic)
    if (decode) {
      avatar.value = useImageCDN(extractFileUrl(decode[0].file_url), 'thumb')
    }
  }
  nearWalletStore.userProfile = resp
  loadingProfile.value = false
}

watch(accountId, async (nv, ov) => {
  await getProfile(nv)
})

if (accountId.value) {
  getProfile(accountId.value)
}

const firstname = computed(() => {
  return (
    nearWalletStore.userProfile?.firstName ||
    nearWalletStore.userProfile?.firstname ||
    ''
  ).trim()
})

const lastname = computed(() => {
  return (
    nearWalletStore.userProfile?.lastName ||
    nearWalletStore.userProfile?.lastname ||
    ''
  ).trim()
})

const fullname = computed(() => {
  return [firstname.value, lastname.value].join(' ').trim()
})

watch(
  () => nearWalletStore.userProfile,
  (nv) => {
    if (nv && nv.id) {
      window?.posthog?.identify(nv.id, {
        email: nearWalletStore.user?.email,
        name: fullname.value,
        first_name: firstname.value,
        last_name: lastname.value,
        hostname: window.location.hostname
      })
    }
  }
)

const sourceData = ref<{
  title: string
  type: string
  owner: string
  audiences: string[]
  objectives: string[]
  brief_image: string
  brief_logo: string
  minimum_price: number
  metadata: unknown
  brief_status: string
  nda_status: boolean
  nda_accepted: string[]
  nda_submitted: string[]
  nda_text: string
  submission_deadline: string
  interest_deadline: string
}>()
const logoUrl = ref('https://contentedworld.com/')

const balanceInCurrency = ref('0')
const onCurrencyClick = (currency: string) => {
  pricingStore.setCurrency(currency)
}

// related to app navs
const getSelectedApp = () =>
  (window.location.pathname || '')
    .split('/')
    .filter((urlSegment: string) => !!urlSegment)
    .shift() || ''

const login = () => {
  nearWalletStore.doLogin()
}

const logout = () => {
  nearWalletStore.web3authLogout().then(() => {
    closeMenu('dropdown')
  })
}

const pathname = window.location.pathname

const headerMenuItems = computed(() => [
  {
    id: '/marketplace/create-brief',
    label: 'Create a Brief',
    selected: pathname === '/marketplace/create-brief',
    show: false
  },
  {
    id: '/briefs',
    label: 'View Briefs',
    selected: Boolean(pathname.startsWith('/briefs')),
    show: false
  },
  {
    id: '/marketplace/licensing?media_preference=premium',
    label: 'Stock Library',
    selected: pathname !== '/marketplace/create-brief' && getSelectedApp() === 'marketplace',
    show: false
  },
  {
    id: '/studio/content',
    label: 'Creator Studio',
    selected: getSelectedApp() === 'studio',
    show: false
  },
  {
    id: '/scout/content',
    label: 'Content Bidding',
    selected: getSelectedApp() === 'scout',
    show: false
  }
])

const dropDownMenuItems = computed(
  () =>
    [
      // Add header menu items if on a mobile device
      // ...(isMobile.value && loggedIn.value ? headerMenuItems.value : []),
      {
        id: $router.resolve({
          name: 'briefs.list',
          query: {
            tab: 'my'
          }
        }).href,
        label: 'My Briefs',
        selected: Boolean(pathname.startsWith('/briefs')),
        show: true
      },
      {
        id: $router.resolve({
          name: 'teams.index'
        }).href,
        label: 'My Teams',
        selected: false,
        show: true
      },
      {
        id: $router.resolve({
          name: 'user.profile',
          params: {
            username: accountId.value || 'unknown'
          }
        }).href,
        label: 'My Profile',
        selected: false,
        show: true
      },
      {
        id: $router.resolve({
          name: 'user.updateprofile',
          query: {
            from: window.location.pathname || ''
          }
        }).href,
        label: 'Update Profile',
        selected: false,
        show: true
      },
      { id: '#', label: 'Logout', selected: false, action: 'logout', show: true }
    ] as Array<{
      id: string
      label: string
      selected: boolean
      show: boolean
      action?: string
    }>
)

const headermenu = ref(headerMenuItems.value.filter((item) => !!item.selected)?.pop()?.id)
watch(headermenu, (nv, ov) => {
  if (nv && nv !== ov) {
    $router.push(nv)
  }
})

// menus
const menu = reactive<{
  currency: {
    show: boolean
    fadeIn: boolean
  }
  dropdown: {
    show: boolean
    fadeIn: boolean
  }
}>({
  currency: {
    show: false,
    fadeIn: false
  },
  dropdown: {
    show: false,
    fadeIn: false
  }
})

// close with fade
const closeMenu = (m: 'currency' | 'dropdown') => {
  menu[m].fadeIn = false
  setTimeout(() => {
    menu[m].show = false
  }, 0)
}

// immediate close
const closeAll = () => {
  closeMenu('currency')
  closeMenu('dropdown')
}

// fade in menu
const showMenu = (m: 'currency' | 'dropdown') => {
  menu[m].show = true
  // show
  setTimeout(() => {
    menu[m].fadeIn = true
  }, 50)
}

const toggleMenu = (m: 'currency' | 'dropdown') => {
  if (m == 'currency') {
    closeMenu('dropdown')
  } else {
    closeMenu('currency')
  }
  if (menu[m].fadeIn) {
    closeMenu(m)
  } else {
    showMenu(m)
  }
}

//watch sourceData
watchEffect(() => {
  balanceInCurrency.value = currencyToCOTO(balance.value.USDC, pricingStore.selectedCurrency, true)!

  if (sourceData.value) {
    briefMachineActor.send({ type: 'SET_TITLE', title: sourceData.value.title })
    isStarted.value = true
  }
})

onMounted(() => {
  window.sourceDataDebug = sourceData
  // initWasm({})
})

onUnmounted(() => {
  briefMachineActor.stop()
})

const submit = async (data: unknown) => {
  console.log('submit : DEPRECATED', data)
  // const pinDataToIPFSResponse = await pinDataToIPFS(JSON.stringify(data))
  // const cidString = pinDataToIPFSResponse?.cid ? pinDataToIPFSResponse.cid.toString() : ''
  // await nearWalletStore.addSmartBriefs(cidString)
}

watch(accountId, (nv, ov) => {
  if (nv !== ov) {
    params.accountId = nv
  }
})

watch(
  () => briefMachineActor.getSnapshot(),
  (newState) => {
    // console.log(newState.value);
    // console.log(newState.context.data);

    if (newState.matches('setType')) {
      briefMachineActor.send({ type: 'SET_TYPE', briefType: sourceData.value?.type })
    } else if (newState.matches('setOwner')) {
      briefMachineActor.send({ type: 'SET_OWNER', owner: nearWalletStore.accountId })
    } else if (newState.matches('setAudiences')) {
      briefMachineActor.send({ type: 'SET_AUDIENCES', audiences: sourceData.value?.audiences })
    } else if (newState.matches('setObjective')) {
      briefMachineActor.send({ type: 'SET_OBJECTIVE', objective: sourceData.value?.objectives })
    } else if (newState.matches('setImage')) {
      briefMachineActor.send({
        type: 'SET_IMAGE',
        imageUrl: sourceData.value?.brief_image,
        logoUrl: sourceData.value?.brief_logo
      })
    } else if (newState.matches('setMinimumPrice')) {
      briefMachineActor.send({
        type: 'SET_MINIMUM_PRICE',
        minimumPrice: sourceData.value?.minimum_price
      })
    } else if (newState.matches('setMetaData')) {
      briefMachineActor.send({
        type: 'SET_META_DATA',
        metaData: JSON.stringify(sourceData.value?.metadata)
      })
    } else if (newState.matches('completed')) {
      submit(newState.context.data.brief)
    }
  }
)
// just set window.error_message to show error message from other components
const error_message = ref()

const thisUrl = window.location.href

const buttonClass =
  'inline-flex bg-black text-white items-center px-4 py-2 border border-gray-300 rounded-md font-semibold text-xs text-gray-700 uppercase tracking-widest shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 active:text-gray-800 active:bg-gray-50 disabled:opacity-25 transition'

const buttonClassPink =
  'inline-flex bg-[#FF008B] text-white items-center px-8 py-4 border border-gray-300 rounded-full font-semibold text-xs text-gray-700 uppercase tracking-widest shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 active:text-gray-800 active:bg-gray-50 disabled:opacity-25 transition'

clearInterval(window.error_message_interval)
window.error_message_interval = setInterval(function () {
  if (window.error_message) {
    error_message.value = window.error_message
    if (!window.error_message_timeout) {
      window.error_message_timeout = setTimeout(() => {
        error_message.value = ''
        window.error_message = ''
        window.error_message_timeout = null
      }, 5000)
    }
  }
}, 1000)

const userAgent = ref(window.navigator.userAgent)
</script>

<template>
  <!-- todo - get rid of this, integrate landbot in a better way -->
  <Suspense>
    <NearWallet />
  </Suspense>

  <div v-if="error_message" class="bg-red-500 text-center text-white p-5">
    {{ error_message }}
  </div>

  <div class="mr-3 absolute w-full h-full top-0 left-0" v-if="isWebView">
    <div class="opacity-95 bg-white fixed w-full h-full z-[80]"></div>
    <div class="absolute w-full h-full flex flex-col items-center z-[81]">
      <img src="/src/images/Contented-Logo_stacked_1000x750px.png" class="w-[50%] py-8" />
      <div class="mb-12 text-center mx-6">
        <p>To login: <b>Hold</b> the button below for 5 secs.</p>
        <p class="mt-3">Select open link in Safari or your preferred web browser</p>
      </div>
      <a :href="thisUrl" target="_blank" rel="noopener noreferrer" :class="buttonClassPink">
        OPEN IN WEB
      </a>
    </div>
  </div>

  <div
    :style="{
      background: background
    }"
    class="flex items-center justify-between py-3 px-4 mx-auto"
  >
    <div class="flex items-center">
      <div :title="$t('home.link_title')" class="min-w-[100px]">
        <a :href="logoUrl" rel="noopener">
          <div class="flex flex-col items-center -mt-1.5">
            <span class="text-white text-[45%] self-end uppercase italic font-bold tracking-wider">
              beta
            </span>
            <img src="/images/logo/logo-white.svg" class="ml-2 max-h-[25px]" />
          </div>
        </a>
      </div>
    </div>

    <div
      v-if="!isMobile"
      class="hidden lg:flex-grow lg:flex lg:flex-wrap lg:items-center lg:justify-center lg:px-3 text-sm"
    >
      <RouterLink
        v-for="(item, index) in headerMenuItems.filter((i: any) => i.show)"
        :key="index"
        :class="[
          'text-white mx-2 lg:mx-4 md:py-3 hover:text-cm-blue-light-200 underline-offset-4 decoration-2 decoration-red-500',
          item.selected ? 'text-cm-blue-light-200 underline' : 'hover:underline'
        ]"
        :title="item.label"
        :to="item.id"
      >
        <span>{{ item.label }}</span>
      </RouterLink>
    </div>

    <div class="flex items-center">
      <div class="ml-auto flex items-center">
        <div class="relative text-left flex justify-center items-center">
          <!-- [START]: Avatar Menu -->
          <div class="pr-3 flex justify-end">
            <div v-if="!loggedIn">
              <button v-if="$slots.loginButton" type="button" @click.prevent="login">
                <slot name="loginButton"></slot>
              </button>
              <SecondaryButton v-else :class="buttonClass" type="button" @click.prevent="login">
                Login
              </SecondaryButton>
            </div>
            <button v-else @click="toggleMenu('dropdown')">
              <ProfileAvatar
                :profile-id="accountId"
                :data="avatar"
                class="w-8 h-8"
                image-bg-color="bg-white"
                image-border-color="border-white"
                image-border-color-hover="group-hover:border-[#FFFFFF]"
                fallback-border-color="border-white"
                fallback-text-color="text-white"
                fallback-text-size="text-md"
              />
            </button>
          </div>

          <div
            v-if="menu.dropdown.show"
            class="absolute min-w-[230px] z-50 top-[48px] right-[12px] transition duration-500 opacity-0"
            :class="{
              'opacity-100': menu.dropdown.fadeIn
            }"
            role="menu"
            aria-label="User profile dropdown menu"
            :aria-expanded="menu.dropdown.show"
          >
            <div class="bg-white rounded-md w-full flex flex-col">
              <div class="rounded-t-md h-[100px] flex w-full items-center justify-center relative">
                <div class="absolute top-0 w-full rounded-t-md bg-[#ff0093] h-[60px]"></div>
                <div class="absolute w-full flex justify-center h-[60px] top-[32px]">
                  <div v-if="!loadingProfile">
                    <ProfileAvatar
                      :profile-id="accountId"
                      :data="avatar"
                      class="w-14 h-14 border-2 border-white rounded-full"
                      image-bg-color="bg-white"
                      image-border-color="border-white"
                      image-border-color-hover="group-hover:border-[#FFFFFF]"
                      fallback-border-color="border-white"
                      fallback-text-color="text-white"
                      fallback-text-size="text-md"
                      role="img"
                      aria-label="User profile picture"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center mb-3 px-4">
                <span v-if="!loadingProfile && fullname">
                  {{ fullname }}
                </span>
                <br />
                <span v-if="user?.email" class="text-xs text-gray-400">{{ user.email }}</span>
              </div>

              <RouterLink
                v-for="item in (dropDownMenuItems || []).filter((i: any) => i.show)"
                :key="item.id"
                :title="item.label"
                :to="item.id"
                class="w-full block text-sm text-left"
                role="menuitem"
              >
                <button
                  v-if="item.action === 'logout'"
                  @click="logout()"
                  class="px-4 py-2 text-black hover:text-[#FF008B]"
                  aria-label="Logout"
                >
                  {{ item.label }}
                </button>
                <button
                  v-else
                  class="px-4 py-2 text-black hover:text-[#FF008B]"
                  @click="closeMenu('dropdown')"
                  aria-label="Navigate to {{ item.label }}"
                >
                  {{ item.label }}
                </button>
              </RouterLink>

              <!-- [START]: Balance -->
              <div class="bg-[#ff0093] text-white rounded-b-md mt-2 p-3 pt-1">
                <div class="my-2">
                  <div class="text-sm">Balance:</div>
                </div>

                <div v-if="balance" class="font-semibold my-2">
                  <span>{{ balanceInCurrency }}&nbsp;</span>
                  <span class="text-xs">{{ balance.USDC ? `($${balance.USDC} USDC)` : '' }}</span>
                </div>

                <!-- [START]: Currency -->
                <div
                  class="grid grid-cols-3 gap-2 justify-left cursor-pointer text-center text-sm rounded-md"
                  role="group"
                  aria-label="Currency selection"
                >
                  <!-- <div
                    v-on:click="onCurrencyClick('GBP')"
                    class="click-currency-gbp rounded-md hover:bg-gray-700 px-2 p-1"
                    :class="{
                      'bg-gray-800 text-white': pricingStore.selectedCurrency === 'GBP'
                    }"
                  >
                    GBP
                  </div>
                  <div
                    v-on:click="onCurrencyClick('EUR')"
                    class="click-currency-eur rounded-md hover:bg-gray-700 px-2 p-1"
                    :class="{
                      'bg-gray-800 text-white': pricingStore.selectedCurrency === 'EUR'
                    }"
                  >
                    EUR
                  </div> -->
                  <div
                    v-on:click="onCurrencyClick('USD')"
                    class="click-currency-usd rounded-md hover:bg-gray-700 px-2 p-1"
                    :class="{
                      'bg-gray-800 text-white': pricingStore.selectedCurrency === 'USD'
                    }"
                  >
                    USD
                  </div>
                </div>
                <!-- [END]: Currency -->
              </div>
              <!-- [END]: Balance -->
            </div>
          </div>
          <!-- [END]: Avatar Menu -->

          <!-- click on bg to hide -->
          <div
            v-if="menu.currency.show || menu.dropdown.show"
            class="fixed inset-0 z-40 bg-black opacity-20"
            @click="closeAll"
          ></div>
        </div>
      </div>

      <!-- Burger Menu for mobile as well as desktop -->
      <div class="flex items-center">
        <button
          @click="toggleBurgerMenu"
          class="text-white mr-4 text-2xl"
          aria-label="Open menu"
          :aria-expanded="burgerMenuParams.isBurgerMenuOpen"
          aria-controls="burgerMenu"
        >
          <i class="fa fa-bars"></i>
        </button>

        <transition name="slide-right">
          <div
            v-if="burgerMenuParams.isBurgerMenuOpen"
            ref="burgerMenuOverlay"
            class="fixed inset-0 z-50 bg-black bg-opacity-50"
            @click="toggleBurgerMenu"
          >
            <div
              id="burgerMenu"
              class="bg-white p-6 w-8/12 sm:w-2/4 md:w-1/4 h-full fixed right-0 top-0 rounded-l-lg shadow-lg"
              @click.stop
              role="menu"
            >
              <div class="absolute top-0 right-0 w-full bg-[#ff0093] h-[60px] rounded-tl-lg"></div>
              <button
                @click="toggleBurgerMenu"
                class="absolute top-4 left-4 text-[#FFFFFF]"
                aria-label="Close menu"
              >
                <i class="fa fa-times"></i>
              </button>
              <div class="mt-20 space-y-4">
                <ul class="menu">
                  <li
                    v-for="(item, index) in burgerMenuParams.burgerMenuItems"
                    :key="index"
                    class="menu-item relative"
                    :class="{ 'menu-item-has-children': item.submenu }"
                    role="menuitem"
                  >
                    <div class="flex justify-between items-center">
                      <component
                        v-if="!item.submenu"
                        :is="'a'"
                        :href="item.id"
                        @click="!isExternalLink(item.id) ? toggleBurgerMenu : undefined"
                        class="block text-sm text-left w-full py-3 px-4 cursor-pointer"
                        :target="isExternalLink(item.id) ? '_blank' : undefined"
                        :rel="'noopener noreferrer'"
                      >
                        <span>{{ item.label }}</span>
                      </component>
                      <div
                        v-else
                        @click="toggleBurgerSubmenu(index, $event)"
                        class="block text-sm text-left w-full py-3 px-4 cursor-pointer"
                      >
                        <span>{{ item.label }}</span>
                        <span v-if="item.submenu" class="ocm-dropdown-arrow cursor-pointer ml-4">
                          <i
                            :class="[
                              'fa',
                              { 'fa-angle-down': !item.open, 'fa-angle-up': item.open },
                              'ml-2'
                            ]"
                          ></i>
                        </span>
                      </div>
                    </div>
                    <ul v-if="item.submenu && item.open" class="sub-menu pl-6" role="menu">
                      <li
                        v-for="(subItem, subIndex) in item.submenu"
                        :key="subIndex"
                        class="menu-item"
                        role="menuitem"
                      >
                        <component
                          :is="'a'"
                          :href="subItem.id"
                          @click="!isExternalLink(subItem.id) ? toggleBurgerMenu : undefined"
                          class="block text-sm text-left w-full py-2 px-4 cursor-pointer"
                          :target="isExternalLink(subItem.id) ? '_blank' : undefined"
                          :rel="'noopener noreferrer'"
                        >
                          <span>{{ subItem.label }}</span>
                        </component>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </transition>
      </div>

    </div>
  </div>
</template>

<style scoped>
.grad-color {
  color: rgb(197, 188, 223);
}
.grad-top {
  background: rgb(197, 188, 223);
  background: linear-gradient(
    180deg,
    rgba(197, 188, 223, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(0, 212, 255, 0) 100%
  );
}
.menu-item {
  position: relative;
}
.sub-menu {
  margin-top: 10px;
}
.ocm-dropdown-arrow {
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}
/* Slide transition */
.right-menu {
  right: 0;
  left: auto;
}
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.3s ease;
}
.slide-right-enter,
.slide-right-leave-to /* .slide-right-leave-active in <2.1.8 */ {
  transform: translateX(100%);
}
</style>

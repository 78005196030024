<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { fetchBrief } from '@/queries/api'
import { getSmartBriefs } from '@/queries/api'
import { route } from '@/utils/route'

import { storeToRefs } from 'pinia'
import { useNearWalletStore } from '@/stores/near-wallet'
import { submitSmartBriefNDA } from '@/queries/api'
import { useUcanStore } from '@/stores/ucan'
import { useBriefStore } from '@/stores/briefs'

import SmartBriefCard from '@briefs/SmartBriefCard.vue'
import Loading from '@/components/Loading.vue'
import Modal from '@/components/Jetstream/Modal.vue'
import PageHeading from '@/components/PageHeading.vue'
import Markdown from '@/components/Markdown.vue'
import JoinTile from '@/components/Pages/Creatives/JoinTile.vue'

const $router = useRouter()

const props = defineProps({
  brief_id: {
    type: String
  }
})

const nearWalletStore = useNearWalletStore()
const ucanStore = useUcanStore()
const briefStore = useBriefStore()

const { accountId } = storeToRefs(nearWalletStore)
const { loggedIn } = storeToRefs(nearWalletStore)

const pageTitle = ref('Invite to Brief')

const ndaTextModal = ref(false)
const isLoading = ref(false)

const brief = ref<SmartBrief>()
const briefLoading = ref(false)

const briefs = ref<SmartBrief[]>([])
const briefsLoading = ref(false)

const isAccepted = ref(false)
const isPublic = ref(false)
const isOwner = ref(false)

watch(accountId, () => {
  if (brief.value) {
    if (brief.value.owner === accountId.value) {
      isOwner.value = true
    } else if (accountId.value !== '') {
      isAccepted.value = brief.value.ndaSubmitted.find((e) => e === accountId.value) !== undefined
    }
  }
})

watch(isAccepted, (isAcceptedNew) => {
  if (isAcceptedNew) {
    redirectToBriefDetails()
  }
})

const convertNewLine = (content?: string) => (content || '').replace(/\^newline\^/g, '\n\n')

const submitNDA = async () => {
  if (!loggedIn.value) {
    nearWalletStore.doLogin()
  } else {
    isLoading.value = true
    if (brief.value && brief.value.type !== 'public') {
      await submitSmartBriefNDA(ucanStore, brief.value.id)
    }
    redirectToBriefDetails()
  }
}

const redirectToBriefDetails = () => {
  if (!brief.value) {
    console.debug('redirectToBriefDetails: brief is empty')
    return
  }

  isLoading.value = false

  $router.push({
    name: 'briefs.briefdetails',
    params: {
      brief_id: brief.value?.slug || brief.value.id
    }
  })
}

const handleProceedClick = () => {
  // Push GTM event
  /* if (typeof gtag !== 'undefined') {
    gtag('event', 'click', {
      event_category: 'button',
      event_label: 'Invite Proceed Button',
      value: 'invite_proceed_button_click'
    });
  } */
  redirectToBriefDetails()
}

const getSmartBriefCardHref = (brief_id: any, tile_number: any) => {
  const href = route({
    name: 'briefs.briefdetails',
    params: {
      brief_id
    }
  })
  // Push GTM event
  /* if (typeof gtag !== 'undefined') {
    gtag('event', 'click', {
      event_category: 'link',
      event_label: `Invite Proceed Tile #${tile_number}`,
      value: `invite_proceed_tile_${tile_number}_click`
    });
  } */
  return href
}

onMounted(async () => {
  briefLoading.value = true
  briefsLoading.value = true

  await briefStore.fetchBriefs({
    option: 'live'
  })

  briefs.value = [...(briefStore.briefs || [])]

  if (props.brief_id === 'brief') {
    brief.value = briefs.value.slice(0, 1)?.pop()
    briefs.value = briefs.value.slice(1)
  } else {
    brief.value = await fetchBrief(props.brief_id!)
  }

  isPublic.value = brief.value?.isNDARequired === 'Yes' && brief.value?.status === 'published'

  briefsLoading.value = false
  briefLoading.value = false
})

const showCaseStudyVideo = ref<boolean | 'case-study' | 'content'>(false)
</script>

<template>
  <div>
    <div>
      <header class="bg-cm-blue-500 shadow">
        <PageHeading :title="pageTitle" />
      </header>
    </div>
    <main>
      <div v-if="briefLoading" class="w-full h-screen flex justify-center items-center">
        <div class="mx-auto">
          <Loading v-if="isLoading" />
        </div>
      </div>
      <div v-else>
        <div
          class="contented-brief-invite-page-bg-gradient pt-6 md:py-16 bg-cover bg-no-repeat bg-center"
          :style="{
            backgroundImage: `url('/images/brief-invite-creative/contented-circles.png')`
          }"
        >
          <div class="flex flex-col-reverse md:flex-row max-w-5xl mx-auto">
            <!-- 1-column on Mobile and 2-columns on Desktop -->
            <div class="w-full md:w-1/2 mr-3 md:px-1.5" v-if="brief?.id">
              <div class="md:max-w-sm">
                <SmartBriefCard
                  :brief="brief"
                  :isBottomCornersRounded="false"
                  :href="getSmartBriefCardHref(brief?.slug || brief.id, 1)"
                />
              </div>
            </div>
            <div class="w-full md:w-1/2 md:ml-3 text-white">
              <div class="mx-3 max-w-lg md:mx-auto px-1.5">
                <h3
                  class="my-3 md:mt-0 text-3xl tracking-wide md:text-2xl text-center md:text-left montserrat font-extrabold"
                >
                  Content creators, get paid by big <span class="hidden md:block h-0"><br /></span>
                  brands for doing what you love!
                </h3>
                <div class="block my-6 roboto">
                  <h4 class="my-3 font-bold tracking-wide text-left text-lg">How does it work?</h4>
                  <div class="text-sm">
                    <ol class="list-decimal ml-5 my-3">
                      <li>Brands share content briefs on Contented</li>
                      <li>When you both match, start to collaborate on content</li>
                      <li>Get paid as soon as they accept the content</li>
                    </ol>
                    <!--
                    <p v-if="!isPublic && !isOwner" class="my-3 roboto">
                      By clicking 'Proceed' you agree to the
                      <a
                        href="#"
                        @click.prevent="ndaTextModal = true"
                        class="text-[#FF008B] hover:opacity-80"
                        >NDA</a
                      >.
                    </p>
                    -->
                    <p class="my-3">
                      You'll be able to see the full details of this brief by clicking Proceed, or
                      one of our alternative briefs below. .If you complete the job, there will be a
                      10% commission fee paid to Contented.
                    </p>
                  </div>
                </div>
              </div>
              <div
                :class="{
                  'my-6 text-center': true,
                  'invisible md:visible': !brief?.id
                }"
              >
                <button
                  type="button"
                  @click="redirectToBriefDetails"
                  class="rounded-full bg-[#FF008B] text-white hover:opacity-80 my-3 px-8 py-2 font-medium tracking-wide"
                >
                  <span>Proceed</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="md:hidden">
          <div class="w-full text-center mt-6">
            <button
              type="button"
              @click="redirectToBriefDetails"
              class="rounded-full bg-[#FF008B] text-white hover:opacity-80 my-3 px-8 py-2 font-medium tracking-wide"
            >
              <span>Proceed</span>
            </button>
          </div>
        </div>

        <!-- splitter -->
        <div id="splitter" class="contented-brief-invite-page-bg-gradient">
          <div>&nbsp;</div>
        </div>

        <!-- Case Studies -->
        <div
          class="w-full mb-3 mt-2 md:mt-3 bg-white p-3 bg-center bg-no-repeat bg-cover"
          :style="{
            backgroundImage: `url('/images/brief-invite-creative/contented-watermark-opaque-white-text.png')`
          }"
        >
          <div class="flex flex-wrap justify-between items-start max-w-5xl mx-auto">
            <div class="w-full md:w-1/2 flex flex-col">
              <div class="my-3">
                <h2 class="text-left montserrat font-extrabold tracking-wider text-2xl">
                  Case Study: Dove Men+Care
                </h2>
              </div>
              <div class="my-3 text-sm">
                <p>
                  Check out our latest case study. Dove's Men+care brand were looking for creators
                  to help promote a new product. Discover how they achieved their goals using the
                  talented creators sourced through Contented.
                </p>
              </div>
              <div class="my-9 text-center hidden">
                <a href="#" target="_blank" rel="noopener">
                  <button
                    type="button"
                    class="border-2 border-[#FF008B] text-[#FF008B] hover:bg-[#FF008B] hover:text-white rounded-full px-8 py-1.5 font-medium tracking-wide"
                  >
                    Find out more
                  </button>
                </a>
              </div>
            </div>
            <div class="w-full md:w-1/2">
              <div class="py-3 p-3 flex justify-between max-h-[300px] mx-auto">
                <div
                  @click="showCaseStudyVideo = 'case-study'"
                  class="flex justify-end cursor-pointer hover:opacity-70"
                  title="Contented Case Study Behind the Scenes with Dove Men+Care 5 April 2024"
                >
                  <img
                    src="/images/creative-invite-page-case-study-left.png"
                    alt="creators Behind the scenes of our recent project with Dove Men+Care 📸 #skincarecommunity #selfcaretips #mensgrooming #bts Tags: #cleanwithme #asmr #SkincareCommunity #SelfCareTips #MensGrooming #bts #Unilever #DoveMenCare #CaseStudy"
                    class="h-full"
                  />
                </div>
                <div
                  @click="showCaseStudyVideo = 'content'"
                  class="flex justify-start cursor-pointer hover:opacity-70"
                  title="Contented Case Study Final TikTok post with Dove Men+Care “Weekly Bathroom re-set"
                >
                  <img
                    src="/images/creative-invite-page-case-study-right.png"
                    alt="How often is your bathroom re-set? Be honest. #cleanwithme #asmr Tags: #cleanwithme #asmr #SkincareCommunity #SelfCareTips #MensGrooming #Unilever #DoveMenCare"
                    class="h-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- You might also like these other briefs -->
        <div class="w-full bg-[#f7f8fc] py-9">
          <div class="max-w-5xl mx-auto">
            <div class="mx-3" v-if="briefs?.length > 0">
              <h3
                class="my-3 md:mt-0 text-xl tracking-wide md:text-2xl text-left md:text-left roboto font-extrabold"
              >
                You might also like these other briefs
              </h3>
            </div>
            <div>
              <ul class="flex flex-wrap">
                <li
                  v-for="(brief, index) in (briefs || []).slice(0, 3)"
                  :key="brief.id"
                  class="w-full md:w-1/2 lg:w-1/3"
                >
                  <div class="md:mx-3 my-3">
                    <SmartBriefCard
                      :isBottomCornersRounded="false"
                      :brief="brief"
                      :href="getSmartBriefCardHref(brief?.slug || brief.id, index + 2)"
                    />
                  </div>
                </li>
                <li class="w-full md:w-1/2 lg:w-1/3">
                  <router-link
                    :to="{
                      name: 'creatives.start'
                    }"
                  >
                    <div class="md:mx-3 my-3">
                      <JoinTile />
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Case Study Video Modal -->
        <div>
          <Modal
            @close="showCaseStudyVideo = false"
            :closeable="true"
            max-width="2xl"
            :show="showCaseStudyVideo !== false"
          >
            <div class="bg-white p-6 rounded-lg border border-gray-300">
              <div class="mx-auto" v-if="showCaseStudyVideo === 'case-study'">
                <div style="position: relative; padding-top: 56.25%">
                  <iframe
                    src="https://iframe.mediadelivery.net/embed/242427/e91b5265-f4cc-459e-a7c8-35447c1118d9?autoplay=true&loop=false&muted=false&preload=true&responsive=true"
                    loading="lazy"
                    style="border: 0; position: absolute; top: 0; height: 100%; width: 100%"
                    allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                    allowfullscreen="true"
                  ></iframe>
                </div>
              </div>
              <div class="mx-auto" v-if="showCaseStudyVideo === 'content'">
                <div style="position: relative; padding-top: 56.25%">
                  <iframe
                    src="https://iframe.mediadelivery.net/embed/242427/dd7407ee-9f14-44ce-b77a-61162a4c018b?autoplay=true&loop=false&muted=false&preload=true&responsive=true"
                    loading="lazy"
                    style="border: 0; position: absolute; top: 0; height: 100%; width: 100%"
                    allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                    allowfullscreen="true"
                  ></iframe>
                </div>
              </div>
            </div>
          </Modal>
        </div>

        <!-- NDA Text Modal -->
        <div v-if="!!brief?.ndaText">
          <Modal
            :show="ndaTextModal"
            @close="ndaTextModal = false"
            :closeable="true"
            max-width="2xl"
          >
            <div class="bg-white p-6 rounded-lg border border-gray-300">
              <div class="w-full font-medium flex justify-between">
                <div>&nbsp;</div>
                <div
                  @click.prevent="ndaTextModal = false"
                  class="text-gray-400 hover:text-blue-400 cursor-pointer"
                >
                  <i class="fa fa-times"></i>
                </div>
              </div>
              <div class="text-md mt-4 mb-2">
                <Markdown :content="convertNewLine(brief.ndaText || '...')" />
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </main>
  </div>
</template>

<style style="scoped" lang="scss">
.contented-brief-invite-page-bg-gradient {
  @apply bg-gradient-to-r from-[#2C2C97] from-[0%] via-[#6A22BF] via-[50%] to-[#FA00FF] to-[100%];
}
#splitter {
  @apply md:hidden relative mt-3 w-full py-20;
  &:before {
    content: '';
    position: absolute;
    top: -15px;
    right: 0;
    height: 20%;
    width: 100%;
    background: #ffffff;
    transform: skew(0deg, 2deg);
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -32px;
    right: 0;
    height: 35%;
    width: 100%;
    background: #ffffff;
    transform: skew(0deg, 355deg);
  }
}
</style>

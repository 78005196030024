<script lang="ts" setup>
import { AxiosError } from 'axios'
import { keyBy } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { computed, ref, watchEffect } from 'vue'
import { createTeamInvite, deleteTeamInvite } from '@/queries/api'
import Modal from '@/components/Jetstream/Modal.vue'
import AlertDialog from '@/components/AlertDialog.vue'
import CopyLink from '@/components/CopyLink.vue'
import ActionButton from '@/components/Pages/Teams/Partial/ActionButton.vue'
import { useTeamStore } from '@/stores/team'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { baseUrl } from '@/utils/route'
import { Vue3Lottie } from 'vue3-lottie'
import LottieLoadingBlocks from '@/lottie/loading-blocks-2.json'

const props = withDefaults(
  defineProps<{
    type: 'uuid' | 'nickname'
    typeValue: string
  }>(),
  {
    type: 'uuid',
    typeValue: ''
  }
)

const $useRouter = useRouter()

const teamStore = useTeamStore()
const { invites, roles, team, availablePermissions } = storeToRefs(teamStore)
const { can: teamCan, cannot: teamCannot } = teamStore

const loadingInvites = ref<boolean>(false)
const loadingRoles = ref<boolean>(false)
const loadingAvailablePermissions = ref<boolean>(false)

const availablePermissionsByName = computed((): Record<string, string> => {
  return availablePermissions.value.reduce(
    (acc, permission) => ({
      ...acc,
      [permission.name]: permission.description
    }),
    {}
  )
})

type FormInviteType = {
  email: string
  message?: string
  role_uuid: string
}

const formInvite = ref<FormInviteType>({
  email: '',
  message: '',
  role_uuid: ''
})

const selectedRole = computed(() => {
  return rolesByUuid.value[formInvite.value.role_uuid]
})

const rolesByUuid = computed(() => keyBy(roles.value, 'uuid'))

const showInviteModal = ref<boolean>(false)

const loadInvites = async () => {
  loadingInvites.value = true
  if (teamCan('team.invites.list')) {
    await teamStore.fetchTeamInvites(props.type, props.typeValue)
  }
  loadingInvites.value = false
}

const loadRoles = async () => {
  loadingRoles.value = true
  if (teamCan('team.roles.list')) {
    await teamStore.fetchTeamRoles(props.type, props.typeValue)
  }
  loadingRoles.value = false
}

const loadAvailablePermissions = async () => {
  loadingAvailablePermissions.value = true
  await teamStore.fetchAvailablePermissions(props.type, props.typeValue)
  loadingAvailablePermissions.value = false
}

watchEffect(async () => {
  loadInvites()
  loadRoles()
  loadAvailablePermissions()
})

const generateUniqueToken = () => {
  // create a new array of 16 random bytes
  const array = new Uint8Array(16)
  window.crypto.getRandomValues(array)

  // convert the array to a string
  const token = Array.from(array, function (byte) {
    return ('0' + (byte & 0xff).toString(16)).slice(-2)
  }).join('')

  // return 13 digit timestamp + 32 character uuid + 32 character token
  return [new Date().getTime(), uuidv4().replace(/-/gi, ''), token].join('.')
}

const onSubmit = async (data: FormInviteType) => {
  try {
    await createTeamInvite(props.type, props.typeValue, {
      roleUuid: data.role_uuid,
      email: data.email,
      message: data?.message || '',
      token: generateUniqueToken(),
      inviteLink:
        baseUrl() +
        $useRouter.resolve({
          name: 'teams.show',
          params: {
            nickname: team.value?.nickname,
            view: 'invite'
          }
        }).href,
      teamLink:
        baseUrl() +
        $useRouter.resolve({
          name: 'teams.show',
          params: {
            nickname: team.value?.nickname
          }
        }).href
    })
    closeModal()
    loadInvites()
    loadRoles()
  } catch (err) {
    console.log(err)
  }
}

const removeConfirmDialog = ref<InstanceType<typeof AlertDialog> | null>(null)
const onRemove = async (inviteUuid: string) => {
  try {
    if (teamCannot('team.invites.delete')) {
      throw Error('You do not have permission to remove invites.')
    }

    const ok = await removeConfirmDialog.value?.show({
      title: 'Remove Invite',
      message: `Are you sure you want to remove this invite?`,
      okButton: 'Yes',
      cancelButton: 'No'
    })

    if (ok) {
      await deleteTeamInvite(props.type, props.typeValue, inviteUuid)
      loadInvites()
      loadRoles()
    }
  } catch (err) {
    if (err !== false) {
      await removeConfirmDialog.value?.show({
        title: 'Error',
        message:
          err instanceof AxiosError
            ? err.response?.data.message
            : 'Something went wrong while removing the invite.',
        okButton: 'Ok'
      })
    }
  }
}

const resetForm = () => {
  formInvite.value = {
    email: '',
    message: '',
    role_uuid: ''
  }
}

const openModal = () => {
  resetForm()
  showInviteModal.value = true
}

const closeModal = () => {
  resetForm()
  showInviteModal.value = false
}
</script>

<template>
  <div>
    <!-- Title -->
    <div class="flex items-center">
      <h2 class="text-xl tracking-wide font-medium">Invites</h2>
      <span v-if="teamCan('team.invites.create')">
        <ActionButton icon="fa-solid fa-plus-circle" @click.prevent="openModal">
          Invite
        </ActionButton>
      </span>
    </div>

    <!-- Table showing Open Invites -->
    <div>
      <div v-if="loadingInvites">
        <Vue3Lottie :animationData="LottieLoadingBlocks" :height="50" :width="50" />
      </div>
      <div class="table-cmg" v-else-if="invites && invites?.length > 0">
        <table>
          <thead>
            <tr>
              <th scope="col">E-Mail</th>
              <th scope="col">Link</th>
              <th scope="col">Role</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in invites" :key="item.uuid">
              <th scope="row" class="w-3/5">
                {{ item.email }}
              </th>
              <td>
                <CopyLink
                  v-if="team"
                  :linkTo="{
                    name: 'teams.show',
                    params: {
                      nickname: team.nickname,
                      view: 'invite'
                    },
                    query: {
                      id: item.uuid,
                      token: item.token
                    }
                  }"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <i class="fal fa-clipboard"></i>
                  <span class="sr-only">Copy Link</span>
                </CopyLink>
              </td>
              <td>
                {{ rolesByUuid?.[item.roleUuid]?.displayName }}
              </td>
              <td>
                <span v-if="teamCannot('team.invites.delete')"> &mdash; </span>
                <span v-else>
                  <button
                    type="button"
                    class="font-medium text-cw-primary hover:underline"
                    @click.prevent="onRemove(item.uuid)"
                    title="Remove"
                  >
                    <i class="fa-light fa-trash"></i>
                    <span class="sr-only">Remove</span>
                  </button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p class="text-sm text-gray-500">No invites found.</p>
      </div>
    </div>

    <!-- Remove Invite Modal -->
    <AlertDialog ref="removeConfirmDialog"></AlertDialog>

    <!-- Invite Modal -->
    <Modal :show="showInviteModal" @close="closeModal" :closeable="true">
      <div class="bg-white p-6 rounded-lg border border-gray-300">
        <div class="w-full font-medium flex justify-between">
          <div class="text-xl">Invite Member</div>
          <div @click="closeModal" class="text-gray-400 hover:text-blue-400 cursor-pointer">
            <i class="fa fa-times"></i>
          </div>
        </div>
        <div class="">
          <FormKit type="form" :actions="false" @submit="onSubmit">
            <div class="my-1">
              <FormKit
                type="text"
                name="email"
                label="E-mail"
                v-model="formInvite.email"
                validation="required|email"
                placeholder="Enter the email address for the person you wish to invite..."
              />
            </div>
            <div class="my-1">
              <FormKit
                type="textarea"
                name="message"
                v-model="formInvite.message"
                label="Message"
                placeholder="Remember to write in complete sentences."
                validation="length:0,250"
                validation-visibility="live"
                :validation-messages="{
                  length: 'Instructions cannot be more than 250 characters.'
                }"
              />
            </div>
            <div class="my-1">
              <FormKit
                type="radio"
                name="role_uuid"
                label="Role"
                v-model="formInvite.role_uuid"
                :validation="[['required', 'in:' + roles?.map((role) => role.uuid).join(',')]]"
                :options="
                  roles?.map((role) => ({
                    label: role.displayName || role.name || '',
                    value: role.uuid
                  }))
                "
              />
            </div>
            <div
              class="my-1 text-sm bg-gray-50 p-3 px-4 rounded-md text-slate-800"
              v-if="formInvite.role_uuid"
            >
              <div class="uppercase tracking-wider font-semibold text-slate-500">
                <strong>Permissions in {{ selectedRole.displayName }}</strong>
              </div>
              <div class="my-1">
                <ul class="flex flex-wrap">
                  <li
                    v-for="permission in selectedRole.permissions"
                    :key="permission"
                    class="w-full md:w-1/2 my-1"
                  >
                    <span class="flex items-center">
                      <i class="fa-thin fa-square-check text-xs mr-3"></i>
                      {{ availablePermissionsByName?.[permission] || permission }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="flex item-center justify-between">
              <FormKit
                type="submit"
                label="Submit"
                input-class="rounded-full px-6 text-sm font-bold"
                outer-class="text-center"
              />
            </div>
          </FormKit>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script lang="ts" setup>
import { computed, onUnmounted, ref, watch, watchEffect } from 'vue'
import { type Survey, type RatingSurveyQuestion } from 'posthog-js/lib/src/posthog-surveys-types'

export interface Props {
  id: {
    production?: string
    preview?: string
    uat?: string
  }
  title?: string
  textSizeClass?: string
}

const props = withDefaults(defineProps<Props>(), {
  textSizeClass: 'text-5xl'
})

const surveySentFired = ref<boolean>(false)
const surveyDismissedFired = ref<boolean>(false)

const survey = ref<Survey>()

const ratings = [
  {
    response: '1',
    // emoji: ':disappointed:',
    font: 'fa-face-worried'
  },
  {
    response: '2',
    // emoji: ':worried:',
    font: 'fa-frown'
  },
  {
    response: '3',
    // emoji: ':neutral_face:',
    font: 'fa-face-meh'
  },
  {
    response: '4',
    // emoji: ':slightly_smiling_face:',
    font: 'fa-face-smile'
  },
  {
    response: '5',
    // emoji: ':smile:',
    font: 'fa-face-grin'
  }
]

const surveyId = ref<string>()
const environment = ref<keyof Props['id']>()

const getEnvironment = (hostname: string = ''): keyof Props['id'] => {
  hostname = (hostname || '').toLowerCase()
  let key: keyof Props['id'] = 'preview'
  if (hostname.startsWith('app.')) {
    key = 'production'
  } else if (hostname.startsWith('uat.')) {
    key = 'uat'
  }
  return key
}

const surveyShown = (id?: string) => {
  if (!id) return

  window?.posthog?.capture('survey shown', {
    $survey_id: id
  })
}

const surveyDismissed = (id?: string) => {
  if (!id || surveyDismissedFired.value || surveySentFired.value) return

  window?.posthog?.capture('survey dismissed', {
    $survey_id: id
  })

  surveyDismissedFired.value = true
}

const surveySent = (id?: string, response?: string) => {
  if (!id || !response || surveySentFired.value) return

  window?.posthog?.capture('survey sent', {
    $survey_id: id,
    $survey_response: `${response}`
  })

  surveySentFired.value = true
}

const getSurvey = async (id: string) => {
  if (!id) return
  window?.posthog?.surveys.getSurveys((surveys: Survey[]) => {
    survey.value = surveys.find((survey: Survey) => survey.id === id)
  }, true)
}

const question = computed((): RatingSurveyQuestion => {
  return survey.value?.questions?.[0] as RatingSurveyQuestion
})

watch(survey, (nv, ov) => {
  if (nv && nv !== ov) {
    surveyShown(nv.id)
  }
})

watchEffect(async () => {
  environment.value = getEnvironment(window.location.hostname)
  surveyId.value = props.id && props.id[environment.value] ? props.id[environment.value] : undefined
  if (surveyId.value) {
    await getSurvey(surveyId.value)
  }
})

// fire dismissed survey event when the user leaves without submitting
window.addEventListener('beforeunload', function () {
  surveyDismissed(survey.value?.id)
})

onUnmounted(() => {
  surveyDismissed(survey.value?.id)
})
</script>

<template>
  <div :survey-id="surveyId" :environment="environment">
    <div v-if="survey?.id && question?.type === 'rating'">
      <div v-if="surveySentFired" class="text-center">
        <p>Thank you for your feedback!</p>
      </div>
      <div v-else>
        <h3 class="text-[#3F3F46] font-bold text-xl" v-if="props?.title">
          {{ props.title }}
        </h3>
        <ul class="survey-rating flex items-center justify-evenly">
          <li
            v-for="rating in ratings"
            :key="rating.response"
            @click="surveySent(survey.id, rating.response)"
            class="survey-rating-emoji cursor-pointer text-gray-400 hover:text-[#ffd533]"
            :title="rating.response"
          >
            <span v-if="rating.font" :class="[props.textSizeClass]">
              <i :class="rating.font" class="fa-thin"></i>
              <i :class="rating.font" class="fa-solid"></i>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.survey-rating {
  .survey-rating-emoji {
    span {
      i.fa-thin {
        display: inline;
      }
      i.fa-solid {
        display: none;
      }
    }
    &:hover {
      span {
        i.fa-thin {
          display: none;
        }
        i.fa-solid {
          display: inline;
        }
      }
    }
  }
}
</style>

<script lang="ts" setup>
import Wrapper from '@/components/Pages/Creatives/Wizard/Shared/Wrapper.vue'
import ProfileForm, {
  type EditFormOnSubmitSuccessData
} from '@/components/Pages/Profiles/EditForm.vue'
import AuthWrapper from '@/components/Pages/Creatives/Wizard/Shared/Auth.vue'

const props = withDefaults(
  defineProps<{
    onDone?: () => void | undefined
  }>(),
  {
    onDone: (): void => {}
  }
)

const onSubmitSuccess = (data: EditFormOnSubmitSuccessData) => {
  console.log('onSubmitSuccess', data)
  props?.onDone()
}
</script>

<template>
  <Wrapper>
    <AuthWrapper
      title="Add Profile"
      description="Before you add your profile information please login or register using a social media account"
    >
      <ProfileForm
        :hide-fields="{
          languages: true
        }"
        :onSubmitSuccess="onSubmitSuccess"
        :submitButtonLabel="'Next'"
      />
    </AuthWrapper>
  </Wrapper>
</template>

<script lang="ts" setup>
// @ts-nocheck
import { ref } from 'vue'
// import { format as dateFormat } from 'date-fns'
import { getExpiryDate } from '@/utils/dateChecks'
import { storeToRefs } from 'pinia'
// import { memoize } from 'lodash'
import { useToast } from 'vue-toastification'
import { router } from '@inertiajs/vue3'

import Alert from '@/components/Alert.vue'
import BadgePill from '@/components/BadgePill.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import CotoValue from '@/components/CotoValue.vue'
import CurrencyValue from '@/components/CurrencyValue.vue'
import LetterboxImage from '@/components/Pages/Imaging/LetterboxImage.vue'
import Loading from '@/components/Loading.vue'
import MediaPaging from '@media/MediaPaging.vue'
import TimeUntilExpired from '@/components/TimeUntilExpired.vue'
import getConfig from '@/config/config'

import { useNearWalletStore } from '@/stores/near-wallet'

const nearWalletStore = useNearWalletStore()
const { accountId } = storeToRefs(nearWalletStore)

export interface Props {
  items?: any[]
  mode: string
  search?: string
  between?: boolean
  pagingOffset?: number
  pagingPerPage?: number
  media_preference?: string
}

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
  search: '',
  between: false,
  pagingOffset: 0,
  pagingPerPage: 10,
  media_preference: 'premium'
})

const getLicence = (item) => {
  if (!item.licences) {
    return false
  }
  for (var i = 0; i < item.licences.length; i++) {
    if (accountId.value == item.licences[i]['owner']) {
      return item.licences[i]
    }
  }
}

// mode may change from 'Licence' to 'Download' if content is already licensed
const getMode = (item, mode: string) => {
  if (mode == 'license') {
    if (getLicence(item)) {
      return 'download'
    }
  }
  return mode
}

const getSpent = (item) => {
  const licence = getLicence(item)
  if (licence) {
    return parseFloat(licence.price)
  }
  return parseFloat(item.licencingPrice) ? parseFloat(item.licencingPrice) : parseFloat(item.spent)
}

const isAdmin = () => {
  if (getConfig().TEST_MODE) {
    return true
  }
  return [
    'sukerman.near',
    'sukerman.testnet',
    'atinkapoor.testnet',
    'maarten-multiverse.testnet'
  ].includes(accountId.value)
}

const toast = useToast()

const emit = defineEmits(['init', 'update', 'delete'])

// this is a bodge to stop the compiler not defining emit
emit('init', true)

const loading = ref(false),
  error = ref(false)

const detailsClass = 'flex items-center justify-between p-3'
const linksClass =
  'font-semibold tracking-wide text-cm-blue-light-400 group-hover:text-cm-blue-light-500'

const clickAction = (item) => {
  // window.open breaks the test
  const mode = getMode(item, props.mode)

  if (mode == 'download') {
    // temporary fix until direct download can be done
    router.visit(route('marketplace.licensing.detail', { uuid: item.id }), {
      data: {
        pagingOffset: props.pagingOffset,
        media_preference: props.media_preference,
        pagingPerPage: props.pagingPerPage,
        search: props.search
      }
    })
    /*
        if (item.media_link_original) {
            window.open(item.media_link_original, '_blank')
        } else {
            window.open(item.media_link, '_blank')
        }
        */
  } else if (mode == 'license') {
    router.visit(route('marketplace.licensing.detail', { uuid: item.id }), {
      data: {
        pagingOffset: props.pagingOffset,
        media_preference: props.media_preference,
        pagingPerPage: props.pagingPerPage,
        search: props.search
      }
    })
  } else if (mode == 'bids') {
    router.visit(
      route('scout.content.bid', {
        briefResponse: item.id,
        pagingOffset: props.pagingOffset,
        pagingPerPage: props.pagingPerPage
      })
    )
  } else if (mode == 'bid') {
    router.visit(
      route('scout.content.bid', {
        briefResponse: item.id,
        pagingOffset: props.pagingOffset,
        pagingPerPage: props.pagingPerPage
      })
    )
    // router.visit(route('scout.briefs.response', {
    //     brief: item.brief_id,
    //     briefResponse: item.id,
    // }));
    /*
    } else  (props.mode == 'pay') {
        window.alert("[TODO]: Need a API for this!");
    } */
  } else if (mode == 'uploads') {
    router.visit(
      route('studio.portfolio.detail', {
        uuid: item.id
      })
    )
  } else if (mode == 'responses') {
    router.visit(
      route('studio.portfolio.detail', {
        uuid: item.id
      })
    )
  } else {
    console.error('clickAction : no action defined for ', mode, item)
  }
}

/*

not called atm, ruins coverage stats

// convert 'Partially outbid' etc to tailwind class colours
const getClassNames = function(v) {
    if (!v.length) return;
    const lower = v.replaceAll('Bid ','').replace(' ','-').toLowerCase(v);
    const val = 'bg-bid-status-' + lower + ' text-bid-status-text-' + lower;
    return val;
};

const getColorForBidStatus = (status = '') => {
    const colors = {
        'winning': 'green',
        'partially outbid': 'yellow',
        'outbid': 'red',
    };
    return colors[("" + status).toLowerCase()] || 'gray';
};
*/

const getPrice = (item) => {
  if (item.bids.length > 0) {
    return item.bids[0].value
  }
  return 0
}

const getBidsSum = (item) => {
  if (!item.bids) {
    return 0
  }

  let value = 0
  for (const bid of item.bids) {
    value += bid.value
  }
  return value
}

const deleteItem = async function (item) {
  const ok = await confirmDialog.value.show({
    title: 'Delete content',
    message: `Are you sure you want to delete ${item.name}?`,
    okButton: 'Delete'
  })
  if (ok) {
    loading.value = true
    await nearWalletStore.deleteContent(item.id)
    toast.success('Content Deleted', {
      timeout: 0
    })
    loading.value = false
    emit('delete', true)
  }
}

const update = (vals) => {
  emit('update', vals)
}

// const groupdate = memoize((timestamp) => {
//   return dateFormat(parseInt(timestamp), 'LLLL yyyy')
// })

// const calculateTotal = memoize((date, items) => {
//   return items.reduce((acc, item) => acc + item.pay_amount, 0.0)
// })

const matchedTerms = (arr) => {
  var matched = []
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] == props.search) {
      matched.push(arr[i])
    }
  }
  return matched
}

const confirmDialog = ref(null)

const processedItems = ref([])

const replaceDom = (url) => {
  // no link
  if (!url || !url.match('http')) {
    return
  }

  // remove broken ipfs providers
  url = url.replace('https://', '')
  if (url.match('edge.estuary.tech')) {
    // https://edge.estuary.tech/gw/bafybeihvohlindutg5fqwsj5zgl24yjaesoyhmlpfh7f7kn3clnprijy3q
    url = url.replace('edge.estuary.tech/gw/', '')
  } else if (url.match('ipfs.w3s.link')) {
    // https://bafybeihvohlindutg5fqwsj5zgl24yjaesoyhmlpfh7f7kn3clnprijy3q.ipfs.w3s.link/92020234-acac-427c-b801-8af74769eb5e-watermarked
    url = url.split('.ipfs.w3s.link')
    url = url[0] + url[1]
  } else if (url.match('ipfs.io')) {
    // https://bafybeihvohlindutg5fqwsj5zgl24yjaesoyhmlpfh7f7kn3clnprijy3q.ipfs.w3s.link/92020234-acac-427c-b801-8af74769eb5e-watermarked
    url = url.replace('ipfs.io/ipfs', '')
  } else {
    console.warn('Unknown domain in url', url)
    return url
  }
  // web2 trusted solution
  return 'https://ipfs.miappi.com/ipfs/' + url
}

props.items.forEach((item) => {
  item.media_link = replaceDom(item.media_link)
  item.media_link_original = replaceDom(item.media_link_original)
  item.media_original = replaceDom(item.media_original)
  item.media_thumbnail = replaceDom(item.media_thumbnail)
  processedItems.value.push(item)
})
</script>

<template>
  <div class="w-full">
    <div v-if="loading" class="w-full flex justify-center p-2">
      <Loading />
    </div>
    <div v-else-if="error" class="w-full flex justify-center p-2">
      <Alert type="error" :text="error" />
    </div>
    <div v-else class="search-results-ready">
      <ConfirmDialog ref="confirmDialog"></ConfirmDialog>

      <MediaPaging
        :pagingTotal="100"
        :pagingOffset="pagingOffset"
        :pagingPerPage="pagingPerPage"
        @update="update"
      />

      <div
        v-if="processedItems"
        class="flex flex-wrap"
        :class="{
          'justify-between': between
        }"
      >
        <div
          v-for="item in processedItems"
          :key="item.id"
          class="search-result mr-3 mb-3 mt-3 border border-gray-250 bg-white"
        >
          <pre v-if="1 == 2" class="text-xs">{{ item.bid }}</pre>

          <LetterboxImage
            imageRes="small"
            :autoFit="true"
            :rounded="false"
            :rounded_top="false"
            :shadow="false"
            :media="item"
          />

          <div v-if="props.search" class="flex gap-2 m-2 mt-3 mb-0">
            <div
              v-for="term in matchedTerms(item.search_meta)"
              :key="term"
              class="rounded-md bg-blue-400 text-white text-xs p-1"
            >
              {{ term }}
            </div>
          </div>

          <div :class="detailsClass" v-if="mode">
            <div v-if="getMode(item, mode) == 'download'">
              <small class="text-gray-500">
                <CotoValue :value="getSpent(item)" />
                Spent
              </small>
              <span class="block text-cm-blue-500">
                <CurrencyValue :value="getSpent(item)" />
              </span>
            </div>

            <div v-if="getMode(item, mode) == 'license'">
              <small class="text-gray-500">
                <CotoValue :value="parseFloat(item.licencingPrice)" />
              </small>
              <span class="block text-cm-blue-500">
                <CurrencyValue :value="parseFloat(item.licencingPrice)" />
              </span>
            </div>

            <div v-if="mode == 'pay'">
              <div class="text-cm-blue-500">
                <strong>
                  <CurrencyValue :value="parseFloat(item.spent)" />
                </strong>
                <span>&nbsp;</span>
                <small class="text-gray-500">
                  <CotoValue :format="true" :value="parseFloat(item.spent)" />
                </small>
              </div>
            </div>

            <div v-if="mode == 'bid'">
              <div>
                <strong class="text-cm-blue-500 mr-3">
                  <CurrencyValue :value="parseFloat(getBidsSum(item))" />
                </strong>
                <small class="text-gray-500">
                  <CotoValue :value="parseFloat(getBidsSum(item))" />
                </small>
              </div>
            </div>

            <div class="flex-col h-24" v-if="mode == 'bids'">
              <small class="text-gray-500"> Bid </small>
              <div class="text-cm-blue-500 text-sm">
                <strong
                  ><CurrencyValue :value="item.spent ? parseFloat(item.spent.amount) : 0" />,
                  {{ item.spent ? item.spent.percentage : 0 }}%</strong
                >
              </div>
              <div class="text-xs text-center font-bold p-1 px-2 mt-1 rounded-lg">
                {{ item.bid_status }}
              </div>
            </div>

            <div v-if="mode == 'briefs'">
              <div class="text-sm text-gray-500">Remaining</div>

              <div class="text-cm-blue-500">
                <strong>3 days</strong>
              </div>

              <!-- <div v-if="item.bid.bid_status">
                                <BadgePill :color="getColorForBidStatus(item.bid.bid_status)">
                                    {{ item.bid.bid_status }}
                                </BadgePill>
                            </div> -->
            </div>

            <div v-if="mode == 'rewards'" class="flex justify-between w-full">
              <div>
                <span class="block text-gray-500"> Spend </span>
                <span class="block text-cm-blue-500">
                  <strong class="block mr-2">
                    <CurrencyValue
                      :value="item.ibid ? parseFloat(item.mybid.value) : parseFloat('0')"
                    />
                  </strong>
                  <small class="block text-gray-500">
                    <CotoValue
                      :format="true"
                      :value="item.ibid ? parseFloat(item.mybid.value) : 0"
                    />
                  </small>
                </span>
              </div>
              <div>
                <span class="block text-gray-500"> Earned </span>
                <span class="block text-cm-blue-500">
                  <strong class="block mr-2">
                    <CurrencyValue :value="item.earning" />
                  </strong>
                  <small class="block text-gray-500">
                    <CotoValue :format="true" :value="item.earning" />
                  </small>
                </span>
              </div>
              <div>
                <span class="block text-gray-500"> Owned </span>
                <span class="block text-cm-blue-500">
                  <strong class="mr-2"
                    >{{ item.percentage_owned || item.mybid.percentage }}%</strong
                  >
                </span>
              </div>
            </div>

            <div v-if="mode == 'rewards-studio'" class="flex justify-between w-full">
              <div>
                <small class="block text-gray-500"> Earned </small>
                <span class="block text-cm-blue-500">
                  <strong>
                    <CurrencyValue :value="parseFloat(item.earned_amount)" />
                  </strong>
                  <span>&nbsp;</span>
                  <small class="text-gray-500">
                    <CotoValue :format="true" :value="parseFloat(item.earned_amount)" />
                  </small>
                </span>
              </div>
              <div>
                <small class="block text-gray-500"> Owned </small>
                <span class="block text-cm-blue-500">
                  <strong class="mr-2">{{ item.percentage_owned }}%</strong>
                </span>
              </div>
            </div>

            <div class="flex-col h-24" v-if="mode == 'uploads'">
              <small class="flex-none text-gray-500"> Remaining </small>
              <div class="text-cm-blue-500 grow">
                <TimeUntilExpired :dateTime="getExpiryDate(item.created_on)" />
              </div>
            </div>

            <div v-if="mode == 'uploads-ended'">
              <div v-if="item.type === 'standard'" class="flex items-center justify-between">
                <div>
                  <small class="block text-gray-500"> Type </small>
                  <span class="block text-cm-blue-500">
                    <strong>Standard</strong>
                  </span>
                </div>
              </div>
              <div v-else class="flex items-center justify-between">
                <div>
                  <small class="block text-gray-500"> Type </small>
                  <span class="block text-cm-blue-500">
                    <strong>Premium</strong>
                  </span>
                </div>
              </div>
            </div>

            <!-- Action Link on the bottom right -->
            <div v-if="mode != 'rewards' && mode != 'rewards-studio'" class="text-right capitalize">
              <div v-if="mode == 'briefs'">
                <small>
                  <span class="text-gray-500">Bid </span>
                  <span class="font-semibold">{{ item.bid.amount_pct }}%</span>
                </small>
                <span class="block text-cm-blue-500">
                  <strong class="block">
                    <CurrencyValue
                      :value="parseFloat(item.bid.amount)"
                      :currency="item.bid.amount_currency"
                    />
                  </strong>
                  <small class="text-gray-500 block">
                    <CotoValue :value="parseFloat(item.bid.amount)" />
                  </small>
                </span>
              </div>
              <div v-else-if="mode == 'bids'">
                <span class="block text-gray-500 text-xs"> Spent </span>
                <span class="block text-cm-blue-500 text-sm">
                  <strong
                    ><CurrencyValue :value="parseFloat(item.mybid?.value ?? '0')" />,
                    {{ item.mybid?.percentage }}%</strong
                  >
                </span>
                <span class="text-sm">
                  <CotoValue :value="parseFloat(item.mybid?.value ?? '0')" />
                </span>
                <div class="flex justify-between"></div>
              </div>
              <div v-else-if="mode == 'uploads'">
                <small class="block text-gray-500">
                  <CotoValue :value="getPrice(item)" />
                </small>
                <span class="block text-cm-black-500">
                  <CurrencyValue :value="parseFloat(getPrice(item))" />
                </span>
              </div>
              <div v-else-if="mode == 'uploads-ended'">
                <button class="click-button-delete" @click.prevent="deleteItem(item)">
                  Delete
                </button>
              </div>
              <div v-else-if="mode == 'pay'"></div>
              <a
                v-else
                href="#"
                :title="item.owner"
                @click.prevent="clickAction(item)"
                class="cypress-main-action click-button-action"
                :class="linksClass"
              >
                <span v-if="item.status === 'paid'">
                  <BadgePill> Paid </BadgePill>
                </span>
                <span v-else-if="item.status === 'unpaid'"> Pay </span>
                <span v-else class="flex flex-col">
                  <div>{{ getMode(item, mode) }}</div>
                </span>
              </a>

              <button
                v-if="item.owner == accountId || isAdmin()"
                class="click-button-delete text-xs mt-2 text-blue-400"
                @click.prevent="deleteItem(item)"
              >
                Delete
              </button>
            </div>
          </div>
        </div>

        <MediaPaging
          v-if="items.length"
          :pagingTotal="100"
          :pagingOffset="pagingOffset"
          :pagingPerPage="pagingPerPage"
          @update="update"
        />
      </div>
    </div>
  </div>
</template>

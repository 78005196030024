import axios, { type AxiosResponse } from 'axios'

import { lazyNearWalletStore as nearWalletStore } from '@/stores/near-wallet'

import getConfig from '@/config/config'

import { hashValue } from '@/utils/hasher'

const CONTENT_API = getConfig().apiUrl + '/content-api/v1'
const MEDIA_API = getConfig().apiUrl + '/media-api/v1'
const BRIEF_API = getConfig().apiUrl + '/creative-brief-api/v1'

export const getToken = () => {
  return nearWalletStore.apiToken
}

const getHeaders = () => {
  return {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Signature': nearWalletStore.apiToken
  }
}

export const checkStatus = (res: {
  status: number
  data: {
    error: {
      message: string
    }
  }
}) => {
  if (res.status >= 200 && res.status < 400) {
    return true
  } else {
    window.toast.error(res.data.error.message)
  }
}

export const logError = (error: { message: any; response?: any; request?: any; config?: any }) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error(error.response.data, error.response.status, error.response.headers)
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error(error.request)
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error('Error', error.message)
  }
  console.log(error.config)
}

const makeId = async (url: string, data: any) => {
  return hashValue(nearWalletStore.accountId + url + JSON.stringify(data))
}

export const getResult = async (url: string, data: any) => {
  const id = await makeId(url, data)
  const result = await axios.get('/mock/' + id).catch((error) => {
    logError(error)
  })

  console.log('getResult', url, data, result)
  return result
}

export const saveResult = (data: any, result: void | AxiosResponse<any, any> | never[], url: string | undefined) => {
  console.log(`${data} ${result} ${url}`);
  // don't do this for now.
  return
}

export const postContent = async (data: any) => {
  const url = CONTENT_API + '/create'

  const result = await axios
    .post(url, data, {
      headers: getHeaders()
    })
    .catch((error) => {
      logError(error)
    })

  saveResult(data, result, url)
  return result
}

export const createKMSKey = async (name: any, contentId: any) => {
  const url = CONTENT_API + '/kmscreate'
  const result = await axios
    .post(
      url,
      {
        name,
        contentId
      },
      {
        headers: getHeaders()
      }
    )
    .catch((error) => {
      logError(error)
      throw error
    })
  return result.data
}

export const getKey = async (name: any, blob: any) => {
  const url = CONTENT_API + '/download'
  const result = await axios
    .post(
      url,
      {
        contentId: name,
        blob
      },
      {
        headers: getHeaders()
      }
    )
    .catch((error) => {
      logError(error)
      throw error
    })

  return result.data.data
}

export const postMedia = async (data: any) => {
  const url = MEDIA_API + '/create-signed-url'

  const result = await axios
    .post(url, data, {
      headers: getHeaders()
    })
    .catch((error) => {
      logError(error)
    })

  // too big
  // saveResult(data,result,url);
  return result
}

export const createBrief = async (data: any) => {
  const url = BRIEF_API + '/create'

  const result = await axios
    .post(url, data, {
      headers: getHeaders()
    })
    .catch((error) => {
      logError(error)
    })

  saveResult(data, result, url)
  return result
}

export const deleteContent = async (data: any) => {
  return

  const url = CONTENT_API + '/delete'

  const result = await axios
    .post(url, data, {
      headers: getHeaders()
    })
    .catch((error) => {
      logError(error)
    })

  saveResult(data, result, url)
  return result
}

export const deleteBrief = async (data: any) => {
  const url = BRIEF_API + '/delete'

  const result = await axios
    .post(url, data, {
      headers: getHeaders()
    })
    .catch((error) => {
      logError(error)
    })

  saveResult(data, result, url)
  return result
}

export const addContentToBrief = async (data: any) => {
  const url = CONTENT_API + '/brief'

  const result = await axios
    .post(url, data, {
      headers: getHeaders()
    })
    .catch((error) => {
      logError(error)
    })

  saveResult(data, result, url)
  return result
}

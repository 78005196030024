<script lang="ts" setup>
// @ts-nocheck

import { ref, watchEffect } from 'vue'
import { useNearWalletStore } from '@/stores/near-wallet'
import { storeToRefs } from 'pinia'
import { fetchProfile } from '@/queries/api'
import { useUcanStore } from '@/stores/ucan'

import BriefDetailsDownloadAllButton from '@briefs/BriefDetailsDownloadAllButton.vue'
import MediaItemTileSubmission from '@media/MediaItemTileSubmission.vue'
import SurveyRating from '@/components/SurveyRating.vue'

const ucanStore = useUcanStore()
const nearWalletStore = useNearWalletStore()
const { accountId } = storeToRefs(nearWalletStore)

const props = defineProps({
  brief: {
    type: Object,
    default: () => ({})
  }
})

const filtered = ref([])

watchEffect(async () => {
  try {
    if (props.brief?.acceptedProfiles && props.brief.candidateProfiles) {
      const acceptedUserIds = props.brief.acceptedProfiles
      const acceptedProfiles = props.brief.candidateProfiles.filter((profile) =>
        acceptedUserIds.includes(profile.userId)
      )
      if (acceptedProfiles) {
        const profileData = await fetchProfile(props.brief.owner)
        const brandName = `${profileData?.firstName} ${profileData?.lastName}`
        filtered.value = await acceptedProfiles.reduce(async (accPromise, profile) => {
          const acc = await accPromise
          if (profile.contents && profile.contents.length > 0) {
            const profileData = await fetchProfile(profile.userId)
            const enhancedContents = profile.contents.map((content) => ({
              ...content,
              amountPaid: profile.amountPaid,
              contentAcceptedAt: profile.contentAcceptedAt,
              brandName,
              creatorName: `${profileData?.firstName} ${profileData?.lastName}`
            }))
            return acc.concat(enhancedContents)
          }
          return acc
        }, [])
      }
    }
  } catch (err) {
    //
  }
})
const isActive = ref()
const setActive = (id) => {
  console.log('setActive', id)
  isActive.value = id
}
</script>

<template>
  <div class="bg-white w-full rounded-md py-3 px-6 mb-9 border-2 border-[#5386EA]">
    <div class="w-full max-w-7xl flex flex-col mx-auto">
      <h3 class="text-lg font-bold tracking-wide my-3" style="text-align: left">
        Licensed content
      </h3>
      <p class="my-3">
        Below you will see all of the content that you have licensed in relation to this brief. You
        can now use this content in your projects. Either download the content or use the
        integrations with platforms such as Shopify, Hootsuite etc to publish the content directly
        to your campaigns.
      </p>
    </div>
  </div>

  <div v-if="filtered.length" class="bg-white w-full rounded-md px-8 pt-9 pb-8 mt-9 mb-6">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <div
        v-for="(entry, index) in filtered"
        :key="index"
        class="mr-2 md:mr-4"
        :style="{ height: '100%' }"
      >
        <MediaItemTileSubmission
          @videoPlaying="setActive"
          :item="entry"
          :links="true"
          :isActive="isActive"
          :notEncrypted="false"
          :showOwner="true"
          :showDescription="true"
          :isAccepted="true"
          :brief="props.brief"
          :imageCss="['md:max-h-[250px]']"
        />
      </div>
    </div>
    <div class="w-full mt-6 mb-3 mx-4" v-if="accountId && filtered.length">
      <BriefDetailsDownloadAllButton
        :items="filtered"
        :accountId="accountId"
        :isAccepted="true"
        :brief="props.brief"
        track="downloaded_licensed_assets"
      />
    </div>
    <div v-if="filtered.length" class="mt-12">
      <SurveyRating
        title="Rate your experience:"
        class="max-w-[360px] mx-auto"
        :id="{
          production: '018ff23d-8a77-0000-16a5-947dc60cd6f8',
          uat: '018ff23e-10e9-0000-5987-6cd0ff167056',
          preview: '018ff23e-393d-0000-709c-2c0ab5d6caa0'
        }"
      />
    </div>
  </div>
</template>

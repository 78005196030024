<script lang="ts" setup>
import { nextTick, onMounted, onUnmounted, ref } from 'vue'
import CreativeWelcomePageContentedSvg from '@/components/Pages/Creatives/CreativeWelcomePageContentedSvg.vue'
import CreativeWelcomePageContentedUserIconSvg from '@/components/Pages/Creatives/CreativeWelcomePageContentedUserIconSvg.vue'
import PageHeading from '@/components/PageHeading.vue'

const pageHeight = ref(0)

const updatePageHeight = () => {
  pageHeight.value = 0
  nextTick(() => {
    pageHeight.value =
      Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight
      ) + 25
  })
}

onMounted(() => {
  window.addEventListener('resize', updatePageHeight)

  updatePageHeight()
})

onUnmounted(() => {
  window.removeEventListener('resize', updatePageHeight)
})
</script>

<template>
  <div
    :key="pageHeight"
    :style="{
      height: pageHeight + 'px'
    }"
  >
    <div class="relative h-full w-full">
      <div
        class="p-4 absolute top-0 bottom-0 right-0 left-0 bg-cover bg-no-repeat bg-center z-0"
        :style="{
          background: `url(https://contented-optimized.b-cdn.net/contented-uploads.b-cdn.net/creative-start-page/1b3a1d6ce6de1af5cb3879150cbc195b.jpeg?auto_optimize=medium)`,
          'background-blend-mode': 'soft-light, normal'
        }"
      ></div>
      <div
        class="p-4 absolute top-0 bottom-0 right-0 left-0 opacity-95 z-10"
        :style="{
          background: `linear-gradient(90deg, #2F2FA5 57%, #FF008B 115%)`
        }"
      ></div>
      <div class="absolute top-0 bottom-0 right-0 left-0 z-20">
        <PageHeading background="transparent" title="Contented">
          <template #loginButton>
            <div class="h-8 w-8 mt-1 mr-1">
              <CreativeWelcomePageContentedUserIconSvg />
            </div>
          </template>
        </PageHeading>
        <main class="text-white">
          <div class="max-w-5xl mx-auto p-4 pt-6 pb-12">
            <div class="flex justify-between items-center flex-wrap flex-col">
              <div class="flex flex-col justify-start">
                <h1 class="text-4xl font-bold mb-2 text-center">Welcome to</h1>
                <h2 class="text-5xl font-bold mb-6 text-center">
                  <CreativeWelcomePageContentedSvg />
                </h2>
                <p class="text-2xl mb-8 font-bold text-center mt-3">Teams</p>
              </div>
            </div>

            <div class="pt-0 pb-3">
              <div class="border-2 border-white h-10 w-1 mx-auto">&nbsp;</div>
            </div>

            <h3 class="text-xl font-semibold mb-4 max-w-md mx-auto">
              Let's start creating authentic content through collaboration!
            </h3>

            <div
              class="font-semibold uppercase text-xs tracking-widest max-w-md mx-auto pt-4 text-center hover:text-cw-primary"
            >
              <router-link :to="{ name: 'teams.create' }"> Skip to create Team </router-link>
            </div>
          </div>

          <div class="bg-[#F2F2F6] w-full py-16 px-4">
            <h3 class="text-cw-primary text-xl font-bold tracking-wide">
              How does Contented Teams work?
            </h3>

            <div class="pt-3 pb-6">
              <div class="border-2 border-cw-primary h-10 w-1 mx-auto">&nbsp;</div>
            </div>

            <div
              class="max-w-5xl mx-auto grid grid-cols-1 space-y-4 md:grid-cols-3 md:space-y-0 gap-4 mb-8 mt-9"
            >
              <div
                class="bg-white rounded-md p-4 relative"
                v-for="item in [
                  {
                    number: 1,
                    paragraphs: ['Team members can create their own Briefs']
                  },
                  {
                    number: 2,
                    paragraphs: [
                      'You can Invite colleague/s to join your Brief to collaborate or delegate a specific role at any time'
                    ]
                  },
                  {
                    number: 3,
                    paragraphs: [
                      'Any Briefs started, drafted, in progress or completed can be accessed by Team members through your My Teams page in the Login menu'
                    ]
                  }
                ]"
                :key="item.number"
              >
                <div class="w-full flex justify-center items-center mb-3">
                  <div
                    class="-mt-10 w-10 h-10 bg-cw-primary rounded-full text-white font-bold flex items-center justify-center"
                  >
                    {{ item.number }}
                  </div>
                </div>
                <div
                  class="text-center text-cw-primary font-medium tracking-wide h-24 flex items-center justify-center"
                >
                  <p v-for="p in item.paragraphs" :key="p">
                    {{ p }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full py-16 px-4">
            <h3 class="text-xl font-bold tracking-wide max-w-md mx-auto">
              The first step is creating your Team and inviting colleagues so that you can...
            </h3>

            <div class="pt-3 pb-6">
              <div class="border-2 border-white h-10 w-1 mx-auto">&nbsp;</div>
            </div>

            <div
              class="max-w-5xl mx-auto grid grid-cols-1 space-y-4 md:grid-cols-3 md:space-y-0 gap-4 mb-8 mt-9"
            >
              <div
                class="rounded-md p-4 relative border-2 border-white"
                v-for="item in [
                  {
                    number: 1,
                    paragraphs: ['Collaborate on Briefs']
                  },
                  {
                    number: 2,
                    paragraphs: ['Select Candidates']
                  },
                  {
                    number: 3,
                    paragraphs: ['Complete your projects']
                  }
                ]"
                :key="item.number"
              >
                <div class="w-full flex justify-center items-center mb-3 bg-cw-primary">
                  <div
                    class="-mt-10 w-10 h-10 rounded-full text-white font-bold flex items-center justify-center border-2 border-white bg-cw-primary"
                  >
                    {{ item.number }}
                  </div>
                </div>
                <div
                  class="text-center text-whhite font-medium tracking-wide h-24 flex items-center justify-center"
                >
                  <p v-for="p in item.paragraphs" :key="p">
                    {{ p }}
                  </p>
                </div>
              </div>
            </div>
            <div class="flex justify-center mb-6">
              <router-link :to="{ name: 'teams.create' }">
                <button class="bg-cw-primary text-white font-bold py-3 px-8 rounded-full">
                  Create your Team
                </button>
              </router-link>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
//
</style>

<script lang="ts" setup>
// import '@mux/mux-player'

import { ref, watchEffect } from 'vue'
import { router } from '@inertiajs/vue3'
import { useImageCDN } from '@/utils/image'
import { itemSrc, itemType } from '@/utils/mediaFileTypes'

import { makeTestAttributes } from '@/utils/test'

import getConfig from '@/config/config'

const props = defineProps({
  noclick: {
    type: String
  },
  height: {
    type: String,
    default: '220px'
  },
  imageRes: {
    type: String,
    default: 'medium'
  },
  media: {
    type: Object
  },
  bgColor: {
    type: String,
    default: '#FFF'
  },
  autoFit: {
    type: Boolean,
    default: false
  },
  rounded: {
    type: Boolean,
    default: true
  },
  rounded_top: {
    type: Boolean,
    default: false
  },
  shadow: {
    type: Boolean,
    default: true
  },
  popup: {
    type: Boolean,
    default: true
  }
})

// @ts-ignore
const mediaSrc = props?.media ? itemSrc(props.media) : undefined

// @ts-ignore
const mediaType = props?.media ? itemType(props.media) : undefined

const isPortrait = ref()
const isLandscape = ref()
const isLoaded = ref(false)
const isTestMode = ref(getConfig().TEST_MODE)

const imgLoaded = (img: Event) => {
  const i = img.target as HTMLImageElement
  const w = i.width
  const h = i.height

  isLoaded.value = true

  if (props.autoFit) {
    if (w > h) {
      isLandscape.value = true
    } else {
      isPortrait.value = true
    }
  }
}

const noNet = (v: string) => {
  return v.replace('.testnet', '')
}

const clickMe = () => {
  if (props.noclick) {
    router.visit(props.noclick)
  } else {
    if (mediaType == 'image') {
      if (props.media?.media_link) {
        console.log('image', props.media?.media_link)
        window.popupImage = props.media?.media_link
      }
    } else if (mediaType == 'video') {
      if (props.media?.media_link) {
        window.popupVideo = props.media.media_link
      }
    } else {
      console.error('Unrecognized media type', mediaType)
    }
  }
}

const testId = ref('test-waiting-for-data'),
  playerId = ref()

watchEffect(() => {
  playerId.value = 'player-' + props.media?.id
  if (isTestMode.value) {
    testId.value = 'test-' + props.media?.id
    makeTestAttributes(props.media, testId.value, [
      'created_on',
      'bid_count',
      'highest_bid',
      'licencingPrice',
      'media_link',
      'media_type',
      'name',
      'description',
      'orientation',
      'owner',
      'quality',
      'restricted',
      'type'
    ])
  }
})

let heightPx = ref(props.height)
</script>

<style>
.image-container.autofit,
.image-container.landscape {
  width: 320px;
}
.image-container.portrait {
  width: 240px;
}
</style>

<template>
  <div
    @click="clickMe"
    class="cursor-pointer"
    :style="{
      'background-color': bgColor,
      height: heightPx
    }"
    :class="{
      'rounded-t-md': rounded_top,
      'rounded-md': rounded,
      'shadow-md': shadow,
      'click-image': mediaType == 'image',
      'click-video': mediaType == 'video'
    }"
  >
    <div v-if="isTestMode" class="test" :id="testId" />

    <div
      class="image-container w-full h-full transition-all duration-1000 relative"
      :class="{
        autofit: autoFit,
        portrait: isPortrait,
        landscape: isLandscape,
        'flex justify-center bg-black': mediaType == 'video'
      }"
    >
      <div
        v-if="media?.owner"
        class="absolute bottom-0 right-0 p-1 text-xs text-white rounded-tl-lg"
        style="background-color: rgba(0, 0, 0, 0.35)"
      >
        {{ noNet(media.owner) }}
      </div>

      <div v-if="mediaType == 'image'" class="w-full h-full overflow-hidden">
        <div
          v-if="mediaSrc"
          class="w-full h-full transition-all duration-1000 bg-center bg-no-repeat"
          :class="{
            'opacity-0': !isLoaded,
            'opacity-100': isLoaded,
            'bg-cover': autoFit,
            'bg-contain': !autoFit
          }"
          :style="{
            'background-image': 'url(' + useImageCDN(mediaSrc, imageRes) + ')'
          }"
        ></div>

        <img
          v-if="mediaSrc"
          class="opacity-10"
          :class="{ hidden: isLoaded }"
          :src="useImageCDN(mediaSrc, imageRes)"
          @load="imgLoaded"
        />
      </div>

      <div
        v-else-if="mediaType == 'video'"
        class="w-full flex items-center justify-center bg-black text-white"
      >
        <i class="absolute fa-solid fa-play fa-2x"></i>

        <div
          v-if="props?.media?.media_thumbnail"
          class="w-full h-full transition-all duration-1000 bg-center bg-no-repeat"
          :class="{
            'opacity-0': !isLoaded,
            'opacity-100': isLoaded,
            'bg-cover': autoFit,
            'bg-contain': !autoFit
          }"
          :style="{
            'background-image': 'url(' + props.media.media_thumbnail + ')'
          }"
        ></div>
        <div :id="playerId" v-if="props?.media?.media_thumbnail">
          <img :class="{ hidden: isLoaded }" :src="props.media.media_thumbnail" @load="imgLoaded" />
        </div>

        <!--
                <mux-player
                    v-if="isMuxUrl(mediaSrc)"
                    :playback-id="getMuxId(mediaSrc)"
                    :metadata-video-title='mediaName'
                    stream-type="on-demand"
                />
                -->
      </div>
      <div v-else>
        <pre>{{ media }}</pre>
        Unsupported media type {{ mediaType }}
      </div>
    </div>
  </div>
</template>

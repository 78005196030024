<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { onMounted, onUnmounted, ref } from 'vue'
import { useHead } from '@unhead/vue'
import { mobileDetect } from '@/utils/browser'

import PageHeading from '@/components/PageHeading.vue'
import PopupImage from '@media/PopupImage.vue'

const props = defineProps({
  title: {
    type: String,
    default: 'CMG'
  },
  paddingInner: {
    type: String,
    default: 'm-6'
  },
  selectedLinkId: String
})

const { t: $t } = useI18n({})

useHead({
  title: props.title
})

const isMobile = ref(mobileDetect())
const updatePageWidth = () => {
  isMobile.value = mobileDetect()
}
onMounted(() => {
  updatePageWidth()
  window.addEventListener('resize', updatePageWidth)
})
onUnmounted(() => {
  window.removeEventListener('resize', updatePageWidth)
})

const popupActive = ref()
const setPopupState = (val: boolean) => {
  popupActive.value = val
}
</script>

<template>
  <PopupImage @popupState="setPopupState" />
  <div v-show="!popupActive || !isMobile">
    <!-- Page Heading -->
    <header class="bg-cm-blue-500 shadow">
      <PageHeading :title="$t('marketplace.title')" />
    </header>
    <main>
      <div class="flex">
        <div :class="['flex-grow', 'content', props.paddingInner]">
          <!-- Page Content-->
          <slot />
        </div>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useImageCDN } from '@/utils/image'

// Props
interface AvatarPropsData {
  file_url: string
  name: string
  description: string
  tags?: string
}

interface AvatarProps {
  profileId?: string
  data?: string | AvatarPropsData | AvatarPropsData[]
  imageBgColor?: string
  imageBorderColor?: string
  imageBorderColorHover?: string
  fallbackBorderColor?: string
  fallbackTextColor?: string
  fallbackTextSize?: string
}

const props = withDefaults(defineProps<AvatarProps>(), {
  imageBgColor: 'bg-white',
  imageBorderColor: 'border-white',
  imageBorderColorHover: 'group-hover:border-[#FFFFFF]',
  fallbackBorderColor: 'border-white',
  fallbackTextColor: 'text-white',
  fallbackTextSize: 'text-md'
})

// Helpers
const isDataJSON = (data: AvatarProps['data']): boolean => {
  if (typeof data !== 'string') {
    return false
  }

  const firstCharacter = data.substring(0, 1)
  const lastCharacter = data.substring(data.length - 1, data.length)

  const isJSON =
    (firstCharacter === '[' && lastCharacter === ']') ||
    (firstCharacter === '{' && lastCharacter === '}')

  return isJSON
}

const getFileNameFromFileUrl = (fileUrl: string, defaultValue: string = ''): string => {
  const splitUrl = fileUrl.split('/')
  return splitUrl[splitUrl.length - 1] || defaultValue
}

// Component logic
const avatar = computed((): AvatarPropsData[] | undefined => {
  const { data } = props

  // exit early if we have no data
  if (!data) {
    return undefined
  }

  // means it's JSON, we need to normalise to an array
  if (isDataJSON(data)) {
    try {
      const parsed = JSON.parse(data as string)
      const parsedAsArray = Array.isArray(parsed) ? parsed : [parsed]

      // no idea why some values are `[{"name":"","description":"","tags":""}]`
      const isGibberish =
        parsedAsArray?.[0]?.file_url?.startsWith('[{') &&
        parsedAsArray?.[0]?.file_url?.endsWith('}]')

      return isGibberish || !parsedAsArray?.[0]?.file_url ? undefined : parsedAsArray
    } catch (err) {
      return undefined
    }
  }

  // means it's a string, we need to normalise to an object
  if (typeof data === 'string') {
    return data
      ? [
          {
            file_url: data,
            name: getFileNameFromFileUrl(data, 'Avatar'),
            description: getFileNameFromFileUrl(data, 'Avatar')
          }
        ]
      : undefined
  }

  // this could mean it's an object or an array of objects
  const remaining = Array.isArray(data) ? data : [data]
  return remaining?.[0]?.file_url ? remaining : undefined
})

const avatarUrl = computed(() => {
  let url = avatar.value?.[0]?.file_url

  // try parsing the url
  if (url && props?.profileId) {
    try {
      // we need to remap this url
      const parsedUrl = new URL(url)
      if (parsedUrl.hostname === 'ipfs.io') {
        // get the domain we need to call to get the image
        const hostname =
          (props.profileId?.endsWith('.testnet') ? 'cmg-preview' : 'cmg') +
          '.contentedworld.workers.dev'

        // remap the url
        const remappedUrl = 'https://' + hostname + '/v1/profiles/' + props.profileId + '/avatar'
        url = remappedUrl
      }
    } catch (err) {
      //
    }
  }

  // return the url we have
  return url
})
</script>

<template>
  <div class="flex items-center justify-center group" v-if="avatarUrl">
    <img
      class="rounded-full border object-cover object-center h-full w-full"
      :class="[props.imageBgColor, props.imageBorderColor, props.imageBorderColorHover]"
      :src="useImageCDN(avatarUrl)"
      :title="avatar?.[0].name"
      :alt="avatar?.[0].description"
    />
  </div>
  <div
    v-else
    class="rounded-full flex items-center justify-center border"
    :class="[props.fallbackBorderColor, props.fallbackTextColor]"
  >
    <i class="fa-thin fa-user" :class="props.fallbackTextSize"></i>
    <span class="sr-only">Avatar</span>
  </div>
</template>

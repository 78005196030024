<script lang="ts" setup>
// @ts-nocheck
import { onMounted, reactive, ref, watch, watchEffect } from 'vue'
import { storeToRefs } from 'pinia'
import { fullMenu, shortMenu } from '@/utils/profileMenu'
// import { getProfileImage } from '@/queries/content'
// import { useQuery } from '@vue/apollo-composable'
import { fetchProfile } from '@/queries/api'
import { useNearWalletStore } from '@/stores/near-wallet'
import { useUcanStore } from '@/stores/ucan'
import {
  usePricingConfigurationStore,
  currencyToCOTO,
  boot as pricingConfigurationBoot
} from '@/stores/pricing-configurations'
import { createOrUpdateAccount } from '@/utils/storeProfile'

import NovuNotificationsComponent from '@/components/NovuNotificationsComponent.vue'

import getConfig from '@/config/config'

const isTestMode = getConfig().TEST_MODE

// const buttonClass = 'click-login bg-cm-blue-500 text-white hover:text-cm-blue-100 hover:border-cm-blue-100 focus:ring-0 focus:outline-0 focus:border-cm-blue-100'

const nearWalletStore = useNearWalletStore()

const ucanStore = useUcanStore()

const params = reactive({
  accountId: ''
})

const { user } = storeToRefs(nearWalletStore)
// const { result: profilePic } = useQuery(getProfileImage(params), params)
const profileData = ref<ProfileData>()

const profileImage = ref()

const links = ref(shortMenu)

const loggedIn = ref({ ...nearWalletStore.loggedIn })
const balance = ref({ ...nearWalletStore.balance })

const balanceInCurrency = ref(0)
const isProfileFilled = ref(profileData.value?.profileDatas?.length > 0)

const pricingStore = usePricingConfigurationStore()

// watch for balance change
if (!isTestMode) {
  nearWalletStore.$subscribe(async (mutation, state) => {
    // update state
    loggedIn.value = state.loggedIn
    balance.value = state.balance
    user.value = state.user
    balanceInCurrency.value = currencyToCOTO(
      balance.value.USDC,
      pricingStore.selectedCurrency,
      true
    )
  })
}

// watch for avatar changes
const avatar = ref(window.localStorage.getItem('avatar'))
window.avatarUpdated = avatar
clearInterval(window.myWatch)

window.myWatch = setInterval(() => {
  if (window.avatarUpdated) {
    avatar.value = window.localStorage.getItem('avatar')
    window.avatarUpdated = false
  }
}, 1000)

const fakedLogin = ref(false)
const checkLogin = () => {
  if (!isTestMode) {
    return
  }

  // fake login for testing
  nearWalletStore.loggedIn = true
  fakedLogin.value = getConfig().TEST_ACCOUNT_ID
  balance.value = {
    COTO: '1351782',
    NEAR: '179.842997689351161899999998',
    USD: '588.09',
    USDT: '0',
    yoctoNEAR: '179842997689351161899999998'
  }
}

if (isTestMode) {
  clearInterval(window.fakedLogin)
  window.fakedLogin = setInterval(checkLogin, 1000)
  checkLogin()
}

const getUserAvatar = () => {
  if (user.value && user?.value?.profileImage) {
    profileImage.value = user?.value?.profileImage
  } else if (!!profileData.value && !!profileData.value.profilePic) {
    profileImage.value = profileData.value.profilePic
  }
  return profileImage.value
}

watchEffect(() => {
  // catch changes from the PageHeading currency dropdown selector
  balanceInCurrency.value = currencyToCOTO(balance.value.USDC, pricingStore.selectedCurrency, true)
  if (profileData.value?.profileDatas?.length > 0) {
    isProfileFilled.value = true
    links.value = fullMenu
  }

  if (nearWalletStore.loggedIn) {
    params.accountId = nearWalletStore.accountId
  }
  avatar.value = getUserAvatar()
})

watch(
  () => ({
    accountId: nearWalletStore.accountId,
    user: nearWalletStore.user,
    hasPK: ucanStore.data?.secp256k1PrivateKey
  }),
  async (nv) => {
    // ensure we have the data
    if (!nv?.accountId || !nv?.user?.email || !nv?.hasPK) return false

    profileData.value = (await fetchProfile(nv.accountId)) || {}

    try {
      // update...
      await createOrUpdateAccount(nv.accountId, profileData.value || {}, {
        forceUpdateEmailRsa: false,
        forceUpdateDidKey: false,
        forceUpdateUcanToken: false
      })
      // console.debug({ profile});
    } catch (err) {
      console.debug(err)
    }
  }
)

onMounted(async () => {
  await pricingConfigurationBoot()
})
</script>

<template>
  <NovuNotificationsComponent />
</template>

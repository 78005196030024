<script lang="ts" setup>
import { computed, ref, watchEffect } from 'vue'
import { fetchProfile } from '@/queries/api'
import { isVideo } from '@/utils/mediaFileTypes'

import UserLayout from '@/Layouts/UserLayout.vue'
import SpinnerOverlay from '@/components/SpinnerOverlay.vue'
import MediaItem from '@media/MediaItem.vue'

const props = defineProps({
  accountId: {
    type: String,
    default: ''
  },
  uploadId: {
    type: String,
    default: ''
  }
})

const profile = ref<ProfileData>()

const item = computed(() => {
  return {
    ...(profile.value?.contents?.find((item) => item.id == props.uploadId) || {})
  }
})

const loading = ref<boolean>(false)

watchEffect(async () => {
  loading.value = true

  const prfl = await fetchProfile(String(props.accountId))
  profile.value = prfl || profile.value

  loading.value = false
})
</script>

<template>
  <UserLayout selectedLinkId="briefs">
    <SpinnerOverlay v-if="loading" />
    <div class="lg:flex lg:items-center lg:justify-center">
      <div class="lg:flex lg:max-w-5xl">
        <div class="w-full lg:w-1/2 rounded-l-md" v-if="item.file_url">
          <MediaItem
            :item="
              ((): {
                href: string
                image: string
                hls?: string
              } => {
                const itemUrl = item?.file_url || ''

                if (isVideo(itemUrl)) {
                  const hostname = 'vz-45fc0159-3b8.b-cdn.net'
                  const urlSegments = itemUrl.split('/')
                  const urlVideoId = urlSegments?.slice()?.pop()
                  const urlPrefix = `https://${hostname}/${urlVideoId}/`

                  return {
                    href: '#',
                    image: `${urlPrefix}/thumbnail.jpg`,
                    hls: `${urlPrefix}/playlist.m3u8`
                  }
                }
                return {
                  href: '#',
                  image: itemUrl
                }
              })()
            "
            :links="false"
            :videoCss="['w-full']"
            :imageCss="['w-full']"
          />
        </div>
        <div class="w-full lg:w-1/2 flex flex-col justify-center py-6 px-6 bg-white">
          <div>
            <h1 class="text-xl font-bold tracking-wide">{{ item?.name }}</h1>
          </div>
          <div class="flex-grow my-3 text-sm">
            <p>{{ item?.short_description }}</p>
          </div>
          <div class="flex flex-wrap gap-2">
            <div
              v-for="(tag, index) in item?.tags"
              :key="index"
              v-show="!!tag"
              class="bg-gray-300 hover:bg-gray-600 text-gray-600 hover:text-white px-3 py-1.5 rounded-md flex items-center gap-2"
            >
              <span class="text-xs uppercase font-semibold tracking-wide">{{ tag }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </UserLayout>
</template>

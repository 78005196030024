import { saveSmartBrief } from '@/queries/api'
import { useUcanStore } from '@/stores/ucan'
// import { pinDataToIPFS } from '@/utils/ipfs'

/* const saveIPFSData = async (data: any) => {
  const ipfsResult = await pinDataToIPFS(JSON.stringify(data))
  return ipfsResult?.cid ? ipfsResult.cid.toString() : ''
} */

export const submitSmartBrief = async (d: any) => {
  const data: Record<string, unknown> = {}

  console.log('submitSmartBrief : data ', d)

  // new or update
  if (d.brief.id) {
    data.id = d.brief.id
  }

  // set the slug
  if (d?.brief?.slug) {
    data.slug = d.brief.slug
  }

  // brief needs an owner
  if (d.brief.owner) {
    data.owner = d.brief.owner
  } else {
    console.error('submitSmartBrief : No owner found for brief, are you logged in?')
    return
  }

  /* uploaded files are a JSON.stringified({ object }) of the format:
  {
    name: '',
    description: '',
    tags: [],
    file_url:
  }
  */

  // SmartBrief fields
  data.title = d.brief.title
  data.summary = d.brief.summary
  data.brandName = d.brief.brandName
  data.interestDeadline = d.brief.interestDeadline
  data.submissionDeadline = d.brief.submissionDeadline
  data.image = d.brief.image
  data.brandLogo = d.brief.brandLogo
  data.ndaTitle = d.brief.ndaTitle
  data.ndaText = d.brief.ndaText
  data.ndaImage = d.brief.ndaImage
  data.ndaBrandName = d.brief.ndaBrandName
  data.ndaSummary = d.brief.ndaSummary
  data.ndaCompanyLogo = d.brief.ndaCompanyLogo
  data.status = d.brief.status
  data.visibility = d.brief.visibility // NDA required or not
  // optimize page
  data.metaData = d.brief.metaData
  data.aboutTheBrand = d.brief.aboutTheBrand
  data.contentTypes = d.brief.contentTypes
  data.contentRights = d.brief.contentRights
  data.modelRelease = d.brief.modelRelease
  data.deliverables = d.brief.deliverables
  data.preProduction = d.brief.preProduction
  data.rewards = d.brief.rewards
  data.techRequirements = d.brief.techRequirements
  data.considerations = d.brief.considerations
  data.idealCandidate = d.brief.idealCandidate
  data.termsAndConditions = d.brief.termsAndConditions
  data.customSection = d.brief.customSection

  // MarketingBrief fields

  // ProfileData fields
  data.firstName = d.profile.firstName
  data.lastName = d.profile.lastName
  data.city = d.profile.city
  data.country = d.profile.country
  data.companyName = d.profile.companyName
  data.profilePic = d.profile.profilePic
  // data['headline = d.profile.headline // not managed by the BriefsWizard
  // data['aboutYou = d.profile.aboutYou // not managed by the BriefsWizard

  // calling api to save smart brief to db
  console.log('submitNewSmartBrief', data)

  // team nickname
  data.teamNickname = d?.teamNickname

  // save data to IPFS
  // data.cid = await saveIPFSData(data) // We do not need to save data in IPFS on frontend, it is now done in backend before writing to blockchain

  const ucanStore = useUcanStore()
  const res = await saveSmartBrief(ucanStore, data)
  return res
}

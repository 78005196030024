<script lang="ts" setup>
import { RouterLink, type RouterLinkProps } from 'vue-router'

const props = defineProps<RouterLinkProps>()
</script>

<template>
  <RouterLink v-bind="props">
    <slot></slot>
  </RouterLink>
</template>

<script lang="ts" setup>
import { AxiosError } from 'axios'
import { computed, ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { deleteTeamMember } from '@/queries/api'
import AlertDialog from '@/components/AlertDialog.vue'
import { useNearWalletStore } from '@/stores/near-wallet'
import { useTeamStore } from '@/stores/team'
import { storeToRefs } from 'pinia'
import { Vue3Lottie } from 'vue3-lottie'
import LottieLoadingBlocks from '@/lottie/loading-blocks-2.json'
import ProfileAvatar from '@/components/ProfileAvatar.vue'

const props = withDefaults(
  defineProps<{
    type: 'uuid' | 'nickname'
    typeValue: string
  }>(),
  {
    type: 'uuid',
    typeValue: ''
  }
)

const $router = useRouter()

const nearWalletStore = useNearWalletStore()

const teamStore = useTeamStore()
const { members } = storeToRefs(teamStore)
const { can: teamCan, cannot: teamCannot } = teamStore

const loadingMembers = ref<boolean>(false)

const loadMembers = async () => {
  loadingMembers.value = true
  if (teamCan('team.members.list')) {
    await teamStore.fetchTeamMembers(props.type, props.typeValue)
  }
  loadingMembers.value = false
}

watchEffect(async () => {
  loadMembers()
})

const totalAdminMembers = computed(() => {
  return members.value?.filter((member) => member.role.name === 'admin').length || 0
})

const removeMemberConfirmDialog = ref<InstanceType<typeof AlertDialog> | null>(null)
const removeMember = async (teamUuid: string, profileOwner: string) => {
  try {
    if (teamCannot('team.members.delete')) {
      throw Error('You do not have Permission to remove a Member')
    }

    // show a confirmation dialog
    const ok = await removeMemberConfirmDialog.value?.show({
      title: 'Remove Member',
      message: `Are you sure you want to remove ${nearWalletStore.accountId === profileOwner ? 'yourself' : 'this member'}?`,
      okButton: 'Yes',
      cancelButton: 'No'
    })

    if (ok) {
      // remove the member from the team
      await deleteTeamMember('uuid', teamUuid, profileOwner)

      // if we are removing ourselves from the team then redirect to teams page
      if (nearWalletStore.accountId === profileOwner) {
        $router.push({
          name: 'teams.index'
        })
      } else {
        loadMembers()
      }
    }
  } catch (err) {
    if (err !== false) {
      await removeMemberConfirmDialog.value?.show({
        title: 'Error',
        message:
          err instanceof AxiosError
            ? err.response?.data.message
            : 'Something went wrong while removing the member.',
        okButton: 'Ok'
      })
    }
  }
}
</script>

<template>
  <div>
    <div class="flex items-center justify-between">
      <h2 class="text-xl tracking-wide font-medium">Members</h2>
    </div>
    <div>
      <div v-if="loadingMembers">
        <Vue3Lottie :animationData="LottieLoadingBlocks" :height="50" :width="50" />
      </div>
      <div
        class="table-cmg"
        v-else-if="teamCan('team.members.list') && members && members?.length > 0"
      >
        <table>
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Role</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="member in members" :key="member.uuid">
              <th scope="row" class="w-3/5">
                <router-link
                  :to="
                    member.profile.owner
                      ? {
                          name: 'user.profile',
                          params: {
                            username: member.profile.owner
                          }
                        }
                      : '#'
                  "
                >
                  <div class="flex">
                    <ProfileAvatar
                      :profile-id="member?.profile?.owner"
                      :data="member?.profile?.profilePic"
                      class="h-9 w-9 mt-0.5"
                      imageBgColor="bg-white"
                      imageBorderColor="border-white"
                      imageBorderColorHover="group-hover:border-[#FFFFFF]"
                      fallbackBorderColor="border-gray-300"
                      fallbackTextColor="text-gray-500"
                      fallbackTextSize="text-md"
                    />
                    <div class="ml-3 flex flex-col items-center">
                      <span class="w-full block">
                        {{ member.profile.firstName }} {{ member.profile.lastName }}
                      </span>
                      <small class="text-gray-400 hidden sm:block">
                        {{ member.profile.email }}
                      </small>
                    </div>
                  </div>
                </router-link>
              </th>
              <td>
                {{ member.role.displayName || member.role.name || '' }}
              </td>
              <td>
                <span
                  v-if="
                    (member.role.name === 'admin' && totalAdminMembers === 1) ||
                    teamCannot('team.members.delete')
                  "
                >
                  &mdash;
                </span>
                <span v-else>
                  <button
                    type="button"
                    class="font-medium text-cw-primary hover:underline"
                    @click.prevent="removeMember(member.teamUuid, member.profileOwner)"
                    title="Remove"
                  >
                    <i class="fa-light fa-trash"></i>
                    <span class="sr-only">Remove</span>
                  </button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="teamCannot('team.members.list')">
        <p class="text-sm text-gray-500">You don't have permission to view members.</p>
      </div>
      <div v-else>
        <p class="text-sm text-gray-500">No members found.</p>
      </div>
    </div>

    <!-- Remove Invite Modal -->
    <AlertDialog ref="removeMemberConfirmDialog"></AlertDialog>
  </div>
</template>

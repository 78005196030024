<script lang="ts" setup>
import { computed, ref, watchEffect } from 'vue'
import { useNearWalletStore } from '@/stores/near-wallet'
import { useTeamStore } from '@/stores/team'
import { storeToRefs } from 'pinia'
import BriefProfile from '@/components/Pages/Briefs/BriefProfile.vue'
import BriefDetailsCandidatesContent from './BriefDetailsCandidatesContent.vue'
import BriefDetailsQA from '@/components/Pages/Briefs/BriefDetailsQA.vue'
import CurrencyValue from '@/components/CurrencyValue.vue'
import { Vue3Lottie } from 'vue3-lottie'
import busy from '@/lottie/busy.json'
import { parseISO } from 'date-fns'
import { keyBy, orderBy } from 'lodash'

const nearWalletStore = useNearWalletStore()
const teamStore = useTeamStore()
const { accountId } = storeToRefs(nearWalletStore)

const props = withDefaults(
  defineProps<{
    brief: SmartBrief
    isPreviewMode: boolean
    onCandidateProfileStatusEvent: Function
    isBriefLoading?: boolean
    isClosedBrief?: boolean
  }>(),
  {
    isPreviewMode: false,
    onCandidateProfileStatusEvent: () => {},
    isBriefLoading: false,
    isClosedBrief: false
  }
)

console.log(`isBriefLoading: ${props.isBriefLoading}`)

const ACCEPT_VALUE = 'approved'
const REJECT_VALUE = 'rejected'

const profileDetails = ref<Record<string, ProfileData>>({})
const profileLoaded = (profile: ProfileData) => {
  profileDetails.value[profile.id] = profile
}

type Profile = {
  id: string | undefined
  accountId: string
  order: number
  userId: string | undefined
}

const profiles = ref<Profile[]>([])

const profilesProcess = () => {
  type CandidateProfileIdType = SmartBriefProfile & {
    completenessPercentage: number
    createdAt?: string
  }

  const candidateById = keyBy(props.brief.candidateProfiles as CandidateProfileIdType[], 'userId')

  // reset counts
  filtersStatus.value = Object.values(filtersStatus.value).reduce(
    (acc, filterStatus) => ({
      ...acc,
      [filterStatus.value]: {
        label: filterStatus.label,
        value: filterStatus.value,
        count: 0
      }
    }),
    {} as Record<string, FilterStatus>
  )

  type UpdateProfileType = Profile & {
    name: string
    completenessPercentage: number
    createdAt: Date
  }

  let updatedProfiles: UpdateProfileType[] = []

  // sort by status
  props.brief.ndaAccepted.forEach((accountId) => {
    let profile = candidateById[accountId]
    let order = 1
    if (profile?.status == ACCEPT_VALUE) {
      order = 2
    }
    if (profile?.status == REJECT_VALUE) {
      order = 3
    }
    if (profile?.id) {
      if (!updatedProfiles.some((p) => p.id === profile.id)) {
        const profileDetailsValue = profileDetails.value[accountId]

        const fullName = updatedProfiles.push({
          id: profile?.id,
          accountId: accountId,
          order: order,
          userId: profile?.userId,
          name: [profileDetailsValue?.firstName || '', profileDetailsValue?.lastName || '']
            .join(' ')
            .trim(),
          completenessPercentage: profile.completenessPercentage || 0,
          createdAt: profile?.createdAt ? parseISO(profile.createdAt) : new Date()
        })

        // update the count
        if (typeof filtersStatus.value?.[profile.status] !== 'undefined') {
          filtersStatus.value[profile.status].count++
        } else if (profile.status === 'default') {
          filtersStatus.value.pending.count++
        }

        filtersStatus.value.all.count++
      }
    }
  })

  // filter by the status
  updatedProfiles = updatedProfiles.filter((profile) => {
    const profileStatus = candidateById?.[profile.accountId]?.status
    if (filterStatusSelected.value === 'approved') {
      return profileStatus === ACCEPT_VALUE
    } else if (filterStatusSelected.value === 'rejected') {
      return profileStatus === REJECT_VALUE
    } else if (filterStatusSelected.value === 'pending') {
      return profileStatus !== ACCEPT_VALUE && profileStatus !== REJECT_VALUE
    }
    return true
  })

  // sort
  if (orderOptionSelected.value === 'latest') {
    updatedProfiles = orderBy(updatedProfiles, 'createdAt', 'desc')
  } else if (orderOptionSelected.value === 'oldest') {
    updatedProfiles = orderBy(updatedProfiles, 'createdAt', 'asc')
  } else if (orderOptionSelected.value === 'name') {
    updatedProfiles = orderBy(updatedProfiles, 'name', 'asc')
  } else {
    updatedProfiles = orderBy(updatedProfiles, ['order', 'completenessPercentage'], ['asc', 'desc'])
  }

  // console.table(updatedProfiles)

  // update the profiles
  profiles.value = updatedProfiles
}

// SORTING
type OrderOptions = {
  label: string
  value: string
}

const orderOptions = ref<OrderOptions[]>([
  {
    label: 'Default',
    value: 'default'
  },
  {
    label: 'Latest',
    value: 'latest'
  },
  {
    label: 'Oldest',
    value: 'oldest'
  },
  {
    label: 'Name',
    value: 'name'
  }
])

const orderOptionSelected = ref<string>('default')

const orderOptionChanged = () => {
  profilesProcess()
}

// STATUS FILTERS
type FilterStatus = {
  label: string
  value: string
  count: number
}

const filtersStatus = ref<Record<string, FilterStatus>>({
  all: {
    label: 'All',
    value: 'all',
    count: 0
  },
  approved: {
    label: 'Approved',
    value: 'approved',
    count: 0
  },
  rejected: {
    label: 'Rejected',
    value: 'rejected',
    count: 0
  },
  pending: {
    label: 'Pending',
    value: 'pending',
    count: 0
  }
})

const filterStatusShow = computed(
  () =>
    Object.values(filtersStatus.value)
      .filter((filterStatus) => filterStatus.value === 'all')
      .reduce((acc, filterStatus) => acc + filterStatus.count, 0) > 0
)

const filterStatusSelected = ref<string>('all')

const filterStatusChange = () => {
  profilesProcess()
}

watchEffect(() => {
  if (props.brief) {
    profilesProcess()
  }
})
</script>

<template>
  <div class="bg-white rounded-md px-8 py-6 md:px-6 border-2 border-[#5386EA]">
    <div class="w-full max-w-7xl flex flex-col mx-auto">
      <h3 class="text-lg font-bold tracking-wide my-3" style="text-align: left">
        Candidates for this brief
      </h3>
      <div class="my-3">
        Review the candidates below.
        <strong
          >Accept their proposal if you want to engage someone to work on your project and pay them
          <CurrencyValue
            :value="Number(brief.budget) * 0.9"
            :currency="'USD'"
            :append_ISO_3166_1_Alpha_3="true"
          />. Your money will held until you accept the final submission.</strong
        >
      </div>
      <div class="my-3">
        If you reject any of the candidates, you can use the Feedback function to write them a
        personal message giving some context to why they were not accepted.
      </div>
    </div>
  </div>

  <Vue3Lottie v-if="props.isBriefLoading" :animationData="busy" :height="50" />

  <div class="w-full flex justify-end mt-6" v-if="profiles?.length > 0">
    <select v-model="orderOptionSelected" @change="orderOptionChanged" class="form-select">
      <option
        v-for="orderOption in orderOptions"
        :key="orderOption.value"
        :value="orderOption.value"
      >
        Order by {{ orderOption.label }}
      </option>
    </select>
    <span class="mx-1.5">&nbsp;</span>
    <select
      v-model="filterStatusSelected"
      @change="filterStatusChange"
      class="form-select"
      v-if="filterStatusShow"
    >
      <option
        v-for="filterStatus in filtersStatus"
        :key="filterStatus.value"
        :value="filterStatus.value"
        :class="{
          hidden: filterStatus.count <= 0
        }"
      >
        {{ filterStatus.label }} ({{ filterStatus.count }})
      </option>
    </select>
  </div>

  <div v-if="profiles?.length <= 0">
    <div class="w-full my-3">
      <p class="text-sm">No candidates found.</p>
    </div>
  </div>
  <div
    v-else
    v-for="profile in profiles"
    :key="profile.accountId"
    class="bg-white w-full rounded-md md:px-8 pt-6 pb-8 mt-9 mb-6 border-[#EAECF0]"
  >
    <div class="flex flex-row flex-wrap">
      <div class="w-full md:w-1/4">
        <!-- Display the BriefProfile for the item owner -->
        <BriefProfile
          :isPreviewMode="isPreviewMode"
          :accountId="profile.accountId"
          :brief="brief"
          :isLoading="false"
          :on-candidate-profile-status-event="onCandidateProfileStatusEvent"
          :isClosedBrief="isClosedBrief"
          @profileLoaded="profileLoaded"
        />
      </div>
      <div class="w-full md:w-3/4">
        <BriefDetailsCandidatesContent
          :isPreviewMode="isPreviewMode"
          :accountId="profile.accountId"
          :profile="profileDetails[profile.accountId]"
          :brief="brief"
        />

        <div
          class="w-full pt-6"
          v-if="
            brief?.owner === accountId ||
            teamStore.can('team.briefs.creators.message') ||
            profile.accountId === accountId
          "
        >
          <BriefDetailsQA
            :brief="props.brief"
            :brief_id="props.brief.id"
            :reference_id="profile?.id"
            :profile_user_id="profile?.userId"
            :dm_candidate_id="profile?.userId"
            :reference_type="'profile'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

import Profile from '@/components/Pages/Profile/Show.vue'
import MyProfile from '@/components/Pages/Profile/MyProfile.vue'
import UpdateProfile from '@/components/Pages/Profiles/Edit.vue'
import Login from '@/components/Pages/Login.vue'

export default [
  {
    path: '/user',
    children: [
      {
        path: 'profile',
        children: [
          {
            path: ':username/:tab?',
            component: Profile,
            name: 'user.profile',
            props: true
          }
        ]
      },
      {
        path: 'myprofile',
        component: MyProfile,
        name: 'user.myprofile',
        props: true
      },
      {
        path: 'updateprofile',
        component: UpdateProfile,
        name: 'user.updateprofile',
        props: true
      }
    ]
  },
  {
    path: '/login',
    component: Login,
    name: 'user.login',
    props: true
  }
]

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { ref, reactive, watchEffect } from 'vue'
import { calcLicencingPrice } from '@/utils/licencingPriceCalc'
import {
  filterMediaType,
  filterMediaResolutions,
  filterMediaPreference,
  filterOrientation
} from '@/dropdowns/filters'
import { getAllStockContent } from '@/queries/content'
import { storeToRefs } from 'pinia'
import { useQuery } from '@vue/apollo-composable'
import { sub } from 'date-fns'
import { CONTENT_EXPIRY_DAYS } from '@/constants'

import MarketplaceLayout from '@/Layouts/MarketplaceLayout.vue'
import PageTitleHeading from '@/components/PageTitleHeading.vue'
import SearchFilters from '@/components/SearchFilters.vue'
import Alert from '@/components/Alert.vue'
import Loading from '@/components/Loading.vue'
import FixedHeightMedia from '@/components/Media/FixedHeightMedia.vue'

// import { saveResult } from '@/api/requests'

import { useNearWalletStore } from '@/stores/near-wallet'

const nearWalletStore = useNearWalletStore()

const { accountId } = storeToRefs(nearWalletStore)

const props = defineProps({
  pagingOffset: String,
  pagingPerPage: String,
  media_preference: String,
  search: String
})

const { t: $t } = useI18n({})

const filters = ref()
const contents = ref<
  {
    highest_bid: number
  }[]
>([])
const params = reactive({
  accountId: '',
  search: props.search ?? '',
  pagingPerPage: parseInt(props.pagingPerPage ?? '100'),
  pagingOffset: parseInt(props.pagingOffset ?? '0'),
  media_type: '',
  media_resolution: '',
  media_preference:
    props.media_preference === '' || props.media_preference === 'all' ? '' : props.media_preference,
  media_orientation: '',
  media_order_by:
    props.search === ''
      ? props.media_preference === 'all'
        ? 'created_on'
        : 'highest_bid'
      : 'type',
  bids_count: props.media_preference === 'all' ? -1 : 0,
  content_expiry_date: `${sub(Date.now(), { days: CONTENT_EXPIRY_DAYS }).getTime()}`,
  order_direction: props.search === '' ? 'desc' : 'asc'
})

const { result, loading, error, refetch } = useQuery(getAllStockContent(), params)

watchEffect(() => {
  params.accountId = accountId.value
  if (result.value) {
    contents.value = []
    if (typeof result.value.contents === 'undefined') {
      result.value.contents = result.value.search
    }
    // Calculate licencing price for each content
    for (const content of result.value.contents) {
      contents.value.push({ ...content, licencingPrice: calcLicencingPrice(content) })
    }
    // Subgraph gql doesnt support OR in where clause yet
    // so using another query for licensed content but within bidding end days
    // for(const content of result.value.premium) {
    //     if (!contents.value.find((e) => e.id === content.id)) {
    //         contents.value.push({...content, licencingPrice: calcLicencingPrice(content)});
    //     }
    // }
    contents.value.sort((a, b) => (a.highest_bid > b.highest_bid ? 1 : -1))
    // save result
    // saveResult(params, contents.value)
  }
})

// quality_options depends if its image / video set
const updateFilters = (media_type: 'image' | 'video' | 'all', media_preference: string) => {
  filters.value = [
    filterMediaType,
    filterMediaResolutions(media_type),
    filterMediaPreference(media_preference),
    filterOrientation
  ]
}

let debounceTimeout: NodeJS.Timeout
const update = async (val: {
  pagingOffset: number
  pagingPerPage: number
  media_preference: string
  media_type: 'all' | 'image' | 'video'
  search: string
  media_resolution: string
  media_orientation: string
}) => {
  console.log({ val })
  // paging
  if (typeof val.pagingOffset !== 'undefined') {
    params.pagingOffset = val.pagingOffset
    refetch()
    return
  }
  if (typeof val.pagingPerPage !== 'undefined') {
    params.pagingOffset = 0
    params.pagingPerPage = val.pagingPerPage
    refetch()
    return
  }

  // swap out quailty options
  updateFilters(val.media_type, val.media_preference === 'all' ? '' : val.media_preference)

  // update view when search changes
  clearTimeout(debounceTimeout)
  debounceTimeout = setTimeout(() => {
    contents.value = []
    params.pagingOffset = 0
    params.search = val.search.trim()

    params.media_type = val.media_type
    params.media_resolution = val.media_resolution

    params.media_orientation = val.media_orientation

    if (params.search !== '') {
      params.media_preference = ''
      params.media_order_by = 'type'
      params.bids_count = -1
      params.content_expiry_date = `${Date.now()}`
      params.order_direction = 'asc'
    } else {
      params.order_direction = 'desc'
      if (val.media_preference === 'premium' || val.media_preference === 'premiumAdult') {
        params.media_order_by = 'highest_bid'
        params.bids_count = 0
        params.content_expiry_date = `${sub(Date.now(), { days: CONTENT_EXPIRY_DAYS }).getTime()}`
      } else if (val.media_preference === 'standard' || val.media_preference === 'standardAdult') {
        params.media_order_by = 'created_on'
        params.bids_count = -1
        params.content_expiry_date = `${Date.now()}`
      } else {
        params.media_order_by = 'created_on'
        params.bids_count = -1
        params.content_expiry_date = `${Date.now()}`
      }
      params.media_preference = val.media_preference === 'all' ? '' : val.media_preference
    }
    console.log({ params })
  }, 500)
}

updateFilters('all', props?.media_preference === 'all' ? '' : props.media_preference!)
</script>

<template>
  <MarketplaceLayout :title="$t('marketplace.licensing.title')" selectedLinkId="licensing">
    <template #header>
      <div class="flex flex-wrap items-start justify-between">
        <PageTitleHeading>
          {{ $t('marketplace.licensing.title') }}
        </PageTitleHeading>
        <SearchFilters @update="update" :filters="filters" :search="props.search" />
      </div>
    </template>
    <Loading v-if="loading" />
    <Alert v-else-if="error" type="error" :text="error.message" />
    <div v-else-if="result" class="flex flex-wrap mt-3 relative">
      <FixedHeightMedia
        mode="license"
        :items="contents"
        :search="params.search"
        :pagingOffset="params.pagingOffset"
        :pagingPerPage="params.pagingPerPage"
        :media_preference="params.media_preference === '' ? 'all' : params.media_preference"
        @update="update"
        @delete="refetch"
      />
    </div>
  </MarketplaceLayout>
</template>

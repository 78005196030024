<script lang="ts" setup>
import { ref } from 'vue'
import PopupModal from '@media/PopupModal.vue'

export interface Props {
  title: string
  message: string
  okButton: string
  cancelButton?: string
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  message: '',
  okButton: '',
  cancelButton: 'Cancel'
})

const popup = ref<InstanceType<typeof PopupModal> | undefined>(undefined)

var title = ref(props.title)
var message = ref(props.message)
var okButton = ref(props.okButton)
var cancelButton = ref(props.cancelButton)

var resolvePromise = ref<Function>()
var rejectPromise = ref<Function>()

var show = function (opts = {} as Props) {
  title.value = opts.title
  message.value = opts.message
  okButton.value = opts.okButton
  if (opts.cancelButton) {
    cancelButton.value = opts.cancelButton
  }

  popup.value?.open()

  return new Promise((resolve, reject) => {
    resolvePromise.value = resolve
    rejectPromise.value = reject
  })
}

var _confirm = function () {
  popup.value?.close()
  if (typeof resolvePromise.value === 'function') {
    resolvePromise.value(true)
  }
}

var _cancel = function () {
  popup.value?.close()
  if (typeof resolvePromise.value === 'function') {
    resolvePromise.value(false)
  }
}

defineExpose({
  show
})
</script>

<template>
  <PopupModal ref="popup">
    <h1 class="text-2xl">{{ title }}</h1>
    <div class="pt-5 pb-5 min-w-[400px]">
      <p>{{ message }}</p>
    </div>
    <div class="flex flex-row justify-end">
      <button class="pl-3 pr-3 mr-3 leading-10 font-bold text-base cursor-pointer" @click="_cancel">
        {{ cancelButton }}
      </button>
      <span
        class="cypress-ok pl-3 pr-3 bg-cm-blue-light-400 hover:bg-cm-blue-light-500 text-white leading-10 cursor-pointer rounded text-base"
        @click="_confirm"
        >{{ okButton }}</span
      >
    </div>
  </PopupModal>
</template>

import BriefsWizard from '@/components/Pages/Marketplace/BriefsWizard.vue'

import Licensing from '@/components/Pages/Marketplace/Licensing.vue'
import LicensingDetail from '@/components/Pages/Marketplace/LicensingDetail.vue'
import MarketplaceBriefs from '@/components/Pages/Marketplace/Briefs.vue'

import SmartBriefs from '@/components/Pages/Marketplace/SmartBriefs.vue'

import Licensed from '@/components/Pages/Marketplace/Licensed.vue'

// import Rewards from '@/components/Pages/Marketplace/Rewards.vue'
// import RewardsDetail from '@/components/Pages/Marketplace/RewardsDetail.vue'

export default [
  {
    path: '/marketplace',
    children: [
      {
        path: 'create-brief/:stage?/:brief_id?',
        component: BriefsWizard,
        name: 'marketplace.create-brief',
        props: true
      },
      {
        path: 'edit-brief/:stage?/:brief_id?',
        component: BriefsWizard,
        name: 'marketplace.edit-brief',
        props: true
      },
      {
        path: 'licensing',
        component: Licensing,
        name: 'marketplace.licensing',
        props: true,
        children: [
          {
            path: 'uuid',
            component: LicensingDetail,
            name: 'marketplace.licensing.detail',
            props: true
          }
        ]
      },
      {
        path: 'briefs',
        component: MarketplaceBriefs,
        name: 'marketplace.briefs',
        props: true
      },
      {
        path: 'smartbriefs',
        component: SmartBriefs,
        name: 'marketplace.smartbriefs',
        props: true
      },
      {
        path: 'licensed',
        component: Licensed,
        name: 'marketplace.licensed',
        props: true
      }
      /*
      {
        path: 'rewards',
        children: [
          {
            path: '',
            component: Rewards,
            name: 'marketplace.rewards',
            props: true
          },
          {
            path: '{uuid}',
            component: RewardsDetail,
            name: 'marketplace.rewards.detail',
            props: true
          }
        ]
      }
      */
    ]
  }
]

import { create, type CID } from 'ipfs-http-client'
import { pinataPinFileToIPFS } from '@/utils/pinata'

export async function pinDataToIPFS(
  data: BlobPart,
  fileName?: string,
  fileExtension?: string,
  fileType: string = 'application/json'
): Promise<
  | {
      cid: CID
      pinataCid: string
    }
  | undefined
> {
  // Pin/upload to TheGraph
  const ipfsHttp = await create({
    url: 'https://api.thegraph.com/ipfs/api/v0'
  })
  if (!ipfsHttp) {
    window?.toast('IPFS not available, please try later', { timeout: 4000 })
    return
  }
  const { cid } = await ipfsHttp.add(data)

  // Pin/upload to Pinata
  let file = ''
  if (fileName && fileExtension) {
    file = `${encodeURI(fileName)}.${fileExtension}`
  } else {
    file = `${cid}.json`
  }
  const flType = fileType
  const pinataCid = await pinataPinFileToIPFS(new File([data], file, { type: flType }), file)

  return {
    cid,
    pinataCid
  }
}

export async function pinMediaToIPFS(
  data: BlobPart,
  fileName?: string,
  fileExtension?: string,
  fileType: string = 'image/jpeg'
) {
  // Pin/upload to Pinata
  const file = `${encodeURI(fileName || 'image')}.${fileExtension || 'jpg'}`
  const pinataCid = await pinataPinFileToIPFS(new File([data], file, { type: fileType }), file)

  return {
    pinataCid
  }
}

<script lang="ts" setup>
import { onMounted, onUnmounted, ref, computed } from 'vue'
import { mobileDetect } from '@/utils/browser'
import { getAltTag, pdfThumbnail } from '@/utils/media'
import { getFileUrl } from '@/utils/mediaFileTypes'
import { useImageCDN } from '@/utils/image'

import { v4 as uuidv4 } from 'uuid'
const canvas_id = uuidv4()

const emit = defineEmits(['imageLoaded', 'imageError', 'onDelete'])

export interface Props {
  decryptedUrl?: string
  details: any
  imageCss?: any
  hideIfDead?: boolean
  showMeta?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  decryptedUrl: '',
  details: {},
  imageCss: [],
  hideIfDead: false,
  showMeta: false
})

const isMobile = ref(mobileDetect())
const updatePageWidth = () => {
  isMobile.value = mobileDetect()
}

const adjustedImageCss = ref(props.imageCss)
if (!adjustedImageCss.value.some((css: string) => css.startsWith('h-'))) {
  adjustedImageCss.value = [...adjustedImageCss.value, 'h-64']
}

const img = ref<HTMLImageElement>()
const fileName = ref(getFileUrl(props.details.item))
const okToShow = ref(true)

const imgSrc = ref(props.decryptedUrl || fileName.value)

// you can't add options to blobs
if (imgSrc.value && !props.decryptedUrl) {
  imgSrc.value = useImageCDN((imgSrc.value || '').slice(), 'medium', true)
}

const imageIsLoaded = ref(false)
const imageLoaded = () => {
  emit('imageLoaded')
  setTimeout(() => {
    imageIsLoaded.value = true
  }, 10)
}

const imageError = () => {
  // we don't care, just hide this error
  if (props.hideIfDead) {
    okToShow.value = false
    return
  }
  emit('imageError')
}

const popup = ($event: any) => {
  // set global variables for PopupImage.vue
  window.popupTitle = props.details.item.name
  window.popupTags = props.details.item.tags
  window.popupDescription = props.details.item.short_description || props.details.item.description
  // get list of items in the carousel
  const el = $event.target
  let parent = el.parentElement
  while (parent && !parent.hasAttribute('data-carousel-id')) {
    parent = parent.parentElement
  }
  if (parent) {
    window.popupCarousel = parent.getAttribute('data-carousel-id')
  }
  // trigger PopupImage.vue
  window.popupImage = props.decryptedUrl || fileName.value
}

const tagsArray = computed(() => {
  const tags = props.details.item?.tags || ''
  if (!tags || !tags.length) {
    return []
  }
  console.log('tags', tags)
  return tags
    .split(',')
    .map((tag: any) => tag.trim())
    .filter((tag: any) => tag !== '')
})

onMounted(() => {
  updatePageWidth()
  window.addEventListener('resize', updatePageWidth)
  if (props.details.isPDF) {
    pdfThumbnail(canvas_id, imgSrc.value)
  }
})
onUnmounted(() => {
  window.removeEventListener('resize', updatePageWidth)
})

const debug = ref(false)
</script>

<template>
  <pre
    v-if="debug"
    class="text-xs overflow-auto w-[360px] h-[200px] bg-yellow-200 text-black p-2 pb-0 mb-3 rounded-md"
  >
    <b>MEDIAITEM:</b><br>
    {{ details }}
  </pre>

  <div v-if="okToShow" class="media-image relative">
    <!-- pdf -->
    <div v-if="details.isPDF">
      <div class="border-2 rounded-md w-44 p-2" :class="adjustedImageCss">
        <canvas :id="canvas_id" class="bg-gray-200 w-full h-full"></canvas>
      </div>
    </div>
    <!-- file details if not an image or video -->
    <div v-else-if="details.isFile && $route.name != 'profiles.self.uploads'">
      <div class="flex text-xs">
        <!-- file icon -->
        <div class="flex w-10">
          <i class="fa-3x text-[#FF008B]" :class="details.metadata.fileicon"></i>
        </div>
        <!-- name, description, size -->
        <div class="ml-3">
          <p v-if="details.item.name">
            <b>{{ details.item.name }}</b>
          </p>

          <div v-if="details.item.short_description">
            {{ details.item.short_description }}
          </div>
          <p class="mt-1" v-if="details.metadata.filename">
            <b>Filename:</b><br />{{ details.metadata.filename }}
            <span v-if="details.metadata.filesize"> - {{ details.metadata.filesizehuman }}</span>
          </p>
        </div>
        <!-- tags -->
        <div v-if="tagsArray.length" class="ml-6">
          <b>Tags:</b><br />
          <div class="flex">
            <div
              v-for="(tag, index) in tagsArray"
              :key="index"
              class="mt-1 mr-3 bg-gray-200 px-2 py-1 rounded-md shadow-md"
            >
              {{ tag }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- image -->
    <div v-else-if="details.isImage">
      <img
        ref="img"
        class="max-w-none cursor-pointer transition-opacity duration-1000"
        :class="[
          ...adjustedImageCss,
          {
            'opacity-0': !imageIsLoaded,
            'opacity-100': imageIsLoaded
          }
        ]"
        :src="imgSrc"
        :alt="getAltTag(fileName)"
        @load="imageLoaded"
        @error="imageError"
        @click.prevent="popup"
      />
    </div>
    <!-- unknown, or metaupload component is already on the page -->
    <div v-else-if="$route.name != 'profiles.self.uploads'">
      <pre class="text-xs">Unsupported media type {{ details.item }}</pre>
      <pre class="text-xs">FILE {{ details.isFile }}</pre>
      <pre class="text-xs">IMAGE {{ details.isImage }}</pre>
      <pre class="text-xs">VIDEO {{ details.isVideo }}</pre>
      <pre class="text-xs">BUNNY {{ details.isBunny }}</pre>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import SpinnerOverlay from '@/components/SpinnerOverlay.vue'
import ErrorBox from '@/components/ErrorBox.vue'
import { useNearWalletStore } from '@/stores/near-wallet'

const nearWalletStore = useNearWalletStore()

const { accountId, inited } = storeToRefs(nearWalletStore)

const loading = ref(true)

const boot = async () => {
  if (!inited.value) return false

  try {
    if (!accountId.value) {
      await nearWalletStore.web3authLogin()
    }
  } catch (e) {
    console.error('Error logging in with web3auth', e)
  }

  loading.value = false
}

watch(inited, async (nv) => {
  if (nv === true) {
    boot()
  }
})

onMounted(() => {
  boot()
})
</script>

<template>
  <SpinnerOverlay v-if="loading" />
  <ErrorBox v-else-if="!accountId">
    <strong class="tracking-wide font-medium">Please login to continue.</strong>
  </ErrorBox>
  <div v-else><slot></slot></div>
</template>

import { useMemoize } from '@vueuse/core'
import { useUcanStore } from '@/stores/ucan'
import { decryptSmartBriefImage } from '@/utils/postContent'
import {
  cdnUrl,
  embedUrl,
  briefLibraryId,
  videoLibraryId,
  profileLibraryId,
  miappiLibraryId,
  videoCdnUrl,
  miappiCdnUrl
} from '@/utils/mediaConstants'
import { useImageCDN } from '@/utils/image'

const loggedDomains = new Set<string>()

const logOnce = (message: string, url: string) => {
  try {
    const domain = new URL(url).hostname
    if (!loggedDomains.has(domain)) {
      console.log(message, url)
      loggedDomains.add(domain)
    }
  } catch (error) {
    console.error('Invalid URL:', url)
  }
}

export const decryptMedia = async (props: any, accountId: string) => {
  try {
    // use content key for content owner and brief owner key for brief owner
    let key;
    if (accountId === props.item.owner) {
      key = props.item.key;
    } else if (accountId !== props.item.owner && props.isAccepted) {
      key = props.item.keyBriefOwner;
    } else {
      key = '';
    }

    // Decrypt the image URL using decryptSmartBriefImage
    const ucanStore = useUcanStore()
    const { s64, msg } = ucanStore.createApiChallenge()
    const item = props.item
    const decryptedBlob = await decryptSmartBriefImage(
      accountId === props.brief?.owner || props.isAccepted ? '' : item.id,
      item.briefId,
      accountId === props.item.owner || props.isAccepted ? item.file_url : item.watermark_file_url,
      key,
      item.iv,
      item.media_type,
      accountId,
      ucanStore.getApiBaseUrl(),
      s64,
      msg,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (message: string) => {
        console.log('decryptSmartBriefImage ', message)
      }
    )

    // Create a Blob URL from the decrypted Blob
    if (decryptedBlob) {
      return URL.createObjectURL(decryptedBlob)
    } else {
      console.error('Error decrypting image: decryptedBlob is null')
    }
  } catch (err) {
    console.error('Error decrypting image:', err)
  }
}

const splitUrl = (url: string) => {
  const bits = (url || '').slice().split('/')
  if (bits) {
    return {
      videoId: bits.pop(),
      libraryId: bits.pop()
    }
  } else {
    console.error('splitUrl: bad format', url)
  }
}

export const getIDfromUrl = (url: string) => {
  // playlists stored on miappi bunny account
  if (url.includes(miappiCdnUrl)) {
    const bits = (url || '').slice().split('/')
    return {
      libraryId: miappiLibraryId,
      videoId: bits[3]
    }
  }
  if (url?.startsWith('https://iframe.mediadelivery.net/')) {
    return splitUrl(url.replace('https://iframe.mediadelivery.net/', ''))
  }
}

export const getVideoThumb = (videoId: string, libraryId: string) => {
  // thumbs passed straight from the profile
  if (!libraryId) {
    return videoId
  }

  let cdn = cdnUrl
  if (libraryId == profileLibraryId || libraryId == briefLibraryId) {
    cdn = videoCdnUrl
  }
  if (libraryId == miappiLibraryId) {
    cdn = miappiCdnUrl
  }

  let url = cdn + '/' + videoId + '/thumbnail.jpg'

  // proxy locally to avoid paying for the bunny CDN, and optimize
  return useImageCDN(url, 'small', true)
}

export const getVideoStream = (videoId: string, libraryId: string) => {
  // embed options
  const options = '?autoplay=true&loop=false&muted=false&preload=true&responsive=true'

  // miappi home page videos
  if (videoId.includes(miappiCdnUrl)) {
    const stream = videoId.split('/')
    return (
      'https://iframe.mediadelivery.net/embed/157310/' +
      stream[3] + // videoId
      options
    )
  }
  // convert hls playlist to iframe embed
  if (videoId.includes(videoCdnUrl) || videoId.includes('iframe.mediadelivery.net')) {
    const id = videoId.split('/')
    console.log
    videoId = id[3]
    libraryId = videoLibraryId
  }
  return embedUrl + '/' + libraryId + '/' + videoId + options
}

// profile uploads (no watermark cdn)
export const getIDfromProfileUrl = useMemoize(async (file_url) => {
  if (file_url && typeof file_url === 'string' && file_url.match('/proxy/landbot/video/brief/')) {
    const response = await fetch(file_url)
    if (response?.url && response?.url?.startsWith('https://iframe.mediadelivery.net/')) {
      return splitUrl(response.url)
    }
  }
})

// submissions uploads (watermark cdn)
export const getIDfromSubmissionUrl = (url: string) => {
  return splitUrl(url)
}

// check if a value is set for collapsable sections
export const isSet = (v: any) => {
  // JSON empty array
  if (v == '[]') {
    return false
  }
  // empty array
  if (Array.isArray(v) && v.length == 0) {
    return false
  }
  // empty string
  if (typeof v !== 'undefined' && v.trim() !== '') {
    return true
  }
  return false
}

export const upgradeUrlMeta = (data: string) => {
  // check for new format with meta data
  let decode

  try {
    decode = JSON.parse(data)
    // bad data / legacy dataformat
    if (
      typeof decode[0] === 'undefined' ||
      typeof decode[0].file_url === 'undefined' ||
      !decode[0].file_url
    ) {
      // empty array ok, don't complain
      if (isSet(decode)) {
        console.warn('upgradeUrlMeta : bad data [', decode, ']')
      }
      decode = ''
      data = ''
    }
  } catch (e) {
    if (data) {
      // console.log('upgradeUrlMeta: assuming old format', data)
    }
    decode = [
      {
        file_url: data,
        name: '',
        description: '',
        tags: ''
      }
    ]
    data = JSON.stringify(decode)
  }
  return { data: data, decode: decode }
}

export const extractFileUrl = (file: string) => {
  const { decode } = upgradeUrlMeta(file)
  if (decode) {
    return decode[0].file_url
  }
}

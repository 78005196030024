const en = {
  strapline: 'Where brands find great content, and creatives are rewarded fairly.',
  home: {
    link_title: 'Contented Homepage',
    app: {
      button: {
        text: 'Get Started'
      }
    },
    footer: {
      name: 'Contented',
      company: 'Contented Media Group Limited',
      strapline: 'The Next Generation Creation',
      copyright: 'Copyright © {year}'
    }
  },
  marketplace: {
    title: 'Content Marketplace',
    description:
      'Explore and license a world of images, videos, music and other media to add new creative dimensions to your marketing campaigns. Need more? Publish creative briefs inviting content creators to submit new ideas specifically for your campaign.',
    briefs: {
      title: 'Creative Briefs',
      description:
        'Need some unique content? You’re just a brief away from finding it. Write and publish your brief, and our community of creatives will respond with their amazing ideas.',
      restricted: {
        login: 'Please login to create a new Brief.'
      }
    },
    licensed: {
      title: 'Licensed Media',
      description: 'Explore all of the content you have already licensed.',
      restricted: {
        login: 'Please login to view your Licensed Media.'
      }
    },
    licensing: {
      title: 'Stock Library',
      description:
        'If you need to find new content for a project, you’re in the right place. Explore and license content from the library of images, videos, music and other media.'
    },
    rewards: {
      title: 'Transactions',
      description:
        'This page shows you the content you have paid for in the past and the content that you still need to pay for based on your usage.',
      restricted: {
        login: 'Please login to view your Transactions.'
      }
    }
  },
  scout: {
    title: 'Content Bids',
    description:
      'Think you know great content when you see it? Can you spot content with a chance of going viral? Use Content Bids to own up to 20% of media that you think brands will want to use.  The more the brand pays to license the content, the greater your reward.',
    bid: {
      title: 'Bid on Briefs',
      description:
        'Bid on brand briefs in order to own a share of the content that has been submitted by content creators in response to those briefs.'
    },
    bidsandrewards: {
      title: 'Bids & Rewards',
      description:
        'Check out your previous bids, as well as the rewards you received from your fractional ownership of that content.',
      restricted: {
        login: 'Please login to view your Bids &amp; Rewards.'
      }
    },
    bidding: {
      title: 'Content Bidding',
      description:
        'Bid on great content you want to have a share of. You can bid to own up to 20% of any piece of content.'
    }
  },
  studio: {
    title: 'Creator Studio',
    description:
      'Build and manage your portfolio of creative images, videos and other media using Creator Studio. Upload media and sell it via the Content Market. Up for a challenge? Create new content in response to creative briefs published by brands and agencies.',
    bids: {
      title: 'Portfolio & Rewards',
      description:
        'Review the content you’ve uploaded to your portfolio and to specific briefs. Check out the rewards you’ve earned from brands and agencies who have paid for your content.',
      restricted: {
        login: 'Please login to view Portfolio & Rewards.'
      }
    },
    briefs: {
      title: 'View all briefs - Contented',
      description:
        'Review creative briefs published by brands and agencies. Respond to the briefs with your own submissions.'
    },
    smartbriefs: {
      title: 'Respond to Smart Briefs',
      description:
        'Review creative briefs published by brands and agencies. Respond to the briefs with your own submissions.'
    },
    sync: {
      title: 'Social Sync'
    },
    upload: {
      title: 'Upload Content',
      description:
        'If you need to find new content for a project, you’re in the right place. Explore and license content from the library of images, videos, music and other media.'
    },
    bulkupload: {
      title: 'Bulk Upload',
      description:
        'Upload thousands of images at once, Just make sure they have all key the information as metadata [IPTC title, description and keyword], bundle them into one zip file, accept terms & conditions and upload the ZIP file. This process can take some time to complete, so please do not close your browser.'
    }
  },
  user: {
    title: 'User Preferences',
    description: 'Add your name and brand logo.',
    profile: {
      title: 'Profile',
      description: 'Add your personal settings here.',
      restricted: {
        login: 'Please login to create a adjust your profile.'
      }
    }
  }
}

export default en

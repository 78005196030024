<script lang="ts" setup>
import { ref } from 'vue'
import PopupModal from '@media/PopupModal.vue'

type PropsType = {
  title?: string
  message?: string
  okButton?: string
  cancelButton?: string
}

const props = withDefaults(defineProps<PropsType>(), {
  title: 'ATTENTION!',
  message: '',
  okButton: '',
  cancelButton: ''
})

const popup = ref<InstanceType<typeof PopupModal>>()

const title = ref<PropsType['title']>(props.title)
const message = ref<PropsType['message']>(props.message)
const okButton = ref<PropsType['okButton']>(props.okButton)
const cancelButton = ref<PropsType['cancelButton']>(props.cancelButton)

const resolvePromise = ref<(value: unknown) => void>()
const rejectPromise = ref<(value: unknown) => void>()

const show = (opts = {} as PropsType) => {
  title.value = opts.title
  message.value = opts.message
  okButton.value = opts.okButton
  cancelButton.value = opts.cancelButton

  popup.value?.open()

  return new Promise((resolve, reject) => {
    resolvePromise.value = resolve
    rejectPromise.value = reject
  })
}

const _confirm = () => {
  popup.value?.close()
  resolvePromise.value?.(true)
}

const _cancel = () => {
  popup.value?.close()
  rejectPromise.value?.(false)
}

defineExpose({
  show
})

const closeButton = () => {
  if (cancelButton.value) {
    _cancel()
  } else {
    _confirm()
  }
}
</script>

<template>
  <PopupModal ref="popup">
    <div class="p-1">
      <div class="w-full font-medium flex justify-between">
        <div class="text-xl">
          <h2 class="font-bold text-[#FF2028] text-center sm:text-start p-0 m-0">{{ title }}</h2>
        </div>
        <div @click.prevent="closeButton" class="text-gray-400 hover:text-blue-400 cursor-pointer">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="pr-5">
        <div class="mb-3 mt-4">
          <span class="text-gray-500 font-medium text-sm">
            <p>{{ message }}</p>
          </span>
        </div>
        <div class="flex flex-row space-x-4 justify-center pt-3">
          <button
            class="w-[120px] items-center justify-center border-2 text-gray-400 font-medium text-sm tracking-wide hover:bg-[#FF008B] hover:border-[#FF008B] hover:text-white cursor-pointer rounded-full px-3 py-2"
            @click="_confirm"
          >
            {{ okButton }}
          </button>
          <button
            v-if="cancelButton"
            class="w-[120px] items-center justify-center border-2 text-gray-400 font-medium text-sm tracking-wide hover:bg-[#FF008B] hover:border-[#FF008B] hover:text-white cursor-pointer rounded-full px-3 py-2"
            @click="_cancel"
          >
            {{ cancelButton }}
          </button>
        </div>
      </div>
    </div>
  </PopupModal>
</template>

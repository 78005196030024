<script lang="ts" setup>
const props = defineProps({
  stage: {
    default: 1
  }
})

const state0 = 'fa-solid fa-circle-dot bg-cm-gray-50'
const state1 = 'fa-light fa-circle-arrow-right bg-cm-gray-50'
const state2 = 'fa-solid fa-circle-check bg-cm-gray-50'
</script>

<style>
.ticks i {
  color: #0dabe0;
}
</style>

<template>
  <div class="w-[200px] relative flex items-center">
    <div class="absolute w-full bg-gray-300 h-[1px]"></div>
    <div class="absolute w-full">
      <div class="flex text-blue-400 justify-between items-center ticks">
        <!-- page 1 -->
        <i v-if="stage == 1" :class="state0"></i>
        <i v-else-if="stage > 1" :class="state2"></i>

        <!-- page 2 -->
        <i v-if="stage == 2" :class="state0"></i>
        <i v-else-if="stage < 2" :class="state1"></i>
        <i v-else-if="stage > 2" :class="state2"></i>

        <!-- page 3 -->
        <i v-if="stage == 3" :class="state0"></i>
        <i v-else-if="stage < 3" :class="state1"></i>
        <i v-else-if="stage > 3" :class="state2"></i>
      </div>
    </div>
  </div>
</template>

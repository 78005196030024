<script lang="ts" setup>
import { ref, computed, watchEffect } from 'vue'
import { RouterLink } from 'vue-router'
import { useNearWalletStore } from '@/stores/near-wallet'
import { storeToRefs } from 'pinia'
import { useUcanStore } from '@/stores/ucan'
import { fetchProfile } from '@/queries/api'
import { useToast } from 'vue-toastification'
import { Vue3Lottie } from 'vue3-lottie'
import { v4 as uuidv4 } from 'uuid'

import busy from '@/lottie/busy.json'
import BriefDetailsQAComment from '@briefs/BriefDetailsQAComment.vue'
import LoginRequired from '@/components/LoginRequired.vue'
import MediaItemTileSubmission from '@media/MediaItemTileSubmission.vue'
import ToggleContent from '@/components/ToggleContent.vue'

const ucanStore = useUcanStore()
const nearWalletStore = useNearWalletStore()
const { accountId } = storeToRefs(nearWalletStore)
const toast = useToast()

const props = withDefaults(
  defineProps<{
    brief: SmartBrief
    loggedInUserCandidateHasFeedback: boolean
    onSubmitContentEvent: Function
    uppyBusy: boolean
    uppyProgress: number
    isBriefLoading?: boolean
    isClosedBrief?: boolean
  }>(),
  {
    loggedInUserCandidateHasFeedback: false,
    onSubmitContentEvent: () => {},
    uppyBusy: false,
    uppyProgress: 0,
    isBriefLoading: false,
    isClosedBrief: false
  }
)
const profiles = ref<SmartBriefProfile[]>([])
const profileNames = ref<{ [key: string]: ProfileData }>({})
const isProfilesLoading = ref(true)
// const uploadMessage = ref('Uploading')
const isLoading = ref(false)
isLoading.value = false
const UploadRefLogo = ref()
const uploadsComplete = ref(false)

const getAllProfiles = async (userIds: string[]) => {
  const profiles: { [key: string]: any } = {}
  await Promise.all(
    userIds.map(async (userId) => {
      profiles[userId] = await fetchProfile(userId)
    })
  )
  return profiles
}

const isDataLoaded = ref(false)
let profilesFetched = false
watchEffect(async () => {
  if (props.brief.candidateProfiles) {
    profiles.value = props.brief.candidateProfiles
    isDataLoaded.value = true
  }
  if (props.brief.candidateProfiles && !profilesFetched) {
    profilesFetched = true
    try {
      const fetchedProfiles = await getAllProfiles([props.brief.owner, accountId.value])
      profileNames.value = fetchedProfiles
    } catch (error) {
      console.error('Failed to fetch profiles:', error)
    } finally {
      isProfilesLoading.value = false
    }
  }
})

const uuid = uuidv4()

const myContents = computed(() => {
  const myProfile = profiles.value?.filter((item) => item.userId === accountId.value)
  if (!myProfile) return []
  const items = myProfile[0]?.contents?.filter((content) => content.owner === accountId.value)
  // keep a global list so that the popup can go prev/next
  if (typeof window.carousels === 'undefined') {
    window.carousels = {}
  }
  window.carousels[uuid] = items
  return items
})

const userSubmissionAccepted = computed(() => {
  return props?.brief?.acceptedProfiles?.indexOf(accountId.value) > -1
})

const submitted = (data: any) => {
  console.log(data)
  window.location.reload()
}

function convertToUnixTimestamp(dateStr: string) {
  const date = new Date(dateStr)
  const unixTimestamp = date.getTime()
  return unixTimestamp
}

const prepareFeedbackThreads = (feedbacks: SmartBriefFeedback[], contentId: string) => {
  const threads: SmartBriefFeedbackThreadMap = {}
  for (let comment of feedbacks) {
    let parent_id = comment.parentId
    // root thread
    if (!parent_id) {
      parent_id = ''
    }
    // create thread
    if (typeof threads[parent_id] === 'undefined') {
      threads[parent_id] = []
    }
    // add comment
    threads[parent_id].push({
      id: comment.id,
      comment: comment.feedbackText,
      comment_file: comment.feedbackFile ? JSON.parse(comment.feedbackFile || '[]') : [],
      account_name: profileNames.value[comment.owner]?.firstName || 'Unknown User',
      profile_image: '',
      created_on: convertToUnixTimestamp(comment.createdAt),
      reference_id: contentId
    })
  }
  return threads
}

const isActive = ref()
const setActive = (id: any) => {
  isActive.value = id
}

const refreshUpload = ref(0)
const saveUploadedFile = async (fieldName: string, data: any) => {
  isLoading.value = true
  // console.log('saveFile : fieldName', fieldName, data)
  const uploads = JSON.parse(data)
  // console.log(uploads);
  if (uploads?.[0]?.name && uploads?.[0]?.name !== '') {
    await props?.onSubmitContentEvent(uploads[0])
    isLoading.value = false
    uploadsComplete.value = true
    toast.success('Uploaded')
    refreshUpload.value++
  }
}
</script>

<template>
  <div class="flex flex-col min-h-screen">
    <div class="bg-white w-full rounded-md py-3 px-6 mb-9 border-2 border-[#5386EA]">
      <div v-if="myContents?.length === 0" class="w-full max-w-7xl flex flex-col mx-auto">
        <h3 class="text-lg font-bold tracking-wide my-3" style="text-align: left">
          You're good to go!
        </h3>
        <p class="my-3">
          Congratulations, the brand loves the look of your profile and portfolio, and they have
          approved you to work on the brief. You can now create your content and upload it here. 
          <br /><br />
          Have a question before submitting? Message the brand.
        </p>
        <div class="flex my-3">
          <RouterLink
            :to="{
              name: 'briefs.briefdetails',
              params: {
                brief_id: props.brief?.slug || props.brief?.id,
                active_tab: 'messages'
              }
            }"
          >
            <div class="action-button solid">Message</div>
          </RouterLink>
        </div>
      </div>
      <div
        v-else-if="loggedInUserCandidateHasFeedback && !userSubmissionAccepted"
        class="w-full max-w-7xl flex flex-col mx-auto"
      >
        <h3 class="text-lg font-bold tracking-wide my-3" style="text-align: left">
          You have feedback!
        </h3>
        <p class="my-3">
          Hi, the brand has reviewed your content and provided you with feedback regarding changes
          they need you to make.
          <br /><br />
          NOTE: Remember to check the technical requirements and deliverables before you create and
          submit your content. Link to brief details page.
        </p>
      </div>
      <div v-else-if="userSubmissionAccepted" class="w-full max-w-7xl flex flex-col mx-auto">
        <h3 class="text-lg font-bold tracking-wide my-3" style="text-align: left">Success!</h3>
        <p class="my-3">
          Congratulations, the brand has accepted your content. Check out the Licensed Content page
          to see details.
        </p>
      </div>
      <div v-else class="w-full max-w-7xl flex flex-col mx-auto">
        <h3 class="text-lg font-bold tracking-wide my-3" style="text-align: left">
          Thank you for your submission!
        </h3>
        <p class="my-3">
          Your content is now pending review from the brand and they will share feedback if they
          need any changes made.
          <br /><br />
          We'll send you an email when these are ready, or if your content is successfully approved.
        </p>
      </div>
    </div>
    <div class="bg-white w-full rounded-md md:px-8 md:py-8 mb-6">
      <div class="w-full">
        <h3 class="font-semibold text-xl tracking-wide" style="text-align: left">
          My submissions to this brief
        </h3>
      </div>

      <div class="py-6">
        <hr />
      </div>

      <Vue3Lottie v-if="props.isBriefLoading" :animationData="busy" :height="50" />
      <div class="py-6" v-if="isDataLoaded" :data-carousel-id="uuid">
        <div
          v-for="(media, index) in myContents"
          :key="index"
          class="w-full md:flex md:flex-row mb-8"
        >
          <div class="md:w-1/4 pt-3 font-bold">
            Submission {{ (index + 1) }}
            <MediaItemTileSubmission
              :key="`media-${index}-${refreshUpload}`"
              @videoPlaying="setActive"
              :item="media"
              :links="false"
              :isActive="isActive"
              :notEncrypted="false"
              :showOwner="false"
              :showDescription="false"
              :isAccepted="false"
              :brief="brief"
            />
          </div>
          <div
            v-if="(brief?.owner === accountId || media.owner === accountId) && !isProfilesLoading"
            class="md:ml-8 md:w-3/4"
          >
            <BriefDetailsQAComment
              :brief="brief"
              :threads="prepareFeedbackThreads(media.feedbacks, media.id)"
              :reference_type="'content'"
              @submitted="submitted"
            />
          </div>
        </div>
      </div>
      <div class="py-6" v-if="isDataLoaded && !isClosedBrief" :key="refreshUpload">
        <LoginRequired>
          <ToggleContent
            message="Upload your content"
            uploadField="UploadRefLogo"
            :uploadMediaOnly="true"
            :uploadAccountId="accountId"
            :uploadValue="UploadRefLogo"
            :uploadType="'content'"
            :uploadBriefOwnerId="props.brief.owner"
            @onUploaded="saveUploadedFile"
          />
        </LoginRequired>
      </div>
    </div>
  </div>
</template>

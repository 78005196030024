// window.crypto.subtle is not available in all environmentss, so handle that edge-case
const isUnavailable =
  (functionName: string) =>
  (...args: any) =>
    new Promise(
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (resolve) => {
        console.error('[Unavailable]: window.crypto.subtle.' + functionName, ...args)
      },
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (reject: any) => {
        console.error('[Unavailable]: window.crypto.subtle.' + functionName, ...args)
      }
    )

const cryptoSubtle = window?.crypto?.subtle || {
  generateKey: isUnavailable('generateKey'),
  encrypt: isUnavailable('encrypt'),
  decrypt: isUnavailable('decrypt'),
  exportKey: isUnavailable('exportKey'),
  importKey: isUnavailable('importKey')
}

// Define common RSA options
const rsaOptions = {
  name: 'RSA-OAEP',
  modulusLength: 2048,
  publicExponent: new Uint8Array([1, 0, 1]),
  hash: { name: 'SHA-256' }
}

// functions for working with window.crypto.subtle
export function generateKeypair() {
  return cryptoSubtle.generateKey(rsaOptions, true, ['encrypt', 'decrypt'])
}

export function encrypt(input: string | undefined, publicKey: CryptoKey) {
  const data = new TextEncoder().encode(input)

  return cryptoSubtle
    .encrypt(
      { name: rsaOptions.name },
      publicKey,
      data // ArrayBuffer of data you want to encrypt
    )
    .then((enc) => enc)
}

export function decrypt(encrypted: BufferSource, privateKey: CryptoKey) {
  return cryptoSubtle
    .decrypt(
      { name: rsaOptions.name },
      privateKey,
      encrypted // ArrayBuffer of the encrypted data
    )
    .then((decrypted) => new TextDecoder().decode(decrypted))
}

export function exportPublicKey(publicKey: CryptoKey) {
  return cryptoSubtle.exportKey(
    'spki', // format
    publicKey
  )
}

export function importPublicKey(publicKeyAsArrayBuffer: ArrayBufferView | ArrayBuffer) {
  return cryptoSubtle.importKey(
    'spki', // format
    publicKeyAsArrayBuffer, // key as ArrayBuffer
    {
      name: rsaOptions.name,
      hash: rsaOptions.hash
    },
    true,
    ['encrypt']
  )
}

export function exportPrivateKey(privateKey: CryptoKey) {
  return cryptoSubtle.exportKey(
    'pkcs8', // format for private key
    privateKey // your private key
  )
}

export function importPrivateKey(privateKeyAsArrayBuffer: BufferSource) {
  return cryptoSubtle.importKey(
    'pkcs8', // format
    privateKeyAsArrayBuffer, // ArrayBuffer of the private key
    {
      name: rsaOptions.name, // or "RSA-PSS" or your algorithm name
      hash: rsaOptions.hash // or your hash name
    },
    true, // whether the key is extractable
    ['decrypt'] // "decrypt" for RSA-OAEP or "sign" for RSA-PSS
  )
}

// functions to help with conversion between arraybuffer and base64
export function convertArrayBufferToBase64(buffer: Iterable<number> | ArrayBuffer) {
  let binary = ''
  const bytes = buffer instanceof ArrayBuffer
    ? new Uint8Array(buffer)
    : new Uint8Array(Array.from(buffer))
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

export function convertBase64ToArrayBuffer(base64: string) {
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

export const encryptValueUsingPublicKey = async (value: string | undefined) => {
  // ensure we have the value
  if (!value) return false

  // public key in base64 encode
  const publicKeyBase64 =
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA6nPf+vFObIiK/KpZLMS6SlZ/ow4Jv+Nhz2RSdxwvDF75TDhqRKlksnMStLyWp66rsayVgnYFLvSixUQMPYM9v2LOW1wVEP6lNRUTbbfdpIaTHOq7Xp3GOUCSYjpeG0CgmQzHBciSK3qJDRfZJbmT6bqR/vur5CITuI8lWxKKjsw1r3hpOi3VZ7o24LWXB0/5wfsAakx9t9EfByd7rXmE5du4ZPQmmdMVB+wAwSlDV6v3lKaz/aPlkItsETCOXqlfKPr/smWjsoRCxgKv+F7queOerwYLH2frxuhMHmGQwFovSqT5w8mFsQXkIb4PldpGEIxcwYm7hYKAPTIGcz9rpQIDAQAB'

  // import the public key
  const publicKey = await importPublicKey(convertBase64ToArrayBuffer(publicKeyBase64))

  // encrypt the string and return the base64 encoded string
  const encrypted = await encrypt(value, publicKey)

  return convertArrayBufferToBase64(encrypted)
}

<script lang="ts" setup>
import { ref, type PropType } from 'vue'
import { useNearWalletStore } from '@/stores/near-wallet'
import { parseISO, format } from 'date-fns'

import MediaItem from '@media/MediaItem.vue'

const emit = defineEmits(['videoPlaying'])

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  // the id of the currently playing video so other instances of this component know
  isActive: {
    type: String,
    default: ''
  },
  notEncrypted: {
    type: Boolean,
    default: false
  },
  isAccepted: {
    type: Boolean,
    default: false
  },
  links: {
    type: Boolean,
    default: false
  },
  imageCss: {
    type: Array,
    default: () => []
  },
  showDescription: {
    type: Boolean,
    default: false
  },
  showOwner: {
    type: Boolean,
    default: false
  },
  brief: Object as PropType<SmartBrief>
})

const nearWalletStore = useNearWalletStore()

const details = ref({
  isVideo: false
})

const mediaDetails = (data: any) => {
  details.value = data
}
</script>

<template>
  <div
    class="w-full h-auto"
    :class="{
      'flex items-center justify-center': !showOwner
    }"
  >
    <MediaItem
      :hideIfDead="true"
      :item="item"
      :isAccepted="isAccepted"
      :imageCss="['w-full', 'max-w-[300px]', 'h-auto']"
      :videoCss="['w-full', 'max-w-[300px]', 'h-64']"
      @mediaDetails="mediaDetails"
    />

    <div v-if="showOwner && item.owner">
      <div class="my-2 text-xs" v-if="details">
        <b>Format:</b> {{ details.isVideo ? 'Video' : 'Photography' }}<br />
        <b>Earnings:</b> ${{ item?.amountPaid }}
      </div>
    </div>
    <div v-if="showDescription">
      <div class="my-2 text-xs">
        <b>{{ item.name }}</b
        ><br />
        <b>Date licensed:</b>
        {{
          item.contentAcceptedAt
            ? format(parseISO(item.contentAcceptedAt), 'dd/MM/yyyy')
            : format(parseISO(item.updatedAt), 'dd/MM/yyyy')
        }}<br />
        <b>{{ item.creatorName ? 'Creator' : 'By' }}:</b> {{ item.creatorName || item.brandName }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.controls:hover .fa-circle {
  @apply text-white;
}

.controls:hover .fa-play {
  @apply text-slate-700;
}
</style>

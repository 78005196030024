<script lang="ts" setup>
import { ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { getTeams } from '@/queries/api'
import BriefsLayout from '@/Layouts/BriefsLayout.vue'
import Auth from '@/components/Pages/Creatives/Wizard/Shared/Auth.vue'
import { useNearWalletStore } from '@/stores/near-wallet'
import ActionButton from '@/components/Pages/Teams/Partial/ActionButton.vue'
import { Vue3Lottie } from 'vue3-lottie'
import circles from '@/lottie/searching.json'

const nearWalletStore = useNearWalletStore()

const $router = useRouter()

const loading = ref<boolean>(false)
const teams = ref<Team[]>([])

watchEffect(async () => {
  if (nearWalletStore.accountId) {
    loading.value = true
    teams.value = await getTeams()

    if (!teams.value || teams.value.length == 0) {
      $router.push({
        name: 'teams.start'
      })
    }

    loading.value = false
  }
})
</script>

<template>
  <BriefsLayout title="Teams" selectedLinkId="">
    <Auth>
      <div v-if="loading" class="flex justify-center items-center h-screen">
        <Vue3Lottie :animationData="circles" :height="50" :width="50" />
      </div>
      <div v-else>
        <div
          class="-mb-4 uppercase tracking-widest font-bold text-xs text-gray-500 hover:text-[#FF008B]"
        >
          <span>&nbsp;</span>
        </div>
        <div class="flex items-center">
          <h1 class="font-bold tracking-wider text-3xl">Teams</h1>
          <router-link
            :to="{
              name: 'teams.start'
            }"
          >
            <ActionButton icon="fa-solid fa-plus-circle"> Create Team </ActionButton>
          </router-link>
        </div>
      </div>
      <div class="flex flex-wrap items-center -ml-3">
        <router-link
          v-for="team in teams"
          :key="team.uuid"
          :to="{
            name: 'teams.show',
            params: {
              nickname: team.nickname
            }
          }"
          class="group w-full sm:w-1/3 md:w-1/4 xl:w-1/5"
        >
          <div class="m-3 py-1.5 px-6 rounded-md border hover:border-[#FF008B]">
            <h2 class="text-left tracking-wider font-medium group-hover:text-[#FF008B]">
              {{ team.name }}
            </h2>
          </div>
        </router-link>
      </div>
    </Auth>
  </BriefsLayout>
</template>

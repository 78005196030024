<script lang="ts" setup>
import { ref, watch, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useNearWalletStore } from '@/stores/near-wallet'

const props = withDefaults(
  defineProps<{
    //
  }>(),
  {
    //
  }
)

const $router = useRouter()

const nearWalletStore = useNearWalletStore()

const { accountId, inited } = storeToRefs(nearWalletStore)

const loggedIn = ref(false)

const nextPage = (accountId?: string) => {
  if (document.referrer) {
    window.location.href = document.referrer || '/'
  } else if (accountId) {
    $router.push({
      name: 'user.profile',
      params: {
        username: accountId
      }
    })
  } else {
    $router.push({
      name: 'briefs.list'
    })
  }
}

const auth = async () => {
  await nearWalletStore.web3authLogin({
    errorCallback: function (e) {
      if (e.message === 'User closed the modal') {
        nextPage(accountId.value)
      }
    }
  })
}

watch(accountId, (nv, ov) => {
  if (nv && nv !== ov) {
    nextPage(nv)
  }
})

watchEffect(() => {
  loggedIn.value = inited.value && accountId.value ? true : false

  if (!loggedIn.value) {
    auth()
  }
})
</script>

<template>
  <!--  -->
</template>

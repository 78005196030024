<script lang="ts" setup>
// @ts-nocheck
import { nextTick, onMounted, reactive, ref, watch } from 'vue'
import { FormKitMessages } from '@formkit/vue'
import { useNearWalletStore } from '@/stores/near-wallet'
import { fetchProfile } from '@/queries/api'
import { storeToRefs } from 'pinia'
import { pinMediaToIPFS } from '@/utils/ipfs'
import { upgradeUrlMeta } from '@/utils/mediaDecrypt'

import {
  nextPageForProfile,
  submitProfileSave,
  createProfileRecordIfNonExistent
} from '@/utils/storeProfile.js'

import Countries from '@/config/countries'
import _ from 'lodash'
import SpinnerOverlay from '@/components/SpinnerOverlay.vue'
import ProfileAvatar from '@/components/ProfileAvatar.vue'

export type EditFormOnSubmitSuccessData = {
  accountId: string
  profile: ProfileData
}

const props = withDefaults(
  defineProps<{
    hideFields?: {
      languages?: boolean
    }
    onSubmitSuccess?: (data: EditFormOnSubmitSuccessData) => void
    submitButtonLabel?: string
  }>(),
  {
    hideFields: () => ({
      languages: false,
      submitButtonLabel: 'Save'
    })
  }
)

// load data
const nearWalletStore = useNearWalletStore()

const { accountId } = storeToRefs(nearWalletStore)

const profileVersion = ref(0)
const profile = ref({})

const loadProfile = async (id: string) => {
  // fetch the record
  const response = await fetchProfile(id)

  // normalise
  let entry = _.cloneDeep(response)
  entry.socials = entry?.socials || {}

  // toggle the socials on/off
  socials.forEach((social, index) => {
    let network = social.value
    entry.socials[network] = entry.socials[network] || entry[network] || null
    socials[index].showInput = !!entry.socials[network]
  })

  nextTick(() => {
    // update the profile
    profile.value = entry
    profileVersion.value = profileVersion.value + 1

    // support legacy / new upload format
    const { decode } = upgradeUrlMeta(profile.value.profilePic)
    if (decode) {
      profile.value.profilePic = decode
    }

    // wait before showing to let all the state propogate
    nextTick(() => {
      loading.value = false

      // track the intention to update the profile
      if (typeof window?.gtag === 'function') {
        window.gtag('event', 'profile_update_load', {
          event_category: 'profile',
          profile_id: accountId.value
        })
      }

      window?.posthog?.capture('profile_update_load', {
        type: 'profile_update',
        category: 'profile',
        profile_id: accountId.value
      })
    })
  })
}

const boot = async (id: string) => {
  createProfileRecordIfNonExistent(id).then(() => {
    loadProfile(id)
  })
}

// form related
const loading = ref(true)
const form = ref()
const isSubmitted = ref(false)

const countries = Countries.map(({ label }) => ({ label: label, value: label }))

const socials = reactive([
  {
    label: 'YouTube',
    value: 'youtube',
    showInput: false,
    icon: 'fa-brands fa-youtube',
    validation: [['url'], ['FormKitYouTubeValidation']]
  },
  {
    label: 'TikTok',
    value: 'tiktok',
    showInput: false,
    icon: 'fa-brands fa-tiktok',
    validation: [['url'], ['FormKitTikTokValidation']]
  },
  {
    label: 'Instagram',
    value: 'instagram',
    showInput: false,
    icon: 'fa-brands fa-instagram',
    validation: [['url'], ['FormKitInstagramValidation']]
  },
  {
    label: 'X formerly Twitter',
    value: 'twitter',
    showInput: false,
    icon: 'fa-brands fa-twitter',
    validation: [['url'], ['FormKitTwitterValidation']]
  },
  {
    label: 'Website or Blog',
    value: 'website',
    showInput: false,
    icon: 'fa-solid fa-link',
    validation: [['url']]
  },
  // {
  //   label: 'Facebook',
  //   value: 'facebook',
  //   showInput: false,
  //   icon: 'fa-brands fa-facebook',
  //   validation: [['url']]
  // },
  {
    label: 'Behance',
    value: 'behance',
    showInput: false,
    icon: 'fa-brands fa-behance',
    validation: [['url'], ['FormKitBehanceValidation']]
  },
  // {
  //   label: 'Pexels',
  //   value: 'pexels',
  //   showInput: false,
  //   icon: 'fa-brands fa-pexels',
  //   validation: [['url']]
  // },
  // {
  //   label: 'LinkedIn',
  //   value: 'linkedin',
  //   showInput: false,
  //   icon: 'fa-brands fa-linkedin',
  //   validation: [['url']]
  // },
  {
    label: 'Dribbble',
    value: 'dribbble',
    showInput: false,
    icon: 'fa-brands fa-dribbble',
    validation: [['url'], ['FormKitDribbbleValidation']]
  },
  {
    label: 'ArtStation',
    value: 'artstation',
    showInput: false,
    icon: 'fa-brands fa-artstation',
    validation: [['url'], ['FormKitArtStationValidation']]
  }
])

const avatar = ref()
const avatarPreviewUrl = ref()
watch(avatar, (nv, ov) => {
  // clear the old url
  if (nv !== ov && avatarPreviewUrl.value) {
    window.URL.revokeObjectURL(avatarPreviewUrl.value)
    avatarPreviewUrl.value = undefined
  }

  // get the new image
  if (!!nv && !!nv?.[0]) {
    const file = nv?.slice()?.pop()?.file
    if (file) {
      avatarPreviewUrl.value = window.URL.createObjectURL(file)
    }
  }
})

const getAvatarEl = () => document.getElementById('avatar')

const addAvatar = () => {
  getAvatarEl()?.click()
}

const clearAvatar = () => {
  avatar.value = null
}

// TODO : this component needs to save the profile pic { name: file_url } etc format, not just the xxxxxx.jpg because it will overwrite any meta saved by briefs wizard
const onSubmit = async () => {
  // add some warning messages
  if (!accountId.value) {
    console.warn('Unable to save, no accountId.')
    return false
  }

  if (loading.value) {
    console.warn('Already processing.')
    return false
  }

  // update state
  loading.value = true
  isSubmitted.value = true

  try {
    // clean up the 'languages' and the 'socials links'
    let storing = _.cloneDeep(profile.value)

    if (storing.profilePic) {
      storing.profilePic = JSON.stringify(storing.profilePic)
      storing.profile_file = storing.profilePic[0].file_url
    }

    socials.forEach((social) => {
      if (
        typeof storing.socials[social.value] !== 'undefined' &&
        storing.socials[social.value] !== null
      ) {
        const socialValue = (storing.socials[social.value] || '').trim()
        storing.socials[social.value] =
          !socialValue || socialValue === '' ? '__NULL__' : socialValue
        storing.socials[social.value] =
          storing.socials[social.value] === '__NULL__' ? null : storing.socials[social.value]
      }
    })

    storing.languages = storing.languages || []
    storing.languages = storing.languages.reduce(
      (acc, { name, level }) => (name && level ? [...acc, { name: name, level: level }] : acc),
      []
    )

    // process the avatar
    const avatarFile = avatar.value.slice()?.pop()?.file
    if (avatarFile && avatarFile?.name) {
      const avatarFilename = avatarFile.name.split('.')

      const cid = await pinMediaToIPFS(
        avatarFile,
        avatarFilename[0],
        avatarFilename[avatarFilename.length - 1],
        avatarFile.type
      )

      if (cid.pinataCid) {
        storing.profile_file = 'https://ipfs.io/ipfs/' + cid.pinataCid
        storing.profilePic = storing.profile_file
      }
    }

    // process the data
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const resp = await submitProfileSave(nearWalletStore, accountId.value, {
      ...storing
    })

    // track event in google analytics
    if (typeof window?.gtag === 'function') {
      window.gtag('event', 'profile_update_saved', {
        event_category: 'profile',
        profile_id: accountId.value
      })
    }

    window?.posthog?.capture('profile_update_saved', {
      type: 'profile_update',
      category: 'profile',
      profile_id: accountId.value
    })

    // call the onSubmitSuccess callback or redirect user back if we succeeded and we have a return link
    if (typeof props?.onSubmitSuccess === 'function') {
      props.onSubmitSuccess({
        accountId: accountId.value,
        profile: storing
      })
    } else {
      nextPageForProfile(accountId.value)
    }
  } catch (err) {
    //
    console.log('onSubmit', err)
  }

  // update state
  loading.value = false
}

// value gets set to __NULL__ on hide so that the contract knows to reset it, but don't show this value to the user
const showSocial = (index) => {
  socials[index].showInput = true
  // shown input
  const name = socials[index].value
  // don't show __NULL__ if they are about to re-input
  if (profile.value.socials[name] === '__NULL__') {
    profile.value.socials[name] = ''
  }
}

watch(accountId, (nv, ov) => {
  if (nv !== ov) {
    boot(nv)
  }
})

onMounted(() => {
  if (accountId.value) {
    boot(accountId.value)
  }
})
</script>

<template>
  <div>
    <SpinnerOverlay v-if="loading" />

    <div class="profile-form w-full max-w-xl mx-auto my-3" :key="profileVersion">
      <FormKit type="form" id="profile" ref="form" @submit="onSubmit" :actions="false">
        <div>
          <h1 class="text-2xl font-semibold tracking-wider">Profile</h1>
        </div>

        <div class="my-6 text-sm text-red-600 hidden" v-if="form?.node">
          <FormKitMessages :node="form.node" />
        </div>

        <div class="profile-form-section">
          <div class="profile-form-section-header">
            <div>
              <h2>Your details</h2>
            </div>
          </div>

          <div class="my-6">
            <hr />
          </div>

          <div class="my-3">
            <label class="formkit-label text-sm tracking-wide font-semibold" for="avatar"
              >Profile image</label
            >
            <div class="w-full flex items-center -ml-1.5">
              <div class="mx-1.5 mt-3 cursor-pointer" @click="addAvatar">
                <ProfileAvatar
                  :profile-id="profile?.owner"
                  :data="avatarPreviewUrl || profile.profilePic?.[0]?.file_url"
                  class="h-16 w-16"
                  imageBgColor="bg-transparent"
                  imageBorderColor="border-transparent"
                  imageBorderColorHover="group-hover:border-transparent"
                  fallbackBorderColor="border-gray-300"
                  fallbackTextColor="text-gray-500"
                  fallbackTextSize="text-3xl"
                />
              </div>
              <div class="flex items-end h-[80px] text-sm">
                <button
                  type="button"
                  class="mx-1.5 hover:text-[#E2007B]"
                  title="change avatar"
                  @click="addAvatar"
                >
                  <i class="fa-thin fa-pencil"></i>
                </button>
                <button
                  type="button"
                  class="mx-1.5 hover:text-[#E2007B]"
                  title="remove avatar"
                  v-if="!!avatarPreviewUrl"
                  @click="clearAvatar"
                >
                  <i class="fa-thin fa-trash-can"></i>
                </button>
              </div>

              <div class="absolute right-[999999px]">
                <FormKit
                  type="file"
                  label=""
                  id="avatar"
                  accept=".png,.jpeg,.jpg"
                  multiple="false"
                  v-model="avatar"
                />
              </div>
            </div>
          </div>

          <div class="w-full flex justify-between">
            <FormKit
              name="firstname"
              id="firstname"
              label="First name"
              placeholder="First name..."
              v-model="profile.firstName"
              :validation="[['required']]"
              outer-class="pr-3"
            />

            <FormKit
              name="lastname"
              id="lastname"
              label="Last name"
              placeholder="Last name..."
              v-model="profile.lastName"
              outer-class="pl-3"
            />
          </div>

          <div class="w-full flex justify-between">
            <FormKit
              name="city"
              id="city"
              label="City"
              placeholder="City..."
              v-model="profile.city"
              outer-class="pr-3"
            />

            <FormKit
              type="select"
              label="Country"
              placeholder="Select a Country"
              name="country"
              v-model="profile.country"
              outer-class="pl-3"
              :options="countries"
              autocomplete="country"
            />
          </div>

          <div class="w-full flex justify-between">
            <FormKit
              name="companyName"
              id="companyName"
              label="Company"
              placeholder="Company..."
              v-model="profile.companyName"
            />
          </div>
        </div>

        <div class="profile-form-section">
          <div class="profile-form-section-header">
            <div>
              <h2>Social Links</h2>
            </div>
          </div>

          <div class="my-6">
            <hr />
          </div>

          <div class="w-full flex flex-wrap -ml-1">
            <button
              v-for="(social, index) in socials"
              v-show="!social.showInput"
              :key="social.value"
              :title="social.label"
              @click="showSocial(index)"
              type="button"
              class="rounded-md my-3 px-3 py-3 mx-1 h-14 w-14 border border-[#949494] text-[#949494] hover:border-[#E2007B] hover:text-[#E2007B]"
            >
              <i :class="[social.icon]"></i>
            </button>
          </div>

          <FormKit type="list" name="socials" class="w-full" v-if="profile?.socials">
            <div
              v-for="social in socials.filter((s) => !!s.showInput)"
              :key="social.value"
              class="profile-form-socials flex"
            >
              <FormKit
                type="url"
                :name="social.value"
                :id="social.value"
                :label="social.label"
                placeholder="..."
                v-model="profile.socials[social.value]"
                :validation="[...social.validation]"
                outer-class="mr-1"
                :prefix-icon="social.icon"
                inner-class="flex items-center"
              />
              <div class="flex items-start ml-1">
                <button
                  type="button"
                  class="text-gray-400 hover:text-[#E2007B]"
                  @click="
                    () => {
                      profile.socials[social.value] = '__NULL__'
                      socials.forEach((s, i) => {
                        if (s.value === social.value) {
                          socials[i].showInput = false
                        }
                      })
                    }
                  "
                  title="Remove Social"
                >
                  <div class="mt-[45px]">
                    <i class="fa-light fa-trash-can"></i>
                  </div>
                </button>
              </div>
            </div>
          </FormKit>
        </div>

        <div class="profile-form-section">
          <div class="profile-form-section-header">
            <div>
              <h2>About you</h2>
            </div>
          </div>

          <div class="my-6">
            <hr />
          </div>

          <div class="w-full flex">
            <FormKit
              name="headline"
              id="headline"
              label="Profession/s"
              placeholder="e.g. Videographer, Creator, Artist"
              v-model="profile.headline"
              spellcheck="true"
            />
          </div>

          <div class="w-full flex">
            <FormKit
              type="textarea"
              name="aboutyou"
              id="aboutyou"
              label="About you"
              placeholder="Write a few words introducing yourself - a short bio, your content ideas and the reason for your content (is there a running theme/goal/purpose?) and your experience to date."
              v-model="profile.aboutYou"
              rows="6"
              spellcheck="true"
            />
          </div>
        </div>

        <div class="profile-form-section" v-if="!props?.hideFields?.languages">
          <div class="profile-form-section-header">
            <div>
              <h2>Languages</h2>
            </div>
          </div>

          <div class="my-6">
            <hr />
          </div>

          <FormKit type="list" name="languages" class="-mt-3 w-full flex flex-col">
            <div v-for="(item, index) in profile.languages" :key="item" class="flex">
              <FormKit
                name="language"
                label="Language"
                placeholder="Language"
                outer-class="mr-1 flex-grow"
                inner-class="flex items-center"
                v-model="profile.languages[index].name"
                :validation="[['min:1']]"
              />
              <FormKit
                type="select"
                name="level"
                label="Level"
                placeholder="Select a Level"
                outer-class="mx-1 max-w-[135px]"
                inner-class="flex items-center"
                v-model="profile.languages[index].level"
                :validation="[['is:Basic,Medium,High,Native']]"
                :options="[
                  { label: 'Basic', value: 'Basic' },
                  { label: 'Medium', value: 'Medium' },
                  { label: 'High', value: 'High' },
                  { label: 'Native', value: 'Native' }
                ]"
              />
              <div class="flex items-start ml-1">
                <button
                  type="button"
                  class="text-gray-400 hover:text-[#E2007B]"
                  @click="
                    () => {
                      profile.languages = profile.languages.filter((_, i) => i !== index)
                    }
                  "
                  title="Remove Language"
                >
                  <div class="mt-[45px]">
                    <i class="fa-light fa-trash-can"></i>
                  </div>
                </button>
              </div>
            </div>
            <FormKit
              id="languages-add-button"
              type="button"
              outer-class="w-full text-center"
              @click="
                () => {
                  const lang = { name: '', level: '' }
                  profile.languages = profile.languages ? [...profile.languages, lang] : [lang]
                }
              "
            >
              <div
                class="mx-auto text-sm mt-3 px-8 py-2 font-semibold text-[#949494] rounded-full border border-[#949494] hover:border-[#FF008B] hover:text-[#FF008B]"
              >
                Add Language
              </div>
            </FormKit>
          </FormKit>
        </div>

        <div class="w-full">
          <FormKit
            type="submit"
            :label="props.submitButtonLabel || 'Save'"
            input-class="rounded-full"
          />
        </div>
      </FormKit>
    </div>
  </div>
</template>

<style lang="scss">
.profile-form {
  .profile-form-section {
    @apply my-9 rounded-md bg-white border border-gray-200 p-6;
    .profile-form-section-header {
      div {
        @apply flex items-center text-slate-800;
        h2 {
          @apply text-xl tracking-wide my-1.5 font-semibold;
        }
      }
    }
  }
  .formkit-form {
    .formkit-outer {
      @apply w-full my-3;
      .formkit-label {
        @apply text-sm tracking-wide;
      }
      .formkit-inner {
        @apply shadow-none;
        .formkit-input {
          @apply rounded-md text-sm border-gray-300 w-full my-1 placeholder-gray-300;
        }
      }
      .formkit-messages {
        .formkit-message {
          @apply mt-1 text-sm text-red-600;
        }
      }
    }
  }
  .profile-form-socials {
    .formkit-outer {
      .formkit-inner {
        .formkit-input {
          @apply border-l-0 rounded-l-none;
        }
        .formkit-prefix-icon {
          @apply flex items-center justify-center;
          @apply p-[10.5px] border border-gray-300 rounded-md rounded-r-none bg-gray-50;
          svg {
            height: 15px;
            width: 15px;
            @apply text-[#949494];
          }
        }
      }
    }
  }
  #languages-add-button {
    @apply border-0 p-0 bg-transparent outline-none shadow-none;
  }
}
</style>

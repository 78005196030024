<script lang="ts" setup>
// @ts-ignore
import DOMPurify from 'dompurify'
import { marked } from 'marked'
import { computed, ref, watch, watchEffect } from 'vue'

marked.use({
  renderer: {
    link: function (href, title, text) {
      let link: string = marked.Renderer.prototype.link.call(this, href, title, text)
      let result: string = link.replace(`<a `, `<a target="_blank" `)
      return result
    }
  }
})

const props = defineProps({
  content: {
    type: String,
    default: ''
  },
  delimiter: {
    type: String,
    default: '    '
  }
})

/* const formattedContent = computed(() => {
  // Split the content by the specified delimiter and filter out empty strings
  const lines = props.content.split(props.delimiter).filter(Boolean)
  return lines.join('\n') // Join lines with HTML line breaks
}) */

const html = ref<string>()

/* watch(
  () => formattedContent.value,
  async () => {
    const content = await marked.parse(formattedContent.value)
    html.value = DOMPurify.sanitize(content)
  }
) */

watchEffect(async () => {
  const lines = props.content.split(props.delimiter).filter(Boolean)
  const linesFormatted = lines.join('\n')
  const content = await marked.parse(linesFormatted)
  html.value = DOMPurify.sanitize(content)
})
</script>

<template>
  <div class="markdown">
    <div v-html="html"></div>
  </div>
</template>

<style lang="scss">
.markdown {
  @apply px-0 md:py-3;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  margin: 10px auto;
  font-weight: bold;
}
.markdown a:hover {
  text-decoration: underline;
}

.markdown ul {
  list-style-type: disc;
  margin: 5px auto 5px 25px;
}

.markdown ol {
  list-style-type: decimal;
  margin: 5px auto 5px 25px;
}
</style>

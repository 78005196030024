<script lang="ts" setup>
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { computed, onMounted, onUnmounted, ref, reactive, watch, watchEffect } from 'vue'
import { refDebounced } from '@vueuse/core'
import { RouterLink, useRoute } from 'vue-router'
import { fetchProfile } from '@/queries/api'
import { deleteProfileContent, updateProfileContent, submitProfileSave } from '@/utils/storeProfile'
import { useNearWalletStore } from '@/stores/near-wallet'
import { upgradeUrlMeta } from '@/utils/mediaDecrypt'
import { useImageCDN } from '@/utils/image'
import { useUcanStore } from '@/stores/ucan'
import { mobileDetect } from '@/utils/browser'
import { isFile } from '@/utils/mediaFileTypes'

import BioCard from '@/components/BioCard.vue'
import UserLayout from '@/Layouts/UserLayout.vue'
import MediaControls from '@/components/Media/MediaControls.vue'
import WorkContents from '@/components/Pages/Profile/Work.vue'
import StockContents from '@/components/Pages/Profile/Stock.vue'
import SpinnerOverlay from '@/components/SpinnerOverlay.vue'
import ToggleContent from '@/components/ToggleContent.vue'
import UploadList from '@upload/UploadList.vue'

export type ContentPayload = {
  name: string
  description: string
  tags: string
  file_url: string
}

const nearWalletStore = useNearWalletStore()

const $route = useRoute()

const iteration = ref<number>(0)
const iterationDebounced = refDebounced<number>(iteration, 500)

const loading = ref<boolean>(false)

const props = withDefaults(
  defineProps<{
    username: string
    tab?: string
    showBot?: boolean
    allowDelete?: boolean
  }>(),
  {
    username: '',
    tab: '',
    showBot: false,
    allowDelete: false
  }
)

const isMobile = ref(mobileDetect())
const updatePageWidth = () => {
  isMobile.value = mobileDetect()
}
onMounted(() => {
  updatePageWidth()
  window.addEventListener('resize', updatePageWidth)
})
onUnmounted(() => {
  window.removeEventListener('resize', updatePageWidth)
})

const active_tab = ref(props?.tab || 'work')

const avatar = ref<string>('')

const profile = ref<ProfileData>()
const profileContents = ref<ProfileContent[]>([])

const isDeleted = (item: any) => {
  return item.file_url === ''
}

let fileCount = ref(0)

const loadProfile = async () => {
  active_tab.value = props?.tab || 'work'

  if ($route.params.username) {
    // const prfl = await fetchProfile(String($route.params.username))
    const ucanStore = useUcanStore()

    let prfl = await fetchProfile(String($route.params.username))

    // get the profile image
    if (prfl?.profilePic) {
      const { data, decode } = upgradeUrlMeta(prfl.profilePic)
      if (decode) {
        prfl.profilePic = data
        const img = useImageCDN(decode?.[0]?.file_url, 'thumb')
        if (img) {
          avatar.value = img
        }
      }
    }

    // reverse the contents
    const prflContents = [
      ...(prfl?.contents || [])
        .reverse()
        .filter((item) => !isDeleted(item))
        .filter((item) => !item.file_url.includes('preview.contentedmedia.com'))
    ]

    // count the files so we can show the correct total
    fileCount.value = prflContents.filter((item) => isFile(item)).length

    // update profile
    profile.value = prfl || profile.value

    // set the profile contents in reverse order
    profileContents.value = prflContents
  }
}

const isOwner = computed(() => {
  return nearWalletStore?.accountId === profile.value?.owner
})

const onUploadContent = async (fieldName: string, data: any) => {
  // ensure we have a logged in account
  if (!nearWalletStore.accountId) {
    console.log('Please login to upload content', fieldName, data)
    return
  }

  // get the data from the upload
  const uploads = JSON.parse(data) as ContentPayload[]
  if (uploads?.[0]?.name && uploads?.[0]?.name !== '') {
    let payload: ContentPayload[] = uploads

    try {
      // exit if we have no name or short description
      if (!payload.some(({ name }) => name?.trim()?.length > 0)) {
        console.log('Please enter a name and short description')
        return
      }

      // fetch the profile
      const profile = await fetchProfile(nearWalletStore.accountId)

      // create the updated profile
      const updatedProfile = _.cloneDeep(profile)

      // create the entry
      let id
      let updatedContents = profile?.contents?.length ? [...profile.contents] : []

      payload.forEach((item: ContentPayload) => {
        id = uuidv4()
        updatedContents.push({
          id: id,
          name: item.name || id,
          short_description: item.description,
          tags: item?.tags?.split(',').filter((tag) => tag && tag !== '') || [],
          file_url: item.file_url
        })
      })

      // update the profile contents
      updatedProfile.contents = updatedContents

      // save
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      await submitProfileSave(nearWalletStore, nearWalletStore.accountId, updatedProfile)

      // redirect user back if we succeeded and we have a return link
      iteration.value = iteration.value + 1

      // reload the content
      await loadProfile()
    } catch (error) {
      console.error('An error occurred:', error)
    }
  }
}

const showSelected = ref(false)

let selectedItems = reactive<string[]>([])

const onSelect = (item: any, isSelected: boolean) => {
  const id = item.id as string
  // add in the id if its not already in the list
  if (isSelected && !selectedItems.includes(id)) {
    selectedItems.push(id)
  } else {
    // remove the id from the selected items
    const index = selectedItems.indexOf(id)
    if (index !== -1) {
      selectedItems.splice(index, 1)
    }
  }
}

// tell MediaItem to show the selected items
const onShowSelected = (show: boolean) => {
  showSelected.value = show
}

const resetControls = ref(0)
const resetSelectedItems = () => {
  selectedItems = []
  showSelected.value = false
  editMode.value = false
  resetControls.value++
}

const editMode = ref(false)
const uploadValue = ref('')
const onEditFiles = () => {
  console.log('Show: onEditFiles', selectedItems)
  if (selectedItems.length > 0) {
    editMode.value = true
    uploadValue.value = JSON.stringify(selectedItems)
  }
}
const onDeleteFiles = async () => {
  deleteProfileContent(nearWalletStore, [...selectedItems])
}

const onFilter = () => {
  console.log('Show: onFilter')
}
const onSave = async () => {
  // console.log('Show: onSave')
  loading.value = true
  await updateProfileContent(nearWalletStore, profile.value?.contents)
  loading.value = false
  resetSelectedItems()
}
const onCancel = () => {
  console.log('Show: onCancel')
  resetSelectedItems()
}

// update the object in profile with the same id from data
const onUploaded = (data: any) => {
  data = JSON.parse(data)
  data.forEach((updated: any) => {
    const index = profile.value?.contents.findIndex((item) => item.id === updated.id)
    if (index && index > -1 && profile.value) {
      profile.value.contents[index].name = updated.name
      profile.value.contents[index].short_description = updated.description
      profile.value.contents[index].tags = updated.tags
    }
  })
}

setTimeout(async () => {
  await loadProfile()
}, 10)
</script>

<template>
  <UserLayout selectedLinkId="briefs" paddingInner="m-0">
    <SpinnerOverlay v-if="loading" />
    <div v-else class="w-full pb-6 flex flex-col max-w-6xl mx-auto px-3">
      <!-- Bio Card -->
      <div v-if="profile" class="w-full mx-auto my-6">
        <BioCard :profile="profile" />
      </div>
      <!-- Upload Content -->
      <div
        class="w-full mx-auto my-0 mb-6 border-dashed border border-gray-300 rounded-md p-9"
        v-if="isOwner"
        v-show="!editMode"
        :key="iterationDebounced"
      >
        <ToggleContent
          :multiSelect="false"
          message="Add content to your portfolio"
          uploadField="portfolioItem"
          @onUploaded="onUploadContent"
        />
      </div>
      <!-- Edit Content -->
      <div v-if="editMode" class="uploads-edit-mode">
        <UploadList
          :accountId="nearWalletStore?.accountId"
          :editIDList="selectedItems"
          :profileData="profile"
          :showMeta="selectedItems.length == 1"
          @onSave="onSave"
          @onCancel="onCancel"
          @onUploaded="onUploaded"
        />
      </div>
      <!-- Content -->
      <div v-show="!editMode" class="w-full rounded-md border">
        <div class="p-4 flex flex-row space-x-10">
          <div class="w-full flex flex-col md:flex-row items-center justify-between px-3 mt-1.5">
            <RouterLink
              :to="{
                name: 'user.profile',
                params: {
                  username: $route.params.username,
                  tab: 'work'
                }
              }"
              class="text-md font-bold text-gray-600 cursor-pointer"
            >
              <button
                type="button"
                :class="{
                  'text-[#FF008B]': active_tab === 'work'
                }"
              >
                Portfolio <small>({{ profileContents.length - fileCount }} files)</small>
              </button>
            </RouterLink>
            <!-- Media Controls, delete, edit, etc -->
            <MediaControls
              :key="resetControls"
              :selectedItems="selectedItems"
              :isOwner="isOwner"
              :accountId="nearWalletStore?.accountId"
              @onCancel="onCancel"
              @onEditFiles="onEditFiles"
              @onDeleteFiles="onDeleteFiles"
              @onFilter="onFilter"
              @onShowSelected="onShowSelected"
            />
          </div>
        </div>
        <div class="mx-6 sm:mx-3">
          <div v-if="active_tab === 'work'" :key="profileContents.length">
            <WorkContents
              :contents="profileContents"
              :showSelected="showSelected"
              @onSelect="onSelect"
            />
          </div>
          <div v-if="active_tab === 'stock' && username">
            <StockContents :username="username" />
          </div>
        </div>
      </div>
    </div>
  </UserLayout>
</template>

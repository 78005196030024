<script lang="ts" setup>
import { computed } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import { onKeyDown } from '@vueuse/core'

const props = withDefaults(
  defineProps<{
    currentPage: number
    totalPages: number
    arrowPagination?: boolean
  }>(),
  {
    //
  }
)

const emit = defineEmits(['switchPage'])


const visiblePages = computed(() => {
  const maxVisiblePages = 5;
  const halfMax = Math.floor(maxVisiblePages / 2);
  let start = Math.max(1, props.currentPage - halfMax);
  let end = Math.min(props.totalPages, props.currentPage + halfMax);

  // Ensure we always show `maxVisiblePages` number of pages when possible
  if (props.currentPage <= halfMax) {
    end = Math.min(props.totalPages, maxVisiblePages);
  } else if (props.currentPage + halfMax >= props.totalPages) {
    start = Math.max(1, props.totalPages - maxVisiblePages + 1);
  }

  const pages = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }
  return pages;
})

// Page Navigation
const switchPage = useDebounceFn((page: number) => {
  emit('switchPage', page)
}, 50)

const prevPage = () => {
  if (props.currentPage > 1 && props.currentPage <= props.totalPages) {
    switchPage(props.currentPage - 1)
  }
}

const nextPage = () => {
  if (props.currentPage < props.totalPages) {
    switchPage(props.currentPage + 1)
  }
}

const goToFirstPage = () => {
  if (props.currentPage !== 1) {
    switchPage(1)
  }
}

const goToLastPage = () => {
  if (props.currentPage !== props.totalPages) {
    switchPage(props.totalPages)
  }
}

// Keyboard Navigation
onKeyDown(['ArrowLeft'], (e) => {
  if (props.arrowPagination) {
    prevPage()
  }
})

onKeyDown(['ArrowRight'], (e) => {
  if (props.arrowPagination) {
    nextPage()
  }
})
</script>

<template>
  <div v-if="totalPages > 1" class="flex justify-center pt-4 pb-2">
    <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
      <!-- Go to First Page -->
      <button
        :disabled="currentPage === 1"
        @click="goToFirstPage"
        class="relative inline-flex items-center px-2 py-2 ring-1 ring-inset focus:z-20 focus:outline-offset-0"
        :class="{
          'text-gray-400 bg-gray-100 cursor-not-allowed': currentPage === 1,
          'text-black bg-white hover:bg-gray-200': currentPage > 1
        }"
      >
        <div class="h-5 w-5 flex items-center justify-center">
          <span class="sr-only">First</span>
          <i class="fa text-xs fa-angle-double-left"></i>
        </div>
      </button>

      <!-- Previous -->
      <button
        :disabled="currentPage > 1 ? false : true"
        @click="prevPage"
        class="relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset focus:z-20 focus:outline-offset-0"
        :class="{
          'text-gray-400 bg-gray-100 cursor-not-allowed': currentPage <= 1,
          'text-black bg-white hover:bg-gray-200': currentPage > 1
        }"
      >
        <div class="h-5 w-5 flex items-center justify-center">
          <span class="sr-only">Previous</span>
          <i class="fa text-xs fa-chevron-left"></i>
        </div>
      </button>

      <!-- Pages -->
      <button
        v-for="page in visiblePages"
        :key="page"
        :class="{
          'relative inline-flex items-center px-4 py-2 text-sm font-semibold': true,
          'z-10  bg-[#ff008b]  text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ff008b]':
            page == currentPage,
          'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ':
            page != currentPage
        }"
        @click="($e) => switchPage(page)"
      >
        {{ page }}
      </button>

      <!-- Next Page -->
      <button
        :disabled="currentPage < totalPages ? false : true"
        @click="nextPage"
        class="relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset focus:z-20 focus:outline-offset-0"
        :class="{
          'text-gray-400 bg-gray-100 cursor-not-allowed': currentPage >= totalPages,
          'text-black bg-white hover:bg-gray-200': currentPage < totalPages
        }"
      >
        <div class="h-5 w-5 flex items-center justify-center">
          <span class="sr-only">Next</span>
          <i class="fa text-xs fa-chevron-right"></i>
        </div>
      </button>

      <!-- Go to Last Page -->
      <button
        :disabled="currentPage === totalPages"
        @click="goToLastPage"
        class="relative inline-flex items-center px-2 py-2 ring-1 ring-inset focus:z-20 focus:outline-offset-0"
        :class="{
          'text-gray-400 bg-gray-100 cursor-not-allowed': currentPage === totalPages,
          'text-black bg-white hover:bg-gray-200': currentPage < totalPages
        }"
      >
        <div class="h-5 w-5 flex items-center justify-center">
          <span class="sr-only">Last</span>
          <i class="fa text-xs fa-angle-double-right"></i>
        </div>
      </button>
    </nav>
  </div>
</template>

import * as eccrypto from 'eccrypto';
import { ec as EC } from 'elliptic';

export const xorEncrypt = (input: string, key: string) => {
  let output = ''
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i) ^ key.charCodeAt(i % key.length)
    output += String.fromCharCode(char)
  }
  return output
}

export const xorDecrypt = (input: string, key: string) => {
  return xorEncrypt(input, key) // XOR operation is symmetric
}

export async function importKeyBlob(rawKeyData: string) {
  const algorithm = { name: 'AES-CBC', length: 256 }
  const key = await window.crypto.subtle.importKey(
    'raw',
    base64ToArrayBuffer(rawKeyData),
    algorithm,
    true,
    ['decrypt', 'encrypt']
  )
  return key
}

export function base64ToArrayBuffer(base64: string) {
  const binary_string = window.atob(base64)
  const len = binary_string.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }
  return bytes.buffer
}

export function arrayBufferToBase64(buffer: any) {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

// Encrypt a blob using AES-CBC
export async function encryptBlob(key: CryptoKey, blob: any, iv: Uint8Array) {
  //const iv = window.crypto.getRandomValues(new Uint8Array(16));
  const chunks = Math.ceil(blob.size / 1024 / 1024) // 1 MB chunks
  const encryptedChunks = []

  // console.log(`Blob size before ${blob.size}`);

  for (let i = 0; i < chunks; i++) {
    const start = i * 1024 * 1024
    const end = Math.min(start + 1024 * 1024, blob.size)
    const chunk = blob.slice(start, end)
    const data = await chunk.arrayBuffer()

    // Pad the data to a multiple of the block size
    const blockSize = 16 // 16 bytes for AES-CBC
    const paddingLength = blockSize - (data.byteLength % blockSize)
    const paddedData = new Uint8Array(data.byteLength + paddingLength)
    paddedData.set(new Uint8Array(data))

    const encryptionAlgorithm = {
      name: 'AES-CBC',
      iv: iv
    }
    const encrypted = await window.crypto.subtle.encrypt(encryptionAlgorithm, key, data)
    encryptedChunks.push(encrypted)
    // console.log(`${paddedData.length} -> ${encrypted.byteLength}`);
  }

  const combined = combineArrayBuffers(encryptedChunks)
  return {
    chunk: encryptedChunks[0],
    data: new Blob([combined]),
    iv
  }
}

// Decrypt a blob using AES-CBC
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function decryptBlob(key: CryptoKey, blob: Blob, iv: any) {
  // console.log(`Blob size after ${blob.size}`);
  const chunks = Math.ceil(blob.size / 1048592) // 1 MB chunks
  const encryptedChunks = []

  // console.log({chunks});

  for (let i = 0; i < chunks; i++) {
    const start = i * 1048592
    const end = Math.min(start + 1048592, blob.size)
    const chunk1 = blob.slice(start, end)
    const data = await chunk1.arrayBuffer()

    const decryptionAlgorithm = {
      name: 'AES-CBC',
      iv: base64ToArrayBuffer(iv)
    }
    const encrypted = await window.crypto.subtle.decrypt(decryptionAlgorithm, key, data)
    encryptedChunks.push(encrypted)
    // console.log(`${data.byteLength} -> ${encrypted.byteLength}`)
  }
  const combined = combineArrayBuffers(encryptedChunks)
  return new Blob([combined])
}

// Combine an array of array buffers into a single buffer
function combineArrayBuffers(arrayBuffers: any[]) {
  const totalByteLength = arrayBuffers.reduce((acc: any, buf: { byteLength: any }) => acc + buf.byteLength, 0)
  const combinedBuffer = new ArrayBuffer(totalByteLength)
  // console.log({totalByteLength});
  let offset = 0
  arrayBuffers.forEach((buffer: any) => {
    const len = buffer.byteLength
    new Uint8Array(combinedBuffer).set(new Uint8Array(buffer), offset)
    offset += len
  })
  // console.log({combinedBuffer});
  return combinedBuffer
}

export async function generateKey() {
  return window.crypto.subtle.generateKey(
    {
      name: 'AES-CBC',
      length: 256
    },
    true,
    ['encrypt', 'decrypt']
  )
}

// Export a CryptoKey to base64
export async function exportKeyToBase64(key: CryptoKey) {
  const exportedKeyBuffer = await window.crypto.subtle.exportKey('raw', key)
  const exportedKeyArray = new Uint8Array(exportedKeyBuffer)
  const exportedKeyBase64 = btoa(String.fromCharCode(...exportedKeyArray))
  return exportedKeyBase64
}

export async function encryptString(key: CryptoKey, inputString: string | undefined, iv: Uint8Array) {
  // Convert the input string to an ArrayBuffer
  const encoder = new TextEncoder()
  const inputData = encoder.encode(inputString)

  // Encrypt the data
  const encryptedData = await window.crypto.subtle.encrypt({ name: 'AES-CBC', iv }, key, inputData)

  // Convert the ArrayBuffer to a Base64 string
  return arrayBufferToBase64(encryptedData)
}

export async function decryptString(key: CryptoKey, base64Ciphertext: any, iv: any) {
  // Convert the Base64 ciphertext to an ArrayBuffer
  const encryptedData = base64ToArrayBuffer(base64Ciphertext)

  // Decrypt the data
  const decryptionAlgorithm = {
    name: 'AES-CBC',
    iv: base64ToArrayBuffer(iv)
  }
  const decryptedData = await window.crypto.subtle.decrypt(decryptionAlgorithm, key, encryptedData)

  // Convert the decrypted ArrayBuffer back to a string
  const decoder = new TextDecoder()
  return decoder.decode(decryptedData)
}

// Function to get the private key (you already have this)
export const getPrivateKey = async (privateKeyHex: any): Promise<Buffer> => {
  return Buffer.from(privateKeyHex, 'hex');
};

// Function to derive the public key from the private key
export const getPublicKey = (privateKey: Buffer): Buffer => {
  const ec = new EC('secp256k1');
  const keyPair = ec.keyFromPrivate(privateKey);
  const publicKey = keyPair.getPublic('hex');
  return Buffer.from(publicKey, 'hex');
};

// Function to encrypt the message using the receiver's public key
export const encryptKey = async (message: string, publicKey: Buffer): Promise<Encrypted> => {
  const encrypted = await eccrypto.encrypt(publicKey, Buffer.from(message));
  return encrypted;
};

// Function to decrypt the message using the receiver's private key
export const decryptKey = async (encryptedData: Encrypted, privateKey: Buffer): Promise<string> => {
  const decrypted = await eccrypto.decrypt(privateKey, encryptedData);
  return decrypted.toString();
};

// Function to convert the encrypted data to a JSON string for storage
export const encryptedDataToJson = (encryptedData: Encrypted): string => {
  return JSON.stringify({
      iv: encryptedData.iv.toString('hex'),
      ephemPublicKey: encryptedData.ephemPublicKey.toString('hex'),
      ciphertext: encryptedData.ciphertext.toString('hex'),
      mac: encryptedData.mac.toString('hex')
  });
};

// Function to convert the JSON string back to encrypted data for decryption
export const jsonToEncryptedData = (jsonString: string): Encrypted => {
  const parsed = JSON.parse(jsonString);
  return {
      iv: Buffer.from(parsed.iv, 'hex'),
      ephemPublicKey: Buffer.from(parsed.ephemPublicKey, 'hex'),
      ciphertext: Buffer.from(parsed.ciphertext, 'hex'),
      mac: Buffer.from(parsed.mac, 'hex')
  };
};
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useHead } from '@unhead/vue'
import { RouterLink } from 'vue-router'
import { v4 as uuidv4 } from 'uuid'
import { useImageCDN } from '@/utils/image'

import PopupImage from '@media/PopupImage.vue'
import PageHeading from '@/components/PageHeading.vue'
import MediaItem from '@media/MediaItem.vue'

defineProps({
  //
})

const uuid = uuidv4()

const showPage = ref<boolean>(false)

const pageTitle = ref('Create your brief.')

useHead({
  title: pageTitle.value
})

const tileStyleObject = (item: { logo: string }) => {
  return {
    backgroundImage: `url("${item.logo}")`
  }
}

const items = ref<
  Array<{
    href: string
    image: string
    hls?: string
  }>
>(
  [
    {
      href: '#',
      image: 'https://cmg-create-brief-assets.b-cdn.net/summer-2022-11-15-18-21-50-utc.jpg'
    },
    {
      href: '#',
      image:
        'https://cmg-create-brief-assets.b-cdn.net/fresh-fruit-platter-with-pineapple-watermelon-and-2022-11-15-18-10-30-utc.jpg'
    },
    {
      href: '#',
      image:
        'https://cmg-create-brief-assets.b-cdn.net/bmx-stunt-and-jump-riding-in-a-hall-with-sunlight-2021-08-29-00-18-55-utc.jpg'
    },
    {
      href: '#',
      image:
        'https://cmg-create-brief-assets.b-cdn.net/woman-smears-her-hands-with-cream-uses-hand-crea-2022-11-15-05-22-25-utc.jpg'
    },
    {
      href: '#',
      image:
        'https://cmg-create-brief-assets.b-cdn.net/friends-hanging-out-in-the-city-2022-12-16-00-44-58-utc.jpg'
    },
    {
      href: '#',
      image:
        'https://cmg-create-brief-assets.b-cdn.net/young-black-man-wearing-casual-clothes-jumping-out-2021-08-26-19-59-41-utc.jpg'
    },
    {
      href: '#',
      image: 'https://vz-cb44d77f-99b.b-cdn.net/252cd846-c9a6-4679-a311-883af1672606/thumbnail.jpg',
      hls: 'https://vz-cb44d77f-99b.b-cdn.net/252cd846-c9a6-4679-a311-883af1672606/playlist.m3u8'
    },
    {
      href: '#',
      image:
        'https://cmg-create-brief-assets.b-cdn.net/woman-walks-the-streets-of-chicago-2021-08-26-18-30-38-utc.jpg'
    },
    {
      href: '#',
      image:
        'https://cmg-create-brief-assets.b-cdn.net/friends-hanging-out-in-los-angeles-2022-12-16-00-49-29-utc.jpg'
    },
    {
      href: '#',
      image: 'https://cmg-create-brief-assets.b-cdn.net/tropical-beach-2022-11-12-11-18-02-utc.jpg'
    },
    {
      href: '#',
      image:
        'https://cmg-create-brief-assets.b-cdn.net/tropical-landscape-2021-08-26-16-16-20-utc.jpg'
    },
    {
      href: '#',
      image:
        'https://cmg-create-brief-assets.b-cdn.net/street-fashion-of-young-black-people-2022-01-20-23-21-49-utc.jpg'
    },
    {
      href: '#',
      image:
        'https://cmg-create-brief-assets.b-cdn.net/tanned-inspired-woman-in-trendy-wristwatch-posing-2021-09-02-14-59-23-utc.jpg'
    },
    {
      href: '#',
      image:
        'https://cmg-create-brief-assets.b-cdn.net/african-american-male-model-wearing-trendy-sunglas-2021-12-09-18-51-25-utc.jpg'
    },
    {
      href: '#',
      image:
        'https://cmg-create-brief-assets.b-cdn.net/stylish-african-american-man-in-sunglasses-riding-2022-11-12-01-54-39-utc.jpg'
    },
    {
      href: '#',
      image: 'https://vz-cb44d77f-99b.b-cdn.net/a9f77d21-24eb-45a4-9118-616c55a3b15e/thumbnail.jpg',
      hls: 'https://vz-cb44d77f-99b.b-cdn.net/a9f77d21-24eb-45a4-9118-616c55a3b15e/playlist.m3u8'
    },
    {
      href: '#',
      image:
        'https://cmg-create-brief-assets.b-cdn.net/fashion-lifestyle-beautiful-young-woman-with-long-2021-08-26-15-54-44-utc.jpg'
    },
    {
      href: '#',
      image: 'https://vz-cb44d77f-99b.b-cdn.net/d7f7e03a-7866-40ae-ad0d-3f5bba486fb2/thumbnail.jpg',
      hls: 'https://vz-cb44d77f-99b.b-cdn.net/d7f7e03a-7866-40ae-ad0d-3f5bba486fb2/playlist.m3u8'
    },
    {
      href: '#',
      image: 'https://vz-cb44d77f-99b.b-cdn.net/772bd8bb-4bb8-464f-a048-dd6ea352b069/thumbnail.jpg',
      hls: 'https://vz-cb44d77f-99b.b-cdn.net/772bd8bb-4bb8-464f-a048-dd6ea352b069/playlist.m3u8'
    },
    {
      href: '#',
      image:
        'https://cmg-create-brief-assets.b-cdn.net/stylish-woman-in-hat-standing-in-tropical-resort-2023-08-17-18-35-31-utc.jpg'
    },
    {
      href: '#',
      image: 'https://vz-cb44d77f-99b.b-cdn.net/85f37919-2cd2-424f-95bf-ffe6ef2825aa/thumbnail.jpg',
      hls: 'https://vz-cb44d77f-99b.b-cdn.net/85f37919-2cd2-424f-95bf-ffe6ef2825aa/playlist.m3u8'
    },
    {
      href: '#',
      image: 'https://vz-cb44d77f-99b.b-cdn.net/222b9ae5-5067-48a3-8311-32d1c9045309/thumbnail.jpg',
      hls: 'https://vz-cb44d77f-99b.b-cdn.net/222b9ae5-5067-48a3-8311-32d1c9045309/playlist.m3u8'
    },
    {
      href: '#',
      image: 'https://vz-cb44d77f-99b.b-cdn.net/ce7ea74c-41cd-4815-bde7-a6a5b647a4c5/thumbnail.jpg',
      hls: 'https://vz-cb44d77f-99b.b-cdn.net/ce7ea74c-41cd-4815-bde7-a6a5b647a4c5/playlist.m3u8'
    },
    {
      href: '#',
      image:
        'https://cmg-create-brief-assets.b-cdn.net/rapper-dance-on-the-street-trendy-lifestyle-2021-08-26-16-25-37-utc.jpg'
    }
  ]
    //    .filter((item) => !item?.hls)
    .map((item) => ({
      ...item,
      image: useImageCDN(item.image, 'medium', true)
    }))
)

// keep a global list so that the popup can go prev/next
if (typeof window.carousels === 'undefined') {
  window.carousels = {}
}
window.carousels[uuid] = items.value

const chunks = 3
const chunkSize = Math.floor(items.value.length / chunks)

onMounted(() => {
  if ((window.location.host || '').match(/^app\.contented(world|media)\.com$/)) {
    window.location.href = 'https://contentedworld.com'
  } else {
    showPage.value = true
  }
})
</script>

<template>
  <div v-if="showPage">
    <PopupImage />

    <div>
      <header class="bg-cm-blue-500 shadow">
        <PageHeading :title="pageTitle" />
      </header>
      <main>
        <div class="max-w-screen-2xl mx-auto bg-white">
          <div class="flex">
            <div
              class="flex-grow w-full bg-gradient-to-r from-[#003E9B] to-[#A901B8] bg-cover bg-no-repeat bg-center"
              :style="{
                backgroundImage: 'url(\'/images/create-brief-page/hero.png\')'
              }"
            >
              <div class="mx-auto max-w-screen-2xl">
                <div class="text-center w-full my-28">
                  <div class="my-9 text-white">
                    <h1 class="font-extrabold tracking-wide text-5xl montserrat">
                      {{ pageTitle }}
                    </h1>
                  </div>
                  <div class="my-9 text-white text-lg font-normal roboto">
                    <p>
                      Start now by creating your first Smart Brief. Our associates combined <br />
                      with a sprinkling of AI will make sure it's matched with the best<br />
                      creators from around the world!
                    </p>
                  </div>
                  <div class="my-6">
                    <router-link
                      :to="{
                        name: 'marketplace.create-brief'
                      }"
                    >
                      <button
                        class="bg-[#FF008B] py-3 px-6 rounded-full text-white font-semibold tracking-wider hover:opacity-95"
                      >
                        Create a Brief
                      </button>
                    </router-link>
                  </div>
                </div>
                <div class="w-full mx-auto">
                  <div class="flex flex-wrap items-center justify-center mb-6 md:mb-0">
                    <div
                      v-for="item in [
                        {
                          label: 'Converse',
                          logo: '/images/create-brief-page/converse.png',
                          href: ''
                        },
                        {
                          label: 'Oculus',
                          logo: '/images/create-brief-page/oculus.png',
                          href: '/'
                        },
                        {
                          label: 'Prada',
                          logo: '/images/create-brief-page/prada.png',
                          href: '/'
                        }
                      ]"
                      :key="item.label"
                      class="w-full md:w-1/3 max-w-sm mb-6 md:-mb-6"
                    >
                      <RouterLink :to="item.href">
                        <div class="mx-3 bg-gray-200">
                          <div>
                            <div
                              class="h-[200px] w-full bg-cover bg-no-repeat bg-center"
                              :style="tileStyleObject(item)"
                            ></div>
                          </div>
                          <div
                            class="w-full text-white font-semibold tracking-wide bg-[#FF008B] bg-gradient-to-r from-[#FF008B] to-[##FF00C7] px-3 py-3 text-center"
                          >
                            {{ item.label }}
                          </div>
                        </div>
                      </RouterLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="h-6">&nbsp;</div>
          <div class="flex">
            <div class="w-full text-center my-12">
              <h1 class="text-[#757575] font-bolder tracking-wide text-3xl montserrat font-bold">
                Who will you engage today?
              </h1>
            </div>
          </div>
          <div class="w-full px-3 -mt-6" :data-carousel-id="uuid">
            <div class="flex flex-wrap justify-center mx-auto">
              <div class="w-full sm:w-1/2 md:w-1/3 max-w-sm">
                <div
                  v-for="item in items.slice(chunkSize * 0, chunkSize * 1)"
                  :key="item.image"
                  class="block mx-3 my-6"
                >
                  <MediaItem :item="item" :imageCss="['w-full h-auto']" :videoCss="['h-auto']" />
                </div>
              </div>
              <div class="w-full sm:w-1/2 md:w-1/3 max-w-sm">
                <div
                  v-for="item in items.slice(chunkSize * 1, chunkSize * 2)"
                  :key="item.image"
                  class="block mx-3 my-6"
                >
                  <MediaItem :item="item" :imageCss="['w-full h-auto']" :videoCss="['h-auto']" />
                </div>
              </div>
              <div class="w-full sm:w-1/2 md:w-1/3 max-w-sm">
                <div
                  v-for="item in items.slice(chunkSize * 2, chunkSize * 3)"
                  :key="item.image"
                  class="block mx-3 my-6"
                >
                  <MediaItem :item="item" :imageCss="['w-full h-auto']" :videoCss="['h-auto']" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watchEffect, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import { isVideo, getFileIcon } from '@/utils/mediaFileTypes'
import { upgradeUrlMeta } from '@/utils/mediaDecrypt'
import { humanFileSize } from '@/utils/media'

import { initUppy, getUppyInstance, clearUppyInstance } from '@/utils/uppy'
import { useNearWalletStore } from '@/stores/near-wallet'
import { storeToRefs } from 'pinia'

import { processSmartBriefImage, processSmartBriefVideo } from '@/utils/postContent'

import MediaItem from '@media/MediaItem.vue'
import ToggleFade from '@/components/ToggleFade.vue'
import UploadFileMeta from '@upload/UploadFileMeta.vue'
import Loading from '@/components/Loading.vue'

const nearWalletStore = useNearWalletStore()
const { accountId } = storeToRefs(nearWalletStore)

import { v4 as uuidv4 } from 'uuid'
import { reset } from '@formkit/core'
import exifr from 'exifr'
import { getTitle, getDescription, getKeywords } from '@/utils/nptc';

const emit = defineEmits(['onUploaded', 'onDelete', 'onMetaDataSaved', 'onCancel'])

const props = withDefaults(
  defineProps<{
    multiSelect?: boolean
    uploadId: string
    uploadValue: string
    uploadAccountId?: string // temporary account ID if we are not logged in
    uploadDocumentsOnly?: boolean
    uploadImagesOnly?: boolean
    uploadMediaOnly?: boolean
    uploadType?: string
    uploadBriefOwnerId?: string
    showMeta?: boolean
    showIcons?: boolean
  }>(),
  {
    multiSelect: false, // return array of files
    uploadField: '',
    uploadValue: '',
    uploadAccountId: '',
    uploadDocumentsOnly: false,
    uploadImagesOnly: false,
    uploadMediaOnly: false, // images and videos
    uploadType: '',
    uploadBriefOwnerId: '',
    showMeta: false,
    showIcons: false
  }
)

const $route = useRoute()

// less padding on this page
const isEdit = ref($route.name === 'profiles.self.uploads')

// list of files
const values = ref()

const file_upload_error = ref('')
const showFileUpload = ref(true)
const showLoading = ref(false)

const uppyId: string = props.uploadId?.replace(/\./g, '_')

let uppyInstance: any = false

let imageBucket: string = ''
let imageBucketHostCDN: string = ''
let BunnyVideoId: string = ''
let BunnyLibraryId: string = ''

function getBunnyStreamUrl() {
  return `https://iframe.mediadelivery.net/play/${BunnyLibraryId}/${BunnyVideoId}`
}

function convertToProfilesUrl(urlString = '', fileName = '') {
  // return the url if the url is tustemp
  if (urlString.includes('tustemp')) {
    return urlString
  }

  // return the url if the url is a blob url
  const isBlobUrl = urlString && urlString.startsWith('blob:')
  if (isBlobUrl) {
    return urlString
  }

  if (!imageBucket) {
    console.error("Failed to convert to Profiles URL - set Image Bucket URL's first!")
    return
  }

  const segments = urlString.split('/')
  const lastSegment = segments.pop() || ''
  const fileExtension = fileName.includes('.') ? '.' + fileName.split('.').pop() : ''
  const bucketId = imageBucket.split('/profiles/')?.pop() || ''

  return `https://${imageBucketHostCDN}/profiles/${bucketId}/${lastSegment}${fileExtension}`
}

let isTemp = ref(true)
let oldAccountID: string = ''

const checkAccountID = () => {
  let uploadAccountId = accountId.value
  if (!uploadAccountId && props.uploadAccountId) {
    uploadAccountId = props.uploadAccountId
  }

  // isTemp
  if (uploadAccountId.match('temporary-') !== null) {
    isTemp.value = true
  } else {
    isTemp.value = false
  }

  // real or temporary account ID required
  if (!uploadAccountId || (!accountId.value && !isTemp.value)) {
    // console.error('UploadUppy : Not logged in, no temp accountId passed, or bad format')
    // console.error("UploadUppy : use :uploadAccountId='temporary-' + uuidv4()'")
    return
  }
  return uploadAccountId
}

const resetUppyInstance = () => {
  console.log('resetUppyInstance', uppyId)
  clearUppyInstance(uppyId)
  uppyInstance = null
  waitForUppy()
}

const waitForUppy = () => {
  // console.log('UploadUppy : waitForUppy...', uppyId)

  // no need to show the uploader
  if (showFileUpload.value === false) {
    // console.info('UploadUppy : waitForUppy..., no need to show it')
    return
  }

  // use logged in value if possible, if not passed in if possible
  const uploadAccountId = checkAccountID()

  // create if it doesn't exist
  if (!uppyInstance && uploadAccountId && uppyId && showFileUpload.value) {
    setTimeout(() => {
      initUppy(uploadAccountId, uppyId, props, (file: any) => {
        onSubmit(file)
      })
    }, 1000)
  }

  // store ref once it's created
  const res = getUppyInstance(uppyId)

  if (!uppyInstance && res.uppyInstance) {
    uppyInstance = res.uppyInstance
  }

  if (!uppyInstance) {
    setTimeout(() => {
      waitForUppy()
    }, 100)
  }
}

const editID = ref()
const editIndex = ref()
const editFile = (index: number) => {
  showFileUpload.value = true
  // remember we are replacing this item
  editID.value = values.value[index].id
  editIndex.value = index
  // reset meta data
  values.value[index] = {}
  // tell other components
  emit('onMetaDataSaved', editIndex.value, false)
  // reset uppy to have the correct callback
  setTimeout(() => {
    resetUppyInstance()
  }, 1000)
}

const submitJSON = () => {
  // clear edit state
  editID.value = ''
  editIndex.value = ''
  console.log('UploadUppy: onUploaded', values.value)
  emit('onUploaded', JSON.stringify(values.value))
}

async function extractExif(fileData: Blob) {
  try {
      // Extract EXIF metadata from the Blob
      const exifData = await exifr.parse(fileData);
      return exifData;
  } catch (error) {
      console.error('Error extracting EXIF data:', error);
  }
}

// NOTE: hard to find bug was in here
//
// This function is passed to the uppy instance on create and variables it uses are not reactive,
// so if you change a variable used in this function you need to resetUppyInstance to pass this
// callback correctly to the uppy on complete handler
const onSubmit = async (files?: any) => {
  // these only get set after a file is chosen
  const res = getUppyInstance(uppyId)

  if (res.imageBucket && res.imageBucketHostCDN.value) {
    // image uploads
    imageBucket = res.imageBucket.value
    imageBucketHostCDN = res.imageBucketHostCDN.value
    // console.info('onSubmit imageBucket', imageBucket)
    // console.info('onSubmit imageBucketHostCDN', imageBucketHostCDN)
  }

  if (res.BunnyVideoId && res.BunnyLibraryId.value) {
    // video uploads
    BunnyVideoId = res.BunnyVideoId.value
    BunnyLibraryId = res.BunnyLibraryId.value
    // console.info('onSubmit BunnyVideoId', res.BunnyVideoId.value)
    // console.info('onSubmit BunnyLibraryId', res.BunnyLibraryId.value)
  }

  file_upload_error.value = ''

  if (files.successful.length === 0) {
    file_upload_error.value = 'No files were uploaded'
    return
  }

  if (!values.value) {
    values.value = []
  }

  for (const file of files.successful) {
    // console.info('UploadUppy : onSubmit', file)
    let file_url = file.uploadURL
    if (!isTemp.value) {
      if (isVideo(file.type)) {
        file_url = getBunnyStreamUrl()
      } else {
        file_url = convertToProfilesUrl(file_url, file.name)
      }
    }

    // extract metadata from file
    const fileExifData = await extractExif(file.data)
    // console.log('fileExifData: ', fileExifData)
    const fileName = getTitle(fileExifData) || (file.name ? file.name.replace(/\.(jpeg|jpg|gif|png|mov|mp4|mpeg|mpg)$/, '') : '');
    let fileDescription = getDescription(fileExifData) || '';
    const fileTags = getKeywords(fileExifData) || '';

    // upload file to IPFS in case of content submission
    if (props.uploadType && props.uploadType === 'content') {
      showLoading.value = true
      let ipfsResult: any = {}
      if (isVideo(file.type)) {
        ipfsResult = await processSmartBriefVideo(
          file.data,
          file.name,
          file.extension,
          props.uploadBriefOwnerId,
          (message) => {
            console.log(message)
          }
        )
      } else {
        ipfsResult = await processSmartBriefImage(
          file.data,
          file.name,
          file.extension,
          props.uploadBriefOwnerId,
          (message) => {
            console.log(message)
          }
        )
      }
      values.value.push({
        id: '',
        name: fileName,
        description: fileDescription,
        file_url: file_url,
        filetype: ipfsResult?.media_type,
        tags: fileTags,
        ipfs_file_url: ipfsResult?.file_url,
        ipfs_watermark_file_url: ipfsResult?.watermark_file_url,
        ipfs_media_type: ipfsResult?.media_type,
        key: ipfsResult?.key,
        iv: ipfsResult?.iv,
        key_brief_owner: ipfsResult?.key_brief_owner
      })
      showLoading.value = false
    } else {
      // TUS upload
      console.log('UploadUppy : onSubmit', file)
      if (file.source === 'Unsplash') {
        fileDescription = 'A photo from Unsplash by ' + file.meta.authorName + ' ' + file.meta.authorUrl
      }

      // update or add to the list
      const data = {
        id: editID.value ? editID.value : uuidv4(),
        name: fileName,
        description: fileDescription,
        file_url: file_url || file.uploadURL,
        tags: fileTags,
        ipfs_file_url: '',
        ipfs_watermark_file_url: '',
        ipfs_media_type: '',
        fileicon: getFileIcon(file.type),
        filename: file.name,
        filesize: file.size,
        filesizehuman: humanFileSize(file.size),
        filetype: file.type || file.meta.type,
        lastmodified: file.data.lastModified,
        lastmodifiedhuman: new Date(file.data.lastModified).toLocaleString()
      }

      // update the image, add to the list
      if (editID.value) {
        values.value[editIndex.value] = data
      } else {
        values.value.push(data)
      }
    }
  }

  if (props.uploadType && props.uploadType === 'content') {
    // Do not submit in case of content, submit will be done after adding name/description
  } else {
    // close the file uploader, click the done button
    const el = document.getElementsByClassName('uppy-StatusBar-actionBtn--done')
    if (el.length === 1) {
      ;(el[0] as HTMLElement).click()
    }
    submitJSON()
    resetUppyInstance()
    showFileUpload.value = false
  }
}

const deleteFile = (index: number) => {
  values.value.splice(index, 1)
  clearUppyInstance(uppyId)
  uppyInstance = null
  // tell the parent component that the file has been deleted
  submitJSON()
  // reset the uploader
  resetUppyInstance()
}

// update the file list with the new metadata
const metaDataSaved = ref(false)
const onMetaDataSaved = (index: number, saved: boolean, metaData: any) => {
  // console.log('UploadUppy: onMetaDataSaved', saved, metaData)
  // dont overwrite id etc
  values.value[index].name = metaData.name
  values.value[index].description = metaData.description
  values.value[index].tags = metaData.tags
  // data is always sent by onUploaded
  submitJSON()
  emit('onMetaDataSaved', saved)
}

// we can't let the props change because it will re-draw the component and trash the uppy dashboard,
// so we need to store the decoded value locally
let decoded: boolean = false

// if initial value is passed in later, in multiSelect mode, show the file upload always
watchEffect(() => {
  // update uppy instance with new account ID to use bunny for uploads
  const uploadAccountId = checkAccountID()

  // unpack JSON encoded data, do this once only
  if (props.uploadValue && !decoded) {
    decoded = true
    const { decode } = upgradeUrlMeta(props.uploadValue)
    if (decode) {
      values.value = decode
    }
  }

  // will always show in multi mode
  if (props.multiSelect || !values.value?.[0]?.file_url || values.value.length === 0) {
    showFileUpload.value = true
  } else {
    showFileUpload.value = false
  }

  // if the account id has changed we need to recreate the uppy instance
  if (oldAccountID && uploadAccountId !== oldAccountID) {
    console.log('uploadAccountId changed', uploadAccountId, oldAccountID)
    oldAccountID = accountId.value
    // we need to reset the uppy instance because it will now upload to a different endpoint and accept different files
    resetUppyInstance()
  }
  // make sure the uploader is ready
  else if (showFileUpload.value) {
    waitForUppy()
  }
})

const date = new Date().toISOString()

const debug = ref(false)
</script>

<template>
  <!-- debug -->
  <div v-if="debug" class="relative max-w-fit overflow-x-scroll mx-3 mt-6">
    <div class="w-full text-xs rounded-md p-2 bg-blue-200 overflow-x">
      <pre>
<b>UPLOADUPPY: {{ date }} {{ uppyId }}</b><br>
editId {{ editID }} editIndex [{{ editIndex }}] showFileUpload {{ showFileUpload }} metadataSaved {{ metaDataSaved }}
isTemp [{{ isTemp }}] accountId [{{ accountId }}]
props {{ props }}
values {{ values }}
      </pre>
    </div>
  </div>
  <div class="w-full md:max-w-2xl mx-auto">
    <!-- keep track of the edit state -->
    <ToggleFade :show="showFileUpload">
      <div
        :id="uppyId"
        class="uppy-dash my-6"
        :class="{
          'mb-0': isEdit
        }"
      ></div>
      <Loading v-if="showLoading" message="Processing media, please wait..." :vertical="true" />
    </ToggleFade>
    <div v-if="values && !editID">
      <div v-for="(file, index) in values" :key="index">
        <div class="flex flex-wrap">
          <div class="flex-none w-full">
            <div class="relative w-full mb-6 media-container">
              <!-- delete button -->
              <div
                @click="deleteFile(index)"
                class="absolute z-10 top-2 right-2"
              >
                <div
                  class="flex items-center justify-center rounded-md cursor-pointer w-[30px] h-[30px]"
                  style="background-color: rgb(0, 0, 0, 0.25)"
                >
                  <i class="fa fa-xmark text-white"></i>
                </div>
              </div>
              <!-- media thumb -->
              <MediaItem
                :item="file"
                :imageCss="['w-full', 'h-auto']"
                :videoCss="['min-w-[360px]', 'h-auto']"
                :showIcons="showIcons"
                @onEdit="editFile(index)"
                @onDelete="deleteFile(index)"
              />
            </div>
          </div>
          <div class="grow" v-if="!showFileUpload">
            <UploadFileMeta
              :file="file"
              :showMeta="uploadType === 'content' ? true : showMeta"
              @onMetaDataSaved="
                (saved: boolean, data: any) => {
                  onMetaDataSaved(index, saved, data)
                }
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.uppy-Dashboard {
  font-family: 'Roboto';
  .uppy-Dashboard-inner {
    .uppy-Dashboard-innerWrap {
      @apply w-full;
      .uppy-Dashboard-AddFiles {
        @apply flex items-center justify-center;
        .uppy-Dashboard-AddFiles-title {
          @apply p-0 m-0;
        }
      }
    }
  }
}
</style>

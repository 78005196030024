<script lang="ts" setup>
import { computed, ref, type PropType } from 'vue'
import { decryptSmartBriefImage } from '@/utils/postContent'
import { useNearWalletStore } from '@/stores/near-wallet'
import { storeToRefs } from 'pinia'
import { useUcanStore } from '@/stores/ucan'

const nearWalletStore = useNearWalletStore()
const { accountId } = storeToRefs(nearWalletStore)
const ucanStore = useUcanStore()

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  brief: Object as PropType<SmartBrief>
})

const downloading = ref(false)

const shouldShow = computed(() => {
  const propsItem = props.item || {}
  return (
    propsItem.brief_id &&
    propsItem.file_url &&
    propsItem.key &&
    propsItem.iv &&
    propsItem.media_type
  )
})

const download = async (item: any, accountId: string) => {
  if (downloading.value) return
  downloading.value = true

  try {
    const { s64, msg } = ucanStore.createApiChallenge()
    const fileBlob = await decryptSmartBriefImage(
      accountId === props.brief?.owner ? '' : item.id,
      item.brief_id,
      item.file_url,
      item.key,
      item.iv,
      item.media_type,
      accountId,
      ucanStore.getApiBaseUrl(),
      s64,
      msg,
      /* eslint-disable @typescript-eslint/no-unused-vars */
      (message) => {
        //console.log(message);
      }
    )

    const fileName = [
      item.title || 'file',
      '-',
      new Date().getTime(),
      '.',
      item.media_type.replace(/(image|video)\//g, '')
    ].join('')

    // create download link
    const url = URL.createObjectURL(fileBlob!)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName

    // trigger download
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } catch (err) {
    //
  }

  downloading.value = false
}
</script>

<template>
  <button
    type="button"
    @click.prevent.stop="download(props.item, accountId)"
    :class="{
      'opacity-50 cursor-wait': downloading
    }"
    title="Download"
    v-show="shouldShow"
  >
    <i class="fa fa-cloud-arrow-down"></i>
  </button>
</template>

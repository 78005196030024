<script lang="ts" setup>
import { Styles } from '@/styles'

defineProps({
  modelValue: String,
  name: {
    type: String,
    default: 'search'
  }
})

const emit = defineEmits(['update:modelValue'])

const onInputChange = (event: Event) => {
  const elInput = event.target as HTMLInputElement
  emit('update:modelValue', elInput?.value)
}
</script>

<template>
  <div class="group flex items-center">
    <span class="pb-2 pt-2 px-3 rounded-l-md border border-r-0 border-gray-300 bg-white">
      <i class="fal fa-search text-sm"></i>
    </span>
    <input
      type="text"
      :name="name"
      placeholder="Search..."
      :class="Styles.input_search"
      class="cypress-search-input"
      :value="modelValue"
      @input="onInputChange"
    />
  </div>
</template>

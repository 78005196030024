<script lang="ts" setup>
import { ref, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { createProfileRecordIfNonExistent } from '@/utils/storeProfile.js'
import { useNearWalletStore } from '@/stores/near-wallet'
import { getTeamInvite, handleTeamInvite } from '@/queries/api'
import { AxiosError } from 'axios'
import Auth from '@/components/Pages/Creatives/Wizard/Shared/Auth.vue'

const props = withDefaults(
  defineProps<{
    type: 'uuid' | 'nickname'
    typeValue: string
    team: Team
  }>(),
  {
    type: 'uuid',
    typeValue: ''
  }
)

const $route = useRoute()
const $router = useRouter()

const nearWalletStore = useNearWalletStore()

const loading = ref<boolean>(true)
const invite = ref<TeamInvite>()
const errorMessage = ref<string>()

const handleNetworkError = (err?: any) => {
  return err instanceof AxiosError && err.response?.data?.message
    ? err.response.data.message
    : 'Unable to load this Invite, please check again try again.'
}

const loadInvite = async () => {
  loading.value = true
  try {
    if ($route.query.id) {
      invite.value = await getTeamInvite(
        props.type,
        props.typeValue,
        String($route.query.id),
        String($route.query.token)
      )
    }
  } catch (err) {
    errorMessage.value = handleNetworkError(err)
  }
  loading.value = false
}

watchEffect(async () => {
  if (nearWalletStore?.accountId) {
    await createProfileRecordIfNonExistent(nearWalletStore.accountId)
    await loadInvite()
  }
})

const inviteHandler = async (inviteUuid: string, action: 'accept' | 'reject') => {
  try {
    // handle the team invite
    await handleTeamInvite(
      props.type,
      props.typeValue,
      inviteUuid,
      action,
      String($route.query.token)
    )

    // redirect to teams manage page
    $router.push({
      name: 'teams.show',
      params: {
        nickname: props.team.nickname
      }
    })
  } catch (err) {
    errorMessage.value = handleNetworkError(err)
  }
}
</script>

<template>
  <div class="px-6 py-6 mx-auto max-w-md border rounded-md bg-white">
    <Auth
      title="Login is required for this action"
      description="Please login or register to continue."
      :wrapperMarginTop="'mt-0'"
    >
      <div v-if="loading">
        <p>Loading...</p>
      </div>
      <div v-else-if="errorMessage">
        {{ errorMessage }}
      </div>
      <div v-else-if="invite">
        <div class="mb-4 flex">
          <p>
            You have been invited to join the <strong>{{ team.name }}</strong> team.
          </p>
        </div>
        <div class="flex space-x-4">
          <button
            @click="inviteHandler(invite.uuid, 'accept')"
            class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Accept
          </button>
          <button
            @click="inviteHandler(invite.uuid, 'reject')"
            class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Reject
          </button>
        </div>
      </div>
    </Auth>
  </div>
</template>

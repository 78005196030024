<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { RouterLink } from 'vue-router'
import { useNearWalletStore } from '@/stores/near-wallet'
import { fetchBrief } from '@/queries/api'

import Wrapper from '@/components/Pages/Creatives/Wizard/Shared/Wrapper.vue'
import AuthWrapper from '@/components/Pages/Creatives/Wizard/Shared/Auth.vue'
import FollowSocialLinks from '@/components/FollowSocialLinks.vue'
import SurveyRating from '@/components/SurveyRating.vue'

const nearWalletStore = useNearWalletStore()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = withDefaults(
  defineProps<{
    briefId?: string
    onDone?: () => void | undefined
  }>(),
  {
    briefId: undefined,
    onDone: (): void => {}
  }
)

const brief = ref<SmartBrief>()
onMounted(async () => {
  brief.value = await fetchBrief(props.briefId!)
})
</script>

<template>
  <Wrapper>
    <AuthWrapper title="Thank you" description="Before continuing please login or register.">
      <div>
        <h2 class="font-bold tracking-wide text-lg">
          Thanks for applying to the
          <span v-if="brief?.brandName">{{ brief.brandName }}</span> brief!
        </h2>
      </div>
      <div>
        <p class="text-sm text-[#3F3F46]">
          The brand will now review your submission and email you to let you know if they would like
          you to work on this brief.
        </p>
      </div>
      <div class="flex justify-center my-6">
        <RouterLink
          :to="{
            name: 'user.profile',
            params: {
              username: nearWalletStore.accountId
            }
          }"
        >
          <button
            type="button"
            class="py-3 px-8 min-w-28 border-2 font-bold border-[#FF008B] text-white bg-[#FF008B] rounded-full hover:opacity-85"
          >
            View profile
          </button>
        </RouterLink>
      </div>
      <div class="mt-12">
        <SurveyRating
          class="max-w-[360px] mx-auto"
          :id="{
            production: '018ff1e1-9155-0000-978b-5e79f8a5b294',
            uat: '018ff1e1-5283-0000-6511-bfa500d37f60',
            preview: '018fe84e-589f-0000-fd6b-b0c5c41dd36e'
          }"
        />
      </div>
      <div class="flex flex-col justify-center my-6">
        <h2>Why not:</h2>
        <div class="w-full block text-center">
          <!-- <button
            type="button"
            class="mx-3 py-3 px-8 min-w-28 border-2 text-sm font-bold border-[#FF008B] text-[#FF008B] rounded-full hover:text-white hover:bg-[#FF008B]"
          >
            Share this brief
          </button> -->
          <RouterLink
            :to="{
              name: 'briefs.list'
            }"
          >
            <button
              type="button"
              class="mx-3 py-3 px-8 min-w-28 border-2 text-sm font-bold border-[#FF008B] text-[#FF008B] rounded-full hover:text-white hover:bg-[#FF008B]"
            >
              View more briefs
            </button>
          </RouterLink>
        </div>
      </div>
      <div class="flex flex-col justify-center my-6">
        <FollowSocialLinks />
      </div>
    </AuthWrapper>
  </Wrapper>
</template>

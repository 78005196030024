<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue'
import { fetchProfile } from '@/queries/api'
import { useNearWalletStore } from '@/stores/near-wallet'
import ProfileAvatar from '@/components/ProfileAvatar.vue'
import BriefProfileSocials from '@/components/Pages/Briefs/BriefProfileSocials.vue'

export interface Props {
  profile?: ProfileData
  profileId?: string
}

const props = withDefaults(defineProps<Props>(), {})

const nearWalletStore = useNearWalletStore()

const data = ref<ProfileData>()

watchEffect(async () => {
  if (props.profile?.id) {
    data.value = props.profile
  } else if (props.profileId) {
    data.value = await fetchProfile(props.profileId)
  } else if (nearWalletStore?.accountId) {
    data.value = await fetchProfile(nearWalletStore.accountId)
  }
})

const name = computed(() =>
  [data.value?.firstName, data.value?.lastName]
    .filter((name) => name)
    .join(' ')
    .trim()
)

const location = computed(() => [data.value?.city, data.value?.country].join(', '))
</script>

<template>
  <div class="mx-auto bg-white border rounded-md flex flex-col md:flex-row px-6 py-6">
    <div class="flex flex-col items-center justify-center w-full md:w-1/2">
      <div class="sm:flex sm:items-center my-3">
        <div class="text-center">
          <h2 class="text-2xl font-semibold tracking-wider">
            {{ name }}
          </h2>
          <div class="text-sm">
            <p class="text-gray-600" v-if="data?.headline">{{ data?.headline }}</p>
            <p class="text-gray-500" v-if="location">{{ location }}</p>
          </div>
        </div>
      </div>
      <div class="my-3">
        <ProfileAvatar :profile-id="data?.owner" :data="data?.profilePic" class="h-32 w-32" />
      </div>
      <div class="my-3 flex items-center justify-center">
        <div class="w-full">
          <BriefProfileSocials :profile="data" />
        </div>
      </div>
    </div>
    <div class="flex flex-col my-3 w-full md:w-1/2 md:border-l md:border-gray-200 md:pl-9">
      <div class="w-full">
        <h3 class="text-lg font-semibold mb-2 text-left">Bio</h3>
        <div v-if="data?.aboutYou">
          <p class="text-gray-600 text-sm">
            {{ data?.aboutYou }}
          </p>
        </div>
        <div v-else>
          <p class="text-gray-600 text-sm">No bio written.</p>
        </div>
      </div>
      <div class="w-full" v-if="data?.languages?.length">
        <h3 class="text-lg font-semibold mb-2 text-left">Languages</h3>
        <ul class="space-y-1 text-sm" v-if="data?.languages?.length">
          <li v-for="(lang, index) in data?.languages" :key="index" class="text-gray-600">
            {{ lang.name }} - {{ lang.level }}
          </li>
        </ul>
        <div v-else>
          <p class="text-gray-600 text-sm">No languages set</p>
        </div>
      </div>
    </div>
  </div>
</template>

import { createI18n } from 'vue-i18n'
import en from '@/i18n/locale/en' with { type: 'json' }

type Messages = typeof en

const i18n = createI18n<[Messages], 'en'>({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: en
  }
})

export default i18n

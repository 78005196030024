// import axios from 'axios'
// import FormData from 'form-data'
import { pinFileToIpfs } from '@/queries/api'

export const pinataPinFileToIPFS = async (data: File, fileName?: string) => {
  try {
    const res = await pinFileToIpfs(data, fileName)
    return res.data.cid
  } catch (error) {
    console.log(error)
  }

  /* const formData = new FormData()
  formData.append('file', data)

  const pinataMetadata = JSON.stringify({
    name: fileName
  })
  formData.append('pinataMetadata', pinataMetadata)

  const pinataOptions = JSON.stringify({
    cidVersion: 0
  })
  formData.append('pinataOptions', pinataOptions)

  try {
    const res = await axios.post('https://api.pinata.cloud/pinning/pinFileToIPFS', formData, {
      maxBodyLength: 100000 * 100000,
      headers: {
        // @ts-ignore
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        Authorization: `Bearer ${JWT}`
      }
    })
    // console.log(res.data);
    return res.data.IpfsHash
  } catch (error) {
    console.log(error)
  } */
}

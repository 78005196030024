import { defineStore } from 'pinia'
// import { apolloClient } from '@/main'
// import { getPricingConfigurations } from '../queries/content'

export type ChannelPriceType = {
  name: string
  value: number
}

export type ChannelPriceTypes = ChannelPriceType[]

const DEFAULT_CHANNEL_PRICE = 100

const initStore = async () => {
  // return await apolloClient.query({ query: getPricingConfigurations() })
  return { data: { pricingConfigurations: [] } }
}

export const currencyToCOTO = (value: number, currency: string, with_symbol: boolean) => {
  const pricingStore = usePricingConfigurationStore()
  // We are using USD
  // const coto = pricingStore.usdPortion * 1 + pricingStore.eurPortion * pricingStore.usdEuroRatio + pricingStore.gbpPortion * pricingStore.usdPoundRatio;
  const coto = 1
  let symbol = ''
  switch (currency) {
    case 'USD':
      if (with_symbol) symbol = '$'
      return symbol + (coto * value).toFixed(2)
    case 'EUR':
      if (with_symbol) symbol = '€'
      return symbol + (coto * pricingStore.usdEuroRatio * value).toFixed(2)
    case 'GBP':
      if (with_symbol) symbol = '£'
      return symbol + (coto * pricingStore.usdPoundRatio * value).toFixed(2)
  }
  if (with_symbol) symbol = '£'
  return symbol + '0.00'
}

export const convertCoto = (cotoValue: number, currency: string) => {
  const pricingStore = usePricingConfigurationStore()
  if (!cotoValue) {
    return 0
  }
  // We are using USD
  // const coto = pricingStore.usdPortion * 1 + pricingStore.eurPortion * pricingStore.usdEuroRatio + pricingStore.gbpPortion * pricingStore.usdPoundRatio;
  const coto = 1
  switch (currency) {
    case 'USD':
      return coto * cotoValue
    case 'EUR':
      return ((coto * 1) / pricingStore.usdEuroRatio) * cotoValue
    case 'GBP':
      return ((coto * 1) / pricingStore.usdPoundRatio) * cotoValue
    default:
      return cotoValue
  }
}

export const usePricingConfigurationStore = defineStore('pricingConfiguration', {
  state: () => {
    return {
      bidMultiplier: 3,
      minPremiumPrice: 5,
      minStandardPrice: 1,
      usdCurrencyState: null,
      gbpCurrencyState: null,
      eurCurrencyState: null,
      usdPoundRatio: 0.82, // these are overwritten at init
      usdEuroRatio: 0.95, // these are overwritten at init
      selectedCurrency: 'USD',
      channelPrices: [] as ChannelPriceTypes,
      usdPortion: 1, // 0.4,
      gbpPortion: 0, // 0.2,
      eurPortion: 0, // 0.4,
      stableCoins: [],
      isLoaded: false,
      usdtDivisor: 1000000
    }
  },
  actions: {
    set(
      bidMultiplier: number,
      minStandardPrice: number,
      minPremiumPrice: number,
      channelPrices: ChannelPriceTypes
    ) {
      this.bidMultiplier = bidMultiplier
      this.minPremiumPrice = minPremiumPrice
      this.minStandardPrice = minStandardPrice
      this.channelPrices = channelPrices
    },
    setCurrency(currency: string) {
      this.selectedCurrency = currency
      localStorage.setItem('selectedCurrency', currency)
    },
    getChannelPrice(name: string) {
      return DEFAULT_CHANNEL_PRICE
      /* const channel = pricesForChannels.filter((e) => e.name === name.toLowerCase())
      if (channel.length > 0) {
        return channel[0].value
      } else {
        return DEFAULT_CHANNEL_PRICE
      } */
    }
  }
})

// export let pricesForChannels = [] as ChannelPriceTypes

export const boot = async () => {
  const result = await initStore()

  if (result?.data?.pricingConfigurations.length <= 0) return
  /* pricesForChannels = result.data.pricingConfigurations[0].channel_prices

  const store = usePricingConfigurationStore()

  if (result.data.usdcurrencyStates.length > 0) {
    const usd = parseFloat(result.data.usdcurrencyStates[0].price)
    const eur = parseFloat(result.data.eurcurrencyStates[0].price)
    const gbp = parseFloat(result.data.gbpcurrencyStates[0].price)

    const usdPortion = parseFloat(result.data.usdcurrencyStates[0].portion) / 100.0
    const gbpPortion = parseFloat(result.data.gbpcurrencyStates[0].portion) / 100.0
    const eurPortion = parseFloat(result.data.eurcurrencyStates[0].portion) / 100.0

    try {
      store.$patch({
        selectedCurrency: localStorage.getItem('selectedCurrency') ?? 'USD',
        usdCurrencyState: result.data.usdcurrencyStates[0],
        eurCurrencyState: result.data.eurcurrencyStates[0],
        gbpCurrencyState: result.data.eurcurrencyStates[0],
        usdEuroRatio: eur / usd,
        usdPoundRatio: gbp / usd,
        usdPortion: usdPortion,
        gbpPortion: gbpPortion,
        eurPortion: eurPortion,
        usdtDivisor: 1000000
      })
    } catch (err: unknown) {
      //
    }
  } else {
    console.log('No currency calculation constants found!')
  }

  store.$patch({
    bidMultiplier: result.data.pricingConfigurations[0].bidMultiplier,
    minPremiumPrice: result.data.pricingConfigurations[0].minPremiumPrice,
    minStandardPrice: result.data.pricingConfigurations[0].minStandardPrice,
    channelPrices: result.data.pricingConfigurations[0].channel_prices,
    selectedCurrency: localStorage.getItem('selectedCurrency') ?? 'USD',
    stableCoins: result.data.stableCoinStates,
    isLoaded: true
  }) */
}

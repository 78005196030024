import { useScreen } from 'vue-screen'

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0
}

const screen = useScreen()
window.debounceMobileDetectTimeout = 0
export const mobileDetect = () => {
  clearTimeout(window.debounceMobileDetectTimeout)
  const isMobile = screen.width < 768 || isTouchDevice()
  window.debounceMobileDetectTimeout = setTimeout(() => {
    // cleanup bad popups
    if (document.querySelector('.popupActive') && !document.querySelector('.popup-container')) {
      const popup = document.querySelector('.popupActive')
      popup?.classList.remove('popupActive')
    }
    /*
    console.log(
      'mobileDetect :',
      isMobile,
      'screen.width',
      screen.width,
      'isTouchDevice()',
      isTouchDevice()
    )
    */
  }, 1000)
  if (isMobile) {
    document.body.classList.add('mobile')
  } else {
    document.body.classList.remove('mobile')
  }
  return isMobile
}

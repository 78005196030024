<script lang="ts" setup>
// @ts-nocheck
import { ref, computed } from 'vue'
import { isFile, checkFormat } from '@/utils/mediaFileTypes'

import MediaItem from '@media/MediaItem.vue'

const props = defineProps({
  dataType: {
    type: String,
    default: 'objects'
  },
  items: {
    type: Array,
    default: () => []
  },
  noContentMsg: {
    type: String,
    default: ''
  },
  allowedDelete: {
    type: Boolean,
    default: false
  },
  showCount: {
    type: Boolean,
    default: false
  },
  showCountTitle: {
    type: String,
    default: 'Uploaded Documents'
  }
})

const list = ref([])
if (props.items) {
  // just a list of urls
  if (props.dataType == 'url_list') {
    props.items.forEach((i) => {
      list.value.push({ file_url: i })
    })
  } else {
    list.value = props.items
  }

  // filter only documents
  list.value = list.value.filter((item) => isFile(item))
}
</script>

<template>
  <div v-if="list && list.length">
    <p v-if="showCount" class="text-sm font-semibold border-b-2 mb-3">
      {{ showCountTitle }} ({{ list.length }} file<span v-if="list.length != 1">s</span>)
    </p>
    <div v-for="(entry, index) in list" :key="index" class="my-3 border-b-2 border-gray-100 pb-3">
      <MediaItem :item="entry" :showMeta="true" />
    </div>
  </div>
  <div v-else-if="noContentMsg">
    {{ noContentMsg }}
  </div>
</template>

export function getTitle(metadata: any) {
  const titleObj = metadata?.title || metadata?.ImageDescription || metadata?.XPTitle || metadata?.DocumentName || metadata?.Headline;
  if (titleObj) {
    if (typeof titleObj === 'string') {
      return titleObj
    } else if (Array.isArray(titleObj)) {
      const defaultTitle = titleObj.find((title) => title.lang === 'x-default')
      return defaultTitle?.value || titleObj[0]?.value || ''
    } else {
      return titleObj['value'] || ''
    }
  }
  return undefined
}

export function getDescription(metadata: any) {
  const descriptionObj = metadata?.description || metadata?.Description || metadata?.UserComment || metadata?.XPComment || metadata?.ImageDescription || '';
  if (descriptionObj) {
    if (typeof descriptionObj === 'string') {
      return descriptionObj
    } else if (Array.isArray(descriptionObj)) {
      const defaultDescription = descriptionObj.find(
        (description) => description.lang === 'x-default'
      )
      return defaultDescription?.value || descriptionObj[0]?.value || ''
    } else {
      return descriptionObj['value'] || ''
    }
  }
  return undefined
}

export function getKeywords(metadata: { Keywords: any; subject: any; }) {
  if (metadata?.Keywords) {
    return metadata.Keywords
  }
  if (metadata?.subject) {
    return metadata.subject
  }
  return undefined
}

<script lang="ts" setup>
// @ts-nocheck
import { ref, reactive, watch } from 'vue'
import { validateFields, validateStatus } from '@/validate'
import { createBrief } from '@/api/requests'
import { Styles } from '@/styles'
import { usePricingConfigurationStore } from '@/stores/pricing-configurations'

import VueTagsInput from '@sipec/vue3-tags-input'
import PageSectionTitle from '@/components/PageSectionTitle.vue'
import CheckOrCross from '@/components/CheckOrCross.vue'
import UploadFile from '@upload/UploadFile.vue'
import CurrencyValue from '@/components/CurrencyValue.vue'
import JetCheckbox from '@/components/Jetstream/Checkbox.vue'
import JetLabel from '@/components/Jetstream/Label.vue'

import { videoResolutions, aspectRatios } from '@/dropdowns/filters'
import { uploadToEstuary_v2 } from '@/utils/estuary'

const props = defineProps({
  toast: undefined,
  stage: {
    default: 1
  }
})

// this is a bodge to stop the compiler not defining emit
emit('init', true)

const pricingChannel = usePricingConfigurationStore()

const getChannelPrice = (name) => {
  return pricingChannel.getChannelPrice(name)
}
// styles
const addLink = 'cursor-pointer my-3 text-sm text-blue-600 hover:text-blue-400'
const deleteIcon = 'ml-2 fa-light fa-xmark cursor-pointer text-gray-400 hover:text-blue-600'
const deleteLink = 'ml-2 fa-light fa-xmark cursor-pointer text-gray-400 hover:text-blue-600'
const whiteBlock = 'flex items-center bg-white p-3 my-3 rounded-md shadow-md border border-gray-300'
const emit = defineEmits(['init', 'validate'])

const title = ref('')
const objective = ref('')

// tags
const tag = ref('')
const tags = ref([])

// changes in mediaTypeChange()
// const resolutions = ref([])

const formats = ['Video', 'Image', 'PDF']
const metrics = ['Average Circulation', 'Peak Circulation']
const platforms = [
  'Website',
  'Cover of a Magazine',
  'Facebook',
  'Instagram',
  'LinkedIn',
  'Snap',
  'TikTok',
  'Twitter',
  'Weibo',
  'YouTube'
]
const currencies = ['USD', 'EUR']

// list of media formats, T&C's, Restrictions
const MediaList = ref([]),
  TnCsList = ref([]),
  RestrictionsList = ref([]),
  OwnershipList = ref([]),
  PlatformList = ref([]),
  UploadRef = ref(),
  UploadRefLogo = ref(),
  uploadsComplete = ref(false),
  acceptAIGenerated = ref(true)

// URL's of items uploaded to S3 in stage 2
let MediaItems = []
let logoImage = []

// const PlatformListValid = ref(false)

const ownership = ref('Fixed Price'),
  proposedPrice = ref(),
  proposedPriceCurrency = ref('USD'),
  validPrice = ref(0),
  // addTnCs = ref(),
  addRestrictions = ref()
// resetTnCs = ref(),
// resetRestrictions = ref()

const emptyPlatform = {
  name: '',
  username: '',
  magazine: '',
  metric: '',
  circulation: '',
  website: ''
}
const addPlatform = () => {
  PlatformList.value.push({ ...emptyPlatform })
  validate()
}

// add media format row
const addMedia = () => {
  MediaList.value.push({
    media_type: '',
    quality: '',
    aspect_ratio: ''
  })
  validate()
}

// reset other fields when platform changes
const resetPlatform = (index) => {
  const oldName = PlatformList.value[index].name
  PlatformList.value[index] = { ...emptyPlatform }
  PlatformList.value[index].name = oldName
  validate()
}

// add terms / restrictions rows
const addListItem = (text, list, isMandatory = true, title, id) => {
  // via dom click, read the input field
  if (typeof text == 'object') {
    let node = event.target.parentElement.childNodes[0]
    text = node.value
    node.value = ''
  }
  if (!text.length) return
  list.push({
    id: id,
    title: title,
    text: text,
    mandatory: isMandatory,
    selected: false
  })
  validate()
}

// delete rows
const deleteListItem = (index, list) => {
  list.splice(index, 1)
  validate()
}

// reset stock T&C's
const restoreConditions = () => {
  TnCsList.value = []
  addListItem(
    'I own the copyright of this content and if a person or property is featured, I have consent from each person or property owner',
    TnCsList.value
  )
  addListItem(
    'This content contains nudity, violence, anything people might consider shocking or offensive and as such will have to be placed in a special category',
    TnCsList.value,
    false
  )
}

const selectOption = (event) => {
  let cl, pa

  if (typeof event.target !== 'undefined') {
    cl = event.target.classList
    pa = event.target.parentNode
  } else {
    cl = event.classList
    pa = event.parentNode
  }

  // bubble to the top element
  for (let i = 0; i < 3; i++) {
    if (cl.contains('parent')) {
      pa.click()
      return
    }
    pa = pa.parentNode
    cl = pa.classList
  }
}
const selectedOption = (index) => {
  ownership.value = OwnershipList.value[index].title
  status.value.vals.ownership = true
  validate()
}

// deleting tags does not call the tagsChanged handler
const tagsChanged = (newTags) => {
  tags.value = newTags
  if (tags.value.length) {
    status.value.vals.tags = 1
  } else {
    status.value.vals.tags = 0
  }
  validate()
}
const tagsBlurred = () => {
  tagsChanged(tags.value)
}

const validateList = (list, showToasts) => {
  if (list.length) {
    return 1
  } else {
    status.value.valid = false
    return showToasts ? false : 0
  }
}

// validation
const validate = (event, showToasts = false) => {
  // parent calling
  status.value = validateStatus(event, showToasts, props.toast, 'validate-' + props.stage)

  // tags
  status.value.vals.tags = validateList(tags.value, showToasts)

  // restrictions
  status.value.vals.restrictions = validateList(RestrictionsList.value, showToasts)

  // terms
  status.value.vals.terms_and_conditions = validateList(TnCsList.value, showToasts)

  // validate logo
  if (props.stage == 1 && typeof UploadRefLogo.value !== 'undefined' && UploadRefLogo.value) {
    status.value.vals.uploadLogo = validateList(UploadRefLogo.value.fileList, showToasts)
  }

  // validate images
  if (props.stage == 2 && typeof UploadRef.value !== 'undefined') {
    status.value.vals.upload = validateList(UploadRef.value.fileList, showToasts)
  }

  // validate platforms
  if (props.stage == 3) {
    if (!validatePlatforms(showToasts)) {
      status.value.valid = false
    }
  }

  if (showToasts) {
    return status.value.valid
  } else {
    // call parent with the status
    emit('validate', status)
  }
}

const validatePlatforms = (showToasts) => {
  var success = true
  let bad = 0
  if (showToasts) {
    bad = false
  }

  if (ownership.value == 'Own the content') {
    validPrice.value = bad
    if (proposedPrice.value && proposedPrice.value != '$0.00') {
      validPrice.value = true
    } else {
      success = false
    }
  } else {
    for (var i = 0; i < PlatformList.value.length; i++) {
      var p = PlatformList.value[i]
      PlatformList.value[i].valid = true

      // three types
      if (p.name == 'Cover of a Magazine') {
        if (p.magazine == '' || p.metric == '' || p.circulation == '') {
          PlatformList.value[i].valid = bad
          success = false
        }
      } else if (p.name == 'Website') {
        if (p.website == '') {
          PlatformList.value[i].valid = bad
          success = false
        }
      } else if (p.username == '') {
        PlatformList.value[i].valid = bad
        success = false
      }
    }
  }
  return success
}

const setStage = (stage = 1) => {
  // input fields with class 'validate' automatically validated
  let fields = [
    // page 1
    {
      // variables that need a value
      mandatoryVars: {
        MediaList: {
          vals: MediaList
        }
      }
    },
    // page 2
    {},
    // page 3
    {}
  ]

  // give the helper the fields to validate
  validateFields(reactive(fields[stage - 1]))

  // allow components to init on page change
  setTimeout(() => {
    validate()
  }, 500)
}

const saveFiles = async () => {
  // upload any files first
  const toastId = props.toast.success('Uploading files', {
    timeout: 20000
  })
  if (UploadRef.value.fileList.length) {
    for (const file of UploadRef.value.fileList) {
      const cid = await uploadToEstuary_v2(file.file)
      MediaItems.push({
        link: `https://edge.estuary.tech/gw/${cid}`,
        type: file.type
      })
    }
  }
  if (UploadRefLogo.value.fileList.length) {
    console.log(UploadRefLogo.value.fileList[0].name)
    const cid = await uploadToEstuary_v2(UploadRefLogo.value.fileList[0].file)
    logoImage = [
      {
        link: `https://edge.estuary.tech/gw/${cid}`,
        type: UploadRefLogo.value.fileList[0].type
      }
    ]
  }
  uploadsComplete.value = true
  props.toast.dismiss(toastId)
}

const saveData = async () => {
  /* convert the data to match what the api is expecting */

  const data = {
    campaign_title: title.value,
    campaign_objective: objective.value
  }

  if (ownership.value == 'Pay per view / like') {
    data.ownership_model = 'pay_per_view_like'
  } else {
    data.ownership_model = 'fixed_price'
  }

  if (proposedPrice.value) {
    data.proposed_price = proposedPrice.value
    data.proposed_price_currency = proposedPriceCurrency.value
  }

  if (MediaItems.length) {
    data.media_items = MediaItems
  }

  if (logoImage.length) {
    data.logo = logoImage[0].link
  }

  if (MediaList.value.length) {
    data.campaign_formats = []
    for (let i = 0; i < MediaList.value.length; i++) {
      data.campaign_formats.push({
        media_type: MediaList.value[i].media_type.toLowerCase(),
        quality: MediaList.value[i].quality,
        aspect_ratio: MediaList.value[i].aspect_ratio
      })
    }
  }

  if (TnCsList.value.length) {
    data.terms_and_conditions = []
    for (let i = 0; i < TnCsList.value.length; i++) {
      data.terms_and_conditions.push(TnCsList.value[i].text)
    }
  }

  if (RestrictionsList.value.length) {
    data.restrictions = []
    for (let i = 0; i < RestrictionsList.value.length; i++) {
      data.restrictions.push(RestrictionsList.value[i].text)
    }
  }

  if (PlatformList.value.length && PlatformList.value[0].name.length) {
    data.content_usages = []
    for (let i = 0; i < PlatformList.value.length; i++) {
      if (PlatformList.value[i].name == 'Cover of a Magazine') {
        data.content_usages.push({
          type: 'cover',
          data: {
            name: PlatformList.value[i].magazine,
            metric: PlatformList.value[i].metric,
            circulation: PlatformList.value[i].circulation
          }
        })
      } else {
        var username_or_website = PlatformList.value[i].username
        if (PlatformList.value[i].name == 'Website') {
          username_or_website = PlatformList.value[i].website
        }
        data.content_usages.push({
          type: PlatformList.value[i].name.toLowerCase(),
          data: username_or_website
        })
      }
    }
  }

  if (tags.value.length) {
    data.titles = []
    for (let i = 0; i < tags.value.length; i++) {
      data.titles.push(tags.value[i].text)
    }
  }

  data.acceptAIGenerated = acceptAIGenerated.value
  // save the associated data
  createBrief(data, props.toast)
}

const status = ref()
watch(
  () => props.stage,
  (newStage) => {
    setStage(newStage)
  }
)

// init
setStage(props.stage)

// initial lists
addListItem(
  'A fixed price per channel. If you’re confident your campaign will fly, then lock in a great deal by fixing the price for each channel, i.e. Youtube, TikTok, Instagram, Facebook, LinkedIn etc. This is a cost effective way to license great content when you have a set budget you don’t want to exceed.',
  OwnershipList.value,
  false,
  'Fixed Price',
  'fixed'
)
addListItem(
  'You pay per thousand video/photo likes, comments or shares. If your campaign is well engaged by consumers it’s a win for everyone…your amazing campaign gets you more customers and more revenue, the content creator is rewarded for the high levels of engagement. By contrast, if your campaign doesn’t land as expected with the audience, you pay less.',
  OwnershipList.value,
  false,
  'Pay per view / like',
  'pay'
)
addListItem(
  'Get exclusivity. If you want to own the content and use it exclusively for your own project this is the best option for you. Ownership rights will be transferred from the content creator and other stakeholders.',
  OwnershipList.value,
  false,
  'Own the content',
  'owned'
)

addMedia()
restoreConditions()
addPlatform()

// allow main controller to see status and change next button colour.
defineExpose({
  validate,
  saveData,
  saveFiles
})
</script>

<style>
.newBriefs i {
  color: #0dabe0;
}
</style>

<template>
  <div class="newBriefs" @keyup="validate" v-show="status.vals">
    <div v-show="stage == 1" class="flex flex-wrap">
      <div class="sm:w-full md:w-[48%] md:mr-[2%]">
        <PageSectionTitle> Products or services to promote </PageSectionTitle>
        <div class="my-3 flex items-center">
          <div class="flex-grow shadow-md rounded-md">
            <VueTagsInput
              v-model="tag"
              :tags="tags"
              placeholder="Enter a product/service"
              @blur="tagsBlurred"
              @tags-changed="tagsChanged"
            />
          </div>
          <CheckOrCross :mandatory="true" :valid="status.vals.tags" />
        </div>

        <PageSectionTitle> Campaign Title </PageSectionTitle>
        <div class="flex items-center my-3">
          <input
            type="text"
            name="title"
            class="validate-1"
            :class="Styles.input"
            v-model="title"
          />
          <CheckOrCross :mandatory="true" :valid="status.vals.title" />
        </div>

        <PageSectionTitle> Campaign Objective </PageSectionTitle>
        <div class="flex items-center my-3">
          <input
            type="text"
            name="objective"
            class="validate-1"
            :class="Styles.input"
            v-model="objective"
          />
          <CheckOrCross :mandatory="true" :valid="status.vals.objective" />
        </div>

        <div class="flex">
          <PageSectionTitle> Restrictions </PageSectionTitle>
          <CheckOrCross :mandatory="true" :valid="status.vals.restrictions" />
        </div>
        <div class="my-3">
          <div :class="whiteBlock">
            <textarea
              ref="addRestrictions"
              :class="[Styles.textarea, 'add-restriction border-none']"
              placeholder="Enter your restrictions here"
            />
            <div
              :class="addLink"
              class="click-add-restriction"
              @click="addListItem($event, RestrictionsList)"
            >
              Add
            </div>
          </div>
          <div
            class="flex my-3 items-center bg-white p-3 py-0 rounded-md shadow-md"
            v-for="(list, index) in RestrictionsList"
            :key="index"
          >
            <textarea
              name="restrictions"
              :class="[Styles.textarea, 'border-none mb-3']"
              v-model="list.text"
              style="min-height: 100px"
            />
            <i
              class="click-delete-restriction"
              @click="deleteListItem(index, RestrictionsList)"
              :class="deleteIcon"
              title="Delete"
            ></i>
          </div>
        </div>
        <div class="my-3">
          <JetLabel for="terms">
            <div class="flex items-center">
              <JetCheckbox id="terms" v-model:checked="acceptAIGenerated" name="terms" />
              <div class="ml-2">Content from AI generated images can be included</div>
            </div>
          </JetLabel>
        </div>
      </div>

      <div class="w-full md:w-[50%]">
        <PageSectionTitle> Brief Logo </PageSectionTitle>
        <CheckOrCross
          v-if="UploadRefLogo && stage == 1"
          :mandatory="true"
          :valid="status.vals.uploadLogo"
        />
        <div class="flex items-center bg-white mb-2">
          <div class="flex-grow h-full">
            <UploadFile
              ref="UploadRefLogo"
              :uploadsComplete="uploadsComplete"
              :widthFull="true"
              :heightFull="true"
              @update="validate"
            />
          </div>
        </div>
        <PageSectionTitle> Campaign Formats </PageSectionTitle>
        <ul>
          <li class="flex my-3" v-for="(media, index) in MediaList" :key="index">
            <div class="flex flex-col w-full sm:flex-row">
              <select
                @change="validate"
                name="media.media_type"
                v-model="media.media_type"
                :class="[
                  Styles.select,
                  'select-media-type sm:mr-2 mb-3 sm:mb-0',
                  {
                    'bg-gray-100': media.media_type
                  }
                ]"
              >
                <option value="" selected>Media</option>
                <option v-for="option in formats" :key="option">{{ option }}</option>
              </select>
              <select
                v-if="media.media_type == 'Video'"
                @change="validate"
                name="media.quality"
                v-model="media.quality"
                :class="[
                  Styles.select,
                  'select-media-resolution sm:mr-2 mb-3 sm:mb-0',
                  {
                    'bg-gray-100': media.quality
                  }
                ]"
              >
                <option value="" selected>Resolution</option>
                <option v-for="option in videoResolutions" :key="option.id">{{ option.id }}</option>
              </select>
              <select
                v-else-if="media.media_type == 'Image'"
                @change="validate"
                name="media.quality"
                v-model="media.quality"
                :class="[
                  Styles.select,
                  'select-media-resolution sm:mr-2 mb-3 sm:mb-0',
                  {
                    'bg-gray-100': media.quality
                  }
                ]"
              >
                <option value="" selected>Resolution</option>
                <option v-for="option in videoResolutions" :key="option.id">{{ option.id }}</option>
              </select>
              <select
                v-if="media.media_type"
                @change="validate"
                name="media.aspect"
                v-model="media.aspect_ratio"
                :class="[
                  Styles.select,
                  'select-media-aspect-ratio mb-3 sm:mb-0',
                  {
                    'bg-gray-100': media.aspect_ratio
                  }
                ]"
              >
                <option value="" selected>Aspect Ratio</option>
                <option v-for="option in aspectRatios" :key="option.id">{{ option.id }}</option>
              </select>
            </div>
            <div>
              <i
                v-if="index > 0"
                class="click-delete-media pl-0.5"
                :class="deleteLink"
                @click="deleteListItem(index, MediaList)"
              ></i>
              <CheckOrCross :mandatory="true" :valid="media.valid" />
            </div>
          </li>
        </ul>
        <div :class="addLink" class="click-add-media" @click="addMedia">Add Another</div>
      </div>
    </div>

    <div v-show="stage == 2" class="flex flex-wrap">
      <div class="w-full md:w-[47%] md:mr-[3%]">
        <div class="flex">
          <PageSectionTitle> Customize your terms and conditions </PageSectionTitle>
          <CheckOrCross :mandatory="true" :valid="status.vals.restrictions" />
        </div>

        <div class="my-3">
          <div class="flex justify-between">
            <p class="mb-4 text-sm text-gray-600">Set your own terms and conditions checkboxes</p>
          </div>

          <div class="flex items-center bg-white p-3 rounded-md shadow-md mb-3">
            <textarea
              :class="[Styles.textarea, 'add-term border-none']"
              placeholder="Enter your terms here"
            />
            <div
              class="click-add-term cursor-pointer ml-3 my-3 text-sm text-blue-600 hover:text-blue-400"
              @click="addListItem($event, TnCsList)"
            >
              Add
            </div>
          </div>
          <div
            class="flex my-3 items-start bg-white p-3 py-0 rounded-md shadow-md"
            v-for="(list, index) in TnCsList"
            :key="index"
          >
            <div class="flex-grow h-full items-center pb-3">
              <div class="flex items-center">
                <textarea
                  name="terms"
                  :class="[Styles.textarea, 'border-none']"
                  class="flex"
                  v-model="list.text"
                  style="min-height: 100px"
                />
                <i
                  class="click-delete-term"
                  @click="deleteListItem(index, TnCsList)"
                  :class="deleteLink"
                  title="Delete"
                ></i>
              </div>
            </div>
          </div>
          <div
            @click="restoreConditions"
            class="click-reset-terms font-semi-bold hover:font-bold cursor-pointer text-sm text-right text-blue-600"
          >
            Reset
          </div>
        </div>
      </div>

      <div class="flex flex-col w-full md:w-[50%]">
        <div class="flex">
          <PageSectionTitle> Upload Guidance </PageSectionTitle>
          <CheckOrCross
            v-if="UploadRef && stage == 2"
            :mandatory="true"
            :valid="status.vals.upload"
          />
        </div>
        <p class="my-3 text-sm text-gray-600">
          Help your content creators meet the requirements and guidelines
        </p>
        <div class="h-72 items-center">
          <div class="flex items-start">
            <div>
              <UploadFile ref="UploadRef" :multiSelect="true" @toast="toast" @update="validate" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="stage == 3" class="flex flex-wrap">
      <div class="w-full md:w-[48%] md:mr-[2%]">
        <PageSectionTitle>
          <div class="flex">Ownership model</div>
        </PageSectionTitle>
        <div
          :class="[whiteBlock, 'cursor-pointer parent']"
          @click="selectOption"
          v-for="(list, index) in OwnershipList"
          :key="index"
        >
          <input
            :disabled="index != 0"
            :checked="index == 0"
            type="radio"
            @click="selectedOption(index)"
            :id="list.id"
            name="ownership"
            class="mr-3"
            :class="Styles.radio"
          />
          <label class="cursor-pointer" :for="list.id">
            <p>
              <b>{{ list.title }}</b>
            </p>
            <p>{{ list.text }}</p>
          </label>
        </div>
      </div>

      <div v-if="ownership != 'Own the content'" class="flex flex-col w-full md:w-[49%] md:ml-[1%]">
        <PageSectionTitle> Content Usage </PageSectionTitle>
        <div class="flex my-3 flex-col" v-for="(platform, index) in PlatformList" :key="index">
          <div class="flex flex-col sm:flex-row items-start">
            <select
              @change="resetPlatform(index)"
              name="platform.type"
              v-model="platform.name"
              :class="[
                Styles.select,
                'platform mr-3 mb-3 sm:mb-0 w-full sm:w-[50%]',
                {
                  'bg-gray-100': platform.name
                }
              ]"
            >
              <option value="" selected>Select Platform</option>
              <option v-for="option in platforms" :key="option">{{ option }}</option>
            </select>

            <div v-if="platform.name == 'Cover of a Magazine'" class="flex flex-col">
              <input
                type="text"
                :class="[
                  Styles.input,
                  'platform-magazine mb-3',
                  {
                    'bg-gray-100': platform.magazine
                  }
                ]"
                v-model="platform.magazine"
                placeholder="Magazine Name"
              />
              <select
                @change="validate"
                v-model="platform.metric"
                class="validate-3"
                :class="[
                  Styles.select,
                  'platform-metric mb-3',
                  {
                    'bg-gray-100': platform.metric
                  }
                ]"
              >
                <option value="" selected>Select Metric</option>
                <option v-for="option in metrics" :key="option">{{ option }}</option>
              </select>
              <input
                type="text"
                :class="[
                  Styles.input,
                  'platform-circulation',
                  {
                    'bg-gray-100': platform.circulation
                  }
                ]"
                v-model="platform.circulation"
                placeholder="Circulation"
              />
            </div>
            <input
              v-else-if="platform.name == 'Website'"
              type="text"
              :class="[
                Styles.input,
                'platform-website mb-3',
                {
                  'bg-gray-100': platform.website
                }
              ]"
              v-model="platform.website"
              placeholder="www."
            />
            <input
              v-else-if="platform.name"
              type="text"
              :class="[
                Styles.input,
                'platform-username mb-3',
                {
                  'bg-gray-100': platform.username
                }
              ]"
              v-model="platform.username"
              placeholder="Username"
            />
            <i
              v-if="index > 0"
              :class="[deleteIcon, 'click-delete-platform mt-3.5']"
              @click="deleteListItem(index, PlatformList)"
            ></i>

            <CheckOrCross class="mt-2.5" :mandatory="true" :valid="platform.valid" />
          </div>
          <div class="text-sm">
            <div v-if="ownership == 'Pay per view / like'">
              {{ ownership }}<b>Proposed price: $5</b> per 1000 likes / comments
            </div>
            <div v-if="ownership == 'Fixed Price' && platform.name">
              Price <CurrencyValue :value="parseFloat(getChannelPrice(platform.name))" />
            </div>
          </div>
        </div>
        <div :class="[addLink, 'click-add-platform mt-0']" @click="addPlatform">Add Another</div>
      </div>
      <div v-else-if="ownership">
        <PageSectionTitle> Proposed Price </PageSectionTitle>
        <div class="flex items-center">
          <input
            type="text"
            class="validate-3"
            name="proposedPrice"
            :class="[
              Styles.input,
              'my-3 mr-3',
              {
                'bg-gray-100': proposedPrice && proposedPrice != '$0.00'
              }
            ]"
            v-model="proposedPrice"
            placeholder="$0.00"
          />
          <select
            @change="validate"
            v-model="proposedPriceCurrency"
            :class="[
              Styles.select,
              {
                'bg-gray-100 w-[75px]': proposedPriceCurrency
              }
            ]"
          >
            <option v-for="option in currencies" :key="option">{{ option }}</option>
          </select>
          <CheckOrCross :mandatory="true" :valid="status.vals.proposedPrice" />
        </div>
      </div>
    </div>

    <pre v-if="1 == 2" class="text-xs">{{ status }}</pre>
  </div>
</template>

export default function useLazyPiniaStore<T>(store: () => T) {
  let storeInstance: T

  return new Proxy(
    {},
    {
      get(_, propertyName) {
        // Lazy-load the store instance
        if (!storeInstance) {
          storeInstance = store()
        }

        const property = storeInstance[propertyName as keyof typeof storeInstance]

        // If the property is a function, bind it to the storeInstance to ensure `this` is correct.
        if (typeof property === 'function') {
          return property.bind(storeInstance)
        }

        return property
      },
      set(_, propertyName, value): boolean {
        // Lazy-load the store instance
        if (!storeInstance) {
          storeInstance = store()
        }

        const propertyNameKey = propertyName as keyof typeof storeInstance

        const property = storeInstance[propertyNameKey]

        if (typeof property === 'function') {
          throw new Error(`Cannot set a function property on a Pinia store.`)
        }

        storeInstance[propertyNameKey] = value

        return true
      }
    }
  )
}

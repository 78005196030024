<script lang="ts" setup>
import { onMounted, onUnmounted, ref, watch, type PropType } from 'vue'
import { checkFormat, isFile } from '@/utils/mediaFileTypes'
import { v4 as uuidv4 } from 'uuid'
import { useScroll } from '@vueuse/core'
import { isElementInView } from '@/utils/media'
import { mobileDetect } from '@/utils/browser'
import { forEach, update } from 'lodash'

import MediaItem from '@media/MediaItem.vue'
import BriefDetailsDownloadMediaButton from '@briefs/BriefDetailsDownloadMediaButton.vue'

const props = defineProps({
  dataType: {
    type: String,
    default: 'objects'
  },
  height: {
    type: String,
    default: '260px'
  },
  items: {
    type: Array,
    default: () => []
  },
  isAccepted: {
    type: Boolean,
    default: false
  },
  links: {
    type: Boolean,
    default: true
  },
  noContentMsg: {
    type: String,
    default: ''
  },
  showDescription: {
    type: Boolean,
    default: false
  },
  showOwner: {
    type: Boolean,
    default: false
  },
  showCount: {
    type: Boolean,
    default: false
  },
  showCountTitle: {
    type: String,
    default: 'Uploaded Media'
  },
  downloadable: {
    type: Boolean,
    default: false
  },
  brief: Object as PropType<SmartBrief>
})

const { isScrolling } = useScroll(window)

const uuid = uuidv4()
const list = ref()
const numItems = ref(0)

// just a list of urls
if (props.dataType == 'url_list') {
  list.value = []
  props.items.forEach((i) => {
    list.value.push({ file_url: i })
  })
} else {
  list.value = props.items
}

if (list.value) {
  // strip any items that don't have a file url (e.g. failed uploads) or documents
  list.value = list.value.filter((item: any) => item.file_url && !isFile(item))
  // check format, count images, PDFs and videos
  const formattedItems = checkFormat(list.value)
  list.value = formattedItems.list
  numItems.value = formattedItems.numImages + formattedItems.numVideos
}

// keep a global list so that the popup can go prev/next
if (typeof window.carousels === 'undefined') {
  window.carousels = {}
}
window.carousels[uuid] = list.value

const isActive = ref()
const setActive = (id: string) => {
  isActive.value = id
}

const carouselIndex = ref(0)
const endLeft = ref(true)
const endRight = ref(true)
const allItemsAreVisible = ref(true)

const carouselDivs = ref()

const checkItemsAreVisible = () => {
  let allInView = true
  forEach(carouselDivs.value, (el) => {
    if (!isElementInView(el, true)) {
      allInView = false
    } else {
    }
  })
  return allInView
}

const checkScrollPos = () => {
  if (typeof window.debounceResizeTimeout === 'undefined') {
    window.debounceResizeTimeout = []
  }
  clearTimeout(window.debounceResizeTimeout[uuid as any])
  window.debounceResizeTimeout[uuid as any] = setTimeout(() => {
    allItemsAreVisible.value = checkItemsAreVisible()
    // first element is in view, no more scrolling left
    const firstEl = carouselDivs.value[0]
    endLeft.value = isElementInView(firstEl, true)
    // last element is in view, no more scrolling right
    const lastEl = carouselDivs.value[list.value.length - 1]
    endRight.value = isElementInView(lastEl, true)
  }, 500)
}

const scrollIntoViewOptions = {
  block: 'nearest',
  behavior: 'smooth'
}

const prev = () => {
  if (carouselIndex.value > 0) {
    carouselIndex.value--
    const el = carouselDivs.value[carouselIndex.value]
    if (isElementInView(el, true)) {
      // already visible, try the next one
      prev()
      return
    }
    el.scrollIntoView(scrollIntoViewOptions)
    checkScrollPos()
  }
}
const next = () => {
  if (carouselIndex.value < list.value.length - 1) {
    carouselIndex.value++
    const el = carouselDivs.value[carouselIndex.value]
    if (isElementInView(el, true)) {
      // already visible, try the next one
      next()
      return
    }
    el.scrollIntoView(scrollIntoViewOptions)
    checkScrollPos()
  }
}

let controlClass = '',
  controlLeftClass = '',
  controlRightClass = ''

const isMobile = ref(mobileDetect())
const updatePageWidth = () => {
  isMobile.value = mobileDetect()
  if (isMobile.value) {
    controlClass = 'absolute'
    controlLeftClass = '-left-[15px]'
    controlRightClass = '-right-[15px]'
  } else {
    controlClass =
      'absolute transition-all text-white text-2xl w-10 h-10 flex items-center justify-center cursor-pointer rounded-md bg-[rgba(0,0,0,0.5)]'
    controlLeftClass = '-left-[50px]'
    controlRightClass = '-right-[50px]'
  }
  checkScrollPos()
}

watch(isScrolling, () => {
  checkScrollPos()
})

onMounted(() => {
  carouselDivs.value = document.querySelectorAll(
    '[data-carousel-id="' + uuid + '"] div.carousel-item'
  )
  updatePageWidth()
})

onUnmounted(() => {
  delete window.carousels[uuid]
})
</script>

<template>
  <p v-if="showCount" class="text-sm font-semibold border-b-2 mb-3">
    {{ showCountTitle }} ({{ numItems }} file<span v-if="numItems != 1">s</span>)
  </p>
  <!-- media carousel -->
  <div class="flex w-full relative h-64" v-if="numItems">
    <!-- Scroll Left -->
    <div v-show="!allItemsAreVisible" class="h-full flex items-center">
      <div
        :class="[
          controlClass,
          controlLeftClass,
          {
            'bg-gray-200 cursor-auto': endLeft && !isMobile
          }
        ]"
        @click.prevent="prev"
      >
        <i class="fa-solid fa-chevron-left"></i>
      </div>
    </div>
    <!-- Carousel Items -->
    <div
      class="overflow-x-auto overflow-y-hidden w-full relative h-[264px] md:h-[280px]"
      :data-carousel-id="uuid"
      @scroll="checkScrollPos"
    >
      <div class="absolute flex flow-col horizontal-scroll h-64" :class="{ mobile: isMobile }">
        <div
          v-for="entry in list"
          class="carousel-item"
          :key="entry.id"
          :class="{
            'group relative': props.downloadable
          }"
        >
          <BriefDetailsDownloadMediaButton
            v-if="props.downloadable"
            :item="entry"
            class="hidden group-hover:block absolute text-white text-right right-3 top-1.5 z-50"
          />
          <MediaItem
            :item="entry"
            :showOwner="showOwner"
            :showDescription="showDescription"
            :isAccepted="props.isAccepted"
            :imageCss="['h-64']"
            :videoCss="['h-64', 'min-w-[360px]']"
            @mediaLoaded="checkScrollPos"
            @videoPlaying="setActive"
          />
        </div>
      </div>
    </div>
    <!-- Scroll Right -->
    <div v-show="!allItemsAreVisible" class="h-64 flex items-center">
      <div
        :class="[
          controlClass,
          controlRightClass,
          {
            'bg-gray-200 cursor-auto': endRight && !isMobile
          }
        ]"
        @click.prevent="next"
      >
        <i class="fa-solid fa-chevron-right"></i>
      </div>
    </div>
  </div>
  <!-- No Items -->
  <div v-else-if="noContentMsg" class="text-sm">
    {{ noContentMsg }}
  </div>
</template>

<style lang="scss" scoped>
.cursor-auto i {
  @apply text-gray-300;
}

.horizontal-scroll {
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
  overflow-y: hidden; /* Hide vertical scrollbar */
  scrollbar-width: none;
}
/* make a css style where divs have a margin-right of 3rem except the last one */
.horizontal-scroll > div:not(:last-child) {
  @apply mr-3;
}

.horizontal-scroll:hover {
  scrollbar-width: auto;
}
.horizontal-scroll.mobile {
  scrollbar-width: none;
}
.horizontal-scroll::-webkit-scrollbar:vertical {
  display: none;
}
.video {
  @apply min-w-[300px] md:min-w-[420px] bg-black;
}
</style>

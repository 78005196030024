<script lang="ts" setup>
import { watch, ref, reactive, onMounted, onUnmounted } from 'vue'
import { useNearWalletStore } from '@/stores/near-wallet'
import { storeToRefs } from 'pinia'
import {
  getFileUrl,
  getFileIcon,
  getFileType,
  isFile,
  isPDF,
  isImage,
  isVideo
} from '@/utils/mediaFileTypes'
import { humanFileSize, fetchFileMetadata } from '@/utils/media'
import { useRouter, useRoute } from 'vue-router'
import { mobileDetect } from '@/utils/browser'

import {
  decryptMedia,
  getVideoThumb,
  getVideoStream,
  getIDfromUrl,
  getIDfromProfileUrl,
  getIDfromSubmissionUrl
} from '@/utils/mediaDecrypt'

import MediaImage from '@media/MediaImage.vue'
import MediaVideo from '@media/MediaVideo.vue'

import { parseISO, format } from 'date-fns'

const $route = useRoute()
const $router = useRouter()

const nearWalletStore = useNearWalletStore()
const { accountId } = storeToRefs(nearWalletStore)

const emit = defineEmits([
  'mediaLoaded',
  'mediaError',
  'mediaDetails',
  'videoPlaying',
  'onDelete',
  'onEdit',
  'onSelect'
])

export interface Props {
  allowDelete?: boolean
  item: any
  imageCss?: any
  videoCss?: any
  playInline?: boolean
  hideIfDead?: boolean
  hideIcons?: boolean
  isAccepted?: boolean
  showDescription?: boolean
  showIcons?: boolean
  showMeta?: boolean
  showOwner?: boolean
  showSelected?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  allowDelete: false,
  item: {},
  imageCss: [],
  videoCss: [],
  playInline: true,
  hideIfDead: false,
  hideIcons: false,
  isAccepted: false,
  showDescription: false,
  showIcons: false,
  showMeta: false,
  showOwner: false,
  showSelected: false
})

// encrypted submissions
const decryptedUrl = ref()

// pass details to interested components
const details = reactive({
  item: props.item,
  accountId: accountId.value,
  isFile: false,
  isPDF: false,
  isImage: false,
  isVideo: false,
  isBunny: false,
  isOwner: false,
  fileExtension: '',
  metadata: {
    fileicon: 'fa-file',
    filetype: props.item?.filetype || '',
    filename: props.item?.filename || '',
    filesize: props.item?.filesize || 0,
    filesizehuman: humanFileSize(props.item?.filesize || 0),
    lastmodified: props.item?.lastmodified || 0,
    lastmodifiedhuman: props.item?.lastmodifiedhuman || ''
  },
  bunnyLibraryId: '',
  bunnyVideoId: '',
  bunnyThumb: '',
  bunnyStream: '',
  isEncrypted: false,
  isDecrypted: false,
  isLandbot: false,
  isWatermarked: false
})

// filename for download
const fileName: any = ref()

const ok = ref(false)
let fetched = false
const processProps = async (nv: string, retry: number = 0) => {
  // update if we've logged in
  details.accountId = nv

  // wait for item
  if (!props?.item && retry < 3) {
    clearTimeout(window.mediaItemTimeout)
    window.mediaItemTimeout = setTimeout(() => {
      console.log('MediaItem : processProps : waiting for item', retry)
      processProps(nv, retry + 1)
    }, 1000)
    return
  }

  // bunny video / library ids
  let ids

  // get filetype
  let assetType =
    props?.item?.filetype ||
    props?.item?.media_type ||
    getFileType(props?.item?.file_url) ||
    getFileType(props?.item?.hls) ||
    getFileType(props.item?.image)

  // load meta data about files if needed
  if (props.item?.file_url?.includes('tustemp.contentedworld.com') && !fetched) {
    try {
      details.metadata = await fetchFileMetadata(props.item.file_url)
      // sometimes we get a 301 from tus
      if (!details.metadata) {
        setTimeout(async () => {
          console.info('MediaItem : processProps : fetchFileMetadata retry 1')
          await processProps(nv)
        }, 1000)
        return
      }
      fetched = true
      details.metadata.filesizehuman = humanFileSize(details.metadata.filesize)
      details.metadata.fileicon = getFileIcon(details.metadata.filetype)
      details.metadata.filesize = details.metadata.filesize || 0
      details.metadata.filesizehuman = humanFileSize(details.metadata.filesize)
      assetType = details.metadata.filetype
      // console.debug('details.metadata', details.metadata, assetType)
    } catch (e) {
      // this may 404 if tus has not finished processing
      console.log('MediaItem : processProps : fetchFileMetadata', e)
      setTimeout(async () => {
        console.info('MediaItem : processProps : fetchFileMetadata retry 2')
        await processProps(nv)
      }, 1000)
      return
    }
  } else {
    details.metadata = {
      fileicon: getFileIcon(props.item?.filetype) || 'fa-file',
      filetype: props.item?.filetype || '',
      filename: props.item?.filename || '',
      filesize: props.item?.filesize || 0,
      filesizehuman: humanFileSize(props.item?.filesize || 0),
      lastmodified: props.item?.lastmodified || 0,
      lastmodifiedhuman: props.item?.lastmodifiedhuman || ''
    }
  }

  // handle objects that point to watermarked bunny cdn
  if (props.item.watermark_video_stream_url) {
    ids = getIDfromSubmissionUrl(props.item.watermark_video_stream_url)
    details.isVideo = true
    details.isWatermarked = true
  }
  // handle objects that point to non-watermarked bunny cdn
  else if (props?.item?.file_url || props?.item?.hls || props.item.image) {
    if (props?.item?.file_url || props?.item?.hls) {
      ids = getIDfromUrl(assetType)
    } else {
      // landbot
      ids = getIDfromUrl(props?.item?.image || '')
      if (!ids) {
        ids = await getIDfromProfileUrl(props?.item?.image || '')
        details.isLandbot = true
        details.isImage = true
      }
    }
  }

  // sometimes we are given the bunny iframe
  if (props.item.file_url?.includes('iframe.mediadelivery.net')) {
    ids = getIDfromUrl(props.item.file_url)
  }

  // bunny media
  if (ids || props.item?.hls?.includes('b-cdn.net')) {
    // home page hls
    details.isBunny = true
    details.bunnyLibraryId = ids?.libraryId || ''
    details.bunnyVideoId = ids?.videoId || ''
    details.bunnyThumb = getVideoThumb(ids?.videoId || props.item?.image, ids?.libraryId || '')
    details.bunnyStream = getVideoStream(ids?.videoId || props.item?.hls, ids?.libraryId || '')
  }
  // encrypted media
  else if (props.item.briefId) {
    const media = await decryptMedia(props, accountId.value)
    details.isEncrypted = true
    if (media) {
      decryptedUrl.value = media
      details.isDecrypted = true
    }
  }

  const fileDetails = isFile(assetType)
  // flags
  if (isImage(assetType)) {
    details.isImage = true
  } else if (isVideo(assetType)) {
    details.isVideo = true
  } else if (isPDF(assetType)) {
    details.isPDF = true
  } else if (fileDetails) {
    details.isFile = true
    details.metadata.fileicon = fileDetails.fileIcon
    details.metadata.filetype = fileDetails.fileExtension
  }

  /*
  console.log(
    'assetType :',
    assetType,
    'isVideo',
    isVideo(assetType),
    'isImage',
    isImage(assetType),
    'isPDF',
    isPDF(assetType),
    'isFile',
    isFile(assetType)
  )
  */

  // images call when done, other type no
  if (!details.isImage) {
    done.value = true
  }

  // owner
  if (accountId.value && props.item.file_url?.includes(accountId.value)) {
    details.isOwner = true
  }

  // video owner
  if (
    details.isVideo &&
    (($route.fullPath.includes(accountId.value) && $route.name === 'user.profile') ||
      $route.name === 'profiles.self.uploads')
  ) {
    details.isOwner = true
  }

  // filename for download
  fileName.value = ref(getFileUrl(details.item))

  // media identified
  if (details.isImage || details.isVideo || details.isFile || details.isPDF) {
    ok.value = true
    emit('mediaDetails', details)
  } else {
    console.error('MediaItem : processProps : no media found', props)
  }

  // set appropriate controls
  setControls(isMobile.value)
}

const done = ref(false)
const loaded = () => {
  done.value = true
  emit('mediaLoaded')
}
const errored = () => {
  done.value = true
  emit('mediaError')
}

const actionClass =
  'w-8 h-8 ml-2 rounded-md cursor-pointer flex items-center justify-center transition-all bg-[#FF008B] md:bg-[rgba(0,0,0,0.4) md:hover:bg-[#FF008B]'

const showControls = ref(false)
const showEdit = ref(false)
const showDownload = ref(false)
const showDelete = ref(false)

const setControls = (show: boolean = false) => {
  // don't show at same time as the item selectors
  if (props.showSelected) {
    show = false
  }
  // always show options on profile edit upload page
  else if ($route.name === 'profiles.self.uploads') {
    show = true
  }
  // always show edit button on mobile, there is no hover event
  else if (isMobile.value) {
    show = true
  }
  // always show
  if (props.showIcons) {
    show = true
  }
  // never show
  if (props.hideIcons) {
    show = false
  }
  // activate icons on hover
  if (show) {
    // allow owners to download, edit or delete
    if (details.isOwner) {
      // only allow download for files and pdfs
      if (details.isFile || details.isPDF) {
        showDownload.value = true
      }
      // you can edit if you've got the upload id
      if ($route.name === 'user.profile' && details.item.id) {
        showEdit.value = true
      }
      if ($route.name === 'profiles.self.uploads') {
        showEdit.value = true
        showDelete.value = true
      }
    }
    // allow brands to download submissions
    if ($route.name === 'briefs.briefdetails') {
      showControls.value = true
      if (details.isFile || details.isPDF) {
        showDownload.value = true
      }
      return
    }
  }

  showControls.value = show
  return showControls.value
}

const isSelected = ref(false)

const edit = () => {
  if (props.showSelected) {
    return
  }
  // get element with class 'uploads-edit-mode'
  const el = document.getElementsByClassName('uploads-edit-mode')
  // console.log('$route.name', $route.name, el)
  // reset upload
  if (el.length || $route.name === 'profiles.self.uploads') {
    // tell uploadUppy to reset the uppy instance
    emit('onEdit')
  } else {
    // route to the edit page
    $router.push({
      name: 'profiles.self.uploads',
      params: {
        uploadId: details.item.id
      }
    })
  }
}

const onSelect = () => {
  if (!props.showSelected) {
    return
  }
  isSelected.value = !isSelected.value
  emit('onSelect', props.item, isSelected.value)
}

const onDelete = () => {
  emit('onDelete')
}

const isMobile = ref(mobileDetect())
const updatePageWidth = () => {
  isMobile.value = mobileDetect()
  setControls()
}

onMounted(() => {
  window.addEventListener('resize', updatePageWidth)
})
onUnmounted(() => {
  window.removeEventListener('resize', updatePageWidth)
})

updatePageWidth()

// watch for changes to the account id
watch(accountId, async (nv, ov) => {
  await processProps(nv)
})

setTimeout(async () => {
  await processProps('')
}, 100)

const debug = ref(false)
</script>

<template>
  <!-- debug -->
  <pre
    v-if="debug"
    class="text-xs w-[700px] overflow-auto bg-red-200 text-black p-2 pb-0 mb-3 rounded-md"
  >
<b>MEDIAITEM:</b>
details {{ details }}
showControls {{ showControls }}
  </pre>
  <div v-if="!done" class="relative">
    <div
      class="absolute z-20 w-full bg-gray-200 min-h-24 inset-0 flex items-center justify-center"
      :class="props?.imageCss"
    >
      <i class="fa-solid fa-spinner fa-spin text-gray-400 text-2xl"></i>
    </div>
  </div>
  <div v-if="ok || (!ok && !hideIfDead)" class="w-full h-full" :key="details.accountId">
    <div
      class="media-item h-full relative"
      @mouseenter="setControls(true)"
      @mouseleave="setControls(false)"
    >
      <!-- selected button -->
      <div
        v-if="showSelected"
        @click.prevent="onSelect"
        class="cursor-pointer absolute w-full h-full top-0 left-0 flex justify-end items-end"
        style="z-index: 200"
      >
        <div v-if="showSelected" title="Select">
          <i
            class="transition-all duration-500 m-1 fa-light border-[#FF008B] rounded-full"
            style="border-width: 1px"
            :class="{
              'bg-white text-white fa-circle': !isSelected,
              'bg-[#FF008B] text-white fa-circle-check': isSelected
            }"
          ></i>
        </div>
      </div>
      <!-- button layer, edit, delete, download -->
      <div
        class="absolute transition-opacity right-2 bottom-2"
        style="z-index: 200"
        :class="{
          'opacity-0': !showControls,
          'opacity-100': showControls
        }"
      >
        <div class="flex">
          <!-- edit button -->
          <div v-if="showEdit" :class="actionClass" title="Change File" @click="edit">
            <i class="text-white fa-solid fa-pen-to-square"></i>
          </div>
          <!-- download button -->
          <div v-if="showDownload" :class="actionClass" title="Download File">
            <a target="_blank" :href="fileName" download type="application/octet-stream">
              <i class="text-white fa-solid fa-download"></i>
            </a>
          </div>
          <!-- delete button -->
          <div v-if="showDelete" :class="actionClass" title="Delete File" @click="onDelete">
            <i class="text-white fa-solid fa-trash"></i>
          </div>
        </div>
      </div>

      <!-- media image also handles documents / pdfs -->
      <MediaImage
        v-if="details.isImage || details.isFile || details.isPDF"
        :hideIfDead="hideIfDead"
        :decryptedUrl="decryptedUrl"
        :details="details"
        :imageCss="props?.imageCss"
        :showMeta="props?.showMeta"
        @imageLoaded="loaded"
        @imageError="errored"
      />

      <!-- media video -->
      <MediaVideo
        v-if="details.isVideo"
        :hideIfDead="hideIfDead"
        :decryptedUrl="decryptedUrl"
        :details="details"
        :playInline="props?.playInline"
        :videoCss="props?.videoCss"
        @videoPlaying="emit('videoPlaying', $event)"
        @videoLoaded="loaded"
        @videoError="errored"
      />

      <!-- item meta data -->
      <div v-if="showOwner && details.isOwner">
        <div class="my-2 text-xs">
          <b>Format:</b> {{ details.isVideo ? 'Video' : 'Photography' }}<br />
          <b>Earnings:</b> ${{ item?.amountPaid }}
        </div>
      </div>

      <!-- description -->
      <div v-if="showDescription">
        <div class="my-2 text-xs">
          <b>{{ item.name }}</b
          ><br />
          <b>Date licensed:</b>
          {{
            item.contentAcceptedAt
              ? format(parseISO(item.contentAcceptedAt), 'dd/MM/yyyy')
              : format(parseISO(item.updatedAt), 'dd/MM/yyyy')
          }}<br />
          <b>{{ item.creatorName ? 'Creator' : 'By' }}:</b> {{ item.creatorName || item.brandName }}
        </div>
      </div>
    </div>
  </div>
</template>

export const imageResolutions = [
  { id: 'any', label: 'Any' },
  { id: '800ppi', label: '800ppi' },
  { id: '300ppi', label: '300ppi' },
  { id: '200ppi', label: '200ppi' },
  { id: '72ppi', label: '72ppi' }
]

export const videoResolutions = [
  { id: 'any', label: 'Any' },
  { id: 'SD', label: 'SD' },
  { id: 'HD', label: 'HD' },
  { id: 'FHD', label: 'FHD' },
  { id: 'QHD', label: 'QHD' },
  { id: '2K', label: '2K' },
  { id: '4K', label: '4K' },
  { id: '8K', label: '8K' },
  { id: '8K+', label: '>8K' }
]

export const aspectRatios = [
  { id: '16:9', label: '16:9' },
  { id: '9:16', label: '9:16' },
  { id: '1:1', label: '1:1' },
  { id: '6:13', label: '6:13' },
  { id: '4:3', label: '4:3' },
  { id: '16:10', label: '16:10' },
  { id: '18:5', label: '18:5' },
  { id: '1:1.77', label: '1:1.77' },
  { id: '1:1.9', label: '1:1.9' }
]

export const qualityOps = {
  all: {
    default: '',
    options: [{ id: 'any', label: 'Any' }]
  },
  image: {
    default: 'any',
    options: imageResolutions
  },
  video: {
    default: 'any',
    options: videoResolutions
  }
}

export const filterMediaType = {
  key: 'media_type',
  label: 'Media',
  default: 'all',
  options: [
    { id: 'all', label: 'All' },
    { id: 'image', label: 'Images' },
    { id: 'video', label: 'Videos' }
  ]
}

export const filterMediaResolutions = (media_type: keyof typeof qualityOps) => {
  if (!media_type) {
    media_type = 'all'
  }
  const q = qualityOps[media_type]
  return {
    key: 'media_resolution',
    label: 'Resolution',
    default: q.default,
    options: q.options
  }
}

export const filterOrientation = {
  key: 'media_orientation',
  label: 'Orientation',
  default: 'any',
  options: [
    { id: 'any', label: 'Any' },
    { id: 'landscape', label: 'Landscape' },
    { id: 'portrait', label: 'Portrait' }
  ]
}

export const filterMediaPreference = (preference: string) => ({
  key: 'media_preference',
  label: 'Preference',
  default: preference === '' ? 'all' : 'premium',
  options: [
    { id: 'all', label: 'All' },
    { id: 'premium', label: 'Premium' },
    { id: 'standard', label: 'Standard' },
    { id: 'premiumAdult', label: 'Premium Adult' },
    { id: 'standardAdult', label: 'Standard Adult' }
  ]
})

export const filterAspectRatio = {
  key: 'media_aspect_ratio',
  label: 'Aspect Ratio',
  default: '16:9',
  options: aspectRatios
}

export const filterRewards = {
  key: 'rewards_preference',
  label: 'Rewards',
  default: 'all',
  options: [
    { id: 'all', label: 'All' },
    { id: 'highestBids', label: 'Highest Bids' },
    { id: 'highestLicencing', label: 'Highest Licencing' },
    { id: 'biddingOngoing', label: 'Bidding Ongoing' },
    { id: 'noBids', label: 'No Bids' },
    { id: 'noLicencing', label: 'No Licencing' }
  ]
}

export const filterSource = {
  key: 'source',
  label: 'Source',
  default: 'upload',
  options: [
    { id: 'upload', label: 'Uploaded' },
    { id: 'social-facebook', label: 'Social : Facebook' },
    { id: 'social-twittter', label: 'Social : Twitter' },
    { id: 'briefs', label: 'Source: Creative Briefs' }
  ]
}

export const sortTime = {
  key: 'sort_time',
  label: 'Sort',
  default: 'less',
  options: [
    { id: 'less', label: 'Less Time' },
    { id: 'most', label: 'Most Time' },
    { id: 'new', label: 'Newest' }
  ]
}

export const sortTimeSubmissions = {
  key: 'sort_time_submissions',
  label: 'Sort',
  default: 'less',
  options: [
    { id: 'less', label: 'Less Time' },
    { id: 'most', label: 'Most Time' },
    { id: 'new', label: 'Newest' },
    { id: 'least', label: 'Least Submissions' },
    { id: 'most', label: 'Most Submissions' }
  ]
}

import type { Plugin } from 'vue'
import { create } from 'ipfs-http-client'

export default <Plugin>{
  install: (app) => {
    app.config.globalProperties.$ipfsHttp = create({
      url: 'https://api.thegraph.com/ipfs/api/v0'
    })
  }
}

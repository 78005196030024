<script lang="ts" setup>
import { nextTick, onMounted, ref, watch } from 'vue'
import { FormKitMessages } from '@formkit/vue'
import { fetchProfile } from '@/queries/api'
import { useNearWalletStore } from '@/stores/near-wallet.js'
import { nextPageForProfile, updateProfileContent } from '@/utils/storeProfile'

import { storeToRefs } from 'pinia'
import { v4 as uuidv4 } from 'uuid'

import _ from 'lodash'

import SpinnerOverlay from '@/components/SpinnerOverlay.vue'
import UploadUppy from '@upload/UploadUppy.vue'
import UserLayout from '@/Layouts/UserLayout.vue'
import Web3AuthWrapper from '@/components/Web3AuthWrapper.vue'

import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'

const emit = defineEmits(['onUploaded', 'onCancel'])

const props = withDefaults(
  defineProps<{
    uploadId?: string
  }>(),
  {
    uploadId: ''
  }
)

const loading = ref(true)
const form = ref()
const showFileUpload = ref(true)
const values = ref({
  id: '',
  name: '',
  description: '',
  tags: '',
  file_url: ''
})
const valuesVersion = ref(0)

// load data
const nearWalletStore = useNearWalletStore()

const { accountId } = storeToRefs(nearWalletStore)

const uploadField = 'upload-' + uuidv4()
const uploadValue = ref()

const profile = ref({} as any)

const loadProfile = async (id: string) => {
  // fetch the record
  const response = await fetchProfile(id)

  // normalise
  let entry = _.cloneDeep(response)
  entry.socials = entry.socials || {}

  nextTick(() => {
    // update the profile
    profile.value = entry

    // wait before showing to let all the state propogate
    loading.value = false
  })
}

watch(accountId, (nv, ov) => {
  if (nv !== ov) {
    loadProfile(accountId.value)
  }
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
watch(profile, (nv: any, ov) => {
  // hydrate the "values" ref if we can
  if (props.uploadId && nv.contents) {
    const uploadedContent = nv.contents.find((c: any) => c.id == props.uploadId)

    values.value = {
      id: uploadedContent?.id || values.value.id,
      name: uploadedContent?.name || values.value.name,
      description: uploadedContent?.short_description || values.value.description,
      tags: uploadedContent?.tags?.length ? uploadedContent.tags.join(', ') : values.value.tags,
      file_url: uploadedContent?.file_url || values.value.file_url
    }

    valuesVersion.value = valuesVersion.value + 1

    showFileUpload.value = values.value?.file_url?.length <= 0
    uploadValue.value = JSON.stringify([values.value])
  }
})

// enable save button when metadata is saved
const metaDataSaved = ref(false)
const onMetaDataSaved = (state: boolean) => {
  // console.log('Uploads: onMetaDataSaved', state)
  metaDataSaved.value = state
}

// save details of uploaded files / metadata
let uploadedData: any
const onUploaded = (data: any) => {
  uploadedData = data
}

// Save to database
const onSubmit = async () => {
  try {
    loading.value = true
    await updateProfileContent(nearWalletStore, uploadedData)
    // redirect user back if we succeeded and we have a return link
    nextPageForProfile(accountId.value)
  } catch (e) {
    console.error('Error saving profile', e)
  } finally {
    // don't hide the spinner for a bit in case we are redirecting
    setTimeout(() => {
      loading.value = false
    }, 2000)
  }
}

const onCancel = () => {
  nextPageForProfile(accountId.value)
  emit('onCancel')
}

onMounted(() => {
  if (accountId.value) {
    loadProfile(accountId.value)
  }
})
</script>

<template>
  <UserLayout>
    <Web3AuthWrapper>
      <SpinnerOverlay v-if="loading" />
      <div class="w-full max-w-xl mx-auto" :key="valuesVersion">
        <div class="mt-3">
          <h1 class="text-2xl font-medium tracking-wider">Portfolio</h1>
        </div>
        <div v-if="accountId" v-show="!loading">
          <FormKit type="form" ref="form" :actions="false" @submit="onSubmit">
            <div class="my-6 text-sm text-red-600 hidden" v-if="form?.node">
              <FormKitMessages :node="form.node" />
            </div>
            <UploadUppy
              :uploadId="uploadField"
              :uploadValue="uploadValue"
              :uploadAccountId="accountId"
              :multiSelect="false"
              :showMeta="true"
              @onMetaDataSaved="onMetaDataSaved"
              @onUploaded="onUploaded"
              @onCancel="onCancel"
            />
            <div v-if="metaDataSaved" class="mt-3 select-none space-x-4">
              <input type="submit" class="action-button action-save-meta solid" value="Save" />
              <input
                type="button"
                class="action-button action-save-meta hollow"
                value="Cancel"
                @click="onCancel"
              />
            </div>
          </FormKit>
        </div>
      </div>
    </Web3AuthWrapper>
  </UserLayout>
</template>

<style scoped></style>

<script lang="ts" setup>
import { useCotoStore } from '@/stores/coto'

const coto = useCotoStore()

/* We are now using USDC, coto always = 1 */

withDefaults(
  defineProps<{
    value: number
    format: boolean
    decimals: number
    locale: string
  }>(),
  {
    value: 0.0,
    format: true,
    decimals: 2,
    locale: 'en'
  }
)

const formatNumber = (num: number, minimumFractionDigits = 2, locale = 'en') => {
  if (isNaN(num)) return num
  return num.toLocaleString(locale, {
    minimumFractionDigits: minimumFractionDigits
  })
}
</script>

<template>
  ${{ format ? formatNumber(coto.fx_rate * value) : coto.fx_rate * value }} <small>USDC</small>
</template>

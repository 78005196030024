<script lang="ts" setup>
// @ts-nocheck
import { ref, watchEffect } from 'vue'
import { Styles } from '@/styles'
import { getBriefExpiryDate } from '@/utils/dateChecks'
import { usePricingConfigurationStore } from '@/stores/pricing-configurations'
import { calcLicencingPrice } from '@/utils/licencingPriceCalc'

import PageSectionTitle from '@/components/PageSectionTitle.vue'
import TimeUntilExpired from '@/components/TimeUntilExpired.vue'
import CurrencyValue from '@/components/CurrencyValue.vue'
import FixedHeightMedia from '@/components/Media/FixedHeightMedia.vue'
import Link from '@/components/Link.vue'
import PopupImage from '@media/PopupImage.vue'

const emit = defineEmits(['open', 'view', 'license'])
const pricingChannel = usePricingConfigurationStore()
const props = defineProps({
  brief: Object,
  showImages: {
    type: Boolean,
    default: false
  }
})

// const makeLink = (net) => net.toLowerCase().replace(' ', '')

const viewBrief = () => {
  emit('view', props.brief.index)
}

// const licenseBrief = (index) => emit('license', index)

// const openBrief = () => emit('open', props.brief.index)

const isOpen = ref(false)

const contents = ref([])

watchEffect(() => {
  if (props.brief) {
    // Calculate licencing price for each content
    setTimeout(() => {
      for (const content of props.brief.contents) {
        contents.value.push({ ...content, licencingPrice: calcLicencingPrice(content) })
      }
    }, 500)
  }
})

const handleImageClick = (item) => {
  console.log(item)
  window.popupImage = item.link
}

const id = 'id_' + props.brief.id.replaceAll('-', '_')
</script>

<style>
#id_e310c28a_8c81_4f05_b7c9_ff878711f0d5 {
  display: none;
}
</style>

<template>
  <div
    class="bg-white hover:bg-gray-100 p-5 shadow-md border flex items-center cursor-pointer"
    @click="isOpen = !isOpen"
    :id="id"
    :class="{
      'bg-gray-100 rounded-t-md': brief.open,
      'rounded-md': !brief.open
    }"
  >
    <PopupImage />
    <div class="flex-grow">
      <PageSectionTitle>
        {{ brief.titles[0] }}
      </PageSectionTitle>
      <p class="text-pink-600 font-bold">
        <TimeUntilExpired :dateTime="getBriefExpiryDate(brief.created_on)" endedText="Completed" />
      </p>
      <p class="text-sm">Submitted responses: {{ brief.contents.length }}</p>
    </div>
    <div class="flex-none">
      <i v-if="isOpen" class="fa-2x fa-light fa-angle-up"></i>
      <i v-if="!isOpen" class="fa-2x fa-light fa-angle-down"></i>
    </div>
  </div>

  <div v-if="isOpen">
    <div class="bg-white rounded-b-md shadow-md border p-5">
      <p><b>Objective</b></p>
      <p>{{ brief.summary }}</p>

      <div class="flex pt-3">
        <div class="flex flex-grow">
          <div class="mr-6">
            <p><b>Channels</b></p>
            <p v-for="item in brief.content_usages" :key="item.id">
              {{ item.type }}-{{ item.value }}
            </p>
          </div>
          <div class="mr-6">
            <p><b>Accepted content types</b></p>
            <p v-for="item in brief.campaign_formats" :key="item.id">
              {{ item.media_type }}-{{ item.quality }}-{{ item.aspect_ratio }}
            </p>
          </div>
          <div class="mr-6">
            <p><b>Restrictions</b></p>
            <p v-for="txt in brief.restrictions" :key="txt">
              {{ txt }}
            </p>
          </div>
        </div>
        <div class="flex-none" v-if="!showImages">
          <div class="flex h-full items-end">
            <input
              class="bg-[#08A7DE] text-white hover:text-black"
              value="View"
              @click="viewBrief"
              type="submit"
              :class="Styles.submit"
            />
          </div>
        </div>
      </div>
      <div class="w-full my-3">
        <h4 class="font-bold">Pricing</h4>
        <div>
          <div
            v-for="(item, indx) in brief.content_usages"
            :key="indx"
            class="flex flex-wrap items-center border rounded-md px-3 py-3 my-3"
          >
            <div class="my-1 w-full lg:w-3/12">
              <div class="lg:mx-2 font-semibold text-transform: capitalize">
                {{ item.type }}
              </div>
            </div>
            <div class="w-full lg:w-9/12">
              <div class="my-1 w-full lg:mx-2">
                <strong>
                  <CurrencyValue :value="parseFloat(pricingChannel.getChannelPrice(item.type))" />
                </strong>
                <small v-if="item.value"> / {{ item.value }} </small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="1 == 2">
        <div class="w-full mt-6">
          <PageSectionTitle class="mb-3"> Upload Guidance </PageSectionTitle>
          <div v-if="brief.media_items && !!brief.media_items.length">
            <FixedHeightMedia :items="brief.media_items" />
          </div>
          <div v-else>
            <p>None</p>
          </div>
        </div>
      </div>

      <div>
        <div class="w-full mt-3">
          <PageSectionTitle class="mb-3"> Attached Media </PageSectionTitle>
          <div v-for="item in brief.media_items" :key="item.id">
            <img
              class="cursor-pointer"
              @click.stop="handleImageClick(item)"
              width="200"
              height="200"
              v-if="item.type === 'image'"
              :src="item.link"
            />
            <div v-else>
              <Link :href="item.link" class="text-white text-xs bg-blue-900 rounded-full p-2 py-1"
                >Download PDF</Link
              >
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="w-full mt-3">
          <PageSectionTitle class="mb-3"> Submitted responses </PageSectionTitle>
          <div v-if="contents && !!contents.length">
            <FixedHeightMedia :items="contents" mode="license" />
          </div>
          <div v-else>
            <p>There are no responses to show at the moment. Please check again later.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, nextTick } from 'vue'
import { onKeyStroke } from '@vueuse/core'

const props = withDefaults(
  defineProps<{
    selectedItems: any
    isOwner: boolean
    accountId: string
  }>(),
  {
    selectedItems: [],
    isOwner: false,
    accountId: ''
  }
)

const emit = defineEmits([
  'onEdit', // icon clicked
  'onDelete', // icon clicked
  'onFilter', // icon clicked
  'onEditFiles', // edit selected files
  'onDeleteFiles', // delete selected files,
  'onShowSelected', // allow items to be ticked
  'onCancel' // cancel
])

const iconClass = 'cursor-pointer fa-light hover:text-[#FF008B]'

const clearStates = () => {
  showModal.value = false
  editSelected.value = false
  deleteSelected.value = false
  filterSelected.value = false
  // click any selected objects to reset the page
  document.querySelectorAll('.fa-circle-check').forEach((el) => {
    ;(el as HTMLElement).click()
  })
  // remove selectors on media items
  emit('onShowSelected', false)
}

const showModal = ref(false)

// edit icon
const editSelected = ref(false)
const onEdit = () => {
  clearStates()
  editSelected.value = true
  emit('onShowSelected', true)
}

// delete icon
const deleteSelected = ref(false)
const onDelete = () => {
  clearStates()
  deleteSelected.value = true
  emit('onShowSelected', true)
}

// filter icon
const filterSelected = ref(false)
const onFilter = () => {
  clearStates()
  filterSelected.value = true
  emit('onFilter')
}
const onCancel = () => {
  clearStates()
  emit('onCancel')
}

// show delete modal
const onDeleteModal = () => {
  // nothing selected
  if (!props.selectedItems.length) {
    return
  }
  // show modal to confirm delete
  showModal.value = true
  // center modal in viewport
  nextTick(() => {
    const modal = document.getElementById('delete-modal')
    if (modal) {
      const vert = window.innerHeight / 2 - modal.offsetHeight / 2
      modal.style.top = vert + 'px'
    }
  })
}

// send the list of files to edit
const onEditFiles = () => {
  emit('onEditFiles')
}

// send the list of files to delete
const onDeleteFiles = () => {
  emit('onDeleteFiles')
  showModal.value = false
  // allow time for the files to be deleted
  setTimeout(() => {
    clearStates()
  }, 1000)
}

onKeyStroke(['Escape', 'Esc'], (e) => {
  e.preventDefault()
  clearStates()
})
</script>

<template>
  <!-- Media Selectors -->
  <div class="flex flex-col min-h-8 md:flex-row gap-4 items-center transition-all duration-1000">
    <div class="text-xs mt-3 md:mt-0 text-gray-400">
      <span v-if="selectedItems.length">{{ selectedItems.length }} selected</span>
      <span v-else v-if="editSelected || deleteSelected"
        >Select files to <span v-if="editSelected">edit</span><span v-else>delete</span></span
      >
    </div>
    <!-- delete, edit, filter icons-->
    <div class="flex mt-3 md:mt-0 gap-4 items-center">
      <i
        v-if="isOwner"
        @click="onDelete"
        class="fa-trash-can"
        :class="[iconClass, { 'text-gray-400': !deleteSelected, 'text-[#FF008B]': deleteSelected }]"
      ></i>
      <i
        v-if="isOwner"
        @click="onEdit"
        class="fa-edit"
        :class="[iconClass, { 'text-gray-400': !editSelected, 'text-[#FF008B]': editSelected }]"
      ></i>
      <!-- <i
        @click="onFilter"
        class="fa-filter"
        :class="[iconClass, { 'text-gray-400': !filterSelected, 'text-[#FF008B]': filterSelected }]"
      ></i> -->
    </div>
    <!-- delete, edit, cancel buttons -->
    <div class="flex gap-4 mt-3 md:m-0">
      <div
        @click="onDeleteModal"
        v-show="deleteSelected"
        class="action-button small inline w-auto"
        :class="{ hollow: !selectedItems.length, solid: selectedItems.length }"
      >
        Delete
      </div>
      <div
        @click="onEditFiles"
        v-show="editSelected"
        class="action-button small inline w-auto"
        :class="{ hollow: !selectedItems.length, solid: selectedItems.length }"
      >
        Edit
      </div>
      <div
        @click="onCancel"
        v-show="editSelected || deleteSelected"
        class="action-button small inline w-auto hollow"
      >
        Cancel
      </div>
    </div>
  </div>
  <!-- Delete Files Confirmation Modal -->
  <div
    v-show="showModal"
    class="fixed top-0 left-0 w-full h-full flex items-center justify-center"
    style="z-index: 300"
  >
    <div
      id="delete-modal"
      class="flex flex-col gap-6 p-10 shadow-md rounded-md bg-[rgba(250,250,250,0.95)] items-center justify-center"
    >
      <div class="flex font-bold gap-4 items-center text-[#FF008B]">
        <i class="fa-light fa-3x fa-hexagon-exclamation"></i>
        Delete ({{ selectedItems.length }} files)
      </div>
      <div class="text-sm text-center">
        Are you sure you want to delete the selected files?<br />Deleted files cannot be recovered.
      </div>
      <div class="flex gap-4 items-center">
        <div @click="onDeleteFiles" class="action-button small inline w-auto hollow">Delete</div>
        <div @click="showModal = false" class="action-button small inline w-auto hollow">
          Cancel
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
// @ts-nocheck
import { ref, reactive, watchEffect } from 'vue'
import { format as dateFormat } from 'date-fns'
import { groupBy } from 'lodash'
import { getLicensedContent } from '@/queries/content'
import Link from '@/components/Link.vue'
import { saveResult } from '@/api/requests'
import { storeToRefs } from 'pinia'
import { useNearWalletStore } from '@/stores/near-wallet'
import { useQuery } from '@vue/apollo-composable'

import MarketplaceLayout from '@/Layouts/MarketplaceLayout.vue'
import PageTitleHeading from '@/components/PageTitleHeading.vue'
import PageSectionTitle from '@/components/PageSectionTitle.vue'
import FixedHeightMedia from '@/components/Media/FixedHeightMedia.vue'

import Loading from '@/components/Loading.vue'
import Alert from '@/components/Alert.vue'
import LoginRequired from '@/components/LoginRequired.vue'
import getConfig from '@/config/config'

defineProps({
  //
})

const groups = ref(false)

const contents = ref([])
// const filters = ref()

const params = reactive({
  accountId: ''
})

const nearWalletStore = useNearWalletStore()
const { accountId } = storeToRefs(nearWalletStore)

const { result, loading, error, refetch } = useQuery(getLicensedContent(), params)

watchEffect(() => {
  if (getConfig().TEST_MODE) {
    params.accountId = getConfig().TEST_ACCOUNT_ID
  } else {
    if (typeof accountId !== 'undefined' && !accountId) {
      // not logged in yet
      return
    }
    params.accountId = accountId.value
  }
  if (result && result.value) {
    contents.value = result.value.contentLicences.map((e) => {
      return {
        ...e.content,
        spent: e.price
      }
    })
    groups.value = groupBy([...contents.value], (item) =>
      dateFormat(parseInt(item.created_on), 'yyyy-LL')
    )

    // save result
    saveResult(params, {
      groups: groups.value,
      contents: contents.value
    })
  }
})

const groupdate = (timestamp) => {
  return dateFormat(parseInt(timestamp), 'LLLL yyyy')
}
</script>

<template>
  <MarketplaceLayout :title="$t('marketplace.licensed.title')" selectedLinkId="licensed">
    <template #header>
      <div class="md:flex flex-wrap items-start justify-between">
        <PageTitleHeading>
          {{ $t('marketplace.licensed.title') }}
        </PageTitleHeading>
        <!-- <SearchFilters v-if='result && result.contentLicences.length > 0' @update='update' /> -->
      </div>
    </template>
    <LoginRequired :message="$t('marketplace.licensed.restricted.login')">
      <Loading v-if="loading" />
      <Alert v-else-if="error" type="error" :text="error" />
      <div v-else-if="groups" class="flex flex-wrap -mt-6">
        <div class="my-6" v-if="result.contentLicences.length == 0">
          <p>
            - You have no licensed content, head to the
            <Link
              class="text-cm-blue-300"
              :href="route('marketplace.licensing', { media_preference: 'premium' })"
              ><b>Stock Library</b></Link
            >
            to choose some content.
          </p>
        </div>
        <div class="my-3 w-full" v-for="(items, date) in groups" :key="date">
          <div class="my-3">
            <PageSectionTitle class="font-bold text-cm-blue-500 tracking-wider text-lg">
              {{ groupdate(items[0].created_on) }}
            </PageSectionTitle>
          </div>
          <FixedHeightMedia :items="items" mode="download" @delete="refetch" />
        </div>
      </div>
    </LoginRequired>
  </MarketplaceLayout>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { type RouteLocationRaw } from 'vue-router'

const props = withDefaults(
  defineProps<{
    ariaLabel?: string
    links?: Array<{
      name: string
      icon: string
      to: RouteLocationRaw
      show: boolean
      isSelected: boolean
    }>
  }>(),
  {
    ariaLabel: 'Navigation',
    links: () => []
  }
)

const linksShowing = computed(() => props.links.filter((item) => item.show))
</script>

<template>
  <nav
    v-if="linksShowing?.length > 0"
    :aria-label="ariaLabel"
    class="w-full mb-3 h-10 text-center border-b-2 border-cw-primary relative overflow-x-scroll md:overflow-hidden md:border-0 md:-ml-3"
  >
    <div class="absolute flex whitespace-nowrap">
      <router-link
        v-for="item in linksShowing"
        :to="item.to"
        :class="{
          'cursor-pointer font-medium py-1.5 px-3  capitalize ': true,
          'text-gray-900 hover:text-cw-primary': !item.isSelected,
          'text-cw-primary font-semibold tracking-wider': item.isSelected
        }"
      >
        {{ item.name }}
      </router-link>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { AxiosError } from 'axios'
import { computed, ref, watchEffect } from 'vue'
import { createTeamRole, deleteTeamRole, updateTeamRole } from '@/queries/api'
import Modal from '@/components/Jetstream/Modal.vue'
import AlertDialog from '@/components/AlertDialog.vue'
import ActionButton from '@/components/Pages/Teams/Partial/ActionButton.vue'
import { useTeamStore } from '@/stores/team'
import { storeToRefs } from 'pinia'
import { Vue3Lottie } from 'vue3-lottie'
import LottieLoadingBlocks from '@/lottie/loading-blocks-2.json'

const props = withDefaults(
  defineProps<{
    type: 'uuid' | 'nickname'
    typeValue: string
  }>(),
  {
    type: 'uuid',
    typeValue: ''
  }
)

const teamStore = useTeamStore()
const { roles, availablePermissions, membersByRoleUuid, invitesByRoleUuid } = storeToRefs(teamStore)
const { can: teamCan, cannot: teamCannot } = teamStore

const loadingRoles = ref<boolean>(false)
const loadingAvailablePermissions = ref<boolean>(false)

const rolePermissions = computed((): Record<string, string> => {
  return availablePermissions.value
    .filter((permission) => !permission.hidden)
    .reduce(
      (acc, permission) => ({
        ...acc,
        [permission.name]: permission.description
      }),
      {}
    )
})

const updatingRoleUuid = ref<string | undefined>(undefined)
const showCreateRoleModal = ref<boolean>(false)

const confirmDialog = ref<InstanceType<typeof AlertDialog> | null>(null)

const formServerErrors = ref<string[]>()

type FormRoleType = {
  name: string
  displayName: string
  permissions: string[]
}

const formRole = ref<FormRoleType>({
  name: '',
  displayName: '',
  permissions: []
})

const formRoleName = computed({
  get: () => formRole.value.name,
  set: (value: string) => {
    formRole.value.name = value.toLowerCase()
  }
})

const loadRoles = async () => {
  loadingRoles.value = true
  if (teamCan('team.roles.list')) {
    await teamStore.fetchTeamRoles(props.type, props.typeValue)
  }
  loadingRoles.value = false
}

const loadAvailablePermissions = async () => {
  loadingAvailablePermissions.value = true
  await teamStore.fetchAvailablePermissions(props.type, props.typeValue)
  loadingAvailablePermissions.value = false
}

watchEffect(async () => {
  loadRoles()
  loadAvailablePermissions()
})

const onUpdateClick = async (role: TeamRoleWithPermissions) => {
  formRole.value = {
    name: role.name,
    displayName: role.displayName,
    permissions: role?.permissions || []
  }
  formServerErrors.value = []
  updatingRoleUuid.value = role.uuid
  showCreateRoleModal.value = true
}

const onCreateClick = async () => {
  formRole.value = {
    name: '',
    displayName: '',
    permissions: []
  }
  formServerErrors.value = []
  updatingRoleUuid.value = undefined
  showCreateRoleModal.value = true
}

const onCloseModal = () => {
  onCreateClick()
  showCreateRoleModal.value = false
}

const onSubmit = async (data: FormRoleType) => {
  try {
    if (updatingRoleUuid.value && teamCan('team.roles.update')) {
      await updateTeamRole(props.type, props.typeValue, updatingRoleUuid.value, data)
      await loadRoles()
      onCloseModal()
    } else if (!updatingRoleUuid.value && teamCan('team.roles.create')) {
      await createTeamRole(props.type, props.typeValue, data)
      await loadRoles()
      onCloseModal()
    } else {
      formServerErrors.value = [
        `You do not have permission to ${updatingRoleUuid.value ? 'update' : 'create'} roles.`
      ]
    }
  } catch (err) {
    // get the errors
    const extractedErrors = [
      err instanceof AxiosError
        ? err.response?.data?.message || 'Something went wrong while saving this role.'
        : (err as Error).message || 'Something went wrong while saving this role.'
    ]

    // map the errors from the server error to a more friendly one locally
    const extractedErrorsMap: Record<string, string> = {
      [`duplicate key value violates unique constraint "idx_team_roles_team_uuid_name"`]: `A role with this name already exists. Please choose a different name.`
    }

    formServerErrors.value = extractedErrors.map((extractedError) => {
      return extractedErrorsMap[extractedError] || extractedError
    })
  }
}

const onRemove = async (roleUuid: string) => {
  try {
    if (teamCannot('team.roles.delete')) {
      throw Error('You do not have permission to remove role.')
    }

    const ok = await confirmDialog.value?.show({
      title: 'Remove role',
      message: `Are you sure you want to remove this role?`,
      okButton: 'Yes',
      cancelButton: 'No'
    })

    if (ok) {
      await deleteTeamRole(props.type, props.typeValue, roleUuid)
      loadRoles()
    }
  } catch (err) {
    if (err !== false) {
      await confirmDialog.value?.show({
        title: 'Error',
        message:
          err instanceof AxiosError
            ? err.response?.data.message || 'Something went wrong while removing this role.'
            : (err as Error).message || 'Something went wrong while removing this role.',
        okButton: 'Ok'
      })
    }
  }
}
</script>

<template>
  <div>
    <!-- Title -->
    <div class="flex items-center">
      <h2 class="text-xl tracking-wide font-medium">Roles</h2>
      <span v-if="teamCan('team.roles.create')">
        <ActionButton icon="fa-solid fa-plus-circle" @click.prevent="onCreateClick">
          Create Role
        </ActionButton>
      </span>
    </div>

    <!-- Table showing Open Invites -->
    <div>
      <div v-if="loadingRoles">
        <Vue3Lottie :animationData="LottieLoadingBlocks" :height="50" :width="50" />
      </div>
      <div class="table-cmg" v-else-if="roles && roles?.length > 0">
        <table>
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in roles" :key="item.uuid">
              <th scope="row" class="w-4/5">{{ item.displayName || item.name || '' }}</th>
              <td class="flex flex-row flex-wrap items-center">
                <div>
                  <span
                    v-if="
                      teamCannot('team.roles.delete') ||
                      !item.teamUuid ||
                      Number(membersByRoleUuid[item.uuid] || 0) > 0 ||
                      Number(invitesByRoleUuid[item.uuid] || 0) > 0
                    "
                  >
                    &mdash;
                  </span>
                  <span v-else>
                    <button
                      type="button"
                      class="font-medium text-cw-primary hover:underline"
                      @click.prevent="onRemove(item.uuid)"
                      title="Remove"
                    >
                      <i class="fa-light fa-trash"></i>
                      <span class="sr-only">Remove</span>
                    </button>
                  </span>
                </div>
                <div class="ml-2 md:ml-3">
                  <span v-if="teamCannot('team.roles.update') || !item.teamUuid"> &mdash; </span>
                  <span v-else>
                    <button
                      type="button"
                      @click.prevent="
                        onUpdateClick({
                          ...item
                        } as TeamRoleWithPermissions)
                      "
                      class="font-medium text-cw-primary hover:underline"
                      title="Update"
                    >
                      <i class="fa-light fa-edit"></i>
                      <span class="sr-only">Remove</span>
                    </button>
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p class="text-sm text-gray-500">No roles found.</p>
      </div>
    </div>

    <!-- Remove Role Modal -->
    <AlertDialog ref="confirmDialog"></AlertDialog>

    <!-- Create Role Modal -->
    <Modal :show="showCreateRoleModal" @close="onCloseModal" :closeable="true">
      <div class="bg-white p-6 rounded-lg border border-gray-300">
        <div class="w-full font-medium flex justify-between">
          <div class="text-xl">Create Role</div>
          <div @click="onCloseModal" class="text-gray-400 hover:text-blue-400 cursor-pointer">
            <i class="fa fa-times"></i>
          </div>
        </div>
        <div>
          <FormKit type="form" :actions="false" @submit="onSubmit" :errors="formServerErrors">
            <div class="my-1">
              <FormKit
                type="text"
                name="name"
                label="Name"
                validation="required|length:3,100|alphanumeric"
                placeholder="This is the internal name of this role."
                help="This can only be lowercase and alpha numeric"
                v-model="formRoleName"
              />
            </div>
            <div class="my-1">
              <FormKit
                type="text"
                name="displayName"
                label="Display Name"
                validation="required|length:3,255"
                placeholder="This is the friendly name shown to users."
                v-model="formRole.displayName"
              />
            </div>
            <div class="-mt-1.5">
              <div id="rolePermissions" class="relative">
                <FormKit
                  type="checkbox"
                  name="permissions"
                  label="Permissions"
                  help="Please select at least one option from the list below."
                  :options="rolePermissions"
                  v-model="formRole.permissions"
                  on-value="true"
                  off-value="false"
                  validation="required|min:1"
                  validation-label="At least one permission"
                  :delay="200"
                />
              </div>
            </div>
            <div class="flex item-center justify-between">
              <FormKit
                type="submit"
                label="Submit"
                input-class="rounded-full px-6 text-sm font-bold"
                outer-class="text-center"
              />
            </div>
          </FormKit>
        </div>
      </div>
    </Modal>
  </div>
</template>

<style lang="scss">
#rolePermissions {
  @apply my-0 w-full overflow-x-hidden;
  ul {
    @apply mt-3 block;
    li {
      @apply w-full md:w-1/2 inline-block;
      label {
        @apply select-none cursor-pointer;
      }
    }
  }
}
</style>

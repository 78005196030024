<script lang="ts" setup>
import { computed, nextTick, onMounted, ref, watch, watchEffect } from 'vue'
import { type FetchSmartBriefsParams } from '@/queries/api'
import { useHead } from '@unhead/vue'
import { useRouteQuery } from '@vueuse/router'
import { useWindowSize } from '@vueuse/core'
import { useBriefStore } from '@/stores/briefs'
import { route } from '@/utils/route'
import { storeToRefs } from 'pinia'
import _ from 'lodash'

import SmartBriefCard from '@briefs/SmartBriefCard.vue'
import SpinnerOverlay from '@/components/SpinnerOverlay.vue'
import JoinTile from '@/components/Pages/Creatives/JoinTile.vue'
import PaginationLinks from '@/components/PaginationLinks.vue'

const isArrayEmpty = (arr: any[]) => arr.length === 0

useHead({
  title: 'View all briefs | Contented'
})

const props = withDefaults(
  defineProps<{
    team?: Team
    title?: string
    showJoinTile?: boolean
  }>(),
  {
    title: 'Briefs',
    showJoinTile: true
  }
)

const { width } = useWindowSize()

const briefStore = useBriefStore()

const { briefs } = storeToRefs(briefStore)

const briefsPage = ref<SmartBrief[]>([])

const loading = ref<boolean>(isArrayEmpty(briefs.value))

const briefsIsEmpty = computed(() => isArrayEmpty(briefsPage.value))

const fetchBriefs = async (option: FetchSmartBriefsParams['option']) => {
  loading.value = isArrayEmpty(briefStore.briefs)
  await briefStore.fetchBriefs({
    option,
    teamNickname: props?.team?.nickname
  })
  loading.value = false
}

const selectedOption = useRouteQuery<FetchSmartBriefsParams['option']>('filter', 'live')

// related to pagination
const totalPages = ref(0)
const currentPage = useRouteQuery('page', 1, { transform: Number })

const switchPage = (page: number) => {
  if (page === currentPage.value) {
    return
  }

  // scroll up smoothly
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })

  nextTick(() => {
    currentPage.value = page
  })
}

const briefPaging = async (briefsValue: SmartBrief[], pageWidth: number, page: number = 1) => {
  // page size is variable based on the screen size
  const pageSizes: Record<number, number> = {
    0: 10, // 'w-full'
    768: 10, // 'md:w-1/2'
    1024: 12, // 'lg:w-1/3'
    1280: 16 // 'xl:w-1/4'
  }

  let extraTiles = 0
  if (props?.showJoinTile) {
    extraTiles++
  }

  const pageSize = Object.keys(pageSizes)
    .map((pageSize) => Number(pageSize))
    .reduce((pageSize, key) => {
      return pageWidth >= key ? pageSizes[key] - extraTiles : pageSize
    }, 0)

  // calculate the total pages
  totalPages.value = Math.ceil(_.chunk(briefsValue, pageSize).filter((d) => d.length > 0)?.length)

  // return the number of briefs for the page size
  const pageStart = (page - 1) * pageSize
  const pageEnd = pageStart + pageSize
  briefsPage.value = briefsValue.slice(pageStart, pageEnd)
}

const ensurePageIsValid = () => {
  setTimeout(() => {
    if (currentPage.value < 1) {
      currentPage.value = Math.max(currentPage.value, 1)
    }
    if (currentPage.value > totalPages.value) {
      currentPage.value = Math.min(currentPage.value, totalPages.value)
    }
  }, 0)
}

watch(
  () => ({
    briefs: briefs.value,
    width: width.value,
    currentPage: currentPage.value
  }),
  async (nv, ov) => {
    const hasChanged =
      _.difference(nv.briefs, ov.briefs).length > 0 ||
      nv.width !== ov.width ||
      nv.currentPage !== ov.currentPage

    if (hasChanged || nv?.briefs?.length === 0) {
      await briefPaging(briefs.value, width.value, currentPage.value)
      ensurePageIsValid()
    }
  }
)

watch(selectedOption, async () => {
  await fetchBriefs(selectedOption.value)
  ensurePageIsValid()
})

onMounted(async () => {
  await fetchBriefs(selectedOption.value)
  ensurePageIsValid()
})
</script>
<template>
  <SpinnerOverlay v-if="loading" />
  <div
    class="flex items-center mb-4"
    :class="{
      'justify-between': props.title,
      'justify-end': !props.title
    }"
  >
    <h2 v-if="props.title" class="text-xl font-bold">{{ props.title }}</h2>
    <select v-model="selectedOption" class="form-select relative z-10">
      <option value="live">Live</option>
      <option value="archived">Archive</option>
    </select>
  </div>
  <div v-if="!loading">
    <div v-if="!briefsIsEmpty">
      <ul class="flex flex-wrap -ml-3 -mr-3">
        <li v-for="brief in briefsPage" :key="brief.id" class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
          <div class="mx-3 my-3">
            <SmartBriefCard
              :brief="brief"
              :is-bottom-corners-rounded="false"
              :href="
                route({
                  name: 'briefs.briefdetails',
                  params: {
                    brief_id: brief?.slug || brief.id
                  }
                })
              "
            />
          </div>
        </li>
        <li class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4" v-if="props?.showJoinTile">
          <router-link
            :to="{
              name: 'creatives.start'
            }"
          >
            <div class="mx-3 my-3">
              <JoinTile />
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <div v-if="briefsIsEmpty">
      <p class="text-sm text-gray-500">
        We're sorry, there are no live briefs open, please check back later.
      </p>
    </div>
    <PaginationLinks
      :totalPages="totalPages"
      :currentPage="currentPage"
      @switchPage="switchPage"
      :arrowPagination="false"
    />
  </div>
</template>

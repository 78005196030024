<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { onMounted, onUnmounted, onBeforeUnmount, ref, watchEffect } from 'vue'
import { useHead } from '@unhead/vue'
import { mobileDetect } from '@/utils/browser'

import PopupImage from '@media/PopupImage.vue'
import PageHeading from '@/components/PageHeading.vue'

const props = withDefaults(
  defineProps<{
    title: string
    selectedLinkId: string
    backgoundColor?: string
  }>(),
  {
    title: 'CMG'
  }
)

const { t: $t } = useI18n({})

const isMobile = ref(mobileDetect())
const updatePageWidth = () => {
  isMobile.value = mobileDetect()
}
onMounted(() => {
  updatePageWidth()
  window.addEventListener('resize', updatePageWidth)
})
onUnmounted(() => {
  window.removeEventListener('resize', updatePageWidth)
})

useHead({
  title: props.title
})

const popupActive = ref<boolean>()
const setPopupState = (val: boolean) => {
  popupActive.value = val
}

const originalBackgroundColor = ref<string | undefined>()
watchEffect(() => {
  if (props.backgoundColor) {
    originalBackgroundColor.value = document.body.style.backgroundColor
    document.body.style.backgroundColor = props.backgoundColor
  }
})

onBeforeUnmount(() => {
  if (props.backgoundColor) {
    document.body.style.backgroundColor = originalBackgroundColor.value || ''
  }
})
</script>

<template>
  <PopupImage @popupState="setPopupState" />
  <div v-show="!popupActive || !isMobile">
    <!-- Page Heading -->
    <header class="bg-cm-blue-500 shadow">
      <PageHeading :title="$t('studio.title')" />
    </header>
    <main>
      <div class="max-w-screen-2xl mx-auto flex">
        <div class="flex-grow content m-6">
          <!-- Page Content-->
          <slot v-if="$slots.header" name="header" />
          <slot />
        </div>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { RouterLink } from 'vue-router'
import { useNearWalletStore } from '@/stores/near-wallet'

import Wrapper from '@/components/Pages/Creatives/Wizard/Shared/Wrapper.vue'
import AuthWrapper from '@/components/Pages/Creatives/Wizard/Shared/Auth.vue'
import FollowSocialLinks from '@/components/FollowSocialLinks.vue'
import SurveyRating from '@/components/SurveyRating.vue'

import Uploaded from '@/components/Pages/Creatives/Wizard/Shared/Uploaded.vue'

import BioCard from '@/components/BioCard.vue'

import EditForm, {
  type EditFormOnSubmitSuccessData
} from '@/components/Pages/Profiles/EditForm.vue'

const nearWalletStore = useNearWalletStore()

const showProfile = ref(false)

const onSubmitSuccess = (data: EditFormOnSubmitSuccessData) => {
  console.log('onSubmitSuccess', data)
  showProfile.value = false
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = withDefaults(
  defineProps<{
    onDone?: () => void | undefined
  }>(),
  {
    briefId: undefined,
    onDone: (): void => {}
  }
)
</script>

<template>
  <Wrapper>
    <AuthWrapper title="Thank you" description="Before continuing please login or register.">
      <div class="mx-3">
        <div>
          <h2 class="font-bold tracking-wide text-2xl">Thanks for completing your profile</h2>
        </div>
        <div class="flex flex-wrap justify-center my-6 mt-9">
          <RouterLink
            :to="{
              name: 'user.updateprofile'
            }"
          >
            <button
              type="button"
              class="my-1 mx-1 py-1.5 px-6 min-w-28 border-2 font-semibold border-[#FF008B] text-[#FF008B] bg-white rounded-full hover:text-white hover:bg-[#FF008B] hover:opacity-85"
            >
              Edit profile
            </button>
          </RouterLink>
          <RouterLink
            :to="{
              name: 'user.profile',
              params: {
                username: nearWalletStore.accountId
              }
            }"
          >
            <button
              type="button"
              class="my-1 mx-1 py-1.5 px-6 min-w-28 border-2 font-semibold border-[#FF008B] text-white bg-[#FF008B] rounded-full hover:opacity-85"
            >
              View profile
            </button>
          </RouterLink>
        </div>
        <div class="w-full text-center text-[#262626]">
          <div class="py-3">
            <p class="text-sm">
              Please check your details and if you're happy submit your content to the brand for
              review
            </p>
          </div>
          <div class="py-3">
            <p class="text-2xl font-bold tracking-wider">Rate your experience</p>
          </div>
          <div class="py-3">
            <p class="text-sm">We would love to know how your Contented experience was today?</p>
          </div>
        </div>
        <div class="mt-12">
          <SurveyRating
            title=""
            class="max-w-[360px] mx-auto"
            :id="{
              production: '018ff1e1-9155-0000-978b-5e79f8a5b294',
              uat: '018ff1e1-5283-0000-6511-bfa500d37f60',
              preview: '018fe84e-589f-0000-fd6b-b0c5c41dd36e'
            }"
          />
        </div>
        <div class="flex flex-col justify-center my-6">
          <div
            class="w-full my-6"
            :class="{
              'border rounded-md pb-6': showProfile
            }"
          >
            <div
              @click="showProfile = !showProfile"
              class="px-4 py-1 flex flex-row items-center cursor-pointer hover:text-[#FF008B]"
              :class="{
                'border rounded-md': !showProfile
              }"
            >
              <i
                class="fa-solid mr-1.5 text-xs"
                :class="{
                  'fa-chevron-right': !showProfile,
                  'fa-chevron-down': showProfile
                }"
              ></i>
              <h3 class="text-left">Profile</h3>
            </div>
            <div v-show="showProfile" class="w-full mx-auto">
              <EditForm
                :hide-fields="{
                  languages: true
                }"
                :onSubmitSuccess="onSubmitSuccess"
              />
            </div>
          </div>

          <div class="w-full mt-3 mb-9">
            <BioCard />
          </div>

          <Uploaded />
        </div>
        <div class="flex flex-col justify-center my-6">
          <FollowSocialLinks />
        </div>
      </div>
    </AuthWrapper>
  </Wrapper>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue'
import { FormKit } from '@formkit/vue'
import { FormKitMessages } from '@formkit/vue'

const emit = defineEmits(['onMetaDataSaved'])

const props = withDefaults(
  defineProps<{
    file: any
    showMeta?: boolean
  }>(),
  {
    file: {
      file_url: '',
      name: '',
      description: '',
      tags: '',
      ipfs_file_url: '',
      ipfs_watermark_file_url: '',
      ipfs_media_type: '',
      key: '',
      iv: '',
      key_brief_owner: ''
    },
    showMeta: false
  }
)

const file_url = ref(props.file.file_url)
const name = ref(props.file.name)
const description = ref(props.file.description)
const tags = ref(props.file.tags)
const ipfs_file_url = ref(props.file.ipfs_file_url)
const ipfs_watermark_file_url = ref(props.file.ipfs_watermark_file_url)
const ipfs_media_type = ref(props.file.ipfs_media_type)
const key = ref(props.file.key)
const iv = ref(props.file.iv)
const key_brief_owner = ref(props.file.key_brief_owner)

const form = ref()

const tagAddError = ref('')

const tagAdd = ($e: any) => {
  const normalisedTag = ($e.target.value || '').trim().toLowerCase()

  if (!normalisedTag) {
    return false
  }

  let tagsArr = tagsArray.value.slice()

  if (tagsArr.indexOf(normalisedTag) > -1) {
    tagAddError.value = `The tag: ${$e.target.value} is already added.`
    return false
  }

  tagsArr = [...tagsArr, normalisedTag]

  tags.value = tagsArr.join(', ')

  $e.target.value = ''
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const tagRemove = (tagToRemove: any, $e: any) => {
  if (!tags.value) {
    return false
  }

  tags.value = tagsArray.value
    .slice()
    .filter((tag: string) => tag !== tagToRemove)
    .join(', ')
}

const onMetaDataSaved = (edit: boolean) => {
  editing.value = edit
  const data = {
    file_url: file_url.value,
    name: name.value,
    description: description.value,
    tags: tags.value,
    ipfs_file_url: ipfs_file_url.value,
    ipfs_watermark_file_url: ipfs_watermark_file_url.value,
    ipfs_media_type: ipfs_media_type.value,
    key: key.value,
    iv: iv.value,
    key_brief_owner: key_brief_owner.value
  }
  // emit whether we are editing or saving
  emit('onMetaDataSaved', !edit, data)
}

// make any links in the description clickable
const highlightLink = (text: string) => {
  if (typeof text !== 'string') {
    return text
  }
  const regex = /(https?:\/\/[^\s]+)/g
  return text.replace(
    regex,
    '<a class="unsplashLink" href="$1?utm_source=Contented%20Media%20Group&utm_medium=referral " target="_blank">link</a>'
  )
}

const editing = ref(props.showMeta)

const tagsArray = computed(() => {
  if (!tags.value || typeof tags.value !== 'string') {
    return []
  }
  return (tags.value || '')
    .split(',')
    .map((tag: any) => tag.trim())
    .filter((tag: any) => tag !== '')
})

const debug = ref(false)
</script>

<template>
  <div class="bg-blue-200 p" v-if="debug">
    <pre class="text-xs">
      Props {{ props }}
      NAME {{ name }}
      DESCRIPTION {{ description }}
      TAGS {{ tags }}
      TAGS ARRAY {{ tagsArray }}
    </pre>
  </div>
  <!-- not editing-->
  <div class="flex" v-if="!editing">
    <div
      class="grow text-sm"
      :class="{
        'flex justify-center': !name
      }"
    >
      <span>
        <input
          type="button"
          class="float-right ml-2 mb-2 action-button hollow"
          @click="onMetaDataSaved(true)"
          value="Edit Details"
        />
        <b>{{ name }}</b
        ><br />
        <span class="text-xs" v-html="highlightLink(description)"></span>
      </span>
      <span v-if="tagsArray" class="mt-2 flex flex-wrap">
        <div v-for="t in tagsArray" class="text-xs rounded-md mr-3 mb-3 p-2 bg-gray-200" :key="t">
          {{ t }}
        </div>
      </span>
    </div>
  </div>
  <!-- editing -->
  <div v-else class="-mt-4">
    <FormKit
      type="form"
      ref="form"
      @submit="onMetaDataSaved(false)"
      :actions="false"
      class="upload-form"
    >
      <!-- error messages -->
      <div class="my-6 text-sm text-red-600 hidden" v-if="form?.node">
        <FormKitMessages :node="form.node" />
      </div>

      <!-- Name -->
      <FormKit
        type="text"
        name="name"
        label="Title"
        label-class=""
        input-class=""
        v-model="name"
        placeholder="Name of your file"
        spellcheck="true"
        validation="required"
      />

      <!-- Description -->
      <FormKit
        type="textarea"
        name="description"
        label="Description (optional)"
        label-class=""
        input-class=""
        v-model="description"
        placeholder="Description of your file"
        rows="6"
        spellcheck="true"
      />

      <!-- Tags -->
      <div class="-mt-3">
        <div class="flex">
          <FormKit
            type="text"
            name="tagsList"
            label="Tags (optional)"
            placeholder="Type and press enter to add a tag"
            @keydown.enter.prevent="tagAdd"
            @blur="tagAdd"
            autocomplete="off"
          />
        </div>

        <!-- tags list -->
        <div class="flex flex-wrap gap-2 mb-3">
          <div
            v-for="(tag, index) in tagsArray"
            :key="index"
            class="bg-gray-300 hover:bg-gray-600 text-gray-600 hover:text-white px-3 py-1 rounded-md flex items-center gap-2"
          >
            <span class="text-xs uppercase font-semibold tracking-wide">{{ tag }}</span>
            <button @click.prevent="tagRemove(tag, $event)" class="px-0.5">
              <span>
                <i class="text-sm fa-thin fa-times"></i>
              </span>
            </button>
          </div>
        </div>

        <div class="mt-3 select-none">
          <input type="submit" class="action-button action-save-meta solid" value="Save Details" />
        </div>

        <div class="formkit-outer" v-if="tagAddError">
          <div class="formkit-messages">
            <div class="formkit-message">
              {{ tagAddError }}
            </div>
          </div>
        </div>
      </div>
    </FormKit>
  </div>
</template>

<style lang="scss">
.unsplashLink {
  @apply text-blue-600 underline;
}
.formkit-form {
  .profiles-portfolio-section {
    @apply my-9 rounded-md bg-white border border-gray-200 p-6;
  }
  .formkit-outer {
    @apply w-full max-w-full my-3;
    .formkit-label {
      @apply text-sm tracking-wide;
    }
    .formkit-inner {
      .formkit-input {
        @apply rounded-md text-sm border-gray-300 w-full my-1 placeholder-gray-300;
      }
    }
    .formkit-messages {
      .formkit-message {
        @apply mt-0.5 text-xs text-red-600;
      }
    }
  }
}
</style>

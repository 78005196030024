<script lang="ts" setup>
import { reactive, ref, watchEffect, computed } from 'vue'
import { useNearWalletStore } from '@/stores/near-wallet'
import { extractFileUrl } from '@/utils/mediaDecrypt'
import { useImageCDN } from '@/utils/image'

const nearWalletStore = useNearWalletStore()

const props = withDefaults(
  defineProps<{
    brief: SmartBrief
    href?: string
    isBottomCornersRounded?: boolean
  }>(),
  {
    href: '#',
    isBottomCornersRounded: true
  }
)

const {
  owner,
  status,
  title,
  summary,
  image,
  logo,
  brandLogo,
  budget,
  contentTypes,
  visibility,
  ndaTitle,
  ndaImage,
  ndaSummary,
  ndaCompanyLogo,
  ndaSubmitted,
  submissionDeadline
} = props.brief || {}

const isPublished = ref(status === 'published')
const isClosed = computed(() => {
  const now = new Date()
  const deadline = new Date(submissionDeadline)
  return now > deadline
})

const imageValue = computed(() => extractFileUrl(image))
const ndaImageValue = computed(() => extractFileUrl(ndaImage))

const backgroundImageFailedToLoad = ref(false)

const backgroundImageUrl = ref('')

const backgroundImageUpdate = (val: string) => {
  backgroundImageUrl.value = val ? useImageCDN(val) : ''
}

const isAccepted = ref(false)

watchEffect(() => {
  if (!visibility || visibility === 'public') {
    isAccepted.value = true
    backgroundImageUpdate(imageValue.value)
  } else {
    if (nearWalletStore.accountId !== '') {
      if (ndaSubmitted) {
        isAccepted.value = ndaSubmitted.filter((e) => e === nearWalletStore.accountId).length > 0
      }
      isAccepted.value = isAccepted.value || owner === nearWalletStore.accountId
    }
    if (!isAccepted.value) {
      backgroundImageUpdate(ndaImageValue.value)
    } else {
      backgroundImageUpdate(imageValue.value)
    }
  }
})

const getTitle = () => {
  const titleToCheck = isAccepted.value ? title : ndaTitle

  const maxlength = 30

  if (titleToCheck.length > maxlength) {
    return titleToCheck.substring(0, maxlength) + '...'
  }

  return titleToCheck
}

const formatList = (list: string) => {
  return list?.replace(/,/g, ', ')
}

const calculatePotentialEarnings = () => {
  if (
    props.brief?.paymentPreference?.payment_to_creator &&
    props.brief?.paymentPreference?.number_of_creators
  ) {
    return (
      Number(props.brief.paymentPreference.payment_to_creator) /
      Number(props.brief.paymentPreference.number_of_creators)
    )
  }
  return Number(props.brief.budget) * 0.9
}
</script>

<template>
  <router-link
    class="w-full card-container cursor-pointer"
    :to="
      props?.href || {
        name: 'briefs.briefdetails',
        params: {
          brief_id: props.brief?.slug || props.brief?.id
        }
      }
    "
  >
    <div>
      <div class="h-[250px]">
        <div
          v-if="!backgroundImageUrl || backgroundImageFailedToLoad"
          class="h-full flex flex-col items-center justify-center border border-[#F2F3F4] text-[#d6d9dc]"
        >
          <i class="fa-thin fa-image text-5xl"></i>
          <small class="mt-3 uppercase tracking-widest font-medium">No image</small>
        </div>
        <div v-else class="h-full block">
          <img
            :src="backgroundImageUrl"
            alt="Thumbnail"
            class="w-full h-full object-cover object-center"
            @error="() => (backgroundImageFailedToLoad = true)"
            loading="lazy"
          />
        </div>
      </div>
      <!-- Unpublished label conditionally displayed -->
      <div v-if="!isPublished" class="absolute top-0 right-2 bg-cm-orange-50 text-white px-2 py-4">
        Draft
      </div>
      <div v-if="isClosed" class="absolute top-0 right-2 bg-closed text-white px-2 py-4">
        Closed
      </div>
    </div>
    <div
      class="border border-t-0 border-[#F2F3F4] bg-white"
      :class="{
        'rounded-b-md': isBottomCornersRounded
      }"
    >
      <div class="p-3">
        <!-- First Row -->
        <div class="flex justify-between">
          <!-- Title -->
          <div class="py-1.5">
            <span
              ><strong class="font-semibold">{{ getTitle() }}</strong></span
            >
          </div>
        </div>
        <!-- Second Row -->
        <div class="flex justify-between items-center">
          <!-- First Column -->
          <div class="flex flex-col text-[#404040]">
            <!-- Format -->
            <div class="py-1.5 flex flex-col text-xs">
              <strong class="font-semibold tracking-wide">Format</strong>
              <span class="">{{ formatList(contentTypes) }}</span>
            </div>
            <!-- Potential Earnings -->
            <div class="py-1.5 flex flex-col text-xs">
              <strong class="font-semibold tracking-wide">Potential Earnings</strong>
              <span>${{ calculatePotentialEarnings() }}</span>
            </div>
          </div>
          <!-- Second Column -->
          <div>
            <img
              v-if="isAccepted && logo"
              loading="lazy"
              :src="useImageCDN(extractFileUrl(logo))"
              alt="Thumbnail"
              class="w-16 h-16 object-contain object-right-center"
            />
            <img
              v-if="isAccepted && brandLogo"
              loading="lazy"
              :src="useImageCDN(extractFileUrl(brandLogo))"
              alt="Thumbnail"
              class="w-16 h-16 object-contain object-right-center"
            />
            <img
              v-if="!isAccepted && ndaCompanyLogo"
              loading="lazy"
              :src="useImageCDN(extractFileUrl(ndaCompanyLogo))"
              alt="Logo"
              class="w-16 h-16 object-contain object-right-center"
            />
          </div>
        </div>
        <!-- Second row with longer text -->
        <!-- <div class="py-3 text-sm font-normal max-w-xs hidden md:block">
          <div class="truncate">{{ isAccepted ? summary : ndaSummary }}</div>
        </div> -->
        <!--
		  <div class="py-3">
			<ul class="flex justify-between">
			  <li class="hover:text-cm-blue-light-200 cursor-pointer">
				<i class="fal fa-paper-plane"></i>
			  </li>
			  <li class="hover:text-cm-blue-light-200 cursor-pointer">
				<i class="fal fa-heart"></i>
			  </li>
			</ul>
		  </div>
		  -->
      </div>
    </div>
  </router-link>
</template>

<style scoped>
.card-container {
  position: relative;
}
.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.bg-closed {
  background-color: #ff2028;
}
</style>

<script lang="ts" setup>
import { onBeforeMount } from 'vue'
import { ref } from 'vue'

const showSwatch = ref<boolean>(false)

onBeforeMount(() => {
  const host = window.location.host

  if (host.endsWith('.local') || host.startsWith('localhost')) {
    showSwatch.value = true
  }
})
</script>
<template>
  <div
    class="local absolute bottom-1 flex-col text-center text-xs"
    v-if="showSwatch"
    data-testid="tailwind-breakpoint-swatch"
  >
    <div class="p-1 rounded-md mx-1 bg-gray-200 2xl:bg-pink-200">2XL</div>
    <div class="p-1 rounded-md mx-1 bg-gray-200 xl:bg-orange-200">XL</div>
    <div class="p-1 rounded-md mx-1 bg-gray-200 lg:bg-red-200">LG</div>
    <div class="p-1 rounded-md mx-1 bg-gray-200 md:bg-blue-200">MD</div>
    <div class="p-1 rounded-md mx-1 bg-gray-200 sm:bg-green-200">SM</div>
  </div>
</template>

import { v4 as uuidv4 } from 'uuid'
import { useMemoize } from '@vueuse/core'
import { useUcanStore } from '@/stores/ucan'
import { CloudflareWorkersApi } from '@/queries/cloudflare-workers'

let ucanStore: any = null

export const selfSignedUcanToken = useMemoize(async (loggedInAccountId: string) => {
  ucanStore = useUcanStore()
  return await ucanStore.issueNotificationsUcan({
    issuerEmailrsa: (await ucanStore.fetchProfileMemoized(loggedInAccountId))?.data?.emailrsa || ''
  })
})

export const fileUploadJwt = useMemoize(async (loggedInAccountId, file, ucanToken) => {
  ucanStore = useUcanStore()
  return await CloudflareWorkersApi().post(
    `/tus/file/profiles/generate/jwt?id=${loggedInAccountId}`,
    {
      title: uuidv4()
    },
    {
      headers: {
        Authorization: 'Bearer ' + ucanToken
      }
    }
  )
})

// @ts-nocheck

const encodeIfFile = (v?: any) => {
  if (v?.file_url) {
    return JSON.stringify(v)
  }
  return v
}

export const setDataFromBrief = (data: any) => {
  // SmartBrief
  let keys = [
    'id',
    'slug',
    'aboutTheBrand',
    'brandLogo',
    'brandName',
    'considerations',
    'contentRights',
    'contentTypes',
    'customSection',
    'deliverables',
    'idealCandidate',
    'image',
    'interestDeadline',
    'metaData',
    'modelRelease',
    'ndaBrandName',
    'ndaCompanyLogo',
    'ndaImage',
    'ndaSummary',
    'ndaText',
    'ndaTitle',
    'owner',
    'paymentPreference',
    'preProduction',
    'profilePic',
    'rewards',
    'status',
    'submissionDeadline',
    'summary',
    'techRequirements',
    'termsAndConditions',
    'title',
    'visibility'
  ]

  const brief: Partial<SmartBrief> = {}
  const marketing: Partial<MarketingBrief> = {}
  const profile: Partial<ProfileData> = {}

  keys.forEach((key: string) => {
    if (typeof data[key] === 'undefined') {
      console.warn('setDataFromBrief: SmartBrief key missing', key)
    } else {
      brief[key] = encodeIfFile(data[key])
    }
  })

  return { brief, profile, marketing }
}

<script lang="ts" setup>
// @ts-nocheck
import { ref, reactive, watchEffect } from 'vue'
import { getCreativeBriefs } from '@/queries/content'
import { useQuery } from '@vue/apollo-composable'
import { storeToRefs } from 'pinia'
import { useNearWalletStore } from '@/stores/near-wallet'
import { saveResult } from '@/api/requests'

import BriefsDetails from '@/components/Pages/Marketplace/BriefsDetails.vue'
import Loading from '@/components/Loading.vue'
import Alert from '@/components/Alert.vue'

const nearWalletStore = useNearWalletStore()

const params = reactive({
  accountId: ''
})

const { result, loading, error } = useQuery(getCreativeBriefs(), params)
const { accountId } = storeToRefs(nearWalletStore)

watchEffect(() => {
  params.accountId = accountId.value
})

const view = ref(false),
  license = ref(false),
  selected = ref(false)

const BriefsList = ref([])

watchEffect(() => {
  if (result.value) {
    BriefsList.value = result.value.marketingBriefs.map((e, i) => ({ ...e, open: false, index: i }))

    // save result
    saveResult(params, BriefsList.value)
  }
})

const back = () => {
  if (license.value !== false) {
    license.value = false
  } else {
    selected.value = false
    view.value = false
  }
}

const openBrief = (index) => {
  BriefsList.value[index].open = !BriefsList.value[index].open
}

const viewBrief = (index) => {
  view.value = index
  selected.value = BriefsList.value[index]
}

const licenseBrief = (index) => {
  license.value = index
}
</script>

<template>
  <div v-show="view !== false" @click="back" class="flex items-center cursor-pointer">
    <i class="fa-regular fa-angle-left"></i>
    <div class="ml-1">Back</div>
  </div>

  <Loading v-if="loading" />
  <Alert v-else-if="error" type="error" :text="error" />
  <template v-else-if="result">
    <div v-show="view === false" class="mb-3" v-for="(brief, index) in BriefsList" :key="index">
      <BriefsDetails :brief="brief" @open="openBrief" @view="viewBrief" />
    </div>

    <div v-if="view !== false" class="my-3">
      <BriefsDetails v-if="license === false" :brief="selected" @license="licenseBrief" />
    </div>
  </template>
</template>

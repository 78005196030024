<script lang="ts" setup>
import { uniqBy } from 'lodash'
import { ref, watchEffect } from 'vue'
import { useNearWalletStore } from '@/stores/near-wallet'
import { storeToRefs } from 'pinia'
import { useUcanStore } from '@/stores/ucan'
import { fetchProfile } from '@/queries/api'
import { Vue3Lottie } from 'vue3-lottie'
import busy from '@/lottie/busy.json'
import BriefDetailsQAInput from '@briefs/BriefDetailsQAInput.vue'
import BriefDetailsQAComment from '@briefs/BriefDetailsQAComment.vue'

type ThreadValue = {
  id: string
  comment: string
  comment_file: string[]
  account_name: string
  profile_image: string
  owner: string
  isCommentOwner: boolean
  created_on: number
  reference_id: string
  reference_type: string
}

type Threads = Record<string, Array<ThreadValue>>

const ucanStore = useUcanStore()

const props = withDefaults(
  defineProps<{
    brief_id?: string
    brief?: SmartBrief
    initialMessages?: SmartBriefFeedback[]
    reference_id?: string
    reference_type?: 'brief' | 'profile' | 'content' | 'team' | (string & {})
    profile_user_id?: string
    dm_candidate_id?: string
    called_from?: string
    isBriefLoading?: boolean
    pinInputToBottom?: boolean
  }>(),
  {
    brief_id: '',
    reference_id: '',
    reference_type: '',
    profile_user_id: '',
    dm_candidate_id: '',
    called_from: '',
    isBriefLoading: false,
    pinInputToBottom: false
  }
)

// console.log(`isBriefLoading: ${props.isBriefLoading}`)

const nearWalletStore = useNearWalletStore()
const { user, accountId } = storeToRefs(nearWalletStore)

// const profileNames = ref<{ [key: string]: ProfileData }>({});
const isProfilesLoading = ref(true)

const profiles = ref<{ [key: string]: any }>({})
const threads = ref()

/* clearInterval(window.refreshBriefQAInterval)
window.refreshBriefQAInterval = setInterval(() => {
  queried?.refetch()
}, 3000) */

const submitted = (data: {
  id?: string
  feedbackText: string
  feedbackFile: string
  owner: string
  parentId: string | undefined
  referenceId: string
  referenceType: string
  createdAt?: string
  updatedAt?: string
}) => {
  // console.log('submitted', data)

  const comment = {
    id: data.id || '',
    referenceId: data.referenceId,
    referenceType: data.referenceType,
    parentId: data.parentId,
    owner: data.owner,
    feedbackText: data.feedbackText,
    feedbackFile: data.feedbackFile,
    createdAt: data.createdAt || '',
    updatedAt: data.updatedAt
  }

  addCommentToThreads(threads.value, comment).then((threadsTemp) => {
    threads.value = threadsTemp
  })
}

function convertToUnixTimestamp(dateStr: string) {
  const date = new Date(dateStr)
  const unixTimestamp = date.getTime()
  return unixTimestamp
}

const addCommentToThreads = async (
  threadsTemp: Threads,
  comment: SmartBriefFeedback
): Promise<Threads> => {
  let parent_id = comment.parentId || ''

  // Initialize thread if not exists
  if (!threadsTemp[parent_id]) {
    threadsTemp[parent_id] = []
  }

  // fetch profile data
  if (!profiles.value[comment.owner]) {
    profiles.value[comment.owner] = await fetchProfile(comment.owner)
  }

  // add comment
  const existingComment = threadsTemp[parent_id]?.find((c: any) => c.id === comment.id)
  if (!existingComment) {
    threadsTemp[parent_id].push({
      id: comment.id,
      comment: comment.feedbackText,
      comment_file: comment.feedbackFile ? JSON.parse(comment.feedbackFile || '[]') : [],
      account_name: profiles.value[comment.owner]?.firstName || 'Unknown User',
      profile_image: profiles.value[comment.owner]?.profilePic || '',
      owner: comment.owner,
      isCommentOwner: comment.owner === accountId.value,
      created_on: convertToUnixTimestamp(comment.createdAt),
      reference_id: props?.reference_id,
      reference_type: props?.reference_type
    })
  }

  // return the threads with the new comment
  return threadsTemp
}

const fetchThreads = async () => {
  try {
    isProfilesLoading.value = true

    let threadsTemp: Threads = {}
    if (['content', 'profile', 'brief'].includes(props.reference_type)) {
      threads.value = []
    }

    // get the data depending on the reference type
    let messages: Array<SmartBriefFeedback> | undefined
    if (props?.initialMessages && props?.initialMessages?.length > 0) {
      messages = props.initialMessages
    } else if (props?.reference_type === 'profile') {
      messages = props.brief?.candidateProfiles?.find(
        (item) => item.id === props.reference_id
      )?.feedbacks
    } else if (props?.reference_type === 'brief') {
      messages = props.brief?.feedbacks
    }

    // add the messages to the threads
    if (messages && messages?.length > 0) {
      // remove any duplicates
      messages = uniqBy(messages, 'id')

      // add the messages to the threads
      for (let comment of messages) {
        threadsTemp = await addCommentToThreads(threadsTemp, comment)
      }
    }

    // update the threads
    threads.value = threadsTemp
  } catch (error) {
    console.error('Failed to fetch profiles:', error)
  } finally {
    isProfilesLoading.value = false
  }
}

// let profilesFetched = false;
watchEffect(async () => {
  fetchThreads()
})
</script>

<template>
  <div
    v-if="props?.brief && props.brief.owner === accountId && props.reference_type === 'brief'"
    class="bg-white w-full rounded-md py-3 px-6 mb-9 border-2 border-[#5386EA]"
  >
    <div class="w-full max-w-7xl flex flex-col mx-auto">
      <h3 class="text-lg font-bold tracking-wide my-3" style="text-align: left">
        Questions about this brief
      </h3>
      <p class="my-3">
        Below you will see question from the creators you have invited to respond to the brief. To
        promote transparency, the questions and answers are visible to all approved creator
        candidates.
      </p>
    </div>
  </div>

  <div
    :class="[
      'flex flex-col',
      {
        'min-h-screen':
          called_from === 'main_page' ||
          props.reference_type === 'team' ||
          props.reference_type === 'brief'
      }
    ]"
  >
    <div
      :class="[
        'bg-white w-full rounded-md',
        {
          'px-8 pt-6 pb-8 mb-6':
            called_from === 'main_page' ||
            props.reference_type === 'team' ||
            props.reference_type === 'brief',
          'flex flex-col-reverse': props.pinInputToBottom
        }
      ]"
    >
      <div
        :class="{
          'pt-8': props.pinInputToBottom
        }"
      >
        <BriefDetailsQAInput
          :brief="props.brief"
          :brief_id="props.brief_id"
          :reference_id="props?.reference_id"
          :reference_type="props.reference_type"
          :dm_candidate_id="props.dm_candidate_id || undefined"
          @submitted="submitted"
        />
      </div>

      <Vue3Lottie
        v-if="(threads && props.brief && isProfilesLoading) || props.isBriefLoading"
        :animationData="busy"
        :height="50"
      />
      <div
        v-if="threads && !isProfilesLoading && (props?.brief || props.reference_type === 'team')"
        class="w-full mx-auto"
      >
        <BriefDetailsQAComment
          :brief="props.brief"
          :threads="threads"
          :reference_type="props.reference_type"
          @submitted="submitted"
        />
      </div>
    </div>
  </div>
</template>

import Briefs from '@/components/Pages/Briefs/Briefs.vue'
import BriefDetailsComponent from '@/components/Pages/Briefs/BriefDetails.vue'
import BriefInviteCreativeComponent from '@/components/Pages/Briefs/BriefInviteCreative.vue'

export default [
  {
    path: '/briefs',
    children: [
      {
        path: '',
        component: Briefs,
        name: 'briefs.list',
        props: true
      },
      {
        path: ':brief_id/:active_tab?',
        component: BriefDetailsComponent,
        name: 'briefs.briefdetails',
        props: true
      },
      {
        path: 'invite/:brief_id/creative',
        component: BriefInviteCreativeComponent,
        name: 'briefs.invite.creative',
        props: true
      }
    ]
  }
]

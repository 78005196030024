<script lang="ts" setup>
import { onMounted, ref, reactive, watch, watchEffect } from 'vue'
import { FormKitMessages } from '@formkit/vue'
import { useHead } from '@unhead/vue'
import { useRoute, useRouter } from 'vue-router'
import { v4 as uuidv4 } from 'uuid'

import { useUcanStore } from '@/stores/ucan'
import { debounceLog } from '@/utils/logging'
import { isSet } from '@/utils/mediaDecrypt'
import { useBriefStore } from '@/stores/briefs'
import { storeToRefs } from 'pinia'
import { clickMetaSaveButtons, scrollToError } from '@/utils/media'
import { setDataFromBrief } from '@/utils/briefs'
import { submitProfileSave } from '@/utils/storeProfile.js'
import { fetchProfile } from '@/queries/api'

import FollowSocialLinks from '@/components/FollowSocialLinks.vue'
import PageHeading from '@/components/PageHeading.vue'
import ToggleContent from '@/components/ToggleContent.vue'
import ToggleFade from '@/components/ToggleFade.vue'
import ShareLink from '@/components/ShareLink.vue'
import SpinnerOverlay from '@/components/SpinnerOverlay.vue'
import Steps from '@/components/Pages/Creatives/Wizard/Shared/Steps.vue'

import SurveyRating from '@/components/SurveyRating.vue'

import BriefSlugInput from '@/components/BriefSlugInput.vue'

// payment preferences
import BriefProjectPaymentForm from '@briefs/BriefProjectPaymentForm.vue'

// save update brief
import { submitSmartBrief } from '@/utils/api-requests'

// storing the brief data before login
import { clearLocalData, getLocalData, saveLocalData } from '@/utils/saveLocalData'

// login
import AuthWrapper from '@/components/Pages/Creatives/Wizard/Shared/Auth.vue'
import { useNearWalletStore } from '@/stores/near-wallet'

const $route = useRoute()
const $router = useRouter()

const ucanStore = useUcanStore()

const briefStore = useBriefStore()
const { briefActive } = storeToRefs(briefStore)

const nearWalletStore = useNearWalletStore()
const { accountId } = storeToRefs(nearWalletStore)

useHead({
  title: 'Create a brief | Contented'
})

const isEdit = ref(window.location.href.includes('edit-brief'))
let pageUrl = 'marketplace.create-brief'
if (isEdit.value) {
  pageUrl = 'marketplace.edit-brief'
}

// upload temporary account id if not logged in
const uploadAccountId = ref('')

const props = withDefaults(
  defineProps<{
    stage?: string
    brief_id?: string
    brief?: Partial<SmartBrief>
    profile?: Partial<ProfileData>
    marketing?: Partial<MarketingBrief>
  }>(),
  {
    stage: '1',
    brief_id: '',
    brief: () => ({
      id: '',
      slug: '',
      owner: '',
      contentTypes: '',
      metaData: '',
      ndaTitle: '',
      ndaText: '',
      ndaBrandName: '',
      ndaSummary: '',
      ndaCompanyLogo: '',
      ndaImage: '',
      title: '',
      brandName: '',
      brandLogo: '',
      summary: '',
      image: '',
      interestDeadline: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString(),
      submissionDeadline: new Date(new Date().setDate(new Date().getDate() + 14)).toISOString(),
      paymentPreference: {} as SmartBriefPaymentPreferences,
      status: 'unpublished',
      visibility: 'public',
      /* optimise */
      contentRights: '',
      modelRelease: '',
      deliverables: '',
      preProduction: '',
      rewards: '',
      techRequirements: '',
      considerations: '',
      idealCandidate: '',
      termsAndConditions: 'false',
      customSection: ''
    }),
    profile: () => ({
      id: '',
      firstName: '',
      lastName: '',
      city: '',
      country: '',
      companyName: '',
      profilePic: ''
    }),
    marketing: () => ({})
  }
)

// cant use props directly in reactive
let localData = reactive({
  brief: props.brief,
  profile: props.profile,
  marketing: props.marketing,
  paymentComplete: false,
  lastStage: parseInt(props.stage),
  uuidv4: uuidv4(),
  updatedAt: new Date().toISOString()
})

const pageTitle = ref('Create a Brief')
if (isEdit.value) {
  pageTitle.value = 'Edit Brief'
}
const stage = ref()

// dont parse to NaN
if (props.stage) {
  stage.value = parseInt(props.stage)
}

const stages = [
  {
    number: 1,
    label: 'Basics',
    onClickStep: () => {
      setStage(1, true)
    }
  },
  {
    number: 2,
    label: 'Add NDA',
    onClickStep: () => {
      setStage(2, true)
    }
  },
  {
    number: 3,
    label: 'Profile',
    onClickStep: () => {
      setStage(3, true)
    }
  },
  {
    number: 4,
    label: 'Optimize',
    onClickStep: () => {
      setStage(4, true)
    }
  }
]
const form = ref()
const contractBusy = ref(false)

const contentTypes = ref([
  'Edited video',
  'Photography',
  'Video clip',
  'Graphic design',
  'Animation',
  'Illustration',
  'Long form video',
  'Short form video',
  'Other'
])

// must be an array for formkit, translate back to string on submit
const contentTypesVals = ref<string[]>([])

const isValidDate = (date: string | Date) => {
  return !isNaN(new Date(date).getTime())
}

const validateSubmissionDeadline = (node: any) => {
  const interestDeadlineStr = localData.brief.interestDeadline
  const submissionDeadlineStr = node.value
  if (!interestDeadlineStr || !submissionDeadlineStr) {
    return false
  }
  if (!isValidDate(interestDeadlineStr) || !isValidDate(submissionDeadlineStr)) {
    return false
  }
  const interestDeadline = new Date(interestDeadlineStr)
  const submissionDeadline = new Date(submissionDeadlineStr)
  if (submissionDeadline <= interestDeadline) {
    return false
  }
  return true
}

const fadeIn = ref(true)

let lastCompletedStage: number = 0
const stageWarning = ref(false)
const nextValue = ref('Next')

const trackEventCurrentStage = (currentStage: number | string = 1) => {
  const stepName = {
    1: 'basics',
    2: 'nda',
    3: 'profile',
    4: 'optimise',
    5: 'thankyou'
  }[Number(currentStage)]

  if (typeof window?.gtag === 'function') {
    window.gtag('event', 'brief_wizard_stage_' + stepName, {
      event_category: 'brief'
    })
  }

  window?.posthog?.capture('brief_wizard_stage_' + stepName, {
    type: '`brief_wizard',
    stage: stepName,
    category: 'brief'
  })
}

// not used atm, we will use the cloudflare workers to process the files
const moveTemporaryFilesToContract = async () => {
  // Traverse and log all key-value pairs in localData
  const traverseAndLog = (obj: any, prefix = '') => {
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'object' && value !== null) {
        traverseAndLog(value, `${prefix}${key}.`)
      } else {
        if (typeof value === 'string' && value.includes('tustemp.contentedworld.com')) {
          console.log('BriefsWizard : moveTemporaryFilesToContract :', key, JSON.parse(value))
        }
      }
    }
  }
  traverseAndLog(localData)
}

// not submitting? this will be FormKit validation stopping this being called
const setStage = async (v: number, wasButton = false) => {
  // log the event stage
  trackEventCurrentStage(v)

  // you are allowed to jump around pages via the buttons once you've completed them, or the brief was already created
  if (!props.brief_id && wasButton && v > lastCompletedStage) {
    console.log('BriefsWizard : setStage : warning')
    stageWarning.value = true
    setTimeout(() => {
      stageWarning.value = false
    }, 3000)
    return
  }

  clickMetaSaveButtons()

  // save the brief data if not already saved
  if (v == 4) {
    await saveProfile()
    // save the brief data if not already saved
    if (!localData.brief.id) {
      await saveBrief(false)
    }
  }

  // don't let them get to 4 until the payment is complete
  if (!localData.paymentComplete) {
    if (v > 3) {
      v = 3
      console.log(
        'BriefsWizard : setStage : paymentComplete not done yet',
        localData.paymentComplete
      )
    }
  }

  // submit button text
  if (localData.brief.termsAndConditions === 'false' && v == 3) {
    nextValue.value = 'Setup Payment'
  } else {
    nextValue.value = 'Next'
  }

  // update url
  if (v < 5) {
    let params = {
      stage: v,
      brief_id: ''
    }
    if (localData.brief.id) {
      params.brief_id = localData.brief.id
    }
    $router.replace({
      name: pageUrl,
      params: params,
      query: $route.query ? { ...$route.query } : {}
    })
  }

  // lastCompletedStage
  if (localData.brief.status === 'published') {
    lastCompletedStage = 4
  }
  if (localData.brief.termsAndConditions === 'true') {
    lastCompletedStage = 3
  }

  setTimeout(() => {
    // check for validation errors
    const errs = clickMetaSaveButtons()
    if (errs) {
      return
    }

    // animate new stage
    fadeIn.value = false
    setTimeout(() => {
      stage.value = v
      if (!localData.paymentComplete && stage.value == 3) {
        // don't scroll up, just completed the profile save and need to do the payment
      } else {
        window.scroll(0, 0)
      }
      if (v > lastCompletedStage) {
        lastCompletedStage = v
      }
      fadeIn.value = true
    }, 500)
  }, 100)
}

const saveProfile = async () => {
  // legacy profile data
  if (!localData.profile.id) {
    localData.profile.id = accountId.value
  }
  // save profile data
  await submitProfileSave(nearWalletStore, accountId.value, {
    ...localData.profile
  })
}

const getContentTypes = () => {
  // formkit return empty first element
  let vals: string = contentTypesVals.value?.join(',')
  if (vals?.[0] == ',') {
    vals = vals.slice(1)
  }
  return vals
}

// save the brief to contract
const saveBrief = async (finish: boolean, status: string = 'unpublished') => {
  // set owner
  localData.brief.owner = accountId.value
  localData.brief.contentTypes = getContentTypes()
  // allow buttons to set the state
  if (status) {
    localData.brief.status = status
  }
  contractBusy.value = true

  const smartBriefRecord = localData.brief?.id
    ? localData
    : {
        ...localData,
        teamNickname: String($route.query.team || '')
      }

  const res = await submitSmartBrief(smartBriefRecord)
  contractBusy.value = false
  if (res.success == true) {
    localData.brief.id = res.brief.id
    console.log(
      'BriefsWizard : saveBrief : ID : ',
      res.brief.id,
      'login',
      accountId.value,
      'owner',
      localData.brief.owner
    )
    if (finish) {
      // only send emails if we are published and not a draft
      if (localData.brief.status == 'published') {
        // send email that we are published
        await ucanStore.notifyUsingSelfSignedUCAN(accountId.value, {
          workflowId: 'smartbrief-to-brand-you-published-a-brief',
          payload: {
            briefId: localData.brief.id,
            briefName: localData.brief.title,
            briefLink: getBriefLink()
          }
        })
      }
      // got to the final page
      setStage(5)
    }

    // To Brand: Brief Saved in Drafts when status='unpublished'
    if (status === 'unpublished') {
      await ucanStore.notifyUsingSelfSignedUCAN(accountId.value, {
        workflowId: 'to-brand-brief-saved-in-drafts',
        payload: {
          briefLink: getBriefLink()
        }
      })
    }
  } else {
    console.error('BriefsWizard : Failed to save brief', res)
    return
  }
}

// update upload field
const saveUploadedFile = (fieldName: string, data: any) => {
  const bits = fieldName.split('.')
  const section = bits[0] as Exclude<
    keyof typeof localData,
    'paymentComplete' | 'lastStage' | 'updatedAt' | 'uuidv4'
  >
  const field = bits[1] ? (bits[1] as keyof (typeof localData)[typeof section]) : undefined

  const info = JSON.parse(data)
  console.log('BriefsWizard : saveUploadedFile', section, field, info)
  if (field) {
    localData[section][field] = data
  } else {
    localData[section] = data
  }
  moveTemporaryFilesToContract()
}

// dom isn't updating on form values change
const forceRefresh = ref('')

// fetch the brief if we have a brief_id
const briefLoaded = ref(false)

// check what stage we are at, don't allow access to later stages until payment is complete
// redirect to correct stage if we have returned from login
const checkStage = () => {
  // No stage set
  if (localData.lastStage) {
    // resume or return from login
    stage.value = localData.lastStage
    debounceLog('BriefsWizard : checkStage : resume or return from login')
  } else if (props.stage) {
    // use the stage passed in
    stage.value = parseInt(props.stage)
    debounceLog('BriefsWizard : checkStage : use the stage passed in')
  } else {
    // default to start
    stage.value = 1
    debounceLog('BriefsWizard : checkStage : default to start')
  }

  // redirect to correct url
  let params = {
    stage: stage.value,
    brief_id: ''
  }

  if (props.brief_id) {
    params.brief_id = props.brief_id
  } else if (localData.brief.id) {
    params.brief_id = localData.brief.id
  }

  // submit button text
  if (localData.brief.termsAndConditions !== 'true' && stage.value == 3) {
    nextValue.value = 'Setup Payment'
  } else {
    nextValue.value = 'Next'
  }

  // don't allow access to later stages until payment is complete
  if (!localData.paymentComplete) {
    if (stage.value > 3) {
      stage.value = 3
    }
  }

  // last completed stage
  if (stage.value > lastCompletedStage) {
    lastCompletedStage = stage.value
  }
  if (localData.brief.status === 'published') {
    lastCompletedStage = 4
  }

  if (stage.value < 5) {
    // go to correct stage
    $router.replace({
      name: pageUrl,
      params: params,
      query: $route.query ? { ...$route.query } : {},
      hash: $route.hash
    })
  }
}

const getBriefLink = (nodomain: boolean = false) => {
  // get the domain
  let domain: string = window.location.protocol + '//' + window.location.host
  if (nodomain) {
    domain = ''
  }
  return (
    domain +
    $router.resolve({
      name: 'briefs.briefdetails',
      params: {
        brief_id: localData.brief.slug || localData.brief.id
      }
    }).href
  )
}

const viewBrief = () => {
  // redirect to details
  $router.push(getBriefLink(true))
}

// fetch the brief from contract
const fetchBrief = async (id: string) => {
  if (!accountId.value) {
    console.debug('BriefsWizard : fetchBrief : no account id yet')
    return
  }
  await briefStore.fetchBrief(id, accountId.value)
}

const refreshProfile = async () => {
  const profile = await fetchProfile(accountId.value)
  if (profile) {
    localData.profile = profile
  }
}

const setPaymentComplete = () => {
  localData.paymentComplete = true
  setStage(4)
}

// see the wood for the trees when working on the payment bit
const showProfile = ref(true)

const checkUploadAccountId = async () => {
  // if we are not logged in we still need an account id for the temporary upload
  if (accountId.value) {
    await refreshProfile()
    uploadAccountId.value = accountId.value
  } else {
    uploadAccountId.value = 'temporary-' + localData.uuidv4
  }
}

// first read must be from the contract on page load if possible
let useLocalData = false
let fetchedLocal: any

if (!props.brief_id) {
  briefLoaded.value = true
  useLocalData = true
}

// create a new brief
if (!props.stage && !isEdit.value) {
  // marketplace/create-brief without a stage
  console.log('BriefsWizard : no stage passed in create-brief mode, clearing data')
  clearLocalData()
  stage.value = 1
}

// save to localdata
clearInterval(window.autoSave)
window.autoSave = setInterval(() => {
  if (!useLocalData && props.brief_id) {
    console.log('Not saving localdata until we have had a look at the contract')
    return
  }

  // force refresh in the dom
  forceRefresh.value = new Date().getSeconds().toString()

  // final stage
  if (stage.value == 5) {
    clearInterval(window.autoSave)
    clearLocalData()
    return
  }

  // add meta data
  localData.updatedAt = new Date().toISOString()
  localData.brief.contentTypes = getContentTypes()
  localData.lastStage = stage.value

  // save to local storage
  saveLocalData(localData)
}, 1000)

watch(briefActive, (briefValue) => {
  if (briefValue) {
    const data = briefValue

    debounceLog('BriefsWizard : fetchBrief : fetched from contract', data)

    // copy the data into the right localData structure
    const { brief, profile, marketing } = setDataFromBrief(data)

    localData.brief = brief
    localData.profile = profile
    localData.marketing = marketing

    // add in payment preferences
    if (data.paymentPreference) {
      if (!localData.brief.paymentPreference) {
        localData.brief.paymentPreference = data.paymentPreference
      }

      // init payment complete
      if (!localData.paymentComplete) {
        if (parseInt(data.paymentPreference.budget) > 0 && data.paymentPreference.email_contact) {
          localData.paymentComplete = true
        }
      }
    }
    checkStage()
  }
})

watchEffect(async () => {
  if (!props.brief_id) {
    // fetch latest data from local storage
    if (useLocalData) {
      fetchedLocal = getLocalData()
      if (fetchedLocal) {
        console.log('BriefsWizard : fetched from local storage')
        localData = fetchedLocal
      }
    }
  } else if (accountId.value) {
    // brief exists in contract, fetch from there
    await fetchBrief(props.brief_id)
    // we can now save to local storage
    useLocalData = true
  }

  // render page
  briefLoaded.value = true

  // clear bad data
  if (localData && typeof localData.brief.id === 'undefined') {
    console.log('BriefsWizard : invalid data, clearing')
    clearLocalData()
  }

  // unpack content types
  if (localData.brief.contentTypes) {
    contentTypesVals.value = localData.brief.contentTypes?.split(',')
  }

  // generate temp uuid if not set
  if (!localData.uuidv4) {
    localData.uuidv4 = uuidv4()
  }

  checkUploadAccountId()

  debounceLog('BriefsWizard :', localData)
})

onMounted(() => {
  trackEventCurrentStage(1)
})

checkStage()
</script>

<template>
  <header class="bg-cm-blue-500 shadow">
    <PageHeading v-if="stage < 5" :title="pageTitle" />
    <PageHeading v-else title="Congratulations" />
  </header>

  <div v-if="briefLoaded">
    <main>
      <div class="md:flex md:justify-center">
        <div class="max-w-3xl">
          <h1 v-if="stage < 5">{{ pageTitle }}</h1>
          <h1 v-else>Congratulations</h1>

          <!-- Stage navigation -->
          <div v-if="stage < 5" class="flex justify-center">
            <Steps :allowAnyStep="brief_id != ''" :selectedStep="stage" :steps="stages"></Steps>
          </div>

          <ToggleFade :show="stageWarning">
            <div class="my-6 text-red-600 text-center text-xs">
              Please complete the previous stage
            </div>
          </ToggleFade>

          <div v-if="contractBusy">
            <SpinnerOverlay />
          </div>

          <!-- animate stage changes-->
          <div
            class="transition duration-500 px-8"
            :class="{
              'opacity-0': !fadeIn,
              'opacity-100': fadeIn
            }"
          >
            <FormKit type="form" ref="form" :actions="false" @submit="setStage(stage + 1)">
              <div class="my-6 text-sm text-red-600 hidden" v-if="form?.node">
                <FormKitMessages :node="form.node" />
              </div>

              <!-- DOM is not refreshing when NDA slider changes -->
              <div class="absolute left-1 top-1 text-xs" style="opacity: 0.01">
                {{ forceRefresh }}
              </div>

              <!-- stage 1 -->
              <div v-if="stage == 1">
                <h2 class="mb-0">What type of content do you need?</h2>
                <h3 class="mb-3">Please select at least one option from the list below.</h3>

                <!-- Content types -->
                <div id="contentTypes" class="my-3 relative">
                  <FormKit
                    type="checkbox"
                    name="type"
                    :options="contentTypes"
                    v-model="contentTypesVals"
                    on-value="true"
                    off-value="false"
                    validation="required|min:1"
                    validation-label="content types"
                    :delay="200"
                  />
                </div>

                <div class="my-3 md:flex md:justify-between">
                  <!-- Brief Title -->
                  <div class="md:w-[48%]">
                    <FormKit
                      type="text"
                      name="brief_title"
                      id="brief_title"
                      outer-class="min-w-full"
                      label="The brief title *"
                      v-model="localData.brief.title"
                      validation="required"
                    />
                  </div>
                  <!-- Brief Name -->
                  <div class="md:w-[48%]">
                    <FormKit
                      type="text"
                      outer-class="min-w-full"
                      name="brand_name"
                      id="brand_name"
                      label="The brand name *"
                      v-model="localData.brief.brandName"
                      validation="required"
                    />
                  </div>
                </div>

                <div class="my-3 md:flex md:justify-between">
                  <!-- Brief Slug -->
                  <div class="md:w-[48%]" :key="localData.brief.id">
                    <BriefSlugInput v-model="localData.brief.slug" :brief-id="localData.brief.id" />
                  </div>
                </div>

                <!-- Brief Summary -->
                <div class="my-3">
                  <FormKit
                    type="textarea"
                    auto-height
                    outer-class="min-w-full"
                    name="brief_summary"
                    id="brief_summary"
                    label="The brief summary *"
                    v-model="localData.brief.summary"
                    validation="required"
                  />
                </div>

                <!-- Application / Submission Deadline -->
                <div class="my-3 md:flex md:justify-between relative z-20">
                  <div class="md:w-[48%]">
                    <FormKit
                      type="datepicker"
                      name="interest_deadline"
                      label="Application Deadline *"
                      placeholder="Choose a date"
                      format="DD/MM/YY"
                      v-model="localData.brief.interestDeadline"
                      validation="required"
                      :min-date="new Date()"
                      picker-only
                      overlay
                    />
                  </div>
                  <div class="md:w-[48%]">
                    <FormKit
                      type="datepicker"
                      name="submission_deadline"
                      label="Submission Deadline *"
                      placeholder="Choose a date"
                      format="DD/MM/YY"
                      v-model="localData.brief.submissionDeadline"
                      :validation-rules="{ validateSubmissionDeadline }"
                      validation="required|validateSubmissionDeadline"
                      :validation-messages="{
                        validateSubmissionDeadline:
                          'Submission Deadline must be after Application Deadline'
                      }"
                      :min-date="new Date()"
                      picker-only
                      overlay
                    />
                  </div>
                </div>

                <div class="clear h-3"></div>

                <!-- Brand Logo -->
                <div class="my-6 mb-12 flex flex-col items-center justify-center">
                  <ToggleContent
                    message="Upload a brand logo"
                    uploadField="brief.brandLogo"
                    :uploadImagesOnly="true"
                    :uploadAccountId="uploadAccountId"
                    :uploadValue="localData.brief.brandLogo"
                    @onUploaded="saveUploadedFile"
                  />
                </div>

                <!-- Brief Hero Image -->
                <div class="my-6 flex flex-col items-center justify-center">
                  <ToggleContent
                    message="Upload a hero image for the brief"
                    uploadField="brief.image"
                    :uploadImagesOnly="true"
                    :uploadAccountId="uploadAccountId"
                    :uploadValue="localData.brief.image"
                    @onUploaded="saveUploadedFile"
                  />
                </div>
              </div>

              <!-- stage 2 -->
              <div v-if="stage == 2">
                <h2 class="mb-0">Would you like to include an NDA?</h2>
                <h3 class="mb-6">
                  You can choose to add an NDA prior to revealing the brand name and full
                  information
                </h3>

                <!-- NDA required -->
                <div class="flex items-center justify-center">
                  <div class="flex-none text-sm">No</div>
                  <div class="flex-none relative px-2">
                    <FormKit
                      type="toggle"
                      name="visibility"
                      v-model="localData.brief.visibility"
                      thumb-color-on="white"
                      track-color-on="#FF008B"
                      icon-color-on="white"
                      on-value="private"
                      off-value="public"
                    />
                  </div>
                  <div class="flex-none text-sm">Yes</div>
                </div>

                <div class="clear h-3"></div>

                <!-- NDA required section -->
                <ToggleFade :show="localData.brief.visibility == 'private'">
                  <!-- NDA upload -->
                  <div class="mt-3 my-9 flex flex-col items-center justify-center">
                    <ToggleContent
                      message="Upload your NDA"
                      uploadField="brief.ndaText"
                      :uploadDocumentsOnly="true"
                      :uploadAccountId="uploadAccountId"
                      :uploadValue="localData.brief.ndaText"
                      @onUploaded="saveUploadedFile"
                    />
                  </div>

                  <!-- Review text -->
                  <div class="my-3 mt-6">
                    <h2 class="mb-0">Review your information</h2>
                    <h3 class="mb-6">
                      Thanks for uploading your NDA text. Please now review the answers below and
                      decide if you are happy for these to be published
                    </h3>
                  </div>

                  <!-- NDA Brief Title -->
                  <div class="my-3 md:flex md:justify-between">
                    <div class="md:w-[48%]">
                      <FormKit
                        type="text"
                        name="nda_brief_title"
                        id="nda_brief_title"
                        outer-class="min-w-full"
                        label="The NDA brief title"
                        v-model="localData.brief.ndaTitle"
                      />
                    </div>

                    <!-- Brief NDA Brand Name -->
                    <div class="md:w-[48%]">
                      <FormKit
                        type="text"
                        outer-class="min-w-full"
                        name="nda_brand_name"
                        id="nda_brand_name"
                        label="The NDA brand name"
                        v-model="localData.brief.ndaBrandName"
                      />
                    </div>
                  </div>

                  <!-- Brief NDA Summary -->
                  <div class="my-3">
                    <FormKit
                      type="textarea"
                      auto-height
                      outer-class="min-w-full"
                      name="nda_brief_summary"
                      id="nda_brief_summary"
                      label="The NDA brief summary"
                      v-model="localData.brief.ndaSummary"
                    />
                  </div>

                  <!-- ndaCompanyLogo -->
                  <div class="my-6 flex flex-col items-center justify-center">
                    <ToggleContent
                      message="Upload a NDA brand logo"
                      uploadField="brief.ndaCompanyLogo"
                      :uploadImagesOnly="true"
                      :uploadAccountId="uploadAccountId"
                      :uploadValue="localData.brief.ndaCompanyLogo"
                      @onUploaded="saveUploadedFile"
                    />
                  </div>

                  <!-- ndaImage -->
                  <div class="my-6 flex flex-col items-center justify-center">
                    <ToggleContent
                      message="Upload a NDA hero image for the brief"
                      uploadField="brief.ndaImage"
                      :uploadImagesOnly="true"
                      :uploadAccountId="uploadAccountId"
                      :uploadValue="localData.brief.ndaImage"
                      @onUploaded="saveUploadedFile"
                    />
                  </div>
                </ToggleFade>
              </div>

              <!-- stage 3 -->
              <div
                v-if="stage == 3"
                :class="{
                  'mt-9': !accountId
                }"
              >
                <AuthWrapper
                  title="Add profile"
                  description="Before you add your profile information please login or register using a social media account."
                >
                  <h2 class="mb-0">Profile and payments</h2>
                  <h3 class="mb-6">
                    Please complete your profile and payment preferences below. Your profile will be
                    the one you use to communicate with creators
                  </h3>

                  <!-- ProfilePic -->
                  <div
                    v-show="showProfile"
                    class="my-6 flex flex-col items-center justify-center profilePic"
                  >
                    <ToggleContent
                      message="Upload a profile picture"
                      uploadField="profile.profilePic"
                      :uploadImagesOnly="true"
                      :uploadAccountId="uploadAccountId"
                      :uploadValue="localData.profile.profilePic"
                      @onUploaded="saveUploadedFile"
                    />
                  </div>

                  <!-- First Name -->
                  <div class="my-3 md:flex md:justify-between">
                    <div v-if="showProfile" class="md:w-[48%]">
                      <FormKit
                        type="text"
                        name="first_name"
                        id="first_name"
                        outer-class="min-w-full"
                        label="First name"
                        v-model="localData.profile.firstName"
                      />
                    </div>

                    <!-- Last Name -->
                    <div v-if="showProfile" class="md:w-[48%]">
                      <FormKit
                        type="text"
                        name="last_name"
                        id="last_name"
                        outer-class="min-w-full"
                        label="Last name"
                        v-model="localData.profile.lastName"
                      />
                    </div>
                  </div>

                  <div class="my-3 md:flex md:justify-between">
                    <!-- City -->
                    <div v-if="showProfile" class="md:w-[48%]">
                      <FormKit
                        type="text"
                        name="city"
                        id="city"
                        outer-class="min-w-full"
                        label="City"
                        v-model="localData.profile.city"
                      />
                    </div>

                    <!-- Country -->
                    <div v-if="showProfile" class="md:w-[48%]">
                      <FormKit
                        type="text"
                        name="country"
                        id="country"
                        outer-class="min-w-full"
                        label="Country"
                        v-model="localData.profile.country"
                      />
                    </div>
                  </div>

                  <div class="my-3 md:flex md:justify-between">
                    <!-- Company Name -->
                    <div v-if="showProfile" class="md:w-[48%]">
                      <FormKit
                        type="text"
                        name="company_name"
                        id="company_name"
                        outer-class="min-w-full"
                        label="Company name"
                        v-model="localData.profile.companyName"
                      />
                    </div>
                  </div>

                  <!-- Terms & Conditions -->
                  <div class="relative w-full overflow-hidden">
                    <div class="flex mt-6 items-center">
                      <div class="flex-grow text-sm">
                        By checking this box you accept the
                        <a
                          target="_blank"
                          class="link"
                          href="https://contentedworld.com/terms-and-privacy/"
                          >Terms and Conditions and Privacy Policy</a
                        >
                        of Contented Media Group Ltd.
                      </div>
                      <div class="flex-none ml-3">
                        <FormKit
                          type="checkbox"
                          name="terms"
                          id="terms"
                          outer-class="min-w-full"
                          v-model="localData.brief.termsAndConditions"
                          on-value="true"
                          off-value="false"
                          validation="accepted"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- Payment Preferences -->
                  <div
                    v-if="localData.brief.id && localData.brief.termsAndConditions == 'true'"
                    class="my-6"
                  >
                    <BriefProjectPaymentForm
                      :brief="localData.brief"
                      @complete="setPaymentComplete"
                      @scrollToError="scrollToError(true)"
                    />
                  </div>
                </AuthWrapper>
              </div>

              <!-- stage 4 -->
              <div
                v-if="stage == 4"
                :class="{
                  'mt-9': !accountId
                }"
              >
                <AuthWrapper
                  title="Optimize your Brief"
                  description="Before you add your profile information please login or register using a social media account."
                >
                  <h2 class="mb-0">Optimize your brief</h2>
                  <h3 class="mb-6">
                    Add example media and provide further details and background about the brand and
                    the brief
                  </h3>

                  <!-- Upload from local / uppy -->
                  <div class="my-6 flex flex-col items-center justify-center">
                    <ToggleContent
                      message="Add example media"
                      uploadField="brief.metaData"
                      :uploadAccountId="uploadAccountId"
                      :uploadValue="localData.brief.metaData"
                      :multiSelect="true"
                      @onUploaded="saveUploadedFile"
                    />
                  </div>

                  <!-- Upload from social : Not available yet
                <div class="my-6 mb-9 flex flex-col items-center justify-center">
                  <h3 class="font-bold">Add from social</h3>
                  <div class="action-button hollow">Upload file</div>
                </div>
              -->

                  <!-- About the brand -->
                  <div class="my-6">
                    <ToggleContent
                      message="About the brand"
                      :show="isSet(localData.brief.aboutTheBrand)"
                    >
                      <FormKit
                        type="textarea"
                        auto-height
                        outer-class="min-w-full"
                        name="about_the_brand"
                        id="about_the_brand"
                        v-model="localData.brief.aboutTheBrand"
                      />
                    </ToggleContent>
                  </div>

                  <!-- Content rights -->
                  <div class="my-6">
                    <ToggleContent
                      message="Content rights"
                      :show="
                        isSet(localData.brief.contentRights) || isSet(localData.brief.modelRelease)
                      "
                    >
                      <FormKit
                        type="textarea"
                        auto-height
                        outer-class="min-w-full"
                        name="content_rights"
                        id="content_rights"
                        v-model="localData.brief.contentRights"
                      />
                      <!-- Upload from local / uppy -->
                      <div class="my-6 flex flex-col items-center justify-center">
                        <ToggleContent
                          message="Upload model release"
                          :show="isSet(localData.brief.modelRelease)"
                          uploadField="brief.modelRelease"
                          :uploadAccountId="uploadAccountId"
                          :uploadValue="localData.brief.modelRelease"
                          @onUploaded="saveUploadedFile"
                        />
                      </div>
                    </ToggleContent>
                  </div>

                  <!-- Deliverables -->
                  <div class="my-6">
                    <ToggleContent
                      message="Deliverables"
                      :show="
                        isSet(localData.brief.deliverables) || isSet(localData.brief.preProduction)
                      "
                    >
                      <FormKit
                        type="textarea"
                        auto-height
                        outer-class="min-w-full"
                        name="deliverables"
                        id="deliverables"
                        v-model="localData.brief.deliverables"
                      />
                      <!-- Upload from local / uppy -->
                      <div class="my-6 flex flex-col items-center justify-center">
                        <ToggleContent
                          message="Upload Pre-Production notes"
                          :show="isSet(localData.brief.preProduction)"
                          uploadField="brief.preProduction"
                          :uploadAccountId="uploadAccountId"
                          :uploadValue="localData.brief.preProduction"
                          :multiSelect="true"
                          @onUploaded="saveUploadedFile"
                        />
                      </div>
                    </ToggleContent>
                  </div>

                  <!-- Rewards & Incentives -->
                  <div class="my-6">
                    <ToggleContent
                      message="Rewards and incentives"
                      :show="isSet(localData.brief.rewards)"
                    >
                      <FormKit
                        type="textarea"
                        auto-height
                        outer-class="min-w-full"
                        name="rewards"
                        id="rewards"
                        v-model="localData.brief.rewards"
                      />
                    </ToggleContent>
                  </div>

                  <!-- Technical Requirements -->
                  <div class="my-6">
                    <ToggleContent
                      message="Technical requirements"
                      :show="isSet(localData.brief.techRequirements)"
                    >
                      <FormKit
                        type="textarea"
                        auto-height
                        outer-class="min-w-full"
                        name="tech_requirements"
                        id="tech_requirements"
                        v-model="localData.brief.techRequirements"
                      />
                    </ToggleContent>
                  </div>

                  <!-- Things to consider -->
                  <div class="my-6">
                    <ToggleContent
                      message="Things to consider"
                      :show="isSet(localData.brief.considerations)"
                    >
                      <FormKit
                        type="textarea"
                        auto-height
                        outer-class="min-w-full"
                        name="considerations"
                        id="considerations"
                        v-model="localData.brief.considerations"
                      />
                    </ToggleContent>
                  </div>

                  <!-- Ideal candidate -->
                  <div class="my-6">
                    <ToggleContent
                      message="Ideal candidate"
                      :show="isSet(localData.brief.idealCandidate)"
                    >
                      <FormKit
                        type="textarea"
                        auto-height
                        outer-class="min-w-full"
                        name="ideal_candidate"
                        id="ideal_candidate"
                        v-model="localData.brief.idealCandidate"
                      />
                    </ToggleContent>
                  </div>

                  <!-- Custom Section -->
                  <div class="my-6">
                    <ToggleContent
                      message="Custom section"
                      :show="isSet(localData.brief.customSection)"
                    >
                      <FormKit
                        type="textarea"
                        auto-height
                        outer-class="min-w-full"
                        name="custom_section"
                        id="custom_section"
                        v-model="localData.brief.customSection"
                      />
                    </ToggleContent>
                  </div>
                </AuthWrapper>
              </div>

              <!-- stage 5 -->
              <div v-if="stage == 5">
                <h2>Thanks for creating a brief</h2>

                <div class="mb-6 text-center">
                  <p v-if="localData.brief.status === 'published'">
                    Your brief will now be visible to creators who visit the View Briefs page. You
                    might want to increase the visibility of your brief by sharing a direct link to
                    the brief with creators you would like to work with.
                  </p>
                  <p v-else>
                    Your brief will now be saved to Drafts in your My Briefs page. You can continue
                    to Edit the brief to increase the interest in your brief from candidate creators
                    until you Publish.
                  </p>
                </div>

                <!--
            <h2 class="my-6 mb-3 flex justify-center">Rate your experience:</h2>

            <div class="flex justify-center">
              <FormKit
                :classes="{
                  wrapper: 'flex justify-center'
                }"
                type="rating"
                off-color="#D9D7CE"
                on-color="#E4DA80"
                rating-icon="star"
                name="rating"
              />
            </div>
            -->
                <div class="my-6">
                  <SurveyRating
                    class="max-w-[360px] mx-auto"
                    :id="{
                      production: '018ff20a-00e7-0000-1d6a-e65c5063e120',
                      uat: '018ff209-a7ae-0000-98f0-e89e01241834',
                      preview: '018ff208-aaa3-0000-c894-e0a865254fce'
                    }"
                  />
                </div>

                <div class="flex justify-center">
                  <div class="action-button w-42 solid" @click="viewBrief">View brief</div>
                </div>
                <div v-if="localData.brief.status == 'published'">
                  <h2 class="my-6">Why not:</h2>
                  <div class="flex justify-center">
                    <ShareLink
                      :href="getBriefLink()"
                      :text="localData.brief.title"
                      buttonTitle="Share this brief"
                    />
                  </div>
                </div>
                <FollowSocialLinks />
              </div>

              <!-- Next / Publish -->
              <div
                v-if="
                  (stage != 3 || !localData.brief.id) &&
                  stage < 5 &&
                  (stage < 3 || (stage > 2 && accountId))
                "
                class="my-12 flex flex-col items-center justify-center"
              >
                <!--<div class="action-button w-42 solid ml-3" @click="saveBrief(false)">Save Test</div>-->

                <div v-if="stage < stages.length" class="flex">
                  <input
                    type="submit"
                    class="action-button solid"
                    :value="nextValue"
                    @click="scrollToError(true)"
                  />
                </div>
                <div v-else class="flex justify-center">
                  <div
                    class="action-button w-42 hollow mr-2"
                    @click="saveBrief(true, 'unpublished')"
                  >
                    Save Draft
                  </div>
                  <div class="action-button w-42 solid ml-2" @click="saveBrief(true, 'published')">
                    Publish Brief
                  </div>
                </div>
              </div>
            </FormKit>

            <!-- Back -->
            <div
              class="flex my-6 justify-center text-gray-400 hover:text-[#FF008B] cursor-pointer"
              v-if="stage > 1 && stage < 5"
              @click="setStage(stage - 1)"
            >
              Back
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style lang="scss">
h1,
h2 {
  @apply text-center my-6 font-bold;
}
h3,
h4,
h5,
h6 {
  @apply text-center my-3;
}
h1 {
  @apply text-xl;
}
h2 {
  @apply text-lg;
}
h3 {
  @apply text-sm;
}
#contentTypes {
  @apply w-full overflow-x-hidden;
  ul {
    @apply my-3 block;
    li {
      @apply w-1/2 xl:w-1/3 inline-block;
      label {
        @apply select-none cursor-pointer;
      }
    }
  }
}
.profilePic {
  .media-container {
    @apply w-48;
    .bgImageContainer {
      @apply rounded-full bg-cover bg-center w-36 h-36 block;
    }
    .fgImageContainer {
      @apply hidden;
    }
  }
}
</style>

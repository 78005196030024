import { useRouter } from 'vue-router'
import type {
  RouteLocationRaw,
  RouteLocationPathRaw,
  RouteLocationNamedRaw,
  RouteRecordName
} from 'vue-router'

export const baseUrl = () => window.location.protocol + '//' + window.location.hostname

export function route(name: RouteRecordName | RouteLocationRaw, params?: RouteLocationRaw) {
  const $router = useRouter()

  let request: RouteLocationRaw = {}

  if (typeof name === 'string') {
    if (name.startsWith('/')) {
      request = (params || {}) as RouteLocationPathRaw
      request.path = name
    } else {
      request = (params || {}) as RouteLocationNamedRaw
      request.name = name
    }
  } else if (typeof name === 'object') {
    request = name
  }

  const $resolved = $router.resolve(request)

  return $resolved?.href || ''
}

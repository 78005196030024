import ProfileEdit from '@/components/Pages/Profiles/Edit.vue'
import ProfileUpload from '@/components/Pages/Profiles/Uploads.vue'
import ProfilePortfolioItem from '@/components/Pages/Profiles/PortfolioItem.vue'

export default [
  {
    path: '/profiles',
    children: [
      {
        path: '/self',
        children: [
          {
            path: '/edit',
            component: ProfileEdit,
            name: 'profiles.self.edit',
            props: true
          },
          {
            path: '/uploads/:uploadId?',
            component: ProfileUpload,
            name: 'profiles.self.uploads',
            props: true
          }
        ]
      },
      {
        path: '/:accountId/portfolio/:uploadId?',
        component: ProfilePortfolioItem,
        name: 'profiles.portfolio.item',
        props: true
      }
    ]
  }
]

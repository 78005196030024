<script lang="ts" setup>
import { computed, reactive, ref, watchEffect, watch } from 'vue'
import { fetchProfile } from '@/queries/api'
import { useUcanStore } from '@/stores/ucan'
import { useTeamStore } from '@/stores/team'
import { extractFileUrl } from '@/utils/mediaDecrypt'
import { useImageCDN } from '@/utils/image'

import Loading from '@/components/Loading.vue'
import BriefProfileSocials from '@/components/Pages/Briefs/BriefProfileSocials.vue'
import ProfileAvatar from '@/components/ProfileAvatar.vue'

const ucanStore = useUcanStore()
const teamStore = useTeamStore()

const emit = defineEmits(['profileLoaded', 'showBody'])

const params = reactive({
  accountId: ''
})

const props = withDefaults(
  defineProps<{
    accountId: string
    brief: SmartBrief
    isLoading: boolean
    version?: string
    isPreviewMode?: boolean
    doSubmissionAccept?: Function
    onCandidateProfileStatusEvent?: Function
    index?: number
    showAccept?: boolean
    isClosedBrief?: boolean
  }>(),
  {
    version: 'candidate',
    isPreviewMode: false,
    doSubmissionAccept: () => {},
    onCandidateProfileStatusEvent: () => {},
    index: 1,
    showAccept: false,
    isClosedBrief: false
  }
)

// allow buttons to be clicked
const demo = ref(false)
const showBody = ref(true)
const avatar = ref('')
// const isAccepted = ref(false)
const profile = ref<ProfileData>()

watch(showBody, () => {
  emit('showBody', showBody.value)
})

const profileName = computed(() => {
  return profile.value?.firstName + ' ' + profile.value?.lastName
})

const profileLocation = computed(() => {
  return [profile.value?.city, profile.value?.country].join(', ')
})

watchEffect(async () => {
  const profileData = await fetchProfile(props.accountId)

  params.accountId = props.accountId

  profile.value = profileData

  if (props.brief) {
    /* const filtered = props.brief?.contents?.filter(() =>
      props.brief.acceptedProfiles.includes(props.accountId)
    )
    if (filtered.length > 0) {
      isAccepted.value = true
      // console.log(`${props.accountId} is accepted`);
    } else {
      isAccepted.value = false
      // console.log(`${props.accountId} is not accepted`);
    } */
  }

  if (profile.value) {
    const url = useImageCDN(extractFileUrl(profile.value.profilePic), 'thumb')
    if (url) {
      avatar.value = url
    }
    emit('profileLoaded', profile.value)
  }
})

const userCandidateProfile = computed(() => {
  const profile = props?.brief?.candidateProfiles?.find((item) => item.userId === props?.accountId)
  return profile
})

const userSubmissionAccepted = computed(
  () => props?.brief?.acceptedProfiles?.indexOf(props.accountId) > -1
)

const fadeOut = ref(false)
const showLoading = ref(false)
const setLoading = () => {
  fadeOut.value = true
  setTimeout(() => {
    showLoading.value = true
  }, 500)
}
const hideLoading = () => {
  fadeOut.value = false
  setTimeout(() => {
    showLoading.value = false
  }, 5000)
}

const ACCEPT_VALUE = 'approved'
const candidateAccept = async () => {
  if (props.isPreviewMode) {
    console.log('candidateAccept: isPreviewMode active, doing nothing')
    return
  }
  setLoading()
  await props?.onCandidateProfileStatusEvent(props.accountId, ACCEPT_VALUE)
  hideLoading()
}

const SHORTLIST_VALUE = 'shortlisted'
const candidateShortlist = async () => {
  if (props.isPreviewMode) {
    console.log('candidateAccept: isPreviewMode active, doing nothing')
    return
  }
  setLoading()
  await props?.onCandidateProfileStatusEvent(props.accountId, SHORTLIST_VALUE)
  hideLoading()
}

const REJECT_VALUE = 'rejected'
const candidateReject = async () => {
  if (props.isPreviewMode) {
    console.log('candidateAccept: isPreviewMode active, doing nothing')
    return
  }
  setLoading()
  await props?.onCandidateProfileStatusEvent(props.accountId, REJECT_VALUE)
  hideLoading()
}
/* const submissionReject = async (feedback = '') => {
  if (props.isPreviewMode) {
    console.log('candidateAccept: isPreviewMode active, doing nothing')
    return
  }
  setLoading()
  await props?.onCandidateProfileStatusEvent(props.accountId, ACCEPT_VALUE, feedback)
  hideLoading()
} */

const submissionAccept = async () => {
  if (props.isPreviewMode) {
    console.log('candidateAccept: isPreviewMode active, doing nothing')
    return
  }
  setLoading()
  await props?.doSubmissionAccept()
  hideLoading()
}

const isSubmissionPending = computed(() => {
  const isApproved = userCandidateProfile.value?.status === 'approved'
  const isContentPending = userCandidateProfile.value?.contentStatus === 'pending'

  /* let content = props.brief?.contents ? [...props.brief.contents] : []
  content = content?.filter((item) => item?.owner === props?.accountId)
  content.sort((a, b) => (b?.submit_date || 0) - (a?.submit_date || 0))

  let feedback = userCandidateProfile.value?.feedbacks
    ? [...userCandidateProfile.value.feedbacks]
    : []
  feedback.sort((a, b) => (b?.submit_date || 0) - (a?.submit_date || 0))

  let contentLatest: SmartBriefContent = content?.[0]
  let feedbackLatest: CandidateFeedback = feedback?.[0]

  return (
    isAccepted &&
    (!contentLatest?.submit_date ||
      parseInt(`${feedbackLatest?.submit_date || 0}`) > parseInt(`${contentLatest?.submit_date}`))
  ) */
  return isApproved && isContentPending
})
</script>
<template>
  <div class="mx-0">
    <!-- candidates page -->
    <div v-if="version === 'candidate'" class="mb-3 text-center w-[80%] mx-auto">
      <div class="mt-3 flex justify-center">
        <ProfileAvatar
          :profile-id="accountId"
          :data="avatar"
          class="h-20 w-20"
          imageBgColor="bg-transparent"
          imageBorderColor="border-transparent"
          imageBorderColorHover="group-hover:border-transparent"
          fallbackBorderColor="border-gray-300"
          fallbackTextColor="text-gray-500"
          fallbackTextSize="text-3xl"
        />
      </div>
      <h4 class="font-bold text-center tracking-wide mt-4">{{ profileName }}</h4>
      <div>
        <p class="p-2 text-xs" v-if="profile?.headline">{{ profile.headline }}</p>
        <p class="text-sm" v-if="profileLocation">{{ profileLocation }}</p>
      </div>
      <BriefProfileSocials
        :profile="profile"
        class="my-6 text-center"
        wrapper-class="flex justify-center items-center flex-wrap"
      >
      </BriefProfileSocials>
      <div v-if="!isClosedBrief" class="mt-3 flex flex-wrap justify-center items-center text-sm">
        <Loading v-if="showLoading" message="Please wait..." :vertical="true" />
        <div
          class="transition duration-700"
          :class="{
            'opacity-0': fadeOut,
            'opacity-100': !fadeOut
          }"
        >
          <button
            v-if="
              demo ||
              ((teamStore.can('team.briefs.creators.accept') ||
                props.brief?.owner === props.accountId) &&
                userCandidateProfile?.status !== REJECT_VALUE &&
                showLoading === false)
            "
            type="button"
            :onclick="userCandidateProfile?.status !== ACCEPT_VALUE ? candidateAccept : null"
            class="border rounded-full px-6 py-2 text-sm font-bold w-full"
            :class="{
              'hover:opacity-90 bg-[#E2007B] border-[#FF008B] hover:bg-white hover:text-[#FF008B] text-white cursor-pointer':
                userCandidateProfile?.status !== ACCEPT_VALUE || demo,
              'hover:opacity-90 bg-[#009FE3] border-[#009FE3] hover:bg-white hover:text-[#009FE3] text-white':
                userCandidateProfile?.status === ACCEPT_VALUE && !demo,
              'bg-[#009FE3] border-[#009FE3] text-white cursor-default hover:bg-[#009FE3] hover:border-[#009FE3] hover:text-[#009FE3] hover:opacity-100':
                userCandidateProfile?.status === ACCEPT_VALUE
            }"
          >
            <span>Accept{{ userCandidateProfile?.status === ACCEPT_VALUE ? 'ed' : '' }}</span>
          </button>
          <button
            v-if="
              demo ||
              ((teamStore.can('team.briefs.creators.accept') ||
                props.brief?.owner === props.accountId) &&
                userCandidateProfile?.status !== ACCEPT_VALUE &&
                userCandidateProfile?.status !== REJECT_VALUE &&
                showLoading === false)
            "
            type="button"
            :onclick="userCandidateProfile?.status !== SHORTLIST_VALUE ? candidateShortlist : null"
            class="border border-[#009FE3] font-bold rounded-full px-6 py-2 mt-2 w-full"
            :class="{
              'hover:opacity-90 bg-white border-[#009FE3] hover:bg-[#009FE3] hover:text-white text-[#009FE3] cursor-pointer':
                userCandidateProfile?.status !== SHORTLIST_VALUE || demo,
              'hover:opacity-90 bg-[#009FE3] border-[#009FE3] hover:bg-white hover:text-[#009FE3] text-white':
                userCandidateProfile?.status === SHORTLIST_VALUE && !demo,
              'bg-[#009FE3] border-[#009FE3] text-white cursor-default hover:bg-[#009FE3] hover:border-[#009FE3] hover:text-[#009FE3] hover:opacity-100':
                userCandidateProfile?.status === SHORTLIST_VALUE
            }"
          >
            <span>Shortlist{{ userCandidateProfile?.status === SHORTLIST_VALUE ? 'ed' : '' }}</span>
          </button>
          <button
            v-if="
              demo ||
              ((teamStore.can('team.briefs.creators.reject') ||
                props.brief?.owner === props.accountId) &&
                userCandidateProfile?.status !== ACCEPT_VALUE &&
                showLoading === false)
            "
            type="button"
            :onclick="userCandidateProfile?.status !== REJECT_VALUE ? candidateReject : null"
            class="border border-[#FF0000] font-bold rounded-full px-6 py-2 my-2 w-full"
            :class="{
              'border-[#FF008B] text-[#FF008B] hover:opacity-90 cursor-pointer hover:bg-[#E2007B] hover:text-white':
                userCandidateProfile?.status !== REJECT_VALUE || demo,
              'hover:opacity-90 bg-[#FF0000] border-[#FF0000] hover:bg-white hover:text-[#FF0000] text-white':
                userCandidateProfile?.status === REJECT_VALUE && !demo,
              'hover:opacity-100 bg-[#FF0000] border-[#FF0000]cursor-default  hover:bg-[#FF0000] hover:border-[#FF0000] text-white hover:text-[#FF0000]':
                userCandidateProfile?.status === REJECT_VALUE
            }"
          >
            <span>Decline{{ userCandidateProfile?.status === REJECT_VALUE ? 'd' : '' }}</span>
          </button>
        </div>
      </div>
    </div>

    <!-- submissions page without Accept Button -->
    <div
      v-if="version === 'submission' && !showAccept"
      class="flex flex-col items-center md:flex-row md:items-center pb-6"
    >
      <!-- Profile Name -->
      <div class="md:w-1/4 p-4 pt-4 pl-0">
        <div class="flex flex-row">
          <p class="font-bold">Submission {{ index }}</p>
          <!--
          <div class="ml-12">
            <a href="#" @click.prevent="showBody = !showBody" class="hover:text-blue-500">
              <i :class="{
                'fa-solid': true,
                'fa-chevron-up': showBody,
                'fa-chevron-down': !showBody
              }"></i>
            </a>
          </div>
          -->
        </div>
      </div>

      <!-- Profile Picture -->
      <div class="md:w-3/4 flex items-center gap-4 md:ml-8">
        <ProfileAvatar
          :profile-id="accountId"
          :data="avatar"
          class="h-16 w-16"
          imageBgColor="bg-transparent"
          imageBorderColor="border-transparent"
          imageBorderColorHover="group-hover:border-transparent"
          fallbackBorderColor="border-gray-300"
          fallbackTextColor="text-gray-500"
          fallbackTextSize="text-3xl"
        />
        <div class="font-bold">
          {{ profile?.firstName }} {{ profile?.lastName }}
          <!-- Social Links with minimal horizontal spacing -->
          <BriefProfileSocials :profile="profile" class="flex-none" wrapper-class="flex items-left">
          </BriefProfileSocials>
        </div>
      </div>
    </div>

    <!-- submissions page with Accept Button -->
    <div
      v-if="version === 'submission' && showAccept && !isClosedBrief"
      class="flex flex-col items-center justify-center md:flex-row md:items-center md:justify-center pt-4"
    >
      <div class="flex-none p-2">
        <div v-if="showLoading">
          <Loading message="Please wait..." :vertical="true" />
        </div>
        <div v-else-if="isSubmissionPending">
          <button
            type="button"
            class="border border-[#FF6B00] font-bold rounded-full px-6 py-3 text-white bg-[#FF6B00] cursor-default"
          >
            Pending
          </button>
        </div>
        <div v-else>
          <button
            type="button"
            @click.prevent="submissionAccept"
            class="border rounded-full px-6 py-2 text-sm font-bold"
            :class="{
              'hover:opacity-90 bg-[#E2007B] border-[#FF008B] hover:bg-white hover:text-[#FF008B] text-white cursor-pointer':
                !userSubmissionAccepted || demo,
              'hover:opacity-90 bg-[#009FE3] border-[#009FE3] hover:bg-white hover:text-[#009FE3] text-white cursor-pointer':
                userSubmissionAccepted || demo
            }"
            :disabled="userSubmissionAccepted && !demo"
          >
            Accept{{ userSubmissionAccepted ? 'ed' : '' }} all submissions
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

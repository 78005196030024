<script lang="ts" setup>
import { ref, computed, watchEffect } from 'vue'
import { useNearWalletStore, API_RESULT_NO_NEAR } from '@/stores/near-wallet'
import { baseUrl } from '@/utils/route'
import { storeToRefs } from 'pinia'
import { useUcanStore } from '@/stores/ucan'
import { useTeamStore } from '@/stores/team'
import { pinDataToIPFS } from '@/utils/ipfs'
import { currencyToCOTO, usePricingConfigurationStore } from '@/stores/pricing-configurations'
import { fetchProfile, updateSmartBriefProfileStatus } from '@/queries/api'
import { format } from 'date-fns'
import { Vue3Lottie } from 'vue3-lottie'

import AlertDialog from '@/components/AlertDialog.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import MediaItemTileSubmission from '@media/MediaItemTileSubmission.vue'
import BriefDetailsQA from '@briefs/BriefDetailsQA.vue'
import BriefProfile from '@briefs/BriefProfile.vue'
import busy from '@/lottie/busy.json'

const nearWalletStore = useNearWalletStore()
const ucanStore = useUcanStore()
const teamStore = useTeamStore()

const { accountId, balance } = storeToRefs(nearWalletStore)
const pricingStore = usePricingConfigurationStore()

const confirmDialog = ref<InstanceType<typeof ConfirmDialog> | undefined>(undefined)
const alertDialog = ref<InstanceType<typeof AlertDialog> | undefined>(undefined)
const showDetails = ref<{ [key: string]: boolean }>({})

const props = withDefaults(
  defineProps<{
    brief: SmartBrief
    onCandidateProfileStatusEvent: Function
    isBriefLoading?: boolean
    isClosedBrief?: boolean
  }>(),
  {
    onCandidateProfileStatusEvent: () => {},
    isBriefLoading: false,
    isClosedBrief: false
  }
)
const isLoading = ref(false)
const profiles = ref<SmartBriefProfile[]>([])
const profileNames = ref<{ [key: string]: ProfileData }>({})
const isProfilesLoading = ref(true)
// const threads = ref<SmartBriefFeedbackThreadMap>({})

const getAllProfiles = async (briefprofiles: SmartBriefProfile[]) => {
  const uniqueUserIds = Array.from(
    new Set(
      briefprofiles.reduce<string[]>((acc, profile) => {
        acc.push(profile.userId)
        profile.contents.forEach((content) => {
          acc.push(content.owner)
          content.feedbacks.forEach((feedback) => {
            acc.push(feedback.owner)
          })
        })
        return acc
      }, [])
    )
  )

  const profiles: { [key: string]: any } = {}
  await Promise.all(
    uniqueUserIds.map(async (userId) => {
      profiles[userId] = await fetchProfile(userId)
    })
  )
  return profiles
}

const groupedItems = computed(() => {
  const grouped: Record<
    string,
    {
      owner: string
      contents: SmartBriefContent[]
    }
  > = {}
  for (let profile of profiles.value) {
    const contents = profile.contents || []
    if (contents.length > 0) {
      grouped[profile.userId] = {
        owner: profile.userId,
        contents: []
      }
      for (let content of profile.contents) {
        grouped[profile.userId].contents.push(content)
      }
    }
  }
  const items = Object.values(grouped)
  items.sort((a, b) => {
    if (userSubmissionAccepted(a.owner) && !userSubmissionAccepted(b.owner)) {
      return 1
    }
    if (!userSubmissionAccepted(a.owner) && userSubmissionAccepted(b.owner)) {
      return -1
    }
    return 0
  })
  return items
})

const userSubmissionAccepted = (accountId: ContentedProfileIdType) =>
  props?.brief?.acceptedProfiles?.indexOf(accountId) > -1

const doSubmissionAccept = async (item: { owner: string; contents: SmartBriefContent[] }) => {
  // do nothing if this user is already accepted
  if (props?.brief?.acceptedProfiles?.indexOf(item?.owner) > -1) return

  // get data from payment preferences
  let brief_budget = props.brief.budget
  let coupon_code = null
  let payment_to_creator = null
  if (props.brief.paymentPreference) {
    // const { s64, msg } = ucanStore.createApiChallenge()
    brief_budget = (
      Number(props.brief.paymentPreference.total_charges) /
      Number(props.brief.paymentPreference.number_of_creators)
    ).toFixed(2)
    payment_to_creator = (
      Number(props.brief.paymentPreference.payment_to_creator) /
      Number(props.brief.paymentPreference.number_of_creators)
    ).toFixed(2)
    /* coupon_code = await decryptSmartBriefString(
      props.brief.id,
      props.brief.paymentPreference.promo_code!,
      props.brief.paymentPreference.key,
      props.brief.paymentPreference.iv,
      ucanStore.getApiBaseUrl(),
      s64,
      msg,
      (message) => {
        console.log(message)
      }
    )
    console.log(`coupon_code: ${coupon_code}`) */
    coupon_code = props.brief.paymentPreference.promo_code
    /* if (
      props.brief.paymentPreference.email_contact &&
      props.brief.paymentPreference.email_contact !== ''
    ) {
      email_contact = await decryptSmartBriefString(
        props.brief.id,
        props.brief.paymentPreference.email_contact,
        props.brief.paymentPreference.key,
        props.brief.paymentPreference.iv,
        ucanStore.getApiBaseUrl(),
        s64,
        msg,
        (message) => {
          console.log(message)
        }
      )
    }
    if (
      props.brief.paymentPreference.colleague_email &&
      props.brief.paymentPreference.colleague_email !== ''
    ) {
      colleague_email = await decryptSmartBriefString(
        props.brief.id,
        props.brief.paymentPreference.colleague_email,
        props.brief.paymentPreference.key,
        props.brief.paymentPreference.iv,
        ucanStore.getApiBaseUrl(),
        s64,
        msg,
        (message) => {
          console.log(message)
        }
      )
    } */
  }
  // console.log(`brief_budget: ${brief_budget}`)

  const currency = currencyToCOTO(Number(brief_budget), pricingStore.selectedCurrency, true)

  if (balance.value.NEAR < 0.1) {
    await alertDialog.value?.show({
      title: 'Error',
      message: 'You need to have at least 0.1 NEAR in your wallet to accept a submission.',
      okButton: 'OK'
    })
    return
  }

  if (Number(brief_budget) < 0) {
    await alertDialog.value?.show({
      title: 'Error',
      message: 'The brief budget does not allow to accept this submission.',
      okButton: 'OK'
    })
    return
  }

  if (balance.value.USDC < Number(brief_budget)) {
    await alertDialog.value?.show({
      title: 'Error',
      message: 'You do not have enough USDC to accept this submission.',
      okButton: 'OK'
    })
    return
  }

  let message = `By proceeding, a payment of ${currency} (${brief_budget} USDC) will be taken from your account and paid to the creator.`
  if (props.brief.paymentPreference) {
    const paymentOptionDescriptions: Record<string, string> = {
      add20: 'Add 15% to my existing budget',
      add15: 'Add 15% to my existing budget'
    }
    if (props.brief.paymentPreference.payment_options !== null) {
      const paymentOptionText =
        paymentOptionDescriptions[props.brief.paymentPreference.payment_options]
      if (paymentOptionText) {
        message += ` Payment option: ${paymentOptionText}.`
      }
    }
    if (coupon_code && coupon_code !== null) {
      message += ` Coupon code applied: ${coupon_code}.`
    }
    if (props.brief.paymentPreference.number_of_creators !== null) {
      message += ` Number of creators: ${props.brief.paymentPreference.number_of_creators}.`
    }
    if (payment_to_creator !== null) {
      message += ` Payment to each creator: $${payment_to_creator}.`
    }
  }
  const ok = await confirmDialog.value?.show({
    title: 'Do you want to proceed?',
    message: message,
    okButton: 'Yes',
    cancelButton: 'No'
  })
  if (!ok) return

  console.log('doSubmissionAccept', item)
  isLoading.value = true

  // calling contract for nft-mint and usdc transfer
  // get profile data including contents and messages to be written to blockchain
  const candidateProfileData = props?.brief?.candidateProfiles?.find(
    (pr) => pr.userId === item.owner
  )
  if (candidateProfileData) {
    candidateProfileData.contentStatus = 'accepted'
    candidateProfileData.amountPaid = brief_budget
    candidateProfileData.paymentToCreator = payment_to_creator
    candidateProfileData.contentAcceptedAt = format(new Date(), 'yyyy-MM-dd HH:mm:ss.SSS')
  }
  const ipfsResult = await pinDataToIPFS(JSON.stringify(candidateProfileData))
  const cidString = ipfsResult?.cid ? ipfsResult.cid.toString() : ''
  // Loop through each content and concatenate the data
  let aggregatedData: {
    contentId: string
    contentFileUrl: string
  }[] = []
  item.contents.forEach((content) => {
    aggregatedData.push({
      contentId: content.id,
      contentFileUrl: content.file_url
    })
  })
  const aggregatedDataString = JSON.stringify(aggregatedData)
  console.log(aggregatedDataString)
  let contentOwner = item.contents[0]?.owner || ''
  const result = await nearWalletStore.smartBriefNftMint(
    props.brief.id,
    props.brief.owner,
    props.brief.title,
    Number(brief_budget),
    contentOwner,
    aggregatedDataString,
    cidString,
    coupon_code
  )
  if (result === API_RESULT_NO_NEAR) {
    isLoading.value = false
    await confirmDialog.value?.show({
      title: 'Error',
      message: 'You need to have at least 0.1 NEAR in your wallet to perform this action.',
      okButton: 'OK'
    })
    return
  }

  // calling api to update candidate profile status to db
  const dataDB: {
    briefId: string
    userId: string
    status: string
    [key: string]: unknown
  } = {
    briefId: props.brief.id,
    userId: item.owner,
    status: 'accepted',
    amountPaid: brief_budget,
    paymentToCreator: payment_to_creator
  }
  await updateSmartBriefProfileStatus(ucanStore, dataDB)

  // sending notification
  await ucanStore.notifyUsingBriefCandidateProfileUCAN(
    props.brief.owner,
    item?.owner,
    props.brief,
    {
      workflowId: 'smartbrief-content-accepted',
      payload: {
        briefName: props.brief.title,
        briefLink: baseUrl() + '/briefs/' + props.brief.id + '/submissions',
        brand: props?.brief.brandName,
        licencedContentLink: baseUrl() + 'briefs/' + props?.brief.id + '/licencedcontent'
      }
    }
  )

  try {
    // Get the Creator's Profile
    const creatorProfile = (await ucanStore.fetchProfileMemoized(item.owner))?.data
    const creatorProfileName =
      creatorProfile.name || creatorProfile.firstName || creatorProfile.firstname

    // To Brand: Creator Has Been Accepted
    await ucanStore.notifyUsingSelfSignedUCAN(accountId.value, {
      workflowId: 'to-brand-creator-has-been-accepted',
      payload: {
        creatorId: creatorProfile?.owner || item?.owner,
        creatorName: creatorProfileName,
        briefLink: baseUrl() + '/briefs/' + props.brief.id
      }
    })
  } catch (err) {
    //
  }

  isLoading.value = false
  location.reload()
}

const showBody = (show: boolean, ownerId: string) => {
  showDetails.value[ownerId] = show
}

let profilesFetched = false
watchEffect(async () => {
  if (props.brief.candidateProfiles) {
    // only show profiles that have status as approved
    profiles.value = props.brief.candidateProfiles.filter(
      (profile) => profile.status === 'approved'
    )
  }
  if (props.brief.candidateProfiles && !profilesFetched) {
    profilesFetched = true
    try {
      const fetchedProfiles = await getAllProfiles(props.brief.candidateProfiles)
      profileNames.value = fetchedProfiles
    } catch (error) {
      console.error('Failed to fetch profiles:', error)
    } finally {
      isProfilesLoading.value = false
    }
  }
})

watchEffect(() => {
  groupedItems.value.forEach((item) => {
    if (showDetails.value[item.owner] === undefined) {
      showDetails.value[item.owner] = true // Set default to true
    }
  })
})

const isActive = ref()
const setActive = (id: any) => {
  console.log('setActive', id)
  isActive.value = id
}
</script>

<template>
  <div class="bg-white w-full rounded-md py-3 px-6 mb-9 border-2 border-[#5386EA]">
    <div class="w-full max-w-7xl flex flex-col mx-auto">
      <h3 class="text-lg font-bold tracking-wide my-3" style="text-align: left">
        Submissions for this Brief
      </h3>
      <p class="my-3">
        Below you will see the submissions to this brief from different creators. You have the
        option to either accept or feedback on their submissions. If you provide Feedback, their
        status will change to pending. They will also be marked as Pending if they have not yet
        submitted content.
      </p>
    </div>
  </div>

  <!--
  <pre class="text-xs" v-if="groupedItems && groupedItems?.contents">
    Submissions {{ groupedItems.contents?.length }}
  </pre>
  -->

  <Vue3Lottie v-if="props.isBriefLoading" :animationData="busy" :height="50" />
  <div
    v-for="(item, index) in groupedItems"
    :key="item.owner"
    class="bg-white w-full rounded-md md:px-8 pt-6 pb-8 mt-9 mb-6 border-[#EAECF0]"
  >
    <div class="flex flex-row flex-wrap">
      <div class="w-full">
        <BriefProfile
          version="submission"
          :showAccept="false"
          :brief="brief"
          :accountId="item.owner"
          :index="index + 1"
          :do-submission-accept="async () => await doSubmissionAccept(item)"
          :on-candidate-profile-status-event="onCandidateProfileStatusEvent"
          :isLoading="isLoading"
          :isClosedBrief="isClosedBrief"
          @show-body="(show: any) => showBody(show, item.owner)"
        />
      </div>
      <div v-for="(media, index) in item.contents" :key="index" class="w-full">
        <div v-if="showDetails[item.owner]" class="w-full md:flex md:flex-row">
          <div class="md:w-1/4 py-4 mb-6">
            <MediaItemTileSubmission
              @videoPlaying="setActive"
              :brief="brief"
              :item="media"
              :links="true"
              :isActive="isActive"
              :imageCss="['md:max-w-[300px]']"
            />
          </div>
          <div class="md:w-3/4 md:ml-8">
            <div class="w-full py-3 mx-1">
              <div
                v-if="
                  !isProfilesLoading &&
                  (brief?.owner === accountId ||
                    teamStore.can('team.briefs.creators.message') ||
                    media.owner === accountId)
                "
              >
                <p class="font-bold">Provide feedback?</p>
                <p class="mb-8">
                  Would you like to provide feedback on this content before accepting it?
                </p>

                <BriefDetailsQA
                  :brief="props.brief"
                  :brief_id="props.brief.id"
                  :reference_id="media.id"
                  :reference_type="'content'"
                  :called_from="'submissions'"
                  :dm_candidate_id="media.owner"
                  :initial-messages="media.feedbacks"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-full"
        v-if="teamStore.can('team.briefs.creators.approve') || props?.brief?.owner === accountId"
      >
        <BriefProfile
          v-if="item.contents && item.contents.length > 0"
          version="submission"
          :showAccept="true"
          :brief="brief"
          :accountId="item.owner"
          :index="index + 1"
          :do-submission-accept="async () => await doSubmissionAccept(item)"
          :on-candidate-profile-status-event="onCandidateProfileStatusEvent"
          :isLoading="isLoading"
          :isClosedBrief="isClosedBrief"
          @show-body="(show: any) => showBody(show, item.owner)"
        />
      </div>
    </div>
  </div>
  <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
  <AlertDialog ref="alertDialog"></AlertDialog>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted, watchEffect } from 'vue'
import { isSet } from '@/utils/mediaDecrypt'
import UploadUppy from '@upload/UploadUppy.vue'

const emit = defineEmits(['onUploaded', 'onCancel'])

const props = withDefaults(
  defineProps<{
    message: string
    show?: boolean
    multiSelect?: boolean
    uploadDocumentsOnly?: boolean
    uploadImagesOnly?: boolean
    uploadMediaOnly?: boolean
    uploadField?: string
    uploadValue?: string
    uploadAccountId?: string // temporary account ID if we are not logged in
    uploadType?: string
    uploadBriefOwnerId?: string
  }>(),
  {
    message: 'Please provide a message attribute',
    show: false,
    multiSelect: false, // return array of files
    uploadDocumentsOnly: false,
    uploadImagesOnly: false,
    uploadMediaOnly: false,
    uploadField: '',
    uploadValue: '',
    uploadAccountId: '',
    uploadType: '',
    uploadBriefOwnerId: ''
  }
)

// these can change
const localUploadValue = ref(props.uploadValue)
const localUploadAccountId = ref(props.uploadAccountId)

const show = ref(props.show)
const showViaButton = ref(show.value)

const fadeIn = ref(false)
const cancelled = ref(false)

const relabelUploadButton = () => {
  const el = document.querySelector('[data-uppy-acquirer-id="Unsplash"] .uppy-DashboardTab-name')
  // list or icon view, icon needs a line break
  const large = document.querySelector('.uppy-size--md')
  const sep = large ? '<br>' : ' '
  const label = 'Unsplash' + sep + 'image library'
  if (el && el.innerHTML !== label) {
    el.innerHTML = label
  }
  const el2 = document.querySelector(
    '#uppy-DashboardContent-panel--Unsplash .uppy-ProviderBrowser-footer-buttons .uppy-c-btn-primary'
  )
  if (el2) {
    el2.innerHTML = 'Save'
  }
}

// overwrite default strings
onMounted(() => {
  relabelUploadButton()
  window.relabelInterval = setInterval(relabelUploadButton, 50)
})
onUnmounted(() => {
  clearInterval(window.relabelInterval)
})

watchEffect(() => {
  // allow cancelling upload
  if (cancelled.value) {
    show.value = false
    cancelled.value = false
  } else {
    if (props.uploadField) {
      if (isSet(props.uploadValue)) {
        show.value = true
        localUploadValue.value = props.uploadValue
        localUploadAccountId.value = props.uploadAccountId
      } else {
        show.value = false
      }
    }
  }

  // upload button triggers show uploader
  if (showViaButton.value) {
    show.value = true
    showViaButton.value = false
  }

  if (show.value) {
    setTimeout(() => {
      fadeIn.value = true
    }, 50)
  } else {
    fadeIn.value = false
  }
})

const cancel = () => {
  cancelled.value = true
  localUploadValue.value = ''
  if (props.uploadField) {
    emit(
      'onUploaded',
      props.uploadField,
      JSON.stringify({
        name: '',
        description: '',
        tags: [],
        file_url: '',
        ipfs_file_url: '',
        ipfs_watermark_file_url: '',
        ipfs_media_type: ''
      })
    )
  }
}

const setShow = () => {
  showViaButton.value = true
}
const toggleShow = () => {
  showViaButton.value = false
  show.value = !show.value
}

const debug = ref(false)
</script>

<template>
  <div v-if="debug" class="bg-green-200 p-2 rounded-md w-full">
    <pre class="text-xs"><b>TOGGLECONTENT:</b> show [{{ show }}] </pre>
    <pre class="text-xs">props [{{ props }}] </pre>
    <pre class="text-xs">show [{{ show }}] </pre>
    <pre class="text-xs">showViaButton [{{ showViaButton }}] </pre>
    <pre class="text-xs">cancelled [{{ cancelled }}] </pre>
    <pre class="text-xs">localUploadValue [{{ localUploadValue }}] </pre>
  </div>

  <div class="w-full">
    <div v-if="uploadField">
      <h2 v-if="show" class="font-bold m-0 pb-2">{{ message }}</h2>
      <h3 v-else class="font-bold m-0">{{ message }}</h3>
      <div v-if="!show" class="flex justify-center mt-3">
        <div class="action-button inline w-auto hollow" @click="setShow">Upload file</div>
      </div>
    </div>

    <div
      v-else
      class="my-3 flex justify-between align-bottom border-b-2 group cursor-pointer"
      @click="toggleShow"
    >
      <h3 class="font-bold m-0 cursor-pointer group-hover:text-[#FF008B] group-hover:opacity-80">
        {{ message }}
      </h3>
      <span class="cursor-pointer text-[#FF008B] group-hover:opacity-80">
        <i class="fa-regular" :class="{ 'fa-plus': !show, 'fa-xmark': show }"></i>
      </span>
    </div>

    <!-- Content to be revealed on :show='true' -->
    <div
      v-show="show"
      class="transition-opacity duration-500"
      :class="{
        'opacity-0': !fadeIn,
        'opacity-100': fadeIn
      }"
    >
      <slot />

      <!-- Uploader mode -->
      <div v-show="uploadField">
        <UploadUppy
          :uploadId="uploadField"
          :uploadValue="localUploadValue"
          :uploadAccountId="localUploadAccountId"
          :uploadDocumentsOnly="uploadDocumentsOnly"
          :uploadImagesOnly="uploadImagesOnly"
          :uploadMediaOnly="uploadMediaOnly"
          :multiSelect="multiSelect"
          :uploadType="uploadType"
          :uploadBriefOwnerId="uploadBriefOwnerId"
          @onUploaded="(data) => emit('onUploaded', uploadField, data)"
          @onCancel="cancel"
        />

        <!-- <div v-if="show && localUploadValue" class="flex justify-center mt-3">
          <div class="action-button inline w-auto hollow" @click="cancel">Cancel</div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import CurrencyValue from '@/components/CurrencyValue.vue'
import { useNearWalletStore, API_RESULT_NO_NEAR } from '@/stores/near-wallet'
import { ref, watchEffect, watch } from 'vue'
import Loading from '@/components/Loading.vue'
import { storeToRefs } from 'pinia'
import { pinDataToIPFS } from '@/utils/ipfs'
import AlertDialog from '@/components/AlertDialog.vue'
import { requestSmartBriefPayment } from '@/queries/api'
import { useUcanStore } from '@/stores/ucan'

const ucanStore = useUcanStore()

const props = withDefaults(
  defineProps<{
    paid: boolean
    amount: number
    brief: SmartBrief
    reloadBrief: Function
    showModal: boolean
  }>(),
  {
    paid: false,
    amount: 0,
    reloadBrief: () => {},
    showModal: true
  }
)

const emit = defineEmits(['close'])
const localShowModal = ref(props.showModal)

watch(
  () => props.showModal,
  (newVal) => {
    localShowModal.value = newVal
  }
)

const hidePopup = () => {
  emit('close')
}

const isLoading = ref(false)
const isPaid = ref(false)
const nearWalletStore = useNearWalletStore()
const { accountId } = storeToRefs(nearWalletStore)
const confirmDialog = ref<InstanceType<typeof AlertDialog>>()

const iconClass = 'cursor-pointer z-30 rounded-md p-2 py-1 top-3 bg-gray-200'

watchEffect(() => {
  props.brief?.candidateProfiles?.forEach((candidate) => {
    if (candidate.userId === accountId.value && candidate.contentStatus === 'accepted') {
      isPaid.value = candidate.amountRequested !== null
    }
  })
})
const startPayment = async () => {
  isLoading.value = true
  const amount = props.amount.toString()
  const data = {
    amount: amount,
    brief_id: props.brief.id,
    sender_id: accountId.value
  }
  const ipfsResult = await pinDataToIPFS(JSON.stringify(data))
  const cidString = ipfsResult?.cid ? ipfsResult.cid.toString() : ''
  const result = await nearWalletStore.requestSmartBriefPayment(cidString, amount)
  if (result === API_RESULT_NO_NEAR) {
    isLoading.value = false
    await confirmDialog.value?.show({
      title: 'Error',
      message: 'You need to have at least 0.1 NEAR in your wallet to perform this action.',
      okButton: 'OK'
    })
    return
  }
  // calling api to update candidate profile status to db
  await requestSmartBriefPayment(ucanStore, props.brief.id, amount)

  // To Creator: Processing of Payment
  await ucanStore.notifyUsingBriefCandidateProfileUCAN(
    props.brief.owner,
    accountId.value,
    props.brief,
    {
      workflowId: 'to-creator-payment-is-available',
      payload: {
        briefName: props.brief.title
      }
    }
  )

  // GA4Events: Proceed to Payment
  if (typeof window?.gtag === 'function') {
    window.gtag('brief_proceed_to_payment', {
      event_category: 'brief',
      brief_id: props.brief.id,
      profile_id: accountId.value
    })

    window?.posthog?.capture('brief_proceed_to_payment', {
      category: 'brief',
      brief_id: props.brief.id,
      profile_id: accountId.value
    })
  }

  isLoading.value = false
  location.reload()
}
</script>

<template>
  <AlertDialog ref="confirmDialog"></AlertDialog>
  <div class="flex flex-col items-center justify-center">
    <div class="py-9 px-12 rounded-lg shadow-lg bg-white max-w-2xl text-center relative">
      <div class="absolute top-4 right-4 z-21">
        <div class="click-popup-hide" :class="iconClass" @click="hidePopup" @click.prevent>
          <i class="fa-regular fa-xmark"></i>
        </div>
      </div>

      <div class="my-6">
        <h1 class="text-2xl font-bold">Congratulations, this brief is complete!</h1>
      </div>
      <div class="mt-9" v-if="isPaid">
        <div class="my-6">
          <p class="text-gray-500 text-sm">
            Your payment is on its way! Please check your inbox for emails from Wise on how to
            access the funds. Thank you for using Contented and please come back soon for more
            opportunities.
          </p>
        </div>
      </div>
      <div class="mt-9" v-else>
        <div class="my-6">
          <div class="text-gray-700 text-base">Request payment of:</div>
          <div class="text-xl font-semibold">
            <CurrencyValue :value="props.amount" currency="USD" />
          </div>
        </div>
        <div class="my-9">
          <p class="text-gray-500 text-sm">
            You will receive the email from Wise over the coming days confirming how to access your
            funds. If you don't see the email in your inbox, please check your spam folder.
          </p>
        </div>
        <div class="mt-6">
          <Loading v-if="isLoading" />
          <button
            v-else
            @click="startPayment"
            class="rounded-full bg-[#FF008B] px-8 py-2 hover:bg-[#E2007B] text-white focus:outline-none focus:shadow-outline"
            type="button"
          >
            Proceed
          </button>
        </div>
      </div>
      <div class="mt-6">
        <img src="/images/wise-logo.png" alt="wise logo" class="h-20 mx-auto" />
      </div>
    </div>
  </div>
</template>

import axios from 'axios'

export const getAltTag = (imageUrl: string) => {
  return imageUrl
    .split('.net/')
    ?.pop()
    ?.replace(/-/gi, ' ')
    ?.replace(/\.(jpeg|jpg|gif|png|mov|mp4|mpeg|mpg)/gi, '')
}

export const isMuxUrl = (link: string) => {
  return link.includes('stream.mux.com')
}

export const fetchFileMetadata = async (fileUrl: string) => {
  try {
    const response = await axios.head(fileUrl)
    if (response.status !== 200) {
      return null
    }
    // console.log('fetchFileMetadata', response)
    const vals = response.headers['upload-metadata'].split(',')
    const decodedMeta = <any>{}
    // key value pairs, values are base64 encoded
    for (let i = 0; i < vals.length; i++) {
      let pair = vals[i].split(' ')
      // strip quotes
      decodedMeta[pair[0]] = atob(pair[1])
    }
    decodedMeta.filesize = response.headers['upload-length']
    return decodedMeta
  } catch (error) {
    // console.warn('Error fetching metadata:', error)
    return null
  }
}

export const isElementInView = (element: HTMLElement, horizontally: boolean = false) => {
  if (!element) return false
  const rect = element.getBoundingClientRect()
  // only interested on horizontal scroll
  if (horizontally) {
    return (
      rect.left >= 0 && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

// PDF thumbnail
let pdfjs: any
export const pdfLoader = async (cb?: Function) => {
  // load pdfjs
  if (!pdfjs) {
    const libraryVersion = '4.5.136'
    const libraryBaseUrl = `https://cdn.jsdelivr.net/npm/pdfjs-dist@${libraryVersion}`
    pdfjs = await import(/* @vite-ignore */ `${libraryBaseUrl}/+esm`)
    pdfjs.GlobalWorkerOptions.workerSrc = `${libraryBaseUrl}/build/pdf.worker.mjs`
    if (typeof cb === 'function') {
      cb()
    }
  }
  return pdfjs
}

export const pdfThumbnail = async (canvas_id: string, url: string, width = 300, height = 300) => {
  // load pdfjs
  if (!pdfjs) {
    await pdfLoader()
  }

  // Asynchronous download of PDF
  let loadingTask = pdfjs.getDocument(url)
  loadingTask.promise.then(
    function (pdf: any) {
      // Fetch the first page
      let pageNumber = 1
      pdf.getPage(pageNumber).then(function (page: any) {
        // console.log('pdfThumbnail: page loaded', pageNumber)

        let scale = 1.5
        let viewport = page.getViewport({ scale: scale })

        // console.log('pdfThumbnail: viewing dimensions', viewport)

        // Prepare canvas using PDF page dimensions
        let canvas = document.getElementById(canvas_id) as HTMLCanvasElement

        if (canvas) {
          let context = canvas.getContext('2d')
          canvas.height = viewport.height
          canvas.width = viewport.width

          // Render PDF page into canvas context
          let renderContext = {
            canvasContext: context,
            viewport: viewport
          }
          let renderTask = page.render(renderContext)
          renderTask.promise.then(function () {
            // console.log('pdfThumbnail: page rendered')
          })
        }
      })
    },
    function (reason: string) {
      // PDF loading error
      console.error('pdfThumbnail:', reason)
    }
  )
}

// normalise image or video
export const getMuxId = (link: string) => {
  const url = link?.split('/')?.pop() || ''
  return url.replace('.m3u8', '')
}

export const itemThumb = (mediaType: string, mediaSrc: string) => {
  if (mediaType == 'video') {
    if (!mediaSrc?.match('ipfs') && !mediaSrc?.match('estuary')) {
      const root = mediaSrc.replace('https://stream.mux.com/', '').replace('.m3u8', '')
      return 'https://image.mux.com/' + root + '/thumbnail.png'
    }
  }
  return mediaSrc
}

export const humanFileSize = (bytes: number, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return bytes.toFixed(dp) + ' ' + units[u]
}

let scrolled = false
export const scrollToError = (reset: boolean = false) => {
  if (reset) {
    scrolled = false
  }
  const msgs = document.querySelectorAll('li[data-message-type=validation]')
  if (!scrolled && msgs.length > 0) {
    const y = msgs[0].getBoundingClientRect().top + window.scrollY - window.innerHeight / 2
    scrolled = true
    window.scroll({
      top: y,
      behavior: 'smooth'
    })
  }
}

export const clickMetaSaveButtons = () => {
  // save any metadata fields that they haven't clicked save on
  const els = document.querySelectorAll('.action-save-meta')
  els.forEach((el) => {
    ;(el as HTMLElement).click()
  })
  return els.length
}

// Formkit onsubmit won't fire once there are errors so we need to watch
clearInterval(window.messageInterval)
window.messageInterval = setInterval(() => {
  scrollToError()
}, 1000)

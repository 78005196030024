import gql from 'graphql-tag'

export const CONTENT_DETAILS = gql`
  fragment ContentDetails on Content {
    id
    owner
    name
    description
    media_link
    media_link_original
    media_original
    media_thumbnail
    cid_media_link
    cid_media_link_original
    cid_media_original
    cid_media_thumbnail
    media_type
    restricted
    created_on
    orientation
    quality
    tags
    type
    search_meta
    bid_count
    highest_bid
    pinned_media
    media_original_blob
    iv
  }
`

export const BID_DETAILS = gql`
  fragment BidDetails on Bid {
    id
    owner
    percentage
    value
    date
  }
`

export const BRIEF_DETAILS = gql`
  fragment BriefDetails on MarketingBrief {
    id
    owner
    titles
    campaign_title
    campaign_objective
    created_on
    proposed_price
    proposed_price_currency
    logo
    acceptAIGenerated
    content_usages {
      id
      type
      value
    }
    campaign_formats {
      id
      quality
      media_type
      aspect_ratio
    }
    restrictions
  }
`

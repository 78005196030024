import { defaultConfig } from '@formkit/vue'
import { genesisIcons } from '@formkit/icons'
import { createProPlugin, inputs } from '@formkit/pro'
import { createLocalStoragePlugin } from '@formkit/addons'
import { rootClasses } from './formkit.theme'
import {
  FormKitYouTubeValidation,
  FormKitTikTokValidation,
  FormKitInstagramValidation,
  FormKitTwitterValidation,
  FormKitBehanceValidation,
  FormKitDribbbleValidation,
  FormKitArtStationValidation
} from '@/utils/formkit-social-url-rules'

const pro = createProPlugin('fk-8e5de5d7ae1', inputs)

const autoSave = createLocalStoragePlugin({
  // plugin defaults:
  prefix: 'formkit',
  key: undefined,
  control: undefined,
  maxAge: 3600000 * 24, // 24 hours
  debounce: 200,
  beforeSave: undefined,
  beforeLoad: undefined
})

export default defaultConfig({
  iconLoaderUrl: (iconName: string) => {
    if (iconName.startsWith('fa-brands')) {
      iconName = iconName.replace('fa-brands', '').trim().replace('fa-', '')
      return `https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.5.1/svgs/brands/${iconName}.svg`
    } else {
      iconName = iconName.replace('fa-solid', '').trim().replace('fa-', '')
      return `https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.5.1/svgs/solid/${iconName}.svg`
    }
  },
  plugins: [pro, autoSave],
  icons: { ...genesisIcons },
  config: {
    rootClasses
  },
  rules: {
    FormKitYouTubeValidation,
    FormKitTikTokValidation,
    FormKitInstagramValidation,
    FormKitTwitterValidation,
    FormKitBehanceValidation,
    FormKitDribbbleValidation,
    FormKitArtStationValidation
  },
  messages: {
    en: {
      validation: {
        FormKitYouTubeValidation({ name }) {
          return `Please enter a valid ${name} URL`
        },
        FormKitTikTokValidation({ name }) {
          return `Please enter a valid ${name} URL`
        },
        FormKitInstagramValidation({ name }) {
          return `Please enter a valid ${name} URL`
        },
        FormKitTwitterValidation({ name }) {
          return `Please enter a valid ${name} URL`
        },
        FormKitBehanceValidation({ name }) {
          return `Please enter a valid ${name} URL`
        },
        FormKitDribbbleValidation({ name }) {
          return `Please enter a valid ${name} URL`
        },
        FormKitArtStationValidation({ name }) {
          return `Please enter a valid ${name} URL`
        }
      }
    }
  }
})

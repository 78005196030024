<script lang="ts" setup>
import { ref, reactive, watchEffect } from 'vue'
import { VIDEO_MAX_SIZE, ZIP_MAX_SIZE, IMAGE_MAX_SIZE } from '@/utils/mediaConstants'
import { humanFileSize } from '@/utils/media'
import { useImageCDN } from '@/utils/image'
import { useToast } from 'vue-toastification'

import UploadSpinner from '@upload/UploadSpinner.vue'
import getConfig from '@/config/config'

const emit = defineEmits(['init', 'update', 'uploadComplete'])

const toast = useToast()

const isTestMode = getConfig().TEST_MODE

if (isTestMode) {
  let announced = false
  watchEffect(() => {
    if (props.uploadsComplete == false) {
      announced = false
    }
    if (props.uploadsComplete && !announced) {
      toast('TESTMODE : uploadsComplete', { timeout: 40000 })
      announced = true
    }
  })
}

const props = defineProps({
  useImg: {
    type: Boolean,
    default: false
  },
  loadImg: {
    type: String
  },
  widthFull: {
    type: Boolean,
    default: false
  },
  heightFull: {
    type: Boolean,
    default: false
  },
  dragColour: {
    type: String,
    default: '#FFF'
  },
  multiSelect: {
    type: Boolean,
    default: false
  },
  uploadsComplete: {
    type: Boolean,
    default: false
  },
  formatsSpecified: {
    type: String
  },
  formatsAllowed: {
    type: Array,
    default: () => [
      'application/pdf',
      'image/bmp',
      'image/gif',
      'image/ief',
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/tiff',
      'video/x-flv',
      'video/mp4',
      'application/x-mpegURL',
      'video/avi',
      'video/MP2T',
      'video/3gpp',
      'video/quicktime',
      'video/x-msvideo',
      'video/x-ms-wmv',
      'video/webm',
      'video/ogg',
      'video/mpeg',
      'video/h264',
      'video/h265'
    ]
  }
})

const dragColourValue = ref()

// media
const emptyFile = {
  file: null,
  type: null,
  name: null,
  size: null,
  date: null,
  url: null,
  delete: false,
  isImage: false,
  isVideo: false,
  isFile: false,
  // state
  progressPctRef: ref(0),
  progressHumanRef: ref(''),
  progressHumanTotalRef: ref(''),
  error: ref(false),
  uploading: ref(false),
  uploaded: ref(false)
}

const fileList = reactive<any>([])

// previewImages for non multi file uploads
const previewUrl = ref(),
  previewUrlBg = ref()

const uploadRef = ref()

const isDirty = ref(false)

const isVideo = ref(false)
const videoUrl = ref()
const videoPlayer = ref<InstanceType<typeof HTMLVideoElement>>()
const isVideoPlaying = ref(false)
const isZip = ref(false)

const onVideoClick = () => {
  if (!videoPlayer.value) {
    return
  }

  if (isVideoPlaying.value) {
    videoPlayer.value.pause()
    isVideoPlaying.value = false
  } else {
    videoPlayer.value.play()
    isVideoPlaying.value = true
  }
}

const setMedia = (file: any) => {
  let media = { ...emptyFile }

  if (typeof file !== 'object' && props.loadImg) {
    fileList[0] = media
    previewUrl.value = props.loadImg
    return
  }

  if (typeof file === 'undefined' || typeof file.type === 'undefined') {
    return
  }

  if (props.formatsAllowed.indexOf(file.type) == -1) {
    toast('Sorry - that file type is not supported - ' + file.type)
    fileDelete()
    return
  }

  let limit = IMAGE_MAX_SIZE
  if (file.type.match('video')) {
    limit = VIDEO_MAX_SIZE
  }

  if (file.type.match('zip')) {
    limit = ZIP_MAX_SIZE
    isZip.value = true
  }

  if (isTestMode) {
    // for test coverage
    const sizes = getConfig().TEST_OK_SIZES?.split(',')
    // only allow certain sizes so we don't have to upload 500Mb+ to test
    if (sizes && !sizes.includes(file.size.toString())) {
      limit = 1
      console.warn('UploadFile : ', file.size, 'not in allowed list', sizes)
    }
  }

  if (file.size > limit) {
    toast('Sorry - the limit for that file type is ' + humanFileSize(limit))
    fileDelete()
    return
  }

  media.file = file
  media.type = file.type
  media.name = file.name
  // @ts-ignore
  media.size = humanFileSize(file.size)
  // @ts-ignore
  media.bytes = file.size

  // Mozilla Firefox
  if (typeof file.lastModifiedDate === 'undefined') {
    file.lastModifiedDate = new Date(file.lastModified)
  }
  media.date = file.lastModifiedDate.toLocaleString()

  // show preview if its an image
  if ((media.type || '').match('image')) {
    // @ts-ignore
    media.url = URL.createObjectURL(file)
    media.isImage = true
  } else if ((media.type || '').match('video')) {
    media.isVideo = true
    dragColourValue.value = '#000'
    isVideo.value = true
    videoUrl.value = URL.createObjectURL(file)
  } else {
    media.isFile = true
  }

  // add to list or replace existing
  if (props.multiSelect) {
    fileList.push({ ...media })
  } else {
    previewUrl.value = ''
    previewUrlBg.value = ''
    if (media.isImage) {
      previewUrl.value = media.url
      if (!props.useImg) {
        dragColourValue.value = '#FFF'
        previewUrlBg.value = 'url(' + media.url + ')'
      }
    }
    fileList[0] = media
  }
}

// select via click
const fileSelect = () => {
  uploadRef.value.click()
}
const fileSelected = (event: Event) => {
  if (event.target instanceof HTMLInputElement && event.target.files?.[0]) {
    setMedia(event.target.files[0])
    emit('update', true)
    isDirty.value = true
  }
}
const fileDelete = (index = 0) => {
  isVideoPlaying.value = false
  isVideo.value = false
  videoUrl.value = null
  fileList.splice(index, 1)
  previewUrl.value = ''
  previewUrlBg.value = ''
  uploadRef.value = ''
  dragColourValue.value = '#FFF'
  emit('update', true)
}

// select via drag / drop
const dragEnter = () => {
  dragColourValue.value = '#F3F4F6'
}
const dragLeave = () => {
  if (previewUrl.value) {
    dragColourValue.value = '#000'
  } else {
    dragColourValue.value = '#FFF'
  }
}

const drop = (e: DragEvent) => {
  if (e.dataTransfer?.files?.[0]) {
    for (var i = 0; i < e.dataTransfer.files.length; i++) {
      setMedia(e.dataTransfer.files[i])
    }
    emit('update', true)
  }
}

if (props.loadImg) {
  setMedia(props.loadImg)
}

// Method to reset the component state
const resetUploadComponent = (index = 0) => {
  isVideoPlaying.value = false
  isVideo.value = false
  videoUrl.value = null
  fileList.splice(index, 1)
  previewUrl.value = ''
  previewUrlBg.value = ''
  uploadRef.value = ''
  dragColourValue.value = '#FFF'
  emit('update', true)
}

watchEffect(() => {
  if (props.uploadsComplete) {
    resetUploadComponent()
  }
})

// parent can see this
defineExpose({
  fileList
})

const debug = ref(false)
</script>

<template>
  <pre
    v-if="debug"
    class="text-xs absolute z-20 text-white"
    style="background-color: rgb(0, 0, 0, 0.5)"
    >{{ fileList }}</pre
  >
  <div
    class="relative flex items-center justify-center"
    @dragover.prevent
    @drop.prevent
    :class="{
      'w-full': widthFull,
      'h-full': heightFull
    }"
  >
    <div v-if="!multiSelect && previewUrl">
      <img :src="previewUrl" class="rounded-md max-h-[300px]" />
      <div
        class="click-upload-delete absolute z-10 top-4 right-4 py-1 px-2 rounded-md hover:cursor-pointer"
        @click="fileDelete(0)"
        :style="{
          'background-color': 'rgba(0,0,0,0.25)'
        }"
      >
        <i class="fa-solid !text-white fa-xmark-large"></i>
      </div>
    </div>
    <div
      v-else
      @dragenter="dragEnter"
      @dragleave="dragLeave"
      @drop="drop"
      @click="onVideoClick"
      :class="{
        'border-dashed': !uploadRef,
        'w-full h-full md:flex p-3 md:py-14 bg-center bg-contain bg-no-repeat flex-col items-center justify-center border-2 bg-white hover:bg-gray-100 rounded-md':
          !useImg
      }"
      :style="{
        'background-color': dragColourValue,
        'background-image': previewUrlBg
      }"
    >
      <div v-if="isVideo" class="flex items-center justify-center bg-black text-white">
        <video ref="videoPlayer" class="h-64" v-if="videoUrl">
          <source :src="videoUrl" type="video/mp4" />
        </video>
      </div>
      <img v-if="useImg" :src="previewUrl" class="rounded-md max-h-[300px]" />
      <div v-if="fileList.length == 0" class="text-center hover:cursor-copy" @click="fileSelect">
        <i class="fa-thin fa-2x p-3 text-gray-400 opacity-50 fa-image"></i>
        <div class="p-3 pt-0">
          <p><b class="text-blue-700">Upload a file</b> or drag and drop</p>
          <p v-if="formatsSpecified" class="text-gray-400 text-sm">{{ formatsSpecified }}</p>
          <div v-else>
            <p class="text-gray-400 text-sm mt-2">Documents: PDF up to 20Mb</p>
            <p class="text-gray-400 text-sm">
              Images: BMP, GIF, IEF, JPG, PDF, PNG, TIFF up to 20Mb
            </p>
            <p class="text-gray-400 text-sm">
              Videos: 3GP, AVI, FLV, M3U8, MOV, MP4, TS, WMV up to 500Mb
            </p>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="isZip">
          <b>{{ fileList[0].name }}</b>
          <table class="data-table w-full text-sm">
            <tr>
              <th>Type</th>
              <td>{{ fileList[0].type }}</td>
            </tr>
            <tr>
              <th>Size</th>
              <td>{{ fileList[0].size }}</td>
            </tr>
            <tr>
              <th>Date</th>
              <td>{{ fileList[0].date }}</td>
            </tr>
          </table>
        </div>
        <UploadSpinner v-if="!isVideoPlaying" :media="fileList[0]" />
      </div>
      <input
        :accept="formatsAllowed.join(',')"
        class="click-upload-select"
        ref="uploadRef"
        type="file"
        name="upload"
        @change="fileSelected"
        :class="{
          hidden: !isTestMode
        }"
      />
    </div>
  </div>

  <div v-if="multiSelect" class="my-3 grid grid-cols-3 gap-3">
    <div
      class="h-28 relative rounded-md shadow-md flex items-center justify-center"
      v-for="(media, index) in fileList"
      :key="index"
      :class="{
        'bg-black bg-center bg-contain bg-no-repeat': media.isImage,
        'bg-gray-200': !media.isImage
      }"
      :style="{ 'background-image': 'url(' + useImageCDN(media.url) + ')' }"
      @mouseenter="media.delete = 1"
      @mouseleave="media.delete = 0"
    >
      <UploadSpinner :media="media" @delete="fileDelete(index)" />
    </div>
  </div>

  <span class="click-upload-delete hidden" @click="fileDelete(0)">&nbsp;</span>
</template>

import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import useLazyPiniaStore from '@/utils/lazyPiniaStore'
import { getSmartBriefs, getSmartBrief, getTeamBriefs } from '@/queries/api'
import { type FetchSmartBriefsParams } from '@/queries/api'
import { useDebounceFn } from '@vueuse/core'
import { keyBy } from 'lodash'

export const useBriefStore = defineStore('briefs', () => {
  const defaultDebounceTime = 25

  const briefsGroupedByOption = ref<Record<string, Record<string, SmartBrief[]>>>({})
  const briefs = ref<SmartBrief[]>([])
  const briefActive = ref<SmartBrief>()

  const briefsById = computed(() => keyBy(briefs.value, 'id'))
  const briefsBySlug = computed(() => keyBy(briefs.value, 'slug'))

  const fetchBriefs = useDebounceFn(
    async (
      params: FetchSmartBriefsParams & {
        teamNickname?: string
      } = {
        option: 'live'
      }
    ) => {
      const teamNickname = params.teamNickname || 'none'

      briefs.value = briefsGroupedByOption.value?.[teamNickname]?.[params.option] || []

      const result =
        teamNickname === 'none'
          ? await getSmartBriefs({
              option: params.option
            })
          : await getTeamBriefs('nickname', teamNickname, {
              option: params.option
            })

      briefsGroupedByOption.value[teamNickname] = briefsGroupedByOption.value[teamNickname] || {}
      briefsGroupedByOption.value[teamNickname][params.option] = result || []

      briefs.value = result

      return result
    },
    defaultDebounceTime
  )

  const switchActiveBrief = (id: string) => {
    briefActive.value = briefsById.value?.[id] || briefsBySlug.value?.[id]

    return briefActive.value
  }

  const fetchBrief = useDebounceFn(async (id: string, accountId: string) => {
    // fetch from the local store if available, temporarily
    switchActiveBrief(id)

    // fetch from the server
    const result = await getSmartBrief(id, accountId)

    // update the local store of briefs
    briefs.value = briefs.value.map((b) => (b.id === id ? result || b : b))

    // update the active brief
    briefActive.value = result

    // send back what we have
    return result
  }, defaultDebounceTime)

  return {
    briefs,
    briefsById,
    briefActive,
    fetchBrief,
    fetchBriefs,
    switchActiveBrief
  }
})

export type BriefsStoreType = ReturnType<typeof useBriefStore>

export const lazyBriefsStore = useLazyPiniaStore<BriefsStoreType>(useBriefStore) as BriefsStoreType

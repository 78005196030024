<script lang="ts">
const linkScript = (href?: string) => {
  if (!href) {
    return
  }

  let subdomain = 'fonts'
  if ((window.location.hostname || '').endsWith('contentedworld.com')) {
    //
  } else {
    subdomain = 'fonts-origin'
  }

  const linkId =
    'fonts-self-hosted-' +
    href.replace(/\//g, '-').replace(/\./g, '-').replace(/^-/, '').replace(/-$/, '')

  if (document.getElementById(linkId)) {
    return
  }

  const link = document.createElement('link')
  link.id = linkId
  link.rel = 'stylesheet'
  link.href = window.location.protocol + '//' + subdomain + '.contentedworld.com' + href
  document.head.appendChild(link)
}

export default {
  props: {
    href: String
  },
  setup(props, ctx) {
    linkScript(props.href)

    return () => ctx.slots.default?.()
  }
}
</script>

<script lang="ts" setup>
import { onBeforeMount, ref, watchEffect } from 'vue'
import { useHead } from '@unhead/vue'
import { route } from '@/utils/route'
import { useNearWalletStore } from '@/stores/near-wallet'
import { storeToRefs } from 'pinia'
import { useUcanStore } from '@/stores/ucan'
import { getMySmartBriefs } from '@/queries/api'
import SmartBriefCard from '@briefs/SmartBriefCard.vue'
import SpinnerOverlay from '@/components/SpinnerOverlay.vue'
import LoginRequired from '@/components/LoginRequired.vue'

const ucanStore = useUcanStore()
const nearWalletStore = useNearWalletStore()
const { accountId } = storeToRefs(nearWalletStore)

const loading = ref<boolean>(false)
const contents = ref<SmartBrief[]>([])
const selectedOption = ref('live')

useHead({
  title: 'My briefs | Contented'
})

const fetchMyBriefs = async (option: string) => {
  if (!accountId.value) return

  loading.value = true

  const myBriefs: SmartBrief[] = await getMySmartBriefs('created', option)
  const myBriefsApplied: SmartBrief[] = await getMySmartBriefs('applied', option)
  const combinedBriefs = [...myBriefs, ...myBriefsApplied]
  contents.value = combinedBriefs

  loading.value = false
}

const handleChange = () => {
  fetchMyBriefs(selectedOption.value)
}

watchEffect(() => {
  fetchMyBriefs('live')
})
</script>
<template>
  <LoginRequired message="Please login to see My Briefs" class="w-full mb-3">
    <SpinnerOverlay v-if="loading" />
    <div v-else>
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-bold">Briefs</h2>
        <select v-model="selectedOption" @change="handleChange" class="form-select">
          <option value="live">Live</option>
          <option value="archived">Archived</option>
        </select>
      </div>
      <ul v-if="contents?.length" class="flex flex-wrap -ml-3 -mr-3">
        <li v-for="brief in contents" :key="brief.id" class="w-full sm:w-1/2 md:w-1/4">
          <div class="mx-3 my-3">
            <SmartBriefCard
              :brief="brief"
              :href="
                route({
                  name: 'briefs.briefdetails',
                  params: {
                    brief_id: brief?.slug || brief.id
                  }
                })
              "
            />
          </div>
        </li>
      </ul>
      <div v-else class="w-full">
        <p class="text-sm">You do not have any briefs yet.</p>
      </div>
    </div>
  </LoginRequired>
</template>

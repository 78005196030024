<script lang="ts" setup>
import { Vue3Lottie } from 'vue3-lottie'
import Vue3LottieBusy from '@/lottie/busy.json'

const props = defineProps({
  opacity: {
    type: Number,
    default: 10
  }
})
</script>

<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 h-full w-full"
    style="z-index: 100"
    :style="{
      background: 'rgba(0,0,0,' + props.opacity / 100 + ')'
    }"
  ></div>
  <div class="fixed top-0 bottom-0 left-0 right-0 h-full w-full" style="z-index: 1001">
    <div class="h-full w-full flex items-center justify-center">
      <div>
        <Vue3Lottie :animationData="Vue3LottieBusy" :height="200" :width="200" />
      </div>
    </div>
  </div>
</template>

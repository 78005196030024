<script lang="ts" setup>
import { ref } from 'vue'
import { parseISO, formatDuration, intervalToDuration } from 'date-fns'

const props = withDefaults(
  defineProps<{
    time?: string | number
    dateTime?: Date
    endedText?: string
  }>(),
  {
    endedText: 'Expired'
  }
)

const parsedExpiresAt = props.time ? parseISO(`${props.time}`) : props.dateTime

const formattedDuration = ref(props.endedText ?? 'Expired')

const getFormattedDuration = () => {
  const delimiter = ', '
  const timeNow = new Date()
  if (parsedExpiresAt && parsedExpiresAt > timeNow) {
    // get the duration
    const duration = intervalToDuration({
      start: timeNow,
      end: parsedExpiresAt
    })

    // format the duration
    let fd = formatDuration(duration, {
      delimiter: delimiter,
      format: ['days', 'hours', 'minutes', 'seconds']
    })

    // contract units of time
    fd = fd.replace('seconds', 's')
    fd = fd.replace('second', 's')
    fd = fd.replace('minutes', 'm')
    fd = fd.replace('minute', 'm')
    fd = fd.replace('hours', 'h')
    fd = fd.replace('hour', 'h')
    fd = fd.replace('days', 'd')
    fd = fd.replace('day', 'd')

    // only keep the first 3
    fd = fd.split(delimiter).splice(0, 3).join(delimiter)

    // update value
    formattedDuration.value = fd

    // run on a timer
    setTimeout(() => {
      getFormattedDuration()
    }, 1000)
  }
}

getFormattedDuration()
</script>

<template>
  <span class="text-xs">
    {{ formattedDuration }}
  </span>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { type FormKitNode } from '@formkit/core'
import { checkTeamNicknameIsAvailable, createTeam, updateTeam } from '@/queries/api'
import BriefsLayout from '@/Layouts/BriefsLayout.vue'
import Auth from '@/components/Pages/Creatives/Wizard/Shared/Auth.vue'

const props = withDefaults(
  defineProps<{
    showTitle?: boolean
    variant?: 'standalone' | 'embedded'
    intent?: 'create' | 'update'
    team?: Team
  }>(),
  {
    showTitle: true,
    variant: 'standalone',
    intent: 'create'
  }
)

const $router = useRouter()

const pageTitle = ref<string>(
  props.intent === 'create' ? 'Create Team' : `Update ${props.team?.name || ''}`
)

const form = ref<Pick<Team, 'name' | 'nickname' | 'logo'>>(
  props.team || { name: '', nickname: '', logo: '' }
)

const formNickname = computed({
  get: () => form.value.nickname,
  set: (value: string) => {
    form.value.nickname = value.toLowerCase()
  }
})

const customValidationRules = {
  checkNickname: async (node: FormKitNode): Promise<boolean> => {
    return new Promise((resolve) => {
      checkTeamNicknameIsAvailable(String(node.value), props.team?.uuid)
        .then(() => {
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
    })
  }
}

const submit = async (data: Team) => {
  try {
    let team
    if (props.intent === 'create') {
      team = await createTeam(data)
    } else if (props.intent === 'update') {
      team = await updateTeam('uuid', props.team!.uuid, data)
    }

    if (team && team.nickname) {
      $router.push({
        name: 'teams.show',
        params: {
          nickname: team.nickname,
          view: 'manage'
        }
      })
    }
  } catch (err) {
    console.log('Error', (err as any).message || 'Something went wrong, please try again.')
  }
}
</script>

<template>
  <component
    :is="props.intent === 'create' ? BriefsLayout : 'div'"
    :title="pageTitle"
    selectedLinkId=""
  >
    <Auth :title="pageTitle" :description="` `">
      <div
        :class="{
          'mx-auto max-w-md h-100': props?.variant === 'standalone'
        }"
      >
        <div
          v-if="props?.showTitle"
          :class="{
            'flex items-center justify-start': props?.variant === 'standalone',
            'flex items-center justify-between': props?.variant === 'embedded'
          }"
        >
          <component
            :is="props?.variant === 'embedded' ? 'h2' : 'h1'"
            :class="{
              'font-bold tracking-wide text-3xl': props?.variant === 'standalone',
              'font-medium tracking-wide text-xl': props?.variant === 'embedded'
            }"
          >
            {{ pageTitle }}
          </component>
        </div>
        <div
          class="teams-form"
          :class="{
            'max-w-lg mx-auto': props?.variant === 'standalone'
          }"
        >
          <FormKit type="form" :actions="false" @submit="submit">
            <FormKit
              type="text"
              name="name"
              label="Name"
              placeholder="Team Name"
              validation="required|length:3"
              outer-class="py-1.5"
              v-model="form.name"
            />
            <FormKit
              type="text"
              name="nickname"
              label="Nickname"
              placeholder="Team Nickname"
              help="This is will be part of your teams URL."
              validation="required|alphanumeric|length:5,20|checkNickname"
              :validation-rules="customValidationRules"
              :validation-messages="{ checkNickname: 'Nickname is unavilable' }"
              outer-class="py-1.5"
              v-model="formNickname"
            />
            <div class="flex item-center justify-between">
              <FormKit
                type="submit"
                label="Save"
                input-class="rounded-full px-6 py-2 text-sm font-bold"
                outer-class="text-center"
              />
              <div class="flex items-center">
                <router-link
                  :to="
                    props.intent === 'create'
                      ? { name: 'teams.index' }
                      : {
                          name: 'teams.show',
                          params: {
                            nickname: props.team?.nickname,
                            view: 'manage'
                          }
                        }
                  "
                >
                  <button
                    type="button"
                    class="text-sm hover:opacity-80"
                    v-if="props?.variant === 'standalone'"
                  >
                    Cancel
                  </button>
                </router-link>
              </div>
            </div>
          </FormKit>
        </div>
      </div>
    </Auth>
  </component>
</template>

<style lang="scss">
.teams-form {
  .formkit-form {
    .formkit-outer {
      @apply my-3;
      .formkit-label {
        @apply text-sm tracking-wide;
      }
      .formkit-inner {
        @apply shadow-none border-gray-300;
        .formkit-input {
          @apply rounded-md text-sm border-gray-300 my-1 placeholder-gray-300;
        }
      }
      .formkit-messages {
        .formkit-message {
          @apply mt-1 text-sm text-red-600;
        }
      }
    }
  }
}
</style>

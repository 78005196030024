import { miappiCdnUrl, videoCdnUrl } from '@/utils/mediaConstants'

// return the url of the file
export const getFileUrl = (file: any) => {
  return file?.hls || file?.cdn_file_url || file?.file_url || file?.image
}

export const getFileName = (file: any) => {
  // accept mime types, or a file object
  if (typeof file === 'string') {
    if (file.match('/')) {
      return file.split('/').pop()
    }
    file = file.replace('/', '.')
    return file
  }
  return file?.filename || getFileUrl(file)
}

export const getFileIcon = (extension: any) => {
  if (typeof extension != 'string') {
    return 'fa-file'
  }

  // handle extension or mime time
  extension = extension.toLowerCase()
  if (typeof extension == 'string' && extension && extension.match('/')) {
    extension = extension.split('/').pop()
  }

  let fileIcon = 'fa-light '

  if (isImage(extension)) {
    fileIcon += 'fa-image'
  }

  // files handled by the fontawesome
  if (['xls', 'xlsx', 'numbers'].includes(extension)) {
    fileIcon += 'fa-file-spreadsheet'
  }
  if (['doc', 'docx', 'pages'].includes(extension)) {
    fileIcon += 'fa-file-doc'
  }
  if (['ppt', 'pptx', 'key'].includes(extension)) {
    fileIcon += 'fa-file-powerpoint'
  }
  if (extension === 'pdf') {
    fileIcon += 'fa-file-pdf'
  }
  if (['txt', 'text', 'rtf'].includes(extension)) {
    fileIcon += 'fa-file-lines'
  }

  // default to file
  if (fileIcon == 'fa-light ') {
    fileIcon += 'fa-file'
  }

  return fileIcon
}

export const checkFormat = (list: any) => {
  let numFiles = 0,
    numImages = 0,
    numVideos = 0
  if (list.length) {
    // sort if it has createdAt
    if (typeof list[0].createdAt !== 'undefined') {
      list.sort((a: any, b: any) => b?.createdAt - a?.createdAt)
    }

    list.forEach((entry: any) => {
      const url = getFileName(entry)
      // count types
      if (isFile(url)) {
        numFiles++
      }
      if (isImage(url) || isPDF(url)) {
        numImages++
      }
      if (isVideo(url)) {
        numVideos++
      }
    })
  }
  return { list, numFiles, numImages, numVideos }
}

export const parseUrl = (url: string): URL | undefined => {
  try {
    return url ? new URL(url) : undefined
  } catch (err) {
    return undefined
  }
}

export function getFileNameAndExtension(filename: string) {
  const parsedUrl = parseUrl(filename)
  const parts = (parsedUrl?.pathname || filename).split('.')
  const extension = parts.pop()?.toLowerCase()
  const name = parts.join('.')
  return { name, extension }
}

export const extensionMatches = (url_or_extension: string, allowedExtensions: string[]) => {
  if (!url_or_extension || typeof url_or_extension !== 'string') {
    return false
  }
  let img = url_or_extension
  if (url_or_extension.match('http')) {
    const extension = getFileNameAndExtension(url_or_extension).extension
    if (extension) {
      img = extension
    }
  }
  // get file extension from url_or_extension if its a filename
  if (img.match('.')) {
    let split = img.split('.').pop()
    if (split) {
      img = split.toLowerCase()
    }
  }
  return allowedExtensions.includes(img)
}

export const getFileType = (fileUrl: string): any => {
  if (typeof fileUrl !== 'string') {
    return
  }

  // we need to do a HEAD request to get the file type as there's no extension
  if (fileUrl.includes('tustemp.contentedworld.com')) {
    return
  }

  const extension = fileUrl.split('.').pop()?.toLowerCase()
  const spreadsheetExtensions = ['xls', 'xlsx', 'numbers']
  const imageExtensions = ['jpg', 'jpeg', 'jfif', 'png', 'gif', 'bmp', 'tiff', 'heic', 'heif']
  const docExtensions = ['doc', 'docx', 'ppt', 'pptx']
  const videoExtensions = ['mp4', 'avi', 'mov', 'mkv', 'flv', 'wmv']

  let returnType = 'file'

  // Check for mediadelivery.net video URL
  if (fileUrl.includes('mediadelivery.net')) {
    returnType = 'video'
  }

  if (extension?.includes('pdf')) {
    returnType = 'pdf'
  } else if (spreadsheetExtensions.includes(extension!)) {
    returnType = 'xls'
  } else if (imageExtensions.includes(extension!)) {
    returnType = 'image'
  } else if (docExtensions.includes(extension!)) {
    returnType = 'doc'
  } else if (videoExtensions.includes(extension!)) {
    returnType = 'video'
  }

  // console.debug('getFileType', fileUrl, returnType)
  return returnType
}

export const isVideo = (url_or_extension: string) => {
  if (url_or_extension == 'video') {
    return true
  }
  let file = getFileUrl(url_or_extension)
  if (!file) {
    file = url_or_extension
  }

  if (
    file === 'application/x-mpegURL' ||
    file?.includes(videoCdnUrl) ||
    file?.includes(miappiCdnUrl) ||
    file?.includes('mediadelivery') ||
    file?.startsWith('video/')
  ) {
    return true
  }
  return extensionMatches(file, [
    'mp4',
    'avi',
    'mov',
    'wmv',
    'video/mp4',
    'video/avi',
    'video/mov',
    'video/wmv',
    'video/quicktime'
  ])
}

export const isImage = (url_or_extension: string) => {
  if (url_or_extension == 'image') {
    return true
  }
  let file = getFileUrl(url_or_extension)
  if (!file) {
    file = url_or_extension
  }
  return extensionMatches(file, [
    'png',
    'jpg',
    'jpeg',
    'jfif',
    'heic',
    'gif',
    'svg',
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/jfif',
    'image/heic',
    'image/gif',
    'image/svg'
  ])
}

export const isPDF = (file: any) => {
  // this may be a file object or a string
  const fileName = getFileName(file)
  if (fileName) {
    return extensionMatches(fileName, ['pdf'])
  }
}

export const isFile = (file: any) => {
  let fileIcon = '',
    fileExtension = ''

  // this may be a file object or a string
  const fileName = getFileName(file)
  if (fileName) {
    // images and videos are not files
    if (isImage(fileName) || isVideo(fileName) || isPDF(fileName)) {
      return false
    }
    if (typeof fileName.split !== 'function') {
      return false
    }
    const split = fileName.split('.').pop()
    if (split) {
      fileExtension = split.toLowerCase()
      fileIcon = getFileIcon(fileExtension)
      if (fileIcon && fileExtension) {
        const res = { fileIcon, fileExtension, fileName }
        return res
      }
    }
  }
}

export const itemSrc = (
  item: {
    media_thumbnail: string
    media_link: string
    image_url: string
    media_link_original: string
    link: string
  },
  imageRes: string
) => {
  if (item.media_thumbnail && imageRes === 'small') {
    return item.media_thumbnail
  }

  if (item.media_link) {
    //grab watermarked first
    return item.media_link
  }

  if (item.image_url) {
    return item.image_url
  } else if (item.media_link) {
    return item.media_link
  } else if (item.media_link_original) {
    return item.media_link_original
  } else if (item.link) {
    return item.link
  } else {
    console.error('itemSrc : no media src supplied', item)
  }
}

export const itemType = (item: { media_type: string; type: string }) => {
  let media_type = ''
  if (item.media_type) {
    if (item.media_type.includes('image/')) {
      return 'image'
    }
    return item.media_type
  } else if (item.type) {
    media_type = item.type
  } else {
    console.error('itemType : no media type supplied', item)
  }
  // support mimetypes image/jpeg
  const match = media_type?.match('/')
  if (match && match.index != -1) {
    media_type = media_type.split('/')?.pop() || ''
  }
  return media_type
}

export const calculateArraySizeInBytes = (arr: any) => {
  let totalBytes = 0
  arr.forEach((item: any) => {
    if (typeof item === 'string') {
      totalBytes += item.length * 2 // 2 bytes per character for strings
    } else if (typeof item === 'number') {
      totalBytes += 8 // 8 bytes for numbers
    } else if (typeof item === 'boolean') {
      totalBytes += 4 // 4 bytes for booleans
    } else if (Array.isArray(item)) {
      totalBytes += calculateArraySizeInBytes(item) // recursive call for nested arrays
    } else if (typeof item === 'object' && item !== null) {
      totalBytes += calculateObjectSizeInBytes(item) // calculate size for objects
    }
  })
  return totalBytes
}

export const calculateObjectSizeInBytes = (obj: any) => {
  let totalBytes = 0
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      totalBytes += key.length * 2 // 2 bytes per character for keys
      const value = obj[key]
      if (typeof value === 'string') {
        totalBytes += value.length * 2 // 2 bytes per character for strings
      } else if (typeof value === 'number') {
        totalBytes += 8 // 8 bytes for numbers
      } else if (typeof value === 'boolean') {
        totalBytes += 4 // 4 bytes for booleans
      } else if (Array.isArray(value)) {
        totalBytes += calculateArraySizeInBytes(value) // recursive call for nested arrays
      } else if (typeof value === 'object' && value !== null) {
        totalBytes += calculateObjectSizeInBytes(value) // recursive call for nested objects
      }
    }
  }
  return totalBytes
}

<script lang="ts" setup>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useUcanStore } from '@/stores/ucan'
import { useNearWalletStore } from '@/stores/near-wallet'
import { fetchBrief, fetchProfile } from '@/queries/api'
import { v4 as uuidv4 } from 'uuid'
import Wrapper from '@/components/Pages/Creatives/Wizard/Shared/Wrapper.vue'
import AuthWrapper from '@/components/Pages/Creatives/Wizard/Shared/Auth.vue'
import Uploaded from '@/components/Pages/Creatives/Wizard/Shared/Uploaded.vue'
import EditForm, {
  type EditFormOnSubmitSuccessData
} from '@/components/Pages/Profiles/EditForm.vue'
import { submitSmartBriefNDA, acceptSmartBriefNDA } from '@/queries/api'

export type BriefApplication = {
  brief_personal_note: string
}

const props = withDefaults(
  defineProps<{
    briefId?: string
    onDone?: () => void | undefined
  }>(),
  {
    briefId: undefined,
    onDone: (): void => {}
  }
)

const $router = useRouter()

const ucanStore = useUcanStore()

const nearWalletStore = useNearWalletStore()

const profile = ref<ProfileData>()

const showProfile = ref(false)

const briefApplication = ref<BriefApplication>({
  brief_personal_note: ''
})

const onSubmitSuccess = (data: EditFormOnSubmitSuccessData) => {
  console.log('onSubmitSuccess', data)
  showProfile.value = false
}

const onProfileSubmit = async (data: BriefApplication) => {
  try {
    // fetch the brief
    const brief = await fetchBrief(props.briefId!)

    // create the ucanToken for the candidate to the owner of the brief
    let ucanTokenRecord
    if (brief.owner) {
      const ucanAudienceDetails = await ucanStore.fetchProfile(brief.owner)
      const ucanIssuerDetails = await ucanStore.fetchProfile(nearWalletStore.accountId)
      if (ucanAudienceDetails?.data && ucanIssuerDetails?.data) {
        const ucanToken = await ucanStore.issueNotificationsUcan({
          audienceDid: ucanAudienceDetails.data.didKey,
          audienceWalletId: ucanAudienceDetails.data.id,
          issuerEmailrsa: ucanAudienceDetails.data.emailrsa
        })
        ucanTokenRecord = {
          id: uuidv4(),
          iss: ucanIssuerDetails.data.didKey,
          aud: ucanAudienceDetails.data.didKey,
          token: ucanToken
        }
      }
    }

    if (props.briefId) {
      // submit the NDA
      await submitSmartBriefNDA(ucanStore, props.briefId)
      // accept the NDA
      await acceptSmartBriefNDA(
        ucanStore,
        props.briefId,
        data.brief_personal_note,
        ucanTokenRecord ? [ucanTokenRecord] : []
      )
    }

    // use a delegated ucan to notify the brief owner that a user has applied to the brief
    // [TEMP]: Should be... ucanStore.notifyUsingDelegatedUCAN
    await ucanStore.notifyUsingBriefCandidateProfileUCAN(
      nearWalletStore.accountId,
      brief.owner,
      brief,
      {
        workflowId: 'to-brand-creator-applied-to-brief',
        payload: {
          briefName: brief.title,
          briefLink:
            window.location.protocol +
            '//' +
            window.location.host +
            $router.resolve({
              name: 'briefs.briefdetails',
              params: {
                brief_id: brief?.slug || brief.id,
                active_tab: 'candidates'
              }
            }).href,
          creatorId: nearWalletStore.accountId
        }
      }
    )

    props?.onDone()
  } catch (error) {
    console.error('An error occurred:', error)
  }
}

watch(
  () => ({
    accountId: nearWalletStore.accountId
  }),
  async (nv, ov) => {
    if (nv.accountId !== ov.accountId) {
      profile.value = await fetchProfile(nv.accountId)
    }
  }
)
</script>

<template>
  <Wrapper>
    <AuthWrapper
      title="Add Content"
      description="Before you add your content information please login or register using a social media account"
    >
      <div class="w-full">
        <div class="w-full text-center">
          <div class="text-[#3F3F46] font-semibold tracking-wide text-lg">
            Thanks for completing your profile.
          </div>
          <div class="my-2 text-[#3F3F46] text-sm">
            <p>
              Please check your details and if you're happy submit your content to the brand for
              review
            </p>
          </div>
        </div>

        <div
          class="w-full my-6"
          :class="{
            'border rounded-md pb-6': showProfile
          }"
        >
          <div
            @click="showProfile = !showProfile"
            class="px-4 py-1 flex flex-row items-center cursor-pointer hover:text-[#FF008B]"
            :class="{
              'border rounded-md': !showProfile
            }"
          >
            <i
              class="fa-solid mr-1.5 text-xs"
              :class="{
                'fa-chevron-right': !showProfile,
                'fa-chevron-down': showProfile
              }"
            ></i>
            <h3 class="text-left">Profile</h3>
          </div>
          <div v-show="showProfile" class="w-full mx-auto">
            <EditForm
              :hide-fields="{
                languages: true
              }"
              :onSubmitSuccess="onSubmitSuccess"
            />
          </div>
        </div>

        <Uploaded />

        <div class="w-full">
          <FormKit type="form" id="profile" ref="form" @submit="onProfileSubmit" :actions="false">
            <div>
              <slot name="brief_application" />

              <div class="profile-form-section">
                <div class="profile-form-section-header">
                  <div>
                    <h2>What makes you the ideal candidate?</h2>
                  </div>
                </div>

                <div class="my-6">
                  <hr />
                </div>

                <div class="w-full flex">
                  <FormKit
                    type="textarea"
                    name="brief_personal_note"
                    id="brief_personal_note"
                    label="Add a personal note for the brand's attention"
                    placeholder="Write a few words releveant to the brief to introduce yourself - a short bio, your content ideas and the reason for your content (is there a running theme/goal/purpose?) and your experience to date."
                    v-model="briefApplication.brief_personal_note"
                    rows="6"
                    spellcheck="true"
                    validation="required|length:10"
                  />
                </div>
              </div>
            </div>
            <div class="w-full">
              <FormKit type="submit" label="Submit Application" input-class="rounded-full" />
            </div>
          </FormKit>
        </div>
      </div>
    </AuthWrapper>
  </Wrapper>
</template>

// because login happens after the uppy instance is created, suppress repeated messages
window.debounceLogTimeouts = []
export const debounceLog = (msg: string, data: any = '') => {
  const key: any = msg.replace(/[^a-z]/gi, '-')
  if (window.debounceLogTimeouts[key]) {
    clearTimeout(window.debounceLogTimeouts[key])
  }
  window.debounceLogTimeouts[key] = setTimeout(() => {
    console.log(msg, data)
  }, 2000)
}

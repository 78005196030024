<script lang="ts" setup>
import { Styles } from '@/styles'

defineProps({
  modelValue: String,
  options: Object
})

const emit = defineEmits(['update'])

const update = (v: Event) => {
  const elSelect = v.target as HTMLSelectElement
  emit('update', {
    key: elSelect.name,
    val: elSelect.value
  })
}
</script>

<template>
  <select
    v-if="modelValue && options"
    :class="[Styles.select, 'w-auto']"
    :name="options.key"
    :value="modelValue"
    @change="update"
  >
    <option v-for="option in options.options" :value="option.id" :key="option.id">
      {{ options.label }}: {{ option.label }}
    </option>
  </select>
</template>

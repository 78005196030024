import CreativesWizard from '@/components/Pages/Creatives/Wizard/Wizard.vue'
import CreativesStartPage from '@/components/Pages/Creatives/CreativesStartPage.vue'

export default [
  {
    path: '/creatives',
    children: [
      {
        path: 'wiz/:briefId?',
        component: CreativesWizard,
        name: 'creatives.wizard',
        props: true
      }
    ]
  },
  {
    path: '/creatives/start',
    component: CreativesStartPage,
    name: 'creatives.start',
    props: true
  }
]

export const VIDEO_MAX_SIZE = 10 * 1024 * 1024 * 1024 // 2GB
export const IMAGE_MAX_SIZE = 20 * 1024 * 1024
export const ZIP_MAX_SIZE = 10 * 1024 * 1024 * 1024

// before you are logged in, stays local until you login FILES ONLY NO VIDEO
export const TUS_ENDPOINT_TEMP = 'https://tustemp.contentedworld.com/files/'

// after you are logged in, goes to bunny
export const TUS_ENDPOINT_VIDEO = 'https://video.bunnycdn.com/tusupload'
export const TUS_ENDPOINT_GENERAL = 'https://tus.contentedworld.com/files/'
// export const TUS_ENDPOINT_GENERAL = 'http://127.0.0.1:8080/files/'

// bunny
export const profileLibraryId = '213102'
export const briefLibraryId = '205103'
export const videoLibraryId = '205103'
export const submissionLibraryId = '209792'
export const miappiLibraryId = '157310'

export const cdnUrl = 'https://vz-4e8d49c5-eb4.b-cdn.net'
export const briefsCdnUrl = 'https://vz-7678a8e1-7e3.b-cdn.net'
export const videoCdnUrl = 'https://vz-45fc0159-3b8.b-cdn.net'
export const profileCdnUrl = 'https://vz-25bae7c4-5ea.b-cdn.net'
export const miappiCdnUrl = 'https://vz-cb44d77f-99b.b-cdn.net'
export const embedUrl = 'https://iframe.mediadelivery.net/embed'

<script lang="ts" setup>
import { ref, watch } from 'vue'
import { useUcanStore } from '@/stores/ucan'
import { CloudflareWorkersApi } from '@/queries/cloudflare-workers'

const isWidgetEnabled = false

const ucanStore = useUcanStore()

const applicationIdentifier = ref('V2HzwiO8K-6-')
const subscriberId = ref<string>()
const subscriberHash = ref<string>()

const updateSubscriberHash = (id: string) => {
  CloudflareWorkersApi()
    .post('/n/subscriber', {
      id: id
    })
    .then(({ data }) => {
      if (data?.signature && isWidgetEnabled) {
        subscriberHash.value = data.signature
        const windowNovu = window.novu
        if (typeof windowNovu !== 'undefined' && !!windowNovu) {
          windowNovu.init(
            applicationIdentifier.value,
            {
              unseenBadgeSelector: '#unseen-badge',
              bellSelector: '#notification-bell',
              colorScheme: 'dark'
            },
            {
              subscriberId: subscriberId.value,
              subscriberHash: subscriberHash.value
            }
          )
        }
      }
    })
}

watch(
  () => ucanStore.data.nearNamedWalletId,
  async (nv, ov) => {
    if (nv && nv !== ov) {
      subscriberId.value = nv
      updateSubscriberHash(nv)
    }
  }
)

if (isWidgetEnabled) {
  ;(function (n: any, o: Window['document'], t: string, i: string, f: string) {
    n[i] = {}
    var m = ['init', 'on']
    n[i]._c = []
    m.forEach(
      (me) =>
        (n[i][me] = function () {
          n[i]._c.push([me, arguments])
        })
    )
    var elt = o.createElement(f) as HTMLScriptElement
    elt.type = 'text/javascript'
    elt.async = true
    elt.src = t
    var before = o.getElementsByTagName(f)[0]
    before.parentNode?.insertBefore(elt, before)
  })(window, document, 'https://embed.novu.co/embed.umd.min.js', 'novu', 'script')
}
</script>

<template>
  <div v-if="subscriberHash" class="pr-3 cursor-pointer">
    <div id="notification-bell">
      <i class="fa fa-bell text-white hover:text-gray-300"></i>
      <span id="unseen-badge"></span>
    </div>
  </div>
</template>

<style scoped>
/*  */
</style>

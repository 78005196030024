<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useMemoize } from '@vueuse/core'
import { useRouter, useRoute } from 'vue-router'
import { mobileDetect } from '@/utils/browser'
import { videoCdnUrl } from '@/utils/mediaConstants'
import { isFile, isVideo } from '@/utils/mediaFileTypes'
import { useImageCDN } from '@/utils/image'

import MediaItem from '@media/MediaItem.vue'
import DocumentList from '@media/DocumentList.vue'

const emit = defineEmits(['onSelect'])

export interface Props {
  contents?: ProfileContent[]
  allowDelete?: boolean
  showSelected?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isOwner: false,
  allowDelete: false,
  showSelected: false
})

const $route = useRoute()
const $router = useRouter()

const itemMap = (item: ProfileContent) => {
  const itemUrl = item.file_url || ''

  const editUrl = $router.resolve({
    name: 'profiles.self.uploads',
    params: {
      uploadId: item.id
    }
  }).href

  let viewUrl = $router.resolve({
    name: 'profiles.portfolio.item',
    params: {
      accountId: $route.params.username,
      uploadId: item.id
    }
  }).href

  if (isVideo(itemUrl)) {
    const hostname = videoCdnUrl
    const urlSegments = itemUrl.split('/')
    const urlVideoId = urlSegments?.slice()?.pop()
    const urlPrefix = `${hostname}/${urlVideoId}/`
    const urlThumbnail = `${urlPrefix}thumbnail.jpg`
    let thumb = useImageCDN(urlThumbnail, 'small', true)
    return {
      id: item.id,
      href: editUrl || viewUrl,
      links: editUrl || viewUrl ? true : false,
      image: thumb,
      file_url: itemUrl,
      hls: `${urlPrefix}playlist.m3u8`,
      name: item.name,
      description: item.short_description,
      tags: item.tags
    }
  }

  // images
  return {
    id: item.id,
    href: editUrl || viewUrl,
    editUrl: editUrl,
    viewUrl: viewUrl,
    links: editUrl || viewUrl ? true : false,
    image: viewUrl,
    file_url: itemUrl,
    name: item.name,
    short_description: item.short_description,
    tags: item.tags
  }
}

const itemMapMemoized = useMemoize(itemMap)
const uuid = uuidv4()

const items = computed(() => {
  // exclude any items that have a file_url containing 'preview.contentedmedia.com'
  const items = (props.contents || [])
    .filter((item) => !item.file_url.includes('preview.contentedmedia.com'))
    //    .filter((item) => !isDeleted(item))
    .map((item) => itemMapMemoized(item))

  // keep a global list so that the popup can go prev/next
  if (typeof window.carousels === 'undefined') {
    window.carousels = {}
  }
  window.carousels[uuid] = items
  return items
})

const pageWidth = ref(0)

const isMobile = ref(mobileDetect())
const updatePageWidth = () => {
  pageWidth.value = parseInt(String(window.innerWidth || document.documentElement.clientWidth))
  isMobile.value = mobileDetect()
}
onMounted(() => {
  updatePageWidth()
  window.addEventListener('resize', updatePageWidth)
})
onUnmounted(() => {
  window.removeEventListener('resize', updatePageWidth)
})

const chunks = computed(() => {
  /*
    tailwind sizes
      'sm': '640px',
      'md': '768px',
      'lg': '1024px',
      'xl': '1280px',
      '2xl': '1536px',
  */
  if (pageWidth.value >= 1536) {
    return 6
  } else if (pageWidth.value >= 1280) {
    return 5
  } else if (pageWidth.value >= 1024) {
    return 4
  } else if (pageWidth.value >= 768) {
    return 3
  } else if (pageWidth.value >= 640) {
    return 2
  } else {
    return 1
  }
})

const onSelect = (item: any, isSelected: boolean) => {
  emit('onSelect', item, isSelected)
}

const chunkedItems = computed(() => {
  let chunkIndex = 0
  return (items.value || [])
    .map((item) => itemMapMemoized(item as any))
    .filter((item) => !isFile(item))
    .reduce((acc, item) => {
      acc[chunkIndex] = acc[chunkIndex] ? acc[chunkIndex] : []
      acc[chunkIndex].push(item)
      chunkIndex++
      chunkIndex = chunkIndex === chunks.value ? 0 : chunkIndex
      return acc
    }, new Array(chunks.value))
})
</script>

<template>
  <div class="flex flex-wrap" :data-carousel-id="uuid">
    <div
      v-for="(chunkedItem, chunkedItemIndex) in chunkedItems"
      class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6"
      :key="chunkedItemIndex"
    >
      <div v-for="item in chunkedItem" class="my-6 sm:m-4" :key="item.id">
        <MediaItem
          :item="item"
          :imageCss="['w-full h-auto']"
          :videoCss="['h-auto']"
          :showSelected="showSelected"
          @onSelect="onSelect"
        />
        <p class="text-xs text-center px-1 pt-1 text-gray-500">{{ item.name }}</p>
      </div>
    </div>
  </div>
  <div class="md:mx-6 md:mt-6">
    <DocumentList :showCount="true" :items="items" />
  </div>
</template>

import { add as addFns, format, parse } from 'date-fns'
import getConfig from '@/config/config'

export const getExpiryDate = (createdDate: string) => {
  return addFns(new Date(parseInt(createdDate)), { days: getConfig().contentExpiryDays as number })
}

export const getBriefExpiryDate = (createdDate: string) => {
  return addFns(new Date(parseInt(createdDate)), { days: getConfig().briefExpiryDays as number })
}

export const isBriefExpired = (createdDate: string) => {
  const today = new Date()
  return today > getBriefExpiryDate(createdDate)
}

export const isContentExpired = (createdDate: string) => {
  const today = new Date()
  return today > getExpiryDate(createdDate)
}

export function convertDateString(input: string) {
  if (!input) return input
  // Parse the input string "YYYY/MM/DD" into a Date object
  const date = parse(input, 'yyyy/MM/dd', new Date())

  // Format the Date object to "DD/MM/YYYY" string
  const output = format(date, 'dd/MM/yyyy')

  return output
}

export function convertDBDate(input: string | undefined) {
  if (!input) return input
  const date = new Date(input);

  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();
  const shortYear = year.toString().slice(-2);

  const formattedDay = day.toString().padStart(2, '0');
  const formattedMonth = month.toString().padStart(2, '0');

  const formattedDate = `${formattedDay}/${formattedMonth}/${shortYear}`;

  return formattedDate
}

<script setup lang="ts">
import { computed, ref } from 'vue'
import CreativesWizardStart from '@/components/Pages/Creatives/Wizard/Start.vue'
import CreativesWizardProfile from '@/components/Pages/Creatives/Wizard/Profile.vue'
import CreativesWizardContent from '@/components/Pages/Creatives/Wizard/Content.vue'
import CreativesWizardSubmit from '@/components/Pages/Creatives/Wizard/Submit.vue'
import CreativesWizardSent from '@/components/Pages/Creatives/Wizard/Sent.vue'
import CreativesWizardProfileComplete from '@/components/Pages/Creatives/Wizard/ProfileComplete.vue'
import Steps from '@/components/Pages/Creatives/Wizard/Shared/Steps.vue'
import PageHeading from '@/components/PageHeading.vue'
import CreativeWelcomePageContentedUserIconSvg from '@/components/Pages/Creatives/CreativeWelcomePageContentedUserIconSvg.vue'
import { onMounted } from 'vue'

const props = withDefaults(
  defineProps<{
    briefId?: string
  }>(),
  {
    briefId: undefined
  }
)

const emit = defineEmits(['stepChanged'])

const currentStep = ref(1)

const wizardSteps = computed((): Record<number, string> => {
  return props?.briefId
    ? {
        1: 'start',
        2: 'profile',
        3: 'content',
        4: 'submit',
        5: 'complete'
      }
    : {
        1: 'profile',
        2: 'content',
        3: 'complete'
      }
})

const wizardStepsCount = computed((): number => {
  return Object.values(wizardSteps.value).length
})

const wizardStepCurrent = computed((): string => {
  return wizardSteps.value[currentStep.value]
})

const trackEventCurrentStage = () => {
  const stepName = wizardStepCurrent.value

  if (typeof window?.gtag === 'function') {
    window.gtag('event', `brief_creator_wizard_stage_${stepName}`, {
      event_category: 'brief',
      brief_id: props.briefId
    })
  }

  window?.posthog?.capture(`brief_creator_wizard_stage_${stepName}`, {
    type: '`brief_creator_wizard',
    stage: stepName,
    brief_id: props.briefId,
    category: 'brief'
  })
}

const changeStep = (step: number): void => {
  currentStep.value = step
  emit('stepChanged', step)
  trackEventCurrentStage()
}

const incrementStep = () => changeStep(currentStep.value + 1)

onMounted(() => {
  trackEventCurrentStage()
})
</script>

<template>
  <div v-if="!briefId" class="w-full pb-6">
    <PageHeading title="Contented">
      <template #loginButton>
        <div class="h-8 w-8 mt-1 mr-1">
          <CreativeWelcomePageContentedUserIconSvg />
        </div>
      </template>
    </PageHeading>
  </div>
  <div class="w-full max-w-3xl mx-auto">
    <div class="w-full" v-if="briefId && wizardStepsCount !== currentStep">
      <Steps
        v-if="briefId"
        class="mx-auto mt-9"
        :selectedStep="currentStep"
        :steps="[
          {
            number: 1,
            label: 'Start',
            onClickStep: () => changeStep(1)
          },
          {
            number: 2,
            label: 'Add Profile',
            onClickStep: () => changeStep(2)
          },
          {
            number: 3,
            label: 'Add Content',
            onClickStep: () => changeStep(3)
          },
          {
            number: 4,
            label: 'Submit',
            onClickStep: () => changeStep(4)
          }
        ]"
      />
    </div>
    <div class="w-full" v-if="!briefId">
      <Steps
        class="mx-auto mt-9"
        :selectedStep="currentStep"
        :steps="[
          {
            number: 1,
            label: 'Profile',
            onClickStep: () => changeStep(1)
          },
          {
            number: 2,
            label: 'Content',
            onClickStep: () => changeStep(2)
          },
          {
            number: 3,
            label: 'Finish',
            onClickStep: () => {}
          }
        ]"
      />
    </div>

    <div class="pt-3">
      <CreativesWizardStart
        v-if="wizardStepCurrent === 'start'"
        :onDone="
          () => {
            incrementStep()
          }
        "
      />

      <CreativesWizardProfile
        v-if="wizardStepCurrent === 'profile'"
        :onDone="
          () => {
            incrementStep()
          }
        "
      />

      <CreativesWizardContent
        v-if="wizardStepCurrent === 'content'"
        :briefId="props.briefId"
        :onDone="
          () => {
            incrementStep()
          }
        "
      />

      <CreativesWizardSubmit
        v-if="wizardStepCurrent === 'submit'"
        :briefId="props.briefId"
        :onDone="
          () => {
            incrementStep()
          }
        "
      />

      <CreativesWizardSent
        v-if="props.briefId && wizardStepCurrent === 'complete'"
        :briefId="props.briefId"
        :onDone="
          () => {
            //
          }
        "
      />

      <CreativesWizardProfileComplete
        v-if="!props.briefId && wizardStepCurrent === 'complete'"
        :onDone="
          () => {
            //
          }
        "
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash'
import { ref } from 'vue'
import { refDebounced } from '@vueuse/core'
import { v4 as uuidv4 } from 'uuid'
import { fetchProfile } from '@/queries/api'
import { useNearWalletStore } from '@/stores/near-wallet'
import { submitProfileSave } from '@/utils/storeProfile.js'

import AuthWrapper from '@/components/Pages/Creatives/Wizard/Shared/Auth.vue'
import Wrapper from '@/components/Pages/Creatives/Wizard/Shared/Wrapper.vue'
import ToggleContent from '@/components/ToggleContent.vue'
import Uploaded from '@/components/Pages/Creatives/Wizard/Shared/Uploaded.vue'

export type ContentPayload = {
  name: string
  description: string
  tags: string
  file_url: string
}

const props = withDefaults(
  defineProps<{
    briefId?: string
    onDone?: () => void | undefined
  }>(),
  {
    onDone: (): void => {}
  }
)

const nearWalletStore = useNearWalletStore()

const iteration = ref<number>(0)
const iterationDebounced = refDebounced<number>(iteration, 500)

const payload = ref<ContentPayload[]>()

const saveFile = (fieldName: string, data: string) => {
  console.log('saveFile : fieldName', fieldName, data)
  const uploads = JSON.parse(data) as ContentPayload[]
  if (uploads?.[0]?.name && uploads?.[0]?.name !== '') {
    payload.value = uploads
    onSubmit()
  }
}

const onSubmit = async () => {
  try {
    // fetch the profile
    const profile = await fetchProfile(nearWalletStore.accountId)

    // create the updated profile
    const updatedProfile = _.cloneDeep(profile)

    // create the entry
    let id
    let updatedContents = profile?.contents?.length ? [...profile.contents] : []

    payload.value?.forEach((item: ContentPayload) => {
      id = uuidv4()
      updatedContents.push({
        id: id,
        name: item.name || id,
        short_description: item.description,
        tags: item?.tags?.split(',').filter((tag) => tag && tag !== '') || [],
        file_url: item.file_url
      })
    })

    // update the profile contents
    updatedProfile.contents = updatedContents

    // save
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const savedProfile = await submitProfileSave(
      nearWalletStore,
      nearWalletStore.accountId,
      updatedProfile
    )

    // redirect user back if we succeeded and we have a return link
    iteration.value = iteration.value + 1
  } catch (error) {
    console.error('An error occurred:', error)
  }
}

const nextStep = (retry: any) => {
  // save any metadata fields that they haven't clicked save on
  let els = document.querySelectorAll('.action-save-meta')
  els.forEach((el) => {
    ;(el as HTMLElement).click()
  })
  if (els.length) {
    console.log('el : meta data missing', els)
    if (!retry) {
      // try to advance if that click has saved the metadata
      setTimeout(() => {
        nextStep(true)
      }, 1000)
      return
    }
  } else {
    setTimeout(() => {
      props?.onDone()
    }, 500)
  }
}
</script>

<template>
  <Wrapper>
    <AuthWrapper
      title="Add Content"
      description="Before you add your content information please login or register using a social media account"
    >
      <div class="w-full">
        <div class="w-full my-3 text-center" v-if="iterationDebounced === 0">
          <button
            type="button"
            class="text-[#FF008B] font-medium tracking-wide hover:underline"
            @click="nextStep"
          >
            <span v-if="props.briefId">Skip to Submit</span>
            <span v-else>Skip</span>
          </button>
          <p class="mt-3 mb-6 text-[#3F3F46] text-sm">
            Please note, you will maximize your chances of being selected by adding content examples
          </p>
        </div>

        <div :key="iterationDebounced">
          <div class="my-6 mb-12 flex flex-col items-center justify-center">
            <ToggleContent
              :show="props.briefId ? false : true"
              :multiSelect="false"
              message="Upload file from your device:"
              uploadField="portfolioItem"
              @onUploaded="saveFile"
            />
          </div>
        </div>

        <div :key="iterationDebounced" v-if="iterationDebounced > 0">
          <Uploaded :showLatest="iterationDebounced" />
        </div>
      </div>

      <div class="clear-both text-center my-10">
        <button type="button" class="action-button solid" @click.prevent="nextStep">Next</button>
      </div>
    </AuthWrapper>
  </Wrapper>
</template>

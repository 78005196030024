function loadImage(originalImage: Blob | MediaSource) {
  return new Promise((resolve, reject) => {
    const displayImage = new Image()
    const src = URL.createObjectURL(originalImage)
    displayImage.addEventListener('load', () => {
      URL.revokeObjectURL(src)
      resolve(displayImage)
    })
    displayImage.addEventListener('error', (err) => reject(err))
    displayImage.src = src
  })
}

export function popupImage(url: string, title: string, desc: string) {
  window.popupImage = url
  window.popupTitle = title
  window.popupDescription = desc
}

async function loadWatermark(watermark: string) {
  const result = await fetch(watermark)
  const blob = await result.blob()
  const image = await createImageBitmap(blob)
  return image
}

function calculateAspectRatioFit(
  srcWidth: number,
  srcHeight: number,
  maxWidth: number,
  maxHeight: number
) {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)
  return { width: srcWidth * ratio, height: srcHeight * ratio }
}

const getAspectRatio = (width: number, height: number) => {
  const divisor = gcd(width, height)

  return width / divisor + ':' + height / divisor
}

const gcd = (width: number, height: number): number => {
  return height ? gcd(height, width % height) : width
}

export function watermarkImage(originalImage: Blob | MediaSource, watermark: string) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    loadImage(originalImage)
      .then((displayImage) => {
        let quality = ''
        const displayImageObject = displayImage as HTMLImageElement
        const PPI =
          Math.sqrt(
            displayImageObject.width * displayImageObject.width +
              displayImageObject.height * displayImageObject.height
          ) / 10
        if (PPI <= 72) {
          quality = '72ppi'
        } else if (PPI <= 200) {
          quality = '200ppi'
        } else if (PPI <= 300) {
          quality = '300ppi'
        } else if (PPI <= 800) {
          quality = '800ppi'
        } else if (PPI > 800) {
          quality = '>800ppi'
        }

        let maxWidth = 1920,
          maxHeight = 1080

        if (maxWidth > displayImageObject.width) maxWidth = displayImageObject.width
        if (maxHeight > displayImageObject.height) maxHeight = displayImageObject.height

        const { width: imageWidth, height: imageHeight } = calculateAspectRatioFit(
          displayImageObject.width,
          displayImageObject.height,
          maxWidth,
          maxHeight
        )

        const aspectRatio = getAspectRatio(imageWidth, imageHeight)

        canvas.width = imageWidth
        canvas.height = imageHeight

        // initializing the canvas with the original image
        context?.drawImage(displayImageObject, 0, 0, imageWidth, imageHeight)

        loadWatermark(watermark).then((watermarkImage) => {
          const { width: watermarkWidth, height: watermarkHeight } = calculateAspectRatioFit(
            watermarkImage.width,
            watermarkImage.height,
            maxWidth,
            maxHeight
          )

          // drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
          /*
            sx Optional - The x-axis coordinate of the top left corner of the sub-rectangle of the source image to draw into the destination context. Use the 3- or 5-argument syntax to omit this argument.
            sy Optional - The y-axis coordinate of the top left corner of the sub-rectangle of the source image to draw into the destination context. Use the 3- or 5-argument syntax to omit this argument.
            sWidth Optional - The width of the sub-rectangle of the source image to draw into the destination context. If not specified, the entire rectangle from the coordinates specified by sx and sy to the bottom-right corner of the image is used. Use the 3- or 5-argument syntax to omit this argument. A negative value will flip the image.
            sHeight Optional - The height of the sub-rectangle of the source image to draw into the destination context. Use the 3- or 5-argument syntax to omit this argument. A negative value will flip the image.
            dx - The x-axis coordinate in the destination canvas at which to place the top-left corner of the source image.
            dy - The y-axis coordinate in the destination canvas at which to place the top-left corner of the source image.
            dWidth - The width to draw the image in the destination canvas. This allows scaling of the drawn image. If not specified, the image is not scaled in width when drawn. Note that this argument is not included in the 3-argument syntax.
            dHeight - The height to draw the image in the destination canvas. This allows scaling of the drawn image. If not specified, the image is not scaled in height when drawn. Note that this argument is not included in the 3-argument syntax.
          */

          // desired watermark width / height in percent
          const isLandscape = imageWidth > imageHeight

          let pct, renderWidth, renderHeight
          if (isLandscape) {
            // 20% of the height
            pct = 20
            const { width, height } = calculateAspectRatioFit(
              watermarkWidth,
              watermarkHeight,
              imageWidth,
              (imageHeight * pct) / 100
            )
            renderWidth = width
            renderHeight = height
          } else {
            // 80% of the width
            pct = 80
            const { width, height } = calculateAspectRatioFit(
              watermarkWidth,
              watermarkHeight,
              (imageWidth * pct) / 100,
              imageHeight
            )
            renderWidth = width
            renderHeight = height
          }

          // offset
          const renderX = (imageWidth - renderWidth) / 2,
            renderY = (imageHeight - renderHeight) / 2

          context?.drawImage(
            watermarkImage,
            0,
            0,
            watermarkImage.width,
            watermarkImage.height,
            renderX,
            renderY,
            renderWidth,
            renderHeight
          )

          canvas.toBlob(
            function (blob) {
              resolve({
                width: displayImageObject.width,
                height: displayImageObject.height,
                logofied: blob,
                aspectRatio: aspectRatio,
                quality
              })
            },
            'image/jpeg',
            0.85
          )
        })
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function createThumbnail(image: Blob | MediaSource) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    loadImage(image)
      .then((displayImage) => {
        const displayImageObject = displayImage as HTMLImageElement
        const { width, height } = calculateAspectRatioFit(
          displayImageObject.width,
          displayImageObject.height,
          400,
          400
        )
        const canvasWidth = width
        const canvasHeight = height

        canvas.width = canvasWidth
        canvas.height = canvasHeight

        // initializing the canvas with the original image
        context?.drawImage(displayImageObject, 0, 0, canvasWidth, canvasHeight)
        canvas.toBlob(
          function (blob) {
            resolve(blob)
          },
          'image/jpeg',
          0.95
        )
      })
      .catch((error) => reject(error))
  })
}

export const useImageCDN = (url: string, size = 'small', optimize = true) => {
  try {
    // return the url if the url is a blob url
    const isBlobUrl = url && url.startsWith('blob:')
    if (isBlobUrl) {
      return url
    }

    // check if we have a url
    if (url) {
      // create a new url object
      const urlObject = new URL(url)

      // check the host is allowed
      const hosts: Record<string, boolean> = {
        'tustemp.contentedworld.com': true,
        'contented-uploads.b-cdn.net': true, // 'cmg > bunny > storage > contented-uploads',
        'vz-7678a8e1-7e3.b-cdn.net': true, // 'cmg > bunny > stream > contented-briefs',
        'vz-b68fbf05-e90.b-cdn.net': true, // 'cmg > bunny > stream > contented-marketing',
        'vz-45fc0159-3b8.b-cdn.net': true, // 'cmg > bunny > stream > contented-profiles',
        'vz-c64e6494-079.b-cdn.net': true, // 'cmg > bunny > stream > contented-test-watermark',
        'vz-4e8d49c5-eb4.b-cdn.net': true, // 'cmg > bunny > stream > contented-watermark',
        'vz-25bae7c4-5ea.b-cdn.net': true, // 'cmg > bunny > stream > landbot-brief-videos',
        'cmg-create-brief-assets.b-cdn.net': true, // 'miappi > bunny > storage > cmg-create-brief-assets',
        'vz-cb44d77f-99b.b-cdn.net': true // 'miappi > bunny > stream > cmg-create-brief-videos'
      }

      // check if legacy landbot url
      const isLandbotUrl = urlObject?.href?.match(
        '//storage.googleapis.com/media.landbot.io/427040/chats/'
      )

      // check if this is a cmg api domain
      const isApiDomain = urlObject?.hostname?.match(
        /cmg(-test|-preview|-uat)?\.contentedworld\.workers\.dev/
      )

      // check if this is a whitelisted domain
      const isWhitelistedDomain = urlObject && hosts[urlObject.hostname]

      // check if we have a hostname that is in the whitelist
      if (isLandbotUrl || isApiDomain || isWhitelistedDomain) {
        let optimizedUrl =
          'https://contented-optimized.b-cdn.net/' + urlObject.href.replace(/http(s)?:\/\//, '')

        const optimizedUrlObject = new URL(optimizedUrl)

        // map size to height param
        const sizesMap: Record<string, string> = {
          thumb: '256',
          small: '512',
          medium: '1024',
          large: '1600'
        }

        if (sizesMap?.[size]) {
          optimizedUrlObject.searchParams.set('height', sizesMap[size])
        }

        // add auto_optimize param
        if (optimize) {
          optimizedUrlObject.searchParams.set('auto_optimize', 'medium')
        }

        // return the url
        return optimizedUrlObject.href
      }
    }
  } catch (e) {
    //
  }

  // return the original url if we can't process it
  return url
}

export function sortByDateProperty(
  array: any[],
  propertyName: string,
  order: 'asc' | 'desc' = 'desc'
) {
  const arrayCopy = array.slice()
  arrayCopy.sort((a, b) => {
    const dateA = a[propertyName] ? new Date(a[propertyName]) : new Date(0)
    const dateB = b[propertyName] ? new Date(b[propertyName]) : new Date(0)
    return order === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime()
  })
  return arrayCopy
}

<script lang="ts" setup>
import { ref, watchEffect } from 'vue'
import { getTeamMessages } from '@/queries/api'
import BriefDetailsQA from '@briefs/BriefDetailsQA.vue'
import { useToggle } from '@vueuse/core'

const props = withDefaults(
  defineProps<{
    team: Team
  }>(),
  {
    //
  }
)

const [showDetails, showDetailsToggle] = useToggle<boolean>(false)

const loading = ref<boolean>(false)
const messages = ref<SmartBriefFeedback[]>([])

const fetchMessages = async (teamUuid: string) => {
  loading.value = true
  const teamMessages = await getTeamMessages('uuid', teamUuid)

  messages.value = [...teamMessages]

  loading.value = false
}

watchEffect(() => {
  fetchMessages(props.team.uuid)
})
</script>

<template>
  <div>
    <div
      class="bg-white w-full max-w-6xl rounded-md mt-6 py-3 px-6 mx-auto border-2 border-[#5386EA]"
    >
      <div class="w-full flex flex-col mx-auto">
        <h3
          class="group hover:text-cw-primary cursor-pointer text-lg font-bold tracking-wide my-3"
          style="text-align: left"
          @click="() => showDetailsToggle()"
        >
          How Team Chat works
          <button class="text-sm">
            <i
              class="fa-solid fa-chevron-down ml-2"
              :class="{
                'fa-chevron-right': !showDetails,
                'fa-chevron-down': showDetails
              }"
            ></i>
          </button>
        </h3>
        <div
          :class="{
            hidden: !showDetails
          }"
        >
          <p class="py-1.5">Hello {{ props.team.name }} Team!</p>
          <p class="py-1.5">
            Welcome to our new message board! 🎉 This space is designed to keep us all connected,
            informed, and collaborative. Here, you can:
          </p>
          <ul class="list-disc ml-6 py-1.5">
            <li>
              Share Updates: Keep everyone in the loop with the latest status changes on your
              projects.
            </li>
            <li>
              Add Attachments: Easily upload and share important documents, images, and other files.
            </li>
            <li>
              Collaborate on Ideas: Brainstorm, discuss, and refine ideas together to drive our
              projects forward.
            </li>
          </ul>
          <p class="py-1.5">
            Feel free to post your updates, ask questions, and share your thoughts. Let's make the
            most of this tool to enhance our teamwork and productivity. If you have any suggestions
            or need assistance, don't hesitate to reach out.
          </p>
          <p class="py-1.5">Happy collaborating! 😊</p>
        </div>
      </div>
    </div>

    <BriefDetailsQA
      :initial-messages="messages"
      :reference_id="props.team.uuid"
      :reference_type="'team'"
      :called_from="'team'"
      :is-brief-loading="loading"
      :pin-input-to-bottom="true"
    />
  </div>
</template>

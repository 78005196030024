export const makeTestAttributes = (data: any, id: string, fields: string[]) => {
  const el = document.getElementById(id)
  if (el == null) {
    setTimeout(() => {
      makeTestAttributes(data, id, fields)
    }, 50)
    return
  }
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i],
      val = data[field]
    if (typeof val !== 'undefined') {
      el.dataset[field] = val
    }
  }
}

<script lang="ts" setup>
import Markdown from '@/components/Markdown.vue'
import MediaCarousel from '@media/MediaCarousel.vue'
import MediaFilesList from '@media/MediaFilesList.vue'
import { downloadSmartBriefFiles } from '@/queries/api'

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  body: {
    type: String,
    default: ''
  },
  bgGradient: {
    type: Boolean,
    default: false
  },
  imageClasses: {
    type: Array,
    default: []
  },
  imageSource: {
    type: String,
    default: ''
  },
  imagePosition: {
    type: String,
    default: 'left'
  },
  class: {
    type: String,
    default: ''
  },
  imageItems: {
    type: Array,
    default: () => []
  },
  fileItems: {
    type: Array,
    default: () => []
  },
  brief: Object
})

const downloadAll = async () => {
  // calling api to download files from db
  const resp = await downloadSmartBriefFiles(props.brief?.id)
  const url = window.URL.createObjectURL(new Blob([resp.data]))
  let fileName = 'files.zip'
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}
</script>

<template>
  <div :class="props.class">
    <div class="roboto font-medium mx-6">
      <div class="w-full lg:flex-row">
        <div>
          <h3 class="roboto text-xl font-semibold tracking-wide mb-3" style="text-align: left">
            {{ props.title }}
          </h3>
          <div class="text-sm lg:text-md">
            <Markdown :content="props.body" />
          </div>
        </div>
        <div class="w-full flex flex-col">
          <div
            class="flex justify-center items-center mt-4 lg:mt-0"
            v-if="
              props.imageItems && Array.isArray(props.imageItems) && props.imageItems.length > 0
            "
          >
            <MediaCarousel :items="props.imageItems" dataType="objects" noContentMsg="" />
          </div>
          <div
            class="flex justify-center items-center mt-4 lg:mt-4"
            v-if="props.fileItems && Array.isArray(props.fileItems) && props.fileItems.length > 0"
          >
            <MediaFilesList :items="props.fileItems" dataType="objects" noContentMsg="" />
          </div>
        </div>
      </div>
      <div
        v-if="
          (props.fileItems && Array.isArray(props.fileItems) && props.fileItems.length > 0) ||
          (props.imageItems && Array.isArray(props.imageItems) && props.imageItems.length > 0)
        "
        class="flex flex-col lg:flex-row lg:space-x-4 justify-center items-center mt-4"
      >
        <div class="action-button hollow mt-6" @click="downloadAll">Download all</div>
      </div>
      <div
        v-if="
          (props.fileItems && Array.isArray(props.fileItems) && props.fileItems.length > 0) ||
          (props.imageItems && Array.isArray(props.imageItems) && props.imageItems.length > 0)
        "
        class="flex flex-col lg:flex-row lg:space-x-4 justify-center items-center mt-4"
      >
        <span style="display: block; font-size: 0.875rem; color: grey">
          Please note: Download excludes video files
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { mobileDetect } from '@/utils/browser'

const emit = defineEmits(['videoPlaying', 'videoLoaded', 'videoError'])

export interface Props {
  details?: any
  decryptedUrl?: string
  playInline?: boolean
  links?: boolean
  videoCss?: any
  hideIfDead?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  details: {},
  decryptedUrl: '',
  playInline: true,
  links: false,
  videoCss: ['h-64'],
  hideIfDead: false
})

const adjustedVideoCss = ref(props.videoCss || [])

// make adjustedVideoCss default to h-64 if no item beginning with h- is found and it doesn't already contain h-64
if (
  !adjustedVideoCss.value.some((css: string) => css.startsWith('h-')) &&
  !adjustedVideoCss.value.includes('h-64')
) {
  adjustedVideoCss.value = [...adjustedVideoCss.value, 'h-64']
}

const style = ref<CSSStyleDeclaration>()
const imageOK = ref(false)

const vid = ref<HTMLVideoElement>()
const isPlaying = ref<boolean>(false)

const bunnyKey = ref<number>(0)
const bunnyUrl = ref<string>()

const isMobile = ref(mobileDetect())
const updatePageWidth = () => {
  isMobile.value = mobileDetect()
}
onMounted(() => {
  updatePageWidth()
  window.addEventListener('resize', updatePageWidth)
})
onUnmounted(() => {
  window.removeEventListener('resize', updatePageWidth)
})

const waitForBunnyThumb = (retry: number = 0) => {
  if (imageOK.value || retry > 59) {
    return
  }
  setTimeout(() => {
    bunnyKey.value++
    bunnyUrl.value = props.details.bunnyThumb.value + '#' + bunnyKey.value
    waitForBunnyThumb(retry + 1)
  }, 5000)
}

const isBunny = ref(props.details.isBunny)

// Bunny or straight video url or decrypted video data
if (isBunny.value) {
  setTimeout(() => {
    waitForBunnyThumb()
  }, 6000)
} else {
  if (vid.value) {
    if (props.decryptedUrl) {
      vid.value.src = props.decryptedUrl
    } else if (props.details.item?.href) {
      vid.value.src = props.details.item.href
    }
  }
}

const imageLoaded = () => {
  imageOK.value = true
  emit('videoLoaded')
}

const setPlaying = () => {
  if (!props.playInline) {
    popup()
    return
  }

  isPlaying.value = true
  emit('videoPlaying', props.details.item.id)
}

const videoContainer = ref()

const popup = () => {
  // set global variables for PopupImage.vue
  window.popupTitle = props.details.item.name
  window.popupTags = props.details.item.tags
  window.popupDescription = props.details.item.short_description || props.details.item.description
  // get list of items in the carousel
  let parent = videoContainer.value.parentElement
  while (parent && !parent.hasAttribute('data-carousel-id')) {
    parent = parent.parentElement
  }
  if (parent) {
    window.popupCarousel = parent.getAttribute('data-carousel-id')
  }
  // trigger PopupImage.vue
  window.popupVideoThumb = props.details.bunnyThumb
  window.popupVideoStream = props.details.bunnyStream
  // console.log('window.popupVideoStream', props.details)
}

const debug = ref(false)
const debugSize = ref(false)

// show message if we haven't loaded the thumbnail yet
const fadeInBunnyMsg = ref(false)
setTimeout(() => {
  fadeInBunnyMsg.value = true
}, 5000)
</script>

<template>
  <pre v-if="debug" class="text-xs width-[300]px overflow-auto">{{ details }} {{ imageOK }}</pre>
  <pre v-if="debugSize" class="absolute text-xs z-50 bg-green-200">{{ adjustedVideoCss }}</pre>
  <div
    ref="videoContainer"
    class="media-video flex items-center justify-center relative h-full bg-black overflow-hidden"
    :class="[...adjustedVideoCss]"
  >
    <!-- Bunny player -->
    <div
      v-if="isBunny"
      class="w-full h-full"
      :class="{
        'min-h-64': !imageOK
      }"
    >
      <!-- bunny thumb -->
      <div class="w-full h-full flex items-center justify-center">
        <div v-if="!imageOK" class="w-full h-full absolute">
          <!-- spinner -->
          <div
            class="w-full h-full absolute top-0 left-0 z-10 flex flex-col items-center justify-center"
          >
            <div class="fa-spin fa-2x text-white">
              <i class="fa-solid fa-spinner"></i>
            </div>
          </div>
          <!-- wait for thumbnail -->
          <div
            class="absolute top-[15%] w-full h-full z-10 flex text-white text-sm text-center justify-center"
          >
            <div
              class="w-[80%] text-center text-xs transition-opacity duration-1000"
              :class="{
                'opacity-0': !fadeInBunnyMsg,
                'opacity-100': fadeInBunnyMsg
              }"
            >
              Your video is being processed. Please wait...
            </div>
          </div>
          <div
            class="absolute bottom-[15%] w-full z-10 flex text-white text-xs text-center justify-center"
          >
            <div
              class="w-[80%] text-center transition-opacity duration-1000"
              :class="{
                'opacity-0': !fadeInBunnyMsg,
                'opacity-100': fadeInBunnyMsg
              }"
            >
              This may take a while for large videos, so you can leave this page and come back
              later.
            </div>
          </div>
        </div>
        <img
          :src="details.bunnyThumb"
          class="w-full max-w-full max-h-full transition-opacity top-0 left-0 z-20"
          :class="{
            'opacity-0': !imageOK,
            'opacity-100': imageOK
          }"
          @load="imageLoaded"
          :key="bunnyKey"
        />
        <!-- controls -->
        <div
          v-if="!isPlaying && imageOK"
          class="controls absolute top-0 left-0 z-20 w-full h-full flex items-center justify-center"
        >
          <span class="fa-stack fa-2x cursor-pointer" @click.prevent="setPlaying">
            <i class="fa-solid fa-circle fa-stack-2x text-slate-700 opacity-60"></i>
            <i class="ml-0.5 fa-solid fa-play fa-stack-1x text-white"></i>
          </span>
        </div>
      </div>
      <!-- bunny iframe -->
      <div class="w-full h-full">
        <iframe
          v-show="isPlaying"
          autoplay="true"
          loading="lazy"
          :src="details.bunnyStream"
          class="w-full h-full absolute top-0 left-0 z-20"
          style="border: 0"
          allowfullscreen="true"
          allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
        />
      </div>
      <!-- may take a while to generate the thumbnail -->
    </div>

    <!-- HTML5 player -->
    <div v-else>
      <img
        v-if="details.item.image"
        class="absolute w-full h-full"
        :src="details.item.image"
        @load="imageLoaded"
        v-show="!imageOK"
      />
      <video
        :src="props.decryptedUrl || props.details.item.href"
        :style="{
          height: style?.height?.trim()?.replace('px', '') + 'px'
        }"
        :poster="details.item.image"
        :controls="true"
        :class="[...(props?.videoCss || [])]"
      ></video>
      <div
        class="cursor-pointer absolute top-0 left-0 right-0 bottom-0"
        :style="{
          background: 'rgba(0, 0, 0, 0.05)'
        }"
        v-show="!isPlaying"
        @click="
          () => {
            setPlaying()
          }
        "
      >
        <div class="w-full h-full flex items-center justify-center text-sm">
          <span class="fa-stack fa-2x" @click.prevent.stop="setPlaying">
            <i
              class="fa-solid fa-circle fa-stack-2x text-slate-700 group-hover:text-white opacity-60"
            ></i>
            <i
              class="ml-0.5 fa-solid fa-play fa-stack-1x fa-inverse text-white group-hover:text-slate-700"
            ></i>
          </span>
        </div>
        <RouterLink
          v-if="links && details.item?.href && details.item?.href !== '#'"
          :to="details.item?.href"
          class="hidden group-hover:block text-right mr-3 -mt-8 text-white z-50"
          @click="$event.stopPropagation()"
          title="View item"
        >
          <i class="fa fa-link"></i>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

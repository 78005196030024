import gql from 'graphql-tag'
import { BID_DETAILS, BRIEF_DETAILS, CONTENT_DETAILS } from '@/queries/fragments'

/*
type _Schema_
  @fulltext(
    name: "search"
    language: en
    algorithm: rank
    include: [{ entity: "Content", fields: [{ name: "name" }, { name: "description" }] }]
  )
*/

export const searchContent = () => {
  return gql`
    ${CONTENT_DETAILS}
    query Search($search: String) {
      search(text: $search) {
        id
        name
      }
    }
  `
}

export const getAllStockContent = () => {
  return gql`
    ${CONTENT_DETAILS}
    ${BID_DETAILS}
    query Contents(
      $pagingPerPage: Int
      $pagingOffset: Int
      $search: String
      $media_type: String
      $media_orientation: String
      $media_resolution: String
      $media_preference: String
      $media_order_by: String
      $bids_count: Int
      $content_expiry_date: String
      $order_direction: String
    ) {
      contents(
        first: $pagingPerPage
        skip: $pagingOffset
        where: {
          search_meta_contains_nocase: $search
          media_type_contains: $media_type
          quality_contains: $media_resolution
          orientation_contains: $media_orientation
          type_contains: $media_preference
          brief: null
          bid_count_gt: $bids_count
          created_on_lte: $content_expiry_date
        }
        orderBy: $media_order_by
        orderDirection: $order_direction
      ) {
        ...ContentDetails
        bids {
          ...BidDetails
        }
        brief {
          id
          content_usages {
            id
            type
            value
          }
        }
        licences {
          id
          owner
          price
        }
      }
    }
  `
}

export const getAllContent = () => {
  return gql`
    ${CONTENT_DETAILS}
    ${BID_DETAILS}
    query Contents(
      $pagingPerPage: Int
      $pagingOffset: Int
      $search: String
      $media_type: String
      $media_orientation: String
      $media_resolution: String
      $media_preference: String
      $accountId: String
    ) {
      contents(
        first: $pagingPerPage
        skip: $pagingOffset
        where: {
          or: [
            { and: [{ owner: $accountId }, { licence_count_gt: "0" }] }
            { and: [{ owner: $accountId }, { bid_count_gt: "0" }] }
            { bid_owners: [$accountId] }
          ]
        }
      ) {
        ...ContentDetails
        bids {
          ...BidDetails
        }
        brief {
          id
          content_usages {
            id
            type
            value
          }
        }
        licences {
          id
          owner
          price
        }
        payouts {
          id
          owner
          payment
          percentage
          date
        }
        initialBids {
          id
          owner
          percentage
          amount
        }
      }
    }
  `
}

export const getUploadedContent = () => {
  return gql`
    ${CONTENT_DETAILS}
    ${BID_DETAILS}
    query Contents($accountId: String) {
      contents(where: { owner: $accountId }) {
        ...ContentDetails
        bids {
          ...BidDetails
        }
      }
    }
  `
}

export const getProfileData = () => {
  return gql`
    query ProfileData($accountId: String) {
      profileDatas(where: { id: $accountId }) {
        id
        owner
        firstname
        lastname
        headline
        aboutyou
        city
        country
        profilePic
        emailrsa
        facebook
        instagram
        twitter
        website
        behance
        tiktok
        pexels
        linkedin
        youtube
        dribbble
        artstation
        languages {
          name
          level
        }
        contents {
          id
          name
          short_description
          tags
          file_url
        }
        didKey
        ucanTokens {
          id
          iss
          aud
          token
        }
      }
    }
  `
}

export const getProfileImage = () => {
  return gql`
    query ProfileData($accountId: String) {
      profileDatas(where: { id: $accountId }) {
        profilePic
      }
    }
  `
}

export const getMyContent = () => {
  return gql`
    ${CONTENT_DETAILS}
    query Contents($accountId: String, $first: Int, $after: String) {
      contents(where: { owner: $accountId, id_gt: $after }, first: $first) {
        ...ContentDetails
      }
    }
  `
}

export const getLicensedContent = () => {
  return gql`
    ${CONTENT_DETAILS}
    ${BID_DETAILS}
    query ContentLicences($accountId: String) {
      contentLicences(where: { owner: $accountId }) {
        id
        owner
        price
        content {
          ...ContentDetails
          bids {
            ...BidDetails
          }
        }
      }
    }
  `
}

// TODO - filter
export const getRewardsContent = () => {
  return gql`
    ${CONTENT_DETAILS}
    ${BID_DETAILS}
    query ContentLicences($accountId: String) {
      contentLicences(where: { owner: $accountId }) {
        id
        owner
        price
        content {
          ...ContentDetails
          bids {
            ...BidDetails
          }
        }
      }
    }
  `
}

export const getStudioRewardsContent = () => {
  return gql`
    ${CONTENT_DETAILS}
    ${BID_DETAILS}
    query Contents($accountId: String) {
      contents(where: { owner: $accountId }) {
        ...ContentDetails
        bids {
          ...BidDetails
        }
        licences {
          owner
          price
        }
        payouts {
          id
          owner
          payment
          percentage
          date
        }
      }
    }
  `
}

export const getContent = (id: string | undefined) => {
  return gql`
        ${CONTENT_DETAILS}
        ${BID_DETAILS}
        query Content {
            content(id: "${id}") {
                ...ContentDetails
                bids {
                    ...BidDetails
                }
                brief {
                    id
                    content_usages {
                        id
                        type
                        value
                    }
                }
                licences {
                    id
                    owner
                    price
                }
            }
        }
    `
}

export const getContentAndBrief = (content_id: string | undefined, brief_id: string | undefined) => {
  if (brief_id) {
    return gql`
            ${CONTENT_DETAILS}
            ${BID_DETAILS}
            query Content {
                content(id: "${content_id}") {
                    ...ContentDetails
                    bids {
                        ...BidDetails
                    }
                },
                marketingBrief(id:"${brief_id}") {
                    id
                    created_on
                    content_usages {
                        id
                        type
                        value
                    }
                }
            }
        `
  } else {
    return gql`
            ${CONTENT_DETAILS}
            ${BID_DETAILS}
            query Content {
                content(id: "${content_id}") {
                    ...ContentDetails
                    bids {
                        ...BidDetails
                    }
                }
            }
        `
  }
}

export const getAllCreativeBriefs = () => {
  return gql`
    ${BRIEF_DETAILS}
    query MarketingBriefs($media_type: String) {
      marketingBriefs(where: { campaign_formats_: { media_type_contains: $media_type } }) {
        ...BriefDetails
        media_items {
          id
          type
          link
        }
        content_usages {
          id
          type
          value
        }
        campaign_formats {
          id
          quality
          media_type
          aspect_ratio
        }
        restrictions
        created_on
        contents {
          owner
        }
      }
    }
  `
}

export const getSmartBriefs = () => {
  return gql`
    {
      smartBriefs(where: { brief_status: "published", archive: false }) {
        id
        audience
        campaign_objective
        image
        meta_data
        owner
        title
        type
        logo
        ndaAccepted
        ndaSubmitted
        acceptedProfiles
        brief_status
        ndaTitle
        ndaBrandName
        ndaSummary
        ndaCompanyLogo
        ndaImage
        summary
        budget
        interestDeadline
        submissionDeadline
        contentTypes
        brandName
        brandLogo
        isNDARequired
        ndaText
        brief_type
        sections {
          id
          title
          content
          type
          image
        }
        contents {
          id
          owner
          brief_id
          file_url
        }
        paymentPreferences {
          id
          key
          iv
          email_contact
          colleague_email
          colleague_name
          promo_code
          platform_fee
          payment_to_creator
          total_charges
          budget
          payment_options
          number_of_creators
          currency
          vat_number
          vat_postcode
        }
        archive
      }
    }
  `
}

export const getSmartBriefsComments = () => {
  return gql`
    query BriefComments($brief_id: String, $dm_candidate_id: String) {
      briefComments(
        where: { brief_id: $brief_id, dm_candidate_id: $dm_candidate_id }
        orderBy: created_on
        orderDirection: desc
      ) {
        id
        brief_id
        parent_id
        account_id
        account_name
        profile_image
        dm_candidate_id
        comment
        created_on
      }
    }
  `
}

export const getMyBriefs = () => {
  return gql`
    query SmartBriefs($accountId: String) {
      smartBriefs(where: { owner: $accountId, archive: false }) {
        id
        audience
        campaign_objective
        image
        meta_data
        owner
        title
        type
        logo
        ndaAccepted
        ndaSubmitted
        acceptedProfiles
        brief_status

        ndaTitle
        ndaBrandName
        ndaSummary
        ndaCompanyLogo
        ndaImage

        summary
        budget
        interestDeadline
        submissionDeadline
        contentTypes
        brandName
        brandLogo
        isNDARequired
        ndaText
        brief_type
        sections {
          id
          title
          content
          type
          image
        }
        contents {
          id
          owner
          brief_id
          file_url
        }
        paymentPreferences {
          id
          key
          iv
          email_contact
          colleague_email
          colleague_name
          promo_code
          platform_fee
          payment_to_creator
          total_charges
          budget
          payment_options
          number_of_creators
          currency
          vat_number
          vat_postcode
        }
        archive
      }
    }
  `
}

export const getSmartBrief = () => {
  return gql`
    query SmartBriefs($id: ID!) {
      smartBriefs(where: { id: $id, archive: false }) {
        id
        audience
        campaign_objective
        image
        meta_data
        owner
        title
        type
        logo
        ndaAccepted
        ndaSubmitted
        acceptedProfiles
        brief_status
        candidateProfiles {
          id
          account_id
          status
          feedbacks {
            id
            feedback
            submit_date
          }
          ucanTokens {
            id
            iss
            aud
            token
          }
          amountPaid
        }
        ndaTitle
        ndaBrandName
        ndaSummary
        ndaCompanyLogo
        ndaImage
        summary
        budget
        interestDeadline
        submissionDeadline
        contentTypes
        brandName
        brandLogo
        isNDARequired
        ndaText
        brief_type
        sections {
          id
          title
          content
          type
          image
        }
        contents {
          id
          owner
          brief_id
          file_url
          watermark_file_url
          watermark_video_stream_url
          iv
          key
          submit_date
          media_type
        }
        paymentPreferences {
          id
          key
          iv
          email_contact
          colleague_email
          colleague_name
          promo_code
          platform_fee
          payment_to_creator
          total_charges
          budget
          payment_options
          number_of_creators
          currency
          vat_number
          vat_postcode
        }
        archive
      }
    }
  `
}

export const getCreativeBriefs = () => {
  return gql`
    ${BRIEF_DETAILS}
    query MarketingBriefs($accountId: String) {
      marketingBriefs(where: { owner: $accountId }) {
        ...BriefDetails
        media_items {
          id
          type
          link
        }
        created_on
        contents {
          id
          owner
          name
          type
          media_type
          media_link
          media_link_original
          brief {
            id
            content_usages {
              id
              type
              value
              cover {
                id
                name
                metric
                circulation
              }
            }
          }
        }
      }
    }
  `
}

export const getCreativeBrief = () => {
  return gql`
    query MarketingBriefs($id: ID!) {
      marketingBriefs(where: { id: $id }) {
        id
        owner
        titles
        campaign_title
        campaign_objective
        campaign_formats {
          id
          quality
          media_type
          aspect_ratio
        }
        terms_and_conditions
        restrictions
        ownership_model
        proposed_price
        proposed_price_currency
        logo
        media_items {
          id
          type
          link
        }
        content_usages {
          id
          type
          value
          cover {
            id
            name
            metric
            circulation
          }
        }
        contents {
          id
          owner
          name
          type
          media_type
          media_link
          bids {
            value
            percentage
          }
        }
        created_on
      }
    }
  `
}

export const getCreativeBriefsWithMyResponse = () => {
  return gql`
    query MarketingBriefs($accountId: String) {
      marketingBriefs(where: { contents_: { owner: $accountId } }) {
        id
        owner
        titles
        campaign_title
        campaign_objective
        proposed_price
        proposed_price_currency
        logo
        media_items {
          id
          type
          link
        }
        content_usages {
          id
          type
          value
        }
        campaign_formats {
          id
          quality
          media_type
          aspect_ratio
        }
        restrictions
        created_on
      }
    }
  `
}

export const getBriefAndResponse = (brief_id: any, brief_response_id: undefined) => {
  return gql`
        ${CONTENT_DETAILS}
        ${BID_DETAILS}
        ${BRIEF_DETAILS}
        {
            marketingBrief(id: "${brief_id}") {
                ...BriefDetails
            }
            content(id: "${brief_response_id}") {
                ...ContentDetails
                bids {
                    ...BidDetails
                }
            }
        }
    `
}

export const getPricingConfigurations = () => {
  return gql`
    {
      pricingConfigurations {
        id
        bidMultiplier
        minPremiumPrice
        minStandardPrice
        channel_prices {
          name
          value
        }
      }
      usdcurrencyStates {
        name
        price
        portion
        base
      }
      gbpcurrencyStates {
        name
        price
        portion
        base
      }
      eurcurrencyStates {
        name
        price
        portion
        base
      }
      stableCoinStates {
        id
        name
        value
      }
    }
  `
}

import { type FormKitNode } from '@formkit/core'

const FormKitYouTubeValidation = function (node: FormKitNode): boolean {
  const url = new URL(String(node.value))
  return ['youtube.com', 'www.youtube.com', 'youtu.be'].includes(url.hostname)
}

const FormKitTikTokValidation = function (node: FormKitNode): boolean {
  const url = new URL(String(node.value))
  return ['tiktok.com', 'www.tiktok.com'].includes(url.hostname)
}

const FormKitInstagramValidation = function (node: FormKitNode): boolean {
  const url = new URL(String(node.value))
  return ['instagram.com', 'www.instagram.com'].includes(url.hostname)
}

const FormKitTwitterValidation = function (node: FormKitNode): boolean {
  const url = new URL(String(node.value))
  return ['twitter.com', 'www.twitter.com', 'x.com', 'www.x.com'].includes(url.hostname)
}

const FormKitBehanceValidation = function (node: FormKitNode): boolean {
  const url = new URL(String(node.value))
  return ['behance.net', 'www.behance.net'].includes(url.hostname)
}

const FormKitDribbbleValidation = function (node: FormKitNode): boolean {
  const url = new URL(String(node.value))
  return ['dribbble.com', 'www.dribbble.com'].includes(url.hostname)
}

const FormKitArtStationValidation = function (node: FormKitNode): boolean {
  const url = new URL(String(node.value))
  return ['artstation.com', 'www.artstation.com'].includes(url.hostname)
}

export {
  FormKitYouTubeValidation,
  FormKitTikTokValidation,
  FormKitInstagramValidation,
  FormKitTwitterValidation,
  FormKitBehanceValidation,
  FormKitDribbbleValidation,
  FormKitArtStationValidation
}
